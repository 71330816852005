import React from 'react';

export default (props) => (
  <svg
    {...props}
    width="36"
    height="35"
    viewBox="0 0 36 35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M27.5844 5.10939C27.6001 5.11053 27.6158 5.11167 27.6317 5.11167C27.6588 5.11167 27.6858 5.10939 27.7123 5.10569C28.4212 5.12022 28.9885 5.69834 28.9893 6.40719C28.9893 6.72929 29.2505 6.99045 29.5726 6.99045C29.8947 6.99045 30.1558 6.72929 30.1558 6.40719C30.1544 5.2478 29.3473 4.24477 28.215 3.99529V3.43482C28.215 3.11272 27.9538 2.85156 27.6317 2.85156C27.3096 2.85156 27.0485 3.11272 27.0485 3.43482V4.02177C25.8512 4.34188 25.0766 5.50013 25.238 6.72901C25.3995 7.95788 26.447 8.87662 27.6864 8.87662C28.4061 8.87662 28.9893 9.46016 28.9893 10.1798C28.9893 10.8992 28.4061 11.4828 27.6864 11.4828C26.9667 11.4828 26.3835 10.8992 26.3835 10.1798C26.3835 9.85773 26.1223 9.59658 25.8002 9.59658C25.4781 9.59658 25.217 9.85773 25.217 10.1798C25.2184 11.2974 25.9691 12.275 27.0485 12.5653V13.2043C27.0485 13.5264 27.3096 13.7876 27.6317 13.7876C27.9538 13.7876 28.215 13.5264 28.215 13.2043V12.592C29.4467 12.3223 30.2774 11.1678 30.1416 9.91412C30.006 8.66047 28.9474 7.7104 27.6864 7.71069C26.9867 7.71097 26.4117 7.15847 26.384 6.45931C26.3567 5.76014 26.8867 5.16407 27.5844 5.10968V5.10939Z" />
    <path d="M27.7197 0C24.1316 0 21.0695 2.34128 19.9223 5.47627H5.44608C5.1254 5.47627 4.8651 5.76533 4.86282 6.08572L4.72499 26.5463H0.598633C0.276533 26.5463 0 26.7796 0 27.1022V29.6987C0.00740456 32.1431 1.9326 34.1511 4.37441 34.2616V34.2744H22.3824V34.2574C22.5282 34.2628 22.5883 34.2744 22.6806 34.2744H22.6931C25.2203 34.2705 27.2671 32.2205 27.2671 29.6933V16.5655C27.4129 16.5735 27.5673 16.5777 27.7182 16.5777C32.2843 16.5777 36 12.8549 36 8.28859C36 3.72252 32.286 0 27.7197 0ZM4.59626 33.1079H4.59256C2.70581 33.1054 1.17505 31.5797 1.16651 29.6927V27.7128H18.0809V29.6845C18.0817 30.9017 18.5679 32.0684 19.4314 32.9265C19.4926 32.9878 19.5686 33.035 19.6327 33.1079H4.59626ZM26.1006 29.6927C26.1009 31.5763 24.5758 33.1042 22.6925 33.1079H22.6814C20.7904 33.1045 19.2568 31.5758 19.2474 29.685V27.1022C19.2522 26.953 19.1953 26.8086 19.0896 26.7032C18.984 26.5979 18.8396 26.5412 18.6906 26.5463H5.89149L6.02535 6.64278H19.5985C19.4952 7.17676 19.4416 7.71901 19.4388 8.26267C19.4376 9.41238 19.6774 10.5493 20.1428 11.6004H14.6338C14.3114 11.6004 14.0505 11.8616 14.0505 12.1837C14.0505 12.5058 14.3114 12.7669 14.6338 12.7669H20.7756C21.9788 14.6591 23.9023 15.9783 26.1006 16.4197V29.6927ZM27.7197 15.4115C23.7907 15.4115 20.6053 12.2264 20.6056 8.29713C20.6056 4.36786 23.7907 1.18274 27.72 1.18274C31.6489 1.18274 34.8341 4.36814 34.8341 8.29713C34.8298 12.2244 31.6472 15.4073 27.7197 15.4115Z" />
    <path d="M10.8902 15.4822C9.86073 15.4822 9.02344 16.293 9.02344 17.2898C9.02344 18.2865 9.86073 19.0973 10.8902 19.0973C11.9198 19.0973 12.7573 18.2865 12.7573 17.2898C12.7573 16.293 11.9198 15.4822 10.8902 15.4822ZM10.8902 17.9308C10.5038 17.9308 10.1899 17.6432 10.1899 17.2898C10.1899 16.936 10.5041 16.6487 10.8902 16.6487C11.2764 16.6487 11.5908 16.9363 11.5908 17.2898C11.5908 17.6432 11.2764 17.9308 10.8902 17.9308Z" />
    <path d="M14.0508 17.2871C14.0508 17.6092 14.3117 17.8704 14.634 17.8704H22.41C22.7321 17.8704 22.9933 17.6092 22.9933 17.2871C22.9933 16.965 22.7321 16.7039 22.41 16.7039H14.634C14.3117 16.7039 14.0508 16.965 14.0508 17.2871Z" />
    <path d="M10.8902 10.4121C9.86073 10.4121 9.02344 11.2232 9.02344 12.22C9.02344 13.2167 9.86073 14.0275 10.8902 14.0275C11.9198 14.0275 12.7573 13.2167 12.7573 12.22C12.7573 11.2232 11.9198 10.4121 10.8902 10.4121ZM10.8902 12.861C10.5038 12.861 10.1899 12.5734 10.1899 12.22C10.1899 11.8665 10.5041 11.5786 10.8902 11.5786C11.2764 11.5786 11.5908 11.8663 11.5908 12.22C11.5908 12.5734 11.2764 12.8613 10.8902 12.8613V12.861Z" />
    <path d="M10.8902 20.5518C9.86073 20.5518 9.02344 21.3626 9.02344 22.3593C9.02344 23.3561 9.86073 24.1669 10.8902 24.1669C11.9198 24.1669 12.7573 23.3561 12.7573 22.3593C12.7573 21.3626 11.9198 20.5518 10.8902 20.5518ZM10.8902 23.0004C10.5038 23.0004 10.1899 22.713 10.1899 22.3593C10.1899 22.0059 10.5041 21.7183 10.8902 21.7183C11.2764 21.7183 11.5908 22.0059 11.5908 22.3593C11.5908 22.7128 11.2764 23.0004 10.8902 23.0004Z" />
    <path d="M22.41 21.8074H14.634C14.3117 21.8074 14.0508 22.0685 14.0508 22.3906C14.0508 22.7127 14.3117 22.9739 14.634 22.9739H22.41C22.7321 22.9739 22.9933 22.7127 22.9933 22.3906C22.9933 22.0685 22.7321 21.8074 22.41 21.8074Z" />
  </svg>
);
