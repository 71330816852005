import React, { useRef } from 'react'

import * as S from './SortPopup.styles'
import SortAZ from '../../../../images/sortAZ.jsx'
import SortZA from '../../../../images/sortZA.jsx'
import Sort19 from '../../../../images/sort19.jsx'
import Sort91 from '../../../../images/sort91.jsx'

export const Sort = (props) => {
  const { sortType, offset, sortBy, sortOrder, cb, sortField } = props

  const isAscSort = sortOrder === 'asc'
  const toggleSort = () => {
    cb(offset, isAscSort ? 'desc' : 'asc', sortBy)
  }

  return (
    <>
      {sortBy !== 'subject' &&
      sortBy !== 'deptid' &&
      sortBy !== 'status' &&
      sortBy !== 'subtotal' &&
      sortBy !== 'billingcycle' ? (
        <S.SortingDiv>
          <S.Sort>
            {sortType === 'number' && (
              <>
                <S.SortDesc
                  isChecked={
                    (sortField === sortBy && !isAscSort) ||
                    (sortField === sortBy && isAscSort)
                  }
                  onClick={() => toggleSort()}
                >
                  {sortField === sortBy && isAscSort ? (
                    <Sort91
                      fillcolor={
                        (sortField === sortBy && !isAscSort) ||
                        (sortField === sortBy && isAscSort)
                          ? '#fff'
                          : ''
                      }
                    />
                  ) : (
                    <Sort19
                      fillcolor={
                        (sortField === sortBy && !isAscSort) ||
                        (sortField === sortBy && isAscSort)
                          ? '#fff'
                          : ''
                      }
                    />
                  )}
                </S.SortDesc>
              </>
            )}
            {sortType === 'text' && (
              <>
                <S.SortDesc
                  isChecked={
                    (sortField === sortBy && !isAscSort) ||
                    (sortField === sortBy && isAscSort)
                  }
                  onClick={() => toggleSort()}
                >
                  {sortField === sortBy && isAscSort ? (
                    <SortZA
                      fillcolor={
                        (sortField === sortBy && !isAscSort) ||
                        (sortField === sortBy && isAscSort)
                          ? '#fff'
                          : ''
                      }
                    />
                  ) : (
                    <SortAZ
                      fillcolor={
                        (sortField === sortBy && !isAscSort) ||
                        (sortField === sortBy && isAscSort)
                          ? '#fff'
                          : ''
                      }
                    />
                  )}
                </S.SortDesc>
              </>
            )}
          </S.Sort>
        </S.SortingDiv>
      ) : null}
    </>
  )
}
