import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Cookies from 'js-cookie'

import { en } from './translations/en'
import { am } from './translations/am'
import { ru } from './translations/ru'

const resources = {
  en,
  am,
  ru,
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'am',
    nsSeparator: false,

    keySeparator: '.',
    // defaultNS: "Unknown error",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export { i18n }
