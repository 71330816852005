import { MANAGE_FREE_DNS_MODAL } from './ManageFreeDNSModal.types'
import { MODAL_TYPES } from '../../../common/Modal/Modal.types'

const initialState = {
  manageFreeDNSZones: null,
  manageFreeDNSZonesLoading: false,
  manageFreeDNSZonesError: null,
  updateDNSZonesLoading: false,
  updateDNSZonesError: null,
}

export const manageFreeDNSModal = (state = initialState, action) => {
  switch (action.type) {
    case MANAGE_FREE_DNS_MODAL.GET_ZONES_REQUEST:
      return {
        ...state,
        manageFreeDNSZonesLoading: true,
        manageFreeDNSZones: null,
        manageFreeDNSZonesError: null,
      }
    case MANAGE_FREE_DNS_MODAL.GET_ZONES_SUCCESS:
      return {
        ...state,
        manageFreeDNSZonesLoading: false,
        manageFreeDNSZones: action.data,
        manageFreeDNSZonesError: null,
      }
    case MANAGE_FREE_DNS_MODAL.GET_ZONES_FAIL:
      return {
        ...state,
        manageFreeDNSZonesLoading: false,
        manageFreeDNSZones: null,
        manageFreeDNSZonesError: action.error,
      }

    case MANAGE_FREE_DNS_MODAL.SET_ZONES_REQUEST:
      return {
        ...state,
        updateDNSZonesLoading: true,
        updateDNSZonesError: null,
      }
    case MANAGE_FREE_DNS_MODAL.SET_ZONES_SUCCESS:
      return {
        ...state,
        updateDNSZonesLoading: false,
        updateDNSZonesError: null,
      }
    case MANAGE_FREE_DNS_MODAL.SET_ZONES_FAIL:
      return {
        ...state,
        updateDNSZonesLoading: false,
        updateDNSZonesError: action.error,
      }

    case MODAL_TYPES.HIDE_MODAL:
      return {
        ...initialState,
      }
    default:
      return {
        ...state,
      }
  }
}
