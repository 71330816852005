import * as Yup from 'yup'

export const getInitialValues = (initialValues) => ({
  domain: initialValues.domain || '',

  method: {
    label: initialValues?.approvalmethods?.[0].toUpperCase() || '',
    value: initialValues?.approvalmethods?.[0] || '',
  },
  address: {
    label: initialValues?.approveremails?.[0] || '',
    value: initialValues?.approveremails?.[0] || '',
  },
})

export const yupScheme = Yup.object().shape({
  domain: Yup.string()
    .min(2, 'validationFields.firstnameMin')
    .max(72, 'validationFields.firstnameMax'),
  method: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required('validationFields.country'),
  }),
  address: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
})
