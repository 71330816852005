import styled from 'styled-components';
import { Link } from 'react-router-dom';
import closeModal from '../../../images/closeModal.svg';
import { Form, Field } from 'formik';

export const AuthModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 1;
  z-index: 100;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  position: relative;

  @media (min-width: 768px) {
    padding: 23px 39px;
  }
`;

export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-size: cover;

  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`;

export const Modal = styled.div`
  height: 100%;
  width: 377px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  outline: none;
  z-index: 100;
`;

export const ModalTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #000000;
`;

export const ModalHeader = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #000000;
  padding-top: 40px;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 30px;
`;
export const Input = styled(Field)`
  width: 44px;
  height: 44px;
  border: 1px solid #00c084;
  box-sizing: border-box;
  font-size: 20px;
  border-radius: 5px;
  padding: 0px 12px;
`;
export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 2px;
`;
export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00c084;
  border-radius: 5px;
  width: 109px;
  height: 32px;
  margin-top: 30px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`;

export const CancelButton = styled(Link)`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  color: #242e46;
  padding-top: 8px;
`;
