import Container from '../Container'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  min-height: 200px;
  padding-right: 25px;

  &:last-child {
    padding-right: 0px;
  }
`

export const ColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 20px;
  padding-top: 50px;
  width: 100%;
  position: relative;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const FooterContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  background-color: #242e46;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const FooterLink = styled(Link)`
  display: flex;
  text-align: center;
  outline: none;
  text-decoration: none;
  color: white;
  padding-bottom: 9px;
  font-size: 16px;
  margin-top: 8px;
  &:hover {
    text-decoration: underline;
  }
`

export const FooterLinkCharter = styled.a`
  display: flex;
  text-align: center;
  outline: none;
  text-decoration: none;
  color: white;
  padding-bottom: 9px;
  font-size: 16px;
  margin-top: 8px;

  &:hover {
    text-decoration: underline;
  }
`

export const FooterLinkAnchor = styled.a`
  display: flex;
  text-align: center;
  outline: none;
  text-decoration: none;
  color: white;
  padding-bottom: 9px;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`

export const FooterTitle = styled.div`
  display: flex;
  text-align: center;
  outline: none;
  text-decoration: none;
  color: white;
  padding-bottom: 9px;
  font-size: 20px;
  padding-bottom: 21px;
`

export const FooterButton = styled.button`
  display: flex;
  text-align: left;
  outline: none;
  text-decoration: none;
  color: white;
  padding-bottom: 9px;
  padding-left: 0;
  ${(p) => p.withMargins && `margin-top: 8px;`}
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
  &.title {
    font-size: 20px;
    padding-bottom: 21px;
  }
`

export const Version = styled.span`
  color: white;
`

export const Mail = styled.div`
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
`

export const Phone = styled.div`
  color: white;
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
`

export const Number = styled.a`
  text-decoration: none;
  color: white;
`

export const PaymentOptionsContainer = styled.div`
  display: flex;
  width: 220px;
  justify-content: space-between;
  margin-bottom: 60px;
  @media (min-width: 768px) {
    width: 350px;
    margin-bottom: 40px;
  }
`

export const PaymentOptionsImg = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
  @media (min-width: 768px) {
    width: 64px;
    height: 64px;
  }
`
