import styled from 'styled-components';

export const PageBody = styled.div`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  padding: 35px 20px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  @media (min-width: 768px) {
    padding: 70px 20px;
  }
  @media (min-width: 1280px) {
    padding: 65px 200px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const EditProfileTitle = styled.div`
  text-align: center;
  color: #484857;
  font-size: 18px;
  font-weight: 500;
  @media (min-width: 768px) {
    font-size: 24px;
  }
  @media (min-width: 1280px) {
    font-size: 28px;
  }
`;

export const EditProfileContainer = styled.div`
  display: flex;
  width: 310px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 30px 0px 35px 0px;
  @media (min-width: 768px) {
    min-width: 695px;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin: 45px 0;
  }
  @media (min-width: 1280px) {
    margin: 60px 0;
    width: 100%;
  }
`;

export const EditProfileCategory = styled.div`
  font-size: 14px;
  text-decoration: none;
  outline: none;
  border: 0;
  color: #484857;
  cursor: pointer;
  padding-right: 4px;
  padding-left: 4px;
  border-bottom: ${(p) => (p.isActive ? '2px solid #c3c5cb;' : 'none')};
  padding-bottom: 6px;

  &:nth-child(2) {
    padding-top: 21px;
    order: 3;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    font-size: 18px;
    &:nth-child(2) {
      padding-top: 0px;
      margin: 0;
      order: unset;
    }
    padding-bottom: ${(p) => (p.isActive ? '8px' : '0px')};
  }
`;
