import { REGISTRATION_TYPES } from './RegistrationPage.types';


const initialState = {
  loading: false,
  error: null,
  cleanError: false,
  autopay: true
};

function registrationPage(state = initialState, action) {
  switch (action.type) {
    case REGISTRATION_TYPES.REGISTER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case REGISTRATION_TYPES.REGISTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case REGISTRATION_TYPES.REGISTER_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case REGISTRATION_TYPES.CLEAN_REGISTER_ERROR: {
      return {
        ...state,
        error: null,
        autopay: true
      };
    }
    case REGISTRATION_TYPES.TOGGLE_AUTOPAY: {
      return {
        ...state,
        autopay: action.status
      }
    }

    default:
      return state;
  }
}

export { registrationPage };
