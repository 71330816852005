import React from 'react'
import * as S from './CookieModal.styles'
import Cookies from 'js-cookie'
import { i18n } from '../../../i18n'

export const CookieModal = ({ hideModal }) => {
  const handleClick = () => {
    Cookies.set('allowCookies', true)
    setTimeout(() => {
      hideModal()
    }, 10)
  }

  const closeModal = () => {
    setTimeout(() => {
      hideModal()
    }, 10)
  }
  return (
    <S.Modal>
      <S.CookieModalContainer>
        <S.Image />
        <S.Header>{i18n.t('cookiesModal.header')}</S.Header>
        <S.Info>{i18n.t('cookiesModal.text')}</S.Info>
        <S.Accept onClick={handleClick}>
          {i18n.t('cookiesModal.accept')}
        </S.Accept>
        <S.CloseModalButton onClick={closeModal} />
      </S.CookieModalContainer>
    </S.Modal>
  )
}
