export const VPS_PAGE_TYPES = {
  GET_SERVER_LOCATION_REQUEST: 'VPS_PAGE_TYPES.GET_SERVER_LOCATION_REQUEST',
  GET_SERVER_LOCATION_SUCCESS: 'VPS_PAGE_TYPES.GET_SERVER_LOCATION_SUCCESS',
  GET_SERVER_LOCATION_FAIL: 'VPS_PAGE_TYPES.GET_SERVER_LOCATION_FAIL',
  GET_SERVER_OS_REQUEST: 'VPS_PAGE_TYPES.GET_SERVER_OS_REQUEST',
  GET_SERVER_OS_SUCCESS: 'VPS_PAGE_TYPES.GET_SERVER_OS_SUCCESS',
  GET_SERVER_OS_FAIL: 'VPS_PAGE_TYPES.GET_SERVER_OS_FAIL',
  GET_SERVER_SIZE_REQUEST: 'VPS_PAGE_TYPES.GET_SERVER_SIZE_REQUEST',
  GET_SERVER_SIZE_SUCCESS: 'VPS_PAGE_TYPES.GET_SERVER_SIZE_SUCCESS',
  GET_SERVER_SIZE_FAIL: 'VPS_PAGE_TYPES.GET_SERVER_SIZE_FAIL',
  HANDLE_FIXED_FOOTER_VISIBILITY:
    'VPS_PAGE_TYPES.HANDLE_FIXED_FOOTER_VISIBILITY',
};
