import styled from 'styled-components'
import { Field } from 'formik'
import { Link } from 'react-router-dom'

export const SecurityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  width: 100%;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 35px;
  width: 100%;
  @media (min-width: 768px) {
    padding: 0;
    align-items: center;
    width: 425px;
  }
  @media (min-width: 1280px) {
    width: 100%;
  }
  @media (min-width: 1500px) {
    width: 1200px;
  }
`

export const PasswordBlock = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1280px) {
    flex-direction: row;
    ${'' /* flex-wrap: wrap; */}
    justify-content: center;
    width: 100%;
    gap: 10px;
  }
`

export const CurrentPassword = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  width: 100%;

  &.radio {
    justify-content: center;
  }

  @media (min-width: 768px) {
    margin-top: 47px;
  }
  @media (min-width: 1280px) {
    width: unset;
    order: 1;
  }
`
export const NewPassword = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  width: 100%;

  &.radio {
    justify-content: center;
  }

  @media (min-width: 768px) {
    margin-top: 47px;
  }
  @media (min-width: 1280px) {
    width: unset;
    order: 3;
    margin-bottom: 50px;
    padding-right: 8px;
  }
`
export const ConfirmPassword = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  width: 100%;

  &.radio {
    justify-content: center;
  }

  @media (min-width: 768px) {
    margin-top: 47px;
  }
  @media (min-width: 1280px) {
    width: unset;
    order: 4;
    padding-left: 8px;
  }
`

export const Label = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;

  &.radio {
    margin: 0px;
    margin-left: 7px;
    opacity: 0.38;
  }

  @media (min-width: 768px) {
    font-size: 18px;
    margin-bottom: 17px;
  }
`

export const Input = styled(Field)`
  width: auto;
  padding: 10px;
  max-height: 44px;
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;

  @media (min-width: 1280px) {
    width: 385px;
  }

  &.radio {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    -webkit-appearance: none;
    border: 1px solid #d4d4d4;
    padding: 0px;
    cursor: pointer;

    &:checked {
      border-color: #dc773c;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      margin: 20% auto;
      border-radius: 50%;
      left: 4px;
      top: 0px;
      background: #d4d4d4;
    }

    &:checked:before {
      background: #dc773c;
    }
  }
`

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: red;
  font-size: 12px;
  margin-top: 5px;
  ${(p) => p.spaced && 'margin-top: 20px;'};
`

export const Notification = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #484857;
  margin: 30px 0px;
  @media (min-width: 1280px) {
    width: 405px;
    order: 2;
    max-height: 60px;
  }
`

export const Deactivate = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #fb1818;
`

export const ActivateLinkContainer = styled.div`
  margin-top: 30px;
  @media (min-width: 1280px) {
    margin-top: 0;
  }
`

export const ActivateLink = styled(Link)`
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #00c084;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (min-width: 768px) {
    justify-content: center;
  }
`

export const ConfirmButton = styled.button`
  width: 129px;
  height: 38px;
  border-radius: 5px;
  background: ${(p) => (p.disabled ? '#888e9b' : '#00c084')};
  margin-top: 52px;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    margin-right: 22px;
  }
`

export const CancelButton = styled.button`
  width: 129px;
  height: 38px;
  border-radius: 5px;
  background: #242e46;
  margin-top: 52px;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    margin-left: 22px;
  }
`
