import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  position: relative;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const Pages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  order: 2;
  @media (min-width: 768px) {
    order: 2;
  }
`;

export const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: white;
  border: ${(p) => (p.isActive ? '1px solid #00C084' : '1px solid #d4d4d4')};
  cursor: pointer;
  padding: 5px;
  height: 25px;
  width: 25px;
  margin-left: 3px;
  margin-right: 3px;
  &:hover {
    border: 1px solid #00c084;
  }
  @media (min-width: 768px) {
    padding: 10px;
    height: 40px;
    width: 40px;
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const Back = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  background-color: white;
  border: ${(p) => (p.isActive ? '1px solid #00C084' : '1px solid #d4d4d4')};
  cursor: pointer;
  height: 30px;
  width: 70px;
  border-radius: 3px;
  order: 1;
  position: absolute;
  top: 30px;
  left: 25%;
  font-size: 16px;
  &:hover {
    border: 1px solid #00c084;
  }
  @media (min-width: 768px) {
    ${(p) => !p.long && `width: 40px;`};
    height: 40px;
    margin-left: 5px;
    margin-right: 5px;
    order: 1;
    position: static;
    width: 80px;
    font-size: 18px;
  }
`;

export const Forward = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  background-color: white;
  border: ${(p) => (p.isActive ? '1px solid #00C084' : '1px solid #d4d4d4')};
  cursor: pointer;
  height: 30px;
  width: 70px;
  border-radius: 3px;
  order: 3;
  position: absolute;
  top: 30px;
  right: 25%;
  font-size: 16px;
  &:hover {
    border: 1px solid #00c084;
  }
  @media (min-width: 768px) {
    ${(p) => !p.long && `width: 40px;`};
    height: 40px;
    margin-left: 5px;
    margin-right: 5px;
    order: 3;
    position: static;
    width: 80px;
    font-size: 18px;
  }
`;
