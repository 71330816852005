import styled from 'styled-components';

export const Modal = styled.div`
  height: 100%;
  width: 310px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  outline: none;
  @media (min-width: 360px) {
    width: 350px;
  }
  @media (min-height: 500px) {
    max-height: 520px;
    overflow-y: none;
  }

  @media (min-width: 768px) {
    width: 750px;
  }
`;

export const RedirectToCpanelModalContainer = styled.div`
  display: flex;
  height: 300px;
  flex-direction: column;
  padding: 26px;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 1;
  z-index: 100;
  border-radius: 5px;
  position: relative;
  @media (min-width: 768px) {
    padding: 23px 42px;
  }
`;