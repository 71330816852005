import styled from 'styled-components';
import { Link } from 'react-router-dom';
import closeModal from '../../../images/closeModal.svg';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media (min-width: 1280px) {
    display: none;
  }
`;

export const Content = styled.div``;

export const MobileMenuContainer = styled.ul`
  display: flex;
  height: 100%;
  width: 250px;
  height: 100%;
  background-color: white;
  transform: translateX(-300px);
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style-type: none;
  justify-content: space-between;
  &.MobileMenu-enter-active {
    transform: translateX(-300px);
  }

  &.MobileMenu-enter {
    transform: translateX(-300px);
  }

  &.MobileMenu-enter-done {
    transform: translateX(0);
  }

  &.MobileMenu-exit {
    transform: translateX(0);
  }

  &.MobileMenu-exit-active {
    transform: translateX(-300px);
  }
  transition: 0.2s;
  @media (min-width: 768px) {
    width: 300px;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`;

export const CloseModalIcon = styled.div`
  background: url(${closeModal});
  width: 16px;
  height: 16px;
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
  background-size: cover;

  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`;

export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px 0px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0px;
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Service = styled.img`
  width: 150px;
  height: 60px;
  cursor: pointer;
`;
export const ServiceLink = styled(Link)``;
