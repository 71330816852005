import { HEADER_TYPES } from "./Header.types";

export const toggleMenu = () => ({
  type: HEADER_TYPES.TOGGLE_MENU,
});

export const hideMobileMenu = () => ({
  type: HEADER_TYPES.HIDE_MOBILE_MENU,
});

export const setOpaque = (status) => ({
  type: HEADER_TYPES.SET_OPAQUE,
  status,
});
