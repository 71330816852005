import FetchApi from '../../helpers/FetchApi'
import { INVOICE_TYPES } from './InvoicePage.types'
import { getUserCredit } from '../../common/App/App.actions'

export const getInvoice = (id, onSuccess, onFail) => async (dispatch) => {
  try {
    dispatch({
      type: INVOICE_TYPES.GET_INVOICE_REQUEST,
    })
    const req = await FetchApi.get('invoices/info', { invoiceId: id })
    dispatch({
      type: INVOICE_TYPES.GET_INVOICE_SUCCESS,
      data: req.data,
    })

    if (onSuccess) {
      onSuccess(req.data.invoice.status)
    }
  } catch (e) {
    dispatch({
      type: INVOICE_TYPES.GET_INVOICE_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    })
    if (onFail) {
      onFail()
    }
  }
}

export const getPaymentForm = (id) => async (dispatch) => {
  try {
    dispatch({
      type: INVOICE_TYPES.GET_PAYMENT_FORM_REQUEST,
    })
    const req = await FetchApi.get('invoices/getpayment', { invoiceid: id })
    dispatch({
      type: INVOICE_TYPES.GET_PAYMENT_FORM_SUCCESS,
      data: req.data,
    })
  } catch (e) {
    dispatch({
      type: INVOICE_TYPES.GET_PAYMENT_FORM_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    })
  }
}

export const setPaymentMethod = (id, gateway) => async (dispatch) => {
  try {
    dispatch({
      type: INVOICE_TYPES.GET_PAYMENT_FORM_REQUEST,
    })
    await FetchApi.post('invoices/setgateway', { invoiceid: id, gateway })
    dispatch({
      type: INVOICE_TYPES.CHANGE_PAYMENT_METHOD,
      gateway,
    })
    dispatch(getPaymentForm(id))
  } catch (e) {
    console.log('something went wrong')
  }
}

export const applyCredit = (id, amount) => async (dispatch) => {
  try {
    dispatch({
      type: INVOICE_TYPES.APPLY_CREDIT_REQUEST,
    })
    await FetchApi.post('invoices/applyCredit', {
      invoiceid: id,
      amount,
    })

    dispatch({
      type: INVOICE_TYPES.APPLY_CREDIT_SUCCESS,
      amount,
    })

    dispatch(getInvoice(id))
    dispatch(getPaymentForm(id))
    dispatch(getUserCredit())
  } catch (e) {
    dispatch({
      type: INVOICE_TYPES.APPLY_CREDIT_FAIL,
    })
  }
}

export const clearInvoice = () => ({
  type: INVOICE_TYPES.CLEAR_INVOICE,
})
