import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Contacts as Self } from './Contacts';
import { showModal } from '../../../../common/Modal/Modal.actions';
import { removeContact } from '../../../../components/Modals/LoginModal/LoginModal.actions';

const mapStateToProps = ({ loginModal }) => ({
  contacts: loginModal.contacts,
});

const mapDispatchToProps = {
  showModal,
  removeContact,
};

export const Contacts = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
