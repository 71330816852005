import React, { useEffect, useCallback, useState } from "react";
import { Formik } from "formik";

import { i18n } from "../../../i18n";
import * as S from "./DomainContactsModal.styles";
import { getConfig, getYup } from "./DomainContactsModal.config";
import { Loader } from "../../Loader";
import { ifError, errorMessage } from "./helper";
import { countries } from "../../../constants/countries";

export const DomainContactsModal = ({
  domainContacts,
  transformedContacts,
  domainContactsLoading,
  domain,
  getDomainContacts,
  setDomainContacts,
  hideModal,
  updateDomainContactsLoading,
  domainContactModalTab,
  changeDomainContactModalTab,
}) => {
  const [config, setConfig] = useState({});
  const [yupScheme, setYupScheme] = useState({});
  const [init, setInit] = useState(false);

  const handleEnter = useCallback((e, submitForm) => {
    if (e.key === "Enter") {
      submitForm();
    }
  }, []);
  useEffect(() => {
    if (!init) {
      getDomainContacts(domain.id);
      setInit(true);
    }
    const conf = getConfig(transformedContacts);
    setConfig(conf);
    const yup = getYup(transformedContacts, domain.domainname);
    setYupScheme(yup);
  }, [init, setInit, domainContacts, transformedContacts, getDomainContacts]);

  useEffect(() => {
    changeDomainContactModalTab(transformedContacts.registrant);
  }, [transformedContacts.registrant]);

  const domainNameSplitted = domain.domainname.split(".");
  const tld = domainNameSplitted[domainNameSplitted.length - 1];

  return (
    <S.Modal
      onClick={(e) => e.stopPropagation()}
      updateDomainContactsLoading={updateDomainContactsLoading}
    >
      <S.DomainContactsModalContainer>
        {/* <S.ModalTitle>{i18n.t("domainContactModal.title")}</S.ModalTitle> */}
        {/* <S.ModalHeader>{i18n.t("domainContactModal.header")}</S.ModalHeader> */}
        <S.Tabs>
          <S.Tab
            selected={domainContactModalTab === transformedContacts.registrant}
            onClick={() =>
              changeDomainContactModalTab(transformedContacts.registrant)
            }
          >
            {i18n.t("domainContactModal.tab1")}
          </S.Tab>

          <S.Tab
            selected={
              domainContactModalTab === transformedContacts.administrative
            }
            onClick={() =>
              changeDomainContactModalTab(transformedContacts.administrative)
            }
          >
            {i18n.t("domainContactModal.tab3")}
          </S.Tab>
          <S.Tab
            selected={domainContactModalTab === transformedContacts.technical}
            onClick={() =>
              changeDomainContactModalTab(transformedContacts.technical)
            }
          >
            {i18n.t("domainContactModal.tab2")}
          </S.Tab>
        </S.Tabs>
        <S.FormContainer>
          {domainContactsLoading ? (
            <Loader color="#00C084" size={0.5} />
          ) : (
            <Formik
              initialValues={domainContacts}
              validationSchema={yupScheme}
              onSubmit={async (values, { setSubmitting }) => {
                await setDomainContacts(
                  domain.id,
                  values,
                  transformedContacts,
                  tld
                );
                setSubmitting(false);
                setTimeout(() => {
                  hideModal();
                }, 10);
              }}
            >
              {({
                touched,
                errors,
                isSubmitting,
                handleChange,
                submitForm,
                setFieldValue,
                values,
                dirty,
              }) => (
                <S.StyledForm onKeyPress={() => handleEnter(submitForm)}>
                  <S.LeftSide>
                    {config &&
                      !!Object.keys(config).length &&
                      config[domainContactModalTab] &&
                      config[domainContactModalTab].map(
                        (item, index) =>
                          index % 2 === 0 &&
                          (item.editable ? (
                            <S.FormItem key={item.id}>
                              <S.Label>{i18n.t(item.name)}</S.Label>
                              <S.Input
                                name={item.id}
                                placeholder={item.placeholder}
                                onChange={handleChange}
                                values={values}
                              />
                              {ifError(item, errors, touched) && (
                                <S.ErrorMessage>
                                  {i18n.t(errorMessage(item, errors))}
                                </S.ErrorMessage>
                              )}
                            </S.FormItem>
                          ) : (
                            <S.FormItem key={item.id}>
                              <S.Label>{i18n.t(item.name)}</S.Label>
                              <S.UnEditableLabel>
                                {!!Object.keys(domainContacts).length &&
                                  domainContacts[domainContactModalTab][
                                    item.id.split(".")[1]
                                  ]}
                              </S.UnEditableLabel>
                            </S.FormItem>
                          ))
                      )}
                  </S.LeftSide>
                  <S.RightSide>
                    {config &&
                      !!Object.keys(config).length &&
                      config[domainContactModalTab] &&
                      config[domainContactModalTab].map(
                        (item, index) =>
                          index % 2 !== 0 &&
                          (item.editable ? (
                            <>
                              {item.name === "domainContactModal.n2" ? (
                                <S.FormItem>
                                  <S.Label>
                                    {i18n.t("domainContactModal.n2")}
                                  </S.Label>
                                  <S.StyledSelect
                                    value={countries?.find(
                                      (item) =>
                                        item.value ===
                                        values[domainContactModalTab].Country
                                    )}
                                    isSearchable={true}
                                    name={item.id}
                                    options={countries}
                                    onChange={(option) => {
                                      setFieldValue(
                                        `${domainContactModalTab}.Country`,
                                        option.value
                                      );
                                    }}
                                    classNamePrefix="react-select"
                                  />
                                </S.FormItem>
                              ) : (
                                <S.FormItem key={item.id}>
                                  <S.Label>{i18n.t(item.name)}</S.Label>
                                  <S.Input
                                    name={item.id}
                                    placeholder={item.placeholder}
                                    onChange={handleChange}
                                  />
                                  {ifError(item, errors, touched) && (
                                    <S.ErrorMessage>
                                      {i18n.t(errorMessage(item, errors))}
                                    </S.ErrorMessage>
                                  )}
                                </S.FormItem>
                              )}
                            </>
                          ) : (
                            <S.FormItem key={item.id}>
                              <S.Label>{i18n.t(item.name)}</S.Label>
                              <S.UnEditableLabel>
                                {!!Object.keys(domainContacts).length &&
                                  domainContacts[domainContactModalTab][
                                    item.id.split(".")[1]
                                  ]}
                              </S.UnEditableLabel>
                            </S.FormItem>
                          ))
                      )}
                    {domainContactModalTab ===
                      transformedContacts.administrative ||
                    domainContactModalTab === transformedContacts.technical ? (
                      <S.SubmitButton
                        type="submit"
                        disabled={isSubmitting || !dirty}
                      >
                        {updateDomainContactsLoading ? (
                          <Loader color="#FFF" size={0.5} />
                        ) : (
                          i18n.t("nameServerModal.submit")
                        )}
                      </S.SubmitButton>
                    ) : null}
                  </S.RightSide>
                </S.StyledForm>
              )}
            </Formik>
          )}
        </S.FormContainer>
        <S.CloseModalButton onClick={() => hideModal()} />
      </S.DomainContactsModalContainer>
    </S.Modal>
  );
};
