import { connect } from 'react-redux'

import { ConfirmEmailPage as Self } from './ConfirmEmailPage'
import { verifyHash } from './ConfirmEmailPage.actions'
import { showModal } from '../../common/Modal/Modal.actions'

const mapStateToProps = ({ confirmEmailPage }) => ({
  data: confirmEmailPage.data,
  loading: confirmEmailPage.loading,
  error: confirmEmailPage.error,
})

const mapDispatchToProps = {
  verifyHash,
  showModal,
}

export const ConfirmEmailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self)
