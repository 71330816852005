import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

import * as S from './ManageFreeDNSModal.styles'
import { Loader } from '../../../components/Loader'
import { i18n } from '../../../i18n'

export const ManageFreeDNSModal = ({
  hideModal,
  hostingId,
  getDNSZones,
  setDNSZones,
  manageFreeDNSZones,
  manageFreeDNSZonesLoading,
  updateDNSZonesLoading,
}) => {
  const [step, setStep] = useState(1)
  const [dnsRecords, setDnsRecords] = useState([])
  const [errors, setErrors] = useState([])
  const [advancedView, setAdvancedView] = useState(false)

  useEffect(() => {
    getDNSZones(hostingId)
  }, [])

  useEffect(() => {
    if (manageFreeDNSZones?.length) {
      setDnsRecords(
        manageFreeDNSZones.map((item) => ({ ...item, id: uuidv4() }))
      )
    }
  }, [manageFreeDNSZones])

  const handleSetZoneValue = (id, key, value) => {
    const dnsRecordsCopy = _.cloneDeep(dnsRecords)
    const record = dnsRecordsCopy.findIndex((item) => item.id === id)
    dnsRecordsCopy[record][key] = value
    if (key === 'type' && value === 'MX') {
      dnsRecordsCopy[record]['priority'] = 0
    }
    setDnsRecords(dnsRecordsCopy)
  }

  const handleDeleteRecord = (id) => {
    const dnsRecordsCopy = _.cloneDeep(dnsRecords)
    setDnsRecords(dnsRecordsCopy.filter((item) => item.id !== id))
  }

  const handleAddRecord = () => {
    const dnsRecordsCopy = _.cloneDeep(dnsRecords)
    dnsRecordsCopy.push({
      id: uuidv4(),
      name: '',
      type: '',
      value: '',
      ttl: 3600,
    })
    setDnsRecords(dnsRecordsCopy)
  }

  const validateDNSRecords = (dnsRecords) => {
    const errors = []
    const seenRecords = new Set()

    dnsRecords.forEach((record, index) => {
      if (!record.name || !record.ttl || !record.value || !record.type) {
        errors.push(`Record ${index + 1} is missing required fields.`)
      }

      if (
        record.type !== 'NS' &&
        record.type !== 'MX' &&
        record.type !== 'CAA' &&
        record.type !== 'TXT' &&
        record.type !== 'SRV'
      ) {
        const uniqueKey = `${record.name}-${record.type}`
        if (seenRecords.has(uniqueKey)) {
          errors.push(
            `Duplicate record found: ${record.name} with type ${record.type}.`
          )
        } else {
          seenRecords.add(uniqueKey)
        }
      }

      switch (record.type) {
        case 'A':
          if (!/^(\d{1,3}\.){3}\d{1,3}$/.test(record.value)) {
            errors.push(`Invalid IPv4 address in record ${index + 1}.`)
          }
          break
        case 'AAAA':
          if (!/^[a-fA-F0-9:]+$/.test(record.value)) {
            errors.push(`Invalid IPv6 address in record ${index + 1}.`)
          }
          break
        case 'CNAME':
          if (!/^[a-zA-Z0-9.-]+$/.test(record.value)) {
            errors.push(`Invalid domain name in record ${index + 1} for CNAME.`)
          }
          break
        case 'MX':
          if (!/^[a-zA-Z0-9.-]+$/.test(record.value)) {
            errors.push(`Invalid domain name in record ${index + 1} for MX.`)
          }
          if (typeof record.priority !== 'number') {
            errors.push(
              `Missing or invalid priority in MX record ${index + 1}.`
            )
          }
          break
        default:
          break
      }

      if (typeof record.ttl !== 'number' || record.ttl <= 0) {
        errors.push(`Invalid TTL value in record ${index + 1}.`)
      }
    })

    return errors
  }

  const handleSave = () => {
    const dnsRecordsCopy = _.cloneDeep(dnsRecords)
    dnsRecordsCopy.forEach((record) => {
      delete record.id
    })

    const errors = validateDNSRecords(dnsRecordsCopy)
    if (errors.length > 0) {
      setErrors(errors)
      return
    }

    setDNSZones(hostingId, dnsRecordsCopy, () => {
      setStep(2)
    })
  }

  if (manageFreeDNSZonesLoading || updateDNSZonesLoading) {
    return (
      <S.Modal>
        <S.ModalInner>
          <Loader color="#00C084" size={0.5} />
        </S.ModalInner>
      </S.Modal>
    )
  }

  if (step === 1 && dnsRecords?.length) {
    return (
      <S.Modal>
        <S.ModalInner>
          <S.ModalTitle>
            {i18n.t('manageFreeDNSModal.title')}{' '}
            <button
              style={{ color: 'blue', textDecoration: 'underline' }}
              onClick={() => setAdvancedView((p) => !p)}
            >
              {advancedView ? 'Simple view' : 'Advanced view'}
            </button>
          </S.ModalTitle>
          <S.ZonesContainer>
            <S.Row>
              <div style={{ width: advancedView ? '255px' : '400px' }}>
                Name
              </div>
              <div style={{ width: advancedView ? '100px' : '200px' }}>
                Type
              </div>
              <div style={{ width: advancedView ? '100px' : '200px' }}>TTL</div>
              <div style={{ width: advancedView ? '255px' : '400px' }}>
                Value
              </div>
              {advancedView && (
                <>
                  <div style={{ width: '100px' }}>Priority</div>
                  <div style={{ width: '55px' }}>Weight</div>
                  <div style={{ width: '55px' }}>Port</div>
                  <div style={{ width: '55px' }}>Flags</div>
                  <div style={{ width: '100px' }}>Tag</div>
                </>
              )}
              <div style={{ width: '30px' }} />
            </S.Row>
            {dnsRecords.map((item) => (
              <S.Row key={item.id}>
                <S.Input
                  type="text"
                  value={item.name}
                  placeholder="name"
                  onChange={(event) =>
                    handleSetZoneValue(item.id, 'name', event.target.value)
                  }
                  style={{
                    width: advancedView ? '255px' : '400px',
                    border:
                      item.name.trim() === '' ||
                      !/^[a-zA-Z0-9._-]*$/.test(item.name)
                        ? '1px solid red'
                        : '1px solid #ccc',
                  }}
                />
                <select
                  style={{ width: advancedView ? '100px' : '200px' }}
                  value={item.type}
                  onChange={(event) =>
                    handleSetZoneValue(item.id, 'type', event.target.value)
                  }
                >
                  <option value=""></option>
                  <option value="A">A</option>
                  <option value="AAAA">AAAA</option>
                  <option value="TXT">TXT</option>
                  <option value="CNAME">CNAME</option>
                  <option value="MX">MX</option>
                  <option value="NS">NS</option>
                  <option value="SRV">SRV</option>
                  <option value="CAA">CAA</option>
                </select>
                <select
                  style={{ width: advancedView ? '100px' : '200px' }}
                  value={item.ttl}
                  onChange={(event) =>
                    handleSetZoneValue(
                      item.id,
                      'ttl',
                      Number(event.target.value)
                    )
                  }
                >
                  <option value="30">30</option>
                  <option value="60">60</option>
                  <option value="300">300</option>
                  <option value="600">600</option>
                  <option value="3600">3600</option>
                  <option value="14400">14400</option>
                  <option value="86400">86400</option>
                  <option value="604800">604800</option>
                </select>
                <S.Textarea
                  type="text"
                  value={item.value}
                  placeholder="value"
                  onChange={(event) =>
                    handleSetZoneValue(item.id, 'value', event.target.value)
                  }
                  style={{
                    width: advancedView ? '255px' : '400px',
                    border:
                      item.value.trim() === '' || !/^[\s\S]*$/.test(item.value)
                        ? '1px solid red'
                        : '1px solid #ccc',
                  }}
                />
                {advancedView && (
                  <>
                    {item.type === 'MX' ? (
                      <select
                        value={item.priority}
                        onChange={(event) =>
                          handleSetZoneValue(
                            item.id,
                            'priority',
                            Number(event.target.value)
                          )
                        }
                        style={{
                          width: '100px',
                        }}
                      >
                        <option value="0">0</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    ) : (
                      <div style={{ width: '100px' }} />
                    )}
                    {item.type === 'SRV' ? (
                      <S.Input
                        type="number"
                        value={item.weight}
                        placeholder="weight"
                        onChange={(event) =>
                          handleSetZoneValue(
                            item.id,
                            'weight',
                            Number(event.target.value)
                          )
                        }
                        style={{
                          width: '100px',
                          border:
                            item.value.trim() === '' ||
                            !/^[\s\S]*$/.test(item.value)
                              ? '1px solid red'
                              : '1px solid #ccc',
                        }}
                      />
                    ) : (
                      <div style={{ width: '100px' }} />
                    )}
                    {item.type === 'SRV' ? (
                      <S.Input
                        type="number"
                        value={item.port}
                        placeholder="port"
                        onChange={(event) =>
                          handleSetZoneValue(
                            item.id,
                            'port',
                            Number(event.target.value)
                          )
                        }
                        style={{
                          width: '50px',
                          border:
                            item.value.trim() === '' ||
                            !/^[\s\S]*$/.test(item.value)
                              ? '1px solid red'
                              : '1px solid #ccc',
                        }}
                      />
                    ) : (
                      <div style={{ width: '50px' }} />
                    )}
                    {item.type === 'CAA' ? (
                      <S.Input
                        type="text"
                        value={item.flags}
                        placeholder="flags"
                        onChange={(event) =>
                          handleSetZoneValue(
                            item.id,
                            'flags',
                            event.target.value
                          )
                        }
                        style={{
                          width: '50px',
                          border:
                            item.value.trim() === '' ||
                            !/^[\s\S]*$/.test(item.value)
                              ? '1px solid red'
                              : '1px solid #ccc',
                        }}
                      />
                    ) : (
                      <div style={{ width: '50px' }} />
                    )}
                    {item.type === 'CAA' ? (
                      <select
                        value={item.tag}
                        onChange={(event) =>
                          handleSetZoneValue(item.id, 'tag', event.target.value)
                        }
                        style={{
                          width: '100px',
                        }}
                      >
                        <option value="issue">issue</option>
                        <option value="issuewild">issuewild</option>
                        <option value="iodef">iodef</option>
                      </select>
                    ) : (
                      <div style={{ width: '100px' }} />
                    )}
                  </>
                )}
                <S.Delete onClick={() => handleDeleteRecord(item.id)} />
              </S.Row>
            ))}
            <S.Button onClick={() => handleAddRecord()}>Add Record</S.Button>
          </S.ZonesContainer>
          {errors.map((item) => (
            <S.Row>{item}</S.Row>
          ))}
          <S.SaveButton onClick={() => handleSave()}>Save</S.SaveButton>
        </S.ModalInner>
      </S.Modal>
    )
  }

  if (step === 2) {
    return (
      <S.Modal>
        <S.ModalInner>
          <S.Image />
          <S.Header>{i18n.t('manageFreeDNSModal.header')}</S.Header>
          <S.CloseModalButton onClick={hideModal} />
        </S.ModalInner>
      </S.Modal>
    )
  }
  return null
}
