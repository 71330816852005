import styled from 'styled-components'

export const MainBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`

export const CartContainer = styled.div`
  display: none;
  flex-direction: column;
  position: relative;
  @media (min-width: 768px) {
    display: flex;
  }
`

export const ChoosedItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  width: 300px;
  background: #eeeeee;

  @media (min-width: 768px) {
    justify-content: space-between;
    margin-top: 43px;
  }
  @media (min-width: 1280px) {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
  }
`

export const OrderButton = styled.div`
  background: #eeeeee;
  border-radius: 5px;
  width: 182px;
  height: 38px;
  border: none;
  margin-top: 21px;
  align-self: flex-end;
`

export const OptionsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 46px;
  width: 100%;

  @media (min-width: 768px) {
    margin-top: 43px;
    width: unset;
  }

  @media (min-width: 1280px) {
    margin-top: 70px;
    width: 700px;
  }
`

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`
export const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  border-radius: 5px;

  @media (min-width: 768px) {
    margin-bottom: 18px;
  }
  @media (min-width: 1280px) {
    flex-direction: row;
    background: #eeeeee;
  }
`

export const ListItemTopContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 90px;
  background: #eeeeee;

  @media (min-width: 768px) {
    align-items: flex-end;
    min-width: 370px;
  }

  @media (min-width: 1280px) {
    padding-right: 80px;
    height: 71px;
  }
`

export const AddButton = styled.div`
  background: #eeeeee;
  border-radius: 5px;
  border: none;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 22px;
  height: 59px;

  @media (min-width: 1280px) {
    width: auto;
    min-width: 128px;
    margin-top: 0px;
    margin-right: 45px;
    margin-left: 10px;
    margin-bottom: 0px;
  }
`
export const ShowMore = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`
export const ShowMoreButton = styled.div`
  background: #eeeeee;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  order: 2;
  margin-left: 20px;
  margin-right: 20px;
  border: 0;
  height: 39px;
  @media (min-width: 768px) {
    margin-top: 35px;
    margin-right: 0;
    margin-left: 0;
    order: 1;
    width: 183px;
    height: 39px;
  }
  @media (min-width: 1280px) {
    margin-top: 14px;
    order: 1;
    width: 227px;
    height: 49px;
  }
`
