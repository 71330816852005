export const EDIT_PROFILE_TYPES = {
  CHANGE_TAB: 'EDIT_PROFILE_TYPES.CHANGE_TAB',
  CHANGE_PASSWORD_REQUEST: 'EDIT_PROFILE_TYPES.CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'EDIT_PROFILE_TYPES.CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAIL: 'EDIT_PROFILE_TYPES.CHANGE_PASSWORD_FAIL',
  GET_ACTIVATE_STATUS_REQUEST: 'EDIT_PROFILE_TYPES.GET_ACTIVATE_STATUS_REQUEST',
  GET_ACTIVATE_STATUS_FAIL: 'EDIT_PROFILE_TYPES.GET_ACTIVATE_STATUS_FAIL',
  GET_ACTIVATE_STATUS_SUCCESS: 'EDIT_PROFILE_TYPES.GET_ACTIVATE_STATUS_SUCCESS',
  DISABLE_ACTIVATE_REQUEST: 'EDIT_PROFILE_TYPES.DISABLE_ACTIVATE_REQUEST',
  DISABLE_ACTIVATE_SUCCESS: 'EDIT_PROFILE_TYPES.DISABLE_ACTIVATE_SUCCESS',
  DISABLE_ACTIVATE_FAIL: 'EDIT_PROFILE_TYPES.DISABLE_ACTIVATE_FAIL',
};
