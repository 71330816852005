import React from 'react'
import PropTypes from 'prop-types'

import * as S from './QuestionCard.styles'
import { i18n } from '../../i18n'
import { Trans } from 'react-i18next'

export const QuestionCard = ({ faq, activeId, changeExpand }) => {
  const isOpen = activeId === faq.id

  return (
    <S.QuestionContainer isOpen={isOpen} onClick={() => changeExpand(faq.id)}>
      <S.QuestionPart>
        <S.QuestionText>{i18n.t(faq.question)}</S.QuestionText>
        <S.ToggleButton isOpen={isOpen} />
      </S.QuestionPart>
      <S.Answer
        isOpen={isOpen}
        dangerouslySetInnerHTML={
          !faq.isMulti ? { __html: i18n.t(faq.answer) } : null
        }
      >
        {faq.isMulti ? (
          <div>
            <h4>{i18n.t('sslConfigurationPage.header')}</h4>
            <ol>
              <i>
                <b>
                  <li>{i18n.t('sslConfigurationPage.howTo1')}</li>
                </b>
              </i>
              <ul>
                <li>{i18n.t('sslConfigurationPage.steps1.step1')}</li>
                <li>{i18n.t('sslConfigurationPage.steps1.step2')}</li>
                <li>{i18n.t('sslConfigurationPage.steps1.step3')}</li>

                <li>
                  <Trans
                    i18nkey="sslConfigurationPage.steps1.step4"
                    defaults={i18n.t('sslConfigurationPage.steps1.step4')}
                    components={{ b: <strong />, i: <i /> }}
                  />
                </li>
                <li>
                  <Trans
                    i18nkey="sslConfigurationPage.steps1.step5"
                    defaults={i18n.t('sslConfigurationPage.steps1.step5')}
                    components={{ b: <strong />, i: <i /> }}
                  />
                </li>
                <li>
                  <Trans
                    i18nkey="sslConfigurationPage.steps1.step6"
                    defaults={i18n.t('sslConfigurationPage.steps1.step6')}
                    components={{ i: <i /> }}
                  />
                </li>
              </ul>
              <i>
                <b>
                  <li>{i18n.t('sslConfigurationPage.howTo2')}</li>
                </b>
              </i>
              <ul>
                <li>{i18n.t('sslConfigurationPage.steps2.step1')}</li>
                <li>{i18n.t('sslConfigurationPage.steps2.step2')}</li>
                <li>{i18n.t('sslConfigurationPage.steps2.step3')}</li>
                <li>{i18n.t('sslConfigurationPage.steps2.step4')}</li>
                <li>{i18n.t('sslConfigurationPage.steps2.step5')}</li>
                <li>{i18n.t('sslConfigurationPage.steps2.step6')}</li>
                <li>{i18n.t('sslConfigurationPage.steps2.step7')}</li>
                {i18n.exists('sslConfigurationPage.steps2.step8') ? (
                  <li>{i18n.t('sslConfigurationPage.steps2.step8')}</li>
                ) : null}
              </ul>
              <i>
                <b>
                  <p>{i18n.t('sslConfigurationPage.sub')}</p>
                </b>
              </i>
            </ol>
          </div>
        ) : null}
      </S.Answer>
    </S.QuestionContainer>
  )
}

QuestionCard.propTypes = {
  question: PropTypes.object.isRequired,
  activeid: PropTypes.number,
  changeExpand: PropTypes.func.isRequired,
}
