import { DomainsPage as Self } from './DomainsPage'
import { connect } from 'react-redux'
import {
  setDomain,
  setDomainsData,
  domainLookup,
  moreDomainLookup,
  toggleMobileCart,
  toggleUnavailableDomains,
} from './DomainsPage.actions'
import { addToCart, removeFromCart } from '../../common/App/App.actions'
import { withTranslation } from 'react-i18next'
import { numberWithCommas } from '../../common/App/App.actions'
import { getDistinctDomains } from './DomainsPage.helpers'
import { cutTld } from '../../pages/HomePage/components/SearchAreaBlock/SearchAreaBlock.actions'
import { showModal } from '../../common/Modal/Modal.actions'

const mapStateToProps = ({ domainsPage, app, searchAreaBlock }) => ({
  auth: app.auth,
  domain: domainsPage.domain,
  domainsData: getDistinctDomains(domainsPage.domainsData),
  domainsLoading: domainsPage.domainsLoading,
  moreDomainsLoading: domainsPage.moreDomainsLoading,
  offset: domainsPage.domainsOffset,
  domainRequestSuccess: domainsPage.domainRequestSuccess,
  domainsTotal: domainsPage.domainsTotal,
  domainRequestPart2Loading: domainsPage.domainRequestPart2Loading,
  moreDomainRequestPart2Loading: domainsPage.moreDomainRequestPart2Loading,
  mobileCartIsExpanded: domainsPage.mobileCartIsExpanded,
  tlds: searchAreaBlock.tlds,
  domainListExpanded: domainsPage.domainListExpanded,
})

const mapDispatchToProps = {
  setDomainsData,
  setDomain,
  domainLookup,
  moreDomainLookup,
  addToCart,
  removeFromCart,
  toggleMobileCart,
  numberWithCommas,
  toggleUnavailableDomains,
  cutTld,
  showModal,
}

export const DomainsPage = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
)
