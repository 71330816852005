import React from 'react';
import * as S from './HostingInfoBlock.styles';
import { i18n } from '../../../../i18n';

export const HostingInfoBlock = () => (
  <S.InfoBlockContainer>
    <S.InfoBlock>
      <S.HostingPackageTitle>
        {i18n.t('hostingInfoBlock.title')}
      </S.HostingPackageTitle>
      {/* <S.HostingPackageInfo>
        {i18n.t('hostingInfoBlock.packageInfo')}
      </S.HostingPackageInfo> */}
      {/* <S.MoreInfo to={changeUrl('/hosting-packages')}>
        {i18n.t('hostingInfoBlock.more')}
      </S.MoreInfo> */}
    </S.InfoBlock>
  </S.InfoBlockContainer>
);
