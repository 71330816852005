import { omit } from 'lodash';

import FetchApi from '../../helpers/FetchApi';
import { OPEN_TICKET_TYPES } from './OpenTicketPage.types';

export const openTicket = (values, onSuccess) => async (dispatch) => {
  const ommitedValues = omit(values, ['files']);
  try {
    dispatch({
      type: OPEN_TICKET_TYPES.OPEN_TICKET_REQUEST,
    });

    
    if (values.files.length > 0) {
      const req = await FetchApi.upload(values.files);
      await FetchApi.post('tickets/openTicket', {
        ...ommitedValues,
        ...req.data,
      });
    } else {
      await FetchApi.post('tickets/openTicket', ommitedValues);
    }

    dispatch({
      type: OPEN_TICKET_TYPES.OPEN_TICKET_SUCCESS,
    });
    if (onSuccess) {
      onSuccess();
    }
    return true;
  } catch (e) {
    dispatch({
      type: OPEN_TICKET_TYPES.OPEN_TICKET_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    });
    return false;
  }
};
