import React, { useRef } from 'react'

import * as S from './SettingsPopup.styles'
import { useOnClickOutside } from '../../../../../../hooks/useOnClickOutside'
import { NameServerModal } from '../../../../../../components/Modals/NameServerModal'
import { DomainContactsModal } from '../../../../../../components/Modals/DomainContactsModal'
import { RenewDomainModal } from '../../../../../../components/Modals/RenewDomainModal'
import { ConfirmModal } from '../../../../../../components/Modals/ConfirmModal'
import { i18n } from '../../../../../../i18n'

export const SettingsPopup = ({
  showSettingsPopup,
  isShown,
  popupId,
  showModal,
  id,
  domain,
  changeIdProtection,
}) => {
  const containerRef = useRef(null)
  useOnClickOutside(containerRef, () => showSettingsPopup(false))
  if (isShown !== popupId) {
    return null
  }
  return (
    <S.SettingsPopup ref={containerRef}>
      <S.Status
        onClick={() =>
          showModal(NameServerModal, {
            id,
            domain,
            shouldGetNameservers: true,
            largeModal: true,
          })
        }
      >
        {i18n.t('domainTableSettingsPopup.ns')}
      </S.Status>
      {(domain.domainname.endsWith('.am') ||
        domain.domainname.endsWith('.հայ')) &&
        domain.status === 'Active' && (
          <S.Status
            onClick={() =>
              showModal(DomainContactsModal, { id, domain, largeModal: true })
            }
          >
            {i18n.t('domainTableSettingsPopup.changeDomainContacts')}
          </S.Status>
        )}
      <S.Status
        onClick={() => showModal(RenewDomainModal, { domain, domainid: id })}
      >
        {i18n.t('domainTableSettingsPopup.renew')}
      </S.Status>
      <S.Status
        onClick={() =>
          showModal(ConfirmModal, {
            onSuccessFn: () =>
              changeIdProtection(id, domain.idprotection === '1' ? '0' : '1'),
            modalType: 'success',
            successBtnTextKey: 'domainTableSettingsPopup.modalDeleteBtn',
            subheaderKey: 'domainTableSettingsPopup.modalDeleteSubtitle',
          })
        }
      >
        {domain.idprotection === '1' ? (
          <div>{i18n.t('domainTableSettingsPopup.deactivateProtectId')}</div>
        ) : (
          <div>{i18n.t('domainTableSettingsPopup.activateProtectId')}</div>
        )}
      </S.Status>
    </S.SettingsPopup>
  )
}
