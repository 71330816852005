import { RENEW_DOMAIN_TYPES } from './RenewDomainModal.types';

const initialState = {
  error: null,
  isRenewing: false,
};

export const renewDomains = (state = initialState, action) => {
  switch (action.type) {
    case RENEW_DOMAIN_TYPES.RENEW_DOMAIN_REQUEST:
      return {
        ...state,
        isRenewing: true,
      };

    case RENEW_DOMAIN_TYPES.RENEW_DOMAIN_SUCCESS: {
      return {
        ...state,
        isRenewing: false,
      };
    }
    case RENEW_DOMAIN_TYPES.RENEW_DOMAIN_FAIL:
      return {
        ...state,
        isRenewing: false,
      };
    default:
      return state;
  }
};
