import styled from 'styled-components'
import { Form, Field } from 'formik'

import HeaderSm from '../../images/vps-header-sm.png'
import HeaderMd from '../../images/vps-header-md.png'
import HeaderLg from '../../images/vps-header-lg.png'
import CloudsSm from '../../images/Vps-clouds-sm.png'
import CloudsMd from '../../images/Vps-clouds-md.png'
import CloudsLg from '../../images/Vps-clouds-lg.png'

export const PageBody = styled.div`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 75px;
  margin-top: -1px;
  min-height: 100vh;
`

export const PageWrapper = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  background-color: #ffffff;
  @media (min-width: 768px) {
    margin-top: -77px;
  }
`
export const HeaderContainer = styled.div`
  background-image: url(${HeaderSm});
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.1);
  background-size: cover;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 381px;
  @media (min-width: 768px) {
    background-image: url(${HeaderMd});
    height: 630px;
  }
  @media (min-width: 1280px) {
    height: 800px;
    background-image: url(${HeaderLg});
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  margin-bottom: 45px;
  margin-top: 80px;
  @media (min-width: 768px) {
    margin: 80px 0px;
  }
  @media (min-width: 1280px) {
    margin: 0px;
  }
`

export const VpsTitle = styled.h1`
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  @media (min-width: 768px) {
    font-size: 48px;
  }
  @media (min-width: 1280px) {
    font-size: 72px;
  }
`

export const VpsSTitle = styled.h2`
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (min-width: 768px) {
    font-size: 24px;
  }
  @media (min-width: 1280px) {
    font-size: 36px;
  }
`

export const ServersLocationHeader = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #3f3d56;
  margin-bottom: 45px;
  @media (min-width: 768px) {
    font-size: 36px;
    margin-bottom: 0px;
  }
  @media (min-width: 1280px) {
    font-size: 36px;
    margin-top: -90px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (min-width: 1280px) {
    width: 90%;
  }
`

export const RelativeWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 200px;
`

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 80px;
  }
  @media (min-width: 1280px) {
    margin-top: 90px;
  }
`
export const ServerLocation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  background: ${(p) => (p.isActive ? '#1d9ef9' : '#ffffff')};
  width: 90%;
  margin-bottom: 20px;
  cursor: pointer;

  > div > div {
    color: ${(p) => (p.isActive ? '#ffffff' : '#212121;')};
  }

  @media (min-width: 768px) {
    width: 40%;
    margin: 0px 25px 30px 25px;
  }
  @media (min-width: 1280px) {
    width: 23%;
    margin: 10px;
  }
`

export const Flag = styled.img`
  width: 45.56px;
  height: 43px;
  border-radius: 50%;
  margin: 30px 20px 30px 30px;
  box-shadow: 0px 0px 5px #ccc;
`

export const Location = styled.div`
  display: flex;
  flex-direction: column;
`
export const City = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #212121;
`
export const Country = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #212121;
`

export const ServerTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 80px;
  }
  @media (min-width: 1280px) {
    margin-top: 90px;
  }
`

export const TypeHeader = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #3f3d56;
  margin: 40px 0px;

  @media (min-width: 768px) {
    font-size: 36px;
    margin-top: 80px;
    margin-bottom: 0px;
  }
  @media (min-width: 1280px) {
    margin-top: 90px;
  }
`

export const TypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(p) => (p.isActive ? '#1d9ef9' : '#ffffff')};
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  width: 90%;
  margin-bottom: 20px;
  cursor: pointer;
    > div > div {
      color: ${(p) => (p.isActive ? '#ffffff' : '#212121;')}
    }
  }
  @media (min-width: 768px) {
    width: 40%;
    margin: 0px 25px 30px 25px;
  }
  @media (min-width: 1280px) {
    width: 23%;
    margin: 10px;
  }
`

export const TypeImg = styled.img`
  width: 48px;
  height: 48px;
  margin: 30px 20px 30px 30px;
`

export const TypeBox = styled.div`
  display: flex;
  flex-direction: column;
`

export const TypeName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #212121;
`

export const TypeProps = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #212121;
`
export const SizeHeader = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #3f3d56;
  margin: 40px 0px;
  @media (min-width: 768px) {
    font-size: 36px;
    margin: 80px 0px;
  }
  @media (min-width: 1280px) {
    margin: 60px 0px;
  }
`

export const ServerSizeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`

export const SizeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(p) => (p.isActive ? '#1d9ef9' : '#ffffff')};
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  width: 85%;
  margin-bottom: 20px;
  cursor: pointer;
  > div {
    color: ${(p) => (p.isActive ? '#ffffff' : '#212121')};
  }
  > div:nth-child(3) {
    border: ${(p) => (p.isActive ? '1px solid #ffffff' : '1px solid #242e46')};
  }

  @media (min-width: 768px) {
    width: 40%;
    margin: 0px 25px 30px 25px;
  }
  @media (min-width: 1280px) {
    width: 23%;
    margin: 10px;
    height: 345px;
  }
`

export const ServerName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #242e46;
  margin-top: 30px;
  text-align: center;
`
export const ServerPrice = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #242e46;
  font-family: sans-serif;
  margin-top: 17px;
  text-align: center;
  > span {
    font-weight: normal;
  }
`

export const ServerPriceH = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #242e46;
  margin-top: 8px;
`

export const Line = styled.div`
  heigth: 0px;
  width: 40px;
  border: 1px solid #242e46;
  margin-top: 55px;
`
export const ServerCpu = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #242e46;
  margin-top: 45px;
`

export const ServerMemory = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #242e46;
  margin-top: 10px;
`
export const ServerSpace = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: #242e46;
  margin-top: 8px;
  margin-bottom: 50px;
`

export const HostnameLabel = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #3f3d56;
  margin: 40px 0px;
  @media (min-width: 768px) {
    font-size: 36px;
    margin: 50px 0px;
  }
  @media (min-width: 768px) {
    font-size: 36px;
    margin: 70px 0px;
  }
`

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const UpperBlockWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  @media (min-width: 1280px) {
    flex-direction: row;
    justify-content: center;
  }
`

export const Input = styled(Field)`
  padding: 20px;
  max-height: 60px;
  outline: none;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #737373;
`

export const UpperBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 35px;
  width: 100%;
  position: relative;
  @media (min-width: 768px) {
    margin-top: 50px;
  }
`

export const CloudsImg = styled.div`
  width: 340px;
  height: 187px;
  background-image: url(${CloudsSm});
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: 768px) {
    width: 744px;
    height: 437px;
    background-image: url(${CloudsMd});
  }
  @media (min-width: 1280px) {
    width: 1102px;
    height: 673px;
    background-image: url(${CloudsLg});
  }
`
export const Feedback = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 55px;
  right: 15px;
  @media (min-width: 768px) {
    top: 130px;
    right: 130px;
  }
  @media (min-width: 1280px) {
    top: 200px;
    right: 200px;
  }
`
export const Questions = styled.div`
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #484857;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    font-size: 36px;
    margin-bottom: 30px;
  }
  @media (min-width: 1280px) {
    font-size: 48px;
    margin-bottom: 20px;
  }
`
export const WriteUs = styled.div`
  font-weight: normal;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  font-weight: 300;
  color: #484857;
  width: 140px;
  @media (min-width: 768px) {
    font-size: 24px;
    width: unset;
  }
  @media (min-width: 1280px) {
    font-size: 36px;
  }
`

export const BorderLine = styled.div`
  width: 100%;
  height: 3.16px;
  background: #161251;
  margin-top: -3px;
  @media (min-width: 768px) {
    height: 7px;
    margin-top: -4px;
  }
  @media (min-width: 768px) {
    margin-top: -5px;
  }
`

export const Panel = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
  position: ${(p) => (p.shouldPanelBeFixed ? 'fixed' : 'absolute')};
  top: ${(p) => (p.shouldPanelBeFixed ? 'unset' : '210px')};
  left: 0;
  right: 0;
  ${(p) => p.shouldPanelBeFixed && `bottom: 0;`};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  z-index: 1;
  @media (min-width: 768px) {
    top: ${(p) => (p.shouldPanelBeFixed ? 'unset' : '230px')};
  }
  @media (min-width: 1280px) {
    top: ${(p) => (p.shouldPanelBeFixed ? 'unset' : '150px')};
  }
`
export const ServerQ = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 18px 0px 18px 20px;
    padding-bottom: 20px;
  }
`

export const Header = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #c4c4c4;
  margin-bottom: 8px;
`

export const Counter = styled.div`
  width: 150px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Count = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #1d9ef9;
  user-select: none;
`

export const Decrement = styled.div`
  border: 2px solid #c0c0c0;
  width: 16px;
  height: 0px;
  margin-left: 20px;
  pointer-events: ${(p) => (p.disabled ? 'none' : 'default')};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  user-select: none;
`
export const Increment = styled.div`
  color: #c0c0c0;
  margin-right: 20px;
  font-size: 36px;
  pointer-events: ${(p) => (p.disabled ? 'none' : 'default')};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  user-select: none;
`

export const ServerProps = styled.div`
  display: none;
  @media (min-width: 1280px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 18px 5px;
    height: 60px;
    width: 185px;
    padding-bottom: 20px;
  }
`

export const ServerDescription = styled.div`
  font-style: normal;
  font-family: sans-serif;
  font-weight: normal;
  font-size: 24px;
  color: #000000;
`

export const ServerPriceContainer = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 18px 5px;
    height: 60px;
    width: 185px;
    padding-bottom: 20px;
  }
`

export const RegisterButton = styled.button`
  width: 100%;
  padding: 16px 0px;
  background: ${(p) => (p.disabled ? '#888e9b' : '#1d9ef9')};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  @media (min-width: 768px) {
    width: 185px;
    margin-right: 80px;
  }
`

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
  ${(p) => p.spaced && 'margin-top: 5px;'};
`
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    height: 60px;
    width: 45%;
    margin-bottom: 40px;
  }
  @media (min-width: 1280px) {
    margin: 0px 25px;
  }
`
