import { connect } from 'react-redux';

import { DomainContactsModal as Self } from './DomainContactsModal';
import {
  setDomainContacts,
  getDomainContacts,
  changeDomainContactModalTab,
} from '../../../pages/ClientAreaPage/ClientAreaPage.actions';

const mapStateToProps = ({ app, clientAreaPage }) => ({
  domainContacts: clientAreaPage.domainContacts,
  domainContactsLoading: clientAreaPage.domainContactsLoading,
  updateDomainContactsLoading: clientAreaPage.updateDomainContactsLoading,
  transformedContacts: clientAreaPage.transformedContacts,
  domainContactModalTab: clientAreaPage.domainContactModalTab,
});

const mapDispatchToProps = {
  setDomainContacts,
  getDomainContacts,
  changeDomainContactModalTab,
};

export const DomainContactsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self);
