import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

import { PersonalDetails as Self } from './PersonalDetails';
import { getUserInfo, updateUserInfo } from './PersonalDetails.actions';
import { setAutoCharge } from '../../../../components/Modals/AutoChargeModal/AutoChargeModal.actions';
import { showModal } from '../../../../common/Modal/Modal.actions';

const mapStateToProps = ({ userInfo, app }) => ({
  loading: userInfo.loading,
  error: userInfo.error,
  info: userInfo.info,
  updateLoading: userInfo.updateLoading,
  updateError: userInfo.updateError,
});

const mapDispatchToProps = {
  getUserInfo,
  updateUserInfo,
  setAutoCharge,
  showModal
};

export const PersonalDetails = withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Self))
);
