import { connect } from 'react-redux'

import { HomePage as Self } from './HomePage'
import { showModal } from '../../common/Modal/Modal.actions'

const mapStateToProps = ({ app, registrationPage }) => ({
  auth: app.auth,
  userInfo: app.userInfo.user,
  autopay: app.userInfo.autopay,
  userInfoLoading: app.userInfoLoading,
  loading: registrationPage.loading,
})

const mapDispatchToProps = {
  showModal,
}

export const HomePage = connect(mapStateToProps, mapDispatchToProps)(Self)
