import { connect } from 'react-redux';

import {
  generateCsr,
  setTab,
  setStep,
  uploadCsr,
  setIsKeyDownloaded,
} from './CsrModal.actions';
import { CsrModal as Self } from './CsrModal';

import { showModal } from '../../../common/Modal/Modal.actions';

const mapStateToProps = ({ CsrModal }) => ({
  loading: CsrModal.loading,
  error: CsrModal.error,
  tab: CsrModal.tab,
  step: CsrModal.step,
  uploadLoading: CsrModal.uploadLoading,
  uploadError: CsrModal.uploadError,
  csrResult: CsrModal.csrResult,
  isKeyDownloaded: CsrModal.isKeyDownloaded,
});

const mapDispatchToProps = {
  generateCsr,
  setTab,
  setStep,
  uploadCsr,
  setIsKeyDownloaded,
  showModal,
};

export const CsrModal = connect(mapStateToProps, mapDispatchToProps)(Self);
