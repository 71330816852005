import { BLOG_TYPES } from "./BlogPage.types";

const initialState = {
    getPostsLoading: false,
    getPostsData: null,
    getPostsError: null
}


export const getPosts = (state = initialState, action) => {
    switch (action.type) {
        case BLOG_TYPES.GET_POSTS_REQUEST:
            return {
                ...state,
                getPostsLoading: true,
            }
        case BLOG_TYPES.GET_POSTS_SUCCESS:
            return {
                ...state,
                getPostsLoading: false,
                getPostsData: action.data
            }
        case BLOG_TYPES.GET_POSTS_FAIL:
            return {
                ...state,
                getPostsData: null,
                getPostsLoading: false,
                getPostsError: action.error
            }

        default:
            return {
                ...state
            }
    }

}