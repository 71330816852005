import React from 'react'

import { DEFAULT_LIMIT } from '../../ClientAreaPage.constants'
import { i18n } from '../../../../i18n'
import * as S from './Pagination.styles'

export const Pagination = ({ totalResults, goToPage, currentOffset }) => {
  const pagesQuantity = Math.ceil(totalResults / DEFAULT_LIMIT)
  if (pagesQuantity === 1) {
    return null
  }
  const currentPage = currentOffset / DEFAULT_LIMIT
  const pages = Array.from(Array(pagesQuantity), (_, i) => i + 1)

  const formattedPage =
    currentPage < 5
      ? pages.slice(0, 10)
      : pages.slice(currentPage - 5, currentPage + 5)

  const goToPageAndScroll = (offset) => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 400)
    }

    goToPage(offset)
  }

  return (
    <S.PaginationContainer>
      {currentOffset > 0 && (
        <S.Back
          onClick={() => goToPageAndScroll((currentPage - 1) * DEFAULT_LIMIT)}
          long
        >
          {i18n.t('pagination.previous')}
        </S.Back>
      )}
      <S.Pages>
        {formattedPage.map((item) => (
          <S.Page
            onClick={() => goToPageAndScroll((item - 1) * DEFAULT_LIMIT)}
            isActive={currentPage === item - 1}
          >
            {item}
          </S.Page>
        ))}
      </S.Pages>
      {currentPage < pages.length - 1 && (
        <S.Forward
          onClick={() => goToPageAndScroll((currentPage + 1) * DEFAULT_LIMIT)}
          long
        >
          {i18n.t('pagination.next')}
        </S.Forward>
      )}
    </S.PaginationContainer>
  )
}
