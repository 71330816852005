import { SearchAreaBlock as Self } from './SearchAreaBlock';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { toggleTld, cutTld } from './SearchAreaBlock.actions';

const mapStateToProps = ({ domainsPage, searchAreaBlock }) => ({
  domain: domainsPage.domain,
  tld: domainsPage.tld,
  tlds: searchAreaBlock.tlds
});

const mapDispatchToProps = {
  toggleTld,
  cutTld,
};

export const SearchAreaBlock = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
