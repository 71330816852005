import styled from 'styled-components';

import passeye from '../../../images/passeye.png';
import closeModal from '../../../images/closeModal.svg';

export const VpsModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 1;
  z-index: 100;
  border-radius: 5px;
  position: relative;
  @media (min-width: 768px) {
    padding: 23px 42px;
  }
`;

export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-size: cover;

  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`;

export const Modal = styled.div`
  height: 100%;
  width: 310px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  outline: none;
  z-index: 100;
  @media (min-width: 360px) {
    width: 350px;
  }
  @media (min-height: 500px) {
    max-height: 520px;
    overflow-y: auto;
  }

  @media (min-width: 768px) {
    width: 400px;
    overflow-y: auto;
  }
`;

export const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    font-size: 24px;
    font-weight: 400;
  }
`;

export const VpsInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  margin-right: 10px;
  position: relative;
  @media (min-width: 768px) {
    width: 340px;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #242e46;
  margin-right: 10px;
  width: 40%;
  text-align: right;
`;

export const VpsIp = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #242e46;
  width: 40%;
  text-align: center;
`;

export const Username = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #242e46;
  width: 40%;
  text-align: center;
`;

export const PassItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  padding-bottom: 35px;
`;

export const Password = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #242e46;
  width: 50%;
  text-align: center;
  border-bottom: 1px solid #242e46;
`;

export const PassEye = styled.div`
  background: url(${passeye});
  width: 16.57px;
  height: 12px;
  position: absolute;
  bottom: 40px;
  right: 0px;
  cursor: pointer;
  background-size: cover;
`;
