import React from 'react';

import * as S from './RegistrationConfirmModal.styles';
import { i18n } from '../../../i18n';
import { LoginModal } from '../LoginModal';

export const RegistrationConfirmModal = ({ hideModal, showModal }) => (
  <S.Modal onClick={(e) => e.stopPropagation()}>
    <S.RegistrationModalContainer>
      <S.Image />
      <S.Header>{i18n.t('registrationModal.header')}</S.Header>
      <S.SubHeader>{i18n.t('registrationModal.login')}</S.SubHeader>
      <S.SignUpBtn onClick={() => showModal(LoginModal)}>
        {i18n.t('loginModal.login')}
      </S.SignUpBtn>
      <S.CloseModalButton onClick={hideModal} />
    </S.RegistrationModalContainer>
  </S.Modal>
);
