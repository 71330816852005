import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContainer = styled.div`
  display: flex;
  -webkit-overflow-scrolling: touch;
  align-items: center;
  transform: scale(0);
  ${(p) =>
    p.$veryLargeModal &&
    css`
      width: 95%;
      height: 95%;
    `}
  &.Modal-enter-active {
    transform: scale(0);
  }

  &.Modal-enter {
    transform: scale(0);
  }

  &.Modal-enter-done {
    transform: scale(1);
  }

  &.Modal-exit {
    transform: scale(1);
  }

  &.Modal-exit-active {
    transform: scale(0);
  }
  transition: 0.2s;
`

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`
