export const backendRu = {
  backend: {
    hostingStatuses: {
      Active: 'Активный',
      Expired: 'Истекший',
      Cancelled: 'Отменённый',
      Pending: 'В процессе',
      'Pending Transfer': 'Oжидает переноса',
      Grace: 'Период отсрочки платежа',
      'Transferred Away': 'Перенесенный',
    },
    wrongPass: 'Неправильный пароль',
    error_code: {
      blocked_account:
        'Доступ к аккаунту заблокирован из-за ввода неверного пароля. Вы сможете повторить попытку через  5 минут.',
    },
    serviceStatuses: {
      Active: 'Активный',
      Terminated: 'Удаленный',
      Cancelled: 'Oтмененный',
      Pending: 'В ожидании',
      Suspended: 'Приостановленный',
    },
    ticketStatuses: {
      Open: 'Новый',
      Closed: 'Закрытый',
      'Customer-Reply': 'Ответ пользователья',
      Answered: 'Отвеченный',
      'In Progress': 'В процессе',
    },
    invoiceStatuses: {
      Paid: 'Оплачен',
      Cancelled: 'Аннулирован',
      Refunded: 'Возврат',
      Draft: 'Черновик',
      Unpaid: 'Не оплачен',
    },
    filterItems: {
      Active: 'Активный',
      Expired: 'Истекший',
      Cancelled: 'Аннулирован',
      Pending: 'В ожидании',
      Open: 'Новый',
      Closed: 'Закрытый',
      'Customer-Reply': 'Ответ пользователья',
      Paid: 'Оплачен',
      Refunded: 'Возврат',
      Draft: 'Черновик',
      Unpaid: 'Не оплачен',
      Terminated: 'Удаленный',
      Suspended: 'Приостановленный',
      'Pending Transfer': 'Oжидает переноса',
      Grace: 'Период отсрочки платежа',
      Answered: 'Отвеченный',
      'In Progress': 'В процессе',
    },
    servicesBillCycle: {
      Monthly: 'Ежемесячный',
      Quarterly: 'Ежеквартальный',
      'Semi-Annually': 'Полугодовой',
      Annually: 'Ежегодный',
      'Free Account': 'Бесплатно',
      Free: 'Бесплатно',
    },
    logInError: {
      'whmcs-error-200-email-or-password-invalid':
        'Неверный адрес электронной почты или пароль',
      'failed-to-fetch': 'Что-то пошло не так',
      'email-is-not-verified': 'Эл. почта не подтверждена',
    },
    forgotPassword: {
      'email-is-not-found': 'Эл. почта не найдена',
      'failed-to-fetch': 'Что-то пошло не так',
      'email-is-not-verified': 'Эл. почта не подтверждена',
      'email-must-be-a-valid-email': 'Эл. почта должна быть действительной',
    },
    registrationPage: {
      'whmcs-error-200-the-email-address-you-entered-was-not-valid':
        'Вы указали неправильную эл. почту',
      'whmcs-error-200-a-client-already-exists-with-that-email-address':
        'Введенный вами адрес электронной почты уже зарегистрирован',
      'failed-to-fetch': 'Что-то пошло не так',
      '-email-must-be-a-valid-email': 'Эл. почта должна быть действительной',
      'legalcode-must-only-contain-alpha-numeric-characters':
        'Юридический код страницы регистрации должен содержать только буквенно-цифровые символы',
    },
    resetPassword: {
      'failed-to-fetch': 'Что-то пошло не так',
    },
    personalDetails: {
      'Failed to fetch': 'Что-то пошло не так',
    },
    addContact: {
      'failed-to-fetch': 'Что-то пошло не так',
      '-email-must-be-a-valid-email': 'Эл. почта должна быть действительной',
    },
    security: {
      'Failed to fetch': 'Что-то пошло не так',
    },
    viewTicket: {
      'Failed to fetch': 'Что-то пошло не так',
    },
    openTicket: {
      'Failed to fetch': 'Что-то пошло не так',
    },
    addOrder: {
      'failed-to-fetch': 'Что-то пошло не так',
      'this-promocode-doesn-t-belong-to-you':
        'Упомянутый промокод не привязан к вашей учетной записи',
    },
    transferDomain: {
      'Failed to fetch': 'Что-то пошло не так',
    },
    contactOwnerModal: {
      'failed-to-fetch': 'Что-то пошло не так',
      'invalid-domain-name': 'У указанного домена нет правообладателя',
      '-email-must-be-a-valid-email': 'Эл. почта должна быть действительной',
    },
    uploadError: {
      'csr-fails-to-match-the-required-pattern':
        'Неправильный CSR. Пожалуйста, введите еще раз',
      'failed-to-fetch': 'Что-то пошло не так',
    },
    paymentFailed: {
      '001': 'Hello',
      '0-1': 'Истекло время ожидания ответа от процессинговой системы.',
      '0-100': 'Не было попыток оплаты.',
      '0-2000':
        'Транзакция отклонена по причине того, что карта внесена в черный список',
      '0-2001':
        'Транзакция отклонена по причине того, что IP-адрес Клиента внесен в черный список.',
      '0-20010':
        'Транзакция отклонена по причине того, что размер платежа превысил установленные лимиты',
      '0-2002':
        'Транзакция отклонена по причине того, что размер платежа превысил установленные лимиты. Примечание: имеется в виду либо лимиты Банка-эквайера на дневной оборот Магазина, либо лимиты Магазина на оборот по одной карте, либо лимит Магазина по одной операции',
      '0-2004': 'Оплата через SSL без ввода SVС запрещена',
      '0-2005':
        'Означает, что мы не смогли проверить подпись эмитента, то есть PARes был читаемый, но подписан неверно.',
      '0-2006': 'Означает, что эмитент отклонил аутентификацию.',
      '0-2007':
        'Истек срок, отведенный на ввод данных карты с момента регистрации платежа (в данный момент таймаут наступит через 20 минут)',
      '0-2011':
        'Карта определена как вовлеченная в карта 3d secure, но банк- эммитент не готов (в данный момент времени) провести 3ds транзакцию',
      '0-2012': 'Данная операция не поддерживается',
      '0-2013': 'Исчерпаны попытки оплаты',
      '0-2015':
        'VERes от DS содержит iReq, вследствие чего платеж был отклонен',
      '0-2016':
        'Это означает, что банк- эммитент не готов (в данный момент времени) провести 3ds транзакцию (например, не работает ACS банка). Мы не можем определить вовлечена ли карта в 3d secure',
      '0-2017': 'Declined due to 3DSec error',
      '0-2018':
        'Directory server Visa или MasterCard либо недоступен, либо в ответ на запрос вовлеченности карты (VeReq) пришла ошибка связи. Это ошибка взаимодействия платежного шлюза и серверов МПС по причине технических неполадок на стороне последних.',
      '0-2019':
        'PARes от эмитента содержит iReq, вследствие чего платеж был отклонен',
      '0-9000': 'Состояние начала транзакции',
      '01': 'Заказ с таким номером уже зарегистрирован в системе',
      '0100': 'Банк эмитент запретил интернет транзакции по карте.',
      '01001':
        'Выставляется в момент регистрации транзакции, т.е. когда еще по транзакции не было введено данных карты',
      '0101': 'Истек срок действия карты.',
      '0103':
        'Нет связи с Банком-Эмитентом.Торговой точке необходимо связаться с банком-эмитентом.',
      '0104':
        'Попытка выполнения операции по счету, на использование которого наложены ограничения.',
      '0107': 'Следует обратиться к Банку-Эмитенту.',
      '0109':
        'Неверно указан идентификатор мерчанта/терминала (для завершения и предавторизация с разными идентификаторами)',
      '0110': 'Неверно указана сумма транзакции',
      '0111': 'Неверный номер карты',
      '0116':
        'Сумма транзакции превышает доступный остаток средств на выбранном счете.',
      '0120':
        'Отказ в проведении операции - транзакция не разрешена эмитентом. Код ответа платежной сети - 57. Причины отказа необходимо уточнять у эмитента.',
      '0121':
        'Предпринята попытка выполнить транзакцию на сумму, превышающую дневной лимит, заданный банком-эмитентом',
      '0123':
        'Превышен лимит на число транзакций: клиент выполнил максимально разрешенное число транзакций в течение лимитного цикла и пытается провести еще одну.',
      '0125':
        'Неверный номер карты. Попытка возврата на сумму, больше холда, попытка возврата нулевой суммы.',
      '0151017': '3-D Secure - ошибка связи',
      '0151018': 'Таймаут в процессинге. Не удалось отправить',
      '0151019':
        'Таймаут в процессинге. Удалось отправить, но не получен ответ от банка',
      '02001': 'Фродовая транзакция (по мнению процессинга или платежной сети)',
      '02003': 'SSL (Не 3d-Secure/SecureCode) транзакции запрещены Магазину',
      '02005': 'Платеж не соотвествует условиям правила проверки по 3ds',
      '0208': 'Карта утеряна',
      '0209': 'Превышены ограничения по карте',
      '0341014': 'Код отказа РБС',
      '0341041': 'Ошибка возврата денежных средств',
      '05': 'Ошибка значение параметра запроса',
      '071015': 'Введены неправильные параметры карты',
      '08204': 'Такой заказ уже регистрировали (проверка по ordernumber)',
      '09001': 'Внутренний код отказа РБС',
      '0902':
        'Владелец карты пытается выполнить транзакцию, которая для него не разрешена.',
      '0903':
        'Предпринята попытка выполнить транзакцию на сумму, превышающую лимит, заданный банком-эмитентом',
      '0904': 'Ошибочный формат сообщения с точки зрения банка эмитента.',
      '0907':
        'Нет связи с Банком, выпустившим Вашу карту. Для данного номера карты не разрешена авторизация в режиме stand-in (этот режим означает, что эмитент не может связаться с платежной сетью и поэтому транзакция возможна либо в оффлайне с последующей выгрузкой в бэк офис, либо она будет отклонена)',
      '0909':
        'Ошибка функционирования системы, имеющая общий характер. Фиксируется платежной сетью или банком-эмитентом.',
      '0910': 'Банк-эмитент недоступен.',
      '0913': 'Неправильный формат транзакции с точки зрения сети.',
      '0914':
        'Не найдена транзакция (когда посылается завершение или reversal или refund)',
      '0999':
        'Отсутствует начало авторизации транзакции. Отклонено по фроду или ошибка 3dsec.',
      '02': 'Заказ отклонен по причине ошибки в реквизитах платежа',
      '03': 'Неизвестная (запрещенная) валюта',
      '04': 'Отсутствует обязательный параметр запроса',
      '06': 'Незарегистрированный OrderId',
      '07': 'Системная ошибка',
      20: 'Неверное имя ползователья или пароль',
      30: 'Неверный OrderId',
      550: 'Системная ошибка',
    },
    sslConfiguration: {
      new: 'Новый',
      renew: 'Продление',
    },

    telcellPaymenStatus: {
      NEW: 'Счёт выставлен, но не оплачен.',
      PAID: 'Счёт успешно оплачен.',
      REJECTED: 'Счёт отклонён получателем.',
      EXPIRED: 'Истекло время действия счёта.',
      CANCELLED: 'Счёт отменён персоналом системы.',
      CANCELLEDFORREPEAT: 'Счёт отменен для повторного выставления',
    },
    telcellPaymenInvoice: {
      invoice: 'Счет',
    },

    telcellPaymenUnknownStatus: {
      unknownStatus: 'Платеж не удался',
    },
  },
}
