import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logoLg from '../../images/logoBnw-lg.png';
import logoMd from '../../images/logoBnw-md.png';
import logoSm from '../../images/logoBnw-sm.png';
import menuIcon from '../../images/menuIcon.png';
import errorLogo from '../../images/errorLogo.png';
import errorLogoSm from '../../images/errorLogo-sm.png';
import hatSm from '../../images/hat-sm.png';
import hatMd from '../../images/hat-md.png';
import glassesSm from '../../images/glasses-sm.png';
import glassesMd from '../../images/glasses-md.png';
import beadsSm from '../../images/beads-sm.png';
import beadsMd from '../../images/beads-md.png';
import ostrichSm from '../../images/ostrich-sm.png';
import ostrichMd from '../../images/ostrich-md.png';
import costSm from '../../images/cost-sm.png';
import costMd from '../../images/cost-md.png';
import shirtSm from '../../images/shirt-sm.png';
import shirtMd from '../../images/shirt-md.png';

export const PageBody = styled.div`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  @media (min-width: 768px) {
    padding: 40px 0px;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (min-width: 768px) {
    align-items: flex-start;
  }
  @media (min-width: 1280px) {
    padding-left: 55px;
  }
`;

export const Logo = styled.div`
  background-image: url(${logoSm});
  background-repeat: no-repeat;
  background-size: cover;
  width: 36px;
  height: 63.39px;
  position: absolute;
  top: 0;
  left: 50%;
  @media (min-width: 768px) {
    background-image: url(${logoMd});
    width: 50px;
    height: 89.58px;
  }
  @media (min-width: 1280px) {
    background-image: url(${logoLg});
    position: static;
    width: 76px;
    height: 131px;
  }
`;

export const Menu = styled.div`
  background-image: url(${menuIcon});
  background-repeat: no-repeat;
  background-size: cover;
  width: 48px;
  height: 48px;
  order: 1;
  cursor: pointer;
  @media (min-width: 1280px) {
    display: none;
  }
`;

export const Services = styled.div`
  display: none;
  @media (min-width: 1280px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 30px;
    order: 2;
  }
`;
export const Service = styled.img`
  width: 150px;
  height: 60px;
  cursor: pointer;
`;

export const ServiceLink = styled(Link)``;
export const HomeLink = styled(Link)`
  order: 3;
  @media (min-width: 768px) {
    order: 2;
  }
  @media (min-width: 1280px) {
    order: 1;
  }
`;

export const ErrorImg = styled.div`
  background-image: url(${errorLogoSm});
  background-repeat: no-repeat;
  background-size: cover;
  width: 112px;
  height: 94px;
  order: 2;
  position: absolute;
  top: 450%;
  @media (min-width: 768px) {
    background-image: url(${errorLogo});
    background-repeat: no-repeat;
    background-size: cover;
    width: 308px;
    height: 258px;
    order: 3;
    top: 15%;
    left: 10%;
  }
  @media (min-width: 1280px) {
    top: 15%;
    left: 15%;
  }
`;

export const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  @media (min-width: 768px) {
    width: 100%;
  }
`;
export const HatImg = styled.div`
  background-image: url(${hatSm});
  background-repeat: no-repeat;
  background-size: cover;
  cursor: move;
  width: 163px;
  height: 140px;
  position: absolute;
  top: 100%;
  right: 50%;
  z-index: 2;
  filter: ${(p) =>
    p.rand
      ? `invert(42%) sepia(5%) saturate(736%) hue-rotate(
          ${p.rand}deg) brightness(120%) contrast(202%)`
      : 'none'};

  @media (min-width: 768px) {
    background-image: 'url(${hatMd})';
    width: 280px;
    height: 240px;
    top: 8%;
    right: 15%;
  }
  @media (min-width: 1280px) {
    top: 70%;
  }
`;

export const GlassesImg = styled.div`
  background-image: url(${glassesSm});
  background-repeat: no-repeat;
  background-size: cover;
  cursor: move;
  width: 125px;
  height: 149px;
  position: absolute;
  top: 175%;
  left: 55%;
  z-index: 1;
  filter: ${(p) =>
    p.rand
      ? `invert(42%) sepia(40%) saturate(222%) hue-rotate(
          ${p.rand}deg) brightness(151%) contrast(202%)`
      : 'none'};

  @media (min-width: 768px) {
    background-image: url(${glassesMd});
    width: 231px;
    height: 150px;
    top: 600%;
    left: 5%;
  }
  @media (min-width: 1280px) {
    top: 210%;
    left: 5%;
  }
`;
export const BeadsImg = styled.div`
  background-image: url(${beadsSm});
  background-repeat: no-repeat;
  background-size: cover;
  cursor: move;
  width: 50px;
  height: 62px;
  position: absolute;
  top: 550%;
  left: 75%;
  z-index: 1;
  filter: ${(p) =>
    p.rand
      ? `invert(42%) sepia(5%) saturate(900%) hue-rotate(
          ${p.rand}deg) brightness(120%) contrast(150%)`
      : 'none'};

  @media (min-width: 768px) {
    background-image: url(${beadsMd});
    width: 95px;
    height: 95px;
    top: 900%;
    left: 75%;
  }
  @media (min-width: 1280px) {
    top: 290%;
    left: 85%;
  }
`;

export const OstrichImg = styled.div`
  background-image: url(${ostrichSm});
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  width: 145px;
  height: 226px;
  position: absolute;
  top: 360%;
  right: 20%;
  @media (min-width: 768px) {
    background-image: url(${ostrichMd});
    width: 230px;
    height: 400px;
    top: 520%;
    right: 35%;
  }
  @media (min-width: 1280px) {
    top: 180%;
    right: 40%;
    height: 410px;
  }
`;

export const CostImg = styled.div`
  background-image: url(${costSm});
  background-repeat: no-repeat;
  background-size: cover;
  cursor: move;
  width: 116px;
  height: 139px;
  position: absolute;
  top: 710%;
  left: 3%;
  z-index: 1;
  filter: ${(p) =>
    p.rand
      ? `invert(35%) sepia(5%) saturate(630%) hue-rotate(
          ${p.rand}deg) brightness(100%) contrast(202%)`
      : 'none'};
  @media (min-width: 768px) {
    background-image: url(${costMd});
    width: 205px;
    height: 250px;
    top: 1050%;
    left: 10%;
  }
  @media (min-width: 1280px) {
    top: 400%;
  }
`;

export const ShirtImg = styled.div`
  background-image: url(${shirtSm});
  background-repeat: no-repeat;
  background-size: cover;
  cursor: move;
  width: 164px;
  height: 196px;
  position: absolute;
  top: 730%;
  right: 3%;
  z-index: 1;
  filter: ${(p) =>
    p.rand
      ? `invert(30%) sepia(0%) saturate(650%) hue-rotate(
          ${p.rand}deg) brightness(100%) contrast(105%)`
      : 'none'};
  @media (min-width: 768px) {
    background-image: url(${shirtMd});
    width: 302px;
    height: 300px;
    top: 1050%;
    right: 10%;
  }
  @media (min-width: 1280px) {
    top: 360%;
    right: 15%;
  }
`;
