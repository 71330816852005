import React, { useState, useCallback, useEffect } from 'react'
import * as S from './PunycodeConverterModal.styles'
import punycodeLib from 'punycode'
import { useSearchParams } from '../../../hooks/useSearchParams'

export const PunycodeConverterModal = ({ hideModal, punycodeRef, history }) => {
  const [unicode, setUnicode] = useState('')
  const [punycode, setPunycode] = useState('')
  const [searchParams, updateSearchParams] = useSearchParams({ history })

  const handleUnicode = useCallback((v) => {
    setUnicode(v)
    setPunycode(punycodeLib.toASCII(v))
  }, [])

  const handlePunycode = useCallback((v) => {
    setPunycode(v)
    setUnicode(punycodeLib.toUnicode(v))
  }, [])

  useEffect(() => {
    updateSearchParams({ ...searchParams, punycode: 1 })
    return () => {
      const newParams = { ...searchParams }
      delete newParams.punycode
      updateSearchParams(newParams)
    }
  }, [])

  return (
    <S.Modal onClick={(e) => e.stopPropagation()}>
      <S.PunycodeConverterContainer>
        <S.ModalTitle>Punycode Converter</S.ModalTitle>
        <S.FormContainer>
          <S.FormItem>
            <S.Label>Unicode:</S.Label>
            <S.Input
              value={unicode}
              onChange={(e) => handleUnicode(e.target.value)}
            />
          </S.FormItem>
          <S.FormItem>
            <S.Label>Punycode:</S.Label>
            <S.Input
              value={punycode}
              onChange={(e) => handlePunycode(e.target.value)}
            />
          </S.FormItem>
          <S.FormItem></S.FormItem>
        </S.FormContainer>
        <S.CloseModalButton onClick={() => hideModal()} />
      </S.PunycodeConverterContainer>
    </S.Modal>
  )
}
