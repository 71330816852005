import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { ServicesTable as Self } from "./ServicesTable";
import {
  toggleFilterPopup,
  showSortPopup,
  showSettingsPopup,
  getServices,
} from "../../ClientAreaPage.actions";
import { numberWithCommas } from "../../../../common/App/App.actions";

const mapStateToProps = ({ clientAreaPage }) => ({
  data: clientAreaPage.servicesData,
  loading: clientAreaPage.servicesLoading,
  success: clientAreaPage.servicesRequestSuccess,
  total: clientAreaPage.servicesTotal,
  statuses: clientAreaPage.servicesStatuses,
  filter: clientAreaPage.filters.services,
  offsets: clientAreaPage.offsets,
  sortField: clientAreaPage.sortField.services,
  sortOrder: clientAreaPage.sortOrder.services,
});

const mapDispatchToProps = {
  toggleFilterPopup,
  showSortPopup,
  showSettingsPopup,
  numberWithCommas,
  getServices,
};

export const ServicesTable = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
