import * as Yup from 'yup';

export const initialValues = {
  serverLocationId: undefined,
  locationName: '',
  serverOsId: undefined,
  osName: '',
  pid: undefined,
  productServerSize: '',
  price: null,
  price_usd: null,
  serverLabel: '',
  count: 1,
  autoBackUp: false,
};

export const yupScheme = Yup.object().shape({
  serverLabel: Yup.string()
    .required('validationFields.required')
    .min(4, 'validationFields.vpsLabelMin')
    .max(25, 'validationFields.vpsLabelMax')
    .matches(/^[a-zA-Z0-9\s\-.]+$/, 'validationFields.invalidTyping'),
});
