import { CLIENT_AREA_TABS } from '../../ClientAreaPage.constants'

export const headers = [
  {
    id: 'sectionHeader',
    tab: CLIENT_AREA_TABS.TICKETS,
    sortNumber: 0,
    sortType: 'number',
    title: 'ticketsTable.id',
    sortBy: 'tid',
  },
  {
    id: 'sectionHeader',
    tab: CLIENT_AREA_TABS.TICKETS,
    sortNumber: 1,
    sortType: 'text',
    title: 'ticketsTable.section',
    sortBy: 'deptid',
  },
  {
    id: 'titleHeader',
    tab: CLIENT_AREA_TABS.TICKETS,
    sortNumber: 2,
    sortType: 'text',
    title: 'ticketsTable.header',
    sortBy: 'subject',
  },
  {
    id: 'paymentHeader',
    tab: CLIENT_AREA_TABS.TICKETS,
    sortNumber: 3,
    sortType: 'number',
    title: 'ticketsTable.lastAnnouncement',
    sortBy: 'lastreply',
  },
  {
    id: 'statusHeader',
    tab: CLIENT_AREA_TABS.TICKETS,
    sortNumber: 4,
    sortType: 'text',
    title: 'ticketsTable.status',
    sortBy: 'status',
    isMarginNeed: true,
  },
]
