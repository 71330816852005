import React, { useState } from 'react'
import { SetNameServersSuccessModal } from '../../../Modals/SetNameServersSuccessModal'
import { initialState, yupScheme } from './GetCodeModal.config'
import { Formik } from 'formik'
import { i18n } from '../../../../i18n'
import { Loader } from '../../../Loader'

import * as S from './GetCodeModalStyle'

export const GetCodeModal = ({
  hideModal,
  showModal,
  id,
  setNameServers,
  updateNameserversError,
  updateNameserversLoading,
  nameservers,
  email,
}) => {
  const [backendError, setBackendError] = useState(null)
  return (
    <S.ModalContainer>
      <S.ModalTitle>{i18n.t('getCodeModal.title')}</S.ModalTitle>
      <S.SubTitle>
        {i18n.t('getCodeModal.subTitle')} {email}
      </S.SubTitle>
      <S.FormContainer>
        <Formik
          validationSchema={yupScheme}
          initialValues={initialState}
          onSubmit={async (values, { setSubmitting }) => {
            setNameServers(
              id,
              nameservers,
              values.code,
              () => {
                setTimeout(() => {
                  hideModal()
                  setTimeout(() => {
                    showModal(SetNameServersSuccessModal)
                  }, 400)
                }, 10)
              },
              (error) => {
                if (error) {
                  setBackendError(error)
                }
              }
            )
          }}
        >
          {({
            touched,
            errors,
            isSubmitting,
            handleChange,
            submitForm,
            setFieldValue,
            values,
            dirty,
          }) => (
            <S.StyledForm>
              <S.Input
                name="code"
                placeholder="Enter code"
                onChange={(event) => {
                  setFieldValue('code', event.target.value)
                  setBackendError(null)
                }}
              />
              {errors.code && (
                <S.ErrorMessage>{i18n.t(errors.code)}</S.ErrorMessage>
              )}
              {backendError && <S.ErrorMessage>{backendError}</S.ErrorMessage>}
              <S.SubmitButton
                type="submit"
                disabled={
                  isSubmitting || errors.code || updateNameserversLoading
                }
              >
                {updateNameserversLoading ? (
                  <Loader color="#ffffff" size={0.5} />
                ) : (
                  i18n.t('getCodeModal.submitCode')
                )}
              </S.SubmitButton>
            </S.StyledForm>
          )}
        </Formik>
        <S.CloseModalButton onClick={() => hideModal()} />
      </S.FormContainer>
    </S.ModalContainer>
  )
}

export default GetCodeModal
