import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { RightNavBar as Self } from './RightNavBar'
import { showModal } from '../../../common/Modal/Modal.actions'
import {
  toggleCart,
  toggleLanguagesPopup,
  toggleAccountPopup,
} from '../../../common/App/App.actions'

const mapStateToProps = ({ app }) => ({
  cart: app.cart,
  auth: app.auth,
})

const mapDispatchToProps = {
  showModal,
  toggleCart,
  toggleLanguagesPopup,
  toggleAccountPopup,
}

export const RightNavBar = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
)
