export const LOGIN_TYPES = {
  CLEAR_FORM: 'RESET_PASSWORD_TYPES.CLEAR_FORM',
  LOGIN_REQUEST: 'LOGIN_MODAL_TYPES.LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_MODAL_TYPES.LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_MODAL_TYPES.LOGIN_FAIL',
  GET_CONTACTS_REQUEST: 'LOGIN_MODAL_TYPES.GET_CONTACTS_REQUEST',
  GET_CONTACTS_SUCCESS: 'LOGIN_MODAL_TYPES.GET_CONTACTS_SUCCESS',
  GET_CONTACTS_FAIL: 'LOGIN_MODAL_TYPES.GET_CONTACTS_FAIL',
  ADD_NEW_CONTACT: 'LOGIN_MODAL_TYPES.ADD_NEW_CONTACT',
  REMOVE_CONTACT_REQUEST: 'LOGIN_MODAL_TYPES.REMOVE_CONTACT_REQUEST',
  REMOVE_CONTACT_SUCCESS: 'LOGIN_MODAL_TYPES.REMOVE_CONTACT_SUCCESS',
  REMOVE_CONTACT_FAIL: 'LOGIN_MODAL_TYPES.REMOVE_CONTACT_FAIL',
  UPDATE_CONTACT_INFO_REQUEST: 'LOGIN_MODAL_TYPES.UPDATE_CONTACT_INFO_REQUEST',
  UPDATE_CONTACT_INFO_SUCCESS: 'LOGIN_MODAL_TYPES.UPDATE_CONTACT_INFO_SUCCESS',
  UPDATE_CONTACT_INFO_FAIL: 'LOGIN_MODAL_TYPES.UPDATE_CONTACT_INFO_FAIL',
  GET_DOMAINS_REQUEST: 'LOGIN_MODAL_TYPES.GET_DOMAINS_REQUEST',
  GET_DOMAINS_SUCCESS: 'LOGIN_MODAL_TYPES.GET_DOMAINS_SUCCESS',
  GET_DOMAINS_FAIL: 'LOGIN_MODAL_TYPES.GET_DOMAINS_FAIL',
  VERIFY_2FA_REQUEST: 'LOGIN_MODAL_TYPES.VERIFY_2FA_REQUEST',
  VERIFY_2FA_SUCCESS: 'LOGIN_MODAL_TYPES.VERIFY_2FA_SUCCESS',
  VERIFY_2FA_FAIL: 'LOGIN_MODAL_TYPES.VERIFY_2FA_FAIL',
  LOGIN_AS_CLIENT_FAIL: 'LOGIN_MODAL_TYPES.LOGIN_AS_CLIENT_FAIL',
  VERIFY_2FA_ADMIN_FAIL: 'LOGIN_MODAL_TYPES.VERIFY_2FA_ADMIN_FAIL',
  RESEND_EMAIL_REQUEST: 'LOGIN_MODAL_TYPES.RESEND_EMAIL_REQUEST',
  RESEND_EMAIL_SUCCESS: 'LOGIN_MODAL_TYPES.RESEND_EMAIL_SUCCESS',
  RESEND_EMAIL_FAIL: 'LOGIN_MODAL_TYPES.RESEND_EMAIL_FAIL',
};
