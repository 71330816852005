import React, { useState, useEffect, useCallback } from 'react'
import ScrollLock from 'react-scrolllock'
import { CSSTransition } from 'react-transition-group'
import Cookies from 'js-cookie'
import qs from 'qs'

import { i18n } from '../../../i18n'
import * as S from './MobileMenu.styles'
import { config } from '../Header.config'
import logoutLogo from '../../../images/logoutLogo.svg'
import armenianLanguage from '../../../images/armenianLanguage.svg'
import russianLanguage from '../../../images/russianLanguage.svg'
import englishLanguage from '../../../images/englishLanguage.svg'
import blogIcon from '../../../images/blogIcon.svg'
import { changeUrl } from '../../../hooks/changeUrl'

export const MobileMenu = ({
  history,
  auth,
  isExpanded,
  hideMobileMenu,
  logout,
  dimensions,
  toggleLanguagesPopup,
  changeBackendLanguage,
}) => {
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    setAnimate(false)
    setTimeout(() => {
      setAnimate(true)
    }, 50)
  }, [isExpanded])

  const hideMobileMenuWithAnimate = useCallback(() => {
    setAnimate(false)
    setTimeout(() => {
      hideMobileMenu()
      setAnimate(true)
    }, 200)
  }, [setAnimate, hideMobileMenu])

  const logoutAndHideMenu = useCallback(() => {
    logout(history)
    hideMobileMenu()
  }, [logout, history, hideMobileMenu])

  const handleChangeUrl = useCallback((lang) => {
    if (history && history.location) {
      const params = qs.parse(history.location, {
        ignoreQueryPrefix: true,
        encode: true,
      })
      const url = params.pathname
      if (url.includes('/ru/') || url.includes('/en/')) {
        const newUrl = params.pathname.slice(3)

        if (lang === 'am') {
          history.push(newUrl)
        } else {
          history.push('/' + lang + newUrl)
        }
      } else {
        history.push('/' + lang + window.location.pathname)
      }
    }
  })

  const switchLanguage = useCallback((lang) => {
    Cookies.set('lng', lang)
    i18n.changeLanguage(lang)
    auth.token && changeBackendLanguage(lang)
    handleChangeUrl(lang)
  })

  function changeBlogUrl(url) {
    const lang = Cookies.get('lng')
    switch (lang) {
      case 'am':
        return `/am${url}`
      case 'ru':
        return `/ru${url}`
      case 'en':
        return `/en${url}`
      default:
        return `/am${url}`
    }
  }

  return (
    isExpanded && (
      <S.Container>
        <ScrollLock />
        <S.Backdrop onClick={hideMobileMenuWithAnimate} />
        <CSSTransition in={animate} classNames="MobileMenu" timeout={1}>
          {() => (
            <S.MobileMenuContainer>
              <S.Content>
                <S.CloseModalIcon onClick={hideMobileMenuWithAnimate} />
                <S.IDContainer>ID: {auth.userId}</S.IDContainer>
                {config.map((item) => (
                  <S.ListItem key={item.translationKey}>
                    <S.Logo
                      src={item.img}
                      alt="Internet.am section images"
                      title="Internet.am section images"
                    />
                    <S.InlineLink
                      onClick={hideMobileMenu}
                      to={changeUrl(item.to)}
                    >
                      {i18n.t(item.translationKey)}
                    </S.InlineLink>
                  </S.ListItem>
                ))}
                <S.BlogContainer>
                  <S.BlocIcon src={blogIcon} alt="Blog Icon" />
                  <S.Blog href={`/blog${changeBlogUrl('/posts')}`}>Blog</S.Blog>
                </S.BlogContainer>

                {auth.token && (
                  <S.ListItem hideOnDesktop>
                    <S.Logo
                      src={logoutLogo}
                      alt="Logout icon"
                      title="logout image"
                    />
                    <S.LogoutButton onClick={logoutAndHideMenu}>
                      {i18n.t('header.logout')}
                    </S.LogoutButton>
                  </S.ListItem>
                )}
              </S.Content>
              <S.LagnuagesContainer onClick={toggleLanguagesPopup}>
                <S.LanguageLogo
                  onClick={() => i18n.language !== 'am' && switchLanguage('am')}
                  selected={i18n.language === 'am'}
                  src={armenianLanguage}
                  alt="Armenian language icon"
                  title="armenian language icon"
                />
                <S.LanguageLogo
                  onClick={() => i18n.language !== 'ru' && switchLanguage('ru')}
                  selected={i18n.language === 'ru'}
                  src={russianLanguage}
                  alt="Russian language icon"
                  title="russian language icon"
                />
                <S.LanguageLogo
                  onClick={() => i18n.language !== 'en' && switchLanguage('en')}
                  selected={i18n.language === 'en'}
                  src={englishLanguage}
                  alt="English language icon"
                  title="english language icon"
                />
              </S.LagnuagesContainer>
            </S.MobileMenuContainer>
          )}
        </CSSTransition>
      </S.Container>
    )
  )
}
