export const backendEn = {
  backend: {
    hostingStatuses: {
      Active: 'Active',
      Expired: 'Expired',
      Cancelled: 'Cancelled',
      Pending: 'In progress',
      'Pending Transfer': 'Pending transfer',
      Grace: 'Redemption grace period',
      'Transferred Away': 'Transferred away',
    },
    wrongPass: 'Wrong password',
    error_code: {
      blocked_account:
        'Account is locked for incorrect login attempts. You can try to login again after 5 minutes.',
    },
    serviceStatuses: {
      Active: 'Active',
      Terminated: 'Terminated',
      Cancelled: 'Cancelled',
      Pending: 'In progress',
      Suspended: 'Suspended',
    },
    ticketStatuses: {
      Open: 'New',
      Closed: 'Closed',
      'Customer-Reply': 'Customer Reply',
      Answered: 'Replied',
      'In Progress': 'In Progress',
    },
    invoiceStatuses: {
      Paid: 'Paid',
      Cancelled: 'Cancelled',
      Refunded: 'Refund',
      Draft: 'Draft',
      Unpaid: 'Unpaid',
    },
    filterItems: {
      Active: 'Active',
      Expired: 'Expired',
      Cancelled: 'Cancelled',
      Pending: 'Pending',
      Open: 'New',
      Closed: 'Closed',
      'Customer-Reply': 'Customer Reply',
      Paid: 'Paid',
      Refunded: 'Refund',
      Draft: 'Draft',
      Unpaid: 'Unpaid',
      Terminated: 'Terminated',
      Suspended: 'Suspended',
      'Pending Transfer': 'Pending transfer',
      Grace: 'Redeption grace period',
      Answered: 'Replied',
      'In Progress': 'In Progress',
    },
    servicesBillCycle: {
      Monthly: 'Monthly',
      Quarterly: 'Quarterly',
      'Semi-Annually': 'Semi-annually',
      Annually: 'Annually',
      'Free Account': 'Free',
      Free: 'Free',
    },
    logInError: {
      'whmcs-error-200-email-or-password-invalid':
        'Incorrect email or password',
      'failed-to-fetch': 'Something went wrong',
      'email-is-not-verified': 'Email is not verified',
    },
    forgotPassword: {
      'email-is-not-found': 'Email is not found',
      'failed-to-fetch': 'Something went wrong',
      'email-is-not-verified': 'Email is not verified',
      '-email-must-be-a-valid-email': 'Email must be a valid',
    },
    registrationPage: {
      'whmcs-error-200-the-email-address-you-entered-was-not-valid':
        'You entered a wrong email',
      'whmcs-error-200-a-client-already-exists-with-that-email-address':
        'A user already exists with that email address',
      'failed-to-fetch': 'Something went wrong',
      '-email-must-be-a-valid-email': 'Email must be a valid',
      'legalcode-must-only-contain-alpha-numeric-characters':
        'Legal code must only contain alpha-numeric characters',
    },
    resetPassword: {
      'failed-to-fetch': 'Something went wrong',
    },
    personalDetails: {
      'Failed to fetch': 'Something went wrong',
    },
    addContact: {
      'failed-to-fetch': 'Something went wrong',
      '-email-must-be-a-valid-email': 'Email must be a valid',
    },
    security: {
      'Failed to fetch': 'Something went wrong',
    },
    viewTicket: {
      'Failed to fetch': 'Something went wrong',
    },
    openTicket: {
      'Failed to fetch': 'Something went wrong',
    },
    addOrder: {
      'failed-to-fetch': 'Something went wrong',
      'this-promocode-doesn-t-belong-to-you':
        'The mentioned promocode is not linked to your account',
    },
    transferDomain: {
      'Failed to fetch': 'Something went wrong',
    },
    contactOwnerModal: {
      'failed-to-fetch': 'Something went wrong',
      'invalid-domain-name': ' The specified domain has no holder',
      '-email-must-be-a-valid-email': 'Email must be a valid',
    },
    uploadError: {
      'csr-fails-to-match-the-required-pattern':
        ' Incorrect CSR. Please enter again',
      'failed-to-fetch': 'Something went wrong',
    },
    paymentFailed: {
      '001': 'Hello',
      '0-1': 'Processing center response timeout',
      '0-100': 'No payment attempts',
      '0-2000': 'Transaction declined since the card is in the blacklist',
      '0-2001':
        'Transaction declined since Client’s IP address is in the blacklist ',
      '0-20010':
        'Transaction declined since payment amount exceeded the limits set by the issuer bank',
      '0-2002':
        'Transaction declined since payment amount exceeded the limits (daily turnover limits of the merchant set by the acquiring bank or one card turnover limit of the merchant or one transaction limit of the merchant)',
      '0-2004': 'Payment through SSL without entering SVС data is forbidden ',
      '0-2005':
        'We failed to check the issuer’s signature, i.e. PARes was readable but was not signed correctly ',
      '0-2006': 'Issuer declined authentication ',
      '0-2007':
        'Card data registration timeout starting from the moment of payment registration (timeout will be in 20 minutes)',
      '0-2011':
        'Card is included in 3d secure but the issuer bank is not ready (at that moment) to execute 3ds of transaction',
      '0-2012': 'This operation is not supported',
      '0-2013': 'Payment attempts expired ',
      '0-2015': 'VERes of DS contain iReq, due to which payment was declined ',
      '0-2016':
        'Issuer bank is not ready (at that moment) to execute 3ds of transaction (for instance, the bank’s ACS does not function). We cannot define whether the card is included in 3d secure or not.',
      '0-2017': 'Declined due to 3DSec error',
      '0-2018':
        'Directory server Visa or MasterCard cannot be reached or “connection failed” error is received in response to request VeReq. This error is the result interaction of payment gateway and MPS servers due to their technical failures.',
      '0-2019':
        'Issuer’s PARes contains iReq, due to which payment was declined.',
      '0-9000': 'Transactions start ',
      '01': 'Order with the given number is already registered in the system ',
      '0100': 'Issuer bank has forbidden online card transactions',
      '01001':
        'At the moment of registering the transaction, i.e. when the card details are not entered yet',
      '0101': 'Card’s validity period has expired ',
      '0103':
        'No connection with the issuer bank, the merchant must call the issuer bank',
      '0104': 'Attempt to make a transaction via blocked account',
      '0107': 'It is necessary to call the issuer bank',
      '0109':
        'Merchant’s/*terminal ID is invalid (for completion and preauthorization with different IDs)',
      '0110': 'Transaction amount is invalid',
      '0111': 'Card number is invalid',
      '0116': 'Transaction amount exceeds the available account balance ',
      '0120':
        'Transaction declined since it is not allowed by the issuer. Payment network code: 57. Reason for declining the transaction should be clarified from the issuer.',
      '0121':
        'Attempt to make a transaction exceeding the daily limit set by the issuer bank',
      '0123':
        'Limit on the number of transactions is exceeded. Client has made maximum number of transactions within the limit and attempts to make one more transaction',
      '0125':
        'Invalid Card number. Attempt to refund an amount exceeding the hold, attempt to refund zero amount',
      '0151017': '3-D Secure connection error',
      '0151018': 'Processing timeout, failed to send',
      '0151019':
        'Processing timeout, sent but no response received from the bank ',
      '02001':
        'Fraudulent transaction ( according to processing or payment network)',
      '02003':
        'Merchant is not allowed to perform SSL (Not 3d-Secure/SecureCode) transactions ',
      '02005': 'Payment does not comply with the  3ds verification terms ',
      '0208': 'Card is lost ',
      '0209': 'Card limitations are exceeded',
      '0341014': 'RBS decline error',
      '0341041': 'Refund error',
      '05': 'Error in request parameters ',
      '071015': 'Incorrect card parameters input',
      '08204': 'Such order has already been registered (check by order number)',
      '09001': 'Internal code of RBS rejection',
      '0902': 'Cardholder attempts to make a forbidden transaction ',
      '0903':
        'Attempt to make a transaction in the amount exceeding the limit set by the issuer bank ',
      '0904': 'Error message format according to issuer bank ',
      '0907':
        'No connection with the issuer bank. Stand-in authorization is not allowed for the given card number (this mode means that the issuer cannot connect to the payment network, so the transaction is either in offline mode and is then going to be sent to the back office or will be declined) ',
      '0909':
        'General system failure fixed by the payment network or the issuer bank ',
      '0910': 'Issuer bank is not available ',
      '0913': 'Invalid transaction format according to the network',
      '0914':
        'Transaction is not found (while sending completion, reversal or refund request)',
      '0999':
        'Transaction authorization did not start. Declined due to fraud or 3dsec error.',
      '02': 'Order declined due to errors in payment details',
      '03': 'Unknown (forbidden) currency',
      '04': 'Required parameter of the request is missing ',
      '06': 'Unregistered OrderId',
      '07': 'System error',
      20: 'Incorrect Username and Password',
      30: 'Incorrect Value of Opque field of the initial request',
      550: 'System Error',
    },
    sslConfiguration: {
      new: 'New',
      renew: 'Renew',
    },

    telcellPaymenStatus: {
      NEW: 'Invoice presented, not paid',
      PAID: 'Invoice successfully paid',
      REJECTED: 'Invoice is rejected by the recipient.',
      EXPIRED: 'Invoice expired.',
      CANCELLED: 'Invoice is cancelled by the staff',
      CANCELLEDFORREPEAT: 'Invoice canceled for reissue',
    },
    telcellPaymenInvoice: {
      invoice: 'Invoice',
    },

    telcellPaymenUnknownStatus: {
      unknownStatus: 'Payment not sucessful',
    },
  },
}
