import { TELCELL_PAYMENT_TYPES } from './TelcellPaymentPage.types'

const initialState = {
  loading: true,
  data: {},
  error: null,
}

export function telcellPaymentPage(state = initialState, action) {
  switch (action.type) {
    case TELCELL_PAYMENT_TYPES.GET_TELCELL_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        data: {},
        error: null,
      }
    case TELCELL_PAYMENT_TYPES.GET_TELCELL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      }
    case TELCELL_PAYMENT_TYPES.GET_TELCELL_DATA_FAIL:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.error,
      }

    default:
      return state
  }
}
