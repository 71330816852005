import { connect } from 'react-redux'

import { ResetPasswordPage as Self } from './ResetPasswordPage'
import { verifyHash, setPassword } from './ResetPasswordPage.actions'
import { showModal } from '../../common/Modal/Modal.actions'

const mapStateToProps = ({ resetPasswordPage }) => ({
  data: resetPasswordPage.data,
  loading: resetPasswordPage.loading,
  error: resetPasswordPage.error,
  setPasswordData: resetPasswordPage.setPasswordData,
  setPasswordLoading: resetPasswordPage.setPasswordLoading,
  setPasswordError: resetPasswordPage.setPasswordError,
})

const mapDispatchToProps = {
  verifyHash,
  setPassword,
  showModal,
}

export const ResetPasswordPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self)
