import styled from 'styled-components';
import { Link } from 'react-router-dom';
import closeModal from '../../../images/closeModal.svg';
import { Form, Field } from 'formik';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

export const Tabs = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;
  width: 70%;
  font-size: 16px;
  font-weight: 600;
`;

export const Tab = styled.div`
  margin: 15px;
  cursor: pointer;
  color: #484857;
  padding: 4px;
  @media (min-width: 768px) {
    font-size: 18px;
    padding-bottom: 8px;
  }
  border-bottom: ${(p) => (p.selected ? '2px solid #c3c5cb' : 'none')};
`;

export const LoginModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 1;
  z-index: 100;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  position: relative;

  @media (min-width: 768px) {
    padding: 23px 42px;
  }
`;

export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-size: cover;

  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`;

export const Modal = styled.div`
  height: 100%;
  box-sizing: border-box;
  width: 304px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  outline: none;
  z-index: 100;

  @media (min-width: 768px) {
    width: 396px;
  }
`;

export const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

export const FormSection = styled.div`
  display: flex;
  width: 425px;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid rgba(212, 212, 212, 0.4);
  padding-bottom: 28px;

  @media (min-width: 768px) {
    border: none;
    padding-bottom: 0px;
  }

  &:last-child {
    border: none;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
    border-bottom: 1px solid #d4d4d4;
    flex-wrap: wrap;
    width: 970px;
    justify-content: space-between;
    padding-bottom: 32px;
  }
`;

export const Radio = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 33px;

  &:last-child {
    margin: 0px;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;

  &.radio {
    justify-content: center;
  }

  @media (min-width: 768px) {
    margin-top: 21px;
  }
`;

export const ResetPasswordWrapepr = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  &.radio {
    justify-content: center;
  }

  @media (min-width: 768px) {
    margin-top: 14px;
  }
`;

export const ResetPassword = styled.div`
  font-size: 10px;
  color: #dc773c;
  cursor: pointer;
`;

export const Label = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 13px;

  &.radio {
    margin: 0px;
    margin-left: 7px;
    font-size: 10px;
    opacity: 0.38;
  }

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const Input = styled(Field)`
  width: 224px;
  padding: 5px 12px;
  max-height: 30px;
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;

  @media (min-width: 768px) {
    width: 300px;
    padding: 12px;
    max-height: 44px;
  }

  &.radio {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    -webkit-appearance: none;
    border: 1px solid #d4d4d4;
    padding: 0px;
    cursor: pointer;

    &:checked {
      border-color: #dc773c;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      margin: 20% auto;
      border-radius: 50%;
      left: 2px;
      top: 0px;
      background: #d4d4d4;
    }

    &:checked:before {
      background: #dc773c;
    }
  }
`;
export const LoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00c084;
  border-radius: 5px;
  width: 100%;
  height: 38px;
  margin-top: 23px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`;

export const RegisterNow = styled(Link)`
  font-size: 12px;
  color: #212121;
  margin-top: 18px;
  border-bottom: 1px solid #868686;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
`;
export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorWrapepr = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 2px;
`;

export const ResendEmail = styled.div`
  font-size: 12px;
  margin-top: 5px;
  margin-right: 2px;
  text-decoration: underline;
  cursor: pointer;
`;

export const ResendEmailDone = styled.div`
  font-size: 12px;
  margin-top: 5px;
  color: #00c084;
`;

export const Social = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
  width: 100%;
  height: 43px;
  align-items: center;

  > span {
    opacity: 1 !important;
    transition: none !important;
  }

  > span > button {
    width: 120px;
    height: 43px;
    background: #3c5a96;
    border-radius: 5px;
    padding: 5px;
    tex-align: center;
    margin-right: 5px;
    border: 1px solid transparent;
    &:hover {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24),
        0px 0px 2px rgba(0, 0, 0, 0.12);
    }
    @media (min-width: 768px) {
      width: 140px;
    }
  }
  > button {
    width: 120px;
    height: 43px;
    background: #ffffff;
    border-radius: 5px !important;
    padding: 5px;
    tex-align: center;
    margin-left: 5px;
    &:hover {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24),
        0px 0px 2px rgba(0, 0, 0, 0.12);
    }
    @media (min-width: 768px) {
      width: 140px;
    }
  }
  > button > div {
    width: 120px;
    padding: 0px;
    margin-right: 0px !important;
    &:hover {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24),
        0px 0px 2px rgba(0, 0, 0, 0.12);
    }
    @media (min-width: 768px) {
      width: 140px;
    }
  }
  > button > span {
    display: none;
  }
`;

export const BtnFacebook = styled(FacebookLogin)``;
export const BtnGoogle = styled(GoogleLogin)``;
