import FetchApi from '../../helpers/FetchApi'
import { REGISTRATION_TYPES } from './RegistrationPage.types'

export const register = (values) => async (dispatch) => {
  try {
    dispatch({
      type: REGISTRATION_TYPES.REGISTER_REQUEST,
    })

    await FetchApi.post('users/register', values)
    dispatch({
      type: REGISTRATION_TYPES.REGISTER_SUCCESS,
    })
    return true
  } catch (e) {
    dispatch({
      type: REGISTRATION_TYPES.REGISTER_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    })
    return false
  }
}

export const cleanRegisterError = () => ({
  type: REGISTRATION_TYPES.CLEAN_REGISTER_ERROR,
})

export const setToggleAutoPay = (status) => ({
  type: REGISTRATION_TYPES.TOGGLE_AUTOPAY,
  status,
})
