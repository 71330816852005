import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { HostingPackagesBlock as Self } from './HostingPackagesBlock';
import {
  addToCart,
  numberWithCommas,
} from '../../../../common/App/App.actions';

const mapStateToProps = ({ app }) => ({
  auth: app.auth,
});

const mapDispatchToProps = {
  addToCart,
  numberWithCommas,
};

export const HostingPackagesBlock = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
