import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const PaymentSuccessPageContainer = styled.div`
  height: 500px;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (min-width: 768px) {
    height: 600px;
  }
  @media (min-width: 1280px) {
    height: 700px;
  }
`

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 50px;
`

export const Text = styled.div`
  font-size: 14px;
  padding-top: 10px;
  text-align: flex-start;
  @media (min-width: 768px) {
    font-size: 22px;
  }
`

export const Image = styled.img`
  height: 150x;
  width: 150px;
  object-fit: contain;
  margin-bottom: 20px;
  margin-left: 35px;

  @media (min-width: 768px) {
    height: 250px;
    width: 250px;
  }
`

export const ErrorMessage = styled.div`
  font-size: 22px;
`
export const LoaderWrapper = styled.div`
  height: 50px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 5px;
  margin-top: 250px;
`
export const BackLinkBtn = styled(Link)`
  border-radius: 5px;
  border: none;
  padding: 4px 22px;
  margin-top: 17px;
  margin-bottom: 36px;
  background-color: #00c084;
  color: #ffffff;
  transition: 0.3s;
  text-decoration: none;
  font-size: 16px;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    font-size: 22px;
    padding: 8px 44px;
  }
`
