import styled from "styled-components";
import { Field } from "formik";

import Select from "react-select";

export const PersonalDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const FormSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid rgba(212, 212, 212, 0.4);
  padding-bottom: 28px;

  @media (min-width: 768px) {
    justify-content: center;
    width: 700px;
  }

  &:last-child {
    border: none;
  }

  @media (min-width: 1280px) {
    border-bottom: 1px solid #d4d4d4;
    width: 100%;
    padding-bottom: 32px;
    justify-content: space-between;
  }
`;
export const FormSectionBottom = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 28px;

  @media (min-width: 768px) {
    justify-content: center;
    width: 700px;
  }

  &:last-child {
    border: none;
  }

  @media (min-width: 1280px) {
    width: 100%;
    padding-bottom: 32px;
    justify-content: space-between;
  }
`;

export const FormItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  width: 250px;
  align-items: flex-start;

  @media (min-width: 768px) {
    margin-top: 47px;
  }
  @media (min-width: 1280px) {
    width: 400px;
  }
  @media (min-width: 1600px) {
    width: 490px;
  }
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  width: 100%;

  &.radio {
    justify-content: center;
  }

  .country-list {
    width: calc(100vw - 60px);
  }
  &.phoneInput {
    .react-tel-input {
      > input {
        width: calc(100% - 41px);
        z-index: 5;
        float: right;
      }
      .flag-dropdown {
        width: 100%;
        border-radius: 5px;
      }
      .form-control {
        padding-left: 10px;
        border-radius: 0 5px 5px 0px;
        width: 100% !important;
      }
    }
  }

  @media (min-width: 768px) {
    &.phoneInput {
      .react-tel-input {
        input {
          height: 41px;
        }
      }
    }
    margin-top: 47px;
    width: 411px !important;
    .country-list {
      width: 411px;
    }
  }
  .form-control {
    width: 100% !important;
    height: 38px;
  }
`;

export const Label = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 17px;

  &.radio {
    margin: 0px;
    margin-left: 7px;
    opacity: 0.38;
  }

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const Input = styled(Field)`
  width: auto;
  padding: 12px;
  max-height: 44px;
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;

  @media (min-width: 1280px) {
    width: unset;
  }

  &.radio {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    -webkit-appearance: none;
    border: 1px solid #d4d4d4;
    padding: 0px;
    cursor: pointer;

    &:checked {
      border-color: #dc773c;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      margin: 20% auto;
      border-radius: 50%;
      left: 4px;
      top: 0px;
      background: #d4d4d4;
    }

    &:checked:before {
      background: #dc773c;
    }
  }
`;

export const TextField = styled.div`
  width: auto;
  max-height: 44px;
  outline: none;
  position: relative;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #000000;
  padding-top: 5px;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
  ${(p) => p.spaced && "margin-top: 20px;"};
`;

export const SaveButton = styled.button`
  width: 129px;
  height: 38px;
  border-radius: 5px;
  background: ${(p) => (p.disabled ? "#888e9b" : "#00c084")};
  margin-top: 52px;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  border: none;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  @media (min-width: 1280px) {
    width: unset;
  }
`;
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
