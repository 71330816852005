import styled from 'styled-components';
import { Form, Field } from 'formik';

import closeModal from '../../../images/closeModal.svg';

export const GlueRecordsModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 1;
  z-index: 100;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
  @media (min-width: 768px) {
    width: 600px;
    padding: 23px 42px;
    margin-left: 0;
    margin-right: 0;
  }
  @media (min-width: 1280px) {
    width: 800px;
  }
`;

export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-size: cover;

  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`;

export const Modal = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  outline: none;
  z-index: 100;
  text-align: center;
  @media (min-width: 768px) {
    width: auto;
  }
`;

export const ModalTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #242e46;
`;

export const ModalHeader = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #242e46;
  margin-top: 30px;
`;

export const FormContainer = styled.div`
  width: 100%;
  @media (min-width: 1280px) {
    padding: 20px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (min-width: 1280px) {
    flex-direction: row;
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding: 20px;
  width: auto;
  @media (min-width: 768px) {
    background: #ffffff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }

  @media (min-width: 1280px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    padding: 0px 40px;
    width: auto;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 1280px) {
    width: 40%;
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 1280px) {
    width: 40%;
  }
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
  height: 91px;
  @media (min-width: 768px) {
    align-items: center;
    margin-top: 25px;
  }
  @media (min-width: 1280px) {
    margin-top: 40px;
  }
`;

export const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
  text-align: left;

  @media (min-width: 768px) {
    width: 425px;
    margin-bottom: 10px;
    font-size: 18px;
  }

  @media (min-width: 1280px) {
    width: 100%;
    font-size: 18px;
    margin-bottom: 15px;
  }
`;

export const Input = styled(Field)`
  padding: 5px 12px;
  max-height: 44px;
  width: 100%;
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  text-align: left;
  margin-bottom: 5px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    width: 425px;
    padding: 12px;
    max-height: 44px;
    font-size: 16px;
  }
  @media (min-width: 1280px) {
    width: 100%;
    padding: 12px;
    max-height: 44px;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 0px;
  }
`;

export const SubmitButton = styled.button`
  background-color: ${(p) => (p.disabled ? '#888e9b' : '#242e46')};
  border-radius: 5px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  opacity: 0.87;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 7px 50px;
  transition: 0.3s;
  cursor: ${(p) => (p.disabled ? 'initial' : 'pointer')};

  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }

  @media (min-width: 768px) {
    padding: 11px 65px;
    font-size: 18px;
    align-self: center;
    margin-bottom: 10px;
  }
  @media (min-width: 1280px) {
    padding: 12px 65px;
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 50px;
    width: 30%;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
`;

export const MainErrorMessage = styled(ErrorMessage)`
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  @media (min-width: 768px) {
    margin-top: 35px;
  }
  @media (min-width: 1280px) {
    margin-top: 69px;
  }
`;

export const LoaderWrapper = styled.div`
  height: 50px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 5px;
  margin-top: 250px;
`;
