import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { LanguagesPopup as Self } from './LanguagesPopup'
import {
  showLanguagesPopup,
  changeBackendLanguage,
} from '../../../common/App/App.actions'

const mapStateToProps = ({ app }) => ({
  isShown: app.languagesPopupIsShown,
  auth: app.auth,
})

const mapDispatchToProps = {
  showLanguagesPopup,
  changeBackendLanguage,
}

export const LanguagesPopup = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Self)
)
