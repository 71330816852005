import React from 'react'
import * as S from '../SslConfigurationPage.styles'
import { Formik, Form } from 'formik'
import { getInitialValues, yupScheme } from './SecondStep.config'
import { Loader } from '../../../components/Loader'
import { changeUrl } from '../../../hooks/changeUrl'
import { i18n } from '../../../i18n'

export const SecondStep = ({
  step,
  goBack,
  handleContinue,
  domain,
  emails,
  methods,
  initialValues,
  loading,
  orderId,
  history,
  nextStepData,
  id,
}) => {
  localStorage.setItem('process', true)

  return (
    <>
      <S.FormHeader>{i18n.t('sslConfiguration.header2')}</S.FormHeader>
      <Formik
        enableReinitialize
        initialValues={getInitialValues(initialValues)}
        validationSchema={yupScheme}
        onSubmit={() => {}}
      >
        {({ touched, errors, handleChange, values, setFieldValue, dirty }) => {
          const formattedValues = {
            approveremail: values.address.value,
            approval_method: values.method.value,
            step: '3',
            ssl_order_id: orderId,
            dcvmethodMainDomain: values.method.label,
          }

          return (
            <Form>
              <S.ApproverEmailForm>
                <S.FormItem>
                  <S.Label>{i18n.t('sslConfiguration.domain')}</S.Label>
                  <S.Input value={values.domain} readOnly />
                </S.FormItem>
                <S.FormItem>
                  <S.Label>{i18n.t('sslConfiguration.method')}</S.Label>
                  <S.StyledSelect
                    value={values.method}
                    options={methods}
                    onChange={(option) => {
                      setFieldValue('method', option)
                    }}
                    classNamePrefix="react-select"
                  />
                </S.FormItem>
                {values.method.value === 'email' ? (
                  <S.FormItem>
                    <S.Label>{i18n.t('sslConfiguration.email')}</S.Label>
                    <S.StyledSelect
                      value={values.address}
                      options={emails}
                      onChange={(option) => {
                        setFieldValue('address', option)
                      }}
                      classNamePrefix="react-select"
                    />
                  </S.FormItem>
                ) : null}
              </S.ApproverEmailForm>
              <S.ButtonsWrapper>
                <S.Button
                  type="button"
                  onClick={() =>
                    history.push(
                      changeUrl(`/ssl-configuration/${orderId}/${id}/1`)
                    )
                  }
                >
                  {i18n.t('sslConfiguration.back')}
                </S.Button>
                <S.Button
                  type="submit"
                  onClick={() => handleContinue(formattedValues, 3)}
                >
                  {loading ? (
                    <Loader color="#FFFFFF" size={0.5} />
                  ) : (
                    i18n.t('sslConfiguration.continue')
                  )}
                </S.Button>
              </S.ButtonsWrapper>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
