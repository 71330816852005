import React from 'react'
import { Helmet } from 'react-helmet'

import { LayoutCommon } from '../../layouts/LayoutCommon'
import * as S from './ContactsPage.styles'
import { config } from './ContactsPage.config'
import MapWithAMarker from './GoogleMap'
import { i18n } from '../../i18n'

import { changeUrl } from '../../hooks/changeUrl'

const ContactsPage = () => (
  <LayoutCommon>
    <Helmet>
      <title>{i18n.t('headers.contactsPage')}</title>
      <meta
        name="description"
        content={i18n.t('headersDescriptions.contactsPage')}
      />
      <meta property="og:title" content={i18n.t('headers.contactsPage')} />
      <meta
        property="og:description"
        content={i18n.t('headersDescriptions.contactsPage')}
      />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="ABC Domain LLC" />
      <meta
        property="og:image"
        content="https://internet.am/images/fb_logo.png"
      />
    </Helmet>
    <S.PageBody>
      <S.ContactBodyContainer>
        <S.ContactTitle>{i18n.t('contactUsPage.title')}</S.ContactTitle>
        <S.ContactHeader>{i18n.t('contactUsPage.header')}</S.ContactHeader>

        <S.ContainerOfContactCarts>
          {config.map((item) => (
            <S.ContactCartDiv key={item.id}>
              <S.ContactCartHeader>
                <S.ContactCartTitle>{i18n.t(item.title)}</S.ContactCartTitle>
              </S.ContactCartHeader>
              <S.ContactCartDescription>
                {i18n.t(item.description)}
              </S.ContactCartDescription>
              <S.ContactInfo>{i18n.t(item.info)}</S.ContactInfo>
              <S.ContactSupport to={changeUrl('/open-ticket')}>
                {i18n.t(item.support)}
              </S.ContactSupport>
              <S.ContactMail href={i18n.t(item.href)}>
                {i18n.t(item.mail)}
              </S.ContactMail>
            </S.ContactCartDiv>
          ))}
        </S.ContainerOfContactCarts>
        {false && (
          <S.MapContainer>
            <MapWithAMarker
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBCudvHJO__r02vSqoLLyAcP_YR6vB_7Ds&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<S.MapInnerContainer />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </S.MapContainer>
        )}
      </S.ContactBodyContainer>
    </S.PageBody>
  </LayoutCommon>
)

export { ContactsPage }
