import { SEARCH_AREA_TYPES } from './SearchAreaBlock.types';

export const initialState = {
  tlds: [],
};

function searchAreaBlock(state = initialState, action) {
  switch (action.type) {
    case SEARCH_AREA_TYPES.TOGGLE_TLD: {
      const tldsCopy = [...state.tlds];
      if (tldsCopy.indexOf(action.tld) > -1) {
        tldsCopy.splice(tldsCopy.indexOf(action.tld), 1);
      } else {
        tldsCopy.push(action.tld);
      }
      return {
        ...state,
        tlds: tldsCopy,
      };
    }
    case SEARCH_AREA_TYPES.CUT_TLD: {
      const tldsCopy = [...state.tlds];
      if (action.domain.indexOf('.') > -1) {
        const tldSplitted = action.domain.split('.');
        tldSplitted.shift();
        tldsCopy.push(`.${tldSplitted.join('.')}`);
      }
      const uniqueTlds = Array.from(new Set(tldsCopy));
      return {
        ...state,
        tlds: uniqueTlds,
      };
    }

    default:
      return state;
  }
}

export { searchAreaBlock };
