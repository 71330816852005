import { connect } from 'react-redux';
import { SslCard as Self } from './SslCard';
import { addToCart, numberWithCommas } from '../../common/App/App.actions';
import { withTranslation } from 'react-i18next';

const mapStateToProps = ({ app }) => ({
  auth: app.auth,
});

const mapDispatchToProps = {
  addToCart,
  numberWithCommas,
};

export const SslCard = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
