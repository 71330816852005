import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Header as Self } from "./Header";
import { toggleMenu, setOpaque } from "./Header.actions";

const mapStateToProps = ({ app, header }) => ({
  isExpanded: header.isExpanded,
  dimensions: app.dimensions,
  shouldBeOpaque: header.shouldBeOpaque,
});

const mapDispatchToProps = {
  toggleMenu,
  setOpaque,
};

export const Header = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
