import { EditProfilePage as Self } from './EditProfilePage'
import { connect } from 'react-redux'
import { changeTab } from './EditProfilePage.actions'

const mapStateToProps = ({ editProfile }) => ({
  tab: editProfile.tab,
})

const mapDispatchToProps = {
  changeTab,
}

export const EditProfilePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self)
