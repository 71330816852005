import { PERSONAL_DETAILS_TYPES } from './PersonalDetails.types';

const initialState = {
  loading: false,
  error: null,
  info: {},
  updateLoading: false,
  updateError: false,
};

export const userInfo = (state = initialState, action) => {
  switch (action.type) {
    case PERSONAL_DETAILS_TYPES.GET_USER_INFO_REQUEST:
      return {
        ...state,
        error: null,
      };
    case PERSONAL_DETAILS_TYPES.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        info: action.info,
        error: null,
      };
    case PERSONAL_DETAILS_TYPES.GET_USER_INFO_FAIL:
      return {
        ...state,
        error: action.error,
        info: {},
      };
    case PERSONAL_DETAILS_TYPES.UPDATE_USER_INFO_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: null,
      };
    case PERSONAL_DETAILS_TYPES.UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: null,
        info: {
          ...state.info,
          ...action.values
        }
      };
    case PERSONAL_DETAILS_TYPES.UPDATE_USER_INFO_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: action.error,
      };

    default:
      return state;
  }
};
