import styled from 'styled-components'

export const FilterPopup = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  max-height: 200px;
  border-bottom: 1px solid #212121;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0px 0px;
  padding-top: 16px;
  padding-bottom: 16px;
  right: 5px;
  bottom: 30px;
  @media (min-width: 768px) {
    right: 15px;
    bottom: 80px;
  }
  @media (min-width: 1280px) {
  }
`
export const Status = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: ${(p) => (p.isActive ? '#FFFFFF' : '#242e46')};
  background: ${(p) => (p.isActive ? '#242E46' : 'none')};
  text-align: center;
  cursor: pointer;
  padding: 8px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
`
