import styled from 'styled-components'
import { Link } from 'react-router-dom'
import closeModal from '../../../images/closeModal.svg'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media (min-width: 1280px) {
    display: none;
  }
`

export const Content = styled.div``

export const MobileMenuContainer = styled.ul`
  display: flex;
  height: 100%;
  width: 250px;
  height: 100%;
  background-color: white;
  transform: translateX(-300px);
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  list-style-type: none;
  justify-content: space-between;
  &.MobileMenu-enter-active {
    transform: translateX(-300px);
  }

  &.MobileMenu-enter {
    transform: translateX(-300px);
  }

  &.MobileMenu-enter-done {
    transform: translateX(0);
  }

  &.MobileMenu-exit {
    transform: translateX(0);
  }

  &.MobileMenu-exit-active {
    transform: translateX(-300px);
  }
  transition: 0.2s;
  @media (min-width: 768px) {
    width: 300px;
  }
`

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`

export const IDContainer = styled.div`
  font-size: 18px;
  padding: 30px;
  margin-bottom: 10px;
`

export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;

  &.LanguageItem {
    display: none;
    @media (min-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (min-width: 1280px) {
      display: none;
    }
  }
  @media (min-width: 1280px) {
    display: ${(p) => (p.hideOnDesktop ? 'none' : 'flex')};
  }
`

export const InlineLink = styled(Link)`
  display: flex;
  text-align: center;
  outline: none;
  padding: 30px;
  text-decoration: none;
  color: black;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`

export const LogoutButton = styled.button`
  display: flex;
  text-align: center;
  outline: none;
  padding: 28px;
  text-decoration: none;
  color: black;
  font-weight: 300;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`

export const CloseModalIcon = styled.div`
  background: url(${closeModal});
  width: 16px;
  height: 16px;
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
  background-size: cover;

  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`

export const Logo = styled.img`
  height: 24px;
  width: 20px;
`

export const LanguageLogo = styled.img`
  cursor: ${(p) => (p.selected ? 'manual' : 'pointer')};
  width: 28px;
  height: 28px;
  opacity: ${(p) => (p.selected ? 0.3 : 1)};
`

export const LagnuagesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 30px;

  @media (min-width: 768px) {
    padding: 0 20px 30px 20px;
  }
`
export const BlogContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
`
export const Blog = styled.a`
  display: flex;
  text-align: center;
  outline: none;
  padding: 30px;
  text-decoration: none;
  color: black;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`

export const BlocIcon = styled.img`
  height: 24px;
  width: 20px;
`
