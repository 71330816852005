import styled from 'styled-components';
import { Link } from 'react-router-dom';

import loginIcon from '../../../images/login.svg';

export const CartLogo = styled.img`
  width: 25px;
  height: 27px;
  margin-right: 20px;
`;

export const CartLogoButton = styled.button`
  display: flex;
  border: none;
  align-items: center;
  position: relative;
  padding: 10px 5px;
  @media (min-width: 400px) {
    margin-right: 10px;
    padding: 10px;
  }
`;

export const RegisterBtn = styled(Link)`
  display: none;
  align-items: center;
  border: 1px solid black;
  border-radius: 5px;
  background-color: transparent;
  padding: 10px 18px;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  color: black;
  transition: 0.3s;
  &:hover {
    color: #ffffff;
    background-color: #242e46;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 1280px) {
    display: flex;
  }
`;

export const LoginIconContainer = styled.button`
  display: flex;
  padding: 10px 5px;
  border: none;
  align-items: center;
  position: relative;
  transition: 0.3s;

  @media (min-width: 400px) {
    padding: 10px;
  }
  @media (min-width: 1280px) {
    display: none;
  }
`;

export const LoginIcon = styled.div`
  background: url(${loginIcon});
  width: 32px;
  height: 26px;
`;

export const LoginBtn = styled.div`
  display: none;
  background-color: transparent;
  border: none;
  font-weight: 400;
  outline: none;
  padding: 10px 18px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @media (min-width: 1280px) {
    display: block;
  }
`;

export const LanguageLogo = styled.img`
  width: 28px;
  height: 28px;
`;

export const LanguageSwitchButton = styled.button`
  display: none;
  border: none;
  background-color: transparent;
  align-items: center;
  @media (min-width: 1280px) {
    display: flex;
  }
`;

export const RightNavBarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
  @media (min-width: 1280px) {
    width: unset;
  }
`;

export const CartCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 14px;
  height: 14px;
  background: #dd2326;
  color: #ffffff;
  font-weight: normal;
  font-size: 10px;
  border-radius: 45px;
  text-align: center;
  top: 3px;
  right: 15px;
  z-index: 3;
`;

export const WarningIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 45px;
  color: #ffffff;
  background: #ffb400;
  font-weight: normal;
  font-size: 10px;
  text-align: center;
  top: -2px;
  right: 6px;
  z-index: 2;
  &::after {
    content: '!';
    font-size: 12px;
  }
`;

export const AvatarContainer = styled.div`
  padding: 10px 10px;
  cursor: pointer;
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #48a789;
  margin-right: 20px;
`;
