import { connect } from 'react-redux'
import { RegistrationPage as Self } from './RegistrationPage'
import { register, cleanRegisterError } from './RegistrationPage.actions'
import { showModal } from '../../common/Modal/Modal.actions'

const mapStateToProps = ({ registrationPage }) => ({
  loading: registrationPage.loading,
  error: registrationPage.error,
  cleanError: registrationPage.cleanError,
  autopay: registrationPage.autopay,
})

const mapDispatchToProps = {
  register,
  cleanRegisterError,
  showModal,
}

export const RegistrationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self)
