import qs from 'qs'
import { createBrowserHistory, createMemoryHistory } from 'history'

export const useSearchParams = () => {
  const history =
    typeof window === 'undefined'
      ? createMemoryHistory({
          initialEntries: ['/'],
        })
      : createBrowserHistory()
  const queryObject = qs.parse(history.location.search.replace('?', ''))

  const updateSearchParams = (params = {}) => {
    const queryString = qs.stringify(params)

    history.replace(`${history.location.pathname}?${queryString}`)
  }

  return [queryObject, updateSearchParams]
}
