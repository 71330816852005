import React, { useState, useEffect, useCallback } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll'
import * as S from './Modal.styles'

export const Modal = ({
  hideModal,
  ModalComponent,
  componentProps,
  isVisible,
}) => {
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    setAnimate(false)
    setTimeout(() => {
      setAnimate(true)
    }, 50)
  }, [isVisible])

  useLockBodyScroll(isVisible)

  const hideModalWithAnimate = useCallback(() => {
    setAnimate(false)
    setTimeout(() => {
      hideModal(false)
      setAnimate(true)
    }, 200)
  }, [setAnimate, hideModal])

  useEffect(() => {
    document.addEventListener('keydown', handleModalOnEsc, false)
    return () => {
      document.removeEventListener('keydown', handleModalOnEsc, false)
    }
  }, [])
  const handleModalOnEsc = (event) => {
    if (event.keyCode === 27) {
      hideModal()
    }
  }

  return (
    isVisible && (
      <S.Container>
        <S.Backdrop onClick={hideModalWithAnimate} />
        <CSSTransition in={animate} classNames="Modal" timeout={1}>
          {() => (
            <S.ModalContainer
              onKeyPress={handleModalOnEsc}
              $veryLargeModal={componentProps.veryLargeModal}
            >
              <ModalComponent
                {...componentProps}
                hideModal={hideModalWithAnimate}
              />
            </S.ModalContainer>
          )}
        </CSSTransition>
      </S.Container>
    )
  )
}
