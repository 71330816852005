import React, { useRef, useCallback } from 'react'
import Cookies from 'js-cookie'
import qs from 'qs'

import * as S from './LanguagesPopup.styles'
import { languages } from './LanguagesPopup.config'
import { i18n } from '../../../i18n'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'

export const LanguagesPopup = ({
  showLanguagesPopup,
  isShown,
  changeBackendLanguage,
  isDesktop,
  history,
  auth,
}) => {
  const containerRef = useRef(null)
  useOnClickOutside(containerRef, () => showLanguagesPopup(false))

  const handleChangeUrl = useCallback((lang) => {
    if (history && history.location) {
      const params = qs.parse(history.location, {
        ignoreQueryPrefix: true,
        encode: true,
      })

      const url = params.pathname

      if (url.includes('/ru/') || url.includes('/en/')) {
        if (lang === 'am') {
          const amUrl = params.pathname.slice(3) + params.search
          history.push(`${amUrl}`)
        } else if (lang === 'ru') {
          const ruUrl = params.pathname.slice(3) + params.search
          history.push(`/ru${ruUrl}`)
        } else if (lang === 'en') {
          const enUrl = params.pathname.slice(3) + params.search
          history.push(`/en${enUrl}`)
        }
      } else {
        history.push(
          '/' + lang + window.location.pathname + window.location.search
        )
      }
    }
  })

  const switchLanguage = (lang) => {
    Cookies.set('lng', lang)
    i18n.changeLanguage(lang)
    auth.token && changeBackendLanguage(lang)
    showLanguagesPopup(false)
    handleChangeUrl(lang)
  }

  if (!isShown) {
    return null
  }

  return (
    <S.Languages ref={containerRef} isDesktop={isDesktop}>
      {languages.map(
        (item) =>
          i18n.language !== item.id && (
            <S.LanguageContainer
              onClick={() => {
                switchLanguage(item.id)
                showLanguagesPopup(false)
              }}
              key={item.id}
            >
              <S.Flag img={item.flag} />
              <S.Text lng={item.text}>{item.text}</S.Text>
            </S.LanguageContainer>
          )
      )}
    </S.Languages>
  )
}
