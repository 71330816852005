import React, { useEffect, useRef } from "react";
import * as S from "./MobileSortPopup.styles";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { i18n } from "../../../../i18n";

import SortZA from "../../../../images/sortZA.jsx";
import SortAZ from "../../../../images/sortAZ.jsx";
import Sort91 from "../../../../images/sort91.jsx";
import Sort19 from "../../../../images/sort19.jsx";

export const MobileSortPopup = ({
  showSortPopup,
  isShown,
  sortNumber,
  offset,
  sortOrder,
  sortBy,
  fields,
  toggleSortPopup,
  cb,
}) => {
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, () => showSortPopup(-1));

  if (isShown !== sortNumber) return null;

  const isAscSort = sortOrder === "asc";
  const toggleSort = (sortingBy) => {
    if (sortingBy === sortBy) {
      cb(offset, isAscSort ? "desc" : "asc", sortingBy);
    } else cb(offset, "asc", sortingBy);
  };

  return (
    <S.MobileSortingDiv ref={containerRef}>
      {fields.map((header) => (
        <>
          {header.sortBy !== "status" &&
            header.sortBy !== "deptid" &&
            header.sortBy !== "subject" &&
            header.sortBy !== "subtotal" &&
            header.sortBy !== "billingcycle" && (
              <S.Wrapper
                onClick={() => {
                  toggleSortPopup();
                }}
                sortType={header.sortType}
                isChecked={
                  (sortBy === header.sortBy && !isAscSort) ||
                  (sortBy === header.sortBy && isAscSort)
                }
              >
                <S.Header
                  isChecked={
                    (sortBy === header.sortBy && !isAscSort) ||
                    (sortBy === header.sortBy && isAscSort)
                  }
                >
                  {i18n.t(header.title)}
                </S.Header>

                {header.sortBy !== "subject" &&
                  header.sortBy !== "deptid" &&
                  header.sortBy !== "status" && (
                    <S.Sort>
                      {header.sortType === "number" && (
                        <>
                          <S.SortDesc
                            isChecked={
                              (sortBy === header.sortBy && !isAscSort) ||
                              (sortBy === header.sortBy && isAscSort)
                            }
                            onClick={() => toggleSort(header.sortBy)}
                          >
                            {sortBy === header.sortBy && isAscSort ? (
                              <Sort91
                                fillcolor={
                                  (sortBy === header.sortBy && !isAscSort) ||
                                  (sortBy === header.sortBy && isAscSort)
                                    ? "#fff"
                                    : ""
                                }
                              />
                            ) : (
                              <Sort19
                                fillcolor={
                                  (sortBy === header.sortBy && !isAscSort) ||
                                  (sortBy === header.sortBy && isAscSort)
                                    ? "#fff"
                                    : ""
                                }
                              />
                            )}
                          </S.SortDesc>
                        </>
                      )}
                      {header.sortType === "text" && (
                        <>
                          <S.SortDesc
                            isChecked={
                              (sortBy === header.sortBy && !isAscSort) ||
                              (sortBy === header.sortBy && isAscSort)
                            }
                            onClick={() => toggleSort(header.sortBy)}
                          >
                            {sortBy === header.sortBy && isAscSort ? (
                              <SortZA
                                fillcolor={
                                  (sortBy === header.sortBy && !isAscSort) ||
                                  (sortBy === header.sortBy && isAscSort)
                                    ? "#fff"
                                    : ""
                                }
                              />
                            ) : (
                              <SortAZ
                                fillcolor={
                                  (sortBy === header.sortBy && !isAscSort) ||
                                  (sortBy === header.sortBy && isAscSort)
                                    ? "#fff"
                                    : ""
                                }
                              />
                            )}
                          </S.SortDesc>
                        </>
                      )}
                    </S.Sort>
                  )}
              </S.Wrapper>
            )}
        </>
      ))}
    </S.MobileSortingDiv>
  );
};
