import styled from 'styled-components'

export const SortingDiv = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    border: none;
    bottom: 80px;
  }
`

export const SortDesc = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  // width: 50%;
  justify-content: center;
  border-radius: 0px 5px 0px 0px;
  background: ${(p) => (p.isChecked ? '#242e46' : 'none')};

  &:hover {
    box-shadow: -1px 0 5px -2px #888;
  }
`
export const Sort = styled.div`
  align-items: center;
  // width: 50px;
`

export const Header = styled.div`
  color: ${(p) => (p.isChecked ? '#fff' : 'none')};
  margin-left: 20px;
  font-size: 14px;
`
