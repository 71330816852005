import styled from 'styled-components';

import * as fontWeight from '../../assets/styles/constants/fontWeight';

export const PageTitle = styled.div`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 24px;
  color: #484857;
`;

export const RegistrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 40px 20px;
  min-height: 300px;
  ${(p) => p.centered && `justify-content: center;`};

  @media (min-width: 768px) {
    padding: 130px 230px;
  }

  @media (min-width: 1280px) {
    padding: 70px 230px 130px 230px;
  }
`;

export const InformationText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 26px;

  @media (min-width: 768px) {
    width: 690px;
    font-size: 18px;
  }

  @media (min-width: 1280px) {
    width: 798px;
    margin-top: 29px;
  }
`;

export const StyledImage = styled.img`
  @media (min-width: 768px) {
    width: 400px;
  }
  width: 300px;
  margin-bottom: 20px;
`;

export const FailText = styled.div`
  color: #242e46;
  font-size: 18px;
  margin-bottom: 65px;
`;
