import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { showModal } from '../../common/Modal/Modal.actions'
import {
  addOrder,
  removeFromCart,
  updateCart,
  numberWithCommas,
  setDomainContact,
} from '../../common/App/App.actions'
import { OrderDetailsPage as Self } from './OrderDetailsPage'

const mapStateToProps = ({ app, loginModal }) => ({
  auth: app.auth,
  cart: app.cart,
  contacts: loginModal.contacts,
  dimensions: app.dimensions,
  addOrderLoading: app.addOrderLoading,
  addOrderError: app.addOrderError,
  contactId: app.contactId,
})

const mapDispatchToProps = {
  showModal,
  addOrder,
  updateCart,
  removeFromCart,
  numberWithCommas,
  setDomainContact,
}

export const OrderDetailsPage = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
)
