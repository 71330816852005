import * as Yup from 'yup'

const initialState = {
  code: '',
}

const yupScheme = Yup.object().shape({
  code: Yup.string()
    .min(6, 'validationFields.wrongCode')
    .max(6, 'validationFields.wrongCode')
    .matches(/^[0-9]+$/, 'validationFields.codeRequired')
    .required('validationFields.codeRequired'),
})

export { initialState, yupScheme }
