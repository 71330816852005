import React, { useEffect } from 'react'
import { Formik } from 'formik'
import { Helmet } from 'react-helmet'

import { LayoutCommon } from '../../layouts/LayoutCommon'
import { initialValues, yupScheme } from './CreditRechargePage.config'
import * as S from './CreditRechargePage.styles'
import { i18n } from '../../i18n'
import { Loader } from '../../components/Loader'

import { changeUrl } from '../../hooks/changeUrl'

const CreditRechargePage = ({
  auth,
  history,
  creditRecharge,
  loading,
  error,
}) => {
  useEffect(() => {
    if (!auth.creditRecharge) {
      history.push(changeUrl('/'))
    }
  }, [auth])
  return (
    <LayoutCommon>
      <Helmet>
        <title>{i18n.t('headers.creditRechargePage')}</title>
        <meta
          name="description"
          content={i18n.t('headersDescriptions.creditRechargePage')}
        />
        <meta name="og:title" content={i18n.t('headers.creditRechargePage')} />
        <meta
          name="og:description"
          content={i18n.t('headersDescriptions.creditRechargePage')}
        />
        <meta
          name="og:image"
          content="https://internet.am/images/fb_logo.png"
        />
      </Helmet>
      <S.PageBody>
        <S.DomainBodyContainer>
          <S.DomainTitle>{i18n.t('creditRecharge.title')}</S.DomainTitle>
          <S.DomainHeader>{i18n.t('creditRecharge.header')}</S.DomainHeader>
          <Formik
            initialValues={initialValues}
            validationSchema={yupScheme}
            onSubmit={async (values, { setSubmitting }) => {
              creditRecharge(values.amount, (invoiceId) => {
                history.push(changeUrl(`/invoice/?id=${invoiceId}`))
              })
              setSubmitting(false)
            }}
          >
            {({ values, handleChange, isSubmitting, errors, touched }) => (
              <>
                <S.FormContainer>
                  <S.Input
                    name="amount"
                    placeholder={i18n.t('creditRecharge.placeholder')}
                    value={values.amount}
                    onChange={handleChange}
                  />

                  <S.SubmitButton type="submit" disabled={isSubmitting}>
                    {loading ? (
                      <Loader color="#FFF" size={0.5} />
                    ) : (
                      i18n.t('creditRecharge.submit')
                    )}
                  </S.SubmitButton>
                </S.FormContainer>
                {touched.amount && errors.amount && (
                  <S.ErrorMessage spaced>
                    {i18n.t(errors.amount)}
                  </S.ErrorMessage>
                )}
                {error && <S.ErrorMessage spaced>{error}</S.ErrorMessage>}
              </>
            )}
          </Formik>
        </S.DomainBodyContainer>
      </S.PageBody>
    </LayoutCommon>
  )
}

export { CreditRechargePage }
