export const ifError = (item, errors, touched) => {
  return (
    item.id.includes('.') &&
    errors[item.id.split('.')[0]] &&
    errors[item.id.split('.')[0]][item.id.split('.')[1]] &&
    touched[item.id.split('.')[0]] &&
    touched[item.id.split('.')[0]][item.id.split('.')[1]]
  );
};

export const errorMessage = (item, errors) =>
  errors[item.id.split('.')[0]][item.id.split('.')[1]];
