import { createGlobalStyle } from 'styled-components'

import * as fontWeight from './assets/styles/constants/fontWeight'

const GlobalStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  
  body {
    overflow-x: hidden;
    background-color: #242E46;        
    font-family: ${(p) =>
      ['en', 'ru'].includes(p.language) ? 'Roboto' : 'Mardoto'};
    font-weight: ${fontWeight.REGULAR};
    margin: 0;
  }

  textarea {
    font-family: ${(p) =>
      ['en', 'ru'].includes(p.language) ? 'Roboto' : 'Mardoto'};
    font-weight: ${fontWeight.REGULAR};   
  }

  button {
    background-color: transparent;
    border: none;
    font-family: ${(p) =>
      ['en', 'ru'].includes(p.language) ? 'Roboto' : 'Mardoto'};
    cursor: pointer;
    outline: none;
  }

  input {
    outline: none;
  }

  .tooltipContainer {
    &:hover {
      .tooltip {
        display: block;
      }
    }
  }

  
  
`

export default GlobalStyles
