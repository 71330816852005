import { HOSTING_TYPES } from './HostingPage.types';
import { HOSTING_TABS } from './HostingPage.constants';

const initialState = {
  tab: HOSTING_TABS.LINUX,
  expandedCard: null,
};

function hostingPage(state = initialState, action) {
  switch (action.type) {
    case HOSTING_TYPES.CHANGE_TAB:
      return {
        ...state,
        tab: action.tab,
      };
    case HOSTING_TYPES.EXPAND_CARD:
      return {
        ...state,
        expandedCard: state.expandedCard === action.id ? null : action.id,
      };
    default:
      return state;
  }
}

export { hostingPage };
