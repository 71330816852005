export const CLIENT_AREA_TYPES = {
  CHANGE_TAB: 'CLIENT_AREA_TYPES.CHANGE_TAB',
  SHOW_FILTER_POPUP: 'CLIENT_AREA_TYPES.SHOW_FILTER_POPUP',
  TOGGLE_FILTER_POPUP: 'CLIENT_AREA_TYPES.TOGGLE_FILTER_POPUP',
  SHOW_SETTINGS_POPUP: 'CLIENT_AREA_TYPES.SHOW_SETTINGS_POPUP',
  TOGGLE_SETTINGS_POPUP: 'CLIENT_AREA_TYPES.TOGGLE_SETTINGS_POPUP',
  SHOW_SORT_POPUP: 'CLIENT_AREA_TYPES.SHOW_SORT_POPUP',
  TOGGLE_SORT_POPUP: 'CLIENT_AREA_TYPES.TOGGLE_SORT_POPUP',
  GET_DOMAINS_REQUEST: 'CLIENT_AREA_TYPES.GET_DOMAINS_REQUEST',
  GET_DOMAINS_SUCCESS: 'CLIENT_AREA_TYPES.GET_DOMAINS_SUCCESS',
  GET_DOMAINS_FAIL: 'CLIENT_AREA_TYPES.GET_DOMAINS_FAIL',
  GET_SERVICES_REQUEST: 'CLIENT_AREA_TYPES.GET_SERVICES_REQUEST',
  GET_SERVICES_SUCCESS: 'CLIENT_AREA_TYPES.GET_SERVICES_SUCCESS',
  GET_SERVICES_FAIL: 'CLIENT_AREA_TYPES.GET_SERVICES_FAIL',
  GET_TICKETS_REQUEST: 'CLIENT_AREA_TYPES.GET_TICKETS_REQUEST',
  GET_TICKETS_SUCCESS: 'CLIENT_AREA_TYPES.GET_TICKETS_SUCCESS',
  GET_TICKETS_FAIL: 'CLIENT_AREA_TYPES.GET_TICKETS_FAIL',
  GET_INVOICES_REQUEST: 'CLIENT_AREA_TYPES.GET_INVOICES_REQUEST',
  GET_INVOICES_SUCCESS: 'CLIENT_AREA_TYPES.GET_INVOICES_SUCCESS',
  GET_INVOICES_FAIL: 'CLIENT_AREA_TYPES.GET_INVOICES_FAIL',
  SET_FILTER: 'CLIENT_AREA_TYPES.SET_FILTER',
  GET_DOMAIN_CONTACTS_REQUEST: 'CLIENT_AREA_TYPES.GET_DOMAIN_CONTACTS_REQUEST',
  GET_DOMAIN_CONTACTS_SUCCESS: 'CLIENT_AREA_TYPES.GET_DOMAIN_CONTACTS_SUCCESS',
  GET_DOMAIN_CONTACTS_FAIL: 'CLIENT_AREA_TYPES.GET_DOMAIN_CONTACTS_FAIL',
  SET_DOMAIN_CONTACTS_REQUEST: 'CLIENT_AREA_TYPES.SET_DOMAIN_CONTACTS_REQUEST',
  SET_DOMAIN_CONTACTS_SUCCESS: 'CLIENT_AREA_TYPES.SET_DOMAIN_CONTACTS_SUCCESS',
  SET_DOMAIN_CONTACTS_FAIL: 'CLIENT_AREA_TYPES.SET_DOMAIN_CONTACTS_FAIL',
  CHANGE_DOMAIN_CONTACT_MODAL_TAB:
    'CLIENT_AREA_TYPES.CHANGE_DOMAIN_CONTACT_MODAL_TAB',
  CHANGE_ID_PROTECT_REQUEST: 'CLIENT_AREA_TYPES.CHANGE_ID_PROTECT_REQUEST',
  CHANGE_ID_PROTECT_SUCCESS: 'CLIENT_AREA_TYPES.CHANGE_ID_PROTECT_SUCCESS',
  CHANGE_ID_PROTECT_FAIL: 'CLIENT_AREA_TYPES.CHANGE_ID_PROTECT_FAIL',
  SET_INITIAL_PARAMS: 'CLIENT_AREA_TYPES.SET_INITIAL_PARAMS',
  SET_SERVICE_DETAILS: 'CLIENT_AREA_TYPES.SET_SERVICE_DETAILS',
  GET_SERVICE_DETAILS_REQUEST: 'CLIENT_AREA_TYPES.GET_SERVICE_DETAILS_REQUEST',
  GET_SERVICE_DETAILS_SUCCESS: 'CLIENT_AREA_TYPES.GET_SERVICE_DETAILS_SUCCESS',
  GET_SERVICE_DETAILS_FAIL: 'CLIENT_AREA_TYPES.GET_SERVICE_DETAILS_FAIL',
}
