export const CSR_MODAL = {
  GENERATE_CSR_REQUEST: 'CSR_MODAL.GENERATE_CSR_REQUEST',
  GENERATE_CSR_SUCCESS: 'CSR_MODAL.GENERATE_CSR_SUCCESS',
  GENERATE_CSR_FAIL: 'CSR_MODAL.GENERATE_CSR_FAIL',
  UPLOAD_CSR_REQUEST: 'CSR_MODAL.UPLOAD_CSR_REQUEST',
  UPLOAD_CSR_SUCCESS: 'CSR_MODAL.UPLOAD_CSR_SUCCESS',
  UPLOAD_CSR_FAIL: 'CSR_MODAL.UPLOAD_CSR_FAIL',
  SET_TAB: 'CSR_MODAL.SET_TAB',
  SET_STEP: 'CSR_MODAL.SET_STEP',
  SET_IS_KEY_DOWNLOADED: 'CSR_MODAL.SET_IS_KEY_DOWNLOADED',
};
