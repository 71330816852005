import React, { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Helmet } from 'react-helmet'

import { LayoutCommon } from '../../layouts/LayoutCommon'
import * as S from './OrderDetailsPage.styles'
import { LoginModal } from '../../components/Modals/LoginModal'
import { AddContactModal } from '../../components/Modals/AddContactModal'
import { Tooltip } from '../../components/Tooltip'
import { Loader } from '../../components/Loader'
import { i18n } from '../../i18n'
import { getInitialValues, yupScheme } from './OrderDetailsPage.config'
import { priceTimeSelectOptions } from './OrderDetailsPage.constants'

import { changeUrl } from '../../hooks/changeUrl'
import { validateAdditionalField } from '../../helpers/validateRuDomain'

import slugify from 'react-slugify'
import Cookies from 'js-cookie'

const OrderDetailsPage = ({
  history,
  auth,
  loginLoading,
  contacts,
  showModal,
  cart,
  addOrder,
  addOrderLoading,
  addOrderError,
  updateCart,
  setDomainContact,
  removeFromCart,
  dimensions,
  numberWithCommas,
  contactId,
}) => {
  const [orderHasBeenSubmitted, setOrderHasBeenSubmitted] = useState(false)
  const options = contacts.map((item) => ({
    value: item.id,
    label: `${item.firstname} ${item.lastname}`,
  }))

  options.unshift({
    label: i18n.t('orderDetailsPage.current'),
    value: 'current',
  })

  options.push({
    label: i18n.t('orderDetailsPage.newContact'),
    value: 'create_new',
  })

  useEffect(() => {
    if (!cart.length && !orderHasBeenSubmitted) {
      history.push(changeUrl('/'))
    } else {
      const dataLayer = window.dataLayer || []
      dataLayer.push({
        event: 'view_item',
        currency: 'USD',
        // value: cart[0].premium_price_usd || cart[0].price_usd,
        // items: [
        //   {
        //     item_id: cart[0].id,
        //     item_name: cart[0].name,
        //   },
        // ],
        // ecommerce: {
        //   currency: 'USD',
        //   products: [
        //     {
        //       id: cart[0].id,
        //       name: cart[0].name,
        //       price: cart[0].premium_price_usd || cart[0].price_usd,
        //     },
        //   ],
        // },
      })
    }
  }, [cart])

  const handleContactChange = useCallback(
    (o) => {
      if (o.value === 'create_new') {
        return showModal(AddContactModal, {
          largeModal: true,
          isNewContact: true,
          onSuccess: (contactId) => {
            setDomainContact(contactId)
          },
        })
      }
      setDomainContact(o.value === 'current' ? null : o.value)
    },
    [showModal]
  )

  const ids = cart.filter((_) => _.type === 'ssl').map((_) => _._id)

  const isDomain = cart.some((item) => item.type === 'domain')

  return (
    <LayoutCommon>
      <Helmet>
        <title>{i18n.t('headers.orderDetailsPage')}</title>
        <meta
          name="description"
          content={i18n.t('headersDescriptions.orderDetailsPage')}
        />
        <meta name="og:title" content={i18n.t('headers.orderDetailsPage')} />
        <meta
          name="og:description"
          content={i18n.t('headersDescriptions.orderDetailsPage')}
        />
        <meta
          name="og:image"
          content="https://internet.am/images/fb_logo.png"
        />
      </Helmet>
      <S.PageBody>
        <Formik
          initialValues={getInitialValues(ids)}
          validationSchema={yupScheme}
          onSubmit={(values) => {
            setOrderHasBeenSubmitted(true)
            addOrder(values.promocode, values.domains, (invoiceId) => {
              const invoiceIds = Cookies.get('sslShouldBeShown')
              for (let i = 0; i < cart.length; i++) {
                if (cart[i].type === 'ssl') {
                  const ssls = invoiceIds
                    ? [invoiceIds, invoiceId]
                    : [invoiceId]
                  Cookies.set('sslShouldBeShown', ssls.toString(), {
                    expires: 10,
                  })
                  break
                }
              }

              // for (let i = 0; i < cart.length; i++) {
              //   if (cart[i].type === 'ssl') {
              //     Cookies.set('sslShouldBeShown', [...invoiceIds, invoiceId]);
              //     break;
              //   }
              // }
              history.push(changeUrl(`/invoice?id=${invoiceId}`))
              setTimeout(() => {
                setOrderHasBeenSubmitted(false)
              })
            })
          }}
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            setFieldValue,
            handleSubmit,
            setFieldTouched,
            handleBlur,
          }) => {
            const domainsErrors = errors.domains && JSON.parse(errors.domains)

            return (
              <Form>
                <S.PaymentBodyContainer>
                  <S.PaymentTitle>
                    {i18n.t('orderDetailsPage.title')}
                  </S.PaymentTitle>
                  <S.MainBlock>
                    {isDomain ? (
                      <S.OwnerContainer>
                        <S.OwnerHeader>
                          {i18n.t('orderDetailsPage.domainHeader')}
                          {!auth.token && (
                            <S.WarningIcon className="tooltipContainer">
                              <Tooltip
                                className={`tooltip ${
                                  dimensions &&
                                  dimensions.width < 600 &&
                                  'right noArrow'
                                }`}
                                cWidth={10}
                              >
                                {i18n.t(
                                  'tooltips.registerRequiredToSelectContact'
                                )}
                              </Tooltip>
                            </S.WarningIcon>
                          )}
                        </S.OwnerHeader>
                        <S.OwnerNote>
                          {i18n.t('orderDetailsPage.domainNote')}
                        </S.OwnerNote>
                        <S.StyledSelect
                          placeholder={i18n.t('orderDetailsPage.selectContact')}
                          options={options}
                          onChange={handleContactChange}
                          isDisabled={!auth.token}
                          classNamePrefix="react-select"
                          value={options.find(
                            (item) => item.value === contactId
                          )}
                        />
                      </S.OwnerContainer>
                    ) : null}

                    <div>
                      {cart.map((product) => {
                        const price =
                          auth.currency === 2
                            ? product.premium_price_usd || product.price_usd
                            : product.premium_price_amd || product.price
                        return (
                          product.type === 'domain' && (
                            <S.ProductContainer key={product.id}>
                              <S.ProductWrapper>
                                <S.ProductName>
                                  {i18n.t('orderDetailsPage.domainTitle')}
                                </S.ProductName>
                                <S.Type>
                                  {product.domain}
                                  {product.tld}
                                </S.Type>
                              </S.ProductWrapper>
                              {product.type === 'domain' &&
                                product.tld === '.ru' &&
                                validateAdditionalField(
                                  product,
                                  undefined,
                                  undefined,
                                  auth.legalStatus
                                ) && (
                                  <S.WarningText
                                    to={changeUrl(`/domain-settings`)}
                                  >
                                    {i18n.t(
                                      'orderDetailsPage.productInfoMissing'
                                    )}
                                  </S.WarningText>
                                )}
                              <S.PriceContainer>
                                <S.Delete
                                  type="button"
                                  onClick={() => removeFromCart(product)}
                                />
                                <S.PriceValue>
                                  {numberWithCommas(price * product.years)} /{' '}
                                  {product.years}{' '}
                                  {i18n.t('general.periods.forYears')}
                                </S.PriceValue>
                              </S.PriceContainer>
                            </S.ProductContainer>
                          )
                        )
                      })}

                      {cart.map(
                        (product) =>
                          ['hosting', 'constructor'].includes(product.type) && (
                            <S.ProductContainer>
                              <S.ProductWrapper>
                                <S.ProductName>
                                  {product.type === 'hosting'
                                    ? i18n.t('orderDetailsPage.hostingTitle')
                                    : i18n.t(
                                        'orderDetailsPage.constructorTitle'
                                      )}
                                </S.ProductName>
                                <S.Type>{product.name}</S.Type>
                              </S.ProductWrapper>
                              {product.domain && product.tld ? (
                                <S.ChoosedDomain>
                                  {product.domain}
                                  {product.tld}
                                </S.ChoosedDomain>
                              ) : (
                                <S.ChoosedDomain>
                                  <S.WarningText
                                    to={changeUrl(
                                      `/product-details?id=${product._id}`
                                    )}
                                  >
                                    {i18n.t(
                                      'orderDetailsPage.productInfoIncomplete'
                                    )}
                                  </S.WarningText>
                                </S.ChoosedDomain>
                              )}
                              <S.PriceContainer>
                                <S.Delete
                                  type="button"
                                  onClick={() => removeFromCart(product)}
                                />
                                <S.PriceValue>
                                  {numberWithCommas(
                                    auth.currency === 2
                                      ? (
                                          product.price_usd * product.time.value
                                        ).toFixed(1)
                                      : product.price * product.time.value
                                  )}
                                </S.PriceValue>
                                <S.PriceTimeSelect
                                  inputProps={{
                                    autoComplete: 'off',
                                  }}
                                  name="priceSelect"
                                  options={priceTimeSelectOptions}
                                  defaultValue={
                                    priceTimeSelectOptions.find(
                                      (i) => i.value === product.time.value
                                    ) || priceTimeSelectOptions[0]
                                  }
                                  onChange={(v) => {
                                    updateCart(product, {
                                      time: { value: v.value, name: v.name },
                                    })
                                  }}
                                />
                              </S.PriceContainer>
                            </S.ProductContainer>
                          )
                      )}
                      {cart.map(
                        (product) =>
                          product.type === 'vps' && (
                            <S.ProductContainer>
                              <S.ProductWrapper>
                                <S.ProductName>
                                  {i18n.t('orderDetailsPage.vpsTitle')}
                                </S.ProductName>
                                <S.Type>{product.productServerSize}</S.Type>
                              </S.ProductWrapper>
                              <S.ChoosedDomain />
                              <S.PriceContainer>
                                <S.Delete
                                  type="button"
                                  onClick={() => removeFromCart(product)}
                                />
                                <S.PriceValue>
                                  {numberWithCommas(
                                    auth.currency === 2
                                      ? (
                                          product.price_usd * product.time.value
                                        ).toFixed(1)
                                      : product.price * product.time.value
                                  )}
                                </S.PriceValue>
                                <S.PriceTimeSelect
                                  inputProps={{
                                    autoComplete: 'off',
                                  }}
                                  name="priceSelect"
                                  options={priceTimeSelectOptions.filter(
                                    (item) => item.name !== 'annually'
                                  )}
                                  defaultValue={
                                    priceTimeSelectOptions.find(
                                      (i) => i.value === product.time.value
                                    ) || priceTimeSelectOptions[0]
                                  }
                                  onChange={(v) => {
                                    updateCart(product, {
                                      time: { value: v.value, name: v.name },
                                    })
                                  }}
                                />
                              </S.PriceContainer>
                            </S.ProductContainer>
                          )
                      )}
                      {cart.map(
                        (product) =>
                          product.type === 'ssl' && (
                            <>
                              <S.ProductContainer>
                                <S.ProductWrapper>
                                  <S.ProductName>
                                    {i18n.t('orderDetailsPage.sslTitle')}
                                  </S.ProductName>
                                  <S.Type>{product.name}</S.Type>
                                </S.ProductWrapper>
                                <S.DomainWrapper>
                                  <S.DomainInput
                                    placeholder={i18n.t(
                                      'orderDetailsPage.domainTitle'
                                    )}
                                    value={values.domains?.[product._id]}
                                    onChange={(e) => {
                                      setFieldTouched(`domain-${product._id}`)
                                      setFieldValue('domains', {
                                        ...values.domains,
                                        [product._id]: e.target.value,
                                      })
                                    }}
                                    name="domains"
                                  />
                                </S.DomainWrapper>

                                <S.PriceContainer>
                                  <S.Delete
                                    type="button"
                                    onClick={() => removeFromCart(product)}
                                  />
                                  <S.PriceValue>
                                    {numberWithCommas(
                                      auth.currency === 2
                                        ? product.price_usd
                                        : product.price
                                    )}
                                    /{i18n.t('general.periods.yearly')}
                                  </S.PriceValue>
                                </S.PriceContainer>
                              </S.ProductContainer>
                              {(touched.domains?.[product._id] ||
                                touched[`domain-${product._id}`]) &&
                                domainsErrors?.[product._id] && (
                                  <S.DomainErrorMessage>
                                    {i18n.t(domainsErrors[product._id])}
                                  </S.DomainErrorMessage>
                                )}
                            </>
                          )
                      )}
                    </div>
                    <S.Wrapper>
                      <S.BlockContainer>
                        <S.Header>
                          {i18n.t('orderDetailsPage.addNotes')}
                        </S.Header>
                        <S.TextareaWrapper>
                          <S.Textarea
                            component="textarea"
                            name="message"
                            onChange={handleChange}
                            value={values.message}
                            placeholder={i18n.t('orderDetailsPage.placeholder')}
                            maxLength={500}
                            isError={values.message.length === 500}
                          />
                          <S.Counter isError={values.message.length === 500}>
                            {values.message.length}/500
                          </S.Counter>
                        </S.TextareaWrapper>
                        {errors.message && touched.message && (
                          <S.ErrorMessage>
                            {i18n.t(errors.message)}
                          </S.ErrorMessage>
                        )}
                      </S.BlockContainer>
                      <S.BlockContainer>
                        <S.Header>{i18n.t('orderDetailsPage.total')}</S.Header>
                        <S.PromoWrapper className="promoWrapper">
                          <S.PromoHeader>
                            {i18n.t('orderDetailsPage.promocode')}
                          </S.PromoHeader>
                          <S.Line />
                          <S.Input
                            name="promocode"
                            onChange={handleChange}
                            value={values.promocode}
                            placeholder={i18n.t(
                              'orderDetailsPage.promocodeInput'
                            )}
                          />
                          {errors.promocode && (
                            <S.ErrorIconContainer className="tooltipContainer">
                              <S.ErrorIcon />
                              <Tooltip className="tooltip top right noArrow error">
                                {i18n.t(errors.promocode)}
                              </Tooltip>
                            </S.ErrorIconContainer>
                          )}
                        </S.PromoWrapper>
                        {/* {errors.promocode && (
                        <S.ErrorMessage>
                          {i18n.t(errors.promocode)}
                        </S.ErrorMessage>
                        
                      )} */}
                        <S.TotalPaymentBlock>
                          <S.TotalSum>
                            {numberWithCommas(
                              cart
                                .reduce(
                                  (a, b) => {
                                    const bPrice =
                                      auth.currency === 2
                                        ? (b.premium_price_usd || b.price_usd) *
                                          (b.years || 1)
                                        : (b.premium_price_amd || b.price) *
                                          (b.years || 1)
                                    let first = a.time
                                      ? a.time.value * a.price
                                      : a.price * (a.years || 1)
                                    let second = b.time
                                      ? b.time.value * bPrice
                                      : bPrice

                                    return { price: first + second }
                                  },
                                  { price: 0 }
                                )
                                .price.toFixed(1)
                            )}
                          </S.TotalSum>
                          <S.PaymentCondition>
                            {i18n.t('orderDetailsPage.paymentDate')}
                          </S.PaymentCondition>
                        </S.TotalPaymentBlock>
                      </S.BlockContainer>
                    </S.Wrapper>
                    {!auth.token && (
                      <S.LoginContainer>
                        <S.LoginButton
                          onClick={() => showModal(LoginModal)}
                          type="button"
                        >
                          {i18n.t('orderDetailsPage.login')}
                        </S.LoginButton>
                        <S.RegButton to={changeUrl('/registration')}>
                          {i18n.t('orderDetailsPage.register')}
                        </S.RegButton>
                      </S.LoginContainer>
                    )}
                  </S.MainBlock>

                  {!!auth.token && (
                    <S.PaymentButton
                      className="tooltipContainer"
                      type="submit"
                      disabled={
                        cart.some(
                          (item) =>
                            ['constructor', 'hosting'].includes(item.type) &&
                            !item.domain
                        ) ||
                        cart.some(
                          (item) =>
                            item.type === 'domain' &&
                            item.tld === '.ru' &&
                            validateAdditionalField(
                              item,
                              undefined,
                              undefined,
                              auth.legalStatus
                            )
                        )
                      }
                    >
                      {cart.some(
                        (item) =>
                          ['constructor', 'hosting'].includes(item.type) &&
                          !item.domain
                      ) && (
                        <Tooltip className="tooltip top">
                          {i18n.t('tooltips.cartProblemTooltip')}
                        </Tooltip>
                      )}
                      {addOrderLoading && !addOrderError ? (
                        <Loader color="#FFF" size={0.5} />
                      ) : (
                        i18n.t('orderDetailsPage.payment')
                      )}
                    </S.PaymentButton>
                  )}

                  {addOrderError && (
                    <S.Error>
                      {i18n.t(`backend.addOrder.${slugify(addOrderError)}`)}
                    </S.Error>
                  )}
                </S.PaymentBodyContainer>
              </Form>
            )
          }}
        </Formik>
      </S.PageBody>
    </LayoutCommon>
  )
}
export { OrderDetailsPage }
