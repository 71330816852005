import FetchApi from '../../../helpers/FetchApi';
import { TRANSFER_DOMAIN_TYPES } from './TransferDomainModal.types';

export const transferDomain = (values, onSuccess) => async (dispatch) => {
  values.domain_name = values.domain_name.toLowerCase();

  try {
    dispatch({
      type: TRANSFER_DOMAIN_TYPES.TRANSFER_DOMAIN_REQUEST,
    });
    const req = await FetchApi.post('tickets/transferDomain', values);

    dispatch({
      type: TRANSFER_DOMAIN_TYPES.TRANSFER_DOMAIN_SUCCESS,
      data: req.data,
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    dispatch({
      type: TRANSFER_DOMAIN_TYPES.TRANSFER_DOMAIN_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    });
  }
};
