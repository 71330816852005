export const DOMAIN_TYPES = {
  SET_DOMAINS_DATA: 'DOMAIN_TYPES.SET_DOMAINS_DATA',
  SET_DOMAIN: 'DOMAIN_TYPES.SET_DOMAIN',
  GET_DOMAINS_REQUEST: 'DOMAIN_TYPES.GET_DOMAINS_REQUEST',
  GET_DOMAINS_REQUEST_PART2: 'DOMAIN_TYPES.GET_DOMAINS_REQUEST_PART2',
  GET_DOMAINS_SUCCESS: 'DOMAIN_TYPES.GET_DOMAINS_SUCCESS',
  GET_DOMAINS_SUCCESS_PART2: 'DOMAIN_TYPES.GET_DOMAINS_SUCCESS_PART2',
  GET_DOMAINS_FAIL: 'DOMAIN_TYPES.GET_DOMAINS_FAIL',
  GET_MORE_DOMAINS_REQUEST: 'DOMAIN_TYPES.GET_MORE_DOMAINS_REQUEST',
  GET_MORE_DOMAINS_REQUEST_PART2: 'DOMAIN_TYPES.GET_MORE_DOMAINS_REQUEST_PART2',
  GET_MORE_DOMAINS_SUCCESS: 'DOMAIN_TYPES.GET_MORE_DOMAINS_SUCCESS',
  GET_MORE_DOMAINS_SUCCESS_PART2: 'DOMAIN_TYPES.GET_MORE_DOMAINS_SUCCESS_PART2',
  GET_MORE_DOMAINS_FAIL: 'DOMAIN_TYPES.GET_MORE_DOMAINS_FAIL',
  TOGGLE_MOBILE_CART: 'DOMAIN_TYPES.TOGGLE_MOBILE_CART',
  TOGGLE_UNAVAILABLE_DOMAINS: 'DOMAIN_TYPES.TOGGLE_UNAVAILABLE_DOMAINS',
};
