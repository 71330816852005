import React, { useEffect, useState } from 'react';
import QRCodeGenerator from 'qrcode';

import { LayoutCommon } from '../../layouts/LayoutCommon';
import * as S from './TwoFactorAuthEnablePage.styles';
import { Loader } from '../../components/Loader';
import { i18n } from '../../i18n';
import { TwoFactorAuthModal } from '../../components/Modals/TwoFactorAuthModal';

const TwoFactorAuthEnablePage = ({
  history,
  auth,
  getQrCode,
  data,
  loading,
  showModal,
  disableActivateLink,
  getActivateStatus,
  token,
}) => {
  const [QRCode, setQRCode] = useState(null);

  useEffect(() => {
    getActivateStatus(auth.email);
  }, []);

  useEffect(() => {
    if (token) {
      disableActivateLink(token, () => {
        getQrCode(async (otpauth_url) => {
          const QRCodeImg = await QRCodeGenerator.toDataURL(otpauth_url);
          setQRCode(QRCodeImg);
        });
      });
    }
  }, [token]);

  useEffect(() => {
    getQrCode(async (otpauth_url) => {
      const QRCodeImg = await QRCodeGenerator.toDataURL(otpauth_url);
      setQRCode(QRCodeImg);
    });
  }, []);

  return (
    <LayoutCommon>
      <S.PageContainer>
        <S.PageTitle>{i18n.t('twoFactorAuthEnablePage.title')}</S.PageTitle>
        {loading ? (
          <Loader color="#00C084" size={0.5} />
        ) : (
          <>
            {QRCode && (
              <S.StyledImg
                src={QRCode}
                alt="QR code image"
                title="Image of QR code"
              />
            )}
          </>
        )}
        <S.SubmitButton
          onClick={() => {
            showModal(TwoFactorAuthModal, { email: auth.email });
          }}
        >
          {i18n.t('twoFactorAuthEnablePage.submitButton')}
        </S.SubmitButton>
      </S.PageContainer>
    </LayoutCommon>
  );
};

export { TwoFactorAuthEnablePage };
