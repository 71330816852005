import * as Yup from 'yup'

export const initialValues = {
  personType: 0,
  firstname: '',
  lastname: '',
  legalCode: '',
  phonenumber: '',
  email: '',
  password: '',
  password2: '',
  country: 'AM',
  state: '',
  city: '',
  address1: '',
  postcode: '',
  currency: 1,
  autopay: false,
  privacy: false,
}

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || 'Two strings should be equal',
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref)
    },
  })
}

Yup.addMethod(Yup.string, 'equalTo', equalTo)

export const yupScheme = Yup.object().shape({
  personType: Yup.number(),
  firstname: Yup.string()
    .min(2, 'validationFields.firstnameMin')
    .max(72, 'validationFields.firstnameMax')
    .matches(/^([a-zA-Z0-9\s\-.']){1,72}$/, 'validationFields.invalidTyping')
    .required('validationFields.firstname'),
  lastname: Yup.string()
    .min(2, 'validationFields.lastnameMin')
    .max(72, 'validationFields.lastnameMax')
    .matches(/^([a-zA-Z0-9\s\-.']){1,72}$/, 'validationFields.invalidTyping')
    .required('validationFields.lastname'),
  legalCode: Yup.string()
    .min(5, 'validationFields.legalCodeMin')
    .matches(/^([a-zA-Z0-9\s-]){1,40}$/, 'validationFields.invalidTyping')
    .required('validationFields.passport'),
  phonenumber: Yup.string()
    .min(9, 'validationFields.phonenumberMin')
    .max(20, 'validationFields.phonenumberMax')
    .required('validationFields.phonenumber'),
  email: Yup.string()
    .email('validationFields.invalidTyping')
    .matches(/^[^']*$/, 'validationFields.invalidTyping')
    .required('validationFields.email'),
  password: Yup.string()
    .min(12, 'validationFields.passMin')
    .max(64, 'validationFields.passMax')
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])/,
      'validationFields.password'
    )
    .required('validationFields.password'),
  password2: Yup.string()
    .equalTo(Yup.ref('password'), 'validationFields.passMatch')
    .required('validationFields.passMatch'),
  country: Yup.string().required('validationFields.country'),
  state: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-`."]+$/, 'validationFields.invalidTyping')
    .required('validationFields.state'),
  city: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-`."]+$/, 'validationFields.invalidTyping')
    .required('validationFields.city'),
  address1: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-,."/]+$/, 'validationFields.invalidTyping')
    .required('validationFields.address'),
  postcode: Yup.string()
    .matches(/^([a-zA-Z0-9\s\-.]){1,40}$/, 'validationFields.invalidTyping')
    .required('validationFields.postcode'),
  privacy: Yup.bool().oneOf([true], 'validationFields.privacy'),
})
