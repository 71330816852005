import styled from 'styled-components';

export const SettingsPopup = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  max-height: 176px;
  border-bottom: 1px solid #212121;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0px 0px;
  padding-top: 16px;
  padding-bottom: 16px;
  right: 0px;
  bottom: 145px;
  @media (min-width: 768px) {
    right: 0px;
    bottom: 50px;
  }
  @media (min-width: 1280px) {
  }
`;
export const Status = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #242e46;
  text-align: center;
  cursor: pointer;
  padding: 8px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
`;
