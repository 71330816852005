import { HOSTING_TYPES } from './HostingPage.types';

export const changeTab = tab => ({
  type: HOSTING_TYPES.CHANGE_TAB,
  tab,
});

export const changeExpand = id => ({
  type: HOSTING_TYPES.EXPAND_CARD,
  id,
});
