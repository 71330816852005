import { SslPage as Self } from './SslPage'
import { connect } from 'react-redux'
import {
  changeTab,
  domainValidation,
  organizationValidation,
  extendedValidation,
} from './SslPage.actions'
import {
  domainValidationReducer,
  organizationValidationReducer,
  extendedValidationReducer,
} from './SslPage.reducer'

const mapStateToProps = ({
  sslPage,
  domainValidationReducer,
  organizationValidationReducer,
  extendedValidationReducer,
}) => ({
  tab: sslPage.tab,
  domainValidationData:
    domainValidationReducer.domainValidationData?.products?.product,
  organizationValidationData:
    organizationValidationReducer.organizationValidationData?.products?.product,
  extendedValidationData:
    extendedValidationReducer.extendedValidationData?.products?.product,
})

const mapDispatchToProps = {
  changeTab,
  domainValidation,
  organizationValidation,
  extendedValidation,
}

export const SslPage = connect(mapStateToProps, mapDispatchToProps)(Self)
