import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

const Self = ({ children, location }) => {
  const [pathname, setPathname] = useState('')

  useEffect(() => {
    if (pathname !== location.pathname && location.search !== '?section=faq') {
      window.scrollTo(0, 0)
    }
    setPathname(location.pathname)
  }, [location])
  return children
}

export const ScrollTop = withRouter(Self)
