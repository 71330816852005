import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const InfoBlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: white;
  margin-top: 250px;
  @media (min-width: 768px) {
    margin-top: unset;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 532px;
  justify-content: center;
  align-items: center;
  padding: 15px 15px;

  @media (min-width: 768px) {
    padding-top: 80px;
  }
`;

export const HostingPackageTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: #484857;
  text-align: center;
  padding-bottom: 14px;

  @media (min-width: 768px) {
    font-size: 24px;
    padding-bottom: 20px;
  }
`;

export const HostingPackageInfo = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #737373;
  text-align: center;
  padding-bottom: 15px;
  font-weight: 300;

  @media (min-width: 768px) {
    line-height: 21px;
    font-size: 18px;
  }
`;

export const MoreInfo = styled(Link)`
  font-size: 12px;
  color: #595ba8;
  border-bottom: 1px solid #595ba8;
  text-decoration: none;
  cursor: pointer;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;
