import styled from 'styled-components';
import closeModal from '../../../images/closeModal.svg';

export const MaintenanceModalContainer = styled.div`
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 40px;
`;
export const Modal = styled.div`
  @media (max-width: 385px) {
    width: 300px;
  }
  width: 500px;
  height: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  outline: none;
  z-index: 100;
  background: white;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  margin: 10px auto;
  color: black;
`;

export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 14px;
  height: 14px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-size: cover;

  @media (min - width: 768px) {
    width: 14px;
    height: 14px;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
`;
