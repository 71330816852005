import * as Yup from 'yup'

export const initialValues = {
  password: '',
  password2: '',
}

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || 'Two strings should be equal',
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref)
    },
  })
}

Yup.addMethod(Yup.string, 'equalTo', equalTo)

export const yupScheme = Yup.object().shape({
  password: Yup.string()
    .min(12, 'validationFields.passMin')
    .max(64, 'validationFields.passMax')
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])/,
      'validationFields.password'
    )
    .required('validationFields.password'),
  password2: Yup.string()
    .equalTo(Yup.ref('password'), 'validationFields.passMatch')
    .required('validationFields.passMatch'),
})
