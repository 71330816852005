import { connect } from 'react-redux'

import { Modal as Self } from './Modal'
import { hideModal, showModal } from './Modal.actions'

const mapStateToProps = ({ modal, app }) => ({
  ModalComponent: modal.ModalComponent,
  componentProps: modal.componentProps,
  isVisible: modal.isVisible,
  dimensions: app.dimensions,
})

const mapDispatchToProps = {
  hideModal,
  showModal,
}

export const Modal = connect(mapStateToProps, mapDispatchToProps)(Self)
