import domainService from '../../images/domainService.png';
import constructorService from '../../images/constructorService.png';
import hostingService from '../../images/hostingService.png';
import sslService from '../../images/sslService.png';
import supportService from '../../images/supportService.png';
import domainServiceEn from '../../images/domainServiceEn.png';
import constructorServiceEn from '../../images/constructorServiceEn.png';
import hostingServiceEn from '../../images/hostingServiceEn.png';
import sslServiceEn from '../../images/sslServiceEn.png';
import supportServiceEn from '../../images/supportServiceEn.png';
import domainServiceRu from '../../images/domainServiceRu.png';
import constructorServiceRu from '../../images/constructorServiceRu.png';
import hostingServiceRu from '../../images/hostingServiceRu.png';
import sslServiceRu from '../../images/sslServiceRu.png';
import supportServiceRu from '../../images/supportServiceRu.png';

export const services = [
  {
    id: 'domains',
    image: domainService,
    imageEn: domainServiceEn,
    imageRu: domainServiceRu,
    link: '/domain-registration',
  },
  {
    id: 'constructor',
    image: constructorService,
    imageEn: constructorServiceEn,
    imageRu: constructorServiceRu,
    link: '/constructor-packages',
  },
  {
    id: 'hosting',
    image: hostingService,
    imageEn: hostingServiceEn,
    imageRu: hostingServiceRu,
    link: '/hosting-packages',
  },
  {
    id: 'ssl',
    image: sslService,
    imageEn: sslServiceEn,
    imageRu: sslServiceRu,
    link: '/ssl-packages',
  },
  {
    id: 'support',
    image: supportService,
    imageEn: supportServiceEn,
    imageRu: supportServiceRu,
    link: '/support',
  },
];
