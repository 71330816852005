import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ConstructorCard as Self } from './ConstructorCard';
import { addToCart, numberWithCommas } from '../../common/App/App.actions';

const mapStateToProps = ({ app }) => ({
  auth: app.auth,
});

const mapDispatchToProps = {
  addToCart,
  numberWithCommas,
};

export const ConstructorCard = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
