import React, { useRef } from 'react';

import * as S from './SettingsPopup.styles';
import { useOnClickOutside } from '../../../../../../hooks/useOnClickOutside';
import { ViewTicketModal } from '../../../../../../components/Modals/ViewTicketModal';
import { i18n } from '../../../../../../i18n';

export const SettingsPopup = ({
  showSettingsPopup,
  isShown,
  popupId,
  showModal,
  ticketId,
  ticketTid,
}) => {
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, () => showSettingsPopup(false));
  if (isShown !== popupId) {
    return null;
  }
  return (
    <S.SettingsPopup ref={containerRef}>
      <S.Status
        onClick={() => showModal(ViewTicketModal, { ticketId, ticketTid })}
      >
        {i18n.t('settingsPopup.title')}
      </S.Status>
    </S.SettingsPopup>
  );
};
