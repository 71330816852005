import { SSL_TYPES } from './SslPage.types';
import { SSL_TABS } from './SslPage.constants';

const initialState = {
  domainValidationData: null,
  domainValidationError: null,
  domainValidationLoading: false,
  organizationValidationData: null,
  organizationValidationError: null,
  organizationValidationLoading: false,
  extendedValidationkData: null,
  extendedValidationError: null,
  extendedValidationLoading: false,
  tab: SSL_TABS.DOMAIN_VALIDATION,
};

function sslPage(state = initialState, action) {
  switch (action.type) {
    case SSL_TYPES.CHANGE_TAB:
      return {
        ...state,
        tab: action.tab,
      };
    default:
      return state;
  }
}

export const domainValidationReducer = (state=initialState, action) => {
  switch(action.type){
    case SSL_TYPES.DOMAIN_VALIDATION_REQUEST:
      return{
        ...state,
        domainValidationLoading: true,
        domainValidationError: null,
        domainValidationData: null
      }
    case SSL_TYPES.DOMAIN_VALIDATION_SUCCESS:
        return{
          ...state,
          domainValidationLoading: false,
          domainValidationError: null,
          domainValidationData: action.payload
        }
    case SSL_TYPES.DOMAIN_VALIDATION_FAIL:
        return{
          ...state,
          domainValidationLoading: false,
          domainValidationError: action.error,
          domainValidationData: action.payload
        } 
    default:
      return state
  }
}

export const organizationValidationReducer = (state=initialState, action) => {
  switch(action.type){
    case SSL_TYPES.ORGANIZATION_VALIDATION_REQUEST:
      return{
        ...state,
        organizationValidationLoading: true,
        organizationValidationError: null,
        organizationValidationData: null
      }
    case SSL_TYPES.ORGANIZATION_VALIDATION_SUCCESS:
        return{
          ...state,
          organizationValidationLoading: false,
          organizationValidationError: null,
          organizationValidationData: action.payload
        }
    case SSL_TYPES.ORGANIZATION_VALIDATION_FAIL:
        return{
          ...state,
          organizationValidationLoading: false,
          organizationValidationError: action.error,
          organizationValidationData: action.payload
        } 
    default:
      return state
  }
}

export const extendedValidationReducer = (state=initialState, action) => {
  switch(action.type){
    case SSL_TYPES.EXTENDED_VALIDATION_REQUEST:
      return{
        ...state,
        extendedValidationLoading: true,
        extendedValidationError: null,
        extendedValidationData: null
      }
    case SSL_TYPES.EXTENDED_VALIDATION_SUCCESS:
        return{
          ...state,
          extendedValidationLoading: false,
          extendedValidationError: null,
          extendedValidationData: action.payload
        }
    case SSL_TYPES.EXTENDED_VALIDATION_FAIL:
        return{
          ...state,
          extendedValidationLoading: false,
          extendedValidationError: action.error,
          extendedValidationData: action.payload
        } 
    default:
      return state
  }
}

export { sslPage };
