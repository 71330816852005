import React, { useEffect, useState, useRef } from 'react';

import * as S from './Dropdown.styles';

export const Dropdown = (props) => {
  const { children, options, optionKey, value, onClick, withDisable } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (el) => { };

  const defaultOption = options && !!options.length && options[0];

  const setDisable = (el) => {
    const val = value.find((_) => _ === el);
    if (!!val && !!val.length) return true;
    return false;
  };

  const handleDropdown = () => {
    setIsOpen(!isOpen);
  };

  let menuRef = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  const fontChange = options.findIndex((i) => i === '.հայ')



  return (
    <S.DropdownContainer onClick={handleDropdown} ref={menuRef}>
      {children}
      {
        <S.DropdownWrapper isOpen={isOpen}>
          {isOpen &&
            options &&
            options.length &&
            options.map((el) => {
              const isDisable = setDisable(el);
              return (
                <S.DropdownOption
                  fontChange={options[fontChange]}
                  default={defaultOption}
                  isDisabled={withDisable ? isDisable : false}
                  onClick={
                    withDisable
                      ? !isDisable
                        ? () => onClick(el)
                        : () => { }
                      : handleClick(el)
                  }
                >
                  {optionKey ? el[optionKey] : el}
                </S.DropdownOption>
              );
            })}
        </S.DropdownWrapper>
      }
    </S.DropdownContainer>
  );
};
