import React, { useEffect, useState } from 'react'
import { LayoutCommon } from '../../layouts/LayoutCommon/LayoutCommon'
import * as S from './SslConfigurationPage.styles'
import { FirstStep } from './FirstStep/FirstStep'
import { SecondStep } from './SecondStep/SecondStep'
import { ThirdStep } from './ThirdStep/ThirdStep'
import { AiOutlineCheck } from 'react-icons/ai'
import { useParams } from 'react-router'
import { changeUrl } from '../../hooks/changeUrl'
import { SSL_TYPES } from './SslConfigurationPage.types'
import { Helmet } from 'react-helmet'
import { i18n } from '../../i18n'

export const SslConfigurationPage = ({
  showModal,
  csr,
  privateKey,
  configureSsl,
  nextStepData,
  setStep,
  initialValues,
  setValues,
  approvalMethods,
  approverEmails,
  loading,
  configureError,
  history,
  initiateConfigureSslData,
  getServiceDetails,
  serviceDetails,
  setServiceDetails,
  getServiceDetailsLoading,
  firstStepSslData,
  firstStepConfigure,
}) => {
  const { orderId, id, step } = useParams()

  const [error, setError] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  const steps = [
    {
      id: 1,
      step: 1,
      line: true,
    },
    {
      id: 2,
      step: 2,
      line: true,
    },
    {
      id: 3,
      step: 3,
    },
  ]

  useEffect(() => {
    firstStepConfigure({
      step: '1',
      ssl_order_id: orderId,
    })
  }, [step])

  useEffect(() => {
    getServiceDetails(id)
  }, [step])

  const goBack = () => {
    setStep(step - 1)
  }

  useEffect(() => {
    if (nextStepData?.result === 'error') {
      setError(nextStepData.msg)
    } else {
      setError('')
    }
  }, [nextStepData, step])

  useEffect(() => {
    return () => {
      localStorage.removeItem(SSL_TYPES.CONFIGURE_SSL_SUCCESS)
      setServiceDetails(null)
    }
  }, [])

  useEffect(() => {
    const configureSslData = localStorage.getItem(
      SSL_TYPES.CONFIGURE_SSL_SUCCESS
    )

    try {
      const data = JSON.parse(configureSslData)
      if (+step !== 1 && !data) {
        history.push(changeUrl(`/ssl-configuration/${orderId}/${id}/1`))
      } else {
        initiateConfigureSslData(data)
      }
    } catch {}
  }, [step, orderId])

  const handleContinue = (formattedValues, nextStep) => {
    setValues({ ...serviceDetails })
    configureSsl(formattedValues, () => {
      history.push(changeUrl(`/ssl-configuration/${orderId}/${id}/${nextStep}`))
    })
  }

  const stepDisplay = () => {
    if (+step === 2 && !nextStepData) {
      return null
    }

    switch (+step) {
      case 1:
        return (
          <FirstStep
            csr={csr}
            showModal={showModal}
            privateKey={privateKey}
            handleContinue={handleContinue}
            initialValues={serviceDetails}
            loading={loading}
            orderId={orderId}
            error={configureError}
            history={history}
            getServiceDetailsLoading={getServiceDetailsLoading}
            isWildcard={serviceDetails?.is_wildcard}
            firstStepSslData={firstStepSslData ? firstStepSslData : null}
          />
        )

      case 2:
        return (
          <SecondStep
            handleContinue={handleContinue}
            goBack={goBack}
            domain={nextStepData.domain}
            emails={approverEmails}
            methods={approvalMethods}
            initialValues={nextStepData}
            loading={loading}
            orderId={orderId}
            history={history}
            id={id}
          />
        )

      case 3:
        return (
          <ThirdStep
            goBack={goBack}
            nextStepData={nextStepData}
            history={history}
          />
        )

      default:
        return <div>Not found</div>
    }
  }

  return (
    <LayoutCommon>
      <Helmet>
        <title>{i18n.t('headers.sslConfiguration')}</title>
        <meta
          name="og:image"
          content="https://internet.am/images/fb_logo.png"
        />
      </Helmet>
      <S.SslConfiguationPage>
        <S.StepsContainer>
          <S.Steps>
            {steps.map((s) => {
              return (
                <React.Fragment key={s.id}>
                  <S.StepBox completed={step > s.step || step == 3}>
                    {step > s.step || step == 3 ? (
                      <AiOutlineCheck fill="#fff" />
                    ) : (
                      s.step
                    )}
                  </S.StepBox>
                  {s.line && (
                    <S.StepLine completed={step > s.step || step === 3} />
                  )}
                </React.Fragment>
              )
            })}
          </S.Steps>
        </S.StepsContainer>

        <S.FormContainer>{stepDisplay()}</S.FormContainer>

        <S.ErrorMessage>{error}</S.ErrorMessage>
      </S.SslConfiguationPage>
    </LayoutCommon>
  )
}
