import React, { useCallback } from 'react';
import * as S from './HostingModal.styles';
import {
  hostingPackages,
  colors,
} from '../../../pages/HostingPage/HostingPage.constants';
import { i18n } from '../../../i18n';

export const HostingModal = ({
  hideModal,
  addToCart,
  domain,
  tld,
  numberWithCommas,
  auth,
}) => {
  const addToCartAndHideModal = useCallback(
    (product) => {
      addToCart({
        ...product,
        type: 'hosting',
        domain,
        tld,
        time: { value: 1, name: 'monthly' },
      });
      hideModal();
    },
    [addToCart, hideModal, domain, tld]
  );

  return (
    <>
      <S.Modal onClick={(e) => e.stopPropagation()}>
        <S.HostingModalContainer>
          <S.ModalTitle>{i18n.t('hostingModal.title')}</S.ModalTitle>
          <S.ModalHeader>{i18n.t('hostingModal.header')}</S.ModalHeader>
          <S.Container>
            {hostingPackages.map((item, index) => (
              <S.Block>
                <S.PackageParagraph>{item.name}</S.PackageParagraph>
                <S.Details>
                  {item.params.map(
                    (x, index) =>
                      index < 4 && (
                        <S.DetailContainer>
                          <S.ValueContainer>{i18n.t(x.value)}</S.ValueContainer>
                          <S.KeyContainer>{i18n.t(x.key)}</S.KeyContainer>
                        </S.DetailContainer>
                      )
                  )}
                </S.Details>
  
                <S.PriceChooseContainer>
                  <S.PriceContainer>
                    <S.PriceValue>
                      {numberWithCommas(
                        auth.currency === 2 ? item.price_usd : item.price
                      )}
                    </S.PriceValue>
                    <S.PriceKey>
                      /{i18n.t('general.periods.monthly')}
                    </S.PriceKey>
                  </S.PriceContainer>
                  <S.ChooseButton
                    color={colors[index].backgroundColor}
                    onClick={() => addToCartAndHideModal(item)}
                  >
                    {i18n.t('hostingModal.button')}
                  </S.ChooseButton>
                </S.PriceChooseContainer>
              </S.Block>
            ))}
          </S.Container>
          <S.CloseModalButton onClick={() => hideModal()} />
        </S.HostingModalContainer>
      </S.Modal>
    </>
  );
};
