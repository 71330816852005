import { connect } from 'react-redux';

import { TwoFactorAuthEnablePage as Self } from './TwoFactorAuthEnablePage';
import { getQrCode } from './TwoFactorAuthEnablePage.actions';
import { showModal } from '../../common/Modal/Modal.actions';
import {
  disableActivateLink,
  getActivateStatus,
} from '../EditProfilePage/components/Security/Security.actions';

const mapStateToProps = ({ app, twoFactorAuthEnablePage, security }) => ({
  auth: app.auth,
  loading: twoFactorAuthEnablePage.loading,
  data: twoFactorAuthEnablePage.data,
  error: twoFactorAuthEnablePage.error,
  token: security.token,
});

const mapDispatchToProps = {
  getQrCode,
  showModal,
  disableActivateLink,
  getActivateStatus,
};

export const TwoFactorAuthEnablePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self);
