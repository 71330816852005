import React, { useEffect } from 'react'
import * as S from './ServiceDetails.styles'
import { LayoutCommon } from '../../layouts/LayoutCommon/LayoutCommon'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { changeUrl } from '../../hooks/changeUrl'
import { Loader } from '../../components/Loader'
import { SslCards } from '../SslPage/SslPage.constants'
import { PrivateKeyModal } from '../../components/Modals/PrivateKeyModal/PrivateKeyModal'
import { i18n } from '../../i18n'

export const ServiceDetails = ({
  serviceDetails,
  getServiceDetails,
  history,
  showModal,
}) => {
  const { id } = useParams('id')
  const orderId = serviceDetails?.ssl_order_id

  useEffect(() => {
    getServiceDetails(id)
  }, [id])

  const resultImage = Object.values(SslCards)
    .find((i) => i.data.find((product) => product?.pid === serviceDetails?.pid))
    ?.data.find((p) => p?.pid === serviceDetails?.pid).image

  const method = serviceDetails?.ssl_approve_type
  const status =
    serviceDetails?.csr_configs?.ssl_status?.charAt(0).toUpperCase() +
    serviceDetails?.csr_configs?.ssl_status?.slice(1)

  const dnsFields = [
    {
      id: 1,
      label: i18n.t('serviceDetails.configStatus'),
      description: i18n.t(`sslConfiguration.${serviceDetails?.ssl_status}`),
    },
    {
      id: 2,
      label: i18n.t('serviceDetails.sslStatus'),
      description: i18n.t(`serviceDetails.${status}`),
    },
    {
      id: 3,
      label: i18n.t('serviceDetails.domain'),
      description: serviceDetails?.domain,
    },
    {
      id: 4,
      label: i18n.t('serviceDetails.orderId'),
      description: serviceDetails?.csr_configs?.partner_order_id,
    },
    {
      id: 5,
      label: 'DNS CNAME Record',
      description: serviceDetails?.csr_configs?.approver_method?.dns?.record,
    },
    {
      id: 6,
      label: 'CSR (Certificate Signing Request)',
      description: serviceDetails?.csr_configs?.csr,
      isCsr: true,
    },
  ]

  const emailFields = [
    {
      id: 1,
      label: i18n.t('serviceDetails.configStatus'),
      description: i18n.t(`sslConfiguration.${serviceDetails?.ssl_status}`),
    },
    {
      id: 2,
      label: i18n.t('serviceDetails.sslStatus'),
      description: i18n.t(`serviceDetails.${status}`),
    },
    {
      id: 3,
      label: i18n.t('serviceDetails.domain'),
      description: serviceDetails?.domain,
    },
    {
      id: 4,
      label: i18n.t('serviceDetails.orderId'),
      description: serviceDetails?.csr_configs?.partner_order_id,
    },
    {
      id: 5,
      label: i18n.t('serviceDetails.approverEmail'),
      description: serviceDetails?.csr_configs?.approver_method?.email,
    },
    {
      id: 6,
      label: 'CSR (Certificate Signing Request)',
      description: serviceDetails?.csr_configs?.csr
        ? serviceDetails?.csr_configs?.csr
        : serviceDetails?.csr_configs?.ca,
      isCsr: true,
    },
  ]

  const httpsFields = [
    {
      id: 1,
      label: i18n.t('serviceDetails.configStatus'),
      description: i18n.t(`sslConfiguration.${serviceDetails?.ssl_status}`),
    },
    {
      id: 2,
      label: i18n.t('serviceDetails.sslStatus'),
      description: i18n.t(`serviceDetails.${status}`),
    },
    {
      id: 3,
      label: i18n.t('serviceDetails.domain'),
      description: serviceDetails?.domain,
    },
    {
      id: 4,
      label: i18n.t('serviceDetails.orderId'),
      description: serviceDetails?.csr_configs?.partner_order_id,
    },
    {
      id: 5,
      label: 'Hash File',
      description:
        serviceDetails?.csr_configs?.approver_method?.http?.filename ||
        serviceDetails?.csr_configs?.approver_method?.https?.filename,
    },
    {
      id: 6,
      label: 'Content',
      description:
        serviceDetails?.csr_configs?.approver_method?.http?.content ||
        serviceDetails?.csr_configs?.approver_method?.https?.content,
    },
    {
      id: 7,
      label: 'CSR (Certificate Signing Request)',
      description: serviceDetails?.csr_configs?.csr,
      isCsr: true,
    },
  ]

  const sslInfo = [
    {
      id: 1,
      label: i18n.t('serviceDetails.regDate'),
      info: serviceDetails?.regdate,
    },
    {
      id: 2,
      label: i18n.t('serviceDetails.cycle'),
      info: i18n.t(`backend.servicesBillCycle.${serviceDetails?.billingcycle}`),
    },
    {
      id: 3,
      label: i18n.t('serviceDetails.next'),
      info: serviceDetails?.nextduedate,
    },
    {
      id: 4,
      label: i18n.t('serviceDetails.domain'),
      info: serviceDetails?.domain,
    },
  ]

  const downloadFile = (fileName, val) => {
    const element = document.createElement('a')
    const file = new Blob([val], { type: 'text/plain;charset=utf-8' })
    element.href = URL.createObjectURL(file)
    element.download = fileName
    document.body.appendChild(element)
    element.click()
  }

  const DisplayButtons = () => {
    return (
      <S.ButtonsWrapper>
        {(method === 'http' || method === 'https') && (
          <S.Button
            onClick={() =>
              downloadFile(
                `${
                  serviceDetails?.csr_configs?.approver_method?.https
                    ?.filename ||
                  serviceDetails?.csr_configs?.approver_method?.http?.filename
                }`,
                serviceDetails?.csr_configs?.approver_method?.https?.content ||
                  serviceDetails?.csr_configs?.approver_method?.http?.content
              )
            }
          >
            {i18n.t('serviceDetails.downloadFile')}
          </S.Button>
        )}
        {serviceDetails?.csr_configs?.private_key ? (
          <S.Button
            onClick={() =>
              showModal(PrivateKeyModal, {
                privateKey: serviceDetails?.csr_configs?.private_key,
              })
            }
          >
            {i18n.t('serviceDetails.getPrivateKey')}
          </S.Button>
        ) : null}
      </S.ButtonsWrapper>
    )
  }

  const DisplayDetails = () => {
    if (serviceDetails?.ssl_status === 'Awaiting Configuration') {
      return (
        <S.Info>
          <S.Label>{i18n.t('serviceDetails.configStatus')}</S.Label>
          <S.Description>
            {i18n.t(`sslConfiguration.${serviceDetails?.ssl_status}`)}{' '}
            <Link to={changeUrl(`/ssl-configuration/${orderId}/${id}/1`)}>
              {i18n.t('settingsPopup.configure')}
            </Link>
          </S.Description>
        </S.Info>
      )
    } else if (method === 'dns') {
      return dnsFields.map((i) => (
        <S.Info key={i.id}>
          <S.Label>{i.label}</S.Label>
          {i.isCsr ? (
            <S.Description>
              <S.CsrInput value={i.description} />
            </S.Description>
          ) : (
            <S.Description>{i.description}</S.Description>
          )}
        </S.Info>
      ))
    } else if (method === 'https' || method === 'http') {
      return httpsFields.map((i) => (
        <S.Info key={i.id}>
          <S.Label>{i.label}</S.Label>
          {i.isCsr ? (
            <S.Description>
              <S.CsrInput value={i.description} />
            </S.Description>
          ) : (
            <S.Description>{i.description}</S.Description>
          )}
        </S.Info>
      ))
    } else {
      return emailFields.map((i) => (
        <S.Info key={i.id}>
          <S.Label>{i.label}</S.Label>
          {i.isCsr ? (
            <S.Description>
              <S.CsrInput value={i.description} />
            </S.Description>
          ) : (
            <S.Description>{i.description}</S.Description>
          )}
        </S.Info>
      ))
    }
  }

  return (
    <LayoutCommon>
      <S.ServiceDetailsPage>
        {serviceDetails ? (
          <>
            <S.SslInfoBlock>
              <S.SslInfoLeft>
                <S.Image src={resultImage} alt="resultImage" />
                <S.SslInfoLabel>{serviceDetails.name}</S.SslInfoLabel>
                <S.Status>
                  {i18n.t(`serviceDetails.${serviceDetails.status}`)}
                </S.Status>
              </S.SslInfoLeft>
              <S.SslInfoRight>
                {sslInfo.map((i) => {
                  return (
                    <React.Fragment key={i.id}>
                      <S.SslInfoLabel>{i.label}</S.SslInfoLabel>
                      <S.SslInfo>{i.info}</S.SslInfo>
                    </React.Fragment>
                  )
                })}
              </S.SslInfoRight>
            </S.SslInfoBlock>
            {serviceDetails?.status === 'Active' && (
              <S.InfoBlock>
                <DisplayDetails />
                {serviceDetails?.csr_configs?.san_details &&
                  serviceDetails?.csr_configs?.san_details?.length !== 0 &&
                  serviceDetails?.csr_configs?.san_details.map((i, index) => {
                    return (
                      <S.Info>
                        <S.Label>SAN {index + 1}</S.Label>
                        <S.Description>
                          {i.san_name} / {i.status_description}
                        </S.Description>
                      </S.Info>
                    )
                  })}
                {serviceDetails?.ssl_status !== 'Awaiting Configuration' &&
                (method === 'http' ||
                  method === 'https' ||
                  serviceDetails?.csr_configs?.private_key) ? (
                  <S.Info>
                    <S.Label>{i18n.t('serviceDetails.actions')}</S.Label>
                    <S.Description>
                      <DisplayButtons />
                    </S.Description>
                  </S.Info>
                ) : null}
              </S.InfoBlock>
            )}
          </>
        ) : (
          <S.LoaderContainer>
            <Loader color={'#00C084'} size={0.5} />
          </S.LoaderContainer>
        )}
      </S.ServiceDetailsPage>
    </LayoutCommon>
  )
}
