export const APP_TYPES = {
  SET_WINDOW_DIMENSIONS: 'APP_TYPES.SET_WINDOW_DIMENSIONS',
  ADD_TO_CART: 'APP_TYPES.ADD_TO_CART',
  REMOVE_FROM_CART: 'APP_TYPES.REMOVE_FROM_CART',
  UPDATE_CART: 'APP_TYPES.UPDATE_CART',
  SHOW_CART: 'APP_TYPES.SHOW_CART',
  TOGGLE_CART: 'APP_TYPES.TOGGLE_CART',
  SET_DOMAIN_NAMESERVERS: 'APP_TYPES.SET_DOMAIN_NAMESERVERS',
  SET_DOMAIN_IPS: 'APP_TYPES.SET_DOMAIN_IPS',
  SET_DOMAIN_CONTACT: 'APP_TYPES.SET_DOMAIN_CONTACT',
  SHOW_LANGUAGES_POPUP: 'APP_TYPES.SHOW_LANGUAGES_POPUP',
  TOGGLE_LANGUAGES_POPUP: 'APP_TYPES.TOGGLE_LANGUAGES_POPUP',
  SHOW_ACCOUNT_POPUP: 'APP_TYPES.SHOW_ACCOUNT_POPUP',
  TOGGLE_ACCOUNT_POPUP: 'APP_TYPES.TOGGLE_ACCOUNT_POPUP',
  ADD_ORDER_REQUEST: 'APP_TYPES.ADD_ORDER_REQUEST',
  ADD_ORDER_SUCCESS: 'APP_TYPES.ADD_ORDER_SUCCESS',
  ADD_ORDER_FAIL: 'APP_TYPES.ADD_ORDER_FAIL',
  SET_CART: 'APP_TYPES.SET_CART',
  SET_AUTH_DATA: 'APP_TYPES.SET_AUTH_DATA',
  LOGOUT: 'APP_TYPES.LOGOUT',
  CHANGE_BACKEND_LANGUAGE_REQUEST: 'APP_TYPES.CHANGE_BACKEND_LANGUAGE_REQUEST',
  GET_USER_INFO_REQUEST: 'APP_TYPES.GET_USER_INFO_REQUEST',
  GET_USER_INFO_SUCCESS: 'APP_TYPES.GET_USER_INFO_SUCCESS',
  GET_USER_INFO_FAIL: 'APP_TYPES.GET_USER_INFO_FAIL',
  GET_USER_CREDIT_REQUEST: 'APP_TYPES.GET_USER_CREDIT_REQUEST',
  GET_USER_CREDIT_SUCCESS: 'APP_TYPES.GET_USER_CREDIT_SUCCESS',
  GET_USER_CREDIT_FAIL: 'APP_TYPES.GET_USER_CREDIT_FAIL',
  GET_USER_IP: 'APP_TYPES.GET_USER_IP',
};
