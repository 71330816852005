import styled from 'styled-components';

import closeModal from '../../../images/closeModal.svg';
import registrationImage from '../../../images/registrationSuccessImage.svg';

export const RegistrationModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 1;
  z-index: 100;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;

  @media (min-width: 768px) {
    padding: 23px 42px;
  }
`;

export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-size: cover;

  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`;

export const Modal = styled.div`
  height: 100%;
  width: 304px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  outline: none;
  z-index: 100;

  @media (min-width: 768px) {
    width: 396px;
  }
`;

export const Image = styled.div`
  background-image: url(${registrationImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 177px;
  width: 219px;
  margin-top: 34px;
`;

export const Header = styled.div`
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: #242e46;
  margin-top: 12px;
`;
export const SubHeader = styled.div`
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  color: #242e46;
  margin-top: 8px;
  margin-bottom: 30px;
`;

export const SignUpBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00c084;
  border-radius: 5px;
  width: 100%;
  height: 38px;
  margin-top: 15px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`;
