import FetchApi from '../../helpers/FetchApi';

import { VPS_PAGE_TYPES } from './VpsPage.types';

export const getServerLocation = () => async (dispatch) => {
  try {
    dispatch({
      type: VPS_PAGE_TYPES.GET_SERVER_LOCATION_REQUEST,
    });

    const req = await FetchApi.get(`domains/getVultr?param=location`);
    dispatch({
      type: VPS_PAGE_TYPES.GET_SERVER_LOCATION_SUCCESS,
      serverLocationData: req.data.body,
    });
  } catch (e) {
    dispatch({
      type: VPS_PAGE_TYPES.GET_SERVER_LOCATION_FAIL,
      serverLocationError: e.data
        ? e.data.message || e.data.error
        : e.message || 'Something went wrong',
    });
  }
};

export const getServerOs = () => async (dispatch) => {
  try {
    dispatch({
      type: VPS_PAGE_TYPES.GET_SERVER_OS_REQUEST,
    });

    const req = await FetchApi.get(`domains/getVultr?param=os`);

    dispatch({
      type: VPS_PAGE_TYPES.GET_SERVER_OS_SUCCESS,
      serverOsData: req.data.body,
    });
  } catch (e) {
    dispatch({
      type: VPS_PAGE_TYPES.GET_SERVER_OS_FAIL,
      serverOsError: e.data
        ? e.data.message || e.data.error
        : e.message || 'Something went wrong',
    });
  }
};

export const getServerSize = () => async (dispatch) => {
  try {
    dispatch({
      type: VPS_PAGE_TYPES.GET_SERVER_SIZE_REQUEST,
    });

    const req = await FetchApi.get(`domains/getVultr?param=products`);

    dispatch({
      type: VPS_PAGE_TYPES.GET_SERVER_SIZE_SUCCESS,
      serverSizeData: req.data.body.products.product,
    });
  } catch (e) {
    dispatch({
      type: VPS_PAGE_TYPES.GET_SERVER_SIZE_FAIL,
      serverSizeError: e.data
        ? e.data.message || e.data.error
        : e.message || 'Something went wrong',
    });
  }
};

export const handleFixedFooterVisibility = (top, height) => ({
  type: VPS_PAGE_TYPES.HANDLE_FIXED_FOOTER_VISIBILITY,
  height,
});
