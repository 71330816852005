import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'integer', function() {
  return this.matches(/^\d+$/, 'validationFields.onlyDigits');
});

export const initialValues = {
  amount: '',
};

export const yupScheme = Yup.object().shape({
  amount: Yup.string()
    .integer()
    .required('validationFields.required')
    .max(8, 'validationFields.amountMax8'),
});
