export function PhysicalAvatar(name) {
  try {
    if (!name || name.indexOf(' ') === -1) {
      return '';
    }
    let names = name.split(' ');
    if (!names[0][0]) {
      return '';
    }
    let initials = '';

    initials +=
      names[0][0].toUpperCase() +
      names[names.length - 1][0].substring(0, 1).toUpperCase();
    return initials;
  } catch (e) {
    return '';
  }
}

export function LegalAvatar(name) {
  try {
    if (!name || name.indexOf(' ') === -1) {
      return '';
    }
    let names = name.split(' ');
    if (!names[0][0]) {
      return '';
    }
    let initials = '';
    if (names.length <= 2) {
      initials = names[0].substring(0, 2).toUpperCase();
    } else {
      if (!names[0][0] && !names[names.length - 2][0]) {
        return '';
      }
      initials +=
        names[0][0].toUpperCase() +
        names[names.length - 2][0].substring(0, 1).toUpperCase();
    }
    return initials;
  } catch (e) {
    return '';
  }
}
