export const transformContacts = (keys) => {
  let transformedContacts = {};
  const technical = ["Tech", "technical"];
  const registrant = ["Registrant", "owner"];
  const administrative = ["Admin", "administrative"];

  for (let contact of keys) {
    if (technical.includes(contact)) {
      transformedContacts.technical = contact;
    }
    if (registrant.includes(contact)) {
      transformedContacts.registrant = contact;
    }
    if (administrative.includes(contact)) {
      transformedContacts.administrative = contact;
    }
  }
  return transformedContacts;
};
