import * as Yup from 'yup';

export const initialValues = (personType) => ({
  personType: personType || 0,
  passportNumbers: '',
  taxPayerNumber: '',
  dateOfBirth: '',
  phoneNumber: '',
  passportSeries: '',
  companyNameLat: '',
  postalAddressCyr: '',
  legalAddressCyr: '',
  passportIssuer: '',
  passportDate: '',
});

export const yupScheme = Yup.object().shape({
  personType: Yup.number().required(),
  passportNumbers: Yup.string().when('personType', {
    is: 0,
    then: Yup.string().required('validationFields.id'),
  }),
  taxPayerNumber: Yup.string().when('personType', {
    is: 1,
    then: Yup.string().required('validationFields.tid'),
  }),
  dateOfBirth: Yup.string().when('personType', {
    is: 0,
    then: Yup.string().required('validationFields.dob'),
  }),
  passportSeries: Yup.string().when('personType', {
    is: 0,
    then: Yup.string(),
  }),
  companyNameLat: Yup.string().when('personType', {
    is: 1,
    then: Yup.string()
      .required('validationFields.companyName')
      .matches(
        /^[A-Za-z.:;"(/)\- ,'?!#%^*][A-Za-z0-9.:;"(/)\- ,'?!#%^*]*$/,
        'validationFields.companyNameInvalid'
      ),
  }),
  postalAddressCyr: Yup.string().when('personType', {
    is: 1,
    then: Yup.string()
      .required('validationFields.postalAddress')
      .matches(
        /^[\u0430-\u044f0-9А-ЯёЁ.:;"(/)\- ,'?!#%^*]+$/,
        'validationFields.postalAddressInvalid'
      ),
  }),
  legalAddressCyr: Yup.string().when('personType', {
    is: 1,
    then: Yup.string()
      .required('validationFields.legalAddress')
      .matches(
        /^[\u0430-\u044f0-9А-ЯёЁ.:;"(/)\- ,'?!#%^*]+$/,
        'validationFields.legalAddressInvalid'
      ),
  }),
  passportIssuer: Yup.string().when('personType', {
    is: 0,
    then: Yup.string().required('validationFields.passportIssuer'),
  }),
  passportDate: Yup.string().when('personType', {
    is: 0,
    then: Yup.string().required('validationFields.passportIssueDate'),
  }),
  phoneNumber: Yup.string().required('validationFields.phonenumber'),
});
