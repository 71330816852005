import React, { useEffect, useRef } from 'react'
import { changeUrl } from '../../hooks/changeUrl'
import { SSL_TABS, SslCards } from './SslPage.constants'
import { SSLServicesBlock } from '../HomePage/components/SSLServicesBlock/SSLServicesBlock'
import { useIntersection } from '../../hooks/useIsVisible'
import { LayoutCommon } from '../../layouts/LayoutCommon'
import { QuestionsList } from '../../components/QuestionsList'
import { questions } from '../SupportPage/SupportPage.constants'
import { SslCard } from '../../components/SslCard'
import { Helmet } from 'react-helmet'
import { i18n } from '../../i18n'
import qs from 'qs'

import * as S from './SslPage.styles'

export const SslPage = ({
  history,
  tab,
  changeTab,
  domainValidation,
  organizationValidation,
  extendedValidation,
  domainValidationData,
  organizationValidationData,
  extendedValidationData,
}) => {
  // useEffect(() => {
  //   domainValidation();
  //   organizationValidation();
  //   extendedValidation()
  // }, [])

  const viewRef = useRef()
  const inViewport = useIntersection(viewRef, '-200px')

  const params = qs.parse(history.location, {
    ignoreQueryPrefix: true,
    encode: true,
  })

  useEffect(() => {
    if (inViewport) {
      history.replace(changeUrl('/ssl-packages?section=faq'))
    } else if (!inViewport) {
      history.replace(changeUrl('/ssl-packages'))
    }
  }, [inViewport])

  useEffect(() => {
    if (params.search) {
      viewRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  return (
    <LayoutCommon transparentHeader>
      <Helmet>
        <title>{i18n.t('headers.sslPage')}</title>
        <meta
          name="description"
          content={i18n.t('headersDescriptions.sslPage')}
        />
        <link
          rel="alternate"
          href="https://internet.am/en/ssl-packages"
          hreflang="en"
        />
        <link
          rel="alternate"
          href="https://internet.am/ru/ssl-packages"
          hreflang="ru"
        />
        <link
          rel="alternate"
          href="https://internet.am/ssl-packages"
          hreflang="am-AM"
        />
        <meta name="keywords" content={i18n.t('headerKeywords.sslPage')} />
        <meta property="og:title" content={i18n.t('headers.sslPage')} />
        <meta
          property="og:description"
          content={i18n.t('headersDescriptions.sslPage')}
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ABC Domain LLC" />
        <meta
          property="og:image"
          content="https://internet.am/images/fb_logo.png"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={i18n.t('headers.sslPage')} />
        <meta
          name="twitter:description"
          content={i18n.t('headersDescriptions.sslPage')}
        />
        <meta
          name="twitter:image"
          content="https://internet.am/images/fb_logo.png"
        />
      </Helmet>
      <S.PageBody>
        <S.Header>
          <S.HeaderImg />
          <S.HeaderContainer>
            <S.SslContainerTitle inerTitle>
              {i18n.t('sslPage.title')}
            </S.SslContainerTitle>
          </S.HeaderContainer>
          <S.SslCategoryContainer>
            <S.SslCategory
              onClick={() => changeTab(SSL_TABS.DOMAIN_VALIDATION)}
              isActive={tab === SSL_TABS.DOMAIN_VALIDATION}
            >
              {i18n.t('sslPage.domainValidation')}
            </S.SslCategory>
            <S.SslCategory
              onClick={() => changeTab(SSL_TABS.ORGANIZATION_VALIDATION)}
              isActive={tab === SSL_TABS.ORGANIZATION_VALIDATION}
            >
              {i18n.t('sslPage.companyValidation')}
            </S.SslCategory>
            <S.SslCategory
              onClick={() => changeTab(SSL_TABS.EXTENDED_VALIDATION)}
              isActive={tab === SSL_TABS.EXTENDED_VALIDATION}
            >
              {i18n.t('sslPage.extendedValidation')}
            </S.SslCategory>
          </S.SslCategoryContainer>
        </S.Header>
        {/* <SSLServicesBlock /> */}
        {/* {tab === SSL_TABS.DOMAIN_VALIDATION && (
          <S.SslCardsContainer>
            {domainValidationData && domainValidationData.map((Ssl) => {
              return <SslCard key={Ssl.pid} card={Ssl} history={history} />;
            })}
            
          </S.SslCardsContainer>
        )} */}
        {tab === SSL_TABS.DOMAIN_VALIDATION && (
          <S.SslCardsContainer>
            {SslCards.domainValidation.data.map((Ssl) => {
              return <SslCard key={Ssl.id} card={Ssl} history={history} />
            })}
          </S.SslCardsContainer>
        )}
        {/* {tab === SSL_TABS.ORGANIZATION_VALIDATION && (
          <S.SslCardsContainer>
            {organizationValidationData && organizationValidationData.map((Ssl) => {
              return <SslCard key={Ssl.pid} card={Ssl} history={history} />;
            })}
          </S.SslCardsContainer>
        )}  */}
        {tab === SSL_TABS.ORGANIZATION_VALIDATION && (
          <S.SslCardsContainer>
            {SslCards.organizationValidation.data.map((Ssl) => {
              return <SslCard key={Ssl.id} card={Ssl} history={history} />
            })}
          </S.SslCardsContainer>
        )}
        {/* {tab === SSL_TABS.EXTENDED_VALIDATION && (
          <S.SslCardsContainer>
            {extendedValidationData && extendedValidationData.map((Ssl) => {
              return <SslCard key={Ssl.pid} card={Ssl} history={history} />;
            })}
          </S.SslCardsContainer>
        )}  */}
        {tab === SSL_TABS.EXTENDED_VALIDATION && (
          <S.SslCardsContainer>
            {SslCards.extendedValidation.data.map((Ssl) => {
              return <SslCard key={Ssl.id} card={Ssl} history={history} />
            })}
          </S.SslCardsContainer>
        )}
        <S.UpperBlock>
          <S.Packman />
          <S.FeedbackBlock>
            <S.Questions>{i18n.t('hostingPage.contacUsHeader')}</S.Questions>
            <S.WriteUsLink to={changeUrl('/open-ticket')}>
              {i18n.t('hostingPage.contacUsSubHeader')}
            </S.WriteUsLink>
          </S.FeedbackBlock>
        </S.UpperBlock>
        <S.BorderLine />
        <div ref={viewRef}>
          <QuestionsList isInPage questions={questions.ssl.list} />
        </div>
      </S.PageBody>
    </LayoutCommon>
  )
}
