import FetchApi from '../../helpers/FetchApi'

import { RESET_PASSWORD_PAGE_TYPES } from './ResetPasswordPage.types'

export const verifyHash = (hash) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PASSWORD_PAGE_TYPES.VERIFY_HASH_REQUEST,
    })

    const req = await FetchApi.get(`users/verifyresspasswordhash?hash=${hash}`)

    dispatch({
      type: RESET_PASSWORD_PAGE_TYPES.VERIFY_HASH_SUCCESS,
      data: req.data,
    })
  } catch (e) {
    dispatch({
      type: RESET_PASSWORD_PAGE_TYPES.VERIFY_HASH_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    })
  }
}

export const setPassword = (hash, values, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PASSWORD_PAGE_TYPES.SET_PASSWORD_REQUEST,
    })
    const req = await FetchApi.post(`users/setpassword`, {
      hash,
      password: values.password,
      password2: values.password,
    })

    dispatch({
      type: RESET_PASSWORD_PAGE_TYPES.SET_PASSWORD_SUCCESS,
      data: req.data,
    })
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    dispatch({
      type: RESET_PASSWORD_PAGE_TYPES.SET_PASSWORD_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    })
  }
}
