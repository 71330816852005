import { CSR_MODAL } from "./CsrModal.types";
import FetchApi from "../../../helpers/FetchApi";
import { omit } from "lodash";

export const generateCsr = (values, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: CSR_MODAL.GENERATE_CSR_REQUEST,
    });

    const formattedValues = omit(values, ["validationEmail", "validationType"]);

    const response = await FetchApi.post("products/generateCSR", {
      ...formattedValues,
      country: formattedValues.country.toUpperCase(),
    });
    dispatch({
      type: CSR_MODAL.GENERATE_CSR_SUCCESS,
      data: response.data,
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    dispatch({
      type: CSR_MODAL.GENERATE_CSR_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    });
  }
};

export const uploadCsr = (values, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: CSR_MODAL.UPLOAD_CSR_REQUEST,
    });
    const formattedValues = omit(values, ["validationEmail"]);
    await FetchApi.post(
      "products/uploadCSR",
      values.validationType === "file" ? formattedValues : values
    );
    dispatch({
      type: CSR_MODAL.UPLOAD_CSR_SUCCESS,
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    dispatch({
      type: CSR_MODAL.UPLOAD_CSR_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    });
  }
};

export const setTab = (tab) => ({
  type: CSR_MODAL.SET_TAB,
  tab,
});

export const setStep = (step) => ({
  type: CSR_MODAL.SET_STEP,
  step,
});

export const setIsKeyDownloaded = () => ({
  type: CSR_MODAL.SET_IS_KEY_DOWNLOADED,
});
