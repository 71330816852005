import React, { useCallback, useEffect } from 'react'
import qs from 'qs'
import { Formik, Form } from 'formik'
import { Helmet } from 'react-helmet'
import slugify from 'react-slugify'

import { LayoutCommon } from '../../layouts/LayoutCommon'
import { initialValues, yupScheme } from './ResetPasswordPage.config'
import * as S from './ResetPasswordPage.styles'
import { i18n } from '../../i18n'
import { Loader } from '../../components/Loader'
import { LoginModal } from '../../components/Modals/LoginModal'
import { ResetPasswordModal } from '../../components/Modals/ResetPasswordModal'
import ResetPasswordFailImage from '../../images/reset-password-fail.png'

import { changeUrl, constructCannonical } from '../../hooks/changeUrl'

const ResetPasswordPage = ({
  history,
  loading,
  error,
  showModal,
  verifyHash,
  setPassword,
  setPasswordData,
  setPasswordLoading,
  setPasswordError,
}) => {
  const handleEnter = useCallback((e, submitForm) => {
    if (e.key === 'Enter') {
      submitForm()
    }
  }, [])

  useEffect(() => {
    const params = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
      encode: true,
    })
    const hash = params.hash
    verifyHash(hash)
  }, [history.location.search])

  return (
    <LayoutCommon>
      <Helmet>
        <title>{i18n.t('headers.resetPasswordPage')}</title>
        <meta
          name="description"
          content={i18n.t('headersDescriptions.resetPasswordPage')}
        />
        <meta name="og:title" content={i18n.t('headers.resetPasswordPage')} />
        <meta
          name="og:description"
          content={i18n.t('headersDescriptions.resetPasswordPage')}
        />
        <meta
          name="og:image"
          content="https://internet.am/images/fb_logo.png"
        />
        {/* <link rel="canonical" href={constructCannonical('reset-password')} /> */}
      </Helmet>
      {loading ? (
        <S.RegistrationContainer centered>
          <Loader color="#00C084" size={0.5} />
        </S.RegistrationContainer>
      ) : error ? (
        <S.RegistrationContainer>
          <S.StyledImage
            src={ResetPasswordFailImage}
            alt="Reset password fail image"
            title="Image for reset password fail"
          />
          <S.FailText>{i18n.t('resetPasswordPage.failText')}</S.FailText>
          <S.TryAgainButton onClick={() => showModal(ResetPasswordModal)}>
            {i18n.t('resetPasswordPage.tryAgainButton')}
          </S.TryAgainButton>
        </S.RegistrationContainer>
      ) : (
        <S.RegistrationContainer>
          <S.PageTitle>{i18n.t('resetPasswordPage.title')}</S.PageTitle>
          <S.InformationText>
            {i18n.t('resetPasswordPage.infoText')}
          </S.InformationText>
          <S.FormContainer>
            <Formik
              initialValues={initialValues}
              validationSchema={yupScheme}
              onSubmit={async (values, { setSubmitting }) => {
                const params = qs.parse(history.location.search, {
                  ignoreQueryPrefix: true,
                  encode: true,
                })
                const hash = params.hash
                await setPassword(hash, values, () => {
                  showModal(LoginModal)
                  history.push(changeUrl('/'))
                })
                setSubmitting(false)
              }}
            >
              {({
                touched,
                errors,
                isSubmitting,
                handleChange,
                values,
                setFieldValue,
                submitForm,
                isValid,
              }) => (
                <Form onKeyPress={() => handleEnter(submitForm)}>
                  <S.FormItem>
                    <S.Label>{i18n.t('resetPasswordPage.password')}*</S.Label>
                    <S.Input
                      type="password"
                      placeholder="******"
                      name="password"
                      onChange={handleChange}
                      value={values.password}
                    />
                    {errors.password && touched.password && (
                      <S.ErrorMessage>{i18n.t(errors.password)}</S.ErrorMessage>
                    )}
                  </S.FormItem>
                  <S.FormItem>
                    <S.Label>
                      {i18n.t('resetPasswordPage.confirmPassword')}*
                    </S.Label>
                    <S.Input
                      type="password"
                      placeholder="******"
                      name="password2"
                      onChange={handleChange}
                      value={values.password2}
                    />
                    {errors.password2 && touched.password2 && (
                      <S.ErrorMessage>
                        {i18n.t(errors.password2)}
                      </S.ErrorMessage>
                    )}
                  </S.FormItem>
                  <S.ConfirmButton type="submit" disabled={isSubmitting}>
                    {setPasswordLoading ? (
                      <Loader color="#FFF" size={0.5} />
                    ) : (
                      i18n.t('resetPasswordPage.confirmButton')
                    )}
                  </S.ConfirmButton>
                  {setPasswordError && (
                    <div>
                      {i18n.t(
                        `backend.resetPassword.${slugify(setPasswordError)}`
                      )}
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </S.FormContainer>
        </S.RegistrationContainer>
      )}
    </LayoutCommon>
  )
}

export { ResetPasswordPage }
