import FetchApi from '../../helpers/FetchApi';

import { CONFIRM_EMAIL_PAGE_TYPES } from './ConfirmEmailPage.types';

export const verifyHash = (hash) => async (dispatch) => {
  try {
    dispatch({
      type: CONFIRM_EMAIL_PAGE_TYPES.VERIFY_HASH_REQUEST,
    });

    const req = await FetchApi.get(`users/verifyEmail?hash=${hash}`);

    dispatch({
      type: CONFIRM_EMAIL_PAGE_TYPES.VERIFY_HASH_SUCCESS,
      data: req.data,
    });
  } catch (e) {
    dispatch({
      type: CONFIRM_EMAIL_PAGE_TYPES.VERIFY_HASH_FAIL,
      error: e.data
        ? e.data.message || e.data.error
        : e.message || 'Something went wrong',
    });
  }
};
