import React, { useCallback } from 'react';
import { Formik } from 'formik';

import * as S from './TransferDomainModal.styles';
import { i18n } from '../../../i18n';
import { initialValues, yupScheme } from './TransferDomainModal.config';
import { Loader } from '../../../components/Loader';
import { TransferDomainSuccessModal } from '../TransferDomainSuccessModal';

export const TransferDomainModal = ({
  hideModal,
  transferDomain,
  initialValue,
  showModal,
  error,
  loading,
}) => {
  const handleEnter = useCallback((e, submitForm) => {
    if (e.key === 'Enter') {
      submitForm();
    }
  }, []);
  return (
    <S.Modal onClick={(e) => e.stopPropagation()}>
      <S.TransferDomainModalContainer>
        <S.ModalTitle>{i18n.t('transferDomainModal.title')}</S.ModalTitle>
        <Formik
          initialValues={initialValues(initialValue)}
          validationSchema={yupScheme}
          onSubmit={(values) => {
            transferDomain(values, () => {
              showModal(TransferDomainSuccessModal);
            });
          }}
        >
          {({
            touched,
            errors,
            values,
            isSubmitting,
            handleChange,
            submitForm,
          }) => (
            <S.StyledForm onKeyPress={() => handleEnter(submitForm)}>
              <S.FormItem>
                <S.Row>
                  <S.Label>
                    {i18n.t('transferDomainModal.domainLabel')}*
                  </S.Label>
                  <S.Input
                    placeholder={i18n.t(
                      'transferDomainModal.domainPlaceholder'
                    )}
                    onChange={handleChange}
                    name="domain_name"
                    value={values.domain_name}
                  />
                </S.Row>
                <S.Row>
                  {touched.domain_name && errors.domain_name && (
                    <S.ErrorMessage spaced>
                      {i18n.t(errors.domain_name)}
                    </S.ErrorMessage>
                  )}
                </S.Row>
              </S.FormItem>
              <S.FormItem>
                <S.Row>
                  <S.Label>
                    {i18n.t('transferDomainModal.eppCodeLabel')}*
                  </S.Label>
                  <S.Input
                    placeholder={i18n.t(
                      'transferDomainModal.eppCodePlaceholder'
                    )}
                    name="epp_code"
                    onChange={handleChange}
                    value={values.epp_code}
                  />
                </S.Row>
                <S.Row>
                  {errors.epp_code && touched.epp_code && (
                    <S.ErrorMessage>{i18n.t(errors.epp_code)}</S.ErrorMessage>
                  )}
                </S.Row>
              </S.FormItem>
              <S.SubmitButton>
                {loading ? (
                  <Loader color="#FFF" size={0.5} />
                ) : (
                  i18n.t('transferDomainModal.submit')
                )}
              </S.SubmitButton>
              {error && <S.ErrorMessage spaced>{i18n.t(`backend.transferDomain.${error}`)}</S.ErrorMessage>}
            </S.StyledForm>
          )}
        </Formik>
        <S.CloseModalButton onClick={() => hideModal()} />
      </S.TransferDomainModalContainer>
    </S.Modal>
  );
};
