import FetchApi from '../../../helpers/FetchApi'
import { getDomains } from '../../../pages/ClientAreaPage/ClientAreaPage.actions'
import { RENEW_DOMAIN_TYPES } from './RenewDomainModal.types'

export const renewDomain =
  (domainid, years, promocode, onSuccess) => async (dispatch) => {
    try {
      dispatch({
        type: RENEW_DOMAIN_TYPES.RENEW_DOMAIN_REQUEST,
      })
      const req = await FetchApi.post('domains/renewdomain', {
        domainid,
        promocode,
        years,
      })
      dispatch({
        type: RENEW_DOMAIN_TYPES.RENEW_DOMAIN_SUCCESS,
      })
      dispatch(getDomains())
      if (onSuccess) {
        onSuccess(req.data.data.invoiceid)
      }
      return req.data.data.status
    } catch (e) {
      dispatch({
        type: RENEW_DOMAIN_TYPES.RENEW_DOMAIN_FAIL,
        error: e,
      })
      return false
    }
  }
