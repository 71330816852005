import { SEARCH_AREA_TYPES } from './SearchAreaBlock.types';

export const toggleTld = (tld) => ({
  type: SEARCH_AREA_TYPES.TOGGLE_TLD,
  tld,
});

export const cutTld = (domain) => ({
  type: SEARCH_AREA_TYPES.CUT_TLD,
  domain,
});

