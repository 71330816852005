import React from 'react'
import * as S from './SSLConfigurationPage.styles'

import { i18n } from '../../i18n'
import { LayoutCommon } from '../../layouts/LayoutCommon/LayoutCommon'

export const SSLConfigurationPage = () => {
  return (
    <LayoutCommon>
      <S.SSLConfigurationPage>
        {/* <S.Header>{i18n.t('sslConfigurationPage.howTo')}</S.Header> */}
        <S.StepsContainer>
          {/* <h5>{i18n.t('sslConfigurationPage.howToSub')}</h5> */}
          <ul>
            <li>{i18n.t('sslConfigurationPage.steps1.step1')}</li>
            <li>{i18n.t('sslConfigurationPage.steps1.step2')}</li>
            <li>{i18n.t('sslConfigurationPage.steps1.step3')}</li>
            <li>{i18n.t('sslConfigurationPage.steps1.step4')}</li>
            <li>{i18n.t('sslConfigurationPage.steps1.step5')}</li>
            <li>{i18n.t('sslConfigurationPage.steps1.step6')}</li>
          </ul>
          <h5>{i18n.t('sslConfigurationPage.sub2')}</h5>
          <h5>{i18n.t('sslConfigurationPage.sub2v1')}</h5>

          <ol>
            <li>{i18n.t('sslConfigurationPage.steps2.step3')}</li>
            <li>{i18n.t('sslConfigurationPage.steps2.step4')}</li>
            <li>{i18n.t('sslConfigurationPage.steps2.step5')}</li>
            <li>{i18n.t('sslConfigurationPage.steps2.step6')}</li>
          </ol>
          <h5>{i18n.t('sslConfigurationPage.sub1')}</h5>

          <ol>
            <li>{i18n.t('sslConfigurationPage.steps3.step7')}</li>
            <li>{i18n.t('sslConfigurationPage.steps3.step8')}</li>
          </ol>
          <h5>{i18n.t('sslConfigurationPage.sub9')}</h5>

          <ol>
            <li>{i18n.t('sslConfigurationPage.steps4.step9')}</li>
            <li>{i18n.t('sslConfigurationPage.steps4.step10')}</li>
          </ol>

          <h5>{i18n.t('sslConfigurationPage.sub3')}</h5>

          <ol>
            <li>{i18n.t('sslConfigurationPage.steps5.step11')}</li>
            <li>{i18n.t('sslConfigurationPage.steps5.step12')}</li>
          </ol>

          <h5>{i18n.t('sslConfigurationPage.sub9')}</h5>

          <ol>
            <li>{i18n.t('sslConfigurationPage.steps6.step13')}</li>
            <li>{i18n.t('sslConfigurationPage.steps6.step14')}</li>
          </ol>

          <p>{i18n.t('sslConfigurationPage.sub4')}</p>
          <p>{i18n.t('sslConfigurationPage.sub5')}</p>
          <h5>{i18n.t('sslConfigurationPage.sub7')}</h5>
          <em>{i18n.t('sslConfigurationPage.sub6')}</em>
        </S.StepsContainer>
      </S.SSLConfigurationPage>
    </LayoutCommon>
  )
}
