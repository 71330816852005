import { SSL_TYPES } from './SslConfigurationPage.types'
import FetchApi from '../../helpers/FetchApi'

export const setStep = (step) => ({
  type: SSL_TYPES.SET_STEP,
  step: step,
})

export const setValues = (values, onSuccess) => ({
  type: SSL_TYPES.SET_VALUES,
  values: values,
})

export const configureSsl = (values, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: SSL_TYPES.CONFIGURE_SSL_REQUEST,
    })

    const response = await FetchApi.post('products/configureSsl', values)

    if (response?.data?.body.result === 'success') {
      localStorage.setItem(
        SSL_TYPES.CONFIGURE_SSL_SUCCESS,
        JSON.stringify(response)
      )
    }

    dispatch({
      type: SSL_TYPES.CONFIGURE_SSL_SUCCESS,
      data: response,
    })

    if (onSuccess && response?.data.body.result === 'success') {
      onSuccess()
    }
  } catch (e) {
    dispatch({
      type: SSL_TYPES.CONFIGURE_SSL_FAIL,
      error: e,
    })
  }
}

export const initiateConfigureSslData = (data) => (dispatch) => {
  dispatch({
    type: SSL_TYPES.CONFIGURE_SSL_SUCCESS,
    data,
  })
}

export const firstStepConfigure = (values) => async (dispatch) => {
  try {
    dispatch({
      type: SSL_TYPES.FIRST_STEP_CONFIGURE_SSL_REQUEST,
    })

    const response = await FetchApi.post('products/configureSsl', values)

    dispatch({
      type: SSL_TYPES.FIRST_STEP_CONFIGURE_SSL_SUCCESS,
      data: response.data.body['Organization Contact Information'],
    })
  } catch (e) {
    dispatch({
      type: SSL_TYPES.FIRST_STEP_CONFIGURE_SSL_FAIL,
      error: e,
    })
  }
}
