import { omit } from 'lodash';

import FetchApi from '../../../helpers/FetchApi';
import { VIEW_TICKET_TYPES } from './ViewTicketModal.types';

export const getTicketsInfo = (ticketId, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: VIEW_TICKET_TYPES.GET_TICKET_REQUEST,
    });
    const req = await FetchApi.get(`tickets/info?ticketId=${ticketId}`);
    if (onSuccess) {
      onSuccess();
    }
    dispatch({
      type: VIEW_TICKET_TYPES.GET_TICKET_SUCCESS,
      data: req.data.data,
    });
  } catch (e) {
    dispatch({
      type: VIEW_TICKET_TYPES.GET_TICKET_FAIL,
      error: e,
    });
  }
};

export const getTicketsInfoSimple = (ticketId) => async (dispatch) => {
  try {
    const req = await FetchApi.get(`tickets/info?ticketId=${ticketId}`);
    dispatch({
      type: VIEW_TICKET_TYPES.GET_TICKET_SUCCESS,
      data: req.data.data,
    });
  } catch (e) {}
};

export const replyTicket = (values, auth) => async (dispatch) => {
  const ommitedValues = omit(values, ['files']);
  try {
    dispatch({
      type: VIEW_TICKET_TYPES.REPLY_TICKET_REQUEST,
    });
    if (values.files.length > 0) {
      const uploadRequest = await FetchApi.upload(values.files);
      const req = await FetchApi.post('tickets/replyTicket', {
        ...ommitedValues,
        ...uploadRequest.data,
      });

      const attachments = uploadRequest.data.files.map((item) => ({
        originalName: item.originalname,
        filename: item.filename,
        index: 1,
      }));

      dispatch({
        type: VIEW_TICKET_TYPES.REPLY_TICKET_SUCCESS,
        message: values.ticketMessage,
        email: auth.email,
        fullname: auth.fullname,
        attachments,
      });
      return req.data.data.message;
    } else {
      const req = await FetchApi.post('tickets/replyTicket', ommitedValues);
      dispatch({
        type: VIEW_TICKET_TYPES.REPLY_TICKET_SUCCESS,
        message: values.ticketMessage,
        email: auth.email,
        fullname: auth.fullname,
      });
      return req.data.data.message;
    }
  } catch (e) {
    dispatch({
      type: VIEW_TICKET_TYPES.REPLY_TICKET_FAIL,
      error: e,
    });
    return false;
  }
};

export const closeTicket = (ticketId, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: VIEW_TICKET_TYPES.CLOSE_TICKET_REQUEST,
    });
    const req = await FetchApi.post('tickets/closeTicket', {
      ticketId,
    });
    dispatch({
      type: VIEW_TICKET_TYPES.CLOSE_TICKET_SUCCESS,
      ticketId,
    });
    if (onSuccess) {
      onSuccess();
    }
    return req.data.data.status;
  } catch (e) {
    dispatch({
      type: VIEW_TICKET_TYPES.CLOSE_TICKET_FAIL,
      error: e,
    });
    return false;
  }
};
