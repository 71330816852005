import { VpsPage as Self } from './VpsPage'
import { connect } from 'react-redux'
import {
  getServerLocation,
  getServerOs,
  getServerSize,
  handleFixedFooterVisibility,
} from './VpsPage.actions'

import { addToCart, numberWithCommas } from '../../common/App/App.actions'

const mapStateToProps = ({ vpsPage, app }) => ({
  locationLoading: vpsPage.serverLocationLoading,
  locationError: vpsPage.serverLocationError,
  locationData: vpsPage.serverLocationData,
  osLoading: vpsPage.serverOsLoading,
  osError: vpsPage.serverOsError,
  osData: vpsPage.serverOsData,
  sizeLoading: vpsPage.serverSizeLoading,
  sizeError: vpsPage.serverSizeError,
  sizeData: vpsPage.serverSizeData,
  location: vpsPage.location,
  os: vpsPage.os,
  productSize: vpsPage.productSize,
  shouldFixedFooterBeVisible: vpsPage.shouldFixedFooterBeVisible,
  auth: app.auth,
})

const mapDispatchToProps = {
  getServerLocation,
  getServerOs,
  getServerSize,
  handleFixedFooterVisibility,
  numberWithCommas,
  addToCart,
}

export const VpsPage = connect(mapStateToProps, mapDispatchToProps)(Self)
