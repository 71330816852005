import * as Yup from 'yup'

export const loginInitialValues = {
  email: '',
  password: '',
}

export const loginAsClientInitialValues = {
  email: '',
  password: '',
  username: '',
}

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || 'Two strings should be equal',
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref)
    },
  })
}

export const loginAsClientYupScheme = Yup.object().shape({
  username: Yup.string().required('validationFields.email'),
  password: Yup.string()
    // .min(6, 'validationFields.passMin')
    .max(64, 'validationFields.passMax')
    .required('validationFields.loginPass'),
  email: Yup.string()
    .email('validationFields.invalidTyping')
    .required('validationFields.client'),
})

export const loginYupScheme = Yup.object().shape({
  email: Yup.string()
    .email('validationFields.invalidTyping')
    .required('validationFields.email'),
  password: Yup.string()
    // .min(6, 'validationFields.passMin')
    .max(64, 'validationFields.passMax')
    .required('validationFields.loginPass'),
})

Yup.addMethod(Yup.string, 'equalTo', equalTo)
