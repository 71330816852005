import styled from 'styled-components';

export const CheckboxContainer = styled.div`
    width: 18px;
    height: 18px;
    cursor: pointer;
    background: ${(p) => p.isChecked ? '#00c084' : '#ccc'};
    border-radius: 3px;
    margin-right: 25px;
    margin-top: 7px;
`

export const Checkbox = styled.span`
    height: 15px;
    width: 15px;
    &:after {
    content: '';
    position: relative;
    display: ${(p) => (p.isChecked ? 'block' : 'none')};
    left: 6px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid ${(p) => 'white'};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    
`;