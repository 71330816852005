function getDistinctDomains(data) {
  const resArr = [];
  data.forEach(item => {
    const i = resArr.findIndex(
      x => x.tld === item.tld && x.domain === item.domain
    );
    if (i <= -1) {
      resArr.push({ ...item });
    }
  });
  return resArr;
}

export { getDistinctDomains };
