import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Draggable from 'react-draggable'

import * as S from './ErrorPage.styles'
import { i18n } from '../../i18n'
import { services } from './ErrorPage.constants'
import { ErrorMobileMenu } from './ErrorMobileMenu'
import { changeUrl } from '../../hooks/changeUrl'

const ErrorPage = ({
  toggleMenu,
  setOpaque,
  changeHatColor,
  changeGlassesColor,
  changeBeardsColor,
  changeCostColor,
  changeShirtColor,
  hatColor,
  glassesColor,
  beardsColor,
  costColor,
  shirtColor,
}) => {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    setOpaque(window.scrollY < 50 ? false : true)
  }

  const randomizeHatColor = () => {
    changeHatColor(Math.floor(Math.random() * 360))
  }
  const randomizeGlassesColor = () => {
    changeGlassesColor(Math.floor(Math.random() * 360))
  }
  const randomizeBeardsColor = () => {
    changeBeardsColor(Math.floor(Math.random() * 360))
  }
  const randomizeCostColor = () => {
    changeCostColor(Math.floor(Math.random() * 360))
  }
  const randomizeShirtColor = () => {
    changeShirtColor(Math.floor(Math.random() * 360))
  }

  return (
    <S.PageBody>
      <Helmet>
        <title>{i18n.t('headers.errorPage')}</title>
        <meta
          name="description"
          content={i18n.t('headersDescriptions.errorPage')}
        />
        <meta name="og:title" content={i18n.t('headers.errorPage')} />
        <meta
          name="og:description"
          content={i18n.t('headersDescriptions.errorPage')}
        />
        <meta
          name="og:image"
          content="https://internet.am/images/fb_logo.png"
        />
      </Helmet>
      <S.BodyContainer>
        <S.Header>
          <S.Menu onClick={toggleMenu} />
          <ErrorMobileMenu />
          <S.HomeLink to={changeUrl('/')}>
            <S.Logo />
          </S.HomeLink>
          <S.Services>
            {services.map((servicePackage) => (
              <S.ServiceLink to={changeUrl(servicePackage.link)}>
                <S.Service
                  key={servicePackage.id}
                  src={
                    i18n.language === 'am'
                      ? servicePackage.image
                      : i18n.language === 'en'
                      ? servicePackage.imageEn
                      : i18n.language === 'ru'
                      ? servicePackage.imageRu
                      : servicePackage.image
                  }
                  alt="Service image"
                  href={changeUrl(servicePackage.link)}
                />
              </S.ServiceLink>
            ))}
          </S.Services>
          <S.ErrorImg />
        </S.Header>
        <S.MainBlock>
          <Draggable onStart={randomizeHatColor}>
            <S.HatImg rand={hatColor} />
          </Draggable>
          <Draggable onStart={randomizeGlassesColor}>
            <S.GlassesImg rand={glassesColor} />
          </Draggable>
          <Draggable onStart={randomizeBeardsColor}>
            <S.BeadsImg rand={beardsColor} />
          </Draggable>
          <S.OstrichImg />
          <Draggable onStart={randomizeCostColor}>
            <S.CostImg rand={costColor} />
          </Draggable>
          <Draggable onStart={randomizeShirtColor}>
            <S.ShirtImg rand={shirtColor} />
          </Draggable>
        </S.MainBlock>
      </S.BodyContainer>
    </S.PageBody>
  )
}

export { ErrorPage }
