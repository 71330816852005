import styled from 'styled-components'
import { Form, Field } from 'formik'
import Select from 'react-select'

import closeModal from '../../../images/closeModal.svg'

export const Modal = styled.div`
  height: 550px;
  width: 100%;
  border-radius: 5px;
  outline: none;
  z-index: 100;
  overflow-y: auto;

  @media (min-width: 768px) {
    width: auto;
  }
`

export const CsrModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 1;
  z-index: 100;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
  @media (min-width: 768px) {
    width: 600px;
    padding: 50px 42px 0px 42px;

    margin-left: 0;
    margin-right: 0;
  }
`

export const ModalTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #242e46;
`

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
  @media (min-width: 768px) {
    align-items: center;
    margin-top: 25px;
  }
  @media (min-width: 1280px) {
    margin-top: 40px;
  }
`

export const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
  text-align: left;

  @media (min-width: 768px) {
    width: 425px;
    margin-bottom: 10px;
    font-size: 18px;
  }

  @media (min-width: 1280px) {
    width: unset;
    font-size: 18px;
    margin-bottom: 15px;
  }
`

export const Input = styled(Field)`
  padding: 5px 12px;
  max-height: 44px;
  width: 100%;
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  text-align: left;
  margin-bottom: 5px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    width: 425px;
    padding: 12px;
    max-height: 44px;
    font-size: 16px;
  }
  @media (min-width: 1280px) {
    width: 100%;
    padding: 12px;
    max-height: 44px;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 0px;
  }
`

export const StyledSelect = styled(Select)`
  width: 100%;
`

export const Textarea = styled(Input)`
  height: 80px;
  max-height: unset !important;
  resize: vertical;
  max-height: 300px;
  margin-top: 20px;
`

export const Tabs = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Tab = styled.div`
  font-size: 14px;
  color: #484857;
  cursor: pointer;
  padding-right: 4px;
  padding-left: 4px;
  border-bottom: ${(p) => (p.isActive ? '2px solid #c3c5cb;' : 'none')};
  padding-bottom: 6px;

  @media (min-width: 768px) {
    font-size: 24px;
    padding-bottom: ${(p) => (p.isActive ? '8px' : '0px')};
  }
`

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    background: #ffffff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
`

export const SubmitButton = styled.button`
  background-color: #242e46;
  border-radius: 5px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  opacity: 0.87;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 7px 50px;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }

  @media (min-width: 768px) {
    padding: 11px 65px;
    font-size: 18px;
    align-self: center;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  @media (min-width: 1280px) {
    padding: 12px 65px;
    font-size: 18px;
    align-self: flex-start;
    margin-top: 74px;
    margin-bottom: 0px;
    width: 100%;
  }
`

export const StyledButton = styled.button`
  background-color: #242e46;
  border-radius: 5px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  opacity: 0.87;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 7px 50px;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  &:disabled {
    background-color: #cecece;
  }

  @media (min-width: 768px) {
    padding: 11px 65px;
    font-size: 18px;
    align-self: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  @media (min-width: 1280px) {
    padding: 12px 65px;
    font-size: 18px;
    align-self: flex-start;
    margin-top: 30px;
    margin-bottom: 0px;
    width: 100%;
  }
`

export const ErrorMessage = styled.div`
  text-align: left;
  margin-top: 5px;
  color: red;
  font-size: 12px;

  @media (min-width: 1280px) {
    width: 100%;
  }
`

export const GeneratedCSRResult = styled.div`
  padding: 20px;
  height: 400px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-size: cover;

  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`
