import { pick } from 'lodash';

const physicalFields = [
  'passportNumbers',
  'dateOfBirth',
  'passportSeries',
  'passportIssuer',
  'passportDate',
  'phoneNumber',
];
const legalFields = [
  'taxPayerNumber',
  'companyNameLat',
  'postalAddressCyr',
  'legalAddressCyr',
  'phoneNumber',
];

export const validateAdditionalField = (product, e, item, legalStatus) => {
  if (!product.additionalFields) return true;

  if (!legalStatus) {
    const phyisicalHasNonFilledValues = Object.values(
      pick(product.additionalFields, physicalFields)
    ).some((item) => !item);
    const legalHasNonFilledValues = Object.values(
      pick(product.additionalFields, legalFields)
    ).some((item) => !item);
    if (phyisicalHasNonFilledValues && legalHasNonFilledValues) return true;
    return false;
  }

  const importantFields = pick(
    product.additionalFields,
    legalStatus === 'physical' ? physicalFields : legalFields
  );

  const isNonFilledValue = Object.values(importantFields).some((item) => !item);

  if (isNonFilledValue) return true;

  return false;
};
