import React from "react";

export default (props) => (
  // <svg
  //   {...props}
  //   width="24"
  //   height="24"
  //   viewBox="0 0 24 24"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <path
  //     opacity="0.87"
  //     d="M669.01 888.671L668.671 889.021V880.167C668.671 879.857 668.548 879.56 668.33 879.342C668.111 879.123 667.814 879 667.505 879C667.195 879 666.899 879.123 666.68 879.342C666.461 879.56 666.338 879.857 666.338 880.167V889.021L666 888.671C665.78 888.452 665.482 888.328 665.171 888.328C664.861 888.328 664.563 888.452 664.343 888.671C664.123 888.891 664 889.189 664 889.5C664 889.81 664.123 890.108 664.343 890.328L666.676 892.661C666.787 892.768 666.918 892.851 667.061 892.906C667.201 892.968 667.352 893 667.505 893C667.657 893 667.808 892.968 667.948 892.906C668.091 892.851 668.222 892.768 668.333 892.661L670.666 890.328C670.775 890.219 670.861 890.09 670.92 889.948C670.979 889.806 671.009 889.654 671.009 889.5C671.009 889.346 670.979 889.194 670.92 889.052C670.861 888.909 670.775 888.78 670.666 888.671C670.558 888.563 670.428 888.476 670.286 888.418C670.144 888.359 669.992 888.328 669.838 888.328C669.684 888.328 669.532 888.359 669.39 888.418C669.248 888.476 669.118 888.563 669.01 888.671Z"
  //     fill={props.fillcolor}
  //   />
  //   <path
  //     d="M678.676 880.945C678.676 881.57 678.569 882.115 678.355 882.578C678.145 883.042 677.826 883.404 677.398 883.664C676.974 883.922 676.451 884.053 675.828 884.059H675.688V883.113H675.781C676.336 883.111 676.755 882.992 677.039 882.758C677.323 882.523 677.488 882.178 677.535 881.723C677.236 882.014 676.889 882.16 676.496 882.16C676.15 882.16 675.852 882.078 675.602 881.914C675.352 881.747 675.161 881.52 675.031 881.23C674.904 880.939 674.84 880.609 674.84 880.242C674.84 879.872 674.918 879.535 675.074 879.23C675.233 878.923 675.457 878.68 675.746 878.5C676.035 878.32 676.368 878.23 676.746 878.23C677.132 878.23 677.47 878.327 677.762 878.52C678.053 878.712 678.279 878.984 678.438 879.336C678.596 879.688 678.676 880.094 678.676 880.555V880.945ZM675.965 880.23C675.965 880.431 675.995 880.612 676.055 880.773C676.115 880.932 676.204 881.059 676.324 881.152C676.447 881.246 676.596 881.293 676.773 881.293C676.951 881.293 677.107 881.247 677.242 881.156C677.378 881.065 677.479 880.948 677.547 880.805V880.348C677.547 880.087 677.512 879.867 677.441 879.688C677.374 879.505 677.279 879.37 677.156 879.281C677.036 879.19 676.898 879.145 676.742 879.145C676.583 879.145 676.445 879.195 676.328 879.297C676.211 879.398 676.121 879.533 676.059 879.699C675.996 879.863 675.965 880.04 675.965 880.23Z"
  //     fill={props.fillcolor}
  //   />
  //   <path
  //     d="M677.629 887.305V893H676.5V888.645L675.156 889.062V888.148L677.508 887.305H677.629Z"
  //     fill={props.fillcolor}
  //   />
  // </svg>

  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.87"
      d="M6.00009 9.32852L6.33842 8.97853L6.33842 17.8334C6.33842 18.1428 6.46133 18.4395 6.68012 18.6583C6.89891 18.8771 7.19565 19 7.50506 19C7.81447 19 8.11121 18.8771 8.33 18.6583C8.54879 18.4395 8.6717 18.1428 8.6717 17.8334L8.6717 8.97853L9.01003 9.32852C9.22971 9.5482 9.52767 9.67162 9.83835 9.67162C10.149 9.67162 10.447 9.5482 10.6667 9.32852C10.8863 9.10884 11.0098 8.81088 11.0098 8.5002C11.0098 8.18952 10.8863 7.89157 10.6667 7.67189L8.33338 5.3386C8.22243 5.23239 8.09159 5.14913 7.94839 5.0936C7.80874 5.03188 7.65774 5 7.50506 5C7.35238 5 7.20138 5.03188 7.06174 5.0936C6.91853 5.14913 6.78769 5.23239 6.67674 5.3386L4.34345 7.67189C4.23468 7.78066 4.14839 7.9098 4.08952 8.05192C4.03065 8.19404 4.00036 8.34637 4.00036 8.5002C4.00036 8.65404 4.03065 8.80636 4.08952 8.94849C4.14839 9.09061 4.23468 9.21974 4.34345 9.32852C4.45223 9.4373 4.58137 9.52358 4.72349 9.58245C4.86561 9.64132 5.01794 9.67162 5.17177 9.67162C5.3256 9.67162 5.47793 9.64132 5.62005 9.58245C5.76218 9.52358 5.89131 9.4373 6.00009 9.32852Z"
      fill={props.fillcolor}
    />
    <path
      d="M18.6758 15.9453C18.6758 16.5703 18.569 17.1146 18.3555 17.5781C18.1445 18.0417 17.8255 18.4036 17.3984 18.6641C16.974 18.9219 16.4505 19.0534 15.8281 19.0586H15.6875V18.1133H15.7812C16.3359 18.1107 16.7552 17.9922 17.0391 17.7578C17.3229 17.5234 17.4883 17.1784 17.5352 16.7227C17.2357 17.0143 16.8893 17.1602 16.4961 17.1602C16.1497 17.1602 15.8516 17.0781 15.6016 16.9141C15.3516 16.7474 15.1615 16.5195 15.0312 16.2305C14.9036 15.9388 14.8398 15.6094 14.8398 15.2422C14.8398 14.8724 14.918 14.5352 15.0742 14.2305C15.2331 13.9232 15.457 13.6797 15.7461 13.5C16.0352 13.3203 16.3685 13.2305 16.7461 13.2305C17.1315 13.2305 17.4701 13.3268 17.7617 13.5195C18.0534 13.7122 18.2786 13.9844 18.4375 14.3359C18.5964 14.6875 18.6758 15.0938 18.6758 15.5547V15.9453ZM15.9648 15.2305C15.9648 15.431 15.9948 15.612 16.0547 15.7734C16.1146 15.9323 16.2044 16.0586 16.3242 16.1523C16.4466 16.2461 16.5964 16.293 16.7734 16.293C16.9505 16.293 17.1068 16.2474 17.2422 16.1562C17.3776 16.0651 17.4792 15.9479 17.5469 15.8047V15.3477C17.5469 15.0872 17.5117 14.8672 17.4414 14.6875C17.3737 14.5052 17.2786 14.3698 17.1562 14.2812C17.0365 14.1901 16.8984 14.1445 16.7422 14.1445C16.5833 14.1445 16.4453 14.1953 16.3281 14.2969C16.2109 14.3984 16.1211 14.5326 16.0586 14.6992C15.9961 14.8633 15.9648 15.0404 15.9648 15.2305Z"
      fill={props.fillcolor}
    />
    <path
      d="M17.6289 4.30469V10H16.5V5.64453L15.1562 6.0625V5.14844L17.5078 4.30469H17.6289Z"
      fill={props.fillcolor}
    />
  </svg>
);
