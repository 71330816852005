import { ErrorPage as Self } from './ErrorPage'
import { connect } from 'react-redux'
import { toggleMenu, setOpaque } from '../../components/Header/Header.actions'
import {
  changeHatColor,
  changeGlassesColor,
  changeBeardsColor,
  changeCostColor,
  changeShirtColor,
} from '../ErrorPage/ErrorPage.actions'

const mapStateToProps = ({ header, errorPage }) => ({
  isExpanded: header.isExpanded,
  hatColor: errorPage.hatColor,
  glassesColor: errorPage.glassesColor,
  beardsColor: errorPage.beardsColor,
  costColor: errorPage.costColor,
  shirtColor: errorPage.shirtColor,
})

const mapDispatchToProps = {
  toggleMenu,
  setOpaque,
  changeHatColor,
  changeGlassesColor,
  changeBeardsColor,
  changeCostColor,
  changeShirtColor,
}

export const ErrorPage = connect(mapStateToProps, mapDispatchToProps)(Self)
