import React from "react";
import { i18n } from "../../../i18n";

import * as S from "./DomainUnavailableModal.styles";

export const DomainUnavailableModal = ({ hideModal, domainStatus }) => {
  return (
    <S.Modal onClick={(e) => e.stopPropagation()}>
      <S.DomainUnavailableModalContainer>
        <S.Image />
        <S.Header>{i18n.t("domainUnavailableModal.header")}</S.Header>
        <S.DomainInfo>
          {!!domainStatus.filter((item) => !item.isAvailable).length &&
            i18n.t("domainUnavailableModal.message")}
          {domainStatus.map((item) => {
            return (
              !item.isAvailable && (
                <S.UnavailableDomainsList>
                  <S.UnavailableDomains>{item.domain}</S.UnavailableDomains>
                </S.UnavailableDomainsList>
              )
            );
          })}
        </S.DomainInfo>
        <S.CloseModalButton onClick={hideModal} />
      </S.DomainUnavailableModalContainer>
    </S.Modal>
  );
};
