import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';
import Cookies from 'js-cookie';

import { showModal } from '../common/Modal/Modal.actions';
import { LoginModal } from '../components/Modals/LoginModal';

const ProtectedRouteUnwrapped = ({
  component: Component,
  location,
  showModal,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (Cookies.get('token')) {
        return <Component {...rest} {...props} />;
      } else {
        setTimeout(() => {
          showModal(LoginModal, {
            goTo: props.location
              ? `${props.location.pathname}${props.location.search}` || '/'
              : '/',
          });
        }, 50);
        return (
          <Redirect
            to={{
              pathname: '/',
              state: {
                from: props.location,
              },
            }}
          />
        );
      }
    }}
  />
);

const mapDispatchToProps = {
  showModal,
};

export const ProtectedRoute = connect(
  undefined,
  mapDispatchToProps
)(ProtectedRouteUnwrapped);
