import * as Yup from 'yup';

export const initialValues = {
  personType: 0,
  firstname: '',
  lastname: '',
  phonenumber: '',
  email: '',
  country: 'AM',
  state: '',
  city: '',
  address1: '',
  postcode: '',
};

export const yupScheme = Yup.object().shape({
  personType: Yup.number(),
  firstname: Yup.string()
    .min(2, 'validationFields.firstnameMin')
    .max(72, 'validationFields.firstnameMax')
    .matches(/^([a-zA-Z0-9\s\-.]){1,40}$/, 'validationFields.invalidTyping')
    .required('validationFields.firstname'),
  lastname: Yup.string()
    .min(2, 'validationFields.lastnameMin')
    .max(72, 'validationFields.lastnameMax')
    .matches(/^([a-zA-Z0-9\s\-.]){1,40}$/, 'validationFields.invalidTyping')
    .required('validationFields.lastname'),
  phonenumber: Yup.string()
    .min(9, 'validationFields.phonenumberMin')
    .max(20, 'validationFields.phonenumberMax')
    .required('validationFields.phonenumber'),
  email: Yup.string()
    .email('validationFields.invalidTyping')
    .matches(/^[^']*$/, 'validationFields.invalidTyping')
    .required('validationFields.email'),
  country: Yup.string().required('validationFields.country'),
  state: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-`."]+$/, 'validationFields.invalidTyping')
    .required('validationFields.state'),
  city: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-`."]+$/, 'validationFields.invalidTyping')
    .required('validationFields.city'),
  address1: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-,."/]+$/, 'validationFields.invalidTyping')
    .required('validationFields.address'),
  postcode: Yup.string()
    .matches(/^([a-zA-Z0-9\s\-.]){1,40}$/, 'validationFields.invalidTyping')
    .required('validationFields.postcode'),
});
