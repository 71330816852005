import { connect } from 'react-redux'
import { DomainPrices as Self } from './DomainPrices'
import { getDomainPricesAmd, getDomainPricesUsd } from './DomainPrices.actions'

const mapStateToProps = ({
  app,
  getDomainPricesAmdReducer,
  getDomainPricesUsdReducer,
}) => ({
  auth: app.auth,
  domainPricesAmd: getDomainPricesAmdReducer.getDomainPricesAmdData,
  domainPricesUsd: getDomainPricesUsdReducer.getDomainPricesUsdData,
  loadingAmd: getDomainPricesAmdReducer.getDomainPricesAmdLoading,
  loadingUsd: getDomainPricesUsdReducer.getDomainPricesUsdLoading,
})

const mapDispatchToProps = {
  getDomainPricesAmd,
  getDomainPricesUsd,
}

export const DomainPrices = connect(mapStateToProps, mapDispatchToProps)(Self)
