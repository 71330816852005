import { ERROR_PAGE_TYPES } from './ErrorPage.types';

export const changeHatColor = (hatColor) => ({
  type: ERROR_PAGE_TYPES.CHANGE_HAT_COLOR,
  hatColor,
});

export const changeGlassesColor = (glassesColor) => ({
  type: ERROR_PAGE_TYPES.CHANGE_GLASSES_COLOR,
  glassesColor,
});
export const changeBeardsColor = (beardsColor) => ({
  type: ERROR_PAGE_TYPES.CHANGE_BEARDS_COLOR,
  beardsColor,
});
export const changeCostColor = (costColor) => ({
  type: ERROR_PAGE_TYPES.CHANGE_COST_COLOR,
  costColor,
});
export const changeShirtColor = (shirtColor) => ({
  type: ERROR_PAGE_TYPES.CHANGE_SHIRT_COLOR,
  shirtColor,
});
