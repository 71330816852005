import React, { useEffect, useState, useMemo, useRef } from 'react'
import * as S from './DomainPrices.styles'
import { TablePagination } from './components/TablePagination'
import { Loader } from '../../components/Loader'
import { i18n } from '../../i18n'
import { format } from './DomainPrices.helpers'

export const DomainPrices = ({
  domainPricesAmd,
  domainPricesUsd,
  getDomainPricesAmd,
  getDomainPricesUsd,
  loadingAmd,
  loadingUsd,
}) => {
  const [forceRender, setForceRender] = useState(Date.now())

  const tableRef = useRef(null)

  useEffect(() => {
    getDomainPricesAmd()
    getDomainPricesUsd()
  }, [])

  useEffect(() => {
    i18n.on('languageChanged', () => {
      setForceRender(Date.now())
    })
  }, [])

  const [filter, setFilter] = useState('')
  const [currency, setCurrency] = useState('AMD')
  const [currentPage, setCurrentPage] = useState(1)

  const domains =
    currency === 'AMD'
      ? domainPricesAmd?.data.pricing
      : domainPricesUsd?.data.pricing

  const domainsArray = domains && Object.keys(domains)

  const indexOfLastPost = currentPage * 50
  const indexOfFirstPost = indexOfLastPost - 50

  const filteredData =
    filter &&
    domainsArray.filter((res) =>
      res.toLowerCase().includes(filter.toLowerCase())
    )

  const currentPosts = useMemo(() => {
    if (filter) return filteredData.slice(indexOfFirstPost, indexOfLastPost)
    else if (domainsArray)
      return domainsArray.slice(indexOfFirstPost, indexOfLastPost)
  }, [
    domainPricesAmd,
    domainPricesUsd,
    indexOfFirstPost,
    indexOfLastPost,
    filter,
  ])

  const paginate = (pageNumber) => {
    tableRef.current.scrollIntoView({ behavior: 'smooth' })
    setCurrentPage(pageNumber)
  }

  return (
    <S.DomainPrices ref={tableRef}>
      {!loadingAmd && !loadingUsd ? (
        <S.DomainTableContainer>
          <S.TopWrapper>
            <S.SearchInput
              onChange={(e) => {
                setFilter(e.target.value)
                setCurrentPage(1)
              }}
              placeholder={i18n.t('pricelistPage.search')}
            />
            <S.RadioButtonsContainer>
              <S.Radio onClick={() => setCurrency('AMD')}>
                <S.Input
                  type="radio"
                  checked={currency === 'AMD'}
                  name="currency_changer"
                  className="radio"
                />
                <S.Label className="radio">AMD</S.Label>
              </S.Radio>
              <S.Radio onClick={() => setCurrency('USD')}>
                <S.Input
                  type="radio"
                  name="currency_changer"
                  className="radio"
                  checked={currency === 'USD'}
                />
                <S.Label className="radio">USD</S.Label>
              </S.Radio>
            </S.RadioButtonsContainer>
            <S.Info>* {i18n.t('pricelistPage.info')} </S.Info>
            <S.Info>
              {i18n.t('pricelistPage.info2')}{' '}
              <S.Link
                href="https://icannwiki.org/List_of_Internet_top-level_domains#F"
                target="_blank"
              >
                {'https://icannwiki.org/List_of_Internet_top-level_domains#F'}
              </S.Link>
            </S.Info>
          </S.TopWrapper>
          <S.BottomWrapper>
            <S.DomainsTable>
              <S.HeaderWrapper>
                <S.Header>{i18n.t('pricelistPage.zone')}</S.Header>
                <S.Header>{i18n.t('pricelistPage.period')}</S.Header>
                <S.Header>{i18n.t('pricelistPage.register')}</S.Header>
                <S.Header>{i18n.t('pricelistPage.renew')}</S.Header>
                <S.Header>{i18n.t('pricelistPage.transfer')}</S.Header>
                <S.Header>{i18n.t('pricelistPage.details')}</S.Header>
              </S.HeaderWrapper>
              {currentPosts?.map((key) => {
                const current = domains[key]

                return (
                  <S.TableRow key={key}>
                    <S.RowItem>.{key}</S.RowItem>
                    <S.RowItem>1 {i18n.t('pricelistPage.year')}</S.RowItem>
                    <S.RowItem>
                      {format(current?.register?.[1], currency)}
                    </S.RowItem>
                    <S.RowItem>
                      {format(current?.renew?.[1], currency)}
                    </S.RowItem>
                    <S.RowItem>
                      {format(current?.transfer?.[1], currency)}
                    </S.RowItem>
                    <S.RowItem>
                      <S.Link
                        href={`https://icannwiki.org/.${key}`}
                        target="_blank"
                      >{`https://icannwiki.org/.${key}`}</S.Link>
                    </S.RowItem>
                  </S.TableRow>
                )
              })}
              {!currentPosts?.length && <S.Error>Nothing found</S.Error>}
            </S.DomainsTable>
          </S.BottomWrapper>
          <TablePagination
            dataPerPage={50}
            totalResults={
              !!filter ? filteredData?.length : domainsArray?.length
            }
            paginate={paginate}
            current={currentPage}
          />
        </S.DomainTableContainer>
      ) : (
        <Loader color="#00C084" size={0.5} />
      )}
    </S.DomainPrices>
  )
}
