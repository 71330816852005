import { CONFIRM_EMAIL_PAGE_TYPES } from './ConfirmEmailPage.types';

const initialState = {
  data: {},
  loading: true,
  error: null,
};

function confirmEmailPage(state = initialState, action) {
  switch (action.type) {
    case CONFIRM_EMAIL_PAGE_TYPES.VERIFY_HASH_REQUEST:
      return {
        ...state,
        data: {},
        loading: true,
        error: null,
      };
    case CONFIRM_EMAIL_PAGE_TYPES.VERIFY_HASH_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null,
      };
    case CONFIRM_EMAIL_PAGE_TYPES.VERIFY_HASH_FAIL:
      return {
        ...state,
        data: {},
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export { confirmEmailPage };
