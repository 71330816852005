export const DOMAIN_PRICES_TYPES = {
  GET_DOMAIN_PRICES_AMD_REQUEST:
    'DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_AMD_REQUEST',
  GET_DOMAIN_PRICES_AMD_SUCCESS:
    'DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_AMD_SUCCESS',
  GET_DOMAIN_PRICES_AMD_FAIL: 'DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_AMD_FAIL',
  GET_DOMAIN_PRICES_USD_REQUEST:
    'DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_USD_REQUEST',
  GET_DOMAIN_PRICES_USD_SUCCESS:
    'DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_USD_SUCCESS',
  GET_DOMAIN_PRICES_USD_FAIL: 'DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_USD_FAIL',
}
