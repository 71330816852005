import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { LoginModal as Self } from './LoginModal'
import { login, resendEmail, clearError } from './LoginModal.actions'
import { showModal } from '../../../common/Modal/Modal.actions'

const mapStateToProps = ({ loginModal }) => ({
  loading: loginModal.loading,
  loginTab: loginModal.loginTab,
  error: loginModal.error,
  loginAsClientError: loginModal.loginAsClientError,
  resendMailOk: loginModal.resendMailOk,
})

const mapDispatchToProps = {
  login,
  showModal,
  resendEmail,
  clearError,
}

export const LoginModal = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Self)
)
