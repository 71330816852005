import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toggleMenu } from '../Header.actions';
import { NavBar as Self } from './NavBar';

const mapStateToProps = ({ app }) => ({
  auth: app.auth,
  userInfo: app.userInfo
});

const mapDispatchToProps = {
  toggleMenu,
};

export const NavBar = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
