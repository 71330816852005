import styled from 'styled-components'

import openButton from '../../images/open.svg'
import closeButton from '../../images/close.svg'

export const QuestionContainer = styled.div`
  display: flex;
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16), 0px 0px 2px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  margin-top: 20px;
  cursor: pointer;
  flex-direction: column;
  box-sizing: border-box;
  &:first-child {
    margin-top: 0px;
  }
  padding: 20px;
  @media (min-width: 1280px) {
    width: 1100px;
  }
`

export const QuestionText = styled.div`
  font-size: 12px;
  color: #484857;
  font-weight: 500;
  width: calc(100% - 9px);

  @media (min-width: 768px) {
    font-size: 18px;
  }

  @media (min-width: 1280px) {
    font-size: 24px;
  }
`

export const Answer = styled.div`
  font-size: 12px;
  color: #484848;
  padding-top: 20px;
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  padding-right: 18px;
  font-weight: 300;

  ul li {
    list-style: disc;
  }

  li {
    margin-top: 10px;
  }

  ol {
    counter-reset: item;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    padding-right: 50px;
  }

  @media (min-width: 1280px) {
    font-size: 18px;
  }
`

export const QuestionPart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ToggleButton = styled.div`
  width: 9px;
  height: 6px;
  background: url(${(p) => (p.isOpen ? closeButton : openButton)});
  background-size: cover;

  @media (min-width: 768px) {
    width: 13px;
    height: 8px;
  }
`
