import qs from 'qs'
import Cookies from 'js-cookie'
import { omit } from 'lodash'

export default class FetchApi {
  static getUrl() {
    return process.env.REACT_APP_API_URL.trim()
  }

  static getToken() {
    try {
      const token = Cookies.get('token')
      return token
    } catch (e) {
      return false
    }
  }

  static setToken(
    token,
    email,
    fullname,
    userId,
    address,
    currency,
    creditRecharge,
    legalStatus,
    disableCode
  ) {
    try {
      Cookies.set('token', token)
      Cookies.set('email', email)
      Cookies.set('fullname', fullname)
      Cookies.set('userId', userId)
      Cookies.set('address', address)
      Cookies.set('currency', currency)
      Cookies.set('creditRecharge', creditRecharge)
      Cookies.set('legalStatus', legalStatus)
      Cookies.set('disableCode', disableCode)
      return true
    } catch (e) {
      return false
    }
  }

  static clearStorageItems() {
    localStorage.removeItem('twk_token')
    localStorage.removeItem('twk_581ad5929ca1830bdca6c41d')
    localStorage.removeItem('_hjid')
    localStorage.removeItem('cart')
  }

  static removeToken() {
    try {
      Object.keys(Cookies.get()).forEach((cookieName) => {
        if (cookieName !== 'lng' && cookieName !== 'allowCookies') {
          Cookies.remove(cookieName)
        }
      })
      this.clearStorageItems()
      return true
    } catch (e) {
      return false
    }
  }

  static async post(path, data, options = {}) {
    return await FetchApi.request(path, 'POST', data, options)
  }

  static async get(path, data, options = {}) {
    return await FetchApi.request(path, 'GET', data, options)
  }

  static async put(path, data, options = {}) {
    return await FetchApi.request(path, 'PUT', data, options)
  }

  static async patch(path, data, options = {}) {
    return await FetchApi.request(path, 'PATCH', data, options)
  }

  static async delete(path, data, options = {}) {
    return await FetchApi.request(path, 'DELETE', data, options)
  }

  static async request(path, method, data, options = {}) {
    let headers = new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    })
    if (!options.noToken) {
      const token = await FetchApi.getToken()
      if (token) headers.append('Authorization', `Bearer ${token}`)
    }

    const myInit = {
      method,
      headers,
    }

    const filteredData = omit(data, '_id')

    if (method === 'GET') {
      path = `${path}${qs.stringify(filteredData, {
        addQueryPrefix: true,
        encode: true,
      })}`
    } else if (method === 'DELETE') {
      const ids = {
        ids: filteredData,
      }
      myInit.body = JSON.stringify(ids)
    } else {
      myInit.body = JSON.stringify(filteredData)
    }

    return await fetch(`${FetchApi.getUrl()}/${path}`, myInit)
      .then((res) => {
        return res
          .json()
          .then((json) => {
            if (res.status === 401) {
              FetchApi.removeToken()
              return window.location.replace('/')
            }
            if (res.status >= 200 && res.status < 300) {
              return Promise.resolve({ data: json })
            } else {
              return Promise.reject({ data: json })
            }
          })
          .catch((e) => {
            return Promise.reject(e)
          })
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  }

  static async upload(data, options = {}) {
    let headers = new Headers({})

    if (!options.noToken) {
      const token = FetchApi.getToken()
      if (token) headers.append('Authorization', `Bearer ${token}`)
    }

    let formData = new FormData()
    for (let file of data) {
      formData.append('files', file)
    }

    const myInit = {
      method: 'POST',
      body: formData,
      headers,
    }

    return await fetch(`${FetchApi.getUrl()}/tickets/uploadFiles`, myInit)
      .then((res) => {
        return res
          .json()
          .then((json) => {
            if (res.status >= 200 && res.status < 300) {
              return Promise.resolve({ data: json })
            } else {
              return Promise.reject({ data: json })
            }
          })
          .catch((e) => {
            return Promise.reject(e)
          })
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  }
}
