import { CONTACT_OWNER_TYPES } from './ContactOwnerModal.types';
import { MODAL_TYPES } from '../../../common/Modal/Modal.types';
import { REGISTRATION_TYPES } from '../../../pages/RegistrationPage/RegistrationPage.types';

const initialState = {
  loading: false,
  error: null,
  data: null,
};

export const contactOwnerModal = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_OWNER_TYPES.CONTACT_OWNER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CONTACT_OWNER_TYPES.CONTACT_OWNER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CONTACT_OWNER_TYPES.CONTACT_OWNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case MODAL_TYPES.HIDE_MODAL:
      return initialState;

    case REGISTRATION_TYPES.CLEAN_REGISTER_ERROR:
      return initialState;
    default:
      return state;
  }
};
