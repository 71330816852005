import React from 'react';

import * as S from './ContactOwnerSuccessModal.styles';
import { i18n } from '../../../i18n';

export const ContactOwnerSuccessModal = ({ hideModal }) => (
  <S.Modal onClick={(e) => e.stopPropagation()}>
    <S.ContactOwnerSuccessModalContainer>
      <S.Image />
      <S.Title>{i18n.t('contactOwnerSuccesModal.title')}</S.Title>
      <S.Header>{i18n.t('contactOwnerSuccesModal.header')}</S.Header>
      <S.SubHeader>{i18n.t('contactOwnerSuccesModal.subHeader')}</S.SubHeader>
      <S.CloseModalButton onClick={hideModal} />
    </S.ContactOwnerSuccessModalContainer>
  </S.Modal>
);
