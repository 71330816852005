import React from 'react'

import * as S from './DomainsTable.styles'
import { i18n } from '../../../../i18n'
import { headers } from './DomainsTable.constants'
import { FilterPopup } from '../FilterPopup'
import { Sort } from '../SortPopup'
import { MobileSortPopup } from '../MobileSortPopup'
import { SettingsPopup } from './components/SettingsPopup'
import { Loader } from '../../../../components/Loader'
import { Pagination } from '../Pagination'

const DomainsTable = ({
  getDomains,
  toggleFilterPopup,
  showSortPopup,
  data,
  total,
  statuses,
  showSettingsPopup,
  loading,
  filter,
  offsets,
  sortField,
  sortOrder,
  sortBy,
}) => {
  if (loading) {
    return (
      <S.EmptyStateContainer>
        <Loader color={'#00C084'} size={0.5} />
      </S.EmptyStateContainer>
    )
  }
  if (!data.length && !filter) {
    return <S.EmptyStateContainer>No records found</S.EmptyStateContainer>
  }
  return (
    <>
      <S.CartBlock>
        <S.Headers>
          {headers.map((header) => (
            <S.Header key={header.id}>
              <S.Title isMarginNeed={header.isMarginNeed}>
                {i18n.t(header.title)}
              </S.Title>
              <Sort
                cb={getDomains}
                sortBy={header.sortBy}
                sortType={header.sortType}
                sortNumber={header.sortNumber}
                sortOrder={sortField === header.sortBy && sortOrder}
                offset={offsets.domains}
                sortField={sortField}
              />
            </S.Header>
          ))}
          <S.SortMin
            id="desktopSort"
            onClick={() => showSortPopup(headers.sortNumber, headers)}
          />
          <MobileSortPopup
            fields={headers}
            cb={getDomains}
            sortBy={sortField}
            sortOrder={sortOrder}
            offset={offsets.domains}
          />
          <FilterPopup options={statuses} filterType="domains" />
          <S.Filter id="desktopFilter" onClick={toggleFilterPopup} />
        </S.Headers>
        {data.map((item) => (
          <S.CartContent key={item.id}>
            {/* <S.CheckmarkFilled />
            <S.CheckmarkNotFilled />  */}
            <S.DomainData>
              <S.Domain>{item.domainname}</S.Domain>

              <S.DataWrapper>
                <S.RegDate isMarginNeed={true}>{item.regdate}</S.RegDate>
                <S.DomainDates>
                  {item.status !== 'Cancelled' &&
                  item.expirydate !== '0000-00-00'
                    ? item.expirydate
                    : ''}
                </S.DomainDates>
              </S.DataWrapper>
              <S.Status>
                {i18n.t(`backend.hostingStatuses.${item.status}`)}
              </S.Status>
            </S.DomainData>
            <SettingsPopup
              popupId={item.id}
              id={item.id}
              domain={item}
              shouldGetNameservers={true}
            />
            {!['Cancelled', 'Expired', 'Pending', 'Transferred Away'].includes(
              item.status
            ) && (
              <S.CartSettingsIcon onClick={() => showSettingsPopup(item.id)} />
            )}
          </S.CartContent>
        ))}
      </S.CartBlock>
      <Pagination
        goToPage={(offset) => getDomains(offset, sortOrder, sortField)}
        totalResults={total}
        currentOffset={offsets.domains}
      />
      {/* <S.BottomButtonsContainer>
        <S.ActionsButton>
          {i18n.t('clientAreaPage.actionButton')}
          <S.ArrowIcon />
        </S.ActionsButton>
        <S.AddServiceButton>
          {i18n.t('clientAreaPage.addServiceButton')}
        </S.AddServiceButton>
      </S.BottomButtonsContainer> */}
    </>
  )
}

export { DomainsTable }
