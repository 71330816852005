import React, { useRef } from 'react';

import * as S from './FilterPopup.styles';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';
import { i18n } from '../../../../i18n'

export const FilterPopup = ({
  showFilterPopup,
  isShown,
  options,
  filterType,
  setFilter,
  filters,
}) => {
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, () => showFilterPopup(false));
  if (!isShown) {
    return null;
  }


  return (
    <S.FilterPopup ref={containerRef}>
      {options
        .sort((a, b) => a.localeCompare(b))
        .map((item) => (
            <S.Status
              onClick={() => {
                setFilter(filterType, item);
                showFilterPopup(false);
              }}
              isActive={filters[filterType] === item}
            >
              {i18n.t(`backend.filterItems.${item}`)}
            </S.Status>
        ))}
        <S.Status onClick={() => {setFilter(filterType); showFilterPopup(false)}}>{i18n.t('filterPopup.showAll')}</S.Status>
    </S.FilterPopup>
  );
};
