import React from 'react';
import * as S from './Checkbox.styles';

export const Checkbox = ({
  name,
  value,
  onChange,
  onBlur,
  title
}) => (
  <S.CheckboxContainer name={name} onClick={onChange} onBlur={onBlur} isChecked={value} title={title}>
    <S.Checkbox isChecked={value} title={title}/>
  </S.CheckboxContainer>
);
