import React, { useEffect } from 'react'
import qs from 'qs'
import { decode } from 'html-entities'

import * as S from './TicketsTable.styles'
import { i18n } from '../../../../i18n'
import { headers } from './TicketsTable.constants'
import { FilterPopup } from '../FilterPopup'
import { Sort } from '../SortPopup'
import { SettingsPopup } from './components/SettingsPopup'
import { MobileSortPopup } from '../MobileSortPopup'
import { Loader } from '../../../../components/Loader'
import { Pagination } from '../Pagination'
import { ViewTicketModal } from '../../../../components/Modals/ViewTicketModal'

const TicketsTable = ({
  toggleFilterPopup,
  showSortPopup,
  data,
  total,
  statuses,
  showSettingsPopup,
  loading,
  filter,
  offsets,
  getTickets,
  history,
  showModal,
  sortField,
  sortOrder,
  sortType,
}) => {
  useEffect(() => {
    if (data.length) {
      const params = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
      })

      if (params.tid) {
        const match = data.find((item) => item.tid === params.tid)
        showModal(ViewTicketModal, {
          ticketId: match.id,
          ticketTid: params.tid,
        })
      }
    }
  }, [history.location.search, data])

  const departments = {
    1: 'ticketsTable.departments.1',
    2: 'ticketsTable.departments.2',
    3: 'ticketsTable.departments.3',
  }

  if (loading) {
    return (
      <S.EmptyStateContainer>
        <Loader color={'#00C084'} size={0.5} />
      </S.EmptyStateContainer>
    )
  }
  if (!data.length && !filter) {
    return <S.EmptyStateContainer>No records found</S.EmptyStateContainer>
  }

  return (
    <>
      <S.CartBlock>
        <S.Headers>
          {headers.map((header) => (
            <S.Header key={header.id} isMarginNeed={header.isMarginNeed}>
              <S.Title>{i18n.t(header.title)}</S.Title>
              <Sort
                cb={getTickets}
                sortType={header.sortType}
                sortNumber={header.sortNumber}
                sortBy={header.sortBy}
                sortOrder={sortField === header.sortBy && sortOrder}
                offset={offsets.tickets}
                sortField={sortField}
              />
            </S.Header>
          ))}

          <S.SortMin
            id="desktopSort"
            onClick={() => showSortPopup(headers.sortNumber, headers)}
          />

          <MobileSortPopup
            cb={getTickets}
            fields={headers}
            sortBy={sortField}
            sortOrder={sortOrder}
            offset={offsets.tickets}
          />
          <FilterPopup options={statuses} filterType="tickets" />
          <S.Filter id="desktopFilter" onClick={toggleFilterPopup} />
        </S.Headers>
        {data.map((item) => (
          <S.CartContent
            key={item.id}
            isOperator={item.requestor_type === 'Operator'}
          >
            <S.TicketsData>
              <S.IdWrapper>
                <S.TicketId>{item.tid}</S.TicketId>
                <S.DepId>{i18n.t(departments[item.deptid])}</S.DepId>
              </S.IdWrapper>
              <S.Subject>{decode(item.subject)}</S.Subject>
              <S.DataWrapper>
                <S.LastReply>
                  <S.LastReplyDate>
                    {item.lastreply.split(' ')[0]}
                  </S.LastReplyDate>
                  <S.LastReplyTime>
                    {item.lastreply.split(' ')[1]}
                  </S.LastReplyTime>
                </S.LastReply>
                <S.Status>
                  {i18n.t(`backend.ticketStatuses.${item.status}`)}
                </S.Status>
              </S.DataWrapper>
            </S.TicketsData>
            <SettingsPopup
              popupId={item.id}
              ticketId={item.id}
              ticketTid={item.tid}
            />
            <S.CartSettingsIcon onClick={() => showSettingsPopup(item.id)} />
          </S.CartContent>
        ))}
      </S.CartBlock>
      <Pagination
        goToPage={(offset) => getTickets(offset, sortOrder, sortField)}
        totalResults={total}
        currentOffset={offsets.tickets}
      />
    </>
  )
}

export { TicketsTable }
