export const NAME_SERVER_MODAL = {
  SET_NAMESERVERS_REQUEST: 'NAME_SERVER_MODAL.SET_NAMESERVERS_REQUEST',
  SET_NAMESERVERS_SUCCESS: 'NAME_SERVER_MODAL.SET_NAMESERVERS_SUCCESS',
  SET_NAMESERVERS_FAIL: 'NAME_SERVER_MODAL.SET_NAMESERVERS_FAIL',
  GET_NAMESERVERS_REQUEST: 'NAME_SERVER_MODAL.GET_NAMESERVERS_REQUEST',
  GET_NAMESERVERS_SUCCESS: 'NAME_SERVER_MODAL.GET_NAMESERVERS_SUCCESS',
  GET_NAMESERVERS_FAIL: 'NAME_SERVER_MODAL.GET_NAMESERVERS_FAIL',
  GET_NAMESERVERS_CODE_REQUEST:
    'NAME_SERVER_MODAL.GET_NAMESERVERS_CODE_REQUEST',
  GET_NAMESERVERS_CODE_SUCCESS:
    'NAME_SERVER_MODAL.GET_NAMESERVERS_CODE_SUCCESS',
  GET_NAMESERVERS_CODE_FAIL: 'NAME_SERVER_MODAL.GET_NAMESERVERS_CODE_FAIL',
}
