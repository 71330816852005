import { SUPPORT_TYPES } from './SupportPage.types';
import { SUPPORT_TABS } from './SupportPage.constants';

const initialState = {
  tab: SUPPORT_TABS.FREQUENT,
  expandedQuestion: null,
  results: [],
  searchResultsShouldShow: false,
};

function supportPage(state = initialState, action) {
  switch (action.type) {
    case SUPPORT_TYPES.CHANGE_TAB:
      return {
        ...state,
        tab: action.tab,
      };
    case SUPPORT_TYPES.EXPAND_QUESTION:
      return {
        ...state,
        expandedQuestion:
          state.expandedQuestion === action.id ? null : action.id,
      };
    case SUPPORT_TYPES.SET_RESULTS:
      return {
        ...state,
        results: action.results,
      };
    case SUPPORT_TYPES.SET_SEARCH_RESULTS_SHOULD_SHOW:
      return {
        ...state,
        searchResultsShouldShow: action.status,
      };
    default:
      return state;
  }
}

export { supportPage };
