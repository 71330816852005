import { MANAGE_FREE_DNS_MODAL } from './ManageFreeDNSModal.types'
import FetchApi from '../../../helpers/FetchApi'

export const getDNSZones = (serviceid) => async (dispatch) => {
  try {
    dispatch({
      type: MANAGE_FREE_DNS_MODAL.GET_ZONES_REQUEST,
    })
    const req = await FetchApi.post(`products/getDnsZones?id=${serviceid}`)

    dispatch({
      type: MANAGE_FREE_DNS_MODAL.GET_ZONES_SUCCESS,
      data: req?.data?.data,
    })
  } catch (e) {
    dispatch({
      type: MANAGE_FREE_DNS_MODAL.GET_ZONES_FAIL,
    })
  }
}

export const setDNSZones =
  (serviceid, values, onSuccess, onError) => async (dispatch, getState) => {
    try {
      dispatch({
        type: MANAGE_FREE_DNS_MODAL.SET_ZONES_REQUEST,
      })
      await FetchApi.post(`products/setDnsZones?id=${serviceid}`, {
        zoneData: { ...values },
      })
      dispatch({
        type: MANAGE_FREE_DNS_MODAL.SET_ZONES_SUCCESS,
      })
      if (onSuccess) {
        onSuccess()
      }
    } catch (e) {
      dispatch({
        type: MANAGE_FREE_DNS_MODAL.SET_ZONES_FAIL,
        error: e.data ? e.data.error || e.data.validation_error : e.message,
      })
      if (onError) {
        onError(e.data ? e.data.error || e.data.validation_error : e.message)
      }
    }
  }
