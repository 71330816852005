import React, { useRef, useEffect } from 'react';

import * as S from './VpsModal.styles';
import { i18n } from '../../../i18n';
import { Loader } from '../../../components/Loader';

export const VpsModal = ({
  hideModal,
  auth,
  loading,
  error,
  vpsInfo,
  getVpsInfo,
  serviceid,
  showPass,
  togglePass,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    getVpsInfo(serviceid);
  }, []);

  return (
    <S.Modal onClick={(e) => e.stopPropagation()} ref={modalRef}>
      <S.VpsModalContainer>
        {loading ? (
          <Loader color="#00C084" size={0.5} />
        ) : (
          <>
            <S.ModalTitle>{i18n.t('vpsModal.title')}</S.ModalTitle>
            <S.VpsInfo>
              <S.InfoItem>
                <S.Label>{i18n.t('vpsModal.ip')}:</S.Label>
                <S.VpsIp>{vpsInfo.ip}</S.VpsIp>
              </S.InfoItem>
              <S.InfoItem>
                <S.Label>{i18n.t('vpsModal.username')}:</S.Label>
                <S.Username>{vpsInfo.username}</S.Username>
              </S.InfoItem>
              <S.PassItem>
                <S.Label>{i18n.t('vpsModal.password')}:</S.Label>
                <S.Password>
                  {showPass ? vpsInfo.password : '**********'}
                </S.Password>
              </S.PassItem>
              <S.PassEye onClick={() => togglePass()} />
            </S.VpsInfo>
            <S.CloseModalButton onClick={hideModal} />
          </>
        )}
      </S.VpsModalContainer>
    </S.Modal>
  );
};
