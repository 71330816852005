import { CLIENT_AREA_TYPES } from './ClientAreaPage.types'
import { DEFAULT_LIMIT } from './ClientAreaPage.constants'
import FetchApi from '../../helpers/FetchApi'
import { transformContacts } from './helper'

export const changeTab = (tab) => ({
  type: CLIENT_AREA_TYPES.CHANGE_TAB,
  tab,
})

export const showFilterPopup = (status) => ({
  type: CLIENT_AREA_TYPES.SHOW_FILTER_POPUP,
  status,
})

export const toggleFilterPopup = () => ({
  type: CLIENT_AREA_TYPES.TOGGLE_FILTER_POPUP,
})

export const showSettingsPopup = (popupId) => ({
  type: CLIENT_AREA_TYPES.SHOW_SETTINGS_POPUP,
  popupId,
})
export const toggleSettingsPopup = () => ({
  type: CLIENT_AREA_TYPES.TOGGLE_SETTINGS_POPUP,
})

export const showSortPopup = (sortNumber, fields) => ({
  type: CLIENT_AREA_TYPES.SHOW_SORT_POPUP,
  sortNumber,
  fields,
})

export const toggleSortPopup = () => ({
  type: CLIENT_AREA_TYPES.TOGGLE_SORT_POPUP,
})

export const setInitialSearchParams = () => ({
  type: CLIENT_AREA_TYPES.SET_INITIAL_PARAMS,
})

export const getDomains =
  (offset, sortOrder, sortBy) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CLIENT_AREA_TYPES.GET_DOMAINS_REQUEST,
      })
      const { domains } = getState().clientAreaPage.filters

      const obj = {
        offset,
        limit: DEFAULT_LIMIT,
      }

      if (sortOrder) obj.sortOrder = sortOrder
      if (sortBy) obj.sortBy = sortBy
      if (domains) obj.status = domains

      const req = await FetchApi.get('domains/all', obj)
      dispatch({
        type: CLIENT_AREA_TYPES.GET_DOMAINS_SUCCESS,
        domains: req.data.data,
        data: req.data.data,
        domainsStatuses: req.data.domainStatuses,
        total: req.data.totalresults,
        offset,
        sortOrder,
        sortBy,
      })
    } catch (e) {
      dispatch({
        type: CLIENT_AREA_TYPES.GET_DOMAINS_FAIL,
        error: e,
      })
    }
  }

export const getServices =
  (offset, sortOrder, sortBy) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CLIENT_AREA_TYPES.GET_SERVICES_REQUEST,
      })

      const { services } = getState().clientAreaPage.filters
      const obj = {
        offset,
        limit: DEFAULT_LIMIT,
      }

      if (sortOrder) obj.sortOrder = sortOrder
      if (sortBy) obj.sortBy = sortBy
      if (services) obj.status = services
      const req = await FetchApi.get('products/all', obj)
      dispatch({
        type: CLIENT_AREA_TYPES.GET_SERVICES_SUCCESS,
        services: req.data.data,
        data: req.data.data,
        servicesStatuses: req.data.hostingStatuses,
        total: req.data.totalresults,
        offset,
        sortOrder,
        sortBy,
      })
    } catch (e) {
      dispatch({
        type: CLIENT_AREA_TYPES.GET_SERVICES_FAIL,
        error: e,
      })
    }
  }

export const getTickets =
  (offset, sortOrder, sortBy) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CLIENT_AREA_TYPES.GET_TICKETS_REQUEST,
      })

      const { tickets } = getState().clientAreaPage.filters
      const obj = {
        offset,
        limit: DEFAULT_LIMIT,
      }
      if (sortOrder) obj.sortOrder = sortOrder
      if (sortBy) obj.sortBy = sortBy
      if (tickets) obj.status = tickets

      const req = await FetchApi.get('tickets/all', obj)
      dispatch({
        type: CLIENT_AREA_TYPES.GET_TICKETS_SUCCESS,
        tickets: req.data.data,
        data: req.data.data,
        ticketsStatuses: req.data.ticketStatuses,
        total: req.data.totalresults,
        offset,
        sortOrder,
        sortBy,
      })
    } catch (e) {
      dispatch({
        type: CLIENT_AREA_TYPES.GET_TICKETS_FAIL,
        error: e,
      })
    }
  }

export const getInvoices =
  (offset, sortOrder, sortBy) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CLIENT_AREA_TYPES.GET_INVOICES_REQUEST,
      })
      const { invoices } = getState().clientAreaPage.filters

      const obj = {
        offset,
        limit: DEFAULT_LIMIT,
      }

      if (sortOrder) obj.sortOrder = sortOrder
      if (sortBy) obj.sortBy = sortBy
      if (invoices) obj.status = invoices

      const req = await FetchApi.get('invoices/all', obj)

      dispatch({
        type: CLIENT_AREA_TYPES.GET_INVOICES_SUCCESS,
        invoices: req.data.data,
        data: req.data.data,
        invoicesStatuses: req.data.invoiceStatuses,
        total: req.data.totalresults,
        offset,
        sortOrder,
        sortBy,
      })
    } catch (e) {
      dispatch({
        type: CLIENT_AREA_TYPES.GET_INVOICES_FAIL,
        error: e,
      })
    }
  }

export const getDomainContacts = (domainid) => async (dispatch) => {
  try {
    dispatch({
      type: CLIENT_AREA_TYPES.GET_DOMAIN_CONTACTS_REQUEST,
    })
    const req = await FetchApi.get(
      `domains/domaincontacts?domainid=${domainid}`
    )
    const data = req.data.data
    const transformedContacts = transformContacts(Object.keys(data))

    dispatch({
      type: CLIENT_AREA_TYPES.GET_DOMAIN_CONTACTS_SUCCESS,
      data: data,
      transformedContacts,
    })
  } catch (e) {
    dispatch({
      type: CLIENT_AREA_TYPES.GET_DOMAIN_CONTACTS_FAIL,
    })
  }
}

export const setDomainContacts =
  (domainid, values, transformedContacts, onSuccess, tld = 'am') =>
  async (dispatch) => {
    const { administrative, registrant, technical } = transformedContacts
    try {
      dispatch({
        type: CLIENT_AREA_TYPES.SET_DOMAIN_CONTACTS_REQUEST,
      })
      await FetchApi.post('domains/setdomaincontacts', {
        data: ['am', 'հայ'].includes(tld)
          ? {
              [administrative]: values.administrative,
              [technical]: values.technical,
              // [registrant]: values.owner,
              billing: values.billing,
            }
          : {
              [administrative]: values.administrative,
              [registrant]: values.billing,
              [technical]: values.technical,
            },
        domainid,
      })
      dispatch({
        type: CLIENT_AREA_TYPES.SET_DOMAIN_CONTACTS_SUCCESS,
      })
      if (onSuccess) {
        onSuccess()
      }
    } catch (e) {
      dispatch({
        type: CLIENT_AREA_TYPES.SET_DOMAIN_CONTACTS_FAIL,
      })
    }
  }

export const changeDomainContactModalTab = (tabName) => ({
  type: CLIENT_AREA_TYPES.CHANGE_DOMAIN_CONTACT_MODAL_TAB,
  tabName,
})

export const setFilter = (filterType, filterValue) => (dispatch, getState) => {
  dispatch({
    type: CLIENT_AREA_TYPES.SET_FILTER,
    filterType,
    filterValue,
  })

  switch (filterType) {
    case 'domains':
      return dispatch(getDomains(getState().clientAreaPage.offsets.domains))
    case 'services':
      return dispatch(getServices(getState().clientAreaPage.offsets.services))
    case 'tickets':
      return dispatch(getTickets(getState().clientAreaPage.offsets.tickets))
    case 'invoices':
      return dispatch(getInvoices(getState().clientAreaPage.offsets.invoices))
    default:
      return () => undefined
  }
}

export const changeIdProtection =
  (domainId, protectId, onSuccess) => async (dispatch) => {
    try {
      dispatch({
        type: CLIENT_AREA_TYPES.CHANGE_ID_PROTECT_REQUEST,
      })
      const data = {
        domainid: domainId,
        idprotect: Number(protectId),
      }
      await FetchApi.post('domains/updateincognito', data)
      dispatch({
        type: CLIENT_AREA_TYPES.CHANGE_ID_PROTECT_SUCCESS,
        domainId,
        protectId,
      })
      if (onSuccess) {
        onSuccess()
      }
    } catch (e) {
      dispatch({
        type: CLIENT_AREA_TYPES.CHANGE_ID_PROTECT_FAIL,
        error: e.data ? e.data.error || e.data.validation_error : e.message,
      })
    }
  }

export const getServiceDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CLIENT_AREA_TYPES.GET_SERVICE_DETAILS_REQUEST,
    })

    const response = await FetchApi.get(`products/getService?id=${id}`)

    const data = response.data.data

    dispatch({
      type: CLIENT_AREA_TYPES.GET_SERVICE_DETAILS_SUCCESS,
      data: data,
    })
  } catch (e) {
    dispatch({
      type: CLIENT_AREA_TYPES.GET_SERVICE_DETAILS_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    })
  }
}

export const setServiceDetails = (data) => ({
  type: CLIENT_AREA_TYPES.SET_SERVICE_DETAILS,
  data,
})
