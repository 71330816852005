import React, {
  useEffect,
  useCallback,
  useState,
  createRef,
  useRef,
} from 'react'
import { range } from 'lodash'
import qs from 'qs'
import { Helmet } from 'react-helmet'

import { LayoutCommon } from '../../layouts/LayoutCommon'
import * as S from './DomainConfigurationPage.styles'
import { config } from './DomainConfigurationPage.config'
import { HostingModal } from '../../components/Modals/HostingModal'
import { NameServerModal } from '../../components/Modals/NameServerModal'
import { i18n } from '../../i18n'

import { changeUrl } from '../../hooks/changeUrl'

import { Formik, Form } from 'formik'
import { initialValues, yupScheme } from './DomainConfiguration.helpers'
import slugify from 'react-slugify'

const DomainConfigurationPage = ({
  history,
  showModal,
  cart,
  updateCart,
  removeFromCart,
  auth,
  error,
  register,
  cleanRegisterError,
}) => {
  const [refs, setRefs] = useState([])

  const goToPayment = useCallback(() => {
    history.push(changeUrl('/order-details'))
  }, [history])

  const formRef = useRef(null)

  const handleEnter = useCallback((e, submitForm) => {
    if (e.key === 'Enter') {
      submitForm()
    }
  }, [])

  const handleSubmit = () => {
    if (formRef.current && formRef.current.submitForm) {
      formRef.current.submitForm()
    } else {
      goToPayment()
    }
  }

  const domains = cart
    .sort((a, b) => (a.order > b.order ? -1 : 1))
    .filter((item) => item.type === 'domain')
  const ruTld = cart.find(
    (item) => item.type === 'domain' && item.tld === '.ru'
  )
  useEffect(() => {
    const initialValidations = {}
    const ruDomains = cart.filter(
      (item) => item.type === 'domain' && item.tld === '.ru'
    )
  }, [auth])
  useEffect(() => {
    setRefs((elRefs) =>
      Array(domains.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    )
  }, [])
  useEffect(() => {
    const params = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
      encode: true,
    })
    const matchIndex = domains.findIndex((item) => item._id === params.id)
    setTimeout(() => {
      if (refs[matchIndex] && refs[matchIndex].current) {
        window.scrollTo(
          0,
          refs[matchIndex].current.getBoundingClientRect().top - 100
        )
        history.replace('/domain-settings')
      }
    })
  }, [history.location.search, domains, refs])

  return (
    <LayoutCommon>
      <Helmet>
        <title>{i18n.t('headers.domainConfigurationPage')}</title>
        <meta
          name="description"
          content={i18n.t('headersDescriptions.domainConfigurationPage')}
        />
        <meta
          name="og:title"
          content={i18n.t('headers.domainConfigurationPage')}
        />
        <meta
          name="og:description"
          content={i18n.t('headersDescriptions.domainConfigurationPage')}
        />
        <meta
          name="og:image"
          content="https://internet.am/images/fb_logo.png"
        />
      </Helmet>
      <S.PageBody>
        <S.OrderNowBodyContainer>
          <S.OrderNowTitle>{i18n.t('domainSettings.title')}</S.OrderNowTitle>
          <S.OrderNowHeader>{i18n.t('domainSettings.header')}</S.OrderNowHeader>
          <S.MainBlock>
            {domains.map((product, i) => {
              const configProduct = config.find(
                (item) => item.tld === product.tld
              )
              let configYears = configProduct ? configProduct.years || 1 : 1
              const years = range(1, configYears + 1)

              return (
                <S.OrderNowContainer key={product._id} ref={refs[i]}>
                  <S.HostingName>
                    {product.domain}
                    {product.tld}
                  </S.HostingName>
                  {configProduct && configProduct.canBeIncognito && (
                    <S.OtherInfoWrapper>
                      <S.FormItem>
                        <S.Wrapper>
                          <S.Label>{i18n.t('domainSettings.period')}</S.Label>
                          <S.Select
                            onChange={(e) =>
                              updateCart(product, {
                                years: e.target.value,
                              })
                            }
                          >
                            {years.map((item) => (
                              <S.Option
                                value={item}
                                selected={item === +product.years}
                              >
                                {item} {i18n.t('domainSettings.years')}
                              </S.Option>
                            ))}
                          </S.Select>
                        </S.Wrapper>
                      </S.FormItem>
                      <S.FormItem>
                        <S.IncognitoWrapper
                          onClick={() =>
                            updateCart(product, {
                              incognito: !product.incognito,
                            })
                          }
                        >
                          {product.incognito ? (
                            <S.CheckmarkFilled />
                          ) : (
                            <S.CheckmarkNotFilled />
                          )}
                          <S.IncognitoHeader>
                            {i18n.t('domainSettings.incognito')}
                          </S.IncognitoHeader>
                        </S.IncognitoWrapper>
                      </S.FormItem>
                    </S.OtherInfoWrapper>
                  )}
                  {configProduct?.additionalFields && (
                    <S.RuWrapper>
                      <S.FormContainer>
                        <Formik
                          isInitialValid
                          innerRef={formRef}
                          initialValues={initialValues(
                            auth.legalStatus === 'legal' ? 1 : 0
                          )}
                          validationSchema={yupScheme}
                          onSubmit={async (values, { setSubmitting }) => {
                            const formattedValues = {
                              passportNumbers: values.passportNumbers,
                              taxPayerNumber: values.taxPayerNumber,
                              dateOfBirth: values.dateOfBirth,
                              phoneNumber: values.phoneNumber,
                              passportSeries: values.passportSeries
                                ? values.passportSeries
                                : 'N/A',
                              companyNameLat: values.companyNameLat,
                              postalAddressCyr: values.postalAddressCyr,
                              legalAddressCyr: values.legalAddressCyr,
                              passportIssuer: values.passportIssuer,
                              passportDate: values.passportDate,
                              personType: values.personType,
                            }

                            updateCart(product, {
                              additionalFields: formattedValues,
                            })
                            setSubmitting(false)
                            goToPayment()
                          }}
                        >
                          {({
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            values,
                            setFieldValue,
                            submitForm,
                            resetForm,
                            isValid,
                          }) => {
                            return (
                              <Form onKeyPress={() => handleEnter(submitForm)}>
                                {!auth.legalStatus && (
                                  <S.ButtonsContainer>
                                    <S.SwitchButton
                                      type="button"
                                      isChecked={values.personType === 0}
                                      onClick={() => {
                                        resetForm()
                                        setFieldValue('personType', 0)
                                        cleanRegisterError()
                                      }}
                                    >
                                      {i18n.t('registrationPage.individual')}
                                    </S.SwitchButton>
                                    <S.SwitchButton
                                      type="button"
                                      isChecked={values.personType === 1}
                                      onClick={() => {
                                        resetForm()
                                        setFieldValue('personType', 1)
                                        cleanRegisterError()
                                      }}
                                    >
                                      {i18n.t('registrationPage.entity')}
                                    </S.SwitchButton>
                                  </S.ButtonsContainer>
                                )}

                                {((!auth.legalStatus &&
                                  values.personType === 0) ||
                                  (auth.legalStatus &&
                                    auth.legalStatus === 'physical')) && (
                                  <S.FormSection>
                                    <S.FormItem>
                                      {/* <S.Wrapper> */}
                                      <S.Label>
                                        {i18n.t('domainSettings.period')}
                                      </S.Label>
                                      <S.Select
                                        onChange={(e) =>
                                          updateCart(product, {
                                            years: e.target.value,
                                          })
                                        }
                                      >
                                        {years.map((item) => (
                                          <S.Option
                                            value={item}
                                            selected={item === +product.years}
                                          >
                                            {item}{' '}
                                            {i18n.t('domainSettings.years')}
                                          </S.Option>
                                        ))}
                                      </S.Select>
                                      {/* </S.Wrapper> */}
                                    </S.FormItem>
                                    <S.FormItem>
                                      <S.Label>
                                        {i18n.t(
                                          'domainConfigurationPageLabels.labelPassportSeries'
                                        )}
                                      </S.Label>
                                      <S.Input
                                        placeholder="AM00000000"
                                        name="passportSeries"
                                        onChange={handleChange}
                                        value={values.passportSeries}
                                      />
                                      {errors.passportSeries &&
                                        touched.passportSeries && (
                                          <S.ErrorMessage>
                                            {i18n.t(errors.passportSeries)}
                                          </S.ErrorMessage>
                                        )}
                                    </S.FormItem>
                                    <S.FormItem>
                                      <S.Label>
                                        {i18n.t(
                                          'domainConfigurationPageLabels.labelPassportIssuer'
                                        )}
                                      </S.Label>
                                      <S.Input
                                        placeholder="001"
                                        name="passportIssuer"
                                        onChange={handleChange}
                                        value={values.passportIssuer}
                                      />
                                      {errors.passportIssuer &&
                                        touched.passportIssuer && (
                                          <S.ErrorMessage>
                                            {i18n.t(errors.passportIssuer)}
                                          </S.ErrorMessage>
                                        )}
                                    </S.FormItem>
                                    <S.FormItem>
                                      <S.Label>
                                        {i18n.t(
                                          'domainConfigurationPageLabels.labelPassportDate'
                                        )}
                                      </S.Label>
                                      <S.DateInput
                                        mask="9999-99-99"
                                        maskChar={null}
                                        placeholder="YYYY/MM/DD"
                                        name="passportDate"
                                        onChange={handleChange}
                                        value={values.passportDate}
                                      />
                                      {errors.passportDate &&
                                        touched.passportDate && (
                                          <S.ErrorMessage>
                                            {i18n.t(errors.passportDate)}
                                          </S.ErrorMessage>
                                        )}
                                    </S.FormItem>
                                    <S.FormItem>
                                      <S.Label>
                                        {i18n.t(
                                          'domainConfigurationPageLabels.labelPhonenumber'
                                        )}
                                      </S.Label>
                                      <S.Input
                                        placeholder="+37400000000"
                                        name="phoneNumber"
                                        onChange={handleChange}
                                        value={values.phoneNumber}
                                      />
                                      {errors.phoneNumber &&
                                        touched.phoneNumber && (
                                          <S.ErrorMessage>
                                            {i18n.t(errors.phoneNumber)}
                                          </S.ErrorMessage>
                                        )}
                                    </S.FormItem>
                                    <S.FormItem>
                                      <S.Label>
                                        {i18n.t(
                                          'domainConfigurationPageLabels.labelId'
                                        )}
                                      </S.Label>
                                      <S.Input
                                        placeholder="ID number/Passport number"
                                        name="passportNumbers"
                                        onChange={handleChange}
                                        value={values.passportNumbers}
                                      />
                                      {errors.passportNumbers &&
                                        touched.passportNumbers && (
                                          <S.ErrorMessage>
                                            {i18n.t(errors.passportNumbers)}
                                          </S.ErrorMessage>
                                        )}
                                    </S.FormItem>
                                    <S.FormItem>
                                      <S.Label>
                                        {i18n.t(
                                          'domainConfigurationPageLabels.labelDob'
                                        )}
                                      </S.Label>
                                      <S.DateInput
                                        mask="9999-99-99"
                                        maskChar={null}
                                        placeholder="YYYY/MM/DD"
                                        name="dateOfBirth"
                                        onChange={handleChange}
                                        value={values.dateOfBirth}
                                      />

                                      {errors.dateOfBirth &&
                                        touched.dateOfBirth && (
                                          <S.ErrorMessage>
                                            {i18n.t(errors.dateOfBirth)}
                                          </S.ErrorMessage>
                                        )}
                                    </S.FormItem>
                                    <S.FormItem />
                                  </S.FormSection>
                                )}

                                {((!auth.legalStatus &&
                                  values.personType === 1) ||
                                  (auth.legalStatus &&
                                    auth.legalStatus === 'legal')) && (
                                  <S.FormSection>
                                    <S.FormItem>
                                      <S.Wrapper>
                                        <S.Label>
                                          {i18n.t('domainSettings.period')}
                                        </S.Label>
                                        <S.Select
                                          onChange={(e) =>
                                            updateCart(product, {
                                              years: e.target.value,
                                            })
                                          }
                                        >
                                          {years.map((item) => (
                                            <S.Option
                                              value={item}
                                              selected={item === +product.years}
                                            >
                                              {item}{' '}
                                              {i18n.t('domainSettings.years')}
                                            </S.Option>
                                          ))}
                                        </S.Select>
                                      </S.Wrapper>
                                    </S.FormItem>
                                    <S.FormItem>
                                      <S.Label>
                                        {i18n.t(
                                          'domainConfigurationPageLabels.labelCompanyName'
                                        )}
                                      </S.Label>
                                      <S.Input
                                        placeholder="Example"
                                        name="companyNameLat"
                                        onChange={handleChange}
                                        value={values.companyNameLat}
                                      />
                                      {errors.companyNameLat &&
                                        touched.companyNameLat && (
                                          <S.ErrorMessage>
                                            {i18n.t(errors.companyNameLat)}
                                          </S.ErrorMessage>
                                        )}
                                    </S.FormItem>
                                    <S.FormItem>
                                      <S.Label>
                                        {i18n.t(
                                          'domainConfigurationPageLabels.labelTin'
                                        )}
                                      </S.Label>
                                      <S.Input
                                        placeholder="321321"
                                        name="taxPayerNumber"
                                        onChange={handleChange}
                                        value={values.taxPayerNumber}
                                      />
                                      {errors.taxPayerNumber &&
                                        touched.taxPayerNumber && (
                                          <S.ErrorMessage>
                                            {i18n.t(errors.taxPayerNumber)}
                                          </S.ErrorMessage>
                                        )}
                                    </S.FormItem>

                                    <S.FormItem>
                                      <S.Label>
                                        {i18n.t(
                                          'domainConfigurationPageLabels.labelPhonenumber'
                                        )}
                                      </S.Label>
                                      <S.Input
                                        placeholder="+37400000000"
                                        name="phoneNumber"
                                        onChange={handleChange}
                                        value={values.phoneNumber}
                                      />
                                      {errors.phoneNumber &&
                                        touched.phoneNumber && (
                                          <S.ErrorMessage>
                                            {i18n.t(errors.phoneNumber)}
                                          </S.ErrorMessage>
                                        )}
                                    </S.FormItem>
                                    <S.FormItem>
                                      <S.Label>
                                        {i18n.t(
                                          'domainConfigurationPageLabels.labelLegalAddress'
                                        )}
                                      </S.Label>
                                      <S.Input
                                        placeholder="Юридический адрес"
                                        name="legalAddressCyr"
                                        onChange={handleChange}
                                        value={values.legalAddressCyr}
                                      />
                                      {errors.legalAddressCyr &&
                                        touched.legalAddressCyr && (
                                          <S.ErrorMessage>
                                            {i18n.t(errors.legalAddressCyr)}
                                          </S.ErrorMessage>
                                        )}
                                    </S.FormItem>
                                    <S.FormItem>
                                      <S.Label>
                                        {i18n.t(
                                          'domainConfigurationPageLabels.labelPostalAddress'
                                        )}
                                      </S.Label>
                                      <S.Input
                                        placeholder="Почтовый адрес"
                                        name="postalAddressCyr"
                                        onChange={handleChange}
                                        value={values.postalAddressCyr}
                                      />
                                      {errors.postalAddressCyr &&
                                        touched.postalAddressCyr && (
                                          <S.ErrorMessage>
                                            {i18n.t(errors.postalAddressCyr)}
                                          </S.ErrorMessage>
                                        )}
                                    </S.FormItem>
                                  </S.FormSection>
                                )}

                                {error && (
                                  <S.ErrorMessage spaced>
                                    {i18n.t(
                                      `backend.registrationPage.${slugify(
                                        error
                                      )}`
                                    )}
                                  </S.ErrorMessage>
                                )}
                              </Form>
                            )
                          }}
                        </Formik>
                      </S.FormContainer>
                    </S.RuWrapper>
                  )}
                  <S.ButtonWrapper>
                    <S.ButtonInnerWrapper>
                      <S.FormItem>
                        <S.Button
                          hostingIsAdded={
                            !!cart.find(
                              (item) =>
                                item.type === 'hosting' &&
                                item.domain === product.domain &&
                                item.tld === product.tld
                            )
                          }
                          onClick={() => {
                            const match = cart.find(
                              (item) =>
                                item.type === 'hosting' &&
                                item.domain === product.domain &&
                                item.tld === product.tld
                            )
                            if (match) {
                              return removeFromCart(match)
                            }
                            showModal(HostingModal, {
                              domain: product.domain,
                              tld: product.tld,
                              largeModal: true,
                            })
                          }}
                        >
                          {!!cart.find(
                            (item) =>
                              item.type === 'hosting' &&
                              item.domain === product.domain &&
                              item.tld === product.tld
                          )
                            ? i18n.t('domainSettings.hostingIsAdded')
                            : i18n.t('domainSettings.addHosting')}
                        </S.Button>
                      </S.FormItem>
                      <S.FormItem>
                        <S.Button
                          onClick={() =>
                            showModal(NameServerModal, {
                              id: product._id,
                              isNewDomain: true,
                              largeModal: true,
                            })
                          }
                        >
                          {i18n.t('domainSettings.nameServers')}
                        </S.Button>
                      </S.FormItem>
                    </S.ButtonInnerWrapper>
                  </S.ButtonWrapper>
                </S.OrderNowContainer>
              )
            })}
            <S.NextButton
              type="submit"
              onClick={() => {
                handleSubmit()
              }}
            >
              {i18n.t('domainSettings.next')}
            </S.NextButton>
            <S.CorrectionContainer>
              <S.WarningText>
                {i18n.t('domainSettings.warning')}
                <S.WarningLink
                  href="https://aipa.am/en/national-procedure-reg/#search_results_place"
                  target="_blank"
                >
                  {i18n.t('domainSettings.warningLink')}
                </S.WarningLink>
              </S.WarningText>
            </S.CorrectionContainer>
            {/* // disabled={}
              //   Object.keys(isFormValid).every((key) => {
              //     return isFormValid[key] === true;
              //   }) && ruTld
              // }
               */}
          </S.MainBlock>
        </S.OrderNowBodyContainer>
      </S.PageBody>
    </LayoutCommon>
  )
}
export { DomainConfigurationPage }
