import { SSL_TYPES } from "./SslPage.types";
import FetchApi from '../../helpers/FetchApi'

export const changeTab = (tab) => ({
  type: SSL_TYPES.CHANGE_TAB,
  tab,
});


export const domainValidation = () => async(dispatch) => {
  try{
    dispatch({
      type: SSL_TYPES.DOMAIN_VALIDATION_REQUEST
    });

    const req = await FetchApi.get('products/getProducts?gid=8');

    dispatch({
      type: SSL_TYPES.DOMAIN_VALIDATION_SUCCESS,
      payload: req.data.data
    });
  }
  catch(e){
    dispatch({
      type: SSL_TYPES.DOMAIN_VALIDATION_FAIL,
      payload: e
    })
  }
}

export const organizationValidation = () => async(dispatch)=>{
  try{
    dispatch({
      type: SSL_TYPES.ORGANIZATION_VALIDATION_REQUEST
    });

    const req = await FetchApi.get('products/getProducts?gid=9')

    dispatch({
      type: SSL_TYPES.ORGANIZATION_VALIDATION_SUCCESS,
      payload: req.data.data
    });
  }
  catch(e){
    dispatch({
      type: SSL_TYPES.ORGANIZATION_VALIDATION_FAIL,
      payload: e
    })
  }
}

export const extendedValidation = () => async(dispatch)=>{
  try{
    dispatch({
      type: SSL_TYPES.EXTENDED_VALIDATION_REQUEST
    });

    const req = await FetchApi.get('products/getProducts?gid=10')

    dispatch({
      type: SSL_TYPES.EXTENDED_VALIDATION_SUCCESS,
      payload: req.data.data
    });
  }
  catch(e){
    dispatch({
      type: SSL_TYPES.EXTENDED_VALIDATION_FAIL,
      payload: e
    })
  }
}