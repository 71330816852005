export const INVOICE_TYPES = {
  GET_INVOICE_REQUEST: 'INVOICE_TYPES.GET_INVOICE_REQUEST',
  GET_INVOICE_SUCCESS: 'INVOICE_TYPES.GET_INVOICE_SUCCESS',
  GET_INVOICE_FAIL: 'INVOICE_TYPES.GET_INVOICE_FAIL',
  GET_PAYMENT_FORM_REQUEST: 'INVOICE_TYPES.GET_PAYMENT_FORM_REQUEST',
  GET_PAYMENT_FORM_SUCCESS: 'INVOICE_TYPES.GET_PAYMENT_FORM_SUCCESS',
  GET_PAYMENT_FORM_FAIL: 'INVOICE_TYPES.GET_PAYMENT_FORM_FAIL',
  CHANGE_PAYMENT_METHOD: 'INVOICE_TYPES.CHANGE_PAYMENT_METHOD',
  APPLY_CREDIT_REQUEST: 'INVOICE_TYPES.APPLY_CREDIT_REQUEST',
  APPLY_CREDIT_SUCCESS: 'INVOICE_TYPES.APPLY_CREDIT_SUCCESS',
  APPLY_CREDIT_FAIL: 'INVOICE_TYPES.APPLY_CREDIT_FAIL',
  CLEAR_INVOICE: 'INVOICE_TYPES.CLEAR_INVOICE',
};
