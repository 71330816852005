import React from 'react'
import { Helmet } from 'react-helmet'

import { LayoutCommon } from '../../layouts/LayoutCommon'
import * as S from './ConstructorPage.styles'
import { ConstructorCard } from '../../components/ConstructorCard'
import {
  constructorsData,
  compareData,
  advantagesData,
} from './ConstructorPage.constants'
import { i18n } from '../../i18n'
import { questions } from '../SupportPage/SupportPage.constants'
import { QuestionsList } from '../../components/QuestionsList'
import ArrowRight from '../../images/constructor-arrow-right.png'
import CheckIcon from '../../images/check-icon.png'

const ConstructorPage = ({ history }) => (
  <LayoutCommon transparentHeader>
    <S.PageWrapper>
      <Helmet>
        <title>{i18n.t('headers.constructorPage')}</title>
        <meta
          name="description"
          content={i18n.t('headersDescriptions.constructorPage')}
        />
        <link
          rel="alternate"
          href="https://internet.am/en/constructor-packages"
          hreflang="en"
        />
        <link
          rel="alternate"
          href="https://internet.am/ru/constructor-packages"
          hreflang="ru"
        />
        <link
          rel="alternate"
          href="https://internet.am/constructor-packages"
          hreflang="am-AM"
        />

        <meta
          name="keywords"
          content={i18n.t('headerKeywords.constructorPage')}
        />
        <meta property="og:title" content={i18n.t('headers.constructorPage')} />
        <meta
          property="og:description"
          content={i18n.t('headersDescriptions.constructorPage')}
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ABC Domain LLC" />
        <meta
          property="og:image"
          content="https://internet.am/images/ConstructorFb.png"
        />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={i18n.t('headers.constructorPage')}
        />
        <meta
          name="twitter:description"
          content={i18n.t('headersDescriptions.constructorPage')}
        />
        <meta
          name="twitter:image"
          content="https://internet.am/images/ConstructorFb.png"
        />
      </Helmet>
      <S.HeaderContainer>
        <S.SiteExample>
          <S.HeaderContent>
            <S.HeaderTitle>
              {i18n.t('constructorPage.headerTitle')}
            </S.HeaderTitle>
            <S.HeaderButtonContent>
              <S.HeaderButton href="#constructors">
                {i18n.t('constructorPage.headerButton')}
              </S.HeaderButton>
              <S.HeaderButtonText>
                {i18n.t('constructorPage.headerText')}
              </S.HeaderButtonText>
            </S.HeaderButtonContent>
          </S.HeaderContent>
        </S.SiteExample>
      </S.HeaderContainer>
      <S.PageBody>
        <S.VideoContainer>
          <S.Video
            url="https://www.youtube.com/watch?v=6uxLCJk-grk"
            autoplay={true}
            loop={true}
            muted={true}
            config={{
              youtube: {
                playerVars: {
                  modestbranding: 1,
                  disablekb: 1,
                  showinfo: 0,
                  autoplay: 1,
                },
              },
            }}
            frameborder="0"
          ></S.Video>
          <S.VideoInfoContainer>
            <div>
              <S.VideoTitle1>
                {i18n.t('constructorPage.videoTitle1')}
              </S.VideoTitle1>
              <S.VideoTitle2>
                {i18n.t('constructorPage.videoTitle2')}
              </S.VideoTitle2>
              <S.VideoTitle3>
                {i18n.t('constructorPage.videoTitle3')}
              </S.VideoTitle3>
            </div>
            <S.VideoInfo>
              <div>{i18n.t('constructorPage.videoSubTitle1')}</div>
              <div>{i18n.t('constructorPage.videoSubTitle2')}</div>
              <div>{i18n.t('constructorPage.videoSubTitle3')}</div>
              <S.VideoButton href="#constructors">
                {i18n.t('constructorPage.videoButton')}
                <img
                  src={ArrowRight}
                  alt="Arrow right"
                  title="arrow right image"
                />
              </S.VideoButton>
            </S.VideoInfo>
          </S.VideoInfoContainer>
        </S.VideoContainer>
        <S.AdvantagesContainer>
          <S.AdvantagesInfo>
            <S.AdvantagesTitle>
              {i18n.t('constructorPage.advantages.title')}
            </S.AdvantagesTitle>
            <S.AdvantagesBody>
              {advantagesData.map((el) => (
                <S.AdvantagesItem>
                  <S.CheckIcon
                    src={CheckIcon}
                    alt="Check icon"
                    title="check icon"
                  />
                  <S.AdvantageText>{i18n.t(el)}</S.AdvantageText>
                </S.AdvantagesItem>
              ))}
            </S.AdvantagesBody>
          </S.AdvantagesInfo>
          <S.AdvantagesImage />
        </S.AdvantagesContainer>
        <S.ConstructorsContainer id="constructors" name="constructors">
          <S.PackagesTitle>
            {i18n.t('constructorPage.packagesTitle')}
          </S.PackagesTitle>
          {constructorsData.map((constructor) => {
            return (
              <ConstructorCard constructor={constructor} history={history} />
            )
          })}
        </S.ConstructorsContainer>
        <S.CompareContainer>
          <S.CompareTitle>
            {i18n.t('constructorPage.compareTitle')}
          </S.CompareTitle>
          <S.CompareItemsContainer>
            <S.CompareRowContent>
              <S.RowTitle>
                {i18n.t('constructorPage.compareRentSite.title')}
              </S.RowTitle>
              {compareData.rentSiteItems.map((el) => (
                <S.CompareItemContainer>
                  <S.CompareImage
                    src={el.img}
                    alt="Shape image"
                    title="element image"
                  />
                  <S.CompareItemName>{i18n.t(el.name)}</S.CompareItemName>
                </S.CompareItemContainer>
              ))}
            </S.CompareRowContent>

            <S.CompareRowContent>
              <S.RowTitle>
                {i18n.t('constructorPage.compareBuyConstructor.title')}
              </S.RowTitle>
              {compareData.buyConstructorItems.map((el) => (
                <S.CompareItemContainer>
                  <S.CompareImage
                    src={el.img}
                    alt="Shape image"
                    title="shape image"
                  />
                  <S.CompareItemName>{i18n.t(el.name)}</S.CompareItemName>
                </S.CompareItemContainer>
              ))}
            </S.CompareRowContent>
          </S.CompareItemsContainer>
        </S.CompareContainer>
        <S.HaveQuestionContainer>
          <S.QuestionImage />
          <S.HaveQuestionText>
            <S.HaveQuestionTitle>
              {i18n.t('constructorPage.HaveQuestionTitle')}
            </S.HaveQuestionTitle>
            <S.HaveQuestionSubTitle>
              {i18n.t('constructorPage.HaveQuestionSubTitle')}
            </S.HaveQuestionSubTitle>
          </S.HaveQuestionText>
        </S.HaveQuestionContainer>
        <S.QuestionsListContainer>
          <QuestionsList isInPage questions={questions.constructor.list} />
          <S.AdvantageTitle>
            {i18n.t('faq.constructor.advantagesTitle')}
          </S.AdvantageTitle>
          <S.AdvantageInfo>
            {i18n.t('faq.constructor.advantagesInfo')}
          </S.AdvantageInfo>
          <S.Info>{i18n.t('faq.constructor.info1')}</S.Info>
          <S.Info>{i18n.t('faq.constructor.info2')}</S.Info>
          <S.Info>{i18n.t('faq.constructor.info3')}</S.Info>
          <S.Info>{i18n.t('faq.constructor.info4')}</S.Info>
          <S.Info>{i18n.t('faq.constructor.info5')}</S.Info>
          <S.Info>{i18n.t('faq.constructor.info6')}</S.Info>
          <S.Info>{i18n.t('faq.constructor.info7')}</S.Info>
          <S.Info>{i18n.t('faq.constructor.info8')}</S.Info>
          <S.Info>{i18n.t('faq.constructor.info9')}</S.Info>
          <S.Info>{i18n.t('faq.constructor.info10')}</S.Info>
        </S.QuestionsListContainer>
      </S.PageBody>
    </S.PageWrapper>
  </LayoutCommon>
)

export { ConstructorPage }
