import * as Yup from 'yup';

export const initialValues = (info) => ({
  country: info.country,
  state: info.state,
  city: info.city,
  address1: info.address1,
  postcode: info.postcode,
  phonenumber: info.phonenumber,
  autopay: info.bindStatus === 'active' ? true : false,
});

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || 'Two strings should be equal',
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref);
    },
  });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);

export const yupScheme = Yup.object().shape({
  phonenumber: Yup.string()
    .min(9, 'validationFields.phonenumberMin')
    .max(20, 'validationFields.phonenumberMax')
    .required('validationFields.phonenumber'),
  country: Yup.string().required('validationFields.country'),
  state: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-`."]+$/, 'validationFields.invalidTyping')
    .required('validationFields.state'),
  city: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-`."]+$/, 'validationFields.invalidTyping')
    .required('validationFields.city'),
  address1: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-,."/]+$/, 'validationFields.invalidTyping')
    .required('validationFields.address'),
  postcode: Yup.string()
    .matches(/^([a-zA-Z0-9\s\-.]){1,40}$/, 'validationFields.invalidTyping')
    .required('validationFields.postcode'),
});
