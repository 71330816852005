import { ERROR_PAGE_TYPES } from './ErrorPage.types';

const initialState = {
  hatColor: 0,
  glassesColor: 0,
  beardsColor: 0,
  costColor: 0,
  shirtColor: 0,
};

function errorPage(state = initialState, action) {
  switch (action.type) {
    case ERROR_PAGE_TYPES.CHANGE_HAT_COLOR:
      return {
        ...state,
        hatColor: action.hatColor,
      };
    case ERROR_PAGE_TYPES.CHANGE_GLASSES_COLOR:
      return {
        ...state,
        glassesColor: action.glassesColor,
      };
    case ERROR_PAGE_TYPES.CHANGE_BEARDS_COLOR:
      return {
        ...state,
        beardsColor: action.beardsColor,
      };
    case ERROR_PAGE_TYPES.CHANGE_COST_COLOR:
      return {
        ...state,
        costColor: action.costColor,
      };
    case ERROR_PAGE_TYPES.CHANGE_SHIRT_COLOR:
      return {
        ...state,
        shirtColor: action.shirtColor,
      };
    default:
      return state;
  }
}

export { errorPage };
