import React, { useCallback } from 'react'
import qs from 'qs'
import { Formik } from 'formik'

import * as S from './SearchAreaBlock.styles'
import { tld } from '../../HomePage.constants'
import { Tooltip } from '../../../../components/Tooltip'
import { TldCard } from '../../../../components/TldCard'
import { i18n } from '../../../../i18n'
import { initialValues, yupScheme, tldCards } from './SearchAreaBlock.config'

import { changeUrl } from '../../../../hooks/changeUrl'
import { Dropdown } from '../../../../components/Dropdown'
import domainNameSelect from '../../../../images/domainNameSelect.svg'
import domainNameRemove from '../../../../images/domainNameRemove.svg'

export const SearchAreaBlock = ({ history, toggleTld, cutTld, tlds }) => {
  const handleSearch = useCallback(
    (domain) => {
      cutTld(domain)
      const domainSplitted = domain.split('.')
      const domainCut = domainSplitted[0]
      const params = qs.stringify(
        { searchInput: domainCut },
        {
          addQueryPrefix: true,
          encode: true,
        }
      )
      history.push(changeUrl(`/domain-registration${params}`))
    },
    [history]
  )

  const handleToggleTld = (tldName) => {
    toggleTld(tldName)
    return true
  }

  return (
    <S.SearchAreaImage>
      <S.SearchAreaContainer>
        <S.SearchAreaBackground />
        <S.MainInfoContainer>
          <S.InfoContainer>
            {i18n.t('homePage.searchAreaBlock.title')}
            <S.InfoContainerSale>
              {' '}
              {/* {i18n.t("homePage.searchAreaBlock.sale")} */}
            </S.InfoContainerSale>
            {/* <S.PromoCodeContainer>
              {i18n.t('homePage.searchAreaBlock.info')}
              {i18n.t('homePage.searchAreaBlock.rusText')}
              <span>{i18n.t('homePage.searchAreaBlock.promocode')}</span>
              {i18n.t('homePage.searchAreaBlock.endText')}
            </S.PromoCodeContainer> */}
            <Formik
              initialValues={initialValues}
              validationSchema={yupScheme}
              onSubmit={(values, { setSubmitting }) => {
                handleSearch(values.domain)
                setSubmitting(false)
              }}
            >
              {({ touched, errors, values, isSubmitting, handleChange }) => (
                <S.StyledFormContainer>
                  <S.Column>
                    <S.SearchInputWrapper
                      isError={touched.domain && errors.domain}
                    >
                      <S.SearchInput
                        placeholder={i18n.t('domainsPage.header')}
                        name="domain"
                        value={values.domain}
                        onChange={handleChange}
                      />
                      <Dropdown
                        options={tld}
                        onClick={handleToggleTld}
                        value={tlds}
                        withDisable
                      >
                        <S.DomainNameSelect>
                          <S.DomainName>.am</S.DomainName>
                          <img src={domainNameSelect} alt="domainNameSelect" />
                        </S.DomainNameSelect>
                      </Dropdown>
                    </S.SearchInputWrapper>
                    {touched.domain && errors.domain && (
                      <S.ErrorIconContainer className="tooltipContainer">
                        <S.ErrorIcon />
                        <Tooltip className="tooltip top right noArrow error">
                          {i18n.t(errors.domain)}
                        </Tooltip>
                      </S.ErrorIconContainer>
                    )}
                    {!tlds.length && (
                      <S.Info to={changeUrl('/domain-registration')}>
                        {i18n.t('searchAreaBlock.info')}
                      </S.Info>
                    )}
                    <S.SelectedDomainsContainer>
                      {tlds.map((tldName, ind) => {
                        return (
                          <S.SelectedDomainName key={ind}>
                            {tldName}
                            <S.SelectedDomainRemove
                              onClick={() => handleToggleTld(tldName)}
                            >
                              <S.RemoveIcon src={domainNameRemove} />
                            </S.SelectedDomainRemove>
                          </S.SelectedDomainName>
                        )
                      })}
                    </S.SelectedDomainsContainer>
                    {tlds.length !== 0 && (
                      <S.Info to={changeUrl('/domain-registration')}>
                        {i18n.t('searchAreaBlock.info')}
                      </S.Info>
                    )}
                  </S.Column>

                  <S.DomainSearchButton type="submit" disabled={isSubmitting}>
                    {i18n.t('searchAreaBlock.search')}
                  </S.DomainSearchButton>
                </S.StyledFormContainer>
              )}
            </Formik>
            <S.OtherDomainContainer />
          </S.InfoContainer>

          <S.PriceCardsContainer>
            {tldCards.map((item, index) => (
              <S.TldCardBackground key={index}>
                <TldCard tld={item} />
              </S.TldCardBackground>
            ))}
          </S.PriceCardsContainer>
        </S.MainInfoContainer>
      </S.SearchAreaContainer>
      <S.Overlay />
    </S.SearchAreaImage>
  )
}
