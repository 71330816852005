import React, { useState, useEffect, useCallback } from 'react';
import ScrollLock from 'react-scrolllock';
import { CSSTransition } from 'react-transition-group';

import * as S from './ErrorMobileMenu.styles';
import { services } from '../ErrorPage.constants';

export const ErrorMobileMenu = ({ isExpanded, hideMobileMenu }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(false);
    setTimeout(() => {
      setAnimate(true);
    }, 50);
  }, [isExpanded]);

  const hideMobileMenuWithAnimate = useCallback(() => {
    setAnimate(false);
    setTimeout(() => {
      hideMobileMenu();
      setAnimate(true);
    }, 200);
  }, [setAnimate, hideMobileMenu]);

  return (
    isExpanded && (
      <S.Container>
        <ScrollLock />
        <S.Backdrop onClick={hideMobileMenuWithAnimate} />
        <CSSTransition in={animate} classNames="MobileMenu" timeout={1}>
          {() => (
            <S.MobileMenuContainer>
              <S.Content>
                <S.CloseModalIcon onClick={hideMobileMenuWithAnimate} />
                <S.ServicesContainer>
                  {services.map((servicePackage) => (
                    <S.Wrapper>
                      <S.ServiceLink to={servicePackage.link}>
                        <S.Service
                          key={servicePackage.id}
                          src={servicePackage.image}
                          alt="Service image"
                          title="service image"
                          href={servicePackage.link}
                        />
                      </S.ServiceLink>
                    </S.Wrapper>
                  ))}
                </S.ServicesContainer>
              </S.Content>
            </S.MobileMenuContainer>
          )}
        </CSSTransition>
      </S.Container>
    )
  );
};
