import { DOMAIN_PRICES_TYPES } from './DomainPrices.types'

const initialState = {
  getDomainPricesUsdLoading: false,
  getDomainPricesAmdLoading: false,
  getDomainPricesError: null,
  getDomainPricesAmdData: null,
  getDomainPricesUsdData: null,
}

export const getDomainPricesAmdReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_AMD_REQUEST:
      return {
        ...state,
        getDomainPricesAmdLoading: true,
      }
    case DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_AMD_SUCCESS:
      return {
        ...state,
        getDomainPricesAmdData: action.data,
        getDomainPricesAmdLoading: false,
      }
    case DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_AMD_FAIL:
      return {
        ...state,
        getDomainPricesError: action.error,
        getDomainPricesAmdLoading: false,
      }
    default:
      return {
        ...state,
      }
  }
}

export const getDomainPricesUsdReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_USD_REQUEST:
      return {
        ...state,
        getDomainPricesUsdLoading: true,
      }
    case DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_USD_SUCCESS:
      return {
        ...state,
        getDomainPricesUsdData: action.data,
        getDomainPricesUsdLoading: false,
      }
    case DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_USD_SUCCESS:
      return {
        ...state,
        getDomainPricesError: action.error,
        getDomainPricesUsdLoading: false,
      }
    default:
      return {
        ...state,
      }
  }
}
