import * as Yup from "yup";

export const getConfig = (transformedContacts) => {
  let { administrative, registrant, technical } = transformedContacts;
  if (!transformedContacts) {
    administrative = "administrative";
    registrant = "owner";
    technical = "technical";
  }

  const config = {
    [administrative]: [
      {
        id: `${administrative}.Full_Name`,
        name: "domainContactModal.n1",
        placeholder: "Full name",
        editable: true,
      },
      {
        id: `${administrative}.Organization_Name`,
        name: "domainContactModal.n4",
        placeholder: "Organization",
        editable: true,
      },
      {
        id: `${administrative}.Address`,
        name: "domainContactModal.n6",
        placeholder: "Address",
        editable: true,
      },
      {
        id: `${administrative}.Postcode`,
        name: "domainContactModal.n7",
        placeholder: "Post Code",
        editable: true,
      },
      {
        id: `${administrative}.City`,
        name: "domainContactModal.n3",
        placeholder: "City",
        editable: true,
      },
      {
        id: `${administrative}.Country`,
        name: "domainContactModal.n2",
        placeholder: "Country",
        editable: true,
      },
      {
        id: `${administrative}.Phone`,
        name: "domainContactModal.n8",
        placeholder: "Phone Number",
        editable: true,
      },
      {
        id: `${administrative}.Email`,
        name: "domainContactModal.n5",
        placeholder: "Email",
        editable: true,
      },
    ],
    [technical]: [
      {
        id: `${technical}.Full_Name`,
        name: "domainContactModal.n1",
        placeholder: "Full Name",
        editable: true,
      },
      {
        id: `${technical}.Organization_Name`,
        name: "domainContactModal.n4",
        placeholder: "Organization",
        editable: true,
      },
      {
        id: `${technical}.Address`,
        name: "domainContactModal.n6",
        placeholder: "Address",
        editable: true,
      },
      {
        id: `${technical}.Postcode`,
        name: "domainContactModal.n7",
        placeholder: "Post Code",
        editable: true,
      },
      {
        id: `${technical}.City`,
        name: "domainContactModal.n3",
        placeholder: "City",
        editable: true,
      },
      {
        id: `${technical}.Country`,
        name: "domainContactModal.n2",
        placeholder: "Country",
        editable: true,
      },
      {
        id: `${technical}.Phone`,
        name: "domainContactModal.n8",
        placeholder: "Phone Number",
        editable: true,
      },
      {
        id: `${technical}.Email`,
        name: "domainContactModal.n5",
        placeholder: "Email",
        editable: true,
      },
    ],
    [registrant]: [
      {
        id: `${registrant}.Full_Name`,
        name: "domainContactModal.n1",
        placeholder: "Full Name",
        editable: false,
      },
      {
        id: `${registrant}.City`,
        name: "domainContactModal.n3",
        placeholder: "City",
        editable: false,
      },
      {
        id: `${registrant}.Address`,
        name: "domainContactModal.n6",
        placeholder: "Address",
        editable: false,
      },
      {
        id: `${registrant}.Country`,
        name: "domainContactModal.n2",
        placeholder: "Country",
        editable: false,
      },
      {
        id: `${registrant}.Postcode`,
        name: "domainContactModal.n7",
        placeholder: "Post Code",
        editable: false,
      },
    ],
  };
  return config;
};

export const getYup = (transformedContacts, domainName) => {
  let { administrative, registrant, technical } = transformedContacts;
  if (!transformedContacts) {
    administrative = "administrative";
    registrant = "owner";
    technical = "technical";
  }

  return Yup.object().shape({
    [administrative]: Yup.object().shape({
      Full_Name: Yup.string()
        .min(6, "validationFields.fullNameMin")
        .max(72, "validationFields.fullNameMax")
        .matches(/^([a-zA-Z0-9\s\-.]){1,40}$/, "validationFields.invalidTyping")
        .required("validationFields.fullName"),
      Email: Yup.string()
        .email("validationFields.email")
        .required("validationFields.email")
        .test(
          "validationFields.sameDomain",
          "validationFields.sameDomain",
          (value) => !value.endsWith(domainName)
        ),
      Address: Yup.string().required("validationFields.address"),
      Postcode: Yup.string().required("validationFields.postcode"),
      Phone: Yup.string()
        .matches(
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
          "validationFields.invalidTyping"
        )
        .required("validationFields.phonenumber"),
    }),
    [registrant]: Yup.object().shape({
      Full_Name: Yup.string().min(2).max(72).required(),

      Address: Yup.string().required("validationFields.address"),
      Postcode: Yup.string().required("validationFields.postcode"),
    }),
    [technical]: Yup.object().shape({
      Full_Name: Yup.string()
        .min(6, "validationFields.fullNameMin")
        .max(72, "validationFields.fullNameMax")
        .required("validationFields.fullName"),
      Email: Yup.string()
        .email("validationFields.email")
        .required("validationFields.email")
        .test(
          "validationFields.sameDomain",
          "validationFields.sameDomain",
          (value) => !value.endsWith(domainName)
        ),
      Address: Yup.string().required("validationFields.address"),
      Postcode: Yup.string().required("validationFields.postcode"),
      Phone: Yup.string()
        .matches(
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
          "validationFields.invalidTyping"
        )
        .required("validationFields.phonenumber"),
    }),
  });
};
