import styled from 'styled-components';

export const TldCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 130px;
  height: 100%;
  padding: 30px 15px;
  box-sizing: border-box;
  border-radius: 20px;
  background: ${({ background }) => background};
  backdrop-filter: blur(20px);

  @media(min-width: 768px) {
  }
  @media(min-width: 1280px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 250px;
    height: 100%;
    padding: 45px;
  }
}
`;
export const TldName = styled.div`
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;

  @media (min-width: 768px) {
  }
  @media (min-width: 1280px) {
    font-size: 64px;
  }
`;

export const TldPrice = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  color: #ffffff;
  margin-top: 20px;

  span {
    font-size: 10px;
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1280px) {
    font-size: 36px;
    margin-top: 30px;
    padding-left: 0px;

    span {
      font-size: 14px;
    }
  }
`;
export const TldOldPrice = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  text-decoration: line-through;
  color: #ffffff;
  padding-top: 5px;

  @media (min-width: 768px) {
  }
  @media (min-width: 1280px) {
    font-size: 14px;
    padding-left: 5px;
  }
`;
