import { GLUE_RECORDS_MODAL } from './GlueRecordsModal.types';
import { MODAL_TYPES } from '../../../common/Modal/Modal.types';

const initialState = {
  modalGlues: null,
  modalGluesLoading: false,
  modalGluesError: null,
  updateGlueLoading: false,
  updateGlueError: null,
  tld: '',
};

export const glueRecordsModal = (state = initialState, action) => {
  switch (action.type) {
    case GLUE_RECORDS_MODAL.GET_GLUES_REQUEST:
      return {
        ...state,
        modalGluesLoading: true,
        modalGlues: null,
        modalGluesError: null,
      };
    case GLUE_RECORDS_MODAL.GET_GLUES_SUCCESS:
      return {
        ...state,
        modalGluesLoading: false,
        modalGlues: action.data,
        modalGluesError: null,
      };
    case GLUE_RECORDS_MODAL.GET_GLUES_FAIL:
      return {
        ...state,
        modalGluesLoading: false,
        modalGlues: null,
        modalGluesError: action.error,
      };
    case GLUE_RECORDS_MODAL.SET_IPS_REQUEST:
      return {
        ...state,
        updateGlueLoading: true,
        updateGlueError: null,
      };
    case GLUE_RECORDS_MODAL.SET_IPS_SUCCESS:
      return {
        ...state,
        updateGlueLoading: false,
        updateGlueError: null,
        tld: action.tld,
      };
    case GLUE_RECORDS_MODAL.SET_IPS_FAIL:
      return {
        ...state,
        updateGlueLoading: false,
        updateGlueError: action.error,
      };
    case MODAL_TYPES.HIDE_MODAL:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};
