import React, { useEffect } from 'react'
import styled from 'styled-components'
import { withRouter, useParams } from 'react-router'
import { withTranslation } from 'react-i18next'
import { useSearchParams } from '../../hooks/useSearchParams'
import Cookies from 'js-cookie'
import { Header } from '../../components/Header'
import { Footer } from '../../components/Footer'
import GlobalStyles from '../../GlobalStyles'
import { PunycodeConverterModal } from '../../components/Modals/PunycodeConverterModal'
import { ContactOwnerModal } from '../../components/Modals/ContactOwnerModal'
import { ResetPasswordModal } from '../../components/Modals/ResetPasswordModal'

const LayoutCommonRaw = ({
  history,
  children,
  i18n,
  transparentHeader,
  changeBackendLanguage,
  showModal,
}) => {
  let { lang } = useParams()
  const [searchParams] = useSearchParams()
  const setLanguage = (lang) => {
    setTimeout(() => {
      Cookies.set('lng', lang)
      i18n.changeLanguage(lang)
      if (Cookies.get('token') && typeof changeBackendLanguage === 'function') {
        changeBackendLanguage(lang)
      }
    })
  }

  useEffect(() => {
    if (!lang) {
      setLanguage('am')
    } else {
      switch (lang) {
        case 'am':
          setLanguage('am')
          break
        case 'en':
          setLanguage('en')
          break
        case 'ru':
          setLanguage('ru')
          break
        default:
          history.push('/404')
      }
    }
  }, [lang])

  useEffect(() => {
    if (lang) {
      setTimeout(() => {
        if (searchParams.punycode) {
          showModal(PunycodeConverterModal, {})
        }
        if (searchParams.contact) {
          showModal(ContactOwnerModal, {})
        }
        if (searchParams.forgot) {
          showModal(ResetPasswordModal, {})
        }
      })
    }
  }, [lang])

  return (
    <>
      <GlobalStyles language={i18n.language} />
      <Header history={history} transparent={transparentHeader} />
      <Content>{children}</Content>
      <Footer history={history} />
    </>
  )
}

const Content = styled.div`
  margin-top: 39px;
  @media (min-width: 768px) {
    margin-top: 77px;
  }
  min-height: 300px;
`

export const LayoutCommon = withTranslation()(withRouter(LayoutCommonRaw))
