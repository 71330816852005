import styled from 'styled-components';
import { Form, Field } from 'formik';

import * as fontWeight from '../../assets/styles/constants/fontWeight';
import background from '../../images/faq-bg-img.svg';
import topImage from '../../images/faq-img.svg';
import closeIcon from '../../images/closeModal.svg';
import errorIcon from '../../images/error-icon.svg';

export const PageBody = styled.div`
  background-color: #f8f8f8;
  width: 100%;
  height: 100%;
  margin-top: 36px;
  @media (min-width: 768px) {
    margin-top: 70px;
  }
`;

export const QuestionPageContainer = styled.div`
  height: 100%;
  display: flex;
  padding: 36px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 768px) {
    padding: 130px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @media (min-width: 768px) {
    width: 695px;
  }
  @media (min-width: 1280px) {
    width: 1100px;
  }
`;

export const TitleSearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 1280px) {
    width: 1100px;
  }
`;

export const QuestionPageTitle = styled.h1`
  font-size: 18px;
  font-weight: 500;
  color: #484857;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

export const FormContainer = styled(Form)`
  position: relative;
  display: flex;
  margin-top: 30px;
  width: 100%;
  justify-content: space-between;
  @media (min-width: 768px) {
    justify-content: unset;
    margin-top: 90px;
  }
`;

export const ErrorIconContainer = styled.div`
  position: absolute;
  right: 0;
  padding: 10px;
  height: 32px;
  width: 32px;
  box-sizing: border-box;
  @media (min-width: 768px) {
    padding: 10px;
    height: 40px;
    width: 40px;
  }
`;

export const ErrorIcon = styled.div`
  position: absolute;
  top: 8px;
  right: 9px;
  height: 18px;
  width: 18px;
  background-image: url(${errorIcon});
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: 768px) {
    top: 12px;
    right: 12px;
  }
`;

export const SearchInputContainer = styled.div`
  position: relative;
  display: flex;
  width: 70%;
  flex-direction: column;
  margin-right: 9px;
  @media (min-width: 768px) {
    width: 263px;
    margin-right: 15px;
  }
  @media (min-width: 1280px) {
    width: 420px;
  }
`;

export const SearchInput = styled(Field)`
  border: 1px solid #e0e0e0;
  padding: 8px 10px;
  border-radius: 5px;
  font-weight: normal;
  width: 100%;
  box-sizing: border-box;

  ::placeholder {
    color: #cfcfcf;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #212121;
  }

  ::-ms-input-placeholder {
    color: #212121;
  }

  @media (min-width: 768px) {
    padding: 9px 18px;
    font-size: 18px;
    line-height: 21px;
  }
  &:focus {
    border: 1px solid #242e46;
  }
  ${(p) => p.isError && 'border: 1px solid #DD2326;'};
`;

export const SearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  background: #00c084;
  padding: 8px 17px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  width: 25%;

  @media (min-width: 768px) {
    border-radius: 5px;
    padding: 8px 22px;
    font-size: 18px;
    width: unset;
  }
`;

export const ResetIcon = styled.button`
  position: absolute;
  width: 12px;
  height: 12px;
  top: 11px;
  right: 11px;
  background: url(${closeIcon});
  background-size: cover;
  @media (min-width: 768px) {
    top: 15px;
    right: 15px;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  ${(p) => p.spaced && 'margin-top: 10px;'};
`;

export const QuestionsImg = styled.div`
  @media (min-width: 768px) {
    background-image: url(${topImage});
    background-repeat: no-repeat;
    background-size: cover;
    width: 566px;
    height: 202px;
    @media (min-width: 1280px) {
      width: 1220px;
      height: 373px;
    }
  }
`;
export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
  width: 100%;

  @media (min-width: 768px) {
    width: 695px;
    padding-top: 71px;
  }

  @media (min-width: 1280px) {
    width: 1300px;
    padding-top: 85px;
  }
`;

export const TabMenu = styled.div`
  display: flex;
  justify-content: center;
`;
export const Tab = styled.div`
  font-size: 14px;
  color: #484857;
  padding-right: 45px;
  border-right: 1px solid #000;
  font-weight: ${(p) => (p.isActive ? '500' : '300')};
  height: 34px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    border: none;
    padding-right: 0px;
    padding-left: 45px;
  }

  @media (min-width: 768px) {
    padding-right: 136px;
    font-size: 24px;

    &:last-child {
      border: none;
      padding-right: 0px;
      padding-left: 136px;
    }
  }

  @media (min-width: 1280px) {
    padding-right: 319px;

    &:last-child {
      padding-left: 319px;
    }
  }
`;

export const FAQSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: 1280px) {
    align-items: center;
  }
`;

export const FAQSectionTitle = styled.div`
  margin-top: 60px;
  font-size: 24px;
  font-weight: ${fontWeight.THIN};
  @media (min-width: 1280px) {
    align-self: flex-start;
    padding-left: 100px;
  }
`;

export const EmptyStateContainer = styled.div``;
