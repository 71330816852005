import React, { useRef } from "react";

import * as S from "./SettingsPopup.styles";
import { useOnClickOutside } from "../../../../../../hooks/useOnClickOutside";
import { i18n } from "../../../../../../i18n";

import { changeUrl } from "../../../../../../hooks/changeUrl";

export const SettingsPopup = ({
  history,
  showSettingsPopup,
  isShown,
  popupId,
  id,
}) => {
  const goToInvoice = (id) => {
    history.push(window.open(changeUrl(`/invoice?id=${id}`)));
  };

  const containerRef = useRef(null);
  useOnClickOutside(containerRef, () => showSettingsPopup(false));
  if (isShown !== popupId) {
    return null;
  }
  return (
    <S.SettingsPopup ref={containerRef}>
      <S.Status onClick={() => goToInvoice(id)}>
        {i18n.t("settingsPopup.viewInvoice")}
      </S.Status>
    </S.SettingsPopup>
  );
};
