import FetchApi from '../../../helpers/FetchApi';
import { CONTACT_OWNER_TYPES } from './ContactOwnerModal.types';

export const contactOwner = (values, onSuccess) => async (dispatch) => {
  values.domain = values.domain.toLowerCase();
  try {
    dispatch({
      type: CONTACT_OWNER_TYPES.CONTACT_OWNER_REQUEST,
    });
    const req = await FetchApi.post('domains/contactowner', values);

    dispatch({
      type: CONTACT_OWNER_TYPES.CONTACT_OWNER_SUCCESS,
      data: req.data,
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    dispatch({
      type: CONTACT_OWNER_TYPES.CONTACT_OWNER_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    });
  }
};
