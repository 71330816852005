import React, { useCallback, useEffect } from 'react'
import { ChangePasswordSuccessModal } from '../../../../components/Modals/ChangePasswordSuccessModal'
import { initialValues, yupScheme } from './Security.config'
import { Formik, Form } from 'formik'
import { changeUrl } from '../../../../hooks/changeUrl'
import { Loader } from '../../../../components/Loader'
import { i18n } from '../../../../i18n'

import * as S from './Security.styles'
import { values } from 'lodash'

const Security = ({
  auth,
  changePassLoading,
  changePassError,
  changePass,
  changePassSuccess,
  showModal,
  getActivateStatus,
  isActivated,
  isActivatedError,
  isActivatedLoading,
  disableActivateLink,
  token,
  logout,
  history,
}) => {
  const getActivateStatusFunction = useCallback(async () => {
    await getActivateStatus(auth.email)
  }, [auth.email])

  useEffect(() => {
    getActivateStatusFunction()
  }, [])

  const handleEnter = useCallback((e, submitForm) => {
    if (e.key === 'Enter') {
      submitForm()
    }
  }, [])

  const disable2Fa = useCallback(() => {
    disableActivateLink(token)
  }, [token])

  return (
    <S.SecurityContainer>
      <S.FormContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={yupScheme}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            changePass(values.password, values.oldpass, () => {
              showModal(ChangePasswordSuccessModal)
              resetForm()
              setTimeout(() => {
                logout(history)
              }, 1000)
            })
          }}
        >
          {({
            touched,
            errors,
            isSubmitting,
            handleChange,
            values,
            setFieldValue,
            submitForm,
            dirty,
          }) => (
            <Form onKeyPress={() => handleEnter(submitForm)}>
              <S.Notification>
                {i18n.t('editProfilePage.securityComponent.noteHeader')}
                <br /> <br />
                {i18n.t('editProfilePage.securityComponent.noteTextOne')}
                <br /> {i18n.t('editProfilePage.securityComponent.noteTextTwo')}
                <br />
                {i18n.t('editProfilePage.securityComponent.noteTextThree')}
              </S.Notification>
              <S.PasswordBlock>
                <S.CurrentPassword>
                  <S.Label>
                    {' '}
                    {i18n.t(
                      'editProfilePage.securityComponent.currentPassword'
                    )}
                  </S.Label>
                  <S.Input
                    type="password"
                    placeholder="******"
                    name="oldpass"
                    onChange={handleChange}
                    value={values.oldpass}
                  />
                  {changePassError && (
                    <S.ErrorMessage>
                      {i18n.t(`backend.wrongPass`)}
                    </S.ErrorMessage>
                  )}
                </S.CurrentPassword>
                <S.NewPassword>
                  <S.Label>
                    {' '}
                    {i18n.t('editProfilePage.securityComponent.newPassword')}
                  </S.Label>
                  <S.Input
                    type="password"
                    placeholder="******"
                    name="password"
                    onChange={handleChange}
                    value={values.password}
                  />
                  {errors.password && touched.password && (
                    <S.ErrorMessage>{i18n.t(errors.password)}</S.ErrorMessage>
                  )}
                </S.NewPassword>
                <S.ConfirmPassword>
                  <S.Label>
                    {' '}
                    {i18n.t(
                      'editProfilePage.securityComponent.confirmPassword'
                    )}
                  </S.Label>
                  <S.Input
                    type="password"
                    placeholder="******"
                    name="password2"
                    onChange={handleChange}
                    value={values.password2}
                  />
                  {errors.password2 && touched.password2 && (
                    <S.ErrorMessage>{i18n.t(errors.password2)}</S.ErrorMessage>
                  )}
                </S.ConfirmPassword>
              </S.PasswordBlock>
              <S.ActivateLinkContainer>
                {isActivatedLoading ? (
                  <Loader color="#00c084" size={0.5} />
                ) : isActivated ? (
                  <S.Deactivate onClick={disable2Fa}>
                    {i18n.t('editProfilePage.securityComponent.linkText2')}
                  </S.Deactivate>
                ) : (
                  <S.ActivateLink to={changeUrl('/enable-2fa')}>
                    {i18n.t('editProfilePage.securityComponent.linkText')}
                  </S.ActivateLink>
                )}
              </S.ActivateLinkContainer>
              <S.ButtonsContainer>
                <S.ConfirmButton
                  type="submit"
                  disabled={changePassLoading || !dirty}
                >
                  {changePassLoading ? (
                    <Loader color="#FFF" size={0.5} />
                  ) : (
                    i18n.t('editProfilePage.securityComponent.confirmButton')
                  )}
                </S.ConfirmButton>
              </S.ButtonsContainer>
            </Form>
          )}
        </Formik>
      </S.FormContainer>
    </S.SecurityContainer>
  )
}

export { Security }
