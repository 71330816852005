import { connect } from "react-redux";

import { MobileSortPopup as Self } from "./MobileSortPopup";
import { showSortPopup, toggleSortPopup } from "../../ClientAreaPage.actions";

const mapStateToProps = ({ clientAreaPage }) => ({
  isShown: clientAreaPage.sortPopupIsShown,
});

const mapDispatchToProps = {
  showSortPopup,
  toggleSortPopup,
};

export const MobileSortPopup = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self);
