import { VPS_PAGE_TYPES } from './VpsPage.types';

const initialState = {
  serverLocationLoading: false,
  serverLocationError: null,
  serverLocationData: [],
  serverOsLoading: false,
  serverOsError: null,
  serverOsData: [],
  serverSizeLoading: false,
  serverSizeError: null,
  serverSizeData: {},
  product: {},
  location: {},
  os: {},
  productSize: {},
  shouldFixedFooterBeVisible: true,
};

function vpsPage(state = initialState, action) {
  switch (action.type) {
    case VPS_PAGE_TYPES.GET_SERVER_LOCATION_REQUEST: {
      return {
        ...state,
        serverLocationLoading: true,
        serverLocationError: null,
        serverLocationData: [],
      };
    }
    case VPS_PAGE_TYPES.GET_SERVER_LOCATION_SUCCESS: {
      return {
        ...state,
        serverLocationLoading: false,
        serverLocationError: null,
        serverLocationData: action.serverLocationData,
      };
    }
    case VPS_PAGE_TYPES.GET_SERVER_LOCATION_FAIL: {
      return {
        ...state,
        serverLocationLoading: false,
        serverLocationError: action.error,
        serverLocationData: [],
      };
    }
    case VPS_PAGE_TYPES.GET_SERVER_OS_REQUEST: {
      return {
        ...state,
        serverOsLoading: true,
        serverOsError: null,
        serverOsData: [],
      };
    }
    case VPS_PAGE_TYPES.GET_SERVER_OS_SUCCESS: {
      return {
        ...state,
        serverOsLoading: false,
        serverOsError: null,
        serverOsData: action.serverOsData,
      };
    }
    case VPS_PAGE_TYPES.GET_SERVER_OS_FAIL: {
      return {
        ...state,
        serverOsLoading: false,
        serverOsError: action.error,
        serverOsData: [],
      };
    }
    case VPS_PAGE_TYPES.GET_SERVER_SIZE_REQUEST: {
      return {
        ...state,
        serverSizeLoading: true,
        serverSizeError: null,
        serverSizeData: [],
      };
    }
    case VPS_PAGE_TYPES.GET_SERVER_SIZE_SUCCESS: {
      return {
        ...state,
        serverSizeLoading: false,
        serverSizeError: null,
        serverSizeData: action.serverSizeData,
      };
    }
    case VPS_PAGE_TYPES.GET_SERVER_SIZE_FAIL: {
      return {
        ...state,
        serverSizeLoading: false,
        serverSizeError: action.error,
        serverSizeData: {},
      };
    }
    case VPS_PAGE_TYPES.HANDLE_FIXED_FOOTER_VISIBILITY: {
      return {
        ...state,
        shouldFixedFooterBeVisible: action.top > action.height,
      };
    }
    default:
      return state;
  }
}

export { vpsPage };
