import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'
import { ContactOwnerModal as Self } from './ContactOwnerModal'
import { showModal } from '../../../common/Modal/Modal.actions'
import { contactOwner } from './ContactOwnerModal.actions'
import { cleanRegisterError } from '../../../pages/RegistrationPage/RegistrationPage.actions'

const mapStateToProps = ({ contactOwnerModal }) => ({
  error: contactOwnerModal.error,
  loading: contactOwnerModal.loading,
})

const mapDispatchToProps = {
  showModal,
  contactOwner,
  cleanRegisterError,
}

export const ContactOwnerModal = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
)
