import { connect } from 'react-redux'

import { ClientAreaPage as Self } from './ClientAreaPage'
import {
  changeTab,
  getDomains,
  getServices,
  getTickets,
  getInvoices,
  setInitialSearchParams,
} from './ClientAreaPage.actions'
import {
  numberWithCommas,
  getUserCredit,
  setCart,
} from '../../common/App/App.actions'
import { showModal } from '../../common/Modal/Modal.actions'
import { cutTld } from '../../pages/HomePage/components/SearchAreaBlock/SearchAreaBlock.actions'

const mapStateToProps = ({ clientAreaPage, app }) => ({
  tab: clientAreaPage.tab,
  domainsData: clientAreaPage.domainsData,
  domainsLoading: clientAreaPage.domainsLoading,
  domainsTotal: clientAreaPage.domainsTotal,
  servicesData: clientAreaPage.servicesData,
  servicesLoading: clientAreaPage.servicesLoading,
  servicesTotal: clientAreaPage.servicesTotal,
  ticketsData: clientAreaPage.ticketsData,
  ticketsLoading: clientAreaPage.ticketsLoading,
  ticketsTotal: clientAreaPage.ticketsTotal,
  invoicesData: clientAreaPage.invoicesData,
  invoicesLoading: clientAreaPage.invoicesLoading,
  invoicesTotal: clientAreaPage.invoicesTotal,
  offsets: clientAreaPage.offsets,
  auth: app.auth,
  userInfo: app.userInfo.user,
  credit: app.credit,
  loading: clientAreaPage.changeProtectionLoading,
  error: clientAreaPage.changeProtectionError,
  sortOrder: clientAreaPage.sortOrder,
  sortBy: clientAreaPage.sortField,
  cart: app.cart,
  isCredit: app.userInfo.isCredit,
})

const mapDispatchToProps = {
  changeTab,
  getDomains,
  getServices,
  getTickets,
  getInvoices,
  numberWithCommas,
  showModal,
  cutTld,
  getUserCredit,
  setCart,
  setInitialSearchParams,
}

export const ClientAreaPage = connect(mapStateToProps, mapDispatchToProps)(Self)
