import domainLogo from '../../images/domainLogo.svg'
import constructorLogo from '../../images/constructorLogo.svg'
import hostingLogo from '../../images/hostingLogo.svg'
import vpsLogo from '../../images/vpsLogo.svg'
import sslLogo from '../../images/sslLogo.svg'
import supportLogo from '../../images/supportLogo.svg'
// import blogIcon from '../../images/blogIcon.svg'

export const config = [
  {
    img: domainLogo,
    to: '/domain-registration',
    translationKey: 'header.domain',
  },
  {
    img: constructorLogo,
    to: '/constructor-packages',
    translationKey: 'header.constructor',
  },
  {
    img: hostingLogo,
    to: '/hosting-packages',
    translationKey: 'header.hosting',
  },
  {
    img: vpsLogo,
    to: '/vps-packages',
    translationKey: 'header.vps',
  },
  { img: sslLogo, to: '/ssl-packages', translationKey: 'header.ssl' },
  { img: supportLogo, to: '/support', translationKey: 'header.support' },
  // {
  //   img: blogIcon,
  //   to: '/blog',
  //   translationKey: 'header.blog',
  // },
]
