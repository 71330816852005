import React, { useCallback, useEffect, useRef } from 'react'
import { changeUrl, constructCannonical } from '../../hooks/changeUrl'
import { defaultNameservers } from '../../components/Modals/NameServerModal/NameServerModal.config'
import { initialValues, yupScheme } from './DomainsPage.config'
import { config as domainsConfig } from '../DomainConfigurationPage/DomainConfigurationPage.config'
import { Formik } from 'formik'
import { QuestionsList } from '../../components/QuestionsList'
import { Helmet } from 'react-helmet'
import { Skeleton } from './Skeleton'
import { LayoutCommon } from '../../layouts/LayoutCommon'
import { LIMIT } from './DomainsPage.constants'
import { Loader } from '../../components/Loader'
import { i18n } from '../../i18n'
import { questions } from '../SupportPage/SupportPage.constants'
import { Tooltip } from '../../components/Tooltip'
import { DomainPrices } from '../DomainPricesPage'
import DiscountIcon from '../../images/discount.svg'
import qs from 'qs'

import * as S from './DomainsPage.styles'

const DomainsPage = ({
  auth,
  domain,
  setDomain,
  domainsData,
  domainsTotal,
  setDomainsData,
  domainLookup,
  domainsLoading,
  domainRequestSuccess,
  history,
  moreDomainsLoading,
  moreDomainLookup,
  offset,
  domainRequestPart2Loading,
  moreDomainRequestPart2Loading,
  addToCart,
  removeFromCart,
  toggleMobileCart,
  mobileCartIsExpanded,
  numberWithCommas,
  tlds,
  toggleUnavailableDomains,
  domainListExpanded,
  cutTld,
  showModal,
}) => {
  const formikRef = useRef(null)
  const handleSearch = useCallback(
    (domain) => {
      cutTld(domain)
      const domainSplitted = domain.split('.')
      const domainCut = domainSplitted[0]
      const params = qs.stringify(
        { searchInput: domainCut, tid: Math.round(Math.random() * 99999) },
        {
          addQueryPrefix: true,
          encode: true,
        }
      )
      domainLookup(domain)
      history.push(changeUrl(`/domain-registration${params}`))
    },
    [history, domainLookup]
  )

  const handleEnter = useCallback(
    (e, domain) => {
      if (e.key === 'Enter') {
        const params = qs.stringify(
          { searchInput: domain, tid: Math.round(Math.random() * 99999) },
          {
            addQueryPrefix: true,
            encode: true,
          }
        )
        domainLookup(domain)
        history.push(changeUrl(`/domain-registration${params}`))
      }
    },
    [history, domainLookup]
  )

  const addToList = useCallback(
    (tld) => {
      const searchResultsCopy = [...domainsData]
      const index = searchResultsCopy.findIndex((item) => item.tld === tld)
      searchResultsCopy[index].isAdded = !searchResultsCopy[index].isAdded
      searchResultsCopy[index].type = 'domain'
      if (searchResultsCopy[index].isAdded) {
        const domainConfig = domainsConfig.find((item) => item.tld === tld)
        searchResultsCopy[index].years = 1
        searchResultsCopy[index].incognito = domainConfig
          ? domainConfig.canBeIncognito
          : false
        searchResultsCopy[index].nameservers = { ...defaultNameservers }
        addToCart(searchResultsCopy[index])
      } else {
        removeFromCart(searchResultsCopy[index])
      }
      setDomainsData(searchResultsCopy)
    },
    [domainsData, setDomainsData, addToCart, removeFromCart]
  )

  const handleMoreDomains = useCallback(() => {
    moreDomainLookup(domain, offset + LIMIT * 2)
  }, [moreDomainLookup, domain, offset])

  useEffect(() => {
    if (history.location.search) {
      const params = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
        encode: true,
      })
      setDomain(params.searchInput)
      if (formikRef.current) {
        formikRef.current.setFieldValue('domain', params.searchInput)
      }
      domainLookup(params.searchInput)
    }
  }, [formikRef, history.location.search])

  const goToOrderNow = useCallback(() => {
    history.push(changeUrl('/domain-settings'))
  }, [history])

  return (
    <LayoutCommon>
      <Helmet>
        <title>{i18n.t('headers.domainsPage')}</title>
        <meta
          name="description"
          content={i18n.t('headersDescriptions.domainsPage')}
        />

        <link
          rel="alternate"
          href="https://internet.am/en/domain-registration"
          hreflang="en"
        />
        <link
          rel="alternate"
          href="https://internet.am/ru/domain-registration"
          hreflang="ru"
        />
        <link
          rel="alternate"
          href="https://internet.am/domain-registration"
          hreflang="am-AM"
        />
        <meta name="keywords" content={i18n.t('headerKeywords.domainsPage')} />
        <meta property="og:title" content={i18n.t('headers.domainsPage')} />
        <meta
          property="og:description"
          content={i18n.t('headersDescriptions.domainsPage')}
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ABC Domain LLC" />
        <meta
          property="og:image"
          content="https://internet.am/images/fb_logo.png"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={i18n.t('headers.domainsPage')} />
        <meta
          name="twitter:description"
          content={i18n.t('headersDescriptions.domainsPage')}
        />
        <meta
          name="twitter:image"
          content="https://internet.am/images/fb_logo.png"
        />
      </Helmet>
      <S.PageBody>
        <S.DomainBodyContainer>
          <S.DomainTitle>{i18n.t('domainsPage.title')}</S.DomainTitle>
          <S.DomainHeader>{i18n.t('domainsPage.header')}</S.DomainHeader>
          <Formik
            initialValues={initialValues}
            validationSchema={yupScheme}
            onSubmit={(values, { setSubmitting }) => {
              const params = qs.parse(history.location.search, {
                ignoreQueryPrefix: true,
                encode: true,
              })
              if (values.domain !== params.searchInput) {
                handleSearch(values.domain)
              }
              setSubmitting(false)
            }}
            innerRef={formikRef}
          >
            {({
              touched,
              errors,
              values,
              isSubmitting,
              handleChange,
              submitForm,
            }) => (
              <S.FormContainer>
                <S.SearchInputContainer>
                  <S.DomainSearcher
                    name="domain"
                    value={values.domain}
                    onKeyPress={() => handleEnter(submitForm)}
                    onChange={handleChange}
                    isError={touched.domain && errors.domain}
                  />
                  {touched.domain && errors.domain && (
                    <S.ErrorIconContainer className="tooltipContainer">
                      <S.ErrorIcon />
                      <Tooltip className="tooltip top right noArrow error">
                        {i18n.t(errors.domain)}
                      </Tooltip>
                    </S.ErrorIconContainer>
                  )}
                </S.SearchInputContainer>
                <S.DomainSearcherButton type="submit" disabled={isSubmitting}>
                  {i18n.t('domainsPage.searchButton')}
                </S.DomainSearcherButton>
              </S.FormContainer>
            )}
          </Formik>
          {domainsLoading ? (
            <Skeleton />
          ) : (
            domainRequestSuccess && (
              <S.MainBlock>
                <S.CartContainer
                  quantity={domainsData.filter((item) => item.isAdded).length}
                >
                  <S.ChoosedItemsContainer>
                    <S.ChoosedHeader>
                      {i18n.t('domainsPage.choosedHeader')}
                    </S.ChoosedHeader>
                    <S.ChoosedItems>
                      {domainsData.map(
                        (item) =>
                          item.isAdded && (
                            <S.Choosen key={item.id}>
                              <S.SelectedItems>
                                <S.CheckmarkFilled />
                                <S.ChoosenName>
                                  {item.domain}
                                  <span>{item.tld}</span>
                                </S.ChoosenName>
                              </S.SelectedItems>
                              <S.ChoosenPrice>
                                {numberWithCommas(
                                  auth.currency === 2
                                    ? item.premium_price_usd || item.price_usd
                                    : item.premium_price_amd || item.price
                                )}
                              </S.ChoosenPrice>
                            </S.Choosen>
                          )
                      )}
                    </S.ChoosedItems>
                    <S.Total>
                      <S.TotalText>
                        {i18n.t('domainsPage.totalText')}
                      </S.TotalText>
                      <S.TotalSum>
                        {numberWithCommas(
                          domainsData
                            .filter((item) => item.isAdded)
                            .reduce(
                              (a, b) => {
                                return {
                                  price:
                                    a.price +
                                    (auth.currency === 2
                                      ? b.premium_price_usd || b.price_usd
                                      : b.premium_price_amd || b.price),
                                }
                              },
                              { price: 0 }
                            ).price
                        )}
                        <S.TotalSumText>
                          {i18n.t('domainsPage.priceText')}
                        </S.TotalSumText>
                      </S.TotalSum>
                    </S.Total>
                  </S.ChoosedItemsContainer>
                  <S.OrderButton
                    disabled={
                      !domainsData.filter((item) => item.isAdded).length
                    }
                    onClick={goToOrderNow}
                  >
                    {i18n.t('domainsPage.orderButton')}
                  </S.OrderButton>
                </S.CartContainer>

                <S.OptionsListContainer>
                  <S.SwitchButton onClick={toggleUnavailableDomains}>
                    {!domainListExpanded
                      ? i18n.t('domainsPage.showUnavialables')
                      : i18n.t('domainsPage.hideUnavialables')}
                  </S.SwitchButton>
                  <S.Options>
                    {domainsData
                      .filter((item) =>
                        !domainListExpanded || tlds.includes(item.tld)
                          ? item.isAvailable || tlds.includes(item.tld)
                          : item
                      )
                      .map((item) => (
                        <S.List
                          key={item.tld}
                          isSelected={tlds.includes(item.tld)}
                          isLastSelected={tlds[tlds.length - 1] === item.tld}
                        >
                          {item.isPremium && item.isAvailable && (
                            <S.Premium>Premium</S.Premium>
                          )}
                          <S.ListItemTopContent
                            isSelected={tlds.includes(item.tld)}
                          >
                            {item.sale && (
                              <S.Sale>
                                <S.SaleImg src={DiscountIcon} />
                                <S.SaleImgText>{item.sale}</S.SaleImgText>
                              </S.Sale>
                            )}
                            <S.DomainName>
                              {item.domain}
                              <span>{item.tld}</span>
                            </S.DomainName>
                            <S.PriceContainer>
                              {item.old && (
                                <S.OldPrice>
                                  {numberWithCommas(item.old)}
                                </S.OldPrice>
                              )}
                              <S.NewPrice>
                                {item.isAvailable
                                  ? numberWithCommas(
                                      auth.currency === 2
                                        ? item.isPremium
                                          ? item.premium_price_usd
                                          : item.price_usd
                                        : item.isPremium
                                        ? item.premium_price_amd
                                        : item.price
                                    )
                                  : i18n.t('domainsPage.unavailable')}

                                {item.isAvailable
                                  ? i18n.t('domainsPage.priceText')
                                  : ''}
                              </S.NewPrice>
                            </S.PriceContainer>
                          </S.ListItemTopContent>
                          <S.ButtonContainer>
                            {item.isAvailable ? (
                              item.isAdded ? (
                                <S.Status onClick={() => addToList(item.tld)}>
                                  <S.StatusText>
                                    {i18n.t('domainsPage.isAdded')}
                                  </S.StatusText>
                                  <S.CheckmarkEmpty />
                                </S.Status>
                              ) : (
                                <S.AddButton
                                  onClick={() => addToList(item.tld)}
                                >
                                  {i18n.t('domainsPage.add')}
                                </S.AddButton>
                              )
                            ) : (
                              false && (
                                <S.WhoIsButton>
                                  {i18n.t('domainsPage.whois')}
                                </S.WhoIsButton>
                              )
                            )}
                          </S.ButtonContainer>
                        </S.List>
                      ))}
                    {(domainRequestPart2Loading ||
                      moreDomainRequestPart2Loading) && (
                      <Loader color="#dddddd" size={1} />
                    )}
                  </S.Options>
                  <S.ShowMore
                    alignRight={
                      domainsData.filter((item) =>
                        !domainListExpanded || tlds.includes(item.tld)
                          ? item.isAvailable || tlds.includes(item.tld)
                          : item
                      ).length ===
                      domainsTotal -
                        domainsData.filter((item) =>
                          !domainListExpanded || tlds.includes(item.tld)
                            ? !item.isAvailable || !tlds.includes(item.tld)
                            : !item
                        ).length
                    }
                  >
                    {domainsData.filter((item) =>
                      !domainListExpanded || tlds.includes(item.tld)
                        ? item.isAvailable || tlds.includes(item.tld)
                        : item
                    ).length !==
                      domainsTotal -
                        domainsData.filter((item) =>
                          !domainListExpanded ? !item.isAvailable : !item
                        ).length && (
                      <S.ShowMoreButton
                        onClick={handleMoreDomains}
                        disabled={moreDomainsLoading}
                      >
                        {moreDomainsLoading ? (
                          <Loader color="#ffffff" size={0.7} />
                        ) : (
                          i18n.t('domainsPage.showMore')
                        )}
                      </S.ShowMoreButton>
                    )}
                    <S.Showed>
                      {`${i18n.t('domainsPage.showed')} 
                      ${
                        domainsData.filter((item) =>
                          !domainListExpanded || tlds.includes(item.tld)
                            ? item.isAvailable || tlds.includes(item.tld)
                            : item
                        ).length
                      } /
                      ${
                        domainsTotal -
                        domainsData.filter((item) =>
                          !domainListExpanded ? !item.isAvailable : !item
                        ).length
                      }`}
                    </S.Showed>
                  </S.ShowMore>
                </S.OptionsListContainer>
                <S.MobileCartContainer isExpanded={mobileCartIsExpanded}>
                  <S.MobileCartInnerContainer onClick={toggleMobileCart}>
                    <S.MobileCartTitle isExpanded={mobileCartIsExpanded}>
                      {i18n.t('domainsPage.mobileChoosen')}
                      <S.Carrot isExpanded={mobileCartIsExpanded} />
                    </S.MobileCartTitle>
                    {mobileCartIsExpanded && (
                      <S.MobileCartContentWrapper>
                        <S.MobileCartContent>
                          <S.ChoosedItemsMobile>
                            {domainsData.map(
                              (item) =>
                                item.isAdded && (
                                  <S.ChoosenMobile key={item.id}>
                                    <S.SelectedItemsMobile>
                                      <S.CheckmarkFilledMobile />
                                      <S.ChoosenNameMobile>
                                        {item.domain}
                                        <span>{item.tld}</span>
                                      </S.ChoosenNameMobile>
                                    </S.SelectedItemsMobile>
                                    <S.ChoosenPrice>
                                      {numberWithCommas(
                                        auth.currency === 2
                                          ? item.price_usd
                                          : item.price
                                      )}
                                    </S.ChoosenPrice>
                                  </S.ChoosenMobile>
                                )
                            )}
                          </S.ChoosedItemsMobile>
                          <S.TotalMobile>
                            <S.TotalTextMobile>
                              {i18n.t('domainsPage.totalText')}
                            </S.TotalTextMobile>
                            <S.TotalSumMobile>
                              {numberWithCommas(
                                domainsData
                                  .filter((item) => item.isAdded)
                                  .reduce(
                                    (a, b) => {
                                      return {
                                        price:
                                          a.price +
                                          (auth.currency === 2
                                            ? b.price_usd
                                            : b.price),
                                      }
                                    },
                                    { price: 0 }
                                  ).price
                              )}
                              <S.TotalSumTextMobile>
                                {i18n.t('domainsPage.priceText')}
                              </S.TotalSumTextMobile>
                            </S.TotalSumMobile>
                          </S.TotalMobile>
                        </S.MobileCartContent>
                        <S.OrderNowButtonMobile
                          disabled={
                            !domainsData.filter((item) => item.isAdded).length
                          }
                          onClick={goToOrderNow}
                        >
                          {i18n.t('domainsPage.orderButton')}
                        </S.OrderNowButtonMobile>
                      </S.MobileCartContentWrapper>
                    )}
                  </S.MobileCartInnerContainer>
                </S.MobileCartContainer>
              </S.MainBlock>
            )
          )}
          <DomainPrices />
          <S.DomainPageContent
            dangerouslySetInnerHTML={{
              __html: i18n.t('domainsPage.content'),
            }}
          />

          <QuestionsList isInPage questions={questions.domains.list} />
        </S.DomainBodyContainer>
      </S.PageBody>
    </LayoutCommon>
  )
}

export { DomainsPage }
