import styled from 'styled-components';
import closeModal from '../../../images/closeModal.svg';
import { Form } from 'formik';

export const TransferDomainModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 1;
  z-index: 100;
  border-radius: 5px;
  position: relative;
  @media (min-width: 768px) {
    padding: 23px 42px;
  }
`;

export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-size: cover;
  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`;

export const Modal = styled.div`
  height: 100%;
  width: 310px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  outline: none;
  z-index: 100;
  @media (min-width: 360px) {
    width: 350px;
  }

  @media (min-width: 768px) {
    width: 750px;
  }
`;

export const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 24px;
    font-weight: 400;
    padding-top: 20px;
    padding-bottom: 10px;
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 22px;
  align-items: flex-start;
  justify-content: flex-start;
  &.radio {
    justify-content: center;
  }

  @media (min-width: 768px) {
    margin-top: 21px;
    text-align: right;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;
export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
export const CounterRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const Label = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 500;
  align-self: flex-start;

  &.radio {
    margin: 0px;
    margin-left: 7px;
    font-size: 10px;
    opacity: 0.38;
  }

  @media (min-width: 768px) {
    font-size: 18px;
    padding-right: 15px;
    width: 28%;
    align-self: center;
  }
`;

export const Input = styled.input`
  padding: 5px 12px;
  max-height: 30px;
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 8px;
  @media (min-width: 768px) {
    width: 72%;
    padding: 12px;
    max-height: 44px;
    font-size: 16px;
    margin-top: 0px;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: 5px 12px;
  min-height: 30px;
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  resize: vertical;
  max-height: 300px;
  margin-top: 8px;
  box-sizing: border-box;
  ${(p) => p.isError && 'border: 1px solid #DD2326;'};
  @media (min-width: 768px) {
    width: 275px;
    width: 72%;
    padding: 12px;
    font-size: 16px;
    margin-top: 0px;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
  @media (min-width: 768px) {
    margin-left: calc(28% + 10px);
  }
`;
export const Counter = styled.span`
  font-size: 12px;
  margin-top: 5px;
  color: #242e46;
  ${(p) => p.isError && 'color: #DD2326;'};
`;

export const SubmitButton = styled.button`
  background-color: #242e46;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  font-size: 12px;
  padding: 9px 30px;
  margin-top: 21px;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    padding: 9px 0px;
    font-size: 14px;
    margin-right: 70px;
    width: 130px;
    align-self: center;
  }
`;
