import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { ViewTicketModal as Self } from './ViewTicketModal';
import { showModal } from '../../../common/Modal/Modal.actions';
import {
  getTicketsInfo,
  getTicketsInfoSimple,
  replyTicket,
  closeTicket,
} from './ViewTicketModal.actions';
import { getUserIp } from '../../../common/App/App.actions';

const mapStateToProps = ({ viewTicket, app }) => ({
  data: viewTicket.ticketsData,
  loading: viewTicket.ticketsLoading,
  auth: app.auth,
  replyLoading: viewTicket.replyLoading,
  error: viewTicket.error,
  userIp: app.userIp,
});

const mapDispatchToProps = {
  showModal,
  getTicketsInfo,
  getTicketsInfoSimple,
  replyTicket,
  closeTicket,
  getUserIp,
};

export const ViewTicketModal = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
