import styled from 'styled-components'
import Select from 'react-select'

export const InvoicePageLayout = styled.div`
  background-color: #efefef;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 20px;
`

export const LinkContainer = styled.div``

export const InvoicePageContainer = styled.div`
  box-sizing: border-box;
  width: 300px;
  padding: 20px 30px;
  min-height: 300px;
  background: red;
  position: relative;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;

  @media (min-width: 500px) {
    width: 400px;
  }

  @media (min-width: 650px) {
    width: 600px;
  }

  @media (min-width: 768px) {
    width: 650px;
    padding: 45px 45px 70px 45px;
  }
  @media (min-width: 1024px) {
    width: 900px;
  }
  @media (min-width: 1280px) {
    width: 1000px;
  }
`

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 140px;
`

export const InvoiceHeader = styled.div`
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: space-between;
`

export const InvoiceHeaderFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`

export const PaymentMethodSelect = styled(Select)`
  width: 150px;
  height: 40px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    width: 200px;
  }
`

export const InvoiceHeaderForm = styled.div`
  display: flex;
  align-self: self-start;
  padding-top: ${(p) => (p.isLoading ? '20px' : '0px')};

  input[type='submit'] {
    position: relative !important;
    width: 150px !important;
    height: 40px !important;
    color: white;
    border: none;
    border-radius: 5px;
    left: unset !important;
    background-color: green;
    cursor: pointer;

    @media (min-width: 768px) {
      width: 200px !important;
    }
  }

  @media (min-width: 600px) {
    height: 50px;
    align-self: unset;
    padding-top: 0;
  }
`

export const ApplyCreditContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export const ApplyCreditAmount = styled.div`
  font-size: 14px;
  margin: 10px 0;
  font-family: sans-serif;

  @media (min-width: 768px) {
    margin: 0;
  }
`

export const ApplyCreditButton = styled.button`
  color: white;
  border: none;
  border-radius: 5px;
  background-color: green;
  cursor: pointer;
  padding: 10px 20px;
  margin-left: 10px;
`

export const LogoLink = styled.div`
  display: flex;
`

export const Logo = styled.img`
  width: 45px;
  height: 55px;

  @media (min-width: 768px) {
    width: 55px;
    height: 65px;
  }
  @media (min-width: 1280px) {
    width: 65px;
    height: 75px;
  }
`

function getColor(status) {
  switch (status) {
    case 'Cancelled':
      return 'red'
    case 'Paid':
      return '#008000'
    case 'Refunded':
      return '#107da7'
    default:
      return '#008000'
  }
}

export const StatusContainer = styled.div`
  display: flex;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  width: 170px;
  border-bottom: 35px solid ${(p) => getColor(p.status)};
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  height: 0;
  color: #fff;
  position: absolute;
  right: -45px;
  top: 56px;
  transform: rotate(45deg);

  @media (min-width: 768px) {
    width: 200px;
    border-bottom: 45px solid ${(p) => getColor(p.status)};
    border-left: 45px solid transparent;
    border-right: 45px solid transparent;
    font-size: 18px;
    right: -56px;
    top: 66px;
  }
  @media (min-width: 1280px) {
    width: 220px;
    border-bottom: 60px solid ${(p) => getColor(p.status)};
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    right: -69px;
    top: 71px;
  }
`

export const Status = styled.div`
  margin-top: 35px;
  font-size: 16px;

  @media (min-width: 768px) {
    margin-top: 45px;
    font-size: 22px;
  }
  @media (min-width: 1280px) {
    margin-top: 60px;
    font-size: 26px;
  }
`

export const InvoiceDetails = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: column;
  }
`

export const InvoiceId = styled.div`
  // text-align: center;
  font-size: 20px;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 22px;
  }
  @media (min-width: 1280px) {
    font-size: 24px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const ClientDetails = styled.div`
  order: 2;
  // padding-top: 15px;
  @media (min-width: 768px) {
    order: 1;
    text-align: left;
    width: 42%;
  }
`

export const ReceiverDetails = styled.div`
  order: 1;

  @media (min-width: 768px) {
    order: 2;
    text-align: right;
    width: 58%;
  }
`
export const Header = styled.div`
  font-weight: 700;
  font-size: 16px;
  padding: 3px 0px;
`

export const Row = styled.div`
  font-size: 14px;
  width: 100%;
  @media (min-width: 768px) {
    font-size: 16px;
  }
`

export const Date = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
`

export const InvoiceDescription = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  margin-top: 50px;
`

export const DescriptionHeader = styled.div`
  color: #333;
  background-color: #f5f5f5;
  font-size: 16px;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-weight: 700;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`
export const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #ddd;
`

export const ProductDetailsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const DescriptionTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  padding: 5px;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`

export const SumTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  padding: 5px;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`
export const ProductDescription = styled.div`
  font-size: 14px;
  padding: 5px;
  width: 80%;
  font-family: sans-serif;
  @media (min-width: 768px) {
    font-size: 14px;
    width: 90%;
  }
  @media (min-width: 1280px) {
    font-size: 16px;
  }
`
export const ProductValue = styled.div`
  font-size: 14px;
  padding: 5px;
  width: 20%;
  font-family: ${(p) => (p.isAmd ? 'Mardoto' : 'Roboto')};
  text-align: right;
  @media (min-width: 768px) {
    font-size: 14px;
    width: 10%;
  }
  @media (min-width: 1280px) {
    font-size: 16px;
  }
`
export const PaymentDetails = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f8f8f8;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #ddd;
`
export const Total = styled.div`
  font-weight: 700;
  font-size: 14px;
  padding: 5px;
  width: 80%;
  @media (min-width: 768px) {
    font-size: 16px;
  }
`

export const Sum = styled.div`
  font-size: 14px;
  padding: 5px;
  font-family: ${(p) => (p.isAmd ? 'Mardoto' : 'Roboto')};
  width: 20%;
  text-align: right;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 1280px) {
    font-size: 16px;
  }
`
export const EhdmDetails = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #ddd;
`

export const EHDM = styled.a`
  font-size: 14px;
  padding: 5px;
  font-family: ${(p) => (p.isAmd ? 'Mardoto' : 'Roboto')};
  width: 20%;
  text-align: left;
  text-decoration: none;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 1280px) {
    font-size: 16px;
  }
`

export const Note = styled.div`
  font-size: 16px;
  padding-top: 20px;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  margin-top: 15px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding: 10px 15px;
    margin-top: 0;
    box-sizing: border-box;
  }
`

export const InfoDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  align-items: center;
  border-bottom: 1px solid #ddd;
  align-items: start;
  width: 150px;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  padding: 10px 15px;
  justify-content: space-around;

  @media (min-width: 768px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    text-align: center;
  }
`

export const InfoDetail = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  font-size: 14px;

  @media (min-width: 768px) {
    height: unset;
    width: 150px;
    text-align: center;
    margin-bottom: 0;
  }
`

export const DesktopInfoWrapper = styled.div`
  display: none;

  @media (min-width: 768px) {
    border: 1px solid #ddd;
    margin-top: 10px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
`

export const MobileInfoWrapper = styled.div`
  // border: 1px solid #ddd;
  margin-top: 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: none;
  }
`

export const InfoHeader = styled.div`
  font-weight: 700;
  font-size: 16px;
  height: 40px;
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  :not(:first-child) {
    border-top: 1px solid #ccc;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    text-align: center;
    width: 150px;
    :not(:first-child) {
      border-top: none;
    }
    padding: 0;
  }
`

export const InfoDate = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid #ddd;
`

export const ResponseInfo = styled.div`
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  width: 300px;
  justify-content: space-around;
  align-items: center;

  @media (min-width: 768px) {
    justify-content: space-between;
  }
`

export const PrintButton = styled.button`
  width: 100px;
  height: 30px;
  background: #fff;
  font-weight: 500;
  border: 1px solid black;
  border-radius: 3px;
  padding: 5px 10px;
  outline: none;
  margin-top: 10px;
`
