import styled from 'styled-components'
import closeModal from '../../../images/closeModal.svg'
import registrationImage from '../../../images/registrationSuccessImage.svg'

export const PrivateKeyModal = styled.div`
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 40px;
`
export const Modal = styled.div`
  @media (max-width: 385px) {
    width: 300px;
  }
  width: 800px;
  height: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  outline: none;
  z-index: 100;
  background: white;
`

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  margin: 20px auto;
  color: black;
`

export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 14px;
  height: 14px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-size: cover;

  @media (min - width: 768px) {
    width: 14px;
    height: 14px;
  }
`

export const Title = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
`

export const Image = styled.div`
  background-image: url(${registrationImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 177px;
  width: 219px;
  margin-top: 34px;
`

export const PrivateKeyInput = styled.textarea`
  width: 100%;
  height: 400px;
  overflow-y: auto;
  resize: none;
  outline: none;
  padding: 10px;
  border: none;
  font-size: 15px;
  box-sizing: border-box;

  border: 1px solid #d4d4d4;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    white-space: pre-wrap;
    position: relative;
    width: 220px;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    white-space: pre-line;
    position: relative;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    white-space: pre-line;
    position: relative;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    white-space: pre-line;
    position: relative;
  }
`
