import React, { useCallback, createRef, useMemo, useEffect } from 'react';
import { Formik } from 'formik';

import * as S from './TwoFactorAuthModal.styles';
import { i18n } from '../../../i18n';
import { Loader } from '../../Loader';
import { initialValues } from './TwoFactorAuthModal.config';

import { changeUrl } from '../../../hooks/changeUrl';

export const TwoFactorAuthModal = ({
  history,
  loading,
  hideModal,
  login,
  showModal,
  email,
  username,
  verify2FA,
  goTo,
}) => {
  const refs = useMemo(
    () => Array.from({ length: 6 }).map(() => createRef()),
    []
  );

  useEffect(() => {
    if (refs[0] && refs[0].current) {
      refs[0].current.focus();
    }
  }, []);

  const handleEnter = useCallback((e, submitForm) => {
    if (e.key === 'Enter') {
      submitForm();
    }
  }, []);

  const handleChangeWithTabs = useCallback(
    (e, handleChange, i) => {
      if (e.target.value && e.target.value >= 0 && e.target.value <= 9) {
        handleChange(e);
        if (refs[i + 1] && refs[i + 1].current) {
          refs[i + 1].current.focus();
        }
      }
    },
    [refs]
  );
  const handleBackspace = useCallback(
    (e, handleChange, setFieldValue, i, values) => {
      if (e.key === 'Backspace' && i !== 0) {
        if (i === 5 && refs[5].current.value) {
          setFieldValue(`number${i + 1}`, '');
        } else {
          refs[i - 1].current.focus();
          setFieldValue(`number${i}`, '');
        }
        handleChange(e);
      }
    },
    [refs]
  );

  return (
    <S.Modal onClick={(e) => e.stopPropagation()}>
      <S.AuthModalContainer>
        <S.ModalTitle>{i18n.t('authModal.title')}</S.ModalTitle>
        <S.ModalHeader>{i18n.t('authModal.header')}</S.ModalHeader>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            const token = Object.values(values).join('');
            await verify2FA(
              token,
              email,
              () => {
                if (goTo) {
                  history.push(changeUrl(goTo));
                } else {
                  history.push(changeUrl('/client-area'));
                }
                hideModal();
              },
              username
            );
            setSubmitting(false);
          }}
        >
          {({
            touched,
            errors,
            values,
            isSubmitting,
            handleChange,
            submitForm,
            setFieldValue,
          }) => (
            <S.StyledForm onKeyPress={() => handleEnter(submitForm)}>
              <S.InputContainer>
                <S.Input
                  innerRef={refs[0]}
                  onChange={(e) => handleChangeWithTabs(e, handleChange, 0)}
                  onKeyDown={(e) =>
                    handleBackspace(e, handleChange, setFieldValue, 0)
                  }
                  placeholder=" "
                  value={values.number1}
                  name="number1"
                  maxlength="1"
                />
                <S.Input
                  innerRef={refs[1]}
                  onChange={(e) => handleChangeWithTabs(e, handleChange, 1)}
                  onKeyDown={(e) =>
                    handleBackspace(e, handleChange, setFieldValue, 1)
                  }
                  placeholder=" "
                  value={values.number2}
                  name="number2"
                  maxlength="1"
                />
                <S.Input
                  innerRef={refs[2]}
                  onChange={(e) => handleChangeWithTabs(e, handleChange, 2)}
                  onKeyDown={(e) =>
                    handleBackspace(e, handleChange, setFieldValue, 2)
                  }
                  placeholder=" "
                  value={values.number3}
                  name="number3"
                  maxlength="1"
                />
                <S.Input
                  innerRef={refs[3]}
                  onChange={(e) => handleChangeWithTabs(e, handleChange, 3)}
                  onKeyDown={(e) =>
                    handleBackspace(e, handleChange, setFieldValue, 3)
                  }
                  placeholder=" "
                  value={values.number}
                  name="number4"
                  maxlength="1"
                />
                <S.Input
                  innerRef={refs[4]}
                  onChange={(e) => handleChangeWithTabs(e, handleChange, 4)}
                  onKeyDown={(e) =>
                    handleBackspace(e, handleChange, setFieldValue, 4)
                  }
                  placeholder=" "
                  value={values.number}
                  name="number5"
                  maxlength="1"
                />
                <S.Input
                  innerRef={refs[5]}
                  onChange={(e) => handleChangeWithTabs(e, handleChange, 5)}
                  onKeyDown={(e) =>
                    handleBackspace(e, handleChange, setFieldValue, 5)
                  }
                  placeholder=" "
                  value={values.number}
                  name="number6"
                  maxlength="1"
                />
              </S.InputContainer>
              <S.ConfirmButton disabled={isSubmitting}>
                {loading ? (
                  <Loader color="#FFF" size={0.5} />
                ) : (
                  i18n.t('authModal.confirmButton')
                )}
              </S.ConfirmButton>
              <S.CancelButton onClick={hideModal}>
                {i18n.t('authModal.cancelButton')}
              </S.CancelButton>
            </S.StyledForm>
          )}
        </Formik>
        <S.CloseModalButton onClick={hideModal} />
      </S.AuthModalContainer>
    </S.Modal>
  );
};
