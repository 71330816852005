import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import App from './App';

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;

const render = Component => {
  renderMethod(<Component />, document.getElementById('root'));
};

window.onload = () => {
  render(App);
};
