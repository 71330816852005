import * as Yup from 'yup';

export const initialValues = {
  email: '',
};

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || 'Two strings should be equal',
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref);
    },
  });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);

export const yupScheme = Yup.object().shape({
  email: Yup.string()
    .email('validationFields.invalidTyping')
    .required('validationFields.email'),
});
