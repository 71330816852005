import { Security as Self } from './Security';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import {
  changePass,
  getActivateStatus,
  disableActivateLink,
} from './Security.actions';
import { showModal } from '../../../../common/Modal/Modal.actions';
import { logout } from '../../../../common/App/App.actions';

const mapStateToProps = ({ app, security }) => ({
  auth: app.auth,
  changePassLoading: security.changePassLoading,
  changePassError: security.changePassError,
  changePassSuccess: security.changePassSuccess,
  isActivated: security.isActivated,
  isActivatedError: security.isActivatedError,
  isActivatedLoading: security.isActivatedLoading,
  disableActiveError: security.disableActiveError,
  token: security.token,
});

const mapDispatchToProps = {
  changePass,
  showModal,
  getActivateStatus,
  disableActivateLink,
  logout,
};

export const Security = withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Self))
);
