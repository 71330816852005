import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const NavigationContainer = styled.div`
  width: 100%;
  max-width: 800px;
`

export const NavigationList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 1280px) {
    flex-direction: row;
    align-items: center;
  }
`

export const ListItem = styled.li`
  &.LanguageItem {
    display: none;
    @media (min-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (min-width: 1280px) {
      display: none;
    }
  }
  @media (min-width: 768px) {
    display: ${(p) => (p.hideOnDesktop ? 'none' : 'block')};
  }
`

export const LanguageLogo = styled.img`
  width: 28px;
  height: 28px;
`

export const LanguageLogoLink = styled.a`
  display: flex;
  align-items: center;
`

export const InlineLink = styled(Link)`
  display: flex;
  text-align: center;
  outline: none;
  padding: 28px;
  text-decoration: none;
  color: black;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
  }
`
export const Blog = styled.a`
  display: flex;
  text-align: center;
  outline: none;
  padding: 28px;
  text-decoration: none;
  color: black;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
  }
`
export const LogoutButton = styled.button``

export const PayInvoice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Text = styled.div`
  width: 120px;
`

export const InvoiceId = styled.div`
  text-decoration: underline;
  cursor: pointer;
`
