import * as Yup from 'yup'

const config = [
  { id: 'ns1', name: 'nameServerModal.n1', placeholder: 'ns1.internet.am' },
  { id: 'ns2', name: 'nameServerModal.n2', placeholder: 'ns2.internet.am' },
  { id: 'ns3', name: 'nameServerModal.n3', placeholder: 'ns3.internet.am' },
  { id: 'ns4', name: 'nameServerModal.n4', placeholder: 'ns4.internet.am' },
  { id: 'ns5', name: 'nameServerModal.n5', placeholder: 'ns5.internet.am' },
]

const defaultNameservers = {
  ns1: 'ns1.internet.am',
  ns2: 'ns2.internet.am',
  ns3: '',
  ns4: '',
  ns5: '',
}

const yupScheme = Yup.object().shape({
  ns1: Yup.string()
    .matches(
      /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      'validationFields.ns1Url'
    )
    .required('validationFields.ns1Required'),
  ns2: Yup.string()
    .matches(
      /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      'validationFields.ns2Url'
    )
    .required('validationFields.ns2Required'),
  ns3: Yup.string()
    .nullable()
    .matches(
      /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      'validationFields.ns3Url'
    ),
  ns4: Yup.string()
    .nullable()
    .matches(
      /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      'validationFields.ns4Url'
    ),
  ns5: Yup.string()
    .nullable()
    .matches(
      /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      'validationFields.ns5Url'
    ),
})

export { config, defaultNameservers, yupScheme }
