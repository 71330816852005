import { RESET_PASSWORD_PAGE_TYPES } from './ResetPasswordPage.types';

const initialState = {
  data: {},
  loading: true,
  error: null,
  setPasswordData: {},
  setPasswordLoading: false,
  setPasswordError: null,
};

function resetPasswordPage(state = initialState, action) {
  switch (action.type) {
    case RESET_PASSWORD_PAGE_TYPES.VERIFY_HASH_REQUEST:
      return {
        ...state,
        data: {},
        loading: true,
        error: null,
      };
    case RESET_PASSWORD_PAGE_TYPES.VERIFY_HASH_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null,
      };
    case RESET_PASSWORD_PAGE_TYPES.VERIFY_HASH_FAIL:
      return {
        ...state,
        data: {},
        loading: false,
        error: action.error,
      };
    case RESET_PASSWORD_PAGE_TYPES.SET_PASSWORD_REQUEST:
      return {
        ...state,
        setPasswordData: {},
        setPasswordLoading: true,
        setPasswordError: null,
      };
    case RESET_PASSWORD_PAGE_TYPES.SET_PASSWORD_SUCCESS:
      return {
        ...state,
        setPasswordData: action.data,
        setPasswordLoading: false,
        setPasswordError: null,
      };
    case RESET_PASSWORD_PAGE_TYPES.SET_PASSWORD_FAIL:
      return {
        ...state,
        setPasswordData: {},
        setPasswordLoading: false,
        setPasswordError: action.error,
      };
    default:
      return state;
  }
}

export { resetPasswordPage };
