import * as Yup from 'yup'
import { i18n } from '../../../i18n'

export const getInitialValues = (
  initialValues,
  countriesData,
  firstStepSslData
) => {
  return {
    firstname: initialValues?.csr_configs?.firstname || '',
    lastname: initialValues?.csr_configs?.lastname || '',
    orgname: initialValues?.csr_configs?.orgname || '',
    jobtitle: initialValues?.csr_configs?.jobtitle || '',
    email: initialValues?.csr_configs?.email || '',
    address1: initialValues?.csr_configs?.address1 || '',
    address2: initialValues?.csr_configs?.address2 || '',
    city: initialValues?.csr_configs?.city || '',
    state: initialValues?.csr_configs?.state || '',
    postcode: initialValues?.csr_configs?.postcode || '',
    country: {
      value: initialValues?.csr_configs?.country || 'AM',
      label:
        countriesData?.find(
          (i) => i.value === initialValues?.csr_configs?.country
        )?.label || 'Armenia (AM)',
    },
    phonenumber: initialValues?.csr_configs?.phonenumber || '',
    ordertype: initialValues?.csr_configs?.order_type || '',
    csr: initialValues?.csr_configs?.csr || '',
    privateKey: initialValues?.csr_configs?.private_key || '',
    ...(firstStepSslData &&
      firstStepSslData.reduce((acc, cur) => {
        if (cur.key === 'org_country') {
          acc[cur.key] = {
            value: cur.description || 'Armenia',
            label:
              countriesData?.find((i) => i.value === cur.description)?.label ||
              'Armenia',
          }
          return acc
        }
        acc[cur.key] = initialValues?.csr_configs?.fields[cur.key] || ''

        return acc
      }, {})),
  }
}

const validationTypes = {
  text: 'string',
  dropdown: 'string',
  yesno: 'boolean',
  password: 'string',
  textarea: 'string',
}

const validations = {
  ordertype: Yup.string().required('validationFields.firstname'),
  csr: Yup.string().required('validationFields.firstname'),
  firstname: Yup.string()
    .min(2, 'validationFields.firstnameMin')
    .max(72, 'validationFields.firstnameMax')
    .matches(/^([a-zA-Z0-9\s\-.]){1,40}$/, 'sslConfiguration.invalidTyping')
    .required('validationFields.firstname'),
  lastname: Yup.string()
    .min(2, 'validationFields.lastnameMin')
    .max(72, 'validationFields.lastnameMax')
    .matches(/^([a-zA-Z0-9\s\-.]){1,40}$/, 'sslConfiguration.invalidTyping')
    .required('validationFields.firstname'),
  orgname: Yup.string(),
  jobtitle: Yup.string(),
  email: Yup.string()
    .email('validationFields.invalidTyping')
    .matches(/^[^']*$/, 'sslConfiguration.invalidTyping')
    .required('validationFields.firstname'),
  address1: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-,."/]+$/, 'sslConfiguration.invalidTyping')
    .required('validationFields.firstname'),
  address2: Yup.string().matches(
    /^[a-zA-Z0-9\s\-,."/]+$/,
    'sslConfiguration.invalidTyping'
  ),
  city: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-`."]+$/, 'sslConfiguration.invalidTyping')
    .required('validationFields.firstname'),
  state: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-`."]+$/, 'sslConfiguration.invalidTyping')
    .required('validationFields.firstname'),

  postcode: Yup.string().matches(
    /^([a-zA-Z0-9\s\-.]){1,40}$/,
    'sslConfiguration.invalidTyping'
  ),
  country: Yup.object().shape({
    value: Yup.string()
      .required('validationFields.firstname')
      .max(2, 'validationFields.countryMax')
      .min(2, 'validationFields.countryMax'),
    label: Yup.string(),
  }),

  phonenumber: Yup.string()
    .min(9, 'validationFields.phonenumberMin')
    .max(20, 'validationFields.phonenumberMax')
    .required('validationFields.phonenumber'),
  privateKey: Yup.string(),
}

export const getYupScheme = (firstStepSslData) =>
  Yup.object().shape({
    ...validations,
    ...firstStepSslData?.reduce((acc, cur) => {
      if (cur.key == 'org_lei') {
        acc[cur.key] = Yup[validationTypes[cur.type]]().max(
          20,
          i18n.t('sslConfiguration.orgFields.leiCodeError')
        )

        return acc
      }
      if (cur.key == 'org_duns') {
        acc[cur.key] = Yup[validationTypes[cur.type]]().matches(
          /^\d{9}$/,
          i18n.t('sslConfiguration.orgFields.orgDunsError')
        )

        return acc
      }
      if (cur.key === 'org_country') {
        Yup.object().shape({
          value: Yup[validationTypes[cur.type]]().required(
            'validationFields.firstname'
          ),
          label: Yup[validationTypes[cur.type]],
        })
        return acc
      }

      acc[cur.key] =
        cur.required &&
        Yup[validationTypes[cur.type]]()
          .matches(
            /^([a-zA-Z0-9\s\-.]){1,40}$/,
            'sslConfiguration.invalidTyping'
          )
          .required('validationFields.firstname')

      return acc
    }, {}),
  })
