import React, { useEffect } from 'react'

import { NavBar } from './NavBar'
import { RightNavBar } from './RightNavBar'
import * as S from './Header.styles'
import logo from '../../images/logo.svg'
import openMenu from '../../images/openMenu.svg'
import { Cart } from './Cart'
import { LanguagesPopup } from './LanguagesPopup'
import { AccountPopup } from './AccountPopup'
import { MobileMenu } from './MobileMenu'

import { changeUrl } from '../../hooks/changeUrl'

const Header = ({
  history,
  dimensions,
  toggleMenu,
  transparent,
  shouldBeOpaque,
  setOpaque,
}) => {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    setOpaque(window.scrollY < 50 ? false : true)
  }

  return (
    <S.HeaderSideFiller transparent={!shouldBeOpaque && transparent}>
      <S.HeaderContainer transparent={!shouldBeOpaque && transparent}>
        <Cart />
        <AccountPopup history={history} />
        <LanguagesPopup isDesktop />
        <S.DesktopView>
          <S.LogoLink to={changeUrl('/')}>
            <S.Logo
              src={logo}
              alt="Internet.am logo"
              title="Internet.am logo image"
            />
          </S.LogoLink>
          <NavBar />
          <RightNavBar />
        </S.DesktopView>
        <S.MobileView>
          <S.LeftPart>
            <S.MenuButton onClick={toggleMenu}>
              <S.MenuIcon
                src={openMenu}
                alt="Open menu image"
                title="Open menu image"
              />
            </S.MenuButton>
          </S.LeftPart>
          <S.LogoLink to={changeUrl('/')}>
            <S.Logo
              src={logo}
              alt="Internet.am logo"
              title="Internet.am logo image"
            />
          </S.LogoLink>
          <RightNavBar history={history} />
        </S.MobileView>
        <MobileMenu history={history} />
      </S.HeaderContainer>
    </S.HeaderSideFiller>
  )
}
export { Header }
