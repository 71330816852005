import React from 'react';
import { decode } from 'html-entities';

import * as S from './Contacts.styles';
import { i18n } from '../../../../i18n';
import { Loader } from '../../../../components/Loader';
import { AddContactModal } from '../../../../components/Modals/AddContactModal';
import { ConfirmModal } from '../../../../components/Modals/ConfirmModal';

const Contacts = ({ history, loading, showModal, contacts, removeContact }) => {
  return (
    <S.ContactsContainer>
      <S.FormContainer>
        {contacts.map((item) => (
          <S.Contact key={item.id}>
            <S.Fullname>
              <S.FirstName>{decode(item.firstname)}</S.FirstName>
              <S.LastName>{decode(item.lastname)}</S.LastName>
            </S.Fullname>
            <S.IconContainer>
              <S.SettingsIcon
                onClick={() =>
                  showModal(AddContactModal, {
                    largeModal: true,
                    contact: item,
                  })
                }
              />

              <S.DeleteIcon
                onClick={() =>
                  showModal(ConfirmModal, {
                    onSuccessFn: () => removeContact(item.id),
                    modalType: 'danger',
                    successBtnTextKey: 'cart.modalDeleteBtn',
                    subheaderKey: 'cart.modalDeleteSubtitle',
                  })
                }
              />
            </S.IconContainer>
          </S.Contact>
        ))}
        <S.AddContactButton
          onClick={() =>
            showModal(AddContactModal, { largeModal: true, isNewContact: true })
          }
        >
          {loading ? (
            <Loader color="#FFF" size={0.5} />
          ) : (
            i18n.t('editProfilePage.contactComponent.addContact')
          )}
        </S.AddContactButton>
      </S.FormContainer>
    </S.ContactsContainer>
  );
};
export { Contacts };
