import FetchApi from '../../../../helpers/FetchApi'
import { PERSONAL_DETAILS_TYPES } from './PersonalDetails.types'
import { setAuthData } from '../../../../common/App/App.actions'
import { countries } from '../../../../constants/countries'

export const getUserInfo = () => async (dispatch) => {
  try {
    dispatch({
      type: PERSONAL_DETAILS_TYPES.GET_USER_INFO_REQUEST,
    })

    const req = await FetchApi.get('users/info')
    dispatch({
      type: PERSONAL_DETAILS_TYPES.GET_USER_INFO_SUCCESS,
      info: req.data.user,
    })
  } catch (e) {
    dispatch({
      type: PERSONAL_DETAILS_TYPES.GET_USER_INFO_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    })
  }
}

export const updateUserInfo =
  (values, onSuccess) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PERSONAL_DETAILS_TYPES.UPDATE_USER_INFO_REQUEST,
      })

      const req = await FetchApi.post('users/updateCustomer', values)

      dispatch({
        type: PERSONAL_DETAILS_TYPES.UPDATE_USER_INFO_SUCCESS,
        values,
      })

      const { auth } = getState().app

      const country = countries.find((item) => item.value === values.country)

      const newAuth = {
        ...auth,
        address: `${values.city}, ${country.label}, ${values.address1}`,
      }

      dispatch(setAuthData(newAuth))
      FetchApi.setToken(
        newAuth.token,
        newAuth.email,
        newAuth.fullname,
        newAuth.userId,
        newAuth.address,
        newAuth.currency,
        newAuth.creditRecharge,
        newAuth.legalStatus,
        newAuth.disableCode
      )

      if (onSuccess) {
        onSuccess()
      }
    } catch (e) {
      dispatch({
        type: PERSONAL_DETAILS_TYPES.UPDATE_USER_INFO_FAIL,
        error: e.data ? e.data.error || e.data.validation_error : e.message,
      })
    }
  }
