import { connect } from 'react-redux';

import { RegistrationConfirmModal as Self } from './RegistrationConfirmModal';
import { showModal } from '../../../common/Modal/Modal.actions';

const mapDispatchToProps = {
  showModal,
};

export const RegistrationConfirmModal = connect(
  undefined,
  mapDispatchToProps
)(Self);
