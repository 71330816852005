import styled from 'styled-components';
import * as fontWeight from '../../assets/styles/constants/fontWeight';
import Container from '../../components/Container/Container';
import checkmarkFilled from '../../images/checkmark-orange-filled.svg';
import InputMask from 'react-input-mask';
import { Field } from 'formik';

export const PageBody = styled.div`
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 75px 20px;
  @media (min-width: 768px) {
    padding: 75px 70px;
  }
`;

export const DateInput = styled(InputMask)`
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  height: 44px;
  font-size: 16px;
  padding: 0px 10px;
  ::placeholder {
    font-size: 14px;
  }
`;

export const OrderNowBodyContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OrderNowTitle = styled.div`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 24px;
  font-style: normal;
  line-height: 29px;
  text-align: center;
  color: #484857;
  @media (min-width: 1280px) {
    font-size: 28px;
    line-height: 29px;
  }
`;

export const OrderNowHeader = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #737373;
  margin-top: 20px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  @media (min-width: 768px) {
    max-width: 650px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;
export const MainBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`;
export const OrderNowContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  width: 100%;
  align-items: center;
  margin-top: 65px;
`;
export const HostingName = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #242e46;
  padding-top: 20px;
  // margin-bottom: 50px;
`;
export const SelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  @media (min-width: 1280px) {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 1280px) {
    flex-direction: column;
    width: unset;
  }
`;

export const OtherInfoWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 16px;
  justify-content: space-around;
`;

export const RuWrapper = styled.div`
  display: ${(p) => (p.hidden ? 'none' : 'flex')};
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  // height: 127px;

  @media (min-width: 1280px) {
    width: 100%;
  }
`;

export const IncognitoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 26px;
  @media (min-width: 1280px) {
    margin-bottom: 0px;
    margin-top: 40px;
  }
`;

export const CheckmarkNotFilled = styled.button`
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #dc773c;
`;
export const CheckmarkFilled = styled.button`
  width: 18px;
  height: 18px;
  background-image: url(${checkmarkFilled});
  background-repeat: no-repeat;
  background-size: cover;
  border: #dc773c;
`;

export const IncognitoHeader = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #242e46;
  padding-left: 14px;
`;

export const Select = styled.select`
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 44px;
  font-size: 18px;
`;

export const Option = styled.option`
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  opacity: 0.87;
  width: 100%;
`;

export const SubLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-top: 8px;
  align-self: flex-start;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;

  @media (min-width: 768px) {
  }

  @media (min-width: 1280px) {
    max-width: unset;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

export const ButtonInnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // margin-top: 20px;
  gap: 10px;

  @media (min-width: 970px) {
    flex-direction: row;
  }
  @media (min-width: 1280px) {
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
    width: 100%;
  }
`;

export const Button = styled.button`
  @media (max-width: 450px) {
    margin: 0 8px;
    width: 250px;
  }
  width: 410px;
  height: 40px;
  background-color: rgb(36, 46, 70);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(p) => (p.isChecked ? 'none' : '1px solid #d4d4d4')};
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;

  @media (min-width: 768px) {
    width: 410px;
    height: 44px;
    font-size: 18px;

    &:hover {
      border-color: ${(p) => (p.isChecked ? 'none' : '#DC773C')};
    }
  }
`;

export const WarningText = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #242e46;
  margin-top: 40px;
  padding-left: 70px;
  padding-right: 70px;
  @media (min-width: 1280px) {
    padding-left: 0px;
    padding-right: 0px;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

export const WarningLink = styled.a`
  font-weight: normal;
  font-size: 14px;
  text-decoration: none;
  color: #3f51b5;
  margin-left: 5px;
  @media (min-width: 1280px) {
    font-size: 16px;
  }
`;

export const NextButton = styled.button`
  background: ${(p) => (p.disabled ? '#888e9b' : '#00c084')};
  border-radius: 5px;
  font-weight: ${fontWeight.MEDIUM};
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 11px 60px;
  border: 0;
  opacity: 0.87;
  text-align: center;
  margin-top: 28px;
  align-self: center;
  margin-top: 50px;
  transition: 0.3s;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  margin-bottom: 20px;
  cursor: ${(p) => (p.disabled ? 'initial' : 'pointer')};
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }

  @media (min-width: 768px) {
    margin-bottom: 60px;
  }

  @media (min-width: 1280px) {
    padding: 11px 90px;
    align-self: center;
    margin-top: 50px;
    font-size: 18px;
    line-height: 21px;
  }
`;

export const Error = styled.div`
  color: red;
  font-size: 12px;
`;

export const CorrectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  width: 90%;

  @media (min-width: 768px) {
    width: 90%;
  }
  @media (min-width: 1280px) {
    width: 410px;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
  ${(p) => p.spaced && 'margin-top: 20px;'};
`;

//..........................

export const PageTitle = styled.h1`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 24px;
  color: #484857;
`;

export const RegistrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 40px 20px;

  @media (min-width: 768px) {
    padding: 130px 230px;
  }

  @media (min-width: 1280px) {
    padding: 70px 230px 130px 230px;
  }
`;

export const Text = styled.div`
  line-height: 14px;
  font-size: 12px;
  color: #484857;

  @media (min-width: 768px) {
    margin-top: 14px;
    font-size: 14px;
  }

  &.terms {
    width: 922px;
    text-align: unset;
    line-height: 19px;
    font-size: 12px;
    width: 100%;

    @media (min-width: 768px) {
      width: 643px;
    }

    @media (min-width: 1280px) {
      font-size: 16px;
      width: 922px;
    }
  }

  > span {
    font-weight: bold;
  }
`;

export const InformationText = styled(Text)`
  width: 100%;
  text-align: center;
  font-size: 12px;
  margin-top: 26px;

  @media (min-width: 768px) {
    width: 690px;
    font-size: 14px;
    line-height: 16px;
  }

  @media (min-width: 1280px) {
    width: 798px;
    margin-top: 29px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;

  @media (min-width: 1280px) {
  }
`;

export const SwitchButton = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => (p.isChecked ? '#dc773c' : '#fff')};
  color: ${(p) => (p.isChecked ? 'white' : 'black')};
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  border: ${(p) => (p.isChecked ? 'none' : '1px solid #d4d4d4')};
  border-radius: 5px;

  @media (min-width: 768px) {
    width: 130px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  @media (min-width: 768px) {
    padding: 0;
  }

  @media (min-width: 1280px) {
    // margin-top: 50px;
  }
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  width: 100%;
  align-items: center;
  margin-top: 65px;

  @media (min-width: 768px) {
    border: none;
    padding-bottom: 0px;
    margin-top: 40px;
  }

  &:last-child {
    border: none;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    padding-bottom: 32px;
  }
`;

export const Label = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 17px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const Input = styled(Field)`
  width: auto;
  padding: 12px;
  max-height: 44px;
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;

  &.radio {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    -webkit-appearance: none;
    border: 1px solid #d4d4d4;
    padding: 0px;
    cursor: pointer;

    &:checked {
      border-color: #dc773c;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      margin: 20% auto;
      border-radius: 50%;
      left: 4px;
      top: 0px;
      background: #d4d4d4;
    }

    &:checked:before {
      background: #dc773c;
    }
  }
`;
