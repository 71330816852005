import React from "react";

export default (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.87">
      <path
        opacity="0.87"
        d="M6.00009 9.32852L6.33842 8.97853L6.33842 17.8334C6.33842 18.1428 6.46133 18.4395 6.68012 18.6583C6.89891 18.8771 7.19565 19 7.50506 19C7.81447 19 8.11121 18.8771 8.33 18.6583C8.54879 18.4395 8.6717 18.1428 8.6717 17.8334L8.6717 8.97853L9.01003 9.32852C9.22971 9.5482 9.52767 9.67162 9.83835 9.67162C10.149 9.67162 10.447 9.5482 10.6667 9.32852C10.8863 9.10884 11.0098 8.81088 11.0098 8.5002C11.0098 8.18952 10.8863 7.89157 10.6667 7.67189L8.33338 5.3386C8.22243 5.23239 8.09159 5.14913 7.94838 5.0936C7.80874 5.03188 7.65774 5 7.50506 5C7.35238 5 7.20138 5.03188 7.06174 5.0936C6.91853 5.14913 6.78769 5.23239 6.67674 5.3386L4.34345 7.67189C4.23468 7.78066 4.14839 7.9098 4.08952 8.05192C4.03065 8.19404 4.00036 8.34637 4.00036 8.5002C4.00036 8.65404 4.03065 8.80636 4.08952 8.94849C4.14839 9.09061 4.23468 9.21974 4.34345 9.32852C4.45223 9.4373 4.58137 9.52358 4.72349 9.58245C4.86561 9.64132 5.01794 9.67162 5.17177 9.67162C5.3256 9.67162 5.47793 9.64132 5.62005 9.58245C5.76218 9.52358 5.89131 9.4373 6.00009 9.32852Z"
        fill={props.fillcolor}
      />
      <path
        d="M14.7891 14.2617V13.3125H19.0547V13.9844L16.2539 18.0547H19.1172V19H14.7891V18.3125L17.6016 14.2617H14.7891Z"
        fill={props.fillcolor}
      />
      <path
        d="M14.5312 10L16.6484 4.3125H17.7344L19.8633 10H18.6133L18.2227 8.82812H16.1641L15.7734 10H14.5312ZM16.4805 7.87891H17.9062L17.1953 5.74219L16.4805 7.87891Z"
        fill={props.fillcolor}
      />
    </g>
  </svg>
);
