import { connect } from 'react-redux'

import { TelcellPaymentPage as Self } from './TelcellPaymentPage'
import { getTelcellPaymentData } from './TelcellPaymentPage.actions'

const mapStateToProps = ({ telcellPaymentPage, app }) => ({
  loading: telcellPaymentPage.loading,
  data: telcellPaymentPage.data,
  error: telcellPaymentPage.error,
})

const mapDispatchToProps = {
  getTelcellPaymentData,
}

export const TelcellPaymentPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self)
