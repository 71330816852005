import React, { createRef, useState, useEffect } from 'react'
import { changeUrl, constructCannonical } from '../../hooks/changeUrl'
import { OpenTicketSuccessModal } from '../../components/Modals/OpenTicketSuccessModal'
import { LayoutCommon } from '../../layouts/LayoutCommon'
import { Formik, Form } from 'formik'
import { decode } from 'html-entities'
import { Loader } from '../../components/Loader'
import { i18n } from '../../i18n'
import {
  initialValues,
  yupScheme,
  sectionOptions,
  priorityOptions,
} from './OpenTicketPage.config'
import Dropzone from 'react-dropzone'

import * as S from './OpenTicketPage.styles'

const OpenTicketPage = ({
  error,
  openTicket,
  showModal,
  history,
  loading,
  userIp,
  getUserIp,
  location: { pathname },
}) => {
  const [previews, setPreviews] = useState([])
  const dropzoneRef = createRef()
  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  }

  useEffect(() => {
    getUserIp()
  }, [])

  return (
    <LayoutCommon>
      <S.RegistrationContainer>
        <S.PageTitle>{i18n.t('openTicketPage.title')}</S.PageTitle>
        <S.FormContainer>
          <Formik
            initialValues={initialValues}
            validationSchema={yupScheme}
            onSubmit={async (values, { setSubmitting }) => {
              const message = `${values.message} \n\n${i18n.t(
                'ipNote.ipText'
              )} ${userIp && userIp}`

              const formattedValues = {
                ...values,
                message,
              }
              await openTicket(formattedValues, () => {
                showModal(OpenTicketSuccessModal)
                history.push(changeUrl('/client-area'))
              })
              setSubmitting(false)
            }}
          >
            {({
              touched,
              errors,
              isSubmitting,
              handleChange,
              values,
              setFieldValue,
              submitForm,
              isValid,
              dirty,
            }) => (
              <Form>
                <S.FormSection>
                  <S.FormItemInline>
                    <S.Label>{i18n.t('openTicketPage.subject')}*</S.Label>
                    <S.InputForSubject
                      placeholder={i18n.t('openTicketPage.subjectPlaceholder')}
                      name="subject"
                      onChange={handleChange}
                      value={decode(values.subject)}
                    />
                    {errors.subject && touched.subject && (
                      <S.ErrorMessage>{i18n.t(errors.subject)}</S.ErrorMessage>
                    )}
                  </S.FormItemInline>
                  <S.FormItem>
                    <S.Label>{i18n.t('openTicketPage.section')}*</S.Label>
                    <S.StyledSelect
                      classNamePrefix="react-select"
                      name="deptId"
                      defaultValue={sectionOptions(i18n).find(
                        (item) => item.value === values.deptId
                      )}
                      isSearchable={true}
                      options={sectionOptions(i18n)}
                      onChange={(sectionOption) =>
                        setFieldValue('deptId', sectionOption.value)
                      }
                    />
                    {errors.section && touched.section && (
                      <S.ErrorMessage>{i18n.t(errors.section)}</S.ErrorMessage>
                    )}
                  </S.FormItem>
                  <S.FormItem>
                    <S.Label>{i18n.t('openTicketPage.priority')}*</S.Label>
                    <S.StyledSelect
                      defaultValue={priorityOptions(i18n).find(
                        (item) => item.value === values.priority
                      )}
                      isSearchable={true}
                      name="priority"
                      options={priorityOptions(i18n)}
                      onChange={(priorityOption) =>
                        setFieldValue('priority', priorityOption.value)
                      }
                      classNamePrefix="react-select"
                    />
                    {errors.priority && touched.priority && (
                      <S.ErrorMessage>{i18n.t(errors.priority)}</S.ErrorMessage>
                    )}
                  </S.FormItem>
                  <S.FormItemInline>
                    <S.Label>{i18n.t('openTicketPage.message')}*</S.Label>
                    <S.TextareaWrapper>
                      <S.Textarea
                        component="textarea"
                        placeholder={i18n.t(
                          'openTicketPage.textareaPlaceholder'
                        )}
                        name="message"
                        onChange={handleChange}
                        value={decode(values.message)}
                        maxLength={500}
                        isError={values.message.length === 500}
                      />
                      <S.Counter isError={values.message.length === 500}>
                        {values.message.length}/500
                      </S.Counter>
                    </S.TextareaWrapper>
                    <Dropzone
                      ref={dropzoneRef}
                      noClick
                      noKeyboard
                      name="files"
                      minSize={0}
                      maxSize={2621440}
                      maxFiles={1}
                      multiple={false}
                      accept="image/jpg, image/jpeg, image/png, image/JPG, image/JPEG, image/PNG"
                      onDrop={(files) => {
                        setFieldValue('files', [...values.files, ...files])
                        const reader = new FileReader()
                        reader.onload = (event) => {
                          setPreviews([...previews, event.target.result])
                        }
                        if (files[0]) {
                          reader.readAsDataURL(files[0])
                        }
                      }}
                    >
                      {({ getRootProps, getInputProps }) => {
                        return (
                          <S.Section>
                            <div {...getRootProps()}>
                              <aside>
                                <input {...getInputProps()} name="files" />
                                <S.ErrorLimits>
                                  <S.Type>
                                    * {i18n.t('openTicketPage.note')}
                                  </S.Type>
                                  <S.Limit>
                                    * {i18n.t('openTicketPage.limit')}
                                  </S.Limit>
                                </S.ErrorLimits>
                                <S.AttachButton
                                  type="button"
                                  disabled={values.files.length > 0}
                                  onClick={openDialog}
                                >
                                  {i18n.t('openTicketPage.attachButton')}
                                </S.AttachButton>
                                <S.AttachmentsList>
                                  {values.files.map((file, index) => (
                                    <S.Attachments>
                                      <S.AcceptedFiles key={file.path}>
                                        <S.ImageFrame>
                                          <S.PreviewImage
                                            src={previews[index]}
                                            alt="preview image"
                                            title="uploaded image"
                                          />
                                        </S.ImageFrame>
                                        <S.ImageName>
                                          {file.path.length > 20
                                            ? file.path.substring(0, 20) + '...'
                                            : file.path}
                                        </S.ImageName>
                                      </S.AcceptedFiles>
                                      <S.RemoveButton
                                        onClick={() => {
                                          setFieldValue(
                                            'files',
                                            values.files.filter(
                                              (item) => item.path !== file.path
                                            )
                                          )
                                          setPreviews([])
                                        }}
                                      />
                                    </S.Attachments>
                                  ))}
                                </S.AttachmentsList>
                              </aside>
                            </div>
                            {values.files.length > 1 && (
                              <S.ErrorMessage>
                                {i18n.t('openTicketPage.limitError')}
                              </S.ErrorMessage>
                            )}
                          </S.Section>
                        )
                      }}
                    </Dropzone>
                    {errors.message && touched.message && (
                      <S.ErrorMessage>{i18n.t(errors.message)}</S.ErrorMessage>
                    )}
                  </S.FormItemInline>
                  <S.ButtonsContainer>
                    <S.SendButton
                      type="submit"
                      disabled={
                        isSubmitting ||
                        !(isValid && dirty) ||
                        values.files.length > 1
                      }
                    >
                      {loading ? (
                        <Loader color="#ffffff" size={0.5} />
                      ) : (
                        i18n.t('openTicketPage.sendButton')
                      )}
                    </S.SendButton>
                    <S.CancelButton to={changeUrl('/client-area')}>
                      {i18n.t('openTicketPage.cancelButton')}
                    </S.CancelButton>
                  </S.ButtonsContainer>
                </S.FormSection>
                {error && (
                  <S.ErrorMessage spaced>
                    {i18n.t(`backend.openTicket.${error}`)}
                  </S.ErrorMessage>
                )}
              </Form>
            )}
          </Formik>
        </S.FormContainer>
      </S.RegistrationContainer>
    </LayoutCommon>
  )
}

export { OpenTicketPage }
