import React, { useEffect } from 'react'
import qs from 'qs'
import { Helmet } from 'react-helmet'

import { LayoutCommon } from '../../layouts/LayoutCommon'
import * as S from './ConfirmEmailPage.styles'
import { i18n } from '../../i18n'
import { Loader } from '../../components/Loader'
import { RegistrationConfirmModal } from '../../components/Modals/RegistrationConfirmModal'
import ResetPasswordFailImage from '../../images/reset-password-fail.png'

import { changeUrl } from '../../hooks/changeUrl'

const ConfirmEmailPage = ({
  history,
  showModal,
  loading,
  error,
  verifyHash,
  data,
}) => {
  useEffect(() => {
    const params = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
      encode: true,
    })
    const hash = params.hash
    verifyHash(hash)
  }, [history.location.search])

  useEffect(() => {
    if (data.result && data.result.affectedRows === 1) {
      showModal(RegistrationConfirmModal)
      history.push(changeUrl('/'))
    }
  }, [data])

  return (
    <LayoutCommon>
      <Helmet>
        <title>{i18n.t('headers.confirmEmailPage')}</title>
        <meta
          name="description"
          content={i18n.t('headersDescriptions.confirmEmailPage')}
        />
        <meta name="og:title" content={i18n.t('headers.confirmEmailPage')} />
        <meta
          name="og:description"
          content={i18n.t('headersDescriptions.confirmEmailPage')}
        />
        <meta
          name="og:image"
          content="https://internet.am/images/fb_logo.png"
        />
      </Helmet>
      {loading ? (
        <S.RegistrationContainer centered>
          <Loader color="#00C084" size={0.5} />
        </S.RegistrationContainer>
      ) : (
        error && (
          <S.RegistrationContainer>
            <S.StyledImage
              src={ResetPasswordFailImage}
              alt="Reset password icon"
              title="reset password image"
            />
            <S.FailText>{i18n.t('confirmEmailPage.failText')}</S.FailText>
          </S.RegistrationContainer>
        )
      )}
    </LayoutCommon>
  )
}

export { ConfirmEmailPage }
