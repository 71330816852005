import React, { useEffect } from 'react'
import qs from 'qs'

import cartLogo from '../../../images/cart.png'
import armenianLanguage from '../../../images/armenianLanguage.svg'
import russianLanguage from '../../../images/russianLanguage.svg'
import englishLanguage from '../../../images/englishLanguage.svg'
import * as S from './RightNavBar.styles'
import { LoginModal } from '../../Modals/LoginModal'
import { i18n } from '../../../i18n'
import { Tooltip } from '../../Tooltip'
import { PhysicalAvatar, LegalAvatar } from './helper'
import { changeUrl } from '../../../hooks/changeUrl'

export const RightNavBar = ({
  history,
  email,
  showModal,
  cart,
  toggleCart,
  toggleLanguagesPopup,
  toggleAccountPopup,
  auth,
}) => {
  useEffect(() => {
    if (history && history.location) {
      const params = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
        encode: true,
      })

      if (params.supermanisbatman) {
        showModal(LoginModal)
      }
    }
  })

  return (
    <S.RightNavBarContainer>
      <S.CartLogoButton onClick={toggleCart}>
        <S.CartLogo src={cartLogo} alt="Cart logo" title="cart logo" />
        {!!cart?.length && <S.CartCounter>{cart.length}</S.CartCounter>}
        {cart.some(
          (item) =>
            ['hosting', 'constructor'].includes(item.type) &&
            (!item.domain || !item.tld)
        ) && (
          <S.WarningIcon className="tooltipContainer">
            <Tooltip className="tooltip" cWidth={9}>
              {i18n.t('tooltips.cartProblemTooltip')}
            </Tooltip>
          </S.WarningIcon>
        )}
      </S.CartLogoButton>
      {auth.fullname ? (
        <S.AvatarContainer onClick={toggleAccountPopup}>
          <S.Avatar>
            {auth.legalStatus === 'physical'
              ? PhysicalAvatar(auth.fullname)
              : LegalAvatar(auth.fullname)}
          </S.Avatar>
        </S.AvatarContainer>
      ) : (
        <>
          <S.LoginIconContainer onClick={() => showModal(LoginModal)}>
            <S.LoginIcon aria-label="Login" />
          </S.LoginIconContainer>
          <S.RegisterBtn to={changeUrl('/registration')}>
            {i18n.t('header.registration')}
          </S.RegisterBtn>

          <S.LoginBtn onClick={() => showModal(LoginModal)}>
            {i18n.t('header.login')}
          </S.LoginBtn>
        </>
      )}

      <S.LanguageSwitchButton onClick={toggleLanguagesPopup}>
        {i18n.language === 'am' && (
          <S.LanguageLogo
            src={armenianLanguage}
            alt="Armenian language icon"
            title="armenian language icon"
          />
        )}
        {i18n.language === 'ru' && (
          <S.LanguageLogo
            src={russianLanguage}
            alt="Russian language icon"
            title="russian language icon"
          />
        )}
        {i18n.language === 'en' && (
          <S.LanguageLogo
            src={englishLanguage}
            alt="English language icon"
            title="english language icon"
          />
        )}
      </S.LanguageSwitchButton>
    </S.RightNavBarContainer>
  )
}
