import styled from 'styled-components'
import * as fontWeight from '../../assets/styles/constants/fontWeight'
import Container from '../../components/Container/Container'

export const PageBody = styled.div`
  background-color: #f8f8f8;
  width: 100%;
  display: flex;
  padding-top: 75px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 75px;
`

export const SpecialTermsBodyContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SpecialTermsTitle = styled.h1`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 18px;
  text-align: center;
  color: #484857;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`

export const SpecialTermsContent = styled.p`
  font-size: 16px;
  @media (min-width: 768px) {
    font-size: 18px;
  }

  @media (min-width: 1280px) {
    font-size: 20px;
  }
`

export const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 43px;
  align-items: center;
  @media (min-width: 992px) {
    align-items: flex-start;
    width: 100%;
    margin: 70px 0px 50px 70px;
  }
`

export const TermsList = styled.ul`
  margin-top: 0;
  margin-bottom: 10px;
`
export const TermsItem = styled.li`
  line-height: 1.4;
`
export const TermsLink = styled.a`
  color: #3f51b5;
  outline: 0;
  text-decoration: none;
  &:hover {
    color: black;
  }
`
export const TermsItemName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  @media (min-width: 768px) {
    font-size: 18px;
  }

  @media (min-width: 1280px) {
    font-size: 20px;
  }
`
