import FetchApi from '../../../helpers/FetchApi'
import { LOGIN_TYPES } from './LoginModal.types'
import {
  setAuthData,
  getUserInfo,
  setCart,
} from '../../../common/App/App.actions'
import { initialValues } from '../../../components/Modals/AddContactModal/AddContactModal.config'

export const login =
  (values, showTwoFactorAuthModal, onSuccess, loginType) =>
  async (dispatch) => {
    try {
      dispatch({
        type: LOGIN_TYPES.LOGIN_REQUEST,
      })
      const req = await FetchApi.post(`users/${loginType}`, values)

      if (req.data.twoFactorAuth) {
        return showTwoFactorAuthModal()
      }

      await FetchApi.setToken(
        req.data.token,
        values.email,
        req.data.fullname,
        req.data.userId,
        req.data.address,
        req.data.currency,
        req.data.creditRecharge ? 1 : 0,
        req.data.legalStatus,
        req.data.disableCode
      )

      dispatch({
        type: LOGIN_TYPES.LOGIN_SUCCESS,
      })

      const carts = JSON.parse(localStorage.getItem('carts'))
      const userCart = carts?.[req.data.userId]
      if (userCart) {
        dispatch(setCart(userCart))
      }

      dispatch(
        setAuthData({
          email: values.email,
          token: req.data.token,
          fullname: req.data.fullname,
          userId: req.data.userId,
          address: req.data.address,
          currency: +req.data.currency,
          creditRecharge: req.data.creditRecharge ? 1 : 0,
          legalStatus: req.data.legalStatus,
          disableCode: req.data.disableCode,
        })
      )

      dispatch(getContacts())
      dispatch(getDomains())
      dispatch(getUserInfo())

      if (onSuccess) {
        onSuccess()
      }
    } catch (e) {
      dispatch({
        type:
          loginType === 'login'
            ? LOGIN_TYPES.LOGIN_FAIL
            : LOGIN_TYPES.LOGIN_AS_CLIENT_FAIL,
        error: e.data
          ? e.data.error_code ||
            e.data.error ||
            e.data.message ||
            e.data.validation_error
          : e.message,
      })
    }
  }
export const getContacts = () => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_TYPES.GET_CONTACTS_REQUEST,
    })

    const req = await FetchApi.get('contacts')
    dispatch({
      type: LOGIN_TYPES.GET_CONTACTS_SUCCESS,
      contacts: req.data.data.contacts.contact,
    })
  } catch (e) {
    dispatch({
      type: LOGIN_TYPES.GET_CONTACTS_FAIL,
      error: e,
    })
  }
}

export const addNewContact = (contact) => ({
  type: LOGIN_TYPES.ADD_NEW_CONTACT,
  contact,
})

export const removeContact = (contactId) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_TYPES.REMOVE_CONTACT_REQUEST,
      contactId,
    })
    await FetchApi.delete(`contacts?id=${contactId}`)
    dispatch({
      type: LOGIN_TYPES.REMOVE_CONTACT_SUCCESS,
    })
  } catch (e) {
    dispatch({
      type: LOGIN_TYPES.REMOVE_CONTACT_FAIL,
      error: e,
    })
  }
}

export const updateContactInfo = (values, contactId) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_TYPES.UPDATE_CONTACT_INFO_REQUEST,
    })

    const formattedValues = {}

    Object.keys(values).forEach((k) => {
      if (k === 'id') {
        return (formattedValues.contactid = values[k])
      } else {
        if (k !== 'email' && Object.keys(initialValues).includes(k)) {
          formattedValues[k] = values[k]
        }
      }
    })

    await FetchApi.put('contacts', formattedValues)
    dispatch({
      type: LOGIN_TYPES.UPDATE_CONTACT_INFO_SUCCESS,
      values,
      contactId,
    })
  } catch (e) {
    dispatch({
      type: LOGIN_TYPES.UPDATE_CONTACT_INFO_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    })
  }
}

export const getDomains = () => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_TYPES.GET_DOMAINS_REQUEST,
    })

    const req = await FetchApi.get('domains/all')

    dispatch({
      type: LOGIN_TYPES.GET_DOMAINS_SUCCESS,
      domains: req.data.data.domains.domain,
    })
  } catch (e) {
    dispatch({
      type: LOGIN_TYPES.GET_DOMAINS_FAIL,
      error: e,
    })
  }
}

export const verify2FA =
  (token, email, onSuccess, username) => async (dispatch) => {
    try {
      dispatch({
        type: LOGIN_TYPES.VERIFY_2FA_REQUEST,
      })
      let req
      if (username) {
        req = await FetchApi.post('users/verify2faAdmin', {
          token,
          username,
          email,
        })
      } else {
        req = await FetchApi.post('users/verify2fa', { email, token })
      }

      await FetchApi.setToken(
        req.data.token,
        email,
        req.data.fullname,
        req.data.userId,
        req.data.address,
        req.data.currency,
        req.data.creditRecharge ? 1 : 0,
        req.data.legalStatus,
        req.data.disableCode
      )

      dispatch({
        type: LOGIN_TYPES.LOGIN_SUCCESS,
      })

      dispatch(
        setAuthData({
          email: email,
          token: req.data.token,
          fullname: req.data.fullname,
          userId: req.data.userId,
          address: req.data.address,
          currency: +req.data.currency,
          creditRecharge: req.data.creditRecharge ? 1 : 0,
          legalStatus: req.data.legalStatus,
          disableCode: req.data.disableCode,
        })
      )

      dispatch(getContacts())
      dispatch(getDomains())
      dispatch(getUserInfo())

      if (onSuccess) {
        onSuccess()
      }
    } catch (e) {
      dispatch({
        type: username
          ? LOGIN_TYPES.VERIFY_2FA_ADMIN_FAIL
          : LOGIN_TYPES.VERIFY_2FA_FAIL,
        error: e,
      })
    }
  }

export const resendEmail = (email) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_TYPES.RESEND_EMAIL_REQUEST,
      email,
    })
    await FetchApi.post(`users/resendEmailVerify`, {
      email,
    })
    dispatch({
      type: LOGIN_TYPES.RESEND_EMAIL_SUCCESS,
    })
  } catch (e) {
    dispatch({
      type: LOGIN_TYPES.RESEND_EMAIL_FAIL,
      error: e,
    })
  }
}

export const clearError = () => ({
  type: LOGIN_TYPES.CLEAR_FORM,
})
