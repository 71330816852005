import styled from 'styled-components';
import closeModal from '../../../images/closeModal.svg';

export const PunycodeConverterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 1;
  z-index: 100;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;

  @media (min-width: 768px) {
    padding: 23px 42px;
  }
`;

export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-size: cover;

  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`;

export const Modal = styled.div`
  height: 100%;
  width: 304px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  outline: none;
  z-index: 100;

  @media (min-width: 768px) {
    width: 396px;
  }
`;

export const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;

  &.radio {
    justify-content: center;
  }

  @media (min-width: 768px) {
    margin-top: 21px;
  }
`;
export const Label = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 13px;

  &.radio {
    margin: 0px;
    margin-left: 7px;
    font-size: 10px;
    opacity: 0.38;
  }

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const Input = styled.input`
  width: 224px;
  padding: 5px 12px;
  max-height: 30px;
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  font-size: 14px;

  @media (min-width: 768px) {
    width: 300px;
    padding: 12px;
    max-height: 44px;
  }
`;
