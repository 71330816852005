import { connect } from 'react-redux'
import { ServiceDetails as Self } from './ServiceDetails'
import { getServiceDetails } from '../ClientAreaPage/ClientAreaPage.actions'
import { showModal } from '../../common/Modal/Modal.actions'

const mapStateToProps = ({ clientAreaPage }) => ({
  serviceDetails: clientAreaPage.serviceDetails,
})

const mapDispatchToProps = {
  getServiceDetails,
  showModal,
}

export const ServiceDetails = connect(mapStateToProps, mapDispatchToProps)(Self)
