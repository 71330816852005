import { EDIT_PROFILE_TYPES } from '../../EditProfilePage.types';

const initialState = {
  changePassLoading: false,
  changePassError: null,
  changePassSuccess: false,
  isActivated: false,
  isActivatedError: null,
  isActivatedLoading: false,
  disableActiveError: null,
  token: null,
};

export const security = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE_TYPES.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePassLoading: true,
        changePassError: null,
        changePassSuccess: false,
      };
    case EDIT_PROFILE_TYPES.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassLoading: false,
        changePassError: null,
        changePassSuccess: true,
      };
    case EDIT_PROFILE_TYPES.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        changePassLoading: false,
        changePassError: action.error,
        changePassSuccess: false,
      };
    case EDIT_PROFILE_TYPES.GET_ACTIVATE_STATUS_REQUEST:
      return {
        ...state,
        isActivatedLoading: true,
        token: null,
      };
    case EDIT_PROFILE_TYPES.GET_ACTIVATE_STATUS_SUCCESS:
      return {
        ...state,
        isActivatedLoading: false,
        isActivated: action.activateStatus,
        token: action.qrCodeToken,
      };
    case EDIT_PROFILE_TYPES.GET_ACTIVATE_STATUS_FAIL:
      return {
        ...state,
        isActivatedLoading: false,
        isActivated: action.error,
        token: null,
      };
    case EDIT_PROFILE_TYPES.DISABLE_ACTIVATE_REQUEST:
      return {
        ...state,
        isActivatedLoading: true,
      };
    case EDIT_PROFILE_TYPES.DISABLE_ACTIVATE_SUCCESS:
      return {
        ...state,
        isActivatedLoading: false,
        isActivated: false,
      };
    case EDIT_PROFILE_TYPES.DISABLE_ACTIVATE_FAIL:
      return {
        ...state,
        isActivatedLoading: false,
        disableActiveError: action.error,
      };
    default: {
      return state;
    }
  }
};
