import { i18n } from '../../i18n'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

export const formatPaymentForm = (paymentForm) => {
  const splitted = paymentForm.split('Վճարել')
  switch (i18n.language) {
    case 'ru':
      return splitted.join('Оплатить')
    case 'en':
      return splitted.join('Pay')
    case 'am':
      return splitted.join('Վճարել')
    default:
      return splitted.join('Վճարել')
  }
}

export const download = (invoiceRef, id) => {
  const input = invoiceRef.current
  html2canvas(input, {
    useCORS: true,
    allowTaint: true,
    scrollY: 0,
    scale: 3,
  }).then((canvas) => {
    const image = { type: 'jpeg', quality: 0.98 }
    const margin = [0, 0]

    let imgWidth = 8.5
    let pageHeight = 11

    const innerPageWidth = imgWidth - margin[0] * 2
    const innerPageHeight = pageHeight - margin[1] * 2

    const pxFullHeight = canvas.height
    const pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth))
    const nPages = Math.ceil(pxFullHeight / pxPageHeight)

    pageHeight = innerPageHeight

    const pageCanvas = document.createElement('canvas')
    const pageCtx = pageCanvas.getContext('2d')
    pageCanvas.width = canvas.width
    pageCanvas.height = pxPageHeight

    const pdf = new jsPDF('p', 'in', [8.5, 11])

    for (let page = 0; page < nPages; page++) {
      if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
        pageCanvas.height = pxFullHeight % pxPageHeight
        pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width
      }

      var w = pageCanvas.width
      var h = pageCanvas.height
      pageCtx.fillStyle = 'white'
      pageCtx.fillRect(0, 0, w, h)
      pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h)

      if (page > 0) pdf.addPage()
      const imgData = pageCanvas.toDataURL('image/' + image.type, image.quality)
      pdf.addImage(
        imgData,
        image.type,
        margin[1],
        margin[0],
        innerPageWidth,
        pageHeight
      )
    }

    pdf.save(`Invoice #${id}`)
  })
}
