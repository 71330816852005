import { connect } from 'react-redux';

import { AccountPopup as Self } from './AccountPopup';
import { showAccountPopup, logout } from '../../../common/App/App.actions';

const mapStateToProps = ({ app }) => ({
  isShown: app.accountPopupIsShown,
  auth: app.auth,
});

const mapDispatchToProps = {
  showAccountPopup,
  logout,
};

export const AccountPopup = connect(mapStateToProps, mapDispatchToProps)(Self);
