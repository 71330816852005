import styled from 'styled-components'
import deleteButton from '../../../images/deleteButton.svg'
import ticketImage from '../../../images/openTicketSuccess.svg'
import closeModal from '../../../images/closeModal.svg'

export const Modal = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  outline: none;
`

export const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #484857;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 28px;
  }
  @media (min-width: 1280px) {
    line-height: 29px;
  }
`
export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 90%;
  padding-top: 30px;
  padding-bottom: 30px;
`

export const ZonesContainer = styled.div`
  height: 100%;
  width: 1400px;
  overflow: scroll;
  max-height: 90%;
  @media (max-width: 1400px) {
    transform: translateX(20%);
  }
  @media (max-width: 992px) {
    transform: translateX(40%);
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
`

export const Input = styled.input`
  width: 250px;
`

export const Textarea = styled.textarea`
  width: 250px;
  height: 100px;
`

export const Delete = styled.button`
  height: 16px;
  width: 15px;
  margin-right: 10px;
  background-image: url(${deleteButton});
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
`

export const Button = styled.button`
  align-items: center;
  border: 1px solid black;
  border-radius: 5px;
  background-color: transparent;
  padding: 10px 18px;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  color: black;
  transition: 0.3s;
  &:hover {
    color: #ffffff;
    background-color: #242e46;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 1280px) {
    display: flex;
  }
`

export const SaveButton = styled.button`
  background-color: #00c084;
  border: none;
  border-radius: 5px;
  padding: 10px 18px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  width: 100%;
  transition: 0.3s;
  margin-top: 40px;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`

export const Image = styled.div`
  background-image: url(${ticketImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 177px;
  width: 280px;
`

export const Header = styled.div`
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: #242e46;
  margin-top: 12px;
`
export const SubHeader = styled.div`
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  color: #242e46;
  margin-top: 8px;
  margin-bottom: 40px;
`

export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-size: cover;

  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`
