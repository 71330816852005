import React, { useEffect } from 'react'
import { useSearchParams } from '../../hooks/useSearchParams'
import { LayoutCommon } from '../../layouts/LayoutCommon'
import { i18n } from '../../i18n'
import { Loader } from '../../components/Loader'
import failImg from '../../images/reset-password-fail.png'
import successIcon from '../../images/greenCircle.png'

import * as S from './TelcellPaymentPage.styles'
import { changeUrl } from '../../hooks/changeUrl'

export const TelcellPaymentPage = ({
  getTelcellPaymentData,
  data,
  loading,
}) => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setTimeout(() => {
      getTelcellPaymentData(searchParams.order)
    }, [])
  }, 4000)

  const getTelcellPaymenStatus = (status) => {
    switch (status) {
      case 'NEW':
        return i18n.t('backend.telcellPaymenStatus.NEW')
      case 'PAID':
        return i18n.t('backend.telcellPaymenStatus.PAID')
      case 'REJECTED':
        return i18n.t('backend.telcellPaymenStatus.REJECTED')
      case 'EXPIRED':
        return i18n.t('backend.telcellPaymenStatus.EXPIRED')
      case 'CANCELLED':
        return i18n.t('backend.telcellPaymenStatus.CANCELLED')
      case 'CANCELLEDFORREPEAT':
        return i18n.t('backend.telcellPaymenStatus.CANCELLEDFORREPEAT')
      default:
        return i18n.t('backend.telcellPaymenUnknownStatus.unknownStatus')
    }
  }
  const statusTranslation = getTelcellPaymenStatus(data?.status)

  const imgToggle = (status) => {
    if (status === 'PAID') {
      return successIcon
    } else {
      return failImg
    }
  }
  const img = imgToggle(data?.status)

  return (
    <LayoutCommon>
      <S.TelcellPaymentPageContainer>
        <S.DataContainer>
          {loading ? (
            <S.LoaderWrapper>
              <Loader color="#242e46" size={0.5} />
            </S.LoaderWrapper>
          ) : (
            <>
              <S.Image src={img} />
              <S.Text>
                {!data?.invoiceid ? (
                  ''
                ) : (
                  <>
                    {i18n.t(`backend.telcellPaymenInvoice.invoice`)}{' '}
                    {data?.invoiceid}
                  </>
                )}
              </S.Text>
              <S.Text>{statusTranslation}</S.Text>
            </>
          )}
        </S.DataContainer>
        <S.BackLinkBtn to={changeUrl('/client-area')}>
          {i18n.t(`telcellPaymenPage.backButton`)}
        </S.BackLinkBtn>
      </S.TelcellPaymentPageContainer>
    </LayoutCommon>
  )
}
