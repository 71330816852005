export const questions = {
  domains: {
    id: 'faq.domainsTitle',
    list: [
      {
        id: 'faq.domains.q1',
        question: 'faq.domains.q1',
        answer: 'faq.domains.a1',
        isFeatured: true,
        tags: ['domain'],
      },
      {
        id: 'faq.domains.q2',
        question: 'faq.domains.q2',
        answer: 'faq.domains.a2',
        tags: ['domain'],
      },
      {
        id: 'faq.domains.q3',
        question: 'faq.domains.q3',
        answer: 'faq.domains.a3',
        tags: ['domain'],
      },
    ],
  },
  hosting: {
    id: 'faq.hostingTitle',
    list: [
      {
        id: 'faq.hosting.q1',
        question: 'faq.hosting.q1',
        answer: 'faq.hosting.a1',
        isFeatured: true,
        tags: ['hosting'],
      },
      {
        id: 'faq.hosting.q2',
        question: 'faq.hosting.q2',
        answer: 'faq.hosting.a2',
        isFeatured: true,
        tags: ['hosting'],
      },
      {
        id: 'faq.hosting.q3',
        question: 'faq.hosting.q3',
        answer: 'faq.hosting.a3',
        isFeatured: true,
        tags: ['hosting'],
      },
      {
        id: 'faq.hosting.q4',
        question: 'faq.hosting.q4',
        answer: 'faq.hosting.a4',
        isFeatured: true,
        tags: ['hosting'],
      },
    ],
  },
  constructor: {
    id: 'faq.constructorTitle',
    list: [
      {
        id: 'faq.constructor.q1',
        question: 'faq.constructor.q1',
        answer: 'faq.constructor.a1',
        isFeatured: true,
        tags: ['constructor'],
      },
    ],
  },
  vps: {
    id: 'faq.vpsTitle',
    list: [
      {
        id: 'faq.vps.q1',
        question: 'faq.vps.q1',
        answer: 'faq.vps.a1',
        isFeatured: true,
        tags: ['vps'],
      },
      {
        id: 'faq.vps.q2',
        question: 'faq.vps.q2',
        answer: 'faq.vps.a2',
        tags: ['vps'],
      },
      {
        id: 'faq.vps.q3',
        question: 'faq.vps.q3',
        answer: 'faq.vps.a3',
        tags: ['vps'],
      },
      {
        id: 'faq.vps.q4',
        question: 'faq.vps.q4',
        answer: 'faq.vps.a4',
        tags: ['vps'],
      },
    ],
  },
  ssl: {
    id: 'faq.sslTitle',
    list: [
      {
        id: 'faq.ssl.q1',
        question: 'faq.ssl.q1',
        answer: 'faq.ssl.a1',
        isFeatured: true,
        tags: ['ssl'],
      },
      {
        id: 'faq.ssl.q2',
        question: 'faq.ssl.q2',
        answer: 'faq.ssl.a2',
        tags: ['ssl'],
      },
      {
        id: 'faq.ssl.q3',
        question: 'faq.ssl.q3',
        isMulti: true,

        tags: ['ssl'],
      },
    ],
  },
}

export const SUPPORT_TABS = {
  FREQUENT: 'Հայտնիները',
  CATEGORIES: 'Ըստ բաժինների',
}
