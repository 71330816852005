import { backendRu } from '../backend/ru'

export const ru = {
  translation: {
    ...backendRu,
    header: {
      domain: 'Домен',
      constructor: 'Конструктор',
      hosting: 'Хостинг',
      vps: 'VPS',
      ssl: 'SSL',
      support: 'Поддержка',
      registration: 'Регистрация',
      login: 'Войти',
      logout: 'Выйти',
      blog: 'Блог',
    },
    loginModal: {
      title: 'Войти',
      mail: 'Эл.адрес',
      password: 'Пароль',
      client: 'Клиент',
      rememberMe: 'Запомнить меня',
      question: 'Забыли пароль?',
      login: 'Войти',
      registerNow: 'Зарегистрироваться',
      resendEmail: 'Отправить письмо повторно',
      emailSent: 'Письмо было отправлено повторно',
    },
    cookiesModal: {
      accept: 'Понятно',
      header: 'Сайт использует файлы cookie',
      text: 'Сайт использует файлы cookie, чтобы можно было использовать все функции сайта',
    },
    maintenanceModal: {
      warning: 'Уважаемые партнеры,',
      info1:
        'мы получили уведомление от Центра интернет-технологий о том, что технические работы запланированы на 8 мая 2022 года. Это может привести к кратковременной недоступности услуг (от 4 до 5 часов) с 10:00 до 20:00.',
      info2:
        'Мы делаем все возможное, чтобы предоставлять нашим клиентам отличный сервис. Однако, из-за тяжелого обновления могут возникнуть некоторые временные проблемы.',
      info3: 'Спасибо за сотрудничество и понимание.',
      info4: 'Команда ABCDomain',
    },

    personalDetailsSuccessModal: {
      text: 'Изменения успешно сохранены',
    },
    authModal: {
      title: 'Введите двухфакторный код аутентификации',
      header:
        'Введите 6-значный код, который вы видите в приложении autenticator',
      confirmButton: 'Подтвердитъ',
      cancelButton: 'Отмена',
    },
    resetPasswordModal: {
      title: 'Востановить пароль',
      subTitle:
        'Забыли пароль? Введите Вашу эл.почту, чтобы начать процесс восстановления пароля',
      mail: 'Эл.адрес',
      placeholder: 'example@example.com',
      send: 'Отправить',
    },
    resetPasswordSuccessModal: {
      header: 'Процесс восстановления Вашего пароля начался',
      subHeader: 'Пожалуйста проверьте Вашу эл.почту',
    },
    domainUnavailableModal: {
      header: 'В настоящее время мы не можем завершить Вашу покупку',
      subHeader: 'Пожалуйста еще раз проверьте доступность домена',
      message: 'Указанный(ые) домен(ы) недоступен(ны):',
    },
    underMaintenanceModal: {
      header:
        'В настоящее время на сайте ведутся технические работы и возможны сбои.',
    },
    changePasswordSuccessModal: {
      header: 'Ваш пароль успешно изменен',
    },
    openTicketSuccesModal: {
      title: 'Спасибо!',
      header: 'Ваша заявка принята',
      subHeader: 'Пожалуйста проверьте Вашу эл.почту',
    },
    transferDomainModal: {
      title: 'Перенести домен',
      domainLabel: 'Домен',
      domainPlaceholder: 'example.am',
      eppCodeLabel: 'EPP Code',
      eppCodePlaceholder: 'EPP Code',
      submit: 'Перенести',
    },
    transferDomainSuccessModal: {
      title: 'Спасибо!',
      header: 'Ваш запрос принят',
      subHeader: 'Мы надеемся, что вы скоро получите хорошие новости!',
    },

    domainConfigurationPageLabels: {
      labelId: 'ID номер',
      labelTin: 'Идентификационный номер налогоплательщика',
      labelDob: 'Дата рождения',
      labelPassportIssuer: 'Oрган выдачи паспорта',
      labelPassportSeries: 'Паспортная серия',
      labelPassportDate: 'Дата выдачи паспорта',
      labelPhonenumber: 'Номер телефона',
      labelLegalAddress: 'Юридический адрес',
      labelPostalAddress: 'Почтовый адрес',
      labelCompanyName: 'Название компании',
    },

    domainConfigurationPageSubLabels: {
      subLabelId: '*ID или номер паспорта',
      subLabelTin: '*Требуется только для организаций',
      subLabelDob: '*Требуется только для физ. лиц',
    },

    footer: {
      main: 'Основное',
      company: 'Компания',
      aboutUs: 'О компании',
      generalTerms: 'Общие правила и условия',
      specialTerms: 'Специальные правила и условия',
      contactUs: 'Связь с нами',
      useful: 'Полезное',
      punycodeConverter: 'Punycode converter',
      contactDomainOwner: 'Связаться с правообладателем домена',
      charter: 'Устав ICANN',
      icannLink: 'https://www.icann.org/resources/pages/benefits-2017-10-27-ru',
      ip: 'IP:',
      hosting: 'Хостинг',
      vpsServers: 'VPS Пакеты',
      linuxHosting: 'Linux Хостинг',
      openTicket: 'Оформить заявку в службу поддержки',
    },

    editProfilePage: {
      title: 'Мой аккаунт',
      personalDetails: 'Личные данные',
      contacts: 'Контакты/Суб-аккаунты',
      security: 'Безопасность',
      personalDetailsComponent: {
        saveButton: 'Сохранить',
      },
      securityComponent: {
        currentPassword: 'Действующий пароль',
        newPassword: 'Новый пароль',
        confirmPassword: 'Подтвердите пароль',
        noteHeader: 'Совет по безопасному паролю',
        noteTextOne:
          'Комбинация не менее 12 символов, состоящая как минимум из одной заглавной буквы, одной строчной буквы',
        noteTextTwo:
          'Используйте специальные символы и цифр (# $ ! % & и т. д.)',
        noteTextThree:
          'Не используйте личную информацию, общеупотребительные слова и фразы',
        linkText: 'Активировать двухфакторную авторизацию',
        linkText2: 'Выключить двухфакторную авторизацию',
        confirmButton: 'Подтвердить',
        cancelButton: 'Отмена',
      },
      contactComponent: {
        addContact: 'Добавить контакт +',
      },
    },

    aboutUsPage: {
      title: 'УНИКАЛЬНЫЙ ПРОВАЙДЕР ХОСТИНГ СЕРВИСА',
      header:
        'Наши усилия направлены на удовлетворение потребностей и желаний наших партнеров',
      paragraphOne:
        'Компания АБСДомен была основана в 2007 году, включив в свой сотав сотрудников подразделения регистрации доменов, хостинга и веб дизайна компании Арминко. Наш домен -<a href=https://internet.am/   target="_blank"> internet.am,</a> что подчеркивает преемственность трацицй компании Арминко - первого интернет провайдера Армении.',
      paragraphTwo:
        'Комания АБСДомен аккредитована со стороны Общества Интернет Армении -<a href=https://www.isoc.am/  target="_blank"> ISOC.AM</a> в качестве регистратора доменных имен в зонах .ам и հայ. Компания прошла путь от создания своего дата центра в Армении до сотрудничества с ведущими поставщиками облачных решений Европы, предоставления новых услуг, регистрации доменов в большинстве доменных зон мира <a href=https://internet.am/ru/domain-registration target="_blank">подробнее о регистрации доменов.</a>',
      paragraphThree:
        'Компания является Local Internet Registry (LIR), зарегистрированна в RIPE (Resurce IP European). Решения в области кибербезопасности осуществляются самой компанией, которая выполняет функции Computer Emergency Respond Team (CERT) для всех доменов, зарегистрированных в зоне .ам и .հայ.',
      paragraphFour:
        'Развитие технологий и постоянное повышение онлайн вовлеченности пользователей позволило компании перейти на онлайн облуживание пользователей с 2020 -го с высокой эффективностью, позволяя пользователям экономить время и не посещать офис компании.',
      paragraphFive:
        'Важным направлением деятельности компании является поддержка создания веб сайтов с помощью конструктора сайтов, что позволяет небольшим и средним компаниям самостоятельно создавать свои веб сайты при помощи конструктора сайтов <a href=https://internet.am/ru/constructor-packages   target="_blank">подробнее о конструкторе сайтов.</a>',
      paragraphSix:
        'Для продвинутых пользователей компания предоставляет услуги виртуального частного сервера - VPS <a href=https://internet.am/ru/vps-packages target="_blank">подробнее о VPS,</a>  что позволяет самостоятельно вести свой сервер со своим оригинальным программным обеспечением.',
      paragraphSeven:
        'Пользователи, работающие с нашей компанией, получают возможность хостировать свой сайт <a href=https://internet.am/ru/hosting-packages target="_blank"> подробнее о хостинге,</a> что позволяет осуществить весь комплекс работ:регистрации домена, создания сайта, хостинг сайта, установку SSL сертификата из одного окна. Установка SSL сертификата сегодня является выжнейшим компонентом эффективной работы сайта в современном мире, <a href=https://internet.am/ru/ssl-packages target="_blank">подробнее об SSL.</a>',
      paragraphEight:
        'Развитие интернета означает возникновение новых вызовов, мы внимательно отслеживаем все новые тенденции и соответственно реагируем на них. Сегодня все развитые страны предпринимают меры для обеспечения защиты персональных данных, обеспечения приватности, АБСДомен в свою очередь следует правилам General Data Protection Regulation GDPR, одновременно обеспечивая возможность связи для заинтересованных сторон с владельцем домена в зонах .ам и .հայ,<a href=https://internet.am/ru/blog/domain-holder target="_blank">как узнать владельца домена.</a> ',
      welcome:
        'Добро пожаловть на наш сайт <a href=https://internet.am/ target="_blank">www.internet.am!</a>',
      phone: 'Наш телефон: +3760270027',
      email: 'Наш адрес электронной почты: info@interent.am',
      rigthsForm:
        'FORM OF CHARTER OF RIGHTS AND OBLIGATIONS OF REGISTRATION OWNERS',
    },
    specialTermsPage: {
      title: 'Специальные правила и условия',
      content:
        'Компания ЭЙБИСИДОМЕН предоставляет услуги на основе договоров оферты. Подробности договора для каждой услуги приведены в формате pdf. Все договора содержат текст на армянском, русском и английском языках, юридически значимым является текст на армянском языке. Переводы на английский и русский языки являются информационными. <br/><br/>' +
        'Наряду с основными нашими услугами, такими как <br/><br/>' +
        'купить домен <a href="https://internet.am/ru/domain-registration" target="_blank">https://internet.am/ru/domain-registration</a><br/><br/>' +
        'хостинг домена  <a href="https://internet.am/ru/hosting-packages" target="_blank">https://internet.am/ru/hosting-packages</a><br/><br/>' +
        'предоставление SSL сертификата <a href="https://internet.am/ru/ssl-packages" target="_blank">https://internet.am/ru/ssl-packages</a><br/><br/>' +
        'разработки веб сайтов с помощью конструктора сайтов <a href="https://internet.am/ru/constructor-packages" target="_blank">https://internet.am/ru/constructor-packages</a><br/><br/>' +
        'предоставлении VPS VDS услуг выделенного сервера  <a href="https://internet.am/ru/vps-packages" target="_blank">https://internet.am/ru/vps-packages</a><br/><br/>' +
        'в нашем ассортименте вы можете найти услуги размещению ваших серверов дата центре компании ЭЙБИСИДОМЕН, создание вашего выделенного почтового сервера на территории Армении. Важно отметить, что мы хостируем не только серверы, работающие под доменом.am, но серверы из любой доменной зоны. <br/><br/>' +
        ' При заказе SSL сертификата типа EV необходимо иметь в виду, что расширенная валидация представляет собой достаточно сложную процедуру, которая может потребовать больше времени по сравнению с обычным ССЛ сертификатом. Установка SSL сертификата требует определенных знаний у разработчика вашего вебсайта. <br/><br/>' +
        'При использовании конструкторов веб сайта визитки вебмастеру нет необходимости детально изучать все тонкости работы с конструктором сайта, однако при создании сложных сайтов, например, электронного магазина необходимо более основательный подход. Важно отметить, при использовании конструктора сайтов вы получаете бесплатный SSL сертификат. <br/><br/>' +
        'Использование IDN – Internationalized Domain Name ․հայ позволит вам сэкономить при его регистрации в пять раз, при этом вы получите возможность быть в более высоких позициях в системе поиска компании Гугл призапросах ваших пользователей на армянском языке.  Кроме того, использование домена.հայ позволяет представлять вашу организацию с правильным произношением названия вашей организации армянским пользователям.<br/><br/>' +
        'Услуга размещения ваших серверов в дата центре компании ЭЙБИСИДОМЕН предоставляется после согласования условий размещения с заказчиком – уточнения размеров серверов, потребляемой мощности, требуемой скорости подключения к Интернету. <br/><br/>' +
        'Компания ЭЙБИСИДОМЕН принимает также индивидуальны заказы, такие как предоставления выделенного IP адреса для веб сайта, разработка и установка выделенного сервера электронной почты, осуществляющего рассылку сообщений для пользователей компании-заказчика, других индивидуальных проектов. При таких потребностях с вами будет составлен индивидуальный договор.',

      coLocation: 'Co-location Сервис',
      dedicatedServer: 'Dedicated Server Сервис',
      mailDelivery: 'Mail delivery Сервис',
      mail: 'Mail Сервис',
      dns: 'DNS',
      domains: 'Домены',
      hosting: 'Хостинг',
      ssl: 'SSL',
    },
    contactUsPage: {
      title: 'Связь с нами',
      header: 'Мы готовы и ждем ваших вопросов',
      tabs: {
        sales: 'Продажы',
        salesDescription:
          'Чтобы связаться с отделом продаж, вы можете позвонить в  рабочие часы по указанному номеру или обратиться в 24/7 онлайн-чат.',
        info: 'Тел: +374 60 270027',
        mail: 'info@internet.am',
        href: 'mailto: info@internet.am',
        onlineChat: 'Онлайн чат',
        chatDescription:
          'Пользуйтесь нашим онлайн чатом. Наши специалисты помогут Вам в любое время дня.',
        techSupport: 'Техническое обслуживание',
        techDescription:
          'Наши специалисты технического обслуживания одни из лучших на рынке. В случае возникновения каких-либо вопросов вы можете обратиться и получить ответ в  кратчайшее время.',
        support: 'Оформить заявку в службу поддержки',
        hq: 'Информация',
        hqDescription: '',
      },
    },
    csrModal: {
      uploadCsr: 'Загрузить CSR',
      generateCsr: 'Сгенерировать CSR',
      downloadKey: 'Скачать key',
      title: 'Создание запроса на подпись сертификата',
      n1: 'Код страны',
      n2: 'Регион/Провинция',
      n3: 'Город',
      n4: 'Название компании',
      n5: 'Тип юр. лица',
      n6: 'Домен',
      n7: 'Эл-почта',
      button: 'Сгенерировать',
      generateCsrDisclaimer:
        'Внимание: Обязательно сохраните private key, без него восстановление сертификата невозможно. Кнопка подтвердить будет доступна после скачания Private key.',
      generateCsrDisclaimer2:
        'Внимание: Обязательно сохраните private key, без него восстановление сертификата невозможно.',

      downloadCsr: 'Сохранить CSR',
      downloadPrivateKey: 'Сохранить Private key',
      copyPasteCsrHere: 'Вставьте ваш собственный CSR сюда',
      confirmCsr: 'Подтвердить',
      validationType: 'Метод валидации',
      validationEmail: 'Эл. почта валидации',
      fileValidation:
        'Инструкции по осуществлению валидации файлом будут посланы Вам через запрос в поддержку.',
      onlyUpperCase: 'Код страны должен быть заглавным',
    },
    uploadCsrSuccessModal: {
      title: 'Спасибо!',
      header: 'Процесс подтверждения CSR начался',
      subHeader:
        'После подтверждения вы получите письмо с сертификатом и настройками.',
    },
    contactDomainOwnerModal: {
      title: 'Связаться с правообладателем домена',
      domainLabel: 'Домен',
      domainPlaceholder: 'example.am',
      nameLabel: 'Ваше имя',
      namePlaceholder: 'Погос Погосян',
      mailLabel: 'Ответить на Email',
      mailPlaceholder: 'example@example.com',
      subjectLabel: 'Тема',
      subjectPlaceholder: 'По поводу домена',
      messageLabel: 'Сообщение',
      messagePlaceholder: 'Пожалуйста свяжитесь со мною',
      submit: 'Отправить',
    },
    contactOwnerSuccesModal: {
      title: 'Спасибо!',
      header: 'Мы отправили ваше письмо правообладателю домена',
      subHeader: 'Мы надеемся, что вы скоро получите хорошие новости!',
    },
    domainsPage: {
      title: 'Поиск и регистрация домена',
      header: 'Введите домен',
      searchButton: 'Поиск',
      choosedHeader: 'Выбрано',
      totalText: 'Итого',
      priceText: '/Ежегодно',
      orderButton: 'Заказать сейчас',
      isAdded: 'Добавленно',
      add: 'Добавить',
      showMore: 'Показать больше +',
      showed: 'Показаны',
      mobileChoosen: 'Выбранно',
      whois: 'Whois',
      showUnavialables: 'Показать недоступные',
      hideUnavialables: 'Скрыть недоступные',
      unavailable: 'Недоступен',
      content:
        'Регистрация домена – общие сведения <br/><br/>' +
        'Система доменных имен сформировалась в 1984 году, когда появился первый сервер имен в университете Висконсин. В 1985 году начали действовать домены .com, .org, .net. Функция сервера имен достаточно простая – связывать доменное имя с IP адресом компьютера (цифровой набор, наподобие телефонного номера), который подключён к Интернет. После обращения к сайту в браузере ваш компьютер сначала узнает IP адрес сайта у сервера имен, после чего осуществляет соединение используя полученный IP адрес. <br/><br/>' +
        'Инженера и ученые, создававшие Интернет, разделили домены на две большие группы – НДвУ (ccTLD – country code Top Level Domain) и дженерик домены (gTLD- generic Top Level Domain). Все таблицы соответствия между доменами ccTLD и gTLD и их IP адресами хранятся в базе данных IANA, которая управляется корпорацией ICANN, зарегистрированной в штате Калифорния, США. <br/><br/>' +
        'Технически доступ к этой базе данных осуществляется с помощью 13 суперкомпьютеров, которые до недавнего времени отвечали на запросы всех пользователей Интернет – какой IP адрес соответствует запрашиваемому домену. Сегодня созданы многочисленные зеркала этих 13 суперкомпьютеров, и пользователь обращается к ближайшему зеркалу, ускоряя получение ответа. В Армении зеркальные сервера установлены в Обществе Интернет Армении (www.isoc.am), и компании МТС-Армения. <br/><br/>' +
        'Выбирая доменную зону для регистрации своего домена нужно учитывать, что в случае gTLD, регулирование ваших прав и обязанностей осуществляется по правилам, разработанным в ICANN и требованиями, к которым некоторые могут быть добавлены компанией, получившей права на управление этим доменом у ICANN.<br/><br/>' +
        'Если же вы выбираете домен НДвУ, то регулирование ваших прав и обязанностей будет осуществляться согласно политики организации, которая получила права на администрирование домена. В Армении политика доступна по ссылке  <a href="https://www.am/policy/en/Policy_EN.pdf" target="_blank">https://www.am/policy/en/Policy_EN.pdf</a>. <br/><br/>' +
        'Важно иметь в виду, что НДвУ не принадлежит данной стране, двухбуквенный код разработчики Интернета взяли из справочника ISO, для Армении это.ам. В различных странах политика регистрации и использования доменных имен может радикально отличаться, перед регистрацией целесообразно просмотреть, какие особенности существуют при регистрации домена в данной доменной зоне. <br/><br/>' +
        'В 2012 году ICANN начала процесс создания новых доменных зон gTLD, число которых сегодня достигло 2000. <br/><br/>' +
        'Одновременно начался процесс создания новых доменных зон, как НДвУ так gTLD на национальных языках IDN, (Intenationalized Domain Name). Армения в результате длительных обсуждений в 2014 году запустила доменную зону.հայ․ Отметим, что принципы регулирование для пользователей IDN не отличается от регулирования для латиноязычных доменов.',
    },
    constructorPage: {
      title: 'Конструктор веб-сайта',
      subTitle: 'Лучшие конструкторы для Вас',
      headerTitle: 'Создайте свой личный сайт',
      headerButton: 'Начать сейчас',
      headerText:
        'Начни строить свой бизнес с нашим конструктором легко и быстро',
      videoTitle1: 'Простое Руководство',
      videoTitle2: 'Для Создание',
      videoTitle3: 'Сайта конструктором',
      videoSubTitle1: '1. Импортируйте понравившийся сайт',
      videoSubTitle2: '2. Делайте нужные изменение',
      videoSubTitle3: '3. Публикуйте',
      videoButton: 'Начать сейчас',
      advantages: {
        title: 'Преимущества',
        n1: 'Быстро',
        n2: 'Доступно',
        n3: 'Красиво',
        n4: 'Оптимизировано SEO/SSM',
        n5: 'Оптимизировано для разных устройств',
      },
      packagesTitle: 'Пакеты',
      compareTitle: 'Сравним Сайт и Конструктор',
      compareRentSite: {
        title: 'Заказать сайт',
        n1: 'Дорого',
        n2: 'Кропотливо',
        n3: 'Технические проблемы',
        n4: 'Головная боль из за обновлений',
        n5: 'Сложная оптимизация для всех устройств',
        n6: 'Сложная оптимизация для соц. сайтов и для поисковиков',
        n7: 'Безлимитный функционал',
      },
      compareBuyConstructor: {
        title: 'Купить конструктор',
        n1: 'Доступно',
        n2: 'Быстро',
        n3: 'Без технических проблем',
        n4: 'Обновления делаются с вашей стороны',
        n5: 'Легкая оптимизация для всех устройств',
        n6: 'Легкая оптимизация для всех соц. сайтов и для поисковиков',
        n7: 'Ограниченный функционал',
      },
      HaveQuestionTitle: 'Остались Вопросы?',
      HaveQuestionSubTitle: 'Обращайся в онлайн чат!',
    },
    constructorComponent: {
      area: 'Доступное пространство',
      pages: 'Количество страниц',
      mails: 'Эл. почта',
      ssl: 'ՏՏԼ сертификат',
      orderNow: 'Заказать сейчас',
    },
    hostingPage: {
      title: 'Пакеты хостинга',
      sTitle: 'Лучшие пакеты для Вас',
      vServers: 'Виртуальные серверы',
      lHosting: 'Linux хостинг',
      orderNow: 'Заказать сейчас',
      advatnages: 'Преимущества во всех пакетах',
      mails: 'Эл. почта',
      capacity: 'Вместимость',
      unlimited: 'Безлимитный',
      db: 'Кол. БД',
      space: 'Доступное пространство',
      subdomain: 'Subdomain',
      addon: 'addon',
      alias: 'alias',
      createSite: 'Создай свой сайт',
      createSiteBold: 'самостоятельно',
      useConstructor:
        'Построй сайт с помощью нашего конструктора быстро и красиво!',
      helpHeader: 'Тысячи компаний уже выбрали ABCDomain',
      liveChat:
        'Вы не ориентируетесь в выборе пакета? Проконсультируйтесь со специалистом через онлайн-чат.',
      orderNowText: 'Если вы уже выбрали пакет, закажите сейчас',
      users: '10000+ пользователей',
      usersText: 'выбрали наши услуги',
      cms: 'Поддержка популярных CMS',
      abcAvd: 'Работайте с нами',
      hostingAdv1: 'PHP',
      hostingAdv2: 'Безлимитный трафик',
      hostingAdv3: 'Управление хостингом с Cpanel',
      hostingAdv4: 'DNS',
      hostingAdv5: 'Доступ по протоколу FTP',
      hostingAdv6: 'Сервер OS - CentOS',
      hostingAdv7: 'MySQLs',
      hostingAdv8: 'Еженедельный бэкап',
      adv1: 'Выгодные условия, отсутствие скрытых платежей',
      adv2: 'Техническая поддержка через заявку или через 24/7 чат',
      adv3: 'Единый центр управления услугами',
      adv4: 'Гарантия предоставления заявленных ресурсов',
      adv5: 'Возможность мгновенного увеличения мощности',
      adv6: 'Высокоэффективная фильтрация спама',
      adv7: 'Скидки для партнёров internet.am',
      adv8: 'Расширенная защита от спама',
      contacUsHeader: 'Остались вопросы?',
      contacUsSubHeader: 'Обращайся в онлайн чат!',
    },
    vpsPage: {
      vServers: 'VPS на Linux системе',
      sTitle: 'Ubuntu, Debian, Fedora и т.д.',
      sLocation: 'Местоположение серверов',
      serverType: 'ОС сервера',
      serverSize: 'Объем сервера',
      hostnameLabel: 'Название сервера',
      hostnamePlaceholder: 'IP сервера',
      labelPlaceholder: 'Название сервера',
      aditionalFeatures: 'Дополнительные настройки',
      enable: 'Активировать ',
      backup: 'Auto Backup',
      forward: 'Вперед',
      serverQ: 'Количество серверов',
      location: 'Место расположения',
      type: 'ОС',
      size: 'Размер',
      price: 'Цена',
      registerNow: 'Далее',
    },
    sslPage: {
      title: 'SSL Сертификаты',
      domainValidation: 'Валидация домена',
      companyValidation: 'Валидация организации',
      extendedValidation: 'Обширная валидация',
      orderNow: 'Заказать сейчас',
    },
    supportPage: {
      title: 'База знаний',
      placeholderText: 'Ваш вопрос',
      search: 'Поиск',
      knownSection: 'Известные',
      categories: 'По категориям',
      searchResults: 'Результаты поиска',
      noSearchResults: 'Результатов не найдено',
    },
    cart: {
      domain: 'Домен',
      hosting: 'Хостинг',
      vps: 'VPS',
      ssl: 'SSL',
      constructor: 'Конструктор',
      total: 'Итого',
      orderNow: 'Заказать сейчас',
      emptyCart: 'Ваша корзина пуста',
      productInfoIncomplete: 'Пожалуйста введите домен',
      modalDeleteBtn: 'Удалить',
      modalDeleteSubtitle: 'Вы действительно хотите удалить?',
    },
    aboutMobileAppBlock: {
      title: 'Загрузите наше',
      titleBold: 'новое приложение',
      list: [
        'Поиск домена и регистрация',
        'Оплата услуг банковскими картами и PayPal',
        'Поддержка управления запросами',
        'Получение уведомлений',
      ],
    },
    searchAreaBlock: {
      title: 'Более 20 доменных зон',
      search: 'Поиск',
      info: 'Показать все возможные расширения домена',
    },
    hostingInfoBlock: {
      title: 'Shared Linux Hosting пакеты',
      packageInfo:
        'Различные пакеты хостинга: shared хостинг, широкий ассортимент виртуальных серверов',
      more: 'больше',
    },
    hostingPackageBlock: {
      buy: 'Приобрести',
    },
    servicesBlock: {
      title:
        'Shared Hosting (Linux), виртуальные сервера, конструкторы сайта, сертификаты безопасности (SSL)',
      description:
        'Различные пакеты хостинга, Shared Hosting (Linux), виртуальные сервера, конструкторы сайта, сертификаты безопасности (SSL) и другое',
      more: 'Больше',
      hosting: 'Хостинг',
      hostingDescription:
        'Современный хостинг, позволяющий размещать различные продвинутые сайты, созданные на популярных фреймворках: Wordpress, Joomla, Laravel. Выбор версии PHP, базы данных MariaDB/MySQL и многое другое.',
      ssl: 'SSL сертификаты',
      sslDescription:
        'Различные SSL сертификаты от лучших провайдеров. Валидация домена, организации и расширенное валидация, а так же Wildcard сертификаты по лучшим расценкам',
      constructor: 'Конструкторы сайта',
      constructorDescription:
        'Нужен сайт-визитка? Нужно быстро? Нет повода для беспокойства, наш конструктор сайта вам поможет создать красивый, качественный и оптимизированный под мобильные устройства сайт всего за несколько часов',
      cloudVPS: 'Cloud VPS',
      cloudVPSDescription:
        'Предлагаем широкий выбор VPS серверов. У нас есть VPS сервера любого размера и мощности, с помощью которых вы сможете установить большие платформы, API и другие системы',
    },
    whyChooseUsBlock: {
      title: 'Почему мы?',
      key1: 'многолетний опыт',
      value1: 'No 1',
      key2: 'профессиональная команда',
      value2: '3.3',
      key3: '24/7 суппорт',
      value3: '111 тыс',
      key4: 'На рынке доменов',
      value4: '24/7',
    },
    customerOpinionBlock: {
      title: 'Мнения клиентов',
    },
    homePageHosting: {
      month: 'ежемесячно',
    },
    resetPasswordPage: {
      title: 'Восстановление пароля',
      infoText: 'Введите новый пароль',
      password: 'Пароль',
      confirmPassword: 'Подтвердить пароль',
      confirmButton: 'Подтвердить',
      failText:
        'Ссылка на востановление пароля неверная или действие ссылки истекло',
      tryAgainButton: 'Восстановить снова',
    },
    confirmEmailPage: {
      failText: 'Ссылка на регистрацию неверная или действие ссылки истекло',
    },

    registrationPage: {
      title: 'Регистрация',
      infoText:
        'Информация, введенная при регистрации, является основанием для идентификации владельца приобретенной услуги. Только Заказчик несет ответственность за неверную или неполную введенную информацию.',
      infoText2:
        'Современные требования к паролям – памятка.<br/><br/>' +
        'Современные требования к паролям в сетях обеспечивают повышенную безопасность, так как киберугрозы постоянно эволюционируют. Вот некоторые основные требования к паролям, которые считаются важными сегодня и их целесообразно использовать при регистрации в компании АБСДомен.<br/><br/>' +
        'Длина пароля: Пароль должен быть длинным, обычно не менее 12 символов. Длинные пароли сложнее подобрать методами перебора.<br/><br/>' +
        'Сложность: Пароль должен включать разнообразные символы, такие как заглавные и строчные буквы, цифры и специальные символы (например, !, @, #, $, %). Это усложняет задачу взломщикам.<br/><br/>' +
        'Необычность: Пароль не должен быть очевидным или содержать простые слова, словарные слова, фразы или последовательные символы (например, &quot;password&quot;, &quot;123456&quot;, &quot;qwerty&quot;). Лучше использовать случайные комбинации символов.<br/><br/>' +
        'Персональные данные: не используйте свои персональные данные в паролях – имя, фамилия, дата рождения, могут помочь хакерам при поиске вашего пароля методом перебора.<br/><br/>' +
        'Уникальность: Для каждой учетной записи следует использовать уникальный пароль. Не стоит использовать один и тот же пароль для регистрации в различных социальных сетях, интернет-магазинах, других сайтах, где есть требование регистрации.<br/><br/>' +
        'Двухфакторная аутентификация (2FA): Внимательно изучите, есть ли 2FA на сайте, где вы собираетесь зарегистрироваться. Это дополнительный слой безопасности, требующий введение дополнительного кода, который присылается на ваш адрес электронной почты.<br/><br/>' +
        'Регулярное обновление: Пароли следует регулярно менять, даже если нет подозрений на компрометацию учетной записи.<br/><br/>' +
        'Использование фразы: Вместо простого пароля можно использовать длинные фразы, которые легко запомнить, но трудно угадать (например, &quot;Про100_восхитительно!&quot;).<br/><br/>' +
        'Парольные менеджеры: Можно использовать парольные менеджеры, которые генерируют и хранят уникальные и сложные пароли для разных учетных записей.<br/><br/>' +
        'Если вы владеете техникой печати вслепую и пользуетесь двумя языками, можно ту же фразу напечатать на втором буквами второго языка.<br/><br/>' +
        'Отслеживание активности: Проверяйте свои аккаунты на наличие подозрительной активности и немедленно реагируйте на нее.<br/><br/>' +
        'Обучение: Воспринимайте этот текст как памятку по части кибербезопасностьи и понимания важности безопасных паролей.<br/><br/>' +
        'Не забывайте следить за новостями в области кибербезопасностьи и кибергигиены.<br/><br/>' +
        'Соблюдение этих требований поможет укрепить безопасность ваших учетных записей и предотвратить несанкционированный доступ в ваш аккаунт.',
      readMoreBtn: 'Показать больше',
      readLessBtn: 'Скрыть',
      text: 'Вам нужно заполнить',
      textBold: 'латинскими буквами',
      individual: 'Физ.лицо',
      entity: 'Юр.лицо',
      firstname: 'Имя',
      lastname: 'Фамилия',
      passport: 'Пасспорт',
      companyName: 'Название компании',
      companyType: 'Тип юр. лица',
      taxCode: 'Идентификационный номер налогоплательщика',
      tel: 'Номер телефона',
      phonenumberPlaceholder: 'Введите номер телефона',
      email: 'Эл-почта',
      password: 'Пароль',
      confirmPassword: 'Подтвердить пароль',
      country: 'Страна',
      state: 'Регион/Провинция',
      city: 'Город',
      address: 'Адрес',
      postalIndex: 'Почтовый индекс',
      terms:
        'Регистрируясь на сайте www.internet.am, я подтверждаю, что прочитал и  полностью принимаю Общие положения, условия и положения (включая Особые условия) и Прайс-лист представленные на этом сайте.',
      regButton: 'Регистрация',
      autoPay:
        'Даю свое согласие на АВТОМАТИЧЕСКОЕ взимание с меня оплаты при выставлении счетов в данном аккаунте.',
      imAgree: 'Даю свое согласие на обработку моих персональных данных',
      autopayment: 'Автоматическое продление',
      autopayButton: 'Я согласен',
      reCaptchaError: 'Неверный reCaptcha',
    },
    domainSettings: {
      title: 'Настройка домена',
      header: 'Пожалуйста, просмотрите выбранное доменное имя',
      period: 'Период регистрации',
      years: 'Год',
      incognito: 'Инкогнито',
      caution:
        'Защитите свою личную информацию: уменьшите спам, включите защиту данных.',
      addHosting: 'Добавить хостинг',
      hostingIsAdded: 'Хостинг добавлен',
      nameServers: 'Настройка Name-серверов',
      warning:
        'Внимание: Вы можете проверить, соответствует ли выбранный вами домен торговой марке, зарегистрированной в Армении, по следующей  ',
      warningLink: 'ссылке.',
      next: 'Далее',
    },
    orderDetailsPage: {
      title: 'Оплата',
      login: 'Войти',
      register: 'Регистрация',
      domainHeader: 'Информация о владельце',
      domainNote:
        'Если вы регистрируете домен вместо другого лица или компании, вы можете добавить контакты на свою личную страницу и выбрать упомянутый контакт при регистрации домена. Если вам это не нужно, пропустите этот раздел',
      current: 'Текущее',
      newContact: 'Добавить новый контакт',
      selectContact: 'Выбрать контакт',
      domainTitle: 'Домен',
      hostingTitle: 'Хостинг',
      constructorTitle: 'Конструктор',
      sslTitle: 'SSL',
      vpsTitle: 'VPS',
      vpsQuantity: 'Количество',
      addNotes: 'Дополнительные замечания',
      placeholder:
        'Вы можете добавить дополнительную информацию или информацию к вашему заказу',
      total: 'Итого',
      paymentDate: 'Подлежит к оплате',
      payment: 'Оплатить',
      caution:
        'Вся информация в заказе передается в зашифрованном виде, исходя из соображений безопасности и необходимости предотвращения мошенничества. Ваш IP-адрес .... ??? (37.252.81.188) будет сохранена.',
      productInfoIncomplete: 'Пожалуйста введите домен',
      productInfoMissing: 'Пожалуйста введите данные домена',
      promocode: 'Промокод',
      promocodeInput: 'Введите код',
      selectTime: {
        n1: 'Ежемесячно',
        n2: 'Трехмесячный',
        n3: 'Полугодовой',
        n4: 'Ежегодно',
      },
    },
    productDetails: {
      title: 'Детали продукта',
      header: 'Введите адрес хостинга',
      placeholder: 'Выбрать...',
      nextButton: 'Далее',
    },
    nameServerModal: {
      title: 'Name servers',
      header:
        'Если вы хотите использовать свои name-серверы, введите их в соответствующий раздел. Наши name-серверы по умолчанию используются для хостинга.',
      submit: 'Сохранить',
      n1: 'Name server 1*',
      n2: 'Name server 2*',
      n3: 'Name server 3',
      n4: 'Name server 4',
      n5: 'Name server 5',
      more: 'Более',
    },

    getCodeModal: {
      title: 'Для изменения DNS требуется код безопасности.',
      subTitle: 'Код отправлен на административную почту домена на',
      submitCode: 'Применить',
    },

    nameServerSuccessModal: {
      n1: 'Спасибо!',
      n2: 'Name-сервера успешно изменены',
    },
    glueRecordsModal: {
      title: 'DNS glue records',
      submit: 'Сохранить',
      n1: 'IP 1',
      n2: 'IP 2',
      n3: 'IP 3',
      n4: 'IP 4',
      n5: 'IP 5',
    },
    glueRecordsSuccessModal: {
      n1: 'Спасибо!',
      n2: 'Данные Name-сервера будут обновлены в течении 24 часов',
    },
    vpsModal: {
      title: 'VPS настройки',
      ip: 'IP',
      username: 'Имя пользователя',
      password: 'Пароль',
    },
    hostingModal: {
      title: 'Linux хостинг',
      header: 'Лучшие Linux пакеты для Вас',
      button: 'Выбрать',
    },
    manageFreeDNSModal: {
      title: 'Управление DNS',
      header: 'DNS записи успешно изменены',
    },
    registrationModal: {
      header: 'Спасибо за регистрацию',
      subHeader: 'Пожалуйста проверьте Вашу эл. почту',
      login: 'Пожалуйста войдите в систему',
    },
    addContactModal: {
      header: 'Добавить',
      addButton: 'Добавить',
    },
    openTicketPage: {
      title: 'Оформить заявку',
      name: 'Имя',
      email: 'Эл-почта',
      subject: 'Тема',
      subjectPlaceholder: 'Тема...',
      section: 'Раздел',
      service: 'Соответствующий сервис',
      priority: 'Приоритет',
      inputFile: 'Вложенные файлы',
      message: 'Сообщение',
      textareaPlaceholder: 'Сообщение...',
      attachButton: 'Прикрепить файлы',
      sendButton: 'Отправить',
      cancelButton: 'Отмена',
      note: 'Допустимые типы файлов: .jpg, .jpeg, .png',
      limit: 'Ограничение: 2.5 МБ на файл',
      limitError: 'Можно загрузить только 1 файл',
    },
    clientAreaPage: {
      placeholder: 'Зарегестрировать домен',
      regButton: 'Регистрация',
      transferButton: 'Перенос',
      domains: 'Домены',
      services: 'Услуги',
      tickets: 'Заявки на поддержку',
      invoices: 'Счета',
      actionButton: 'Действия',
      addServiceButton: 'Добавить сервис +',
      hasCredit: 'Баланс',
      credit: 'на балансе',
      creditRecharge: 'Пополнение баланса',
    },
    domainsTable: {
      domain: 'Домен',
      regData: 'Дата регистрации',
      expiryDate: 'Оплачен до',
      status: 'Статус',
    },
    servicesTable: {
      service: 'Услуга',
      serviceRegDate: 'Дата регистрации',
      serviceBillCycle: 'Цикл выставления счетов',
      price: 'Цена',
      expiryDate: 'Оплачена до',
      status: 'Статус',
    },
    ticketsTable: {
      id: 'ID',
      section: 'Отдел',
      header: 'Тема заявки',
      lastAnnouncement: 'Дата подачи заявки/ответа',
      status: 'Статус',
      departments: {
        1: 'Административный',
        2: 'Технический',
        3: 'Финансовый',
      },
    },
    invoicesTable: {
      invoice: 'Номер',
      price: 'Сумма',
      date: 'Дата выставления',
      nextPayment: 'Обновлен',
      status: 'Статус',
    },

    filterPopup: {
      showAll: 'Показать все',
    },

    sectionOptions: {
      adminDep: 'Административный отдел',
      techDep: 'Технический отдел',
      finDep: 'Финансовый отдел',
    },
    clientAreaTablesStatuses: {
      open: 'Открыт',
      close: 'Закрыт',
      cancel: 'Отменено',
      active: 'Активен',
    },
    priorityOptions: {
      low: 'Низкий',
      medium: 'Средний',
      high: 'Высокий',
    },

    viewTicketModal: {
      title: 'Информация запроса',
      message: 'Сообщение',
      placeholder: 'Ваше сообщение',
      reply: 'Ответить',
      close: 'Закрыть запрос',
      download: 'Скачать',
    },

    invoicePage: {
      receiver: 'Получатель:',
      company: 'АБС Домен ООО',
      taxpayerId: 'Tax code։ 01561302',
      address: '',
      telephone: 'Phone։ +374 60 27 00 27',
      bankDetails: 'Банковские реквизиты։ 1570020918560100 “Америабанк” ЗАО',
      invoicedTo: 'Плательщик:',
      invoiceDate: 'Дата:',
      invoiceItems: 'Пункты счета',
      invoiceDescription: 'Описание',
      sum: 'Сумма',
      subTotal: 'Итого',
      credit: 'Кредит',
      total: 'К оплате',
      note: '* Конечная плата будет взиматься в АМД ',
      transactionDate: 'Дата транзакции',
      gateway: 'Оплачено через',
      transactionId: 'ID транзакции',
      amount: 'Сумма',
      responseInfo: 'Транзакций не найдено',
      print: 'Печать',
      hasCredit: 'У вас есть',
      hasCredit2: 'на балансе',
      applyCredit: 'Применить',
      invoice: 'Счет',
      creditCard: 'Платёжная карта',
      refundedToCard: 'Возврат на платежную карту',
      refundedToBalance: 'Возврат на лицевой счет',
      refundedToIdram: 'Возврат на Idram кошелек',
      creditApplied: 'Списана с баланса',
      download: 'Скачать',
      download_EHDM: 'Скачать E-HDM',
      open_EHDM: 'Открыть E-HDM',
    },

    creditRecharge: {
      title: 'Пополнение баланса',
      header: 'Пополните ваш баланс и осущесвляйте платежи со своего баланса',
      placeholder: 'Сумма',
      submit: 'Подтвердить',
    },

    settingsPopup: {
      title: 'Посмотреть запрос',
      viewInvoice: 'Посмотреть счет',
      enterCpanel: 'Войти в Cpanel',
      freeDNSManagement: 'Управлять DNS',
      csrModal: 'Загрузить CSR',
      vps: 'Настройки',
      details: 'Детали',
      configure: 'Настроить',
    },

    protectIdModal: {
      activate: 'Включая Инкогнито, данные вашего домена станут закрытыми',
      deactivate: 'Выключив инкогнито, данные вашего домена станут открытыми',
      button: 'Продолжить',
    },

    renewModal: {
      button: 'Продлить домен',
      period: 'Период продления',
      promocode: 'Промокод',
      year: 'Год',
    },
    domainTableSettingsPopup: {
      renew: 'Продлить',
      ns: 'Установить DNS',
      changeDomainContacts: 'Обновить контакты домена',
      activateProtectId: 'Активировать режим инкогнито',
      deactivateProtectId: 'Отключить режим инкогнито',
      modalDeleteBtn: 'Да',
      modalDeleteSubtitle: 'Вы действительно хотите совершить это действие?',
    },

    errors: {
      error:
        'ID номер является обязательным и должен состоять не менее чем из 4 символов',
      errorTin:
        'Идентификационный номер налогоплательщика является обязательным и должен состоять не менее чем из 3 символов',
      errorDob:
        'Дата рождения является обязательной и должна быть в формате ГГГГ-ММ-ДД',
    },

    domainContactModal: {
      title: 'Доменные Контакты',
      header:
        'Если хотите изменить доменные контакты, вставьте их в соответствующие разделы.',
      tab1: 'Регистрант',
      tab2: 'Технические',
      tab3: 'Административные',
      n1: 'Имя',
      n2: 'Страна',
      n3: 'Город',
      n4: 'Имя организации',
      n5: 'Эл. адрес',
      n6: 'Адрес',
      n7: 'Почтовый индекс',
      n8: 'Телефонный номер',
      submit: 'Сохранить',
    },

    editContactModal: {
      header: 'Редактировать',
      saveButton: 'Сохранить',
    },
    confirmModal: {
      header: 'Вы уверены?',
      cancelButton: 'Отмена',
    },

    autoChargeModal: {
      header: 'Aвтоматическое взимание',
      text: 'Без траты времени! Вы никогда не забудете оплатить представленные счета, так как они будут автоматически взиматься с Вашего счета после создания.',
      notnow: 'Не сейчас',
    },

    twoFactorAuthEnablePage: {
      title:
        'Отсканируйте QR код, чтобы активировать двухфакторную аутентификацию',
      submitButton: 'Я уже сканировал',
    },

    validationFields: {
      onlyLatin: 'Только латинские буквы и (. - @) символы',
      firstname: 'Поле обязательно',
      firstnameMin: 'Поле должно состоять минимум из 2 символов',
      firstnameMax: 'Поле должно состоять максимум из 72 символов',
      lastname: 'Поле обязательно',
      lastnameMin: 'Поле должно состоять минимум из 2 символов',
      lastnameMax: 'Поле должно состоять максимум из 72 символов',
      legalCodeMin: 'Это поле должно содержать не менее 5 символов',
      fullName: 'Полное имя обязательно',
      fullNameMin: 'Должно состоять минимум из 6 символов',
      fullNameMax: 'Должно состоять максимум из 72 символов',
      eppCode: 'EPP Code обязателен',
      eppMinPhysical: 'EPP Code должен состоять минимум из 4 символов',
      eppMaxPhysical: 'EPP Code должен состоять максимум из 30 символов',
      passport: 'Поле обязательно',
      phonenumber: 'Номер телефона обязателен',
      phonenumberMin: 'Номер телефона должен состоять минимум из 9 символов',
      phonenumberMax: 'Номер телефона должен состоять максимум из 20 символов',
      email: 'Электронная почта обязательна',
      client: 'Электронная почта пользователя обязательна',
      password:
        'Комбинация не менее 12 символов, состоящая как минимум из одной заглавной буквы, одной строчной буквы, цифр и символов. (Не используйте личную информацию, общеупотребительные слова и фразы)',
      passMin:
        'Комбинация не менее 12 символов, состоящая как минимум из одной заглавной буквы, одной строчной буквы, цифр и символов. (Не используйте личную информацию, общеупотребительные слова и фразы)',
      passMax: 'Пароль должен состоять максимум из 64 символов',
      loginPass: 'Пароль обязательна',
      passMatch: 'Пароли не совпадают ',
      country: 'Страна обязательна',
      countryMax: 'Страна должна состоять максимум из 2 символов',
      locality: 'Местоположение обязательно',
      organizationUnit: 'Организационная единица обязательна',
      commonName: 'Домен обязателен',
      state: 'Регион обязателен',
      city: 'Город обязателен',
      productId: 'Id Продукта обязателен',
      organization: 'Имя организации обязательна',
      promocodeMin: 'Промокод должен состоять минимум из 2 символов',
      promocodeMax: 'Промокод должен состоять максимум из 20 символов',
      address: 'Адрес обязателен',
      postcode: 'Почтовый индекс обязателен',
      domain: 'Введите домен',
      question: 'Заполните поле',
      subject: 'Тема обязательна',
      subjectMin: 'Тема должна состоять минимум из 2 символов',
      subjectMax: 'Тема должна состоять максимум из 40 символов',
      message: 'Сообщение обязательно',
      messageMin: 'Сообщение должно состоять минимум из 2 символов',
      messageMax: 'Сообщение должно состоять максимум из 2000 символов',
      messageTextareaMax: 'Сообщение должно состоять максимум из 500 символов',
      section: 'Раздел обязателен',
      service: 'Соответствующий сервис обязателен',
      priority: 'Приоритет обязателен',
      invalidTyping: 'Неверный формат',
      ns1Required: 'ns1 обязателен',
      ns2Required: 'ns2 обязателен',
      ns1Url: 'ns1 должен быть URL',
      ns2Url: 'ns2 должен быть URL',
      ns3Url: 'ns3 должен быть URL',
      ns4Url: 'ns4 должен быть URL',
      ns5Url: 'ns5 должен быть URL',
      sameDomain: 'Эл. почта должна быть зарегистрирована на другом домене',
      priceTime: 'Период обязателен',
      onlyDigits: 'Разрешены только цифры',
      required: 'Поле обязательно',
      amountMax8: 'Сумма должна состоять максимум из 8 цифр',
      vpsLabelMin: 'Имя сервера должно состоять минимум из 4 символов',
      vpsLabelMax: 'Имя сервера должно состоять максимум из 25 символов',
      validationType: 'Метод валидации обязателен',
      firstnamePhysical: 'Имя обязательно',
      firstnameMinPhysical: 'Имя должно состоять минимум из 2 символов',
      firstnameMaxPhysical: 'Имя должно состоять максимум из 72 символов',
      postalAddress: 'Почтовый адрес обязателен',
      legalAddress: 'Юридический адрес обязателен',
      dob: 'Дата рождения обязательна',
      passportSeries: 'Паспортная серия обязательна',
      passportIssueDate: 'Дата выдачи паспорта обязательна',
      passportIssuer: 'Oрган выдачи паспорта обязателен',
      companyName: 'Название компании обязательно',
      tid: 'Идентификационный номер налогоплательщика обязателен',
      id: 'ID номер обязателен',
      postalAddressInvalid: 'Почтовый адрес должен быть на кириллице',
      legalAddressInvalid: 'Легальный адрес должен быть на кириллице',
      companyNameInvalid: 'Название компании должно быть на латыни',
      wrongCode: 'Неверный код,',
      codeRequired: 'Введите код',
      privacy: 'Обязательно',
    },

    ipNote: {
      ipText: 'Отправлено с IP-адреса:',
    },

    headers: {
      aboutUsPage: 'Internet.am – О нас',
      clientAreaPage: 'Internet.am - Личный кабинет',
      editProfilePage: 'Internet.am - Мой аккаунт',
      constructorPage: 'Internet.am – Конструктор сайта',
      contactsPage: 'Internet.am – Связь с нами',
      domainsPage: 'Internet.am - Регистрация доменов',
      domainConfigurationPage: 'Internet.am - Настройка домена',
      homePage: 'Internet.am - Ведущий регистратор доменов в зоне .AM ',
      hostingPage: 'Internet.am - Пакеты хостинга',
      vpsPage: 'Internet.am - Лучшие Cloud VPS серверы для програмирования',
      openTicketPage: 'Internet.am – Открыть запрос на поддержку',
      orderDetailsPage: 'Internet.am – Оплата',
      productDetailsPage: 'Internet.am – Детали продукта',
      registrationPage: 'Internet.am – Регистрация на сайте Internet.am',
      resetPasswordPage: 'Internet.am - Восстановление пароля',
      specialTermsPage: 'Internet.am – Специальные правила и условия',
      sslPage: 'Internet.am – SSL сертификаты',
      supportPage: 'Internet.am – Поддержка',
      creditRechargePage: 'Internet.am – Пополнение баланса',
      errorPage: 'Internet.am - 404 ошибка',
      confirmEmailPage: 'Internet.am - Подтвердить эл. почту',
      blogPage: 'Internet.am - Блог',
      sslConfiguration: 'Internet.am - SSL конфигурация',
    },
    headersDescriptions: {
      aboutUsPage:
        'Компания «ABC DOMAIN» начала свою деятельность с 2007 года как официальный регистратор доменов в зоне .AM и сохраняет свою лидирующую позицию по ныне.',
      clientAreaPage:
        'Легче не бывало! Зарегистрируйте Ваш домен и создайте Ваш сайт в течение нескольких минут.',
      editProfilePage:
        'Легче не бывало! Зарегистрируйте Ваш домен и создайте Ваш сайт в течение нескольких минут.',
      constructorPage:
        'Самостоятельное создание сайта без вмешательства разработчика. Преимущества: быстро, доступно, легко, оптимизировано для SEO/SSM',
      contactsPage:
        'С нами можно связаться по телефону +374 60 270027, отправить письмо на эл. почту  info@internet.amили обратиться в онлайн-чат.',
      domainConfigurationPage:
        'Легче не бывало! Зарегистрируйте Ваш домен и создайте Ваш сайт в течение нескольких минут.',
      domainsPage:
        'ЭЙБИСИДОМЕН занимает первое место среди 10 крупнейших компаний по регистрации доменов в Армении. Компания предоставляет услуги по регистрации домена am, IDN հայ и основных доменных зон Интернета по всему миру.<br/><br/>' +
        'Компания официально аккредитована Армянским информационным центром (AMNIC), https://www.am/, управляемым Интернет-сообществом Армении (ISOC.AM), https://www.isoc.am/. <br/><br/>' +
        'Зарегистрируйте свое уникальное имя в зоне am и станьте одним из более чем 40 000 владельцев доменных имен в Армении. Если желаемое имя уже зарегистрировано, вы можете зарегистрировать свое имя в зонах com.am, net.am, org.am. <br/><br/>' +
        'Следующим вашим шагом может стать создание вашего веб-сайта и веб-почты с сертификатом SSL. ЭЙБИСИДОМЕН обеспечивает простое управление вашим доменным именем, записями DNS, сертификатами SSL и услугами хостинга с помощью инструментов управления cPanel. <br/><br/>' +
        'Вы можете связаться с нами онлайн через веб-сайты abcdomain.am (https://abcdomain.am) или internet.am (https://internet.am). ЭЙБИСИДОМЕН продолжает лучшие традиции компании Arminco, первого интернет-провайдера в Армении.',
      homePage:
        '.AM,.ՀԱՅ,.ORG,.COM,.NET,.INFO,.GROUP и регистрация доменов в ряде других зон, начиная с годовой стоимости 2400 драм. 24/7 поддержка Hosting,SSL,Constructor',
      hostingPage:
        'Изучите пакеты бизнес и личного хостинга для надежных и масштабируемых решений веб-хостинга, предлагаемых ведущей компанией, входящей в десятку лучших регистраторов доменов в Армении, ЭЙБИСИДОМЕН. Общий хостинг, выделенные серверы, предлагаем широкий спектр планов, адаптированных к потребностям вашего веб-сайта. Наслаждайтесь высокой производительностью, надежной поддержкой и расширенными функциями, которые обеспечат быстрое, безопасное и беспроблемное присутствие в Интернете. Найдите идеальный пакет хостинга для вашего веб-сайта и веб-почты уже сегодня.',
      vpsPage:
        'Используйте высокопроизводительные решения VPS-хостинга, адаптированные к вашим конкретным потребностям от ЭЙБИСИДОМЕН, ведущей компании, входящей в десятку лучших регистраторов доменов в Армении. Наши виртуальные частные сервера предлагают выделенные ресурсы, масштабируемость и надежную безопасность для оптимальной производительности веб-сайтов и приложений. Наслаждайтесь гибкостью настройки серверной среды, плавной масштабируемостью и надежной экспертной поддержкой. Повысьте свое присутствие в Интернете с помощью наших услуг VPS, обеспечивающих мощность и контроль над потребностями вашего цифрового проекта.',
      openTicketPage:
        'Запрос на решение проблемы с электронной почтой, SSL сертификатом, конструктором, регистрацией домена, продлением домена, хостингом и мы постараемся быстро решить ее.',
      orderDetailsPage:
        'Легче не бывало! Зарегистрируйте Ваш домен и создайте Ваш сайт в течение нескольких минут.',
      productDetailsPage:
        'Легче не бывало! Зарегистрируйте Ваш домен и создайте Ваш сайт в течение нескольких минут.',
      registrationPage:
        'Для регистрации домена на юридическое или физическое лицо нужно заполнить следующие поля.',
      resetPasswordPage:
        'Легче не бывало! Зарегистрируйте Ваш домен и создайте Ваш сайт в течение нескольких минут.',
      specialTermsPage:
        'Специальные правила и условия ООО ЭйБиСиДомен для доменов, хостинга, SSL, DNS управления',
      sslPage:
        'Positive SSL, Positive Wildcard SSL, Rapid SSL, Sectigo EV SSL, Symantec пакеты и широкий выбор других пакетов. Годовая стоимость начиная с 10000 драм.',
      supportPage:
        'Как зарегистрировать новый домен? Как получить хостинг и изменить имена серверов для домена? Что такое SSL-сертификат? Как подать заявку на онлайн чат?',
      creditRechargePage:
        'Легче не бывало! Зарегистрируйте Ваш домен и создайте Ваш сайт в течение нескольких минут.',
      errorPage:
        'Легче не бывало! Зарегистрируйте Ваш домен и создайте Ваш сайт в течение нескольких минут.',
      confirmEmailPage:
        'Легче не бывало! Зарегистрируйте Ваш домен и создайте Ваш сайт в течение нескольких минут.',
    },

    headerKeywords: {
      homePage:
        'Домен хостинг, приобрести домен в Армении, регистратор доменов, ведущий регистратор доменов в зоне am, SSL сертификат, Линукс хостинг',
      domainsPage:
        'ам,купить домен ам,домейн,домен это,ip адрес домена,что такое домен,купить домен,майл ам,kupit domain,фь,онлайн магазин,домены онлайн,купить домен .am,купить домен .ам,веб,хостмастер, proverit domain,kupit online.',
      hostingPage:
        'Приобрести хостинг в Армении, пакеты хостинга, с возможностью linux hosting cpanel, создание адресов эл.почты',
      vpsPage:
        'SSD Cloud, bandwidth, Ubuntu, Debian, fedora, VPS, virtual private server, виртуальный выделенный сервер',
      sslPage:
        'ssl сертификат, ссл сертификат, ам ссл сертификат, ам сертификат, бесплатный ssl сертификат, ssl сертификат для конструктора.',
      constructorPage:
        'Как с нуля создать веб сайт, landing сайт, создание сайта, веб конструктор',
      specialTermsPage:
        'Регистрация доменов, хостинг, DNS управление, SSL сертификат',
      aboutUsPage:
        'էյբիսիդոմեյն, abc domen, abcdomain, abcdomen  - ЭйБиСидомен, ЭйБиСи Домен ,ЭйБиСидомейн, ЭйБиСи Домейн',
    },

    homePage: {
      searchAreaBlock: {
        title: 'Зарегистрируй свой домен',
        sale: ' скидкой',
        info: 'Зимой в internet.am действуют беспрецедентные скидки! Чтобы воспользоваться, ',
        promocode: 'WINTER2021',
        rusText: ' примените промокод ',
        endText: '',
      },
      tldsCard: {
        period: 'в год',
        oldPrice: '10000/год',
      },
      buyDomainWithSale: {
        title: 'Зарегистрируй свой домен',
      },
      hostingPackageBlock: {
        secondaryText: 'NS գրառումների\nփոփոխման համար\nնախատեսված փաթեթ',
      },
      tldCards: {
        period: '/տարին',
        oldPrice: '10000/տարին',
      },
    },

    tooltips: {
      cartProblemTooltip:
        'В Вашей корзине есть продукты. Обратите внимание на них.',
      registerRequiredToSelectContact:
        'Для выбора контакта Вам надо зарегестрироватся.',
    },
    general: {
      periods: {
        daily: 'ежедневно',
        monthly: 'ежемесячно',
        yearly: 'ежегодно',
        forYears: 'Год(а)',
      },
      currency: {
        dram: '֏',
        usd: '$',
      },
    },
    faq: {
      title: 'ЧАВО',
      domainsTitle: 'Домен',
      hostingTitle: 'Хостинг',
      constructorTitle: 'Конструктор',
      vpsTitle: 'VPS',
      sslTitle: 'SSL',
      domains: {
        q1: 'Как регистрировать домен?',
        a1: '1. поиск домена с главной страницы или со страницы доменов<br /><br />2. настройка параметров домена в соответствии с требованиями (период регистрации, выбор хостинга, конфигурация сервера имен)<br /><br />3. выбор правообладателя домена<br /><br />4. оплата предпочтительным способом, указанным в счете-фактуре',
        q2: 'Как поменять nameserver-а домена?',
        a2: '1. войдите в свой аккаунт<br /><br />2. выберите раздел домены<br /><br />3. в списке доменов найдите нужный домен<br /><br />4. в настройках домена(справа на строке домена) выберите установить DNS<br /><br />5. внесите изменения и сохраните',
        q3: 'Как перенести обслуживание домена в ABCDomain?',
        a3: `1. войдите в свой аккаунт<br /><br />2. перенос<br /><br />3. заполните домен и EPP code (*код переноса домена в зоне .am вы можете получить по ссылке <a href="https://www.am/transfer/code/" target="_blank">https://www.am/transfer/code/</a>, пароль доменного имени, в случае необходимости, можно сбросить по ссылке <a href="https://www.am/password/reset/" target="_blank">https://www.am/password/reset/</a>)<br /><br />4. перенести`,
      },
      hosting: {
        q1: 'Как получить доступ к cpanel хостинга?',
        a1: `1. войдите в свой аккаунт<br /><br />2. раздел "Услуги"<br /><br />3. справа от выбранной услуги – войти в cpanel`,
        q2: 'Как поменять уже купленный пакет хостинга?',
        a2: '<a href="/open-ticket">Оформить заявку в службу поддержки<a/>',
        q3: 'Как изменить периодичность выставления счетов за хостинг?',
        a3: '<a href="/open-ticket">Оформить заявку в службу поддержки<a/>',
        q4: 'Что такое виртуальный хостинг ?',
        a4:
          'Виртуальный или расшаренный хостинг это технология, когда на одном сервере (выделенном или виртуальном) размещается множество сайтов различных владельцев. Пользователи разделяют ресурсы центрального процессора, памяти и подключения к Интернет.<br/><br/>' +
          'Преимущества:<br/><br/>' +
          'Вам не нужно настраивать параметры сервера – все уже сделано компанией, провайдера хостинга.<br/><br/>' +
          'За работой сервера следят сотрудники провайдера хостинга, обновляют программное обеспечение, фиксируют ошибки, отслеживают хакерские атаки на серверной стороне.<br/><br/>' +
          'В случае аппаратных проблем включается резервное оборудование, при необходимости вы получаете доступ к своим бэкапам для восстановления.<br/><br/>' +
          'На виртуальном хостинге вам доступна панель управления, которая позволяет производить различные дополнительные настройки для вашего сайта.<br/><br/>' +
          'Ну и важнейшее преимущество – доступная цена.<br/><br/>' +
          'Конечно, есть и недостатки:<br/><br/>' +
          'Лимитированное количество ресурсов, которые распределяются между всеми сайтами, которые хостируются на данном сервере.<br/><br/>' +
          'Возможно попадание одного из пользователей в черный список рассольников спама, что может отразиться на всех пользователей, правда, на очень короткое время, т.к. системные администраторы компании предоставляющей хостинг принимают меры для изменение маршрутов элктронной почты<br/><br/>' +
          'Ограниченное программное обеспечение, используемое в виртуальном хостинге, не является очень большой проблемой, т.к. все основные программы и языки программирования поддерживаются<br/><br/>' +
          'Резюмируя, можно констататировать, что большинству компаний малого и среднего бизнеса виртуальный хостинг позволяет решить свои проблемы быть представленными в Интернете.<br/><br/>' +
          'Если же вы нуждаетесь в большем количестве ресурсов, то есть очевидный выбор – VPS, <a href=https://internet.am/ru/vps-packages   target="_blank"> подробнее здесь. </a>',
      },
      constructor: {
        q1: 'Каким качественным и функциональным можно сделать сайт с помощью Конструктора?',
        a1: 'Конструктор internet.am имеет большие возможности - импорт предпочитаемого вами веб страницы, создания responsive дизайна, SEO оптимизации, создание страниц с небольшим функционалом и много других функций',
        advantagesTitle: 'Создание веб сайта с помощью конструктора.',
        advantagesInfo: 'Преимущества:',
        info1:
          'Вы сами создаете свой сайт и не зависите от веб дизайнера или дизайнерской компании',
        info2:
          'Можно импортировать понравившийся сайт, создать при помощи ИИ необходимые изображения и тексты, и разместить их на сыпортированном сайте',
        info3:
          'Многоязычная поддержка позволяет созавать трехязычный сайт, что актульно для Армении',
        info4:
          'Использование конструктора позволяет получить бесплатный SSL сертификат',
        info5:
          'Не используются CMS, что не позволяет хакерам взломать его, используя известные уязвимости',
        info6:
          'Несравненно более низкие финансовые затраты на создание сайта по сравнению с услугами веб студий или веб дизайнеров',
        info7:
          'Внесение изменений в ваших руках, нет необходимости разрабатывать техническое задание и составлять контракт на разработку и споровождение сайта',
        info8: 'В вашем распоряжении есть множество плагинов',
        info9: 'Поддрежка основных платежных систем для Интернет магазинов',
        info10:
          'Реализация ваших дизайнерских способностей напрямую, нет необходимости знать языки программирования',
      },
      vps: {
        q1: 'Для чего нужен VPS сервер?',
        a1: 'VPS сервера, в отличии от shared хостинга, используют технологию виртуализации, и вы получаете сервер с ресурсами, который управляется вами. Он более компактный и недорогой, чем целый сервер, но имеет возможность масштабируемости.',
        q2: 'Для чего нужен VPS сервер?',
        a2: 'VPS – Virtual Private Server это выделенный частный компьютера, который упрваляется вашей организацией, он располагается в дата центре провайдера услуг хостинга и  VPS. На ранних этапах развития Инернета VPS был именно отдельным сервером в дата центре. Сегодня VPS обычно является частью сперкомпьютера в дата центе, и этот суперкомпьютер логически разделен на серверы. Можно заказать большой логический кусок суперкомпьютера задорого, а можно заказать небольшой логичский кусочек того же суперкомьютера. Кроме VPS использоуется абревиатура VDS – Virtual dedicated server, что означает, что эти понятия синонимы.',
        q3: 'Как мы можем использовать VPS?',
        a3:
          'Для сайтов, посещаемость которых составляет более 10 тыс в сутки, возможности расшаренного хостинга при этом становятся недостаточными, и наилучший хостинг для вашего домена достигается использованием VPS<br/><br/>' +
          'Для разработки и тестирования своих программных продуктов перед публикацией на рабочем сайте – создаете  макет нового сайта в зоне .am, проверяете его работу и когда все нестыковки устраняются, можно размещать в продакшн.<br/><br/>' +
          'Для создания удаленного рабочего стола вашей организации. Сегодня есть большой выбор программ, позволяющих создать виртуальный офис для сотрудников компании. Не нужно беспокоится при переездах, поездках в коммандировки, разных локдаунов, ваш рабочий стол доступен с любой точки мира.<br/><br/>' +
          'Для создания  своего  VPN сервера на VPS сервере, это резко повышает кибербезопасность вашего рабочего стола.<br/><br/>' +
          'Разрешитье подключаться  к  рабочему столу вашей организцации только от данного VPN сервера и можете быть уверенным, что все прямые хакерские атаки на рабочий стол вашей организации будут отражены.<br/><br/>' +
          'Для вашго бухгалтерского сервера, что позволит бухгалтеру, счетоводу, администрации работать удаленно.<br/><br/>' +
          'Для организации видеонаблюдения – храненение видеофайлов требует много памяти, на вашем VPS сервере всегда можно найти оптимальное соотношение между временем хранения, качеством изображения и другими факторами систем видеонаблюдения.<br/><br/>' +
          'Для хранения архива вашей организации, персональных данных сотрудников и пользователей. <br/><br/>' +
          'Для множества различных задач, от вебмейла и Интернета Вещей до собственно игрового сервера.<br/><br/>' +
          'Но нужно помнить, что ведение своего VPS серевера предполагает наличие квалифицированного персонала, который обслуживает этот сервер. Сегодня распространены решения на базе операционной системы Линукс. Есть определенные сложности с приглашением специалистов в этой области, однако эти сложности разрешимы.<br/><br/>' +
          'Не менее важен вопрос выбора поставщика VPS сервера, надежные поставщики сегодня обеспечивают как бесперебойную работу сервера, так и обеспечивают услуги по бэкапу. Компания ЭЙБИСИДОМЕН предлагает сверхнадежные услуги, гибкие тарифные планы, простоту конфигурации параметров вашего VPS сервера.',

        q4: 'Платформы написанные на каких языках можно разместить на VPS серверах?',
        a4: 'Платформы, приготовленные любым современным языком программирования, можно разместить на VPS сервере. Shared hosting предназначен для размещения PHP или статичных сайтов написанных на HTML/CSS/JS, а на VPS вы сами ставите необходимую инфраструктуру для любого языка программирования.',
      },
      ssl: {
        q1: 'Что такое SSL сертификат?',
        a1: 'SSL расшифровывается как Secure Socket Layers, это протокол безопасности. Протокол описывает процесс, в котором передача данных между пользователем и сервером происходит в зашифрованном виде для того, чтобы третьей стороне не удалось подключиться к этому процессу.<br /><br />SSL сертификат подтверждает однозначную связь между владельцем доменного имени и сайта, на который указывает данный домен. <br /><br />SSL сертификат получают у разных надежных провайдеров в Интернете, надежность провайдера подтверждается исторически сложившейся успешной   практикой использования сертификатов данного провайдера. <br /><br />У каждого сертификата есть дата истечения срока службы, что означает, что вам нужно будет обновлять его, чтобы сохранять сайт в безопасности (обычно это происходит каждый год).',
        q2: 'Что такое Wildcard?',
        a2: 'Wildcard используется для того, чтобы SSL-ом был защищен не только домен, а еще и сабдомены',
        q3: 'Как установить SSL сертификат?',
        a3: 'Для инструкций по установлению, перейдите по <a href="ssl-configuration" target="_blank">ссылке</a>',
      },
    },

    accountPopup: {
      clientArea: 'Мой аккаунт',
      editProfile: 'Редактировать профиль',
      logout: 'Выйти',
    },
    pagination: {
      previous: 'Назад',
      next: 'Вперед',
    },

    sslConfigurationPage: {
      header:
        'How to generate a certificate and install the final files in Cpanel?',
      howTo1: 'To generate a certificate one should',
      howTo2: 'To install the final files in Cpanel one should',
      sub: 'In case you website is not hosted by us, please follow your provider’s instructions',
      steps1: {
        step1: 'login your account “My account”',
        step2: 'choose the section services',
        step3:
          'choose the “Configure now” button on the right of the certificate service',
        step4:
          'choose the order type, generate CSR or upload already generated CSR, fill in the administrative contact information, <b>DOWNLOAD KEY <i>(Private Key is needed to have the certificate  installed, thus please keep the Key in a private and safe place)</i></b> and click continue',
        step5:
          'choose Domain Control Validation (DCV) method, click continue and check the email to finish the process <b><i>(in case you choose «Email Validation», choose the email address for validation from the list and be sure that the provided email is already created)</i></b>',
        step6:
          '<i>to download Validation files and to get the Private Key you need to choose the section services, “View details” button on the right of the certificate service</i>',
      },
      steps2: {
        step1: 'choose the subsection "SSL/TLS" in the “security” section',
        step2: 'choose “Manage SSL sites"  button',
        step3: 'domain - Select a Domain / choose your domain name',
        step4: 'insert the certificate and the private key',
        step5:
          'in the section Certificate: (CRT) paste identically and insert the whole content of yourdomain.crt file',
        step6:
          'in the section Private Key (KEY) paste identically and insert the Private Key downloaded with the CSR',
        step7: 'press Install Certificate button',
      },
      steps3: {
        step7: 'login your account “My account”',
        step8: 'choose the section services',
        step9: 'press upload CSR button - <b>Upload CSR</b>',
        step10:
          'choose the preferred option <b><i>(in case you choose «Email Validation», please be sure that the validation email is already created)</i></b>',
        step11:
          'fill in all the gaps and press generate button <b><i>(in case the sections are filled in wrong, the request will be declined)</i></b>',
        step12:
          '<i>In case an “Email Validation” is chosen, a validation link will be sent within one business day to your email address after you have generated/uploaded CSR. Once you follow the link, the certificated will be validated, yourdomain.crt and yourdomain.ca files will be sent.</i>',
        step13:
          '<i>In case you have chosen to verify the validation via file, instructions will be sent within one business day.</i>',
        step14:
          '<i><b>If you have purchased Wildcard SSL, when generating the CSR, fill in the domain name in the appropriate section like *.example.com.</b></i>',
      },
      steps4: {
        step9: 'choose the subsection "SSL/TLS" in the “security” section',
        step10: 'choose "Manage SSL sites" button',
        step11: 'domain - Select a Domain / chose your domain name',
        step12: 'insert the certificate and the private key',
        step13:
          'in the section Certificate: (CRT) paste identically and insert the whole content of yourdomain.crt file',
        step14:
          'in the section Private Key (KEY) paste identically and insert the Private Key downloaded with the CSR',
        step15: 'press Install Certificate button',
      },
    },

    sslAttentionModal: {
      header: 'Внимание',
      info: 'Конфигурации приобретенного SSL сертификата не завершены.',
      info1: 'Инструкции',
    },

    sslConfiguration: {
      new: 'Новый',
      renew: 'Продление',
      header1: 'Административные контактные данные',
      headerOrganization: 'Контактные данные организации',
      orderType: 'Тип заказа',
      back: 'Назад',
      continue: 'Продолжить',
      firstName: 'Имя',
      lastName: 'Фамилия',
      organization: 'Организация',
      jobTitle: 'Должность',
      city: 'Город',
      zipCode: 'Почтовый индекс',
      country: 'Страна',
      phoneNumber: 'Номер телефона',
      email: 'Адрес эл. почты',
      address1: 'Адрес 1',
      address2: 'Адрес 2',
      state: 'Регион/Провинция',
      header2: 'Почта для подтверждения сертификата',
      domain: 'Домен',
      method: 'DCV Метод',
      'Awaiting Configuration': 'Ожидание конфигурации',
      'Configuration Submitted': 'Конфигурации выполнены',
      message: 'Конфигурация завершена!',
      emailMessage: 'Пожалуйста, проверьте <b>{{email}}</b>',
      invalidTyping: 'Поле должно быть на латыни',

      orgFields: {
        'Organization Name': 'Название компании',
        Division: 'Подразделение',
        'LEI code': 'LEI-код',
        Duns: 'DUNS',
        Address: 'Адрес',
        City: 'Город',
        Country: 'Страна',
        Fax: 'Факс',
        'Phone Number': 'Номер телефона',
        'Zip Code': 'Почтовый индекс',
        'State / Region': 'Регион/Провинция',
        leiCodeError: 'LEI-код должен состоять максимум из 20 символов',
        orgDunsError: 'DUNS должен состоять из 9 цифр',
      },
    },

    serviceDetails: {
      regDate: 'Дата регистрации',
      cycle: 'Цикл выставления счетов',
      next: 'Оплачен до',
      domain: 'Домен',
      configStatus: 'Статус конфигурации',
      sslStatus: 'Статус SSL',
      orderId: 'ID заказа партнера',
      approverEmail: 'Почта для подтверждения',
      actions: 'Действия',
      getPrivateKey: 'Получить private key ',
      downloadFile: 'Скачать файлы валидации',
      Active: 'Активный',
      Cancelled: 'Oтмененный',
      Processing: 'Стадия обработки',
      Expired: 'Истекший',
      Terminated: 'Удаленный',
    },

    pricelistPage: {
      zone: 'Зона',
      year: 'год',
      period: 'Период регистрации',
      register: 'Регистрация',
      renew: 'Продление',
      transfer: 'Перенос',
      details: 'Детали зоны',
      search: 'Поиск TLD',
      info: 'Дополнительная информация и/или документ(ы) могут быть запрошены при регистрации домена(ов) в некоторых зонах.',
      info2: 'Подробности:',
    },

    telcellPaymenPage: {
      backButton: 'вернуться в мой аккаунт',
    },
    goToClientAreaPage: {
      backButton: 'вернуться в мой аккаунт',
    },

    paymentSuccessStatus: {
      success: 'Успешно оплачен',
    },
  },
}
