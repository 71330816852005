import * as Yup from 'yup';

export const initialValues = {
  ticketMessage: '',
  files: [],
};

export const yupScheme = Yup.object().shape({
  ticketMessage: Yup.string()
    .min(2, 'validationFields.messageMin')
    .max(2000, 'validationFields.messageMax')
    .required('validationFields.message'),
});
