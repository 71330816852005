import { combineReducers, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { header } from '../components/Header/Header.reducer'
import { registrationPage } from '../pages/RegistrationPage/RegistrationPage.reducer'
import { addContactModalPage } from '../components/Modals/AddContactModal/AddContactModal.reducer'
import { loginModal } from '../components/Modals/LoginModal/LoginModal.reducer'
import { passwordResetModal } from '../components/Modals/ResetPasswordModal/ResetPasswordModal.reducer'
import { contactOwnerModal } from '../components/Modals/ContactOwnerModal/ContactOwnerModal.reducer'
import { nameServerModal } from '../components/Modals/NameServerModal/NameServerModal.reducer'
import { manageFreeDNSModal } from '../components/Modals/ManageFreeDNSModal/ManageFreeDNSModal.reducer'
import { CsrModal } from '../components/Modals/CsrModal/CsrModal.reducer'
import { hostingPage } from '../pages/HostingPage/HostingPage.reducer'
import { vpsPage } from '../pages/VpsPage/VpsPage.reducer'
import { sslPage } from '../pages/SslPage/SslPage.reducer'
import { editProfile } from '../pages/EditProfilePage/EditProfilePage.reducer'
import { productDetailsPage } from '../pages/ProductDetailsPage/ProductDetailsPage.reducer'
import { domainsPage } from '../pages/DomainsPage/DomainsPage.reducer'
import { supportPage } from '../pages/SupportPage/SupportPage.reducer'
import { clientAreaPage } from '../pages/ClientAreaPage/ClientAreaPage.reducer'
import { viewTicket } from '../components/Modals/ViewTicketModal/ViewTicketModal.reducer'
import { renewDomains } from '../components/Modals/RenewDomainModal/RenewDomainModal.reducer'
import { openTicketPage } from '../pages/OpenTicketPage/OpenTicketPage.reducer'
import { invoicePage } from '../pages/InvoicePage/InvoicePage.reducer'
import { resetPasswordPage } from '../pages/ResetPasswordPage/ResetPasswordPage.reducer'
import { userInfo } from '../pages/EditProfilePage/components/PersonalDetails/PersonalDetails.reducer'
import { security } from '../pages/EditProfilePage/components/Security/Security.reducer'
import { twoFactorAuthEnablePage } from '../pages/TwoFactorAuthEnablePage/TwoFactorAuthEnablePage.reducer'
import { creditRechargePage } from '../pages/CreditRechargePage/CreditRechargePage.reducer'
import { modal } from '../common/Modal/Modal.reducer'
import { app } from '../common/App/App.reducer'
import { searchAreaBlock } from '../pages/HomePage/components/SearchAreaBlock/SearchAreaBlock.reducer'
import { transferDomainModal } from '../components/Modals/TransferDomainModal/TransferDomainModal.reducer'
import { errorPage } from '../pages/ErrorPage/ErrorPage.reducer'
import { confirmEmailPage } from '../pages/ConfirmEmailPage/ConfirmEmailPage.reducer'
import { vpsInfo } from '../components/Modals/VpsModal/VpsModal.reducer'
import { glueRecordsModal } from '../components/Modals/GlueRecordsModal/GlueRecordsModal.reducer'
import { viewport } from '../system/viewport/viewport.reducer'
import { autoChargeModal } from '../components/Modals/AutoChargeModal/AutoChargeModal.reducer'
import { domainValidationReducer } from '../pages/SslPage/SslPage.reducer'
import { organizationValidationReducer } from '../pages/SslPage/SslPage.reducer'
import { extendedValidationReducer } from '../pages/SslPage/SslPage.reducer'
import { sslConfiguationPage } from '../pages/SslConfigurationPage/SslConfigurationPage.reducer'
import { getPosts } from '../pages/BlogPage/BlogPage.reducer'
import {
  getDomainPricesAmdReducer,
  getDomainPricesUsdReducer,
} from '../pages/DomainPricesPage/DomainPrices.reducer'
import { telcellPaymentPage } from '../pages/TelcellPaymentPage/TelcellPaymentPage.reducer'

export const reducers = combineReducers({
  app,
  modal,
  registrationPage,
  addContactModalPage,
  header,
  loginModal,
  passwordResetModal,
  contactOwnerModal,
  domainsPage,
  hostingPage,
  sslPage,
  editProfile,
  productDetailsPage,
  supportPage,
  clientAreaPage,
  viewTicket,
  renewDomains,
  openTicketPage,
  invoicePage,
  resetPasswordPage,
  twoFactorAuthEnablePage,
  userInfo,
  security,
  CsrModal,
  creditRechargePage,
  nameServerModal,
  manageFreeDNSModal,
  searchAreaBlock,
  transferDomainModal,
  errorPage,
  confirmEmailPage,
  vpsPage,
  vpsInfo,
  glueRecordsModal,
  viewport,
  autoChargeModal,
  domainValidationReducer,
  organizationValidationReducer,
  extendedValidationReducer,
  sslConfiguationPage,
  getPosts,
  getDomainPricesAmdReducer,
  getDomainPricesUsdReducer,
  telcellPaymentPage,
})

export const configureStore = (initialState = {}) => {
  return createStore(
    reducers,
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
  )
}
