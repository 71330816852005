import * as Yup from 'yup'

export const initialValues = {
  country: {
    value: 'AM',
    label: 'Armenia (AM)',
  },
  state: '',
  city: '',
  org_name: '',
  org_branch: '',
  domain_fqdn: '',
  validationType: 'email',
  validationEmail: 'admin@yourdomain.am',
  email_address: '',
  isWild: false,
}

export const uploadInitialValues = {
  validationType: 'email',
  validationEmail: 'admin@yourdomain.am',
  csr: '',
}

export const config = [
  {
    id: 'country',
    name: 'csrModal.n1',
    placeholder: 'US',
    upperCase: true,
    mask: true,
  },
  {
    id: 'state',
    name: 'csrModal.n2',
    placeholder: 'Texas',
  },
  {
    id: 'city',
    name: 'csrModal.n3',
    placeholder: 'San Antonio',
  },
  {
    id: 'org_name',
    name: 'csrModal.n4',
    placeholder: 'Big Bobs Beepers',
  },
  {
    id: 'org_branch',
    name: 'csrModal.n5',
    placeholder: 'Marketing',
  },
  {
    id: 'domain_fqdn',
    name: 'csrModal.n6',
    placeholder: 'example.com',
    isChangeable: true,
  },
  {
    id: 'email_address',
    name: 'csrModal.n7',
    placeholder: 'example@gmail.com',
  },
]

export const yupScheme = Yup.object().shape({
  isWild: Yup.boolean(),

  country: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string()
      .required('validationFields.country')
      .max(2, 'validationFields.countryMax')
      .min(2, 'validationFields.countryMax'),
  }),
  state: Yup.string()
    .required('validationFields.state')
    .matches(/^[ a-zA-Z0-9@.-]*$/i, 'validationFields.onlyLatin'),
  city: Yup.string()
    .required('validationFields.city')
    .matches(/^[ a-zA-Z0-9@.-]*$/i, 'validationFields.onlyLatin'),
  org_name: Yup.string()
    .required('validationFields.organization')
    .matches(/^[ a-zA-Z0-9@.-]*$/i, 'validationFields.onlyLatin'),
  org_branch: Yup.string()
    .required('validationFields.organizationUnit')
    .matches(/^[ a-zA-Z0-9@.-]*$/i, 'validationFields.onlyLatin'),
  domain_fqdn: Yup.string()
    .when('isWild', {
      is: false,
      then: Yup.string()
        .required('validationFields.commonName')
        .matches(
          /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/,
          'validationFields.invalidTyping'
        ),
    })
    .when('isWild', {
      is: true,
      then: Yup.string()
        .required('validationFields.commonName')
        .matches(
          /^(\*\.)([a-z\d][a-z\d-]*[a-z\d]\.)+[a-z]+$/,
          'validationFields.invalidTyping'
        ),
    }),
  email_address: Yup.string()
    .required('validationFields.email')
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      'validationFields.invalidTyping'
    ),
})

export const uploadYupScheme = Yup.object().shape({
  csr: Yup.string().required('validationFields.csr'),
  validationType: Yup.string().required('validationFields.validationType'),
  validationEmail: Yup.string(),
})
