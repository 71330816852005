import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { InvoicesTable as Self } from "./InvoicesTable";
import {
  toggleFilterPopup,
  showSortPopup,
  showSettingsPopup,
  getInvoices,
} from "../../ClientAreaPage.actions";
import { numberWithCommas } from "../../../../common/App/App.actions";

const mapStateToProps = ({ clientAreaPage, app }) => ({
  data: clientAreaPage.invoicesData,
  loading: clientAreaPage.invoicesLoading,
  success: clientAreaPage.invoicesRequestSuccess,
  total: clientAreaPage.invoicesTotal,
  statuses: clientAreaPage.invoicesStatuses,
  filter: clientAreaPage.filters.invoices,
  offsets: clientAreaPage.offsets,
  sortField: clientAreaPage.sortField.invoices,
  sortOrder: clientAreaPage.sortOrder.invoices,
  auth: app.auth
});

const mapDispatchToProps = {
  toggleFilterPopup,
  showSortPopup,
  showSettingsPopup,
  numberWithCommas,
  getInvoices,
};

export const InvoicesTable = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
