import { connect } from 'react-redux';

import { HostingModal as Self } from './HostingModal';
import { addToCart, numberWithCommas } from '../../../common/App/App.actions';

const mapStateToProps = ({ app }) => ({
  auth: app.auth,
});

const mapDispatchToProps = {
  addToCart,
  numberWithCommas,
};

export const HostingModal = connect(mapStateToProps, mapDispatchToProps)(Self);
