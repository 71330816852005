import React, { useRef } from 'react';

import * as S from './AccountPopup.styles';
import { i18n } from '../../../i18n';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { changeUrl } from '../../../hooks/changeUrl';

export const AccountPopup = ({
  history,
  showAccountPopup,
  isShown,
  logout,
  auth,
}) => {
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, () => showAccountPopup(false));
  if (!isShown) {
    return null;
  }
  return (
    <S.AccountPopup ref={containerRef}>
      <S.Id>ID:{auth.userId}</S.Id>
      <S.AccountLink
        to={changeUrl('/client-area')}
        onClick={() => showAccountPopup(false)}
      >
        {i18n.t('accountPopup.clientArea')}
      </S.AccountLink>
      <S.AccountLink
        to={changeUrl('/edit-profile')}
        onClick={() => showAccountPopup(false)}
      >
        {i18n.t('accountPopup.editProfile')}
      </S.AccountLink>
      <S.LogoutButton
        onClick={() => {
          logout(history);
          showAccountPopup(false);
        }}
      >
        {i18n.t('accountPopup.logout')}
      </S.LogoutButton>
    </S.AccountPopup>
  );
};
