import React, { useCallback, useEffect, useState } from 'react'
import { config, yupScheme, defaultNameservers } from './NameServerModal.config'
import { SetNameServersSuccessModal } from '../../Modals/SetNameServersSuccessModal'
import { GlueRecordsModal } from '../../Modals/GlueRecordsModal'
import { GetCodeModal } from './GetCodeModal'
import { Formik } from 'formik'
import { Loader } from '../../Loader'
import { i18n } from '../../../i18n'

import * as S from './NameServerModal.styles'

export const NameServerModal = ({
  cart,
  hideModal,
  setNewDomainNameServers,
  id,
  isNewDomain,
  shouldGetNameservers,
  nameservers,
  nameserversLoading,
  getNameservers,
  getNameServersCode,
  domain,
  updateNameserversLoading,
  updateNameserversError,
  showModal,
  modalNameserversCodeLoading,
  setNameServers,
  auth,
}) => {
  const [backendError, setBackendError] = useState(null)

  const handleEnter = useCallback((e, submitForm) => {
    if (e.key === 'Enter') {
      submitForm()
    }
  }, [])
  useEffect(() => {
    if (shouldGetNameservers) {
      getNameservers(domain.id)
    }
  }, [])

  return (
    <S.Modal onClick={(e) => e.stopPropagation()}>
      {nameserversLoading ? (
        <S.LoaderWrapper>
          <Loader color="#242e46" size={0.5} />
        </S.LoaderWrapper>
      ) : (
        <S.NameServerModalContainer>
          <S.ModalTitle>{i18n.t('nameServerModal.title')}</S.ModalTitle>
          <S.FormContainer>
            <Formik
              initialValues={
                isNewDomain
                  ? cart.find((item) => item._id === id).nameservers
                  : nameservers || defaultNameservers
              }
              validationSchema={yupScheme}
              onSubmit={async (values, { setSubmitting }) => {
                const clearValues = Object.fromEntries(
                  Object.entries(values).map(([key, value]) => [
                    key,
                    value && value.endsWith('.') ? value.slice(0, -1) : value,
                  ])
                )

                if (isNewDomain) {
                  setNewDomainNameServers(id, clearValues, () => {
                    setTimeout(() => {
                      hideModal()
                    }, 10)
                  })
                } else if (
                  auth.disableCode === true ||
                  auth.disableCode === 'true'
                ) {
                  setNameServers(
                    id,
                    clearValues,
                    undefined,
                    () => {
                      setTimeout(() => {
                        getNameservers(id)
                        hideModal()
                        setTimeout(() => {
                          showModal(SetNameServersSuccessModal)
                        }, 400)
                      }, 10)
                    },
                    (error) => {
                      if (error) {
                        setBackendError(error)
                      }
                    }
                  )
                } else {
                  getNameServersCode(id, (response) => {
                    setTimeout(() => {
                      showModal(GetCodeModal, {
                        nameservers: clearValues,
                        id,
                        email: response?.data?.email,
                      })
                    }, 10)
                  })
                }
                setSubmitting(false)
              }}
            >
              {({
                touched,
                errors,
                isSubmitting,
                handleChange,
                submitForm,
                values,
                dirty,
              }) => (
                <S.StyledForm onKeyPress={() => handleEnter(submitForm)}>
                  <S.HeaderContainer>
                    {/* <S.ModalHeader>
                      {i18n.t('nameServerModal.header')}
                    </S.ModalHeader> */}
                    {!isNewDomain && (
                      <S.Advanced
                        onClick={() =>
                          showModal(GlueRecordsModal, {
                            largeModal: true,
                            targetNameservers: { ...values },
                            domain,
                            shouldGetGlues: true,
                          })
                        }
                      >
                        {i18n.t('glueRecordsModal.title')}
                      </S.Advanced>
                    )}
                  </S.HeaderContainer>
                  <S.StyledWrapper>
                    <S.LeftSide>
                      {config.map(
                        (item, index) =>
                          index < 3 && (
                            <S.FormItem key={item.id}>
                              <S.Label>{i18n.t(item.name)}</S.Label>
                              <S.Input
                                name={item.id}
                                placeholder={item.placeholder}
                              />
                              {errors[item.id] && touched[item.id] && (
                                <S.ErrorMessage>
                                  {i18n.t(errors[item.id])}
                                </S.ErrorMessage>
                              )}
                            </S.FormItem>
                          )
                      )}
                    </S.LeftSide>
                    <S.RightSide>
                      {config.map(
                        (item, index) =>
                          index >= 3 && (
                            <S.FormItem key={item.id}>
                              <S.Label>{i18n.t(item.name)}</S.Label>
                              <S.Input
                                name={item.id}
                                placeholder={item.placeholder}
                              />
                              {errors[item.id] && touched[item.id] && (
                                <S.ErrorMessage>
                                  {i18n.t(errors[item.id])}
                                </S.ErrorMessage>
                              )}
                            </S.FormItem>
                          )
                      )}
                      <S.MainErrorMessage>
                        {updateNameserversError && updateNameserversError}
                      </S.MainErrorMessage>
                      {backendError && (
                        <S.ErrorMessage>{backendError}</S.ErrorMessage>
                      )}
                      <S.SubmitButton
                        type="submit"
                        disabled={
                          isSubmitting || !dirty || modalNameserversCodeLoading
                        }
                      >
                        {modalNameserversCodeLoading ||
                        updateNameserversLoading ? (
                          <Loader color="#ffffff" size={0.5} />
                        ) : (
                          i18n.t('nameServerModal.submit')
                        )}
                      </S.SubmitButton>
                    </S.RightSide>
                  </S.StyledWrapper>
                </S.StyledForm>
              )}
            </Formik>
          </S.FormContainer>
          <S.CloseModalButton onClick={() => hideModal()} />
        </S.NameServerModalContainer>
      )}
    </S.Modal>
  )
}
