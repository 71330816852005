import FetchApi from '../../../helpers/FetchApi';
import { AUTO_CHARGE_MODAL_TYPES } from './AutoChargeModal.types';

export const setAutoCharge = (status, onSuccess) => async (dispatch) => {
  try {
    if (typeof status === 'undefined') {
      return;
    }

    dispatch({
      type: AUTO_CHARGE_MODAL_TYPES.AUTO_CHARGE_MODAL_TYPES_SET_AUTOCHARGE_REQUEST,
    });

    await FetchApi.post('users/updateAutoPay', { autopay: status });

    dispatch({
      type: AUTO_CHARGE_MODAL_TYPES.AUTO_CHARGE_MODAL_TYPES_SET_AUTOCHARGE_SUCCESS,
      value: status,
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    dispatch({
      type: AUTO_CHARGE_MODAL_TYPES.AUTO_CHARGE_MODAL_TYPES_AUTOCHARGE_FAIL,
      error: e.data.error,
    });
  }
};
