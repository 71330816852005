import React, { useEffect } from 'react'
import { hostingPackages, colors } from '../HostingPage/HostingPage.constants'
import { LayoutCommon } from '../../layouts/LayoutCommon'
import { CookieModal } from '../../components/Modals/CookieModal/CookieModal'
import { Helmet } from 'react-helmet'
import { i18n } from '../../i18n'
import {
  SearchAreaBlock,
  // AboutMobileAppBlock,
  HostingInfoBlock,
  HostingPackagesBlock,
  BuyHostingWithSale,
  ServicesBlock,
  WhyChooseUsBlock,
  CustomerOpinionsBlock,
} from './components'
import Cookies from 'js-cookie'

import * as S from './HomePage.styles'
// import { AutoChargeModal } from '../../components/Modals/AutoChargeModal'
// import moment from 'moment'
// import { MaintenanceModal } from '../../components/Modals/MaintenanceModal/MaintenanceModal'

const HomePage = ({ history, auth, showModal, autopay, userInfoLoading }) => {
  // useEffect(() => {
  //   const autoChargeShowedDate = localStorage.autoChargeShowedDate;

  //   const diff = moment().diff(moment(autoChargeShowedDate), 'minute', true);

  //   if (
  //     !userInfoLoading &&
  //     auth.token &&
  //     autopay === false &&
  //     (diff >= 1 || !autoChargeShowedDate)
  //   ) {
  //     localStorage.setItem('autoChargeShowedDate', new Date());
  //     showModal(AutoChargeModal);
  //   }
  // }, [auth.token, autopay]);

  // useEffect(() => {
  //   showModal(MaintenanceModal);
  // }, []);

  const allow = Cookies.get('allowCookies')

  useEffect(() => {
    setTimeout(() => {
      if (!allow) {
        showModal(CookieModal, {})
      }
    }, 10)
  }, [allow])

  return (
    <LayoutCommon>
      <Helmet>
        <title>{i18n.t('headers.homePage')}</title>
        <link rel="alternate" href="https://internet.am/en/" hreflang="en" />
        <link rel="alternate" href="https://internet.am/ru/" hreflang="ru" />
        <link rel="alternate" href="https://internet.am/" hreflang="am-AM" />
        <meta
          name="description"
          content={i18n.t('headersDescriptions.homePage')}
        />
        <meta name="keywords" content={i18n.t('headerKeywords.homePage')} />
        <meta property="og:title" content={i18n.t('headers.homePage')} />
        <meta
          property="og:description"
          content={i18n.t('headersDescriptions.homePage')}
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ABC Domain LLC" />
        <meta
          property="og:image"
          content="https://internet.am/images/fb_logo.png"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={i18n.t('headers.homePage')} />
        <meta
          name="twitter:description"
          content={i18n.t('headersDescriptions.homePage')}
        />
        <meta
          name="twitter:image"
          content="https://internet.am/images/fb_logo.png"
        />
      </Helmet>
      <S.HomePageContainer>
        <SearchAreaBlock history={history} />
        {/* {<AboutMobileAppBlock aboutMobileAppBlock />} */}
        <HostingInfoBlock />
        <HostingPackagesBlock
          history={history}
          data={hostingPackages}
          colors={colors}
          showSecondaryText
        />
        <BuyHostingWithSale />
        <ServicesBlock />
        <WhyChooseUsBlock />
        <CustomerOpinionsBlock />
      </S.HomePageContainer>
    </LayoutCommon>
  )
}

export { HomePage }
