import { SUPPORT_TYPES } from './SupportPage.types';

export const changeTab = tab => ({
  type: SUPPORT_TYPES.CHANGE_TAB,
  tab,
});

export const changeExpand = id => ({
  type: SUPPORT_TYPES.EXPAND_QUESTION,
  id,
});

export const setSearchQuery = value => ({
  type: SUPPORT_TYPES.SET_SEARCH_QUERY,
  value,
});

export const setResults = results => ({
  type: SUPPORT_TYPES.SET_RESULTS,
  results,
});

export const setSearchResultsShouldShow = status => ({
  type: SUPPORT_TYPES.SET_SEARCH_RESULTS_SHOULD_SHOW,
  status,
});
