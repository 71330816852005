import styled from 'styled-components'
import errorIcon from '../../../../images/error-icon.svg'
import headerBackground from '../../../../images/headerBackground.svg'
import { Form, Field } from 'formik'
import { HashLink as Link } from 'react-router-hash-link'

export const TLDContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    justify-content: space-between;
  }
`

export const TldCardBackground = styled.div`
  backdrop-filter: blur(20px);
  border-radius: 20px;
  width: calc(50% - 25px);
  min-width: 130px;

  @media (min-width: 768px) {
  }
  @media (min-width: 1280px) {
  }
`

export const TLD = styled.button`
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 14px;
  color: #ffffff;
  font-weight: ${(p) => (p.isActive ? 'bold' : 'normal')};
  background-color: ${(p) => (p.isActive ? 'rgba(36,46,70, 0.8)' : 'none')};
  min-width: 48px;
  text-align: center;
  padding: 4px;
  margin-top: 17px;
  margin-right: 15px;
  &:hover {
    background-color: rgba(36, 46, 70, 0.4);
  }
  @media (min-width: 768px) {
    margin-right: 0px;
  }
`

export const StyledFormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  gap: 10px;

  @media (min-width: 768px) {
    max-width: 278px;
    flex-direction: row;
  }
  @media (min-width: 1280px) {
    padding: 40px 20px 45px 0px;
  }
`

export const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    width: unset;
  }
`

export const ErrorIconContainer = styled.div`
  position: absolute;
  right: 90px;
  top: 2px;
  padding: 10px;
  height: 30px;
  width: 30px;
  box-sizing: border-box;

  @media (min-width: 768px) {
  }
  @media (min-width: 1280px) {
    top: 7px;
  }
`

export const ErrorIcon = styled.div`
  position: absolute;
  height: 18px;
  width: 18px;
  background-image: url(${errorIcon});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DomainSearchButton = styled.button`
  border: none;
  background-color: #242e46;
  border-radius: 5px;
  font-size: 14px;
  color: #ffffff;
  height: 40px;
  width: 100%;
  margin-top: 10px;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }

  @media (min-width: 768px) {
    width: 100px;
    padding: 12px 27px 12px 28px;
    margin-top: 0px;
    margin-left: 10px;
  }
  @media (min-width: 1280px) {
    width: 120px;
    height: 50px;
    font-size: 18px;
    padding: 15px 30px 15px 30px;
    margin-top: 0px;
  }
`

export const SearchInputWrapper = styled.div`
  display: flex;
  border-radius: 5px;
  // border: 1px solid #E0E0E0;
  ${(p) =>
    p.isError ? 'border: 1px solid #DD2326' : 'border: 1px solid #E0E0E0'};
`

export const SearchInput = styled(Field)`
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  margin-right: 9px;
  font-size: 14px;
  font-style: normal;
  padding: 0 10px;

  @media (min-width: 768px) {
    margin-right: 0;
    width: unset;
  }
  &:focus {
  }

  @media (min-width: 1280px) {
    width: 395px;
    height: 50px;
    font-size: 18px;
  }
`

// export const SearchAreaTitle = styled.h1`
//   font-size: 28px;
//   font-style: normal;
//   font-weight: 500;
//   letter-spacing: 0em;
//   text-align: left;
//   z-index: 2;
//   width: 100%;
//   color: #242e46;

//   @media (min-width: 768px) {
//     font-size: 32px;
//     width: 55%;
//     padding-top: 50px;
//   }

//   @media (min-width: 1280px) {
//     font-size: 62px;
//     width: 721px;
//     padding-top: 75px;
//   }
// `

export const SearchAreaBackground = styled.div`
  background-image: url(${headerBackground});
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 310px;
  right: 0px;
  width: 400px;
  height: 690px;

  @media (min-width: 768px) {
    top: -60px;
    right: 0px;
    width: 430px;
    height: 690px;
  }
  @media (min-width: 1280px) {
    width: 610px;
    height: 870px;
    top: -85px;
    right: 0px;
  }
`

export const SearchAreaImage = styled.div`
  position: relative;
  background-repeat: no-repeat;
  background-color: white;
  background-size: cover;
  width: 100%;
  display: flex;
  padding: 56px 0;

  @media (min-width: 768px) {
    padding: 100px 0;
  }

  @media (min-width: 1280px) {
    // padding: 200px 0;
  }
`

export const SearchAreaContainer = styled.div`
  min-height: 110px;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px;
  z-index: 1;
  box-sizing: border-box;

  @media (min-width: 768px) {
    padding: 0px 40px;
    width: 100%;
  }
  @media (min-width: 1150px) {
    padding: 0px 40px 0px 100px;
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`

export const MainInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const InfoContainer = styled.h1`
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  z-index: 2;
  width: 100%;
  color: #242e46;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 32px;
    width: 55%;
    padding-top: 50px;
  }

  @media (min-width: 1280px) {
    font-size: 62px;
    width: 721px;
    padding-top: 75px;
  }
`
export const InfoContainerSale = styled.span`
  background: -webkit-linear-gradient(269.9deg, #3c1563 2.56%, #9222ae 93.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;

  @media (min-width: 768px) {
  }
  @media (min-width: 1280px) {
    font-size: 72px;
  }
`
export const PromoCodeContainer = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  font-feature-settings: 'liga' off;
  color: #828283;
  padding: 20px 20px 40px 0px;
  width: 100%;

  span {
    color: #270840;
  }

  @media (min-width: 768px) {
  }
  @media (min-width: 1280px) {
    font-size: 20px;
    line-height: 150%;
    width: 615px;
  }
`
export const PriceCardsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 300px;
  max-height: 300px;
  z-index: 100;
  margin-top: 120px;
  @media (min-width: 768px) {
    margin-top: unset;
  }

  @media (min-width: 1280px) {
    max-width: 550px;
    max-height: 500px;
    gap: 50px;
  }
`

export const PriceCard = styled.div`
  padding: 20px 45px 34px 30px;
  width: 145px;
  border-radius: 20px;
  background: ${({ background }) => background};
  backdrop-filter: blur(20px);
`

export const DomainNameSelect = styled.div`
  width: 62px;
  cursor: pointer;
  display: flex;
  align-items: center;
  top: 13px;
  right: 0px;
  padding-right: 15px;
  border-left: 1px solid #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 12px;
    height: 12px;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 1280px) {
  }
`

export const OtherDomainContainer = styled.div`
  width: 90px;
  background: #ffffff;
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 390px;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 5px #ccc;
`
export const DomainName = styled.div`
  width: 62px;
  font-size: 14px;
  padding-left: 10px;
  color: #121723;

  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 1280px) {
    font-size: 18px;
  }
`

export const SelectedDomainsContainer = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
`
export const SelectedDomainName = styled.div`
  height: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-right: 0.5px solid #c4c4c4;

  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 1280px) {
    font-size: 18px;
  }
`
export const SelectedDomainRemove = styled.div`
  cursor: pointer;
  color: #242e46;
  opacity: 0.54;
  margin-left: 9px;
  margin-right: 9px;
  width: 10px;
  height: 10px;
  align-items: center @media (min-width: 768px) {

  }
  @media (min-width: 1280px) {
    margin-right: 14px;
    margin-left: 14px;
    height: 17px;
  }
`
export const SelectedDomainBorder = styled.div`
  width: 0px;
  height: 30px;
  border: 0.5px solid #c4c4c4;
`

export const RemoveIcon = styled.img`
  user-select: none;
`

export const Info = styled(Link)`
  font-weight: 400;
  font-size: 14px;
  color: #242e46;
  margin-top: 20px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 1280px) {
    font-size: 18px;
  }
`
