import React from 'react';
import * as S from './CustomerOpinionsBlock.styles';
import { customersOpinion } from '../../HomePage.constants';
import Container from '../../../../components/Container';
import { i18n } from '../../../../i18n';

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 100,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const CustomerOpinionsBlock = () => (
  <S.CustomersOpinion>
    <Container>
      <S.CustomerOpinionDiv>
        <S.CustomerOpinionTitle>
          {i18n.t('customerOpinionBlock.title')}
        </S.CustomerOpinionTitle>
        <S.StyledSlider {...settings}>
          {customersOpinion.map((item, index) => (
            <S.SlideContainer key={item.id}>
              <S.OpinionDiv isArmenian={item.id === 'opinion1' || item.id === 'opinion3'}>
                <S.OpinionDivContent
                  dangerouslySetInnerHTML={{ __html: item.opinion }}
                />
                <S.OpinionDivAuthor>{item.author}</S.OpinionDivAuthor>
              </S.OpinionDiv>
              {/* <S.ImageDiv>
                <S.CustomerImage
                  src={customerImage}
                  alt="Customer image"
                  title="customer image"
                />
              </S.ImageDiv> */}
            </S.SlideContainer>
          ))}
        </S.StyledSlider>
      </S.CustomerOpinionDiv>
    </Container>
  </S.CustomersOpinion>
);
