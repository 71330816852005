import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { QuestionsList as Self } from './QuestionsList';
import { changeExpand } from '../../pages/SupportPage/SupportPage.actions';

const mapStateToProps = ({ supportPage }) => ({
  expandedQuestion: supportPage.expandedQuestion,
});

const mapDispatchToProps = {
  changeExpand,
};

export const QuestionsList = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
