import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 40px 20px;
`

export const TextContainer = styled.div`
  margin: 0;
  padding: 0;
`
export const Text = styled.a`
  line-height: 14px;
  font-size: 12px;
  color: #484857;
  letter-spacing: 1px;
  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Number of lines to display */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  @media (min-width: 768px) {
    margin-top: 14px;
    font-size: 14px;
  }
`

export const Button = styled.span`
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background: #00c084;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  letter-spacing: normal;
  border: none;
  text-align: center;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }

  @media (min-width: 768px) {
    margin-top: 10px;
  }
`
