import React, { useCallback, useEffect, useState, useRef } from 'react'
import { changeUrl, constructCannonical } from '../../hooks/changeUrl'
import { RegistrationSuccessModal } from '../../components/Modals/RegistrationSuccessModal'
import { initialValues, yupScheme } from './RegistrationPage.config'
import { LayoutCommon } from '../../layouts/LayoutCommon'
import { Formik, Form } from 'formik'
import { Checkbox } from '../../components/Checkbox'
import { countries } from '../../constants/countries'
import { Helmet } from 'react-helmet'
import { Loader } from '../../components/Loader'
import { i18n } from '../../i18n'
import ReadMore from '../../components/ReadMore/ReadMore'
import * as S from './RegistrationPage.styles'
import PhoneInput from 'react-phone-input-2'

import 'react-phone-input-2/lib/style.css'
import slugify from 'react-slugify'
import Cookies from 'js-cookie'

const RegistrationPage = ({
  history,
  register,
  loading,
  error,
  showModal,
  cleanRegisterError,
}) => {
  const formRef = useRef(null)
  const [inputCountryCode, setInputCountryCode] = useState('')
  const handleEnter = useCallback((e, submitForm) => {
    if (e.key === 'Enter') {
      submitForm()
    }
  }, [])

  useEffect(() => {
    cleanRegisterError()
  }, [])

  const scrollToError = (errors, touched) => {
    if (!Object.keys(touched).length) {
      setTimeout(() => {
        document.querySelector(`input[name=firstname]`).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      })
    }
    const firstError = Object.keys(errors)[0]
    const input = document.querySelector(`input[name="${firstError}"]`)
    if (input) {
      setTimeout(() => {
        input.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      })
    }
  }

  return (
    <LayoutCommon>
      <Helmet>
        <title>{i18n.t('headers.registrationPage')}</title>
        {/* <link rel="canonical" href={constructCannonical('registration')} /> */}
        <link
          rel="alternate"
          href="https://internet.am/en/registration"
          hreflang="en"
        />
        <link
          rel="alternate"
          href="https://internet.am/ru/registration"
          hreflang="ru"
        />
        <link
          rel="alternate"
          href="https://internet.am/registration"
          hreflang="am-AM"
        />
        <meta
          name="description"
          content={i18n.t('headersDescriptions.registrationPage')}
        />
        <meta
          property="og:title"
          content={i18n.t('headers.registrationPage')}
        />
        <meta
          property="og:description"
          content={i18n.t('headersDescriptions.registrationPage')}
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ABC Domain LLC" />
        <meta
          property="og:image"
          content="https://internet.am/images/fb_logo.png"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={i18n.t('headers.registrationPage')}
        />
        <meta
          name="twitter:description"
          content={i18n.t('headersDescriptions.registrationPage')}
        />
        <meta
          name="twitter:image"
          content="https://internet.am/images/fb_logo.png"
        />
      </Helmet>
      <S.RegistrationContainer>
        <S.PageTitle>{i18n.t('registrationPage.title')}</S.PageTitle>
        <S.InformationText>
          {i18n.t('registrationPage.infoText')}
        </S.InformationText>
        <S.Text>
          {i18n.t('registrationPage.text')}{' '}
          <span>{i18n.t('registrationPage.textBold')}</span>
        </S.Text>

        <S.FormContainer>
          <Formik
            initialValues={initialValues}
            validationSchema={yupScheme}
            innerRef={formRef}
            onSubmit={async (values, { setSubmitting }) => {
              const phonenumber = values.phonenumber
                .replace('+', '00')
                .replace(/ /g, '')
                .replace(/-/g, '')

              const formattedValues = {
                firstname: values.firstname,
                lastname: values.lastname,
                legalCode: values.legalCode,
                email: values.email,
                phonenumber,
                password: values.password,
                password2: values.password2,
                country: values.country,
                state: values.state,
                city: values.city,
                address1: values.address1,
                postcode: values.postcode,
                currency: values.currency,
                autopay: values.autopay,
                lang: Cookies.get('lng'),
              }

              if (window.grecaptcha) {
                window.grecaptcha.ready(async () => {
                  const token = await window.grecaptcha.execute(
                    window.grecaptchaClientId,
                    { action: 'SIGN_UP' }
                  )

                  const success = await register({
                    ...formattedValues,
                    legalStatus: values.personType === 0 ? 'physical' : 'legal',
                    token,
                  })
                  if (success) {
                    showModal(RegistrationSuccessModal)
                    history.push(changeUrl('/'))
                  }
                  setSubmitting(false)
                })
              }
            }}
          >
            {({
              touched,
              errors,
              isSubmitting,
              handleChange,
              values,
              setFieldValue,
              submitForm,
              resetForm,
              handleBlur,
            }) => {
              return (
                <Form onKeyPress={() => handleEnter(submitForm)}>
                  <S.ButtonsContainer>
                    <S.Button
                      type="button"
                      isChecked={values.personType === 0}
                      onClick={() => {
                        resetForm()
                        setFieldValue('personType', 0)
                        cleanRegisterError()
                      }}
                    >
                      {i18n.t('registrationPage.individual')}
                    </S.Button>
                    <S.Button
                      type="button"
                      isChecked={values.personType === 1}
                      onClick={() => {
                        resetForm()
                        setFieldValue('personType', 1)
                        cleanRegisterError()
                      }}
                    >
                      {i18n.t('registrationPage.entity')}
                    </S.Button>
                  </S.ButtonsContainer>
                  {values.personType === 0 ? (
                    <S.FormSection>
                      <S.FormItem>
                        <S.Label>
                          {i18n.t('registrationPage.firstname')}*
                        </S.Label>
                        <S.Input
                          placeholder="Poghos"
                          name="firstname"
                          onChange={handleChange}
                          value={values.firstname}
                        />
                        {errors.firstname && touched.firstname && (
                          <S.ErrorMessage>
                            {i18n.t(errors.firstname)}
                          </S.ErrorMessage>
                        )}
                      </S.FormItem>
                      <S.FormItem>
                        <S.Label>
                          {i18n.t('registrationPage.lastname')}*
                        </S.Label>
                        <S.Input
                          placeholder="Poghosyan"
                          name="lastname"
                          onChange={handleChange}
                          value={values.lastname}
                        />
                        {errors.lastname && touched.lastname && (
                          <S.ErrorMessage>
                            {i18n.t(errors.lastname)}
                          </S.ErrorMessage>
                        )}
                      </S.FormItem>
                      <S.FormItem>
                        <S.Label>
                          {i18n.t('registrationPage.passport')}*
                        </S.Label>
                        <S.Input
                          placeholder="AM0000000"
                          name="legalCode"
                          onChange={handleChange}
                          value={values.legalCode}
                        />
                        {errors.legalCode && touched.legalCode && (
                          <S.ErrorMessage>
                            {i18n.t(errors.legalCode)}
                          </S.ErrorMessage>
                        )}
                      </S.FormItem>
                    </S.FormSection>
                  ) : (
                    <S.FormSection>
                      <S.FormItem>
                        <S.Label>
                          {i18n.t('registrationPage.companyName')}*
                        </S.Label>
                        <S.Input
                          placeholder="Example"
                          name="firstname"
                          onChange={handleChange}
                          value={values.firstname}
                        />
                        {errors.firstname && touched.firstname && (
                          <S.ErrorMessage>
                            {i18n.t(errors.firstname)}
                          </S.ErrorMessage>
                        )}
                      </S.FormItem>
                      <S.FormItem>
                        <S.Label>
                          {i18n.t('registrationPage.companyType')}*
                        </S.Label>
                        <S.Input
                          placeholder="LLC"
                          name="lastname"
                          onChange={handleChange}
                          value={values.lastname}
                        />
                        {errors.lastname && touched.lastname && (
                          <S.ErrorMessage>
                            {i18n.t(errors.lastname)}
                          </S.ErrorMessage>
                        )}
                      </S.FormItem>
                      <S.FormItem>
                        <S.Label>{i18n.t('registrationPage.taxCode')}*</S.Label>
                        <S.Input
                          placeholder="321321"
                          name="legalCode"
                          onChange={handleChange}
                          value={values.legalCode}
                        />
                        {errors.legalCode && touched.legalCode && (
                          <S.ErrorMessage>
                            {i18n.t(errors.legalCode)}
                          </S.ErrorMessage>
                        )}
                      </S.FormItem>
                    </S.FormSection>
                  )}
                  <S.FormSection>
                    <S.FormItem className="phoneInput">
                      <S.Label>{i18n.t('registrationPage.tel')}*</S.Label>
                      <PhoneInput
                        placeholder={i18n.t(
                          'registrationPage.phonenumberPlaceholder'
                        )}
                        value={values.phonenumber}
                        onChange={(phone, data) => {
                          setInputCountryCode(data.countryCode)
                          if (data.countryCode !== inputCountryCode) {
                            setFieldValue('phonenumber', data.dialCode)
                          } else {
                            setFieldValue('phonenumber', phone)
                          }
                        }}
                        inputProps={{
                          name: 'phonenumber',
                        }}
                        onBlur={handleBlur}
                        masks={{ am: '(..) ......' }}
                        country="am"
                      />
                      {errors.phonenumber && touched.phonenumber && (
                        <S.ErrorMessage>
                          {i18n.t(errors.phonenumber)}
                        </S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem type="email">
                      <S.Label>{i18n.t('registrationPage.email')}*</S.Label>
                      <S.Input
                        placeholder="example@example.com"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                      />
                      {errors.email && touched.email && (
                        <S.ErrorMessage>{i18n.t(errors.email)}</S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem>
                      <S.Label>{i18n.t('registrationPage.password')}*</S.Label>
                      <S.Input
                        type="password"
                        placeholder="******"
                        name="password"
                        onChange={handleChange}
                        value={values.password}
                      />
                      {errors.password && touched.password && (
                        <S.ErrorMessage>
                          {i18n.t(errors.password)}
                        </S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem>
                      <S.Label>
                        {i18n.t('registrationPage.confirmPassword')}*
                      </S.Label>
                      <S.Input
                        type="password"
                        placeholder="******"
                        name="password2"
                        onChange={handleChange}
                        value={values.password2}
                      />
                      {errors.password2 && touched.password2 && (
                        <S.ErrorMessage>
                          {i18n.t(errors.password2)}
                        </S.ErrorMessage>
                      )}
                    </S.FormItem>
                  </S.FormSection>
                  <S.FormSection>
                    <S.FormItem>
                      <S.Label>{i18n.t('registrationPage.country')}*</S.Label>
                      <S.StyledSelect
                        value={countries.find(
                          (item) => item.value === values.country
                        )}
                        isSearchable={true}
                        name="country"
                        options={countries}
                        onChange={(option) =>
                          setFieldValue('country', option.value)
                        }
                        classNamePrefix="react-select"
                      />
                      {errors.country && touched.country && (
                        <S.ErrorMessage>
                          {i18n.t(errors.country)}
                        </S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem>
                      <S.Label>{i18n.t('registrationPage.state')}*</S.Label>
                      <S.Input
                        placeholder="Yerevan"
                        name="state"
                        onChange={handleChange}
                        value={values.state}
                      />
                      {errors.state && touched.state && (
                        <S.ErrorMessage>{i18n.t(errors.state)}</S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem>
                      <S.Label>{i18n.t('registrationPage.city')}*</S.Label>
                      <S.Input
                        placeholder="Yerevan"
                        name="city"
                        onChange={handleChange}
                        value={values.city}
                      />
                      {errors.city && touched.city && (
                        <S.ErrorMessage>{i18n.t(errors.city)}</S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem>
                      <S.Label>{i18n.t('registrationPage.address')}*</S.Label>
                      <S.Input
                        placeholder="Tumanyan, 3"
                        name="address1"
                        onChange={handleChange}
                        value={values.address1}
                      />
                      {errors.address1 && touched.address1 && (
                        <S.ErrorMessage>
                          {i18n.t(errors.address1)}
                        </S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem>
                      <S.Label>
                        {i18n.t('registrationPage.postalIndex')}*
                      </S.Label>
                      <S.Input
                        placeholder="0023"
                        name="postcode"
                        onChange={handleChange}
                        value={values.postcode}
                      />
                      {errors.postcode && touched.postcode && (
                        <S.ErrorMessage>
                          {i18n.t(errors.postcode)}
                        </S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem className="radio">
                      <S.RadioButtonsContainer>
                        <S.Radio onClick={() => setFieldValue('currency', 1)}>
                          <S.Input
                            type="radio"
                            checked={values.currency === 1}
                            name="currency_changer"
                            className="radio"
                          />
                          <S.Label className="radio">AMD</S.Label>
                        </S.Radio>
                        <S.Radio onClick={() => setFieldValue('currency', 2)}>
                          <S.Input
                            type="radio"
                            name="currency_changer"
                            className="radio"
                            checked={values.currency === 2}
                          />
                          <S.Label className="radio">USD</S.Label>
                        </S.Radio>
                      </S.RadioButtonsContainer>
                    </S.FormItem>
                    <S.TermsContainer>
                      <S.Term>
                        <S.CheckboxContainer>
                          <input type="checkbox" checked />
                          <span className="checkmark " />
                        </S.CheckboxContainer>
                        <S.Text className="terms">
                          {i18n.t('registrationPage.terms')}
                        </S.Text>
                      </S.Term>

                      {/* Code autoPay, don't delete  */}

                      {/* <S.Term>
                          <Checkbox
                            name="autopay"
                            onBlur={handleBlur}
                            value={values.autopay}
                            onChange={() => {
                              setFieldValue('autopay', !values.autopay)
                            }}
                          />
                          <S.Text className="terms">
                            {i18n.t('registrationPage.autoPay')}
                          </S.Text>
                        </S.Term> */}
                      <S.Term>
                        <Checkbox
                          name="privacy"
                          onBlur={handleBlur}
                          value={values.privacy}
                          onChange={() => {
                            setFieldValue('privacy', !values.privacy)
                          }}
                        />
                        <S.Text className="terms">
                          {i18n.t('registrationPage.imAgree')}
                        </S.Text>
                      </S.Term>
                      {errors.privacy && (
                        <S.ErrorMessage>
                          {i18n.t(errors.privacy)}
                        </S.ErrorMessage>
                      )}
                    </S.TermsContainer>
                    <S.RegistrationButton
                      type="submit"
                      disabled={isSubmitting}
                      onClick={
                        !!Object.keys(errors).length ||
                        !Object.keys(touched).length
                          ? () => scrollToError(errors, touched)
                          : () => undefined
                      }
                    >
                      {loading ? (
                        <Loader color="#FFF" size={0.5} />
                      ) : (
                        i18n.t('registrationPage.regButton')
                      )}
                    </S.RegistrationButton>
                  </S.FormSection>
                  {error && (
                    <S.ErrorMessage spaced>
                      {i18n.t(`backend.registrationPage.${slugify(error)}`)}
                      {i18n.t(`registrationPage.reCaptchaError`)}
                    </S.ErrorMessage>
                  )}
                </Form>
              )
            }}
          </Formik>

          {/* <ReadMore /> */}
        </S.FormContainer>
      </S.RegistrationContainer>
    </LayoutCommon>
  )
}

export { RegistrationPage }
