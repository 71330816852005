import React from 'react'
import * as S from './PrivateKeyModal.styles'

export const PrivateKeyModal = ({ hideModal, privateKey }) => {
  return (
    <S.Modal>
      <S.PrivateKeyModal>
        <S.CloseModalButton onClick={hideModal} />
        <S.PrivateKeyInput value={privateKey} />
      </S.PrivateKeyModal>
    </S.Modal>
  )
}
