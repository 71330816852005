import styled from 'styled-components';
import Container from '../../../../components/Container';
import mobileAppPhoto from '../../../../images/mobileAppPhoto.svg';

export const AboutMobileAppDesktop = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    justify-content: inherit;
    align-items: center;
  }
  @media (min-width: 1280px) {
    padding-top: 30px;
  }
`;

export const AboutMobileAppTexts = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: column;
    // padding-top: 50px;
  }
  @media (min-width: 1280px) {
    flex-direction: column;
    padding-top: 30px;
  }
`;

export const AboutMobileAppTitle = styled.div`
  font-size: 20px;
  color: #242e46;
  order: 1;
  text-align: center;
  padding: 100px 40px 30px 40px;
  @media (min-width: 768px) {
    font-size: 24px;
    padding-bottom: -1px;
    padding-left: 20px;
    // padding-top: 200px;
    order: unset;
    text-align: left;
  }
  @media (min-width: 1280px) {
    font-size: 24px;
    padding-left: 13px;
    padding-top: 60px;
    order: unset;
  }
`;

export const MobileAppAdvantageListItem = styled.li`
  margin-top: 9px;
  padding-bottom: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  list-style: none;
  color: #242e46;

  > span {
    padding-left: 18px;
    color: #242e46;
    display: block;
  }

  &::before {
    color: #242e46;
    content: ' ';
    margin-top: 4px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #737373;
    font-weight: bold;
    display: inline-block;
    margin-left: -10px;
    position: absolute;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    float: unset;
    color: #242e46;

    &::before {
      background: #737373;
    }
  }

  @media (min-width: 1280px) {
    font-size: 16px;
    float: unset;
    color: #242e46;

    &::before {
      background: #242e46;
    }
  }
`;

export const MobileAppAdvantageUnorderedList = styled.ul`
  order: 3;
  padding-top: 25px;
  width: 300px;
  margin: auto;  
}

  @media(min-width: 480px){
    width: 360px;
    margin: unset;
  }

  @media (min-width: 768px) {
    padding-left: 25px;
    order: unset;
    width: 450px;
  }
  @media(min-width: 1280px){
    order: unset;
    width: unset;

}
`;

export const MobileAppImage = styled.div`
width: 140px;
height: 250px;
display: flex;
margin: auto;
order: 2;
justify-content: center;
background: url(${mobileAppPhoto}) no-repeat;


@media (min-width: 768px) {
  order: unset;
  width: 140px;
  margin: 0;
  height: 250px;
  margin-top: 100px;
  background: url(${mobileAppPhoto}) no-repeat;
}

@media (min-width: 1280px) {
  order: unset;
  height: 250px;
  margin-top: 60px auto 0px auto;
  width: 100%
  padding-rigth: 0px;
  margin-left: 203px;
  margin-top: 80px;
  background: url(${mobileAppPhoto}) no-repeat;
}
`;

export const MobileAppContainerSideFiller = styled.div`
  background-color: #ffffff;
`;

export const ContainerOfMobileAppItems = styled(Container)`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  justify-content: space-around;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
  @media (min-width: 1280px) {
    flex-direction: row;
  }
`;

export const AboutMobileApp = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 30px;
  width: 100%;
  display: block;

  @media (min-width: 768px) {
    display: none;
    padding: 0px 20px;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: 1280px) {
    padding-top: 0px;
    justify-content: space-between;
    flex-direction: row;
  }
`;
