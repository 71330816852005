import { INVOICE_TYPES } from './InvoicePage.types';
import { MODAL_TYPES } from '../../common/Modal/Modal.types';

const initialState = {
  loading: false,
  data: {},
  error: null,
  paymentFormLoading: false,
  paymentFormData: {
    domainStatus: [],
  },
  paymentFormError: null,
  applyCreditLoading: false,
  applyButtonShouldHide: false,
  cart: [],
};

export function invoicePage(state = initialState, action) {
  switch (action.type) {
    case INVOICE_TYPES.GET_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
        data: {},
        error: null,
      };
    case INVOICE_TYPES.GET_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };
    case INVOICE_TYPES.GET_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.error,
      };
    case INVOICE_TYPES.CLEAR_INVOICE:
      return {
        ...state,
        data: {},
      };
    case INVOICE_TYPES.GET_PAYMENT_FORM_REQUEST:
      return {
        ...state,
        paymentFormLoading: true,
        paymentFormData: {
          domainStatus: [],
        },
        paymentFormError: null,
      };
    case INVOICE_TYPES.GET_PAYMENT_FORM_SUCCESS:
      return {
        ...state,
        paymentFormLoading: false,
        paymentFormData: action.data,
        paymentFormError: null,
      };
    case INVOICE_TYPES.GET_PAYMENT_FORM_FAIL:
      return {
        ...state,
        paymentFormLoading: false,
        paymentFormData: {
          ...state.paymentFormData,
          domainStatus: action.error,
        },
        paymentFormError: action.error,
      };
    case INVOICE_TYPES.CHANGE_PAYMENT_METHOD: {
      const data = { ...state.data };
      data.invoice.paymentmethod = action.gateway;
      return {
        ...state,
        data,
      };
    }
    case INVOICE_TYPES.APPLY_CREDIT_REQUEST:
      return {
        ...state,
        applyCreditLoading: true,
        applyButtonShouldHide: true,
      };
    case INVOICE_TYPES.APPLY_CREDIT_SUCCESS:
      return {
        ...state,
        applyCreditLoading: false,
      };
    case INVOICE_TYPES.APPLY_CREDIT_FAIL:
      return {
        ...state,
        applyCreditLoading: false,
      };
    case MODAL_TYPES.HIDE_MODAL:
      return {
        ...state,
        paymentFormData: '',
      };
    default:
      return state;
  }
}
