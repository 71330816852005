import styled from 'styled-components'
import * as fontWeight from '../../assets/styles/constants/fontWeight'
import Container from '../../components/Container/Container'

export const PageBody = styled.div`
  background-color: #f8f8f8;
  width: 100%;
  display: flex;
  padding-top: 75px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 75px;
`

export const AboutUsBodyContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AboutUsTitle = styled.h1`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 18px;
  text-align: center;
  color: #484857;
  padding: 0px 10px;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`

export const AboutUsHeader = styled.div`
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #737373;
  padding-top: 25px;
  @media (min-width: 768px) {
    font-size: 18px;
    max-width: 650px;
    padding-top: 20px;
  }
`

export const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 43px;
  align-items: center;
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    justify-content: space-around;
    margin-top: 70px;
  }
`

export const Image = styled.img`
  background-repeat: no-repeat;
  background-size: cover;
  width: 300px;
  @media (min-width: 992px) {
    width: 450px;
  }
`

export const Text = styled.div`
  font-size: 16px;
  color: #737373;
  margin-left: 25px;
  margin-right: 25px;
  text-align: left;
  @media (min-width: 992px) {
    font-size: 16px;
    margin-left: 0px;
    margin-right: 0px;
    width: 42%;
  }
`

export const Paragraph = styled.p`
  margin-bottom: 10px;
`

export const RigthsForm = styled.a`
  color: #3f51b5;
  outline: 0;
  text-decoration: none;
`
