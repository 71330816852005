import { connect } from 'react-redux';

import { ConfirmModal as Self } from './ConfirmModal';
import { removeFromCart } from '../../../common/App/App.actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  removeFromCart,
};

export const ConfirmModal = connect(mapStateToProps, mapDispatchToProps)(Self);
