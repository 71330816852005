import { CLIENT_AREA_TABS } from "../../ClientAreaPage.constants";

export const headers = [
  {
    id: "serviceHeader",
    tab: CLIENT_AREA_TABS.SERVICES,
    sortNumber: 0,
    sortType: "text",
    title: "servicesTable.service",
    sortBy: "name",
  },
  {
    id: "serviceRegDate",
    tab: CLIENT_AREA_TABS.SERVICES,
    sortNumber: 1,
    sortType: "number",
    title: "servicesTable.serviceRegDate",
    sortBy: "regdate",
  },
  {
    id: "serviceBillCycle",
    tab: CLIENT_AREA_TABS.SERVICES,
    sortNumber: 2,
    sortType: "number",
    title: "servicesTable.serviceBillCycle",
    sortBy: "billingcycle",
    marginLeftNeed: true,
  },
  {
    id: "priceHeader",
    tab: CLIENT_AREA_TABS.SERVICES,
    sortNumber: 3,
    sortType: "number",
    title: "servicesTable.price",
    sortBy: "firstpaymentamount",
  },
  {
    id: "paymentHeader",
    tab: CLIENT_AREA_TABS.SERVICES,
    sortNumber: 4,
    sortType: "number",
    title: "servicesTable.expiryDate",
    sortBy: "nextduedate",
  },
  {
    id: "statusHeader",
    tab: CLIENT_AREA_TABS.SERVICES,
    sortNumber: 5,
    sortType: "text",
    title: "servicesTable.status",
    sortBy: "status",
  },
];
