import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { DomainsTable as Self } from "./DomainsTable";
import {
  toggleFilterPopup,
  showSortPopup,
  showSettingsPopup,
  getDomains,
} from "../../ClientAreaPage.actions";

const mapStateToProps = ({ clientAreaPage }) => ({
  data: clientAreaPage.domainsData,
  filter: clientAreaPage.filters.domains,
  loading: clientAreaPage.domainsLoading,
  success: clientAreaPage.domainsRequestSuccess,
  total: clientAreaPage.domainsTotal,
  statuses: clientAreaPage.domainsStatuses,
  offsets: clientAreaPage.offsets,
  sortField: clientAreaPage.sortField.domains,
  sortOrder: clientAreaPage.sortOrder.domains,
});

const mapDispatchToProps = {
  toggleFilterPopup,
  showSortPopup,
  showSettingsPopup,
  getDomains,
};

export const DomainsTable = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
