import React from 'react'
import { services } from '../../HomePage.constants'
import * as S from './ServicesBlock.styles'
import { i18n } from '../../../../i18n'

import { changeUrl } from '../../../../hooks/changeUrl'

export const ServicesBlock = () => (
  <S.ServicesSideFiller>
    <S.ContainerOfServices>
      <S.ServiceTitle>{i18n.t('servicesBlock.title')}</S.ServiceTitle>
      {/* <S.ServiceDescription>
        {i18n.t('servicesBlock.description')}
      </S.ServiceDescription> */}
      <S.ContainerOfServicesCarts>
        {services.map((servicePackage, index) => (
          <S.ServiceCartDiv key={servicePackage.id} setMargin={index < 2}>
            <S.ServiceCartHeader>
              <S.ServiceCartTitle>
                {i18n.t(servicePackage.title)}
              </S.ServiceCartTitle>
              <S.ServiceCartImage
                src={servicePackage.image}
                alt="Hosting service image"
                title="Hosting service image"
              />
            </S.ServiceCartHeader>
            <S.ServiceCartDescription>
              {i18n.t(servicePackage.description)}
            </S.ServiceCartDescription>
            <S.ButtonDiv>
              <S.ServiceButton to={changeUrl(servicePackage.link)}>
                {i18n.t('servicesBlock.more')}
              </S.ServiceButton>
            </S.ButtonDiv>
          </S.ServiceCartDiv>
        ))}
      </S.ContainerOfServicesCarts>
    </S.ContainerOfServices>
  </S.ServicesSideFiller>
)
