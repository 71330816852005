import React from 'react'

import { QuestionCard } from '../QuestionCard'
import * as S from './QuestionsList.styles'
import { i18n } from '../../i18n'

export const QuestionsList = ({
  questions,
  expandedQuestion,
  changeExpand,
  isInPage,
}) => {
  return (
    <S.FAQContainer isInPage={isInPage}>
      {isInPage && <S.QuestionsTitle>{i18n.t('faq.title')}</S.QuestionsTitle>}
      <S.QuestionsList>
        {questions.map((faq) => (
          <QuestionCard
            key={faq.id}
            activeId={expandedQuestion}
            changeExpand={changeExpand}
            faq={faq}
          />
        ))}
      </S.QuestionsList>
    </S.FAQContainer>
  )
}
