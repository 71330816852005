import React from "react";
import * as S from "./BuyHostingWithSale.styles";
import { tldCards } from "../SearchAreaBlock/SearchAreaBlock.config";
import { TldCard } from "../../../../components/TldCard";
import { i18n } from "../../../../i18n";

export const BuyHostingWithSale = () => {
  return (
    <S.BuyHostingContainer>
      <S.SaleCardsContainerTitle>
        {i18n.t("homePage.buyDomainWithSale.title")}
      </S.SaleCardsContainerTitle>
      <S.BackgroundImage />
      <S.PriceCardsContainer>
        {tldCards
          .sort((a, b) => a.bottomBlockOrder - b.bottomBlockOrder)
          .map((item, index) => (
            <S.TldCardBackground key={index}>
              <TldCard tld={item} />
            </S.TldCardBackground>
          ))}
      </S.PriceCardsContainer>
    </S.BuyHostingContainer>
  );
};
