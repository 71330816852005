import FetchApi from "../../helpers/FetchApi";

import { TWO_FACTOR_AUTH_ENABLE_TYPES } from "./TwoFactorAuthEnablePage.types";

export const getQrCode = (onSuccess) => async (dispatch, getState) => {
  try {
    const { auth } = getState().app;
    dispatch({
      type: TWO_FACTOR_AUTH_ENABLE_TYPES.GET_QR_CODE_REQUEST,
    });

    const req = await FetchApi.post(`users/createbase32token`, {
      email: auth.email,
    });

    dispatch({
      type: TWO_FACTOR_AUTH_ENABLE_TYPES.GET_QR_CODE_SUCCESS,
      data: req.data,
    });
    if (onSuccess) {
      onSuccess(req.data.otpauth_url);
    }
  } catch (e) {
    dispatch({
      type: TWO_FACTOR_AUTH_ENABLE_TYPES.GET_QR_CODE_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    });
  }
};
