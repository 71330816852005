import * as Yup from 'yup';

export const initialValues = {
  // name: '',
  // email: '',
  subject: '',
  deptId: '1',
  //service: '',
  priority: '',
  message: '',
  files: [],
};

export const yupScheme = Yup.object().shape({
  // name: Yup.string()
  //   .min(2, 'validationFields.firstnameMinPhysical')
  //   .max(72, 'validationFields.firstnameMaxPhysical')
  //   .matches(/^([-A-Za-z0-9 ]){1,20}$/, 'validationFields.invalidTyping')
  //   .required('validationFields.firstnamePhysical'),
  // email: Yup.string()
  //   .email('validationFields.invalidTyping')
  //   .required('validationFields.email'),
  subject: Yup.string()
    .min(2, 'validationFields.subjectMin')
    .max(40, 'validationFields.subjectMax')
    .required('validationFields.subject'),
  deptId: Yup.string().required('validationFields.section'),
  //service: Yup.string().required('validationFields.service'),
  priority: Yup.string().required('validationFields.priority'),
  message: Yup.string()
    .min(2, 'validationFields.messageMin')
    .max(500, 'validationFields.messageTextareaMax')
    .required('validationFields.message'),
  initialFiles: Yup.array(),
});

export const sectionOptions = (i18n) => [
  { value: '1', label: i18n.t('sectionOptions.adminDep') },
  { value: '2', label: i18n.t('sectionOptions.techDep') },
  { value: '3', label: i18n.t('sectionOptions.finDep') },
];

export const priorityOptions = (i18n) => [
  { value: 'Low', label: i18n.t('priorityOptions.low') },
  { value: 'Medium', label: i18n.t('priorityOptions.medium') },
  { value: 'High', label: i18n.t('priorityOptions.high') },
];
