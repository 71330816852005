import { SSL_TYPES } from './SslConfigurationPage.types'

export const initialState = {
  nextStepData: null,
  step: 1,
  configureSslLoading: false,
  configureSslError: null,
  initialValues: {},
  approverEmails: [],
  approvalMethods: [],
  firstStepSslData: [],
}

export const sslConfiguationPage = (state = initialState, action) => {
  switch (action.type) {
    case SSL_TYPES.CONFIGURE_SSL_REQUEST:
      return {
        ...state,
        configureSslLoading: true,
      }

    case SSL_TYPES.CONFIGURE_SSL_SUCCESS:
      return {
        ...state,
        nextStepData: action.data.data.body,
        configureSslLoading: false,

        approverEmails: action.data.data.body.approveremails?.map((i) => ({
          label: i,
          value: i,
        })),
        approvalMethods: action.data.data.body.approvalmethods?.map((i) => ({
          label: i.toUpperCase(),
          value: i,
        })),
      }

    case SSL_TYPES.CONFIGURE_SSL_FAIL:
      return {
        ...state,
        configureSslError: action.error,
        configureSslLoading: false,
      }

    case SSL_TYPES.FIRST_STEP_CONFIGURE_SSL_SUCCESS:
      return {
        ...state,
        firstStepSslData: action.data,
      }

    case SSL_TYPES.SET_VALUES:
      return {
        ...state,
        initialValues: action.values,
      }
    default: {
      return {
        ...state,
      }
    }
  }
}
