import { i18n } from '../../i18n';

export const priceTimeSelectOptions = [
  {
    value: 1,
    label: i18n.t('orderDetailsPage.selectTime.n1'),
    name: 'monthly',
  },
  {
    value: 3,
    label: i18n.t('orderDetailsPage.selectTime.n2'),
    name: 'quarterly',
  },
  {
    value: 6,
    label: i18n.t('orderDetailsPage.selectTime.n3'),
    name: 'semiannually',
  },
  {
    value: 12,
    label: i18n.t('orderDetailsPage.selectTime.n4'),
    name: 'annually',
  },
];