import React, { useCallback, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Formik, Form } from 'formik';
import { decode } from 'html-entities';
import { omit } from 'lodash';

import { countries } from '../../../constants/countries';
import { initialValues, yupScheme } from './AddContactModal.config';
import * as S from './AddContactModal.styles';
import { Loader } from '../../../components/Loader';
import { i18n } from '../../../i18n';
import slugify from 'react-slugify';

const AddContactModal = ({
  addContact,
  addNewContact,
  error,
  hideModal,
  loading,
  onSuccess,
  isNewContact,
  contact,
  updateContactInfo,
  updateLoading,
  cleanRegisterError,
}) => {
  const handleEnter = useCallback((e, submitForm) => {
    if (e.key === 'Enter') {
      submitForm();
    }
  }, []);

  const [inputCountryCode, setInputCountryCode] = useState('');

  return (
    <S.Modal onClick={(e) => e.stopPropagation()}>
      <S.RegistrationContainer>
        <S.PageTitle>
          {isNewContact
            ? i18n.t('addContactModal.header')
            : i18n.t('editContactModal.header')}
        </S.PageTitle>
        <S.Text>
          {i18n.t('registrationPage.text')}{' '}
          <span>{i18n.t('registrationPage.textBold')}</span>
        </S.Text>

        <S.FormContainer>
          <Formik
            initialValues={isNewContact ? initialValues : contact}
            validationSchema={yupScheme}
            onSubmit={async (values, { setSubmitting }) => {
              values = omit(values, 'personType');
              const formattedValues = {
                ...values,
                phonenumber: values.phonenumber
                  .replace('+', '00')
                  .replace(/ /g, '')
                  .replace(/-/g, ''),
              };
              if (isNewContact) {
                const contactid = await addContact(formattedValues);
                if (contactid) {
                  addNewContact({
                    id: contactid,
                    ...formattedValues,
                  });
                  if (onSuccess) {
                    onSuccess(contactid);
                  }
                  hideModal();
                }
              } else {
                await updateContactInfo({ ...values }, contact.id);
                hideModal();
              }
              setSubmitting(false);
            }}
          >
            {({
              touched,
              errors,
              isSubmitting,
              handleChange,
              values,
              setFieldValue,
              submitForm,
              resetForm,
            }) => {
              const firstError = Object.keys(errors)[0];
              let input = document.querySelector(`input[name="${firstError}"]`);
              if (input) {
                input.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                });
              }
              return (
                <Form onKeyPress={() => handleEnter(submitForm)}>
                  {isNewContact ? (
                    <S.ButtonsContainer>
                      <S.Button
                        type="button"
                        isChecked={values.personType === 0}
                        onClick={() => {
                          resetForm();
                          setFieldValue('personType', 0);
                          cleanRegisterError();
                        }}
                      >
                        {i18n.t('registrationPage.individual')}
                      </S.Button>
                      <S.Button
                        type="button"
                        isChecked={values.personType === 1}
                        onClick={() => {
                          resetForm();
                          setFieldValue('personType', 1);
                          cleanRegisterError();
                        }}
                      >
                        {i18n.t('registrationPage.entity')}
                      </S.Button>
                    </S.ButtonsContainer>
                  ) : null}
                  {values.personType === 0 ? (
                    <S.FormSection>
                      <S.FormItem>
                        <S.Label>
                          {i18n.t('registrationPage.firstname')}
                          {isNewContact ? '*' : ''}
                        </S.Label>
                        <S.Input
                          placeholder="Poghos"
                          name="firstname"
                          onChange={handleChange}
                          value={decode(values.firstname)}
                        />
                        {errors.firstname && touched.firstname && (
                          <S.ErrorMessage>
                            {i18n.t(errors.firstname)}
                          </S.ErrorMessage>
                        )}
                      </S.FormItem>
                      <S.FormItem>
                        <S.Label>
                          {i18n.t('registrationPage.lastname')}
                          {isNewContact ? '*' : ''}
                        </S.Label>
                        <S.Input
                          placeholder="Poghosyan"
                          name="lastname"
                          onChange={handleChange}
                          value={decode(values.lastname)}
                        />
                        {errors.lastname && touched.lastname && (
                          <S.ErrorMessage>
                            {i18n.t(errors.lastname)}
                          </S.ErrorMessage>
                        )}
                      </S.FormItem>
                    </S.FormSection>
                  ) : (
                    <S.FormSection>
                      <S.FormItem>
                        <S.Label>
                          {i18n.t('registrationPage.companyName')}
                          {isNewContact ? '*' : ''}
                        </S.Label>
                        <S.Input
                          placeholder="Example"
                          name="firstname"
                          onChange={handleChange}
                          value={decode(values.firstname)}
                        />
                        {errors.firstname && touched.firstname && (
                          <S.ErrorMessage>
                            {i18n.t(errors.firstname)}
                          </S.ErrorMessage>
                        )}
                      </S.FormItem>
                      <S.FormItem>
                        <S.Label>
                          {i18n.t('registrationPage.companyType')}
                          {isNewContact ? '*' : ''}
                        </S.Label>
                        <S.Input
                          placeholder="LLC"
                          name="lastname"
                          onChange={handleChange}
                          value={decode(values.lastname)}
                        />
                        {errors.lastname && touched.lastname && (
                          <S.ErrorMessage>
                            {i18n.t(errors.lastname)}
                          </S.ErrorMessage>
                        )}
                      </S.FormItem>
                    </S.FormSection>
                  )}
                  <S.FormSection>
                    <S.FormItem>
                      <S.Label>
                        {i18n.t('registrationPage.tel')}
                        {isNewContact ? '*' : ''}
                      </S.Label>
                      <PhoneInput
                        placeholder={i18n.t(
                          'registrationPage.phonenumberPlaceholder'
                        )}
                        value={
                          values.phonenumber.startsWith('00')
                            ? values.phonenumber.replace('00', '+')
                            : values.phonenumber
                        }
                        onChange={(phone, data) => {
                          setInputCountryCode(data.countryCode);
                          if (data.countryCode !== inputCountryCode) {
                            setFieldValue('phonenumber', data.dialCode);
                          } else {
                            setFieldValue('phonenumber', phone);
                          }
                        }}
                        name="phonenumber"
                        masks={{ am: '(..) ......' }}
                        country="am"
                      />
                      {errors.phonenumber && touched.phonenumber && (
                        <S.ErrorMessage>
                          {i18n.t(errors.phonenumber)}
                        </S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem type="email">
                      <S.Label>
                        {i18n.t('registrationPage.email')}
                        {isNewContact ? '*' : ''}
                      </S.Label>
                      <S.Input
                        placeholder="example@example.com"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        disabled={!isNewContact}
                      />
                      {errors.email && touched.email && (
                        <S.ErrorMessage>{i18n.t(errors.email)}</S.ErrorMessage>
                      )}
                    </S.FormItem>
                  </S.FormSection>
                  <S.FormSection>
                    <S.FormItem>
                      <S.Label>{i18n.t('registrationPage.country')}*</S.Label>
                      <S.StyledSelect
                        defaultValue={countries.find(
                          (item) => item.value === values.country
                        )}
                        isSearchable={true}
                        name="country"
                        options={countries}
                        onChange={(option) =>
                          setFieldValue('country', option.value)
                        }
                        classNamePrefix="react-select"
                      />
                      {errors.country && touched.country && (
                        <S.ErrorMessage>
                          {i18n.t(errors.country)}
                        </S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem>
                      <S.Label>{i18n.t('registrationPage.state')}*</S.Label>
                      <S.Input
                        placeholder="Yerevan"
                        name="state"
                        onChange={handleChange}
                        value={decode(values.state)}
                      />
                      {errors.state && touched.state && (
                        <S.ErrorMessage>{i18n.t(errors.state)}</S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem>
                      <S.Label>{i18n.t('registrationPage.city')}*</S.Label>
                      <S.Input
                        placeholder="Yerevan"
                        name="city"
                        onChange={handleChange}
                        value={decode(values.city)}
                      />
                      {errors.city && touched.city && (
                        <S.ErrorMessage>{i18n.t(errors.city)}</S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem>
                      <S.Label>{i18n.t('registrationPage.address')}*</S.Label>
                      <S.Input
                        placeholder="Tumanyan, 3"
                        name="address1"
                        onChange={handleChange}
                        value={decode(values.address1)}
                      />
                      {errors.address1 && touched.address1 && (
                        <S.ErrorMessage>
                          {i18n.t(errors.address1)}
                        </S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem>
                      <S.Label>
                        {i18n.t('registrationPage.postalIndex')}*
                      </S.Label>
                      <S.Input
                        placeholder="0023"
                        name="postcode"
                        onChange={handleChange}
                        value={values.postcode}
                      />
                      {errors.postcode && touched.postcode && (
                        <S.ErrorMessage>
                          {i18n.t(errors.postcode)}
                        </S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.SubmitButton type="submit" disabled={isSubmitting}>
                      {loading || updateLoading ? (
                        <Loader color="#FFF" size={0.5} />
                      ) : isNewContact ? (
                        i18n.t('addContactModal.addButton')
                      ) : (
                        i18n.t('editContactModal.saveButton')
                      )}
                    </S.SubmitButton>
                  </S.FormSection>
                  {error && (
                    <S.ErrorMessage spaced>
                      {i18n.t(`backend.addContact.${slugify(error)}`)}
                    </S.ErrorMessage>
                  )}
                </Form>
              );
            }}
          </Formik>
        </S.FormContainer>
        <S.CloseModalButton onClick={() => hideModal()} />
      </S.RegistrationContainer>
    </S.Modal>
  );
};

export { AddContactModal };
