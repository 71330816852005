import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { SettingsPopup as Self } from './SettingsPopup';
import { showSettingsPopup } from '../../../../ClientAreaPage.actions';

const mapStateToProps = ({ clientAreaPage }) => ({
  isShown: clientAreaPage.settingsPopupIsShown,
});

const mapDispatchToProps = {
  showSettingsPopup,
};

export const SettingsPopup = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
