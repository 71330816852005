import styled from 'styled-components';
import { Field } from 'formik';
import Select from 'react-select';
import { Link } from 'react-router-dom';

import * as fontWeight from '../../assets/styles/constants/fontWeight';
import closeModalIcon from '../../images/closeModal.svg';



export const RegistrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 40px 20px;

  @media (min-width: 768px) {
    padding: 70px 230px;
  }

  @media (min-width: 1280px) {
    padding: 70px 230px 115px 230px;
  }
`;
export const PageTitle = styled.h1`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 18px;
  color: #484857;
  @media (min-width: 768px) {
    font-size: 24px;
  }
  @media (min-width: 1280px) {
    font-size: 28px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 0;
    width: unset;
  }

  @media (min-width: 1280px) {
    margin-top: 50px;
  }
`;

export const FormSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid rgba(212, 212, 212, 0.4);
  padding-bottom: 28px;

  @media (min-width: 768px) {
    border: none;
    padding-bottom: 0px;
  }

  &:last-child {
    border: none;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
    border-bottom: 1px solid #d4d4d4;
    flex-wrap: wrap;
    width: 970px;
    justify-content: space-between;
    padding-bottom: 32px;
  }
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  width: 100%;

  &.radio {
    justify-content: center;
  }

  @media (min-width: 768px) {
    margin-top: 47px;
    width: unset;
  }
`;

export const FormItemInline = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  width: 100%;

  &.radio {
    justify-content: center;
  }

  @media (min-width: 768px) {
    margin-top: 47px;
    width: 100%;
  }
`;

export const Label = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 17px;

  &.radio {
    margin: 0px;
    margin-left: 7px;
    opacity: 0.38;
  }

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const Input = styled(Field)`
  width: auto;
  padding: 12px;
  max-height: 44px;
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;

  @media (min-width: 768px) {
    width: 385px;
  }

  &.radio {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    -webkit-appearance: none;
    border: 1px solid #d4d4d4;
    padding: 0px;
    cursor: pointer;

    &:checked {
      border-color: #dc773c;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      margin: 20% auto;
      border-radius: 50%;
      left: 4px;
      top: 0px;
      background: #d4d4d4;
    }

    &:checked:before {
      background: #dc773c;
    }
  }
`;
export const InputForSubject = styled(Field)`
  width: auto;
  padding: 12px;
  max-height: 44px;
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;

  &.radio {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    -webkit-appearance: none;
    border: 1px solid #d4d4d4;
    padding: 0px;
    cursor: pointer;

    &:checked {
      border-color: #dc773c;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      margin: 20% auto;
      border-radius: 50%;
      left: 4px;
      top: 0px;
      background: #d4d4d4;
    }

    &:checked:before {
      background: #dc773c;
    }
  }
`;

export const TextareaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  white-space: pre-line;
`;

export const Section = styled.section`
  width: 100%;
`;

export const AttachButton = styled.button`
  width: 100%;
  padding: 8px 0px;
  text-decoration: none;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  background: ${(p) => (p.disabled ? '#888e9b' : '#242e46')};
  border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 15px;
  ${(p) => p.disabled && `pointer-events: none`};
  @media (min-width: 768px) {
    width: 129px;
  }
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`;

export const Textarea = styled(Field)`
  box-sizing: border-box;
  padding: 12px;
  outline: none;
  position: relative;
  color: #242e46;
  height: 100px;
  width: 100%;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  resize: none;
  ${(p) => p.isError && 'border: 1px solid #DD2326;'};
  outline: none;
  &::placeholder {
    color: #c4c4c4;
    font-weight: 400;
  }

  &.radio {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    -webkit-appearance: none;
    border: 1px solid #d4d4d4;
    padding: 0px;
    cursor: pointer;

    &:checked {
      border-color: #dc773c;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      margin: 20% auto;
      border-radius: 50%;
      left: 4px;
      top: 0px;
      background: #d4d4d4;
    }

    &:checked:before {
      background: #dc773c;
    }
  }
  @media (min-width: 768px) {
    height: 200px;
  }
`;

export const ErrorLimits = styled.div`
  margin-top: 10px;
`;
export const Type = styled.div`
  font-size: 12px;
  color: #737373;
  font-weight: 400;
`;
export const Limit = styled.div`
  font-size: 12px;
  color: #737373;
  font-weight: 400;
  margin-top: 5px;
`;

export const Attachments = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: rgba(36, 46, 70, 0.08);
  border-radius: 5px;
  position: relative;
  height: 200px;
`;

export const AttachmentsList = styled.ul`
  list-style: none;
  padding-left: 0;
  width: 250px;
`;

export const AcceptedFiles = styled.li`
  padding: 10px 10px 10px 20px;
  width: 85%;
  @media (min-width: 500px) {
    width: 90%;
  }
`;

export const ImageName = styled.div`
  font-size: 18px;
  color: #242e46;
  font-weight: normal;
  overflow: hidden;
  width: 200px;
  bottom: 10px;
  position: absolute;
`;

export const ImageFrame = styled.div`
  border-radius: 5px;
  border: 1px solid #242e46;
  width: 200px;
  height: 150px;
  margin-bottom: 30px;
`;

export const PreviewImage = styled.img`
  display: flex;
  width: 200px;
  margin-bottom: 5px;
  border-radius: 5px;
  position: absolute;
  clip: rect(0, 200px, 150px, 0);
  height: 150px;
  object-fit: cover;
  top: 10px;
`;

export const RemoveButton = styled.div`
  background: url(${closeModalIcon});
  width: 10px;
  height: 10px;
  cursor: pointer;
  background-size: cover;
  position: absolute;
  top: 10px;
  right: 10px;
  @media (min-width: 500px) {
    width: 10.5px;
    height: 10.5px;
  }
  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  @media (min-width: 768px) {
    width: 409px;
  }
`;

export const Note = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #242e46;
  opacity: 0.57;
  margin-top: 8px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin-top: 40px;

  @media (min-width: 768px) {
    margin-top: 50px;
  }
  @media (min-width: 1280px) {
    width: 100%;
    justify-content: center;
  }
`;
export const SendButton = styled.button`
  width: 129px;
  padding: 8px 0px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  background: ${(p) => (p.disabled ? '#888e9b' : '#00c084')};
  border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 1280px) {
    margin-right: 22px;
  }
`;
export const CancelButton = styled(Link)`
  width: 129px;
  padding: 8px 0px;
  text-decoration: none;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  background: #242e46;
  border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 1280px) {
    margin-left: 22px;
  }
`;
export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;
export const Counter = styled.div`
  font-size: 12px;
  margin-top: 5px;
  color: #242e46;
  float: right;
  ${(p) => p.isError && 'color: #DD2326;'};
`;
