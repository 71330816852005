import React from 'react'
import { LayoutCommon } from '../../layouts/LayoutCommon'
import { Helmet } from 'react-helmet'
import { i18n } from '../../i18n'
import cert from '../../images/certResized.jpg'
import * as S from './AboutUsPage.styles'
const AboutUsPage = () => (
  <LayoutCommon>
    <Helmet>
      <title>{i18n.t('headers.aboutUsPage')}</title>
      <meta
        name="description"
        content={i18n.t('headersDescriptions.aboutUsPage')}
      />
      <link
        rel="alternate"
        href="https://internet.am/en/about-us"
        hreflang="en"
      />
      <link
        rel="alternate"
        href="https://internet.am/ru/about-us"
        hreflang="ru"
      />
      <link
        rel="alternate"
        href="https://internet.am/about-us"
        hreflang="am-AM"
      />
      <meta name="keywords" content={i18n.t('headerKeywords.aboutUsPage')} />
      <meta property="og:title" content={i18n.t('headers.aboutUsPage')} />
      <meta
        property="og:description"
        content={i18n.t('headersDescriptions.aboutUsPage')}
      />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="ABC Domain LLC" />
      <meta
        property="og:image"
        content="https://internet.am/images/fb_logo.png"
      />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={i18n.t('headers.aboutUsPage')} />
      <meta
        name="twitter:description"
        content={i18n.t('headersDescriptions.aboutUsPage')}
      />
      <meta
        name="twitter:image"
        content="https://internet.am/images/fb_logo.png"
      />
    </Helmet>
    <S.PageBody>
      <S.AboutUsBodyContainer>
        <S.AboutUsTitle>{i18n.t('aboutUsPage.title')}</S.AboutUsTitle>
        <S.AboutUsHeader>{i18n.t('aboutUsPage.header')}</S.AboutUsHeader>
        <S.MainBlock>
          <S.Image
            src={cert}
            alt="АБСДомен технический  центр группы по реагированию на компьютерные угрозы в Армении"
            title="Certificate"
          />
          <S.Text>
            <S.Paragraph
              dangerouslySetInnerHTML={{
                __html: i18n.t('aboutUsPage.paragraphOne'),
              }}
            />
            <S.Paragraph
              dangerouslySetInnerHTML={{
                __html: i18n.t('aboutUsPage.paragraphTwo'),
              }}
            />
            <S.Paragraph
              dangerouslySetInnerHTML={{
                __html: i18n.t('aboutUsPage.paragraphThree'),
              }}
            />
            <S.Paragraph
              dangerouslySetInnerHTML={{
                __html: i18n.t('aboutUsPage.paragraphFour'),
              }}
            />
            <S.Paragraph
              dangerouslySetInnerHTML={{
                __html: i18n.t('aboutUsPage.paragraphFive'),
              }}
            />
            <S.Paragraph
              dangerouslySetInnerHTML={{
                __html: i18n.t('aboutUsPage.paragraphSix'),
              }}
            />
            <S.Paragraph
              dangerouslySetInnerHTML={{
                __html: i18n.t('aboutUsPage.paragraphSeven'),
              }}
            />
            {/* <S.Paragraph
              dangerouslySetInnerHTML={{
                __html: i18n.t('aboutUsPage.paragraphEight'),
              }}
            />
            <S.Paragraph
              dangerouslySetInnerHTML={{
                __html: i18n.t('aboutUsPage.welcome'),
              }}
            />
            <S.Paragraph
              dangerouslySetInnerHTML={{
                __html: i18n.t('aboutUsPage.phone'),
              }}
            />
            <S.Paragraph
              dangerouslySetInnerHTML={{
                __html: i18n.t('aboutUsPage.email'),
              }}
            />
            <S.Paragraph>
              <S.RigthsForm
                href="https://www.icann.org/resources/pages/benefits-2013-09-16-en"
                target="_blank"
              >
                {i18n.t('aboutUsPage.rigthsForm')}
              </S.RigthsForm>
            </S.Paragraph> */}
          </S.Text>
        </S.MainBlock>
      </S.AboutUsBodyContainer>
    </S.PageBody>
  </LayoutCommon>
)

export { AboutUsPage }
