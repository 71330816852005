import React from 'react';
import styled, { keyframes } from 'styled-components';

const Loader = ({ color, size }) => (
  <LoaderDiv color={color} size={size}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </LoaderDiv>
);

const ldsEllipsis1 = keyframes`{
  0% {
      transform: scale(0);
  }
  100% {
      transform: scale(1);
  }
}`;

const ldsEllipsis3 = keyframes`{
  0% {
      transform: scale(1);
  }
  100% {
      transform: scale(0);
  }
}`;

const ldsEllipsis2 = keyframes`{
  0% {
      transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}`;

const LoaderDiv = styled.div`
  display: inline-block;
  align-self: center;
  position: relative;
  width: 80px;
  height: 13px;
  ${p => p.size && `transform: scale(${p.size})`};
  div {
    position: absolute;
    top: 0px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background-color: ${p => p.color};
    &:nth-child(1) {
      left: 8px;
      animation: ${ldsEllipsis1} 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: ${ldsEllipsis2} 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: ${ldsEllipsis2} 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: ${ldsEllipsis3} 0.6s infinite;
    }
  }
`;

export { Loader };
