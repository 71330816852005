import { MODAL_TYPES } from './Modal.types';

const initialState = {
  componentProps: {},
  isVisible: false,
  ModalComponent: null,
};

export const modal = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_TYPES.SHOW_MODAL: {
      return {
        ...state,
        isVisible: true,
        ModalComponent: action.ModalComponent,
        componentProps: action.componentProps,
      };
    }
    case MODAL_TYPES.HIDE_MODAL: {
      return initialState;
    }
    default:
      return state;
  }
};
