import FetchApi from '../../helpers/FetchApi'
import { TELCELL_PAYMENT_TYPES } from './TelcellPaymentPage.types'

export const getTelcellPaymentData =
  (id, onSuccess, onFail) => async (dispatch) => {
    try {
      dispatch({
        type: TELCELL_PAYMENT_TYPES.GET_TELCELL_DATA_REQUEST,
      })
      const req = await FetchApi.get('payments/telcellCheck', { order: id })
      dispatch({
        type: TELCELL_PAYMENT_TYPES.GET_TELCELL_DATA_SUCCESS,
        data: req.data.data,
      })

      if (onSuccess) {
        onSuccess(req.data.invoice.status)
      }
    } catch (e) {
      dispatch({
        type: TELCELL_PAYMENT_TYPES.GET_TELCELL_DATA_FAIL,
        error: e.data ? e.data.error || e.data.validation_error : e.message,
      })
      if (onFail) {
        onFail()
      }
    }
  }
