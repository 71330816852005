import ReactPlayer from 'react-player/lazy'
import styled from 'styled-components'
import Header from '../../images/constructor-header.jpg'
import HeaderSm from '../../images/constructor-header-sm.jpg'
import HeaderMd from '../../images/constructor-header-md.jpg'
import AdvantagesTreeSm from '../../images/advantages-tree-sm.png'
import AdvantagesTreeMd from '../../images/advantages-tree-md.png'
import AdvantagesTree from '../../images/advantages-tree.png'
import ConstructorTreeSm from '../../images/constructor-tree-sm.png'
import ConstructorTreeMd from '../../images/constructor-tree-md.png'
import ConstructorTree from '../../images/constructor-tree.png'

export const PageBody = styled.div`
  background-color: #f8f8f8;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 70px;
  box-sizing: border-box;
`
export const PageWrapper = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  @media (min-width: 768px) {
    margin-top: -77px;
  }
`

export const HeaderContainer = styled.div`
  background-image: url(${HeaderSm});
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.1);
  background-size: cover;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 205px;
  justify-content: center;
  align-items: center;
  @media (min-width: 415px) {
    background-image: url(${HeaderSm});
    height: 275px;
  }
  @media (min-width: 660px) {
    height: 340px;
  }
  @media (min-width: 768px) {
    background-image: url(${HeaderMd});
    height: 530px;
  }
  @media (min-width: 850px) {
    height: 550px;
  }
  @media (min-width: 1000px) {
    height: 650px;
  }

  @media (min-width: 1280px) {
    background-image: url(${Header});
    height: 700px;
  }
  @media (min-width: 1500px) {
    height: 850px;
  }
`

export const SiteExample = styled.div`
  margin: 70px 0;
  width: 80%;
  background: transparent;
  box-sizing: border-box;
  @media (min-width: 768px) {
    margin: 70px 0;
  }

  @media (min-width: 768px) {
    margin: 190px 0;
  }
  @media (min-width: 1280px) {
    margin: 90px 0;
  }
`

export const HeaderContent = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  border-radius: 0px 0px 5px 5px;
  @media (min-width: 768px) {
    width: 60%;
    padding: 0px 80px;
    padding-top: 100px;
  }

  @media (min-width: 1280px) {
    padding: 120px 80px 120px 90px;
  }
`

export const HeaderTitle = styled.h1`
  font-weight: 500;
  font-size: 14px;
  max-width: 100px;
  align-self: flex-start;
  margin-top: 50px;
  @media (min-width: 415px) {
    margin-top: 0px;
  }
  @media (min-width: 768px) {
    font-weight: 700;
    font-size: 24px;
    max-width: 300px;
  }

  @media (min-width: 1280px) {
    font-weight: 400;
    font-size: 48px;
    max-width: 80%;
    margin-left: 10px;
  }
`

export const HeaderButtonContent = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  width: 100%;
  @media (min-width: 768px) {
    justify-content: flex-start;
    margin-top: 20px;
    padding-bottom: 40px;
  }
  @media (min-width: 1280px) {
    margin-left: 10px;
  }
`

export const HeaderButton = styled.a`
  text-decoration: none;
  color: #000;
  background: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 33px;
  text-align: center;
  font-size: 12px;
  border-radius: 5px;
  @media (min-width: 768px) {
    font-size: 18px;
    height: 36px;
  }
  @media (min-width: 768px) {
    width: 150px;
    height: 50px;
  }
`

export const HeaderButtonText = styled.h2`
  font-size: 10px;
  font-weight: normal;
  margin-left: 10px;
  width: 40%;
  @media (min-width: 768px) {
    width: 50%;
    font-size: 14px;
    margin-left: 10px;
  }
  @media (min-width: 1280px) {
    width: 50%;
    font-size: 16px;
  }
`

export const ConstructorsContainer = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 768px) {
    margin-top: 64px;
  }

  @media (min-width: 1280px) {
    margin-top: 78px;
  }
`

export const ConstructorSubTitle = styled.div`
  font-size: 14px;
  color: #737373;
  padding-top: 25px;
  text-align: center;

  @media (min-width: 768px) {
    padding-top: 47px;
    font-size: 18px;
  }

  @media (min-width: 1280px) {
    padding-top: 20px;
  }
`

export const VideoContainer = styled.div`
  padding: 100px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1280px) {
    flex-direction: row;
    justify-content: space-around;
  }
`

export const Video = styled(ReactPlayer)`
  width: 80% !important;
  height: 20%;
  border-radius: 20px !important;
  @media (min-width: 1280px) {
    width: 40% !important;
    height: none !important;
  }
`

export const VideoInfoContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;
  align-items: flex-start;
  margin-top: 50px;
  color: #484857;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (min-width: 1280px) {
    width: 28%;
    flex-direction: column;
    margin-top: 0;
  }
`

export const VideoTitle1 = styled.div`
  font-size: 36px;
`
export const VideoTitle2 = styled.div`
  margin: 30px 0 10px 0;
  font-size: 25px;
  font-weight: 500;
`
export const VideoTitle3 = styled.div`
  font-size: 23px;
  font-weight: 300;
`

export const VideoInfo = styled.div`
  margin: 30px 0;
  font-size: 20px;

  & > div {
    padding: 5px 0;
  }
`

export const VideoButton = styled.a`
  color: #212121;
  font-size: 24px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 50px;
  align-items: center;
  max-width: 270px;
  display: flex;
  & > img {
    width: 100px;
    height: 10px;
    margin-left: 10px;
  }
`

export const AdvantagesContainer = styled.div`
  margin-top: 50px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: #242e46;
  @media (min-width: 768px) {
    align-items: flex-end;
    border-bottom: #161251 7px solid;
  }
  @media (min-width: 1280px) {
    margin-top: 0;
    padding: 0px 50px;
    align-items: center;
  }
`

export const AdvantagesInfo = styled.div`
  width: 40%;
  padding-bottom: 85px;
  padding-left: 20px;
  @media (min-width: 768px) {
    padding-bottom: 30px;
  }
  @media (min-width: 768px) {
    padding-bottom: 30px;
    padding-left: 70px;
  }
  @media (min-width: 1280px) {
    padding-bottom: 140px;
    padding-left: 75px;
  }
`

export const AdvantagesTitle = styled.div`
  font-size: 24px;
  @media (min-width: 768px) {
    font-size: 36px;
  }
`

export const AdvantagesBody = styled.div`
  font-size: 18px;
  margin-top: 80px;

  @media (min-width: 768px) {
    font-size: 22px;
    margin-top: 60px;
  }

  @media (min-width: 1280px) {
    font-size: 24px;
    margin-top: 100px;
  }
`

export const AdvantagesItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
  @media (min-width: 1280px) {
    margin-top: 67px;
  }
`

export const CheckIcon = styled.img`
  width: 20px;
  height: 20px;
`

export const AdvantageText = styled.div`
  margin-left: 15px;
  font-size: 18px;
`

export const AdvantagesImage = styled.div`
  width: 213px;
  height: 194px;
  margin-bottom: 190px;
  background-image: url(${AdvantagesTreeSm});
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: 768px) {
    background-image: url(${AdvantagesTreeMd});
    margin-bottom: 0;
    width: 495px;
    height: 398px;
  }
  @media (min-width: 1280px) {
    background-image: url(${AdvantagesTree});
    height: 856px;
    width: 759px;
    padding-right: 20px;
  }
`

export const PackagesTitle = styled.div`
  font-size: 24px;
  padding-bottom: 64px;
  @media (min-width: 768px) {
    font-size: 36px;
  }
`

export const CompareContainer = styled.div`
  margin-top: 80px;
  padding: 0 20px;
  text-align: center;
  color: #484857;
`

export const CompareTitle = styled.div`
  color: #242e46;
  font-size: 24px;
  padding-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 36px;
    padding-bottom: 70px;
  }
`

export const CompareItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  > :nth-child(1) {
    border-bottom: 1px solid rgb(72, 72, 87, 1);
    padding-bottom: 110px;
  }
  > :nth-child(2) {
    padding-top: 60px;
  }
  @media (min-width: 1280px) {
    flex-direction: row;
    justify-content: center;
    > :nth-child(1) {
      border-bottom: none;
      border-right: 1px solid rgb(72, 72, 87, 1);
      padding-bottom: 0;
    }
    > :nth-child(2) {
      padding-top: 0;
    }
  }
`

export const CompareRowContent = styled.div`
  justify-content: center;
  > :nth-child(8) div {
    padding-bottom: 0;
    border-bottom: none;
  }

  @media (min-width: 768px) {
    padding: 0 90px;
  }

  @media (min-width: 1280px) {
    width: 40%;
    padding: 0 50px;
  }
`

export const CompareItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 25px;
`

export const RowTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  margin: 55px 0;
`

export const CompareImage = styled.img`
  margin-top: 5px;
  width: 15px;
  height: 15px;
`

export const CompareItemName = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(0, 0, 0, 1);
  margin-left: 20px;
  font-size: 16px;
  text-align: start;
  width: 100%;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`

export const HaveQuestionContainer = styled.div`
  color: #484857;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  border-bottom: #161251 3px solid;
  @media (min-width: 768px) {
    margin-top: 180px;
    border-bottom: #161251 7px solid;
  }
`

export const QuestionImage = styled.div`
  width: 225px;
  height: 179px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${ConstructorTreeSm});

  @media (min-width: 768px) {
    width: 481px;
    height: 385px;
    background-image: url(${ConstructorTreeMd});
  }
  @media (min-width: 1280px) {
    width: 769px;
    height: 604px;
    background-image: url(${ConstructorTree});
  }
`

export const HaveQuestionText = styled.div`
  text-align: center;
  margin-right: 40px;
  @media (min-width: 768px) {
    max-width: 388px;
    width: 30%;
  }
`

export const HaveQuestionTitle = styled.div`
  font-size: 20px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 30px;
    font-size: 36px;
  }

  @media (min-width: 1280px) {
    font-size: 48px;
    margin-bottom: 40px;
  }
`

export const HaveQuestionSubTitle = styled.div`
  font-size: 20px;
  font-weight: 300;

  @media (min-width: 768px) {
    font-size: 24px;
  }

  @media (min-width: 1280px) {
    font-size: 36px;
  }
`

export const QuestionsListContainer = styled.div`
  padding: 0 20px;
  box-sizing: border-box;
`

export const AdvantageTitle = styled.h2`
  font-size: 12px;
  color: #484857;
  padding: 0 20px;

  @media (min-width: 768px) {
    font-size: 18px;
  }

  @media (min-width: 1280px) {
    font-size: 20px;
  }
`

export const AdvantageInfo = styled.p`
  font-size: 12px;
  color: #484857;
  font-weight: 500;
  padding: 0 20px;
  @media (min-width: 768px) {
    font-size: 16px;
  }

  @media (min-width: 1280px) {
    font-size: 18px;
  }
`

export const Info = styled.p`
  font-size: 12px;
  color: #484857;
  padding: 0 20px;
  @media (min-width: 768px) {
    font-size: 16px;
  }

  @media (min-width: 1280px) {
    font-size: 18px;
    width: 1100px;
  }
`
