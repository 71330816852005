import FetchApi from '../../helpers/FetchApi'
import { DOMAIN_PRICES_TYPES } from './DomainPrices.types'

export const getDomainPricesAmd = (currency) => async (dispatch) => {
  try {
    dispatch({
      type: DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_AMD_REQUEST,
    })

    const req = await FetchApi.get(`payments/getDomainPrices?currency=1`)
    dispatch({
      type: DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_AMD_SUCCESS,
      data: req.data,
    })
    return true
  } catch (e) {
    dispatch({
      type: DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_AMD_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    })
    return false
  }
}

export const getDomainPricesUsd = (currency) => async (dispatch) => {
  try {
    dispatch({
      type: DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_USD_REQUEST,
    })

    const req = await FetchApi.get(`payments/getDomainPrices?currency=2`)
    dispatch({
      type: DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_USD_SUCCESS,
      data: req.data,
    })
    return true
  } catch (e) {
    dispatch({
      type: DOMAIN_PRICES_TYPES.GET_DOMAIN_PRICES_USD_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    })
    return false
  }
}
