import styled from 'styled-components'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import { Field } from 'formik'

import * as fontWeight from '../../assets/styles/constants/fontWeight'
import Container from '../../components/Container/Container'
import deleteCart from '../../images/deleteCart.svg'
import warning from '../../images/warning-icon.svg'
import errorIcon from '../../images/error-icon.svg'

export const PageBody = styled.div`
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 75px 20px;
  @media (min-width: 768px) {
    padding: 75px 70px;
  }
`

export const PaymentBodyContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PaymentTitle = styled.div`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 24px;
  font-style: normal;
  line-height: 29px;
  text-align: center;
  color: #484857;
  @media (min-width: 1280px) {
    font-size: 28px;
    line-height: 29px;
  }
`

export const PaymentHeader = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #737373;
  margin-top: 20px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  @media (min-width: 768px) {
    max-width: 650px;
    padding-left: 0px;
    padding-right: 0px;
  }
`

export const PaymentSubHeader = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #737373;
  margin-top: 20px;
`

export const MainBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin-top: 45px;
  @media (min-width: 1280px) {
    margin-top: 55px;
  }
`

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  padding: 35px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
    justify-content: space-around;
  }
`

export const LoginButton = styled.button`
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #000000;
  padding: 15px;
  transition: 0.3s;
  &:hover {
    color: #ffffff;
    background-color: #242e46;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }

  @media (min-width: 768px) {
    width: 45%;
    padding: 15px;
    max-width: 500px;
  }
`

export const RegButton = styled(Link)`
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #000000;
  padding: 15px;
  text-decoration: none;
  margin-top: 15px;
  transition: 0.3s;
  &:hover {
    color: #ffffff;
    background-color: #242e46;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    margin-top: 0;
    width: 45%;
    max-width: 500px;
  }
`

export const OwnerContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`

export const OwnerHeader = styled.div`
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #484857;
  margin-top: 50px;
  position: relative;
  width: 200px;
  @media (min-width: 768px) {
    width: unset;
  }
  @media (min-width: 1280px) {
    font-size: 24px;
    margin-top: 50px;
  }
`

export const OwnerNote = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  line-height: 18px;
  text-align: center;
  color: #737373;
  margin-top: 26px;
  padding-left: 50px;
  padding-right: 50px;
  @media (min-width: 1280px) {
    font-size: 18px;
    margin-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    line-height: 21px;
  }
`

export const StyledSelect = styled(Select)`
  width: 270px;
  margin-top: 30px;
  margin-bottom: 20px;
  .react-select__control {
    padding-top: 3px;
    padding-bottom: 2px;
  }

  @media (min-width: 768px) {
    width: 350px;
  }

  @media (min-width: 1280px) {
    width: 425px;
    margin-top: 50px;
    margin-bottom: 40px;
  }
`

export const ProductContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  margin-top: 20px;
  padding: 25px 10px;
  text-align: center;
  @media (min-width: 768px) {
    position: relative;
    flex-direction: row;
    padding: 20px 50px 20px 30px;
    text-align: left;
  }
`

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${'' /* justify-content: space-between; */}
  text-align: center;
  width: 250px;
  @media (min-width: 768px) {
    text-align: left;
    flex-direction: row;
  }
`

export const ProductName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #242e46;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`

export const WarningText = styled(Link)`
  color: #ffb400;
  font-size: 13px;
`

export const Type = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #242e46;
  @media (min-width: 768px) {
    margin-left: 20px;
    font-size: 24px;
  }
`

export const ChoosedDomain = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #242e46;
  word-break: break-all;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`

export const Delete = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 14px;
  height: 16px;
  background-image: url(${deleteCart});
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  @media (min-width: 768px) {
    position: absolute;
    top: 23px;
    right: 23px;
    width: 20px;
    height: 22px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1280px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  @media (min-width: 1280px) {
    margin-top: 40px;
    width: 45%;
  }

  .promoWrapper {
    position: relative;
  }
`

export const Header = styled.div`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: #242e46;
  margin-bottom: 21px;
  margin-top: 20px;
`

export const Input = styled(Field)`
  border: none;
  border-radius: 5px;
  height: 44px;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 60px;
  width: 65%;
`

export const TextareaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const Textarea = styled(Field)`
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  border: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #242e46;
  min-height: 158px;
  max-height: 400px;
  margin-top: 18px;
  padding-top: 20px;
  padding-left: 32px;
  padding-right: 32px;
  resize: vertical;
  box-sizing: border-box;
  width: 100%;
  ${(p) => p.isError && 'border: 1px solid #DD2326;'};
  outline: none;
  &::placeholder {
    color: #c4c4c4;
    font-weight: 400;
  }

  @media (min-width: 1280px) {
    min-height: 195px;
    max-height: 400px;
    padding-left: 11px;
    padding-right: 11px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-top: 0px;
  }
`

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: -12px;
  padding: 5px 0;
  align-self: end;
  ${(p) => p.spaced && 'margin-top: 20px;'};
`

export const Counter = styled.span`
  font-size: 12px;
  margin-top: 5px;
  color: #242e46;
  ${(p) => p.isError && 'color: #DD2326;'};
`

export const TotalPaymentBlock = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1280px) {
    margin-top: 0px;
  }
`

export const TotalSum = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  color: #242e46;
  margin-top: 40px;
  font-family: sans-serif;
`

export const PaymentCondition = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #484857;
  margin-top: 8px;
  margin-bottom: 38px;
`

export const PaymentButton = styled.button`
  position: relative;
  background: #00c084;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: 30px;
  padding: 11px 0px;
  width: 100%;
  transition: 0.3s;
  &:hover {
    &:not(:disabled) {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24),
        0px 0px 2px rgba(0, 0, 0, 0.12);
    }
  }
  @media (min-width: 768px) {
    width: unset;
    padding: 11px 65px;
  }
  @media (min-width: 1280px) {
    margin-top: 60px;
    margin-bottom: 45px;
    padding: 11px 95px;
    font-size: 18px;
    opacity: 0.87;
  }
  &:disabled {
    background-color: #888e9b;
    cursor: initial;
  }
`

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;

  @media (min-width: 768px) {
    justify-content: flex-end;
    padding-top: 0px;
  }
`

export const PriceValue = styled.div`
  font-size: 14px;
  text-align: center;
  color: #242e46;
  font-weight: 500;
  padding-top: 10px;
  font-family: sans-serif;

  @media (min-width: 768px) {
    font-size: 24px;
    padding-top: 0px;
  }

  @media (min-width: 1280px) {
    color: #393939;
    font-weight: normal;
  }
`

export const WarningIcon = styled.div`
  background-image: url(${warning});
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  height: 18px;
  width: 20px;
  right: -20px;
  top: -8px;
  @media (min-width: 400px) {
    height: 22px;
    width: 25px;
    right: -28px;
    top: -12px;
  }
`

export const PriceTimeSelect = styled(Select)`
  margin-left: 20px;
  width: 250px;
`

export const PromoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 4%), 0px 0px 2px rgb(0 0 0 / 2%);
  background: #ffffff;
  width: 100%;
  height: 55px;
  margin-bottom: 8px;
  border-radius: 5px;
`

export const PromoHeader = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #242e46;
  width: 30%;
`

export const ErrorIconContainer = styled.div`
  position: absolute;
  padding: 10px;
  height: 40px;
  width: 30px;
  box-sizing: border-box;
  right: 25px;

  .top.noArrow {
    right: -25px;
    left: unset;
    top: -50px;
  }

  @media (min-width: 768px) {
  }
  @media (min-width: 1280px) {
  }
`

export const ErrorIcon = styled.div`
  position: absolute;
  height: 18px;
  width: 18px;
  background-image: url(${errorIcon});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Line = styled.div`
  height: 37px;
  width: 1px;
  border-right: 0.2px solid #000000;
`

export const Error = styled.div`
  color: red;
  font-size: 12px;
  margin-top: -12px;
`
export const DomainInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 40px;
  font-size: 16px;
  padding-left: 10px;
  box-sizing: border-box;
  width: 100%;
`
export const DomainWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 300px;
  align-items: center;
  margin-left: 20px;
`
export const DomainErrorMessage = styled.p`
  font-size: 14px;
  color: red;
  margin-top: 10px;
`
