import styled from 'styled-components';

import * as fontWeight from '../../assets/styles/constants/fontWeight';

export const ConstructorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 0px;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 40px;
  max-width: 600px;

  @media (min-width: 768px) {
    width: 700px;
    padding: 30px;
    box-sizing: initial;
    max-width: unset;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
    background-color: #ffffff;
    padding: 21px 14px 21px 59px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
    margin-left: 50px;
    margin-right: 50px;
    width: 1120px;
    padding: 27px 21px;
  }
`;

export const Row = styled.div`
  background-color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px 10px;
  box-sizing: border-box;
  @media (min-width: 768px) {
    padding: 30px;
  }
  @media (min-width: 1280px) {
    padding: 0 30px;
    width: 90%;
  }
`;

export const TitleRow = styled(Row)`
  justify-content: center;
  @media (min-width: 1280px) {
    width: 10%;
    border-right: 1px solid #9ea4ae;
    min-height: 80px;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const TitleContainer = styled.div`
  font-size: 24px;
  font-weight: ${fontWeight.MEDIUM};
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  word-wrap: break-word;
  padding: 5px;
  box-sizing: border-box;
  @media (min-width: 768px) {
    padding: 0px;
    width: unset;
  }
`;

export const ColumnKey = styled.div`
  font-size: 16px;
  color: #a8a8a8;
  padding-top: 18px;
  text-align: center;
  order: 1;
  font-size: 16px;
  font-weight: ${fontWeight.REGULAR};
  color: #393939;

  @media (min-width: 768px) {
    width: unset;
  }

  @media (min-width: 1280px) {
    order: 2;
    max-width: unset;
    width: 152px;
  }
`;

export const ColumnValue = styled.div`
  color: #393939;
  font-weight: ${fontWeight.THIN};
  order: 2;
  padding-top: 8px;
  font-size: 16px;

  @media (min-width: 1280px) {
    font-size: 20px;
    order: 1;
    padding-top: 0px;
  }
`;

export const OrderContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media (min-width: 1280px) {
    width: unset;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: #242e46;
  font-weight: ${fontWeight.THIN};
  margin-bottom: 20px;
  background-color: #ffffff;
  width: 100%;
  padding: 30px;
  margin-top: 20px;
  box-sizing: border-box;
  text-align: center;
  @media (min-width: 1280px) {
    color: #393939;
    padding: 0;
    margin-top: 10px;
  }
`;

export const OrderButton = styled.button`
  display: flex;
  background-color: #242e46;
  color: #ffffff;
  width: 100%;
  justify-content: center;
  font-size: 18px;
  text-align: center;
  padding: 30px;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }

  @media (min-width: 1280px) {
    background-color: #242e46;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: #ffffff;
    padding: 9px 0px;
    text-align: center;
    width: 182px;
    transition: 0.3s;
  }
`;
