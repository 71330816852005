import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

const MapWithAMarker = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat: 40.198013, lng: 44.494408 }}
    >
      <Marker position={{ lat: 40.198013, lng: 44.494408 }} />
    </GoogleMap>
  ))
);

export default MapWithAMarker;
