import React from 'react';
import * as S from './Skeleton.styles';

const Skeleton = () => {
  return (
    <S.MainBlock>
      <S.CartContainer>
        <S.ChoosedItemsContainer></S.ChoosedItemsContainer>
        <S.OrderButton></S.OrderButton>
      </S.CartContainer>
      <S.OptionsListContainer>
        <S.Options>
          <S.List>
            <S.ListItemTopContent></S.ListItemTopContent>
            <S.AddButton></S.AddButton>
          </S.List>
          <S.List>
            <S.ListItemTopContent></S.ListItemTopContent>
            <S.AddButton></S.AddButton>
          </S.List>
          <S.List>
            <S.ListItemTopContent></S.ListItemTopContent>
            <S.AddButton></S.AddButton>
          </S.List>
          <S.List>
            <S.ListItemTopContent></S.ListItemTopContent>
            <S.AddButton></S.AddButton>
          </S.List>
          <S.List>
            <S.ListItemTopContent></S.ListItemTopContent>
            <S.AddButton></S.AddButton>
          </S.List>
        </S.Options>
        <S.ShowMore>
          <S.ShowMoreButton></S.ShowMoreButton>
        </S.ShowMore>
      </S.OptionsListContainer>
    </S.MainBlock>
  );
};

export { Skeleton };
