import serviceHostingImage from '../../images/serviceHosting.png'
import serviceSSLImage from '../../images/serviceSSL.png'
import serviceSiteConstructor from '../../images/serviceSiteConstrctor.png'
import serviceCloudeVPS from '../../images/serviceCloudeVPS.png'
import customers from '../../images/customers.png'
import support from '../../images/support.png'
import theBest from '../../images/theBest.png'

export const customersOpinion = [
  {
    id: 'opinion1',
    opinion:
      'Ես ABCdomain ծառայություններից օգտվում եմ արդեն անթիվ տարիների ընթացքում, իսկապես արդեն չեմ էլ հիշում, թե երբվանից։ Ամեն ինչ լավ է։ Մի հատ խնդրի եմ մենակ անընդհատ հանդիպում, վճարումների համակարգը մեկ֊մեկ խուճուճ ա։ Բայց, այս հարցն էլ լուծում ունի, խնդրում եմ աշխատղներին, միանգամից հարցը լուծվում է։',
    author: 'Սամվել Մարտիրոսյան` Տեղեկատվական անվտանգության փորձագետ',
  },

  {
    id: 'opinion2',
    opinion:
      'Информационная компания АрмИнфо долгие годы работает с ABC Domain и всегда чувствовала наличие рядом с собой надежного и ответственного партнера. Ни разу ни с одной смежной проблемой мы не оставались одни, ABC Domain всегда вовремя и оперативно реагировала на наши нужды, исправляя порой наши собственные ошибки. Очень довольны работой партнеров и желаем им дальнейшего процветания.',
    author: 'Эммануил Мкртчян, Гендиректор, ИК АрмИнфо',
  },
  {
    id: 'opinion3',
    opinion:
      'Երկարամյա գործընկերային հարաբերություններ ունենալով՝ կարող եմ ասել, որ ABC Domain ընկերությունը վստահելի, պատասխանատու և արագ արձագանքող թիմ է։ <br /><br />Առավել կարևորում եմ տեխնիկական աջակցության դերը, քանի որ, անշուշտ, խնդիրներ կարող են առաջանալ ցանկացած ընկերության մոտ, բայց ABC Domain ընկերությունը շատ արագ և որակով լուծում է առաջացած խնդիրները։',
    author:
      'Մասիս Եփրեմյան, Գործառնությունների և մոնիթորինգի բաժնի ղեկավար, Սոֆթ Կոնստրակտ ՍՊԸ',
  },
]

export const tld = [
  '.am',
  '.հայ',
  '.com',
  '.net',
  '.org',
  '.ru',
  '.us',
  '.info',
  '.beauty',
]

export const whyChooseUs = [
  {
    id: 'market_domains',
    key: 'whyChooseUsBlock.key1',
    value: 'whyChooseUsBlock.value1',
    image: theBest,
  },
  {
    id: 'providing_domains',
    key: 'whyChooseUsBlock.key2',
    value: 'whyChooseUsBlock.value2',
    image: support,
  },
  {
    id: 'customers',
    key: 'whyChooseUsBlock.key3',
    value: 'whyChooseUsBlock.value3',
    image: customers,
  },
]

export const services = [
  {
    id: 'hosting',
    title: 'servicesBlock.hosting',
    description: 'servicesBlock.hostingDescription',
    image: serviceHostingImage,
    link: '/hosting-packages',
  },
  {
    id: 'ssl',
    title: 'servicesBlock.ssl',
    description: 'servicesBlock.sslDescription',
    image: serviceSSLImage,
    link: '/ssl-packages',
  },
  {
    id: 'constructor',
    title: 'servicesBlock.constructor',
    description: 'servicesBlock.constructorDescription',
    image: serviceSiteConstructor,
    link: '/constructor-packages',
  },
  {
    id: 'vps',
    title: 'servicesBlock.cloudVPS',
    description: 'servicesBlock.cloudVPSDescription',
    image: serviceCloudeVPS,
    link: '/vps-packages',
  },
]

export const SSLservices = [
  {
    id: 'ssl_one',
    title: 'SSlservicesBlock.ssl_one',
    description: 'SSlservicesBlock.sslDescription_one',
    link: '/ssl-packages',
  },
  {
    id: 'ssl_two',
    title: 'SSlservicesBlock.ssl_two',
    description: 'SSlservicesBlock.sslDescription_two',
    link: '/ssl-packages',
  },
  {
    id: 'ssl_three',
    title: 'SSlservicesBlock.ssl_three',
    description: 'SSlservicesBlock.sslDescription_three',
    link: '/ssl-packages',
  },
  {
    id: 'ssl_four',
    title: 'SSlservicesBlock.ssl_four',
    description: 'SSlservicesBlock.sslDescription_four',
    link: '/ssl-packages',
  },
]

export const MobileAppAdvantageList = [
  'aboutMobileAppBlock.list.0',
  'aboutMobileAppBlock.list.1',
  'aboutMobileAppBlock.list.2',
  'aboutMobileAppBlock.list.3',
]
