import React, { useEffect, useCallback } from 'react'
import { changeUrl, constructCannonical } from '../../hooks/changeUrl'
import { serversLocations, serverTypes } from './VpsPage.constants'
import { initialValues, yupScheme } from './VpsPage.config'
import { QuestionsList } from '../../components/QuestionsList'
import { LayoutCommon } from '../../layouts/LayoutCommon'
import { questions } from '../SupportPage/SupportPage.constants'
import { Formik } from 'formik'
import { Helmet } from 'react-helmet'
import { Loader } from '../../components/Loader'
import { i18n } from '../../i18n'

import * as S from './VpsPage.styles'

const VpsPage = ({
  getServerLocation,
  locationData,
  locationLoading,
  getServerOs,
  osLoading,
  osData,
  getServerSize,
  sizeLoading,
  sizeData,
  shouldFixedFooterBeVisible,
  numberWithCommas,
  auth,
  addToCart,
  history,
}) => {
  useEffect(() => {
    getServerLocation()
    getServerOs()
    getServerSize()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  const productsProps = Array.from(sizeData)

  const handleOrderNowButton = useCallback(
    (vps) => {
      for (let i = 0; i < vps.count; i++) {
        const id = addToCart({
          ...vps,
          type: 'vps',
          time: { value: 1, name: 'monthly' },
        })

        const res = []

        res.push(id)

        history.push(changeUrl(`/order-details?id=${id}`))
      }
    },
    [history, addToCart]
  )

  return (
    <LayoutCommon transparentHeader>
      <S.PageWrapper>
        <Helmet>
          <title>{i18n.t('headers.vpsPage')}</title>
          <meta
            name="description"
            content={i18n.t('headersDescriptions.vpsPage')}
          />
          <link
            rel="alternate"
            href="https://internet.am/en/vps-packages"
            hreflang="en"
          />
          <link
            rel="alternate"
            href="https://internet.am/ru/vps-packages"
            hreflang="ru"
          />
          <link
            rel="alternate"
            href="https://internet.am/vps-packages"
            hreflang="am-AM"
          />
          <meta name="keywords" content={i18n.t('headerKeywords.vpsPage')} />
          <meta property="og:title" content={i18n.t('headers.vpsPage')} />
          <meta
            property="og:description"
            content={i18n.t('headersDescriptions.vpsPage')}
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="ABC Domain LLC" />
          <meta
            property="og:image"
            content="https://internet.am/images/VPS.png"
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={i18n.t('headers.vpsPage')} />
          <meta
            name="twitter:description"
            content={i18n.t('headersDescriptions.vpsPage')}
          />
          <meta
            name="twitter:image"
            content="https://internet.am/images/VPS.png"
          />
        </Helmet>
        <S.HeaderContainer>
          <S.TitleWrapper>
            <S.VpsTitle>{i18n.t('vpsPage.vServers')}</S.VpsTitle>
            <S.VpsSTitle>{i18n.t('vpsPage.sTitle')}</S.VpsSTitle>
          </S.TitleWrapper>
        </S.HeaderContainer>
        <S.PageBody>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={yupScheme}
            onSubmit={(values, { setSubmitting }) => {
              handleOrderNowButton(values)
              setSubmitting(false)
            }}
          >
            {({ touched, errors, handleChange, values, setFieldValue }) => (
              <S.StyledForm>
                {locationLoading || osLoading || sizeLoading ? (
                  <Loader color="#242e46" size={0.8} />
                ) : (
                  <>
                    <S.ServersLocationHeader>
                      {i18n.t('vpsPage.sLocation')}
                    </S.ServersLocationHeader>
                    <S.Wrapper>
                      <S.LocationContainer>
                        {locationData.map((serverLocation) => {
                          const match = serversLocations.find(
                            (sl) => sl.name === serverLocation.country
                          )
                          return (
                            <S.ServerLocation
                              key={serverLocation.id}
                              onClick={() => {
                                setFieldValue(
                                  'serverLocationId',
                                  serverLocation.id
                                )
                                setFieldValue(
                                  'locationName',
                                  serverLocation.name
                                )
                              }}
                              isActive={
                                serverLocation.id === values.serverLocationId
                              }
                            >
                              <S.Flag
                                src={match?.image}
                                alt="Server location countries flag image"
                                title="Country flag image"
                              />
                              <S.Location>
                                <S.City>{serverLocation.name}</S.City>
                                <S.Country>
                                  {serverLocation.countryName}
                                </S.Country>
                              </S.Location>
                            </S.ServerLocation>
                          )
                        })}
                      </S.LocationContainer>
                    </S.Wrapper>
                    <S.TypeHeader>{i18n.t('vpsPage.serverType')}</S.TypeHeader>
                    <S.Wrapper>
                      <S.ServerTypeContainer>
                        {osData.map((serverOs) => {
                          const match = serverTypes.find(
                            (st) => st.name === serverOs.os.family
                          )
                          return (
                            <S.TypeContainer
                              key={serverOs.id}
                              onClick={() => {
                                setFieldValue('serverOsId', serverOs.id)
                                setFieldValue('osName', serverOs.os.name)
                              }}
                              isActive={serverOs.id === values.serverOsId}
                            >
                              <S.TypeImg
                                src={match.image}
                                alt="Server type image"
                                title="server type image"
                              />
                              <S.TypeBox>
                                <S.TypeName>{serverOs.os.name}</S.TypeName>
                                <S.TypeProps>{serverOs.os.arch}</S.TypeProps>
                              </S.TypeBox>
                            </S.TypeContainer>
                          )
                        })}
                      </S.ServerTypeContainer>
                    </S.Wrapper>
                    <S.SizeHeader>{i18n.t('vpsPage.serverSize')}</S.SizeHeader>
                    <S.Wrapper>
                      <S.ServerSizeContainer>
                        {productsProps.map((productProps) => {
                          const params = JSON.parse(productProps.description)
                          return (
                            <S.SizeContainer
                              key={productProps.pid}
                              onClick={() => {
                                setFieldValue('pid', productProps.pid)
                                setFieldValue(
                                  'productServerSize',
                                  productProps.name
                                )
                                setFieldValue(
                                  'price',
                                  +productProps.pricing.AMD.monthly
                                )
                                setFieldValue(
                                  'price_usd',
                                  +productProps.pricing.USD.monthly
                                )
                              }}
                              isActive={productProps.pid === values.pid}
                            >
                              <S.ServerName>{productProps.name}</S.ServerName>
                              <S.ServerPrice>
                                {numberWithCommas(
                                  auth.currency === 2
                                    ? productProps.pricing.USD.monthly
                                    : productProps.pricing.AMD.monthly
                                )}{' '}
                                <span>
                                  / {i18n.t('general.periods.monthly')}
                                </span>
                              </S.ServerPrice>
                              <S.Line />
                              <S.ServerCpu>{params.cpu.cores} CPU</S.ServerCpu>
                              <S.ServerMemory>
                                {params.ram.capacity}{' '}
                                {params.ram.unit.toUpperCase()} Memory
                              </S.ServerMemory>
                              <S.ServerSpace>
                                {params.ssd.capacity}{' '}
                                {params.ssd.unit.toUpperCase()} Bandwidth
                              </S.ServerSpace>
                            </S.SizeContainer>
                          )
                        })}
                      </S.ServerSizeContainer>
                    </S.Wrapper>
                    <S.HostnameLabel>
                      {i18n.t('vpsPage.hostnameLabel')}
                    </S.HostnameLabel>
                    <S.RelativeWrapper>
                      <S.UpperBlockWrapper>
                        <S.InputContainer>
                          <S.InputWrapper>
                            <S.Input
                              placeholder={i18n.t('vpsPage.labelPlaceholder')}
                              name="serverLabel"
                              onChange={handleChange}
                              value={values.serverLabel}
                            />
                            {errors.serverLabel && touched.serverLabel && (
                              <S.ErrorMessage>
                                {i18n.t(errors.serverLabel)}
                              </S.ErrorMessage>
                            )}
                          </S.InputWrapper>
                        </S.InputContainer>
                        <S.Panel
                          shouldPanelBeFixed={shouldFixedFooterBeVisible}
                        >
                          <S.ServerQ>
                            <S.Header>{i18n.t('vpsPage.serverQ')}</S.Header>
                            <S.Counter>
                              <S.Decrement
                                onClick={() => {
                                  setFieldValue(
                                    'count',
                                    values.count - 1 < 1 ? 1 : values.count - 1
                                  )
                                }}
                                disabled={
                                  values.count === 1 ||
                                  !values.locationName ||
                                  !values.osName ||
                                  !values.productServerSize ||
                                  !values.serverLabel
                                }
                              />
                              <S.Count>
                                {!values.locationName ||
                                !values.osName ||
                                !values.productServerSize ||
                                !values.serverLabel
                                  ? 1
                                  : values.count}
                              </S.Count>
                              <S.Increment
                                onClick={() => {
                                  setFieldValue(
                                    'count',
                                    values.count + 1 > 3 ? 3 : values.count + 1
                                  )
                                }}
                                disabled={
                                  values.count === 3 ||
                                  !values.locationName ||
                                  !values.osName ||
                                  !values.productServerSize ||
                                  !values.serverLabel
                                }
                              >
                                +
                              </S.Increment>
                            </S.Counter>
                          </S.ServerQ>
                          <S.ServerProps>
                            <S.Header>{i18n.t('vpsPage.location')}</S.Header>
                            <S.ServerDescription>
                              {values.locationName}
                            </S.ServerDescription>
                          </S.ServerProps>
                          <S.ServerProps>
                            <S.Header>{i18n.t('vpsPage.type')}</S.Header>
                            <S.ServerDescription>
                              {values.osName}
                            </S.ServerDescription>
                          </S.ServerProps>
                          <S.ServerProps>
                            <S.Header>{i18n.t('vpsPage.size')}</S.Header>
                            <S.ServerDescription>
                              {values.productServerSize
                                .split('SSD Cloud')
                                .join(' ')}
                            </S.ServerDescription>
                          </S.ServerProps>
                          <S.ServerPriceContainer>
                            <S.Header>{i18n.t('vpsPage.price')}</S.Header>
                            <S.ServerDescription>
                              {auth.currency === 2
                                ? values.price
                                  ? `${numberWithCommas(
                                      values.price_usd * values.count
                                    )} / ${i18n.t('general.periods.monthly')}`
                                  : null
                                : values.price
                                ? `${numberWithCommas(
                                    values.price * values.count
                                  )} / ${i18n.t('general.periods.monthly')}`
                                : null}
                            </S.ServerDescription>
                          </S.ServerPriceContainer>
                          <S.RegisterButton
                            type="submit"
                            disabled={
                              !values.locationName ||
                              !values.osName ||
                              !values.productServerSize ||
                              !values.serverLabel
                            }
                          >
                            {i18n.t('vpsPage.registerNow')}
                          </S.RegisterButton>
                        </S.Panel>
                      </S.UpperBlockWrapper>
                    </S.RelativeWrapper>
                  </>
                )}
              </S.StyledForm>
            )}
          </Formik>
          <S.UpperBlock>
            <S.CloudsImg />
            <S.Feedback>
              <S.Questions>{i18n.t('hostingPage.contacUsHeader')}</S.Questions>
              <S.WriteUs to={changeUrl('/open-ticket')}>
                {i18n.t('hostingPage.contacUsSubHeader')}
              </S.WriteUs>
            </S.Feedback>
          </S.UpperBlock>
          <S.BorderLine />
          <QuestionsList isInPage questions={questions.vps.list} />
        </S.PageBody>
      </S.PageWrapper>
    </LayoutCommon>
  )
}

export { VpsPage }
