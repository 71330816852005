import styled from 'styled-components'
import Header from '../../images/hosting-header.jpg'
import HeaderSm from '../../images/hosting-header-sm.jpg'
import HeaderMd from '../../images/hosting-header-md.jpg'
import cloudsSm from '../../images/clouds-sm.png'
import cloudsMd from '../../images/clouds-md.png'
import cloudsLg from '../../images/clouds-lg.png'
import ballonSm from '../../images/ballon-sm.png'
import ballonMd from '../../images/ballon-md.png'
import ballonLg from '../../images/ballon-lg.png'

import { Link } from 'react-router-dom'

export const PageBody = styled.div`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 75px;
`

export const PageWrapper = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  @media (min-width: 768px) {
    margin-top: -77px;
  }
`

export const HeaderContainer = styled.div`
  background-image: url(${HeaderSm});
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.1);
  background-size: cover;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 190px;
  @media (min-width: 768px) {
    background-image: url(${HeaderMd});
    height: 550px;
  }
  @media (min-width: 1280px) {
    height: 725px;
    background-image: url(${Header});
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  height: auto;
  margin-bottom: 27px;
  margin-top: 50px;
  @media (min-width: 768px) {
    align-self: flex-end;
    align-items: flex-start;
    margin-bottom: 35px;
    margin-top: 0px;
    width: 50%;
  }
  @media (min-width: 1280px) {
    width: 80%;
    padding-left: 50px;
    margin-bottom: 75px;
  }
`

export const HostingTitle = styled.h1`
  text-align: center;
  color: #3282b6;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
  @media (min-width: 768px) {
    font-size: 34px;
  }
  @media (min-width: 1280px) {
    font-size: 64px;
    margin-bottom: 10px;
  }
`

export const HostingSTitle = styled.h2`
  text-align: center;
  color: #3282b6;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 18px;

  @media (min-width: 768px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
  @media (min-width: 1280px) {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 40px;
  }
`

export const OrderNowButton = styled.a`
  padding: 17px 21px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  font-size: 14px;
  font-weight: normal;
  align-self: center;
  text-decoration: none;
  color: #212121;
  @media (min-width: 768px) {
    align-self: flex-start;
  }
  @media (min-width: 1280px) {
    font-size: 18px;
  }
`

export const HostingCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 21px 25px;
  width: 100%;
`

export const HostingCardsInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1280px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

export const HostingCategoryContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  width: 310px;
  margin-top: 30px;
  margin-bottom: 22px;
  @media (min-width: 768px) {
    width: 650px;
  }
  @media (min-width: 1280px) {
    width: unset;
    justify-content: space-around;
  }
`

export const HostingCategory = styled.div`
  font-size: 18px;
  color: #484857;
  cursor: pointer;
  padding-right: 4px;
  padding-left: 4px;
  padding-bottom: 6px;

  @media (min-width: 768px) {
    font-size: 34px;
  }
  @media (min-width: 1280px) {
    font-size: 36px;
  }
`

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 0px;
  width: 50%;
  color: #393939;
  @media (min-width: 768px) {
    width: 50%;
    padding: 18px 0px;
  }
  @media (min-width: 1280px) {
    padding: 29px 0;
    width: 100%;
    color: #ffffff;
    background-color: ${(p) => p.color};
  }
`

export const PriceValueContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  font-family: sans-serif;
  color: #393939;
  @media (min-width: 768px) {
    font-size: 20px;
  }
  @media (min-width: 1280px) {
    font-size: 20px;
    text-align: center;
    color: #ffffff;
    line-height: 23px;
  }
`

export const PriceKeyContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #393939;
  @media (min-width: 768px) {
    font-size: 20px;
  }
  @media (min-width: 1280px) {
    font-size: 20px;
    text-align: center;
    color: #ffffff;
    line-height: 23px;
  }
`

export const HostingPackageDiv = styled.div`
  background: #ffffff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  margin-bottom: 20px;
  @media (min-width: 1280px) {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    flex-direction: column;
    width: 242px;
    background-color: #ffffff;
    border-radius: 5px;
    transition: transform 0.1s;
    margin-right: 15px;
    margin-bottom: 0px;
    &:last-child {
      margin: 0px;
    }
    &:hover {
      transform: scale(1.03) translate(0, 10px);
      ${PriceContainer} {
        background-color: transparent;
        ${PriceValueContainer} {
          color: black;
        }
        ${PriceKeyContainer} {
          color: black;
        }
      }
    }
  }
`

export const HostingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const PackageParagraph = styled.div`
  text-align: center;
  color: #595ba8;
  font-weight: 500;
  font-size: 24px;
  margin-top: 8px;
  @media (min-width: 768px) {
    margin-top: 20px;
    font-size: 32px;
  }

  @media (min-width: 1280px) {
    font-weight: 500;
    font-size: 24px;
    padding: 30px 0;
  }
`

export const Details = styled.div`
  display: flex;
  flex-direction: row;
  margin: 25px 0px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: 768px) {
    margin: 35px 0px;
  }
  @media (min-width: 1280px) {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }
`

export const DetailContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  width: 50%;
  text-align: center;
  @media (min-width: 768px) {
    width: unset;
  }
  @media (min-width: 1280px) {
    padding: 25px 0;
  }
`

export const KeyContainer = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: #393939;
  padding-left: 15px;
  padding-right: 15px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 1280px) {
    font-weight: 300;
  }
`

export const ValueContainer = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #393939;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`

export const BuyButton = styled.button`
  border-radius: 0px 0px 5px 0px;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  height: 83px;
  color: #ffffff;
  background-color: ${(p) => p.color};
  width: 50%;
  padding: 31px 35px;
  border: none;
  @media (min-width: 768px) {
    font-size: 24px;
  }
  @media (min-width: 1280px) {
    border-radius: 5px;
    border: none;
    width: 127px;
    height: 38px;
    font-size: 18px;
    padding: 8px 14px;
    margin-top: 17px;
    margin-bottom: 36px;
    background-color: #00c084;
    color: #ffffff;
    transition: 0.3s;
    &:hover {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24),
        0px 0px 2px rgba(0, 0, 0, 0.12);
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  @media (min-width: 1280px) {
    flex-direction: column;
    align-items: center;
  }
`

export const ConstructorPromo = styled.div`
  background: #ffffff;
  border: 1px solid #242e46;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 90px;
    width: 700px;
    flex-wrap: wrap;
  }
  @media (min-width: 1280px) {
    flex-direction: row;
    margin-top: 95px;
    position: relative;
    min-width: 1260px;
    height: 178px;
  }
`

export const CreateText = styled.div`
  font-weight: 300;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  color: #484857;
  margin-top: 20px;
  margin-bottom: 8px;
  order: 1;
  padding: 0px 50px;

  > span {
    font-weight: 500;
  }

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 56px;
    order: 1;
    width: 57%;
    margin: 0px;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-right: 40px;
    padding-left: 10px;
  }
  @media (min-width: 1280px) {
    padding: 33px 0px;
    margin-left: 40px;
    order: 1;
    width: 35%;
  }
`

export const StartText = styled.div`
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #484857;
  order: 2;
  margin-bottom: 27px;
  padding: 0px 20px;
  @media (min-width: 768px) {
    font-size: 27px;
    line-height: 32px;
    order: 3;
    padding: 0px 30px;
    margin-bottom: 30px;
    text-align: left;
  }
  @media (min-width: 1280px) {
    order: 2;
    padding: 0px 0px;
    width: 660px;
    align-self: flex-start;
    margin-top: 45px;
    margin-left: 45px;
  }
`

export const BeginButton = styled(Link)`
  background: #484857;
  border-radius: 5px;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  padding: 20px 31px;
  text-align: center;
  margin-bottom: 30px;
  order: 3;
  border: none;
  text-decoration: none;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    padding: 34px 30px;
    order: 2;
    margin-right: 30px;
    margin-bottom: 0px;
  }
  @media (min-width: 1280px) {
    font-size: 18px;
    padding: 14px 26px;
    order: 3;
    position: absolute;
    right: 60px;
    bottom: 35px;
  }
`

export const AdvantagesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
  margin-top: 70px;
  @media (min-width: 768px) {
    padding: 0px 70px;
    margin-top: 90px;
  }
  @media (min-width: 1280px) {
    margin-top: 100px;
    width: 100%;
  }
`
export const ListHeader = styled.div`
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #242e46;
  margin-bottom: 40px;
  @media (min-width: 768px) {
    margin-bottom: 50px;
    font-size: 36px;
    line-height: 42px;
  }
  @media (min-width: 1280px) {
    margin-bottom: 40px;
  }
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  @media (min-width: 768px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  @media (min-width: 1280px) {
    width: 90%;
  }
`
export const List = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    margin-bottom: 40px;
    margin-right: 10px;
  }
  @media (min-width: 1280px) {
    margin-bottom: 30px;
  }
`
export const ListImg = styled.img`
  width: 20px;
  height: 20px;
`
export const ListText = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-left: 10px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 21px;
  }
`

export const HelpContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;
  margin-top: 50px;
  position: relative;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  height: 260px;
  border-bottom: 2px solid #161251;
  @media (min-width: 768px) {
    padding: 0px 70px;
    margin-top: 50px;
    border-bottom: 4px solid #161251;
  }
  @media (min-width: 1280px) {
    margin-top: 100px;
    border-bottom: 7px solid #161251;
    height: unset;
  }
`

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 20px;
  @media (min-width: 768px) {
    padding-left: 140px;
  }
  @media (min-width: 1280px) {
    top: 140px;
  }
`

export const HelpHeader = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  text-align: left;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 30px;
  }
`

export const LiveChatHelp = styled.div`
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 10px;
  width: 80%;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 20px;
  }
`

export const OrderNowText = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 30px;
  width: 70%;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 23px;
  }
`
export const OrderNowUpperButton = styled.a`
  padding: 18px 24px;
  background: #242e46;
  border-radius: 5px;
  font-size: 14px;
  font-weight: normal;
  align-self: center;
  text-decoration: none;
  color: #ffffff;
  align-self: end;
  margin-bottom: 20px;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    padding: 21px 24px;
    font-size: 18px;
  }
`
export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${cloudsSm});
  background-repeat: no-repeat;
  background-size: cover;
  width: 210px;
  height: 145px;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: -34px;
  @media (min-width: 768px) {
    background-image: url(${cloudsMd});
    width: 430px;
    height: 268px;
    margin-right: -25px;
    align-items: center;
  }
  @media (min-width: 1280px) {
    background-image: url(${cloudsLg});
    width: 860px;
    height: 461px;
    justify-content: flex-end;
  }
`
export const UsersData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  @media (min-width: 768px) {
    margin-right: 0px;
    margin-left: 50px;
  }
  @media (min-width: 1280px) {
    margin-left: 110px;
  }
`

export const UsersNumber = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #3282b6;
  width: 70px;
  height: 40px;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 42px;
    width: 130px;
    height: 84px;
  }
  @media (min-width: 1280px) {
    font-size: 64px;
    line-height: 75px;
    margin-bottom: 20px;
    width: 220px;
    height: 150px;
  }
`

export const UsersText = styled.div`
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #3282b6;
  width: 129px;
  height: 40px;
  margin-bottom: 9px;
  @media (min-width: 768px) {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 23px;
    width: 167px;
    height: 64px;
  }
  @media (min-width: 1280px) {
    font-weight: 500;
    font-size: 26px;
    margin-bottom: 40px;
    width: 241px;
    height: 92px;
  }
`

export const CmsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
  margin-top: 70px;
  @media (min-width: 768px) {
    padding: 0px 70px;
    margin-top: 85px;
  }
  @media (min-width: 1280px) {
    margin-top: 100px;
    width: 90%;
  }
`

export const CmsHeader = styled.div`
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  @media (min-width: 768px) {
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
  }
`

export const LogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
  flex-wrap: wrap;
  width: 100%;
  @media (min-width: 768px) {
    margin-top: 40px;
    flex-wrap: nowrap;
    width: 100%;
  }
  @media (min-width: 1280px) {
    margin-top: 50px;
  }
`

export const LogoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
`

export const Logo = styled.img`
  width: 52px;
  height: 50px;
`
export const LogoTitle = styled.div`
  margin-top: 5px;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #242e46;
`

export const ABCAdvantagesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
  margin-top: 45px;
  width: 100%;
  @media (min-width: 768px) {
    padding: 0px 70px;
    margin-top: 65px;
  }
  @media (min-width: 1280px) {
    margin-top: 90px;
    width: 100%;
  }
`

export const ABCHeader = styled.div`
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 70px;
  }
  @media (min-width: 1280px) {
    margin-bottom: 45px;
  }
`

export const AdvantagesBody = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1280px) {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
`
export const AdvantagesBlokLeft = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1280px) {
    width: 45%;
    margin-right: 30px;
  }
`
export const AdvantagesBlokRight = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1280px) {
    width: 45%;
    margin-left: 30px;
  }
`

export const AdvantagesItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 17px;
  @media (min-width: 768px) {
    margin-bottom: 23px;
  }
  @media (min-width: 1280px) {
    height: 40px;
    align-items: center;
  }
`

export const CheckIcon = styled.img`
  width: 15px;
  height: 10px;
  @media (min-width: 768px) {
    width: 27px;
    height: 18px;
  }
`

export const AdvantageText = styled.div`
  margin-left: 10px;
  font-size: 12px;
  line-height: 13px;
  font-weight: normal;
  color: #000000;
  @media (min-width: 768px) {
    margin-left: 24px;
    font-size: 20px;
    line-height: 23px;
  }
`

export const ContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  position: relative;
  width: 100%;
  align-items: flex-start;
  border-bottom: 3px solid #161251;
  padding: 0px 20px;
  @media (min-width: 768px) {
    margin-top: 12px;
    border-bottom: 7px solid #161251;
    padding: 0px 70px;
  }
  @media (min-width: 1280px) {
    margin-top: 0px;
    border-bottom: 7px solid #161251;
  }
`

export const Image = styled.div`
  background-image: url(${ballonSm});
  background-repeat: no-repeat;
  background-size: cover;
  width: 245px;
  height: 175px;
  margin-left: -25px;
  @media (min-width: 768px) {
    background-image: url(${ballonMd});
    background-repeat: no-repeat;
    background-size: cover;
    width: 540px;
    height: 384px;
  }
  @media (min-width: 1280px) {
    background-image: url(${ballonLg});
    background-repeat: no-repeat;
    background-size: cover;
    width: 900px;
    height: 615px;
  }
`

export const ContactUs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 5px;
  width: 50%;
  @media (min-width: 768px) {
    top: 90px;
    right: 90px;
    width: unset;
  }
  @media (min-width: 1280px) {
    top: 170px;
    right: 150px;
  }
`

export const ContactUsHeader = styled.div`
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #484857;
  margin-top: 40px;
  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 42px;
  }
  @media (min-width: 1280px) {
    font-size: 48px;
    line-height: 56px;
  }
`
export const ContactUsSubHeader = styled.div`
  font-weight: normal;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  font-weight: 300;
  color: #484857;
  width: 140px;
  @media (min-width: 768px) {
    font-size: 24px;
    width: unset;
  }
  @media (min-width: 1280px) {
    font-size: 36px;
  }
`
