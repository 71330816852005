import * as Yup from 'yup';

export const initialValues = {
  domain: '',
};

export const yupScheme = Yup.object().shape({
  domain: Yup.string()
    .matches(
      /^[a-zA-Z0-9\u0561-\u0587\u0531-\u0556]([a-zA-Z0-9.\-\u0561-\u0587\u0531-\u0556])*[a-zA-Z0-9\u0561-\u0587\u0531-\u0556]$/,
      'validationFields.invalidTyping'
    )
    .required('validationFields.domain'),
});

export const tldCards = [
  {
    currentPrice: 6400,
    oldPrice: '10000/տարին',
    name: '.com',
    background:
      'linear-gradient(133.96deg, rgba(81, 72, 150, 0.8) -0.49%, rgba(57, 121, 179, 0.8) 100%)',
    bottomBlockOrder: 1,
  },
  {
    currentPrice: 5400,
    oldPrice: '10000/տարին',
    name: '.ru',
    background:
      'linear-gradient(222.91deg, rgba(140, 247, 164, 0.8) 0%, rgba(30, 166, 8, 0.8) 87.16%)',
    bottomBlockOrder: 4,
  },
  {
    currentPrice: 6400,
    oldPrice: '10000/տարին',
    name: '.net',
    background:
      'linear-gradient(46.58deg, rgba(246, 170, 77, 0.8) 6.7%, rgba(247, 91, 66, 0.8) 97.68%)',
    bottomBlockOrder: 2,
  },
  {
    currentPrice: 10000,
    oldPrice: '10000/տարին',
    name: '.am',
    background:
      'linear-gradient(136.33deg, rgba(182, 77, 246, 0.8) 4.43%, rgba(88, 6, 117, 0.8) 97.78%)',
    bottomBlockOrder: 3,
  },
];
