import { connect } from 'react-redux'

import { ManageFreeDNSModal as Self } from './ManageFreeDNSModal'

import { getDNSZones, setDNSZones } from './ManageFreeDNSModal.actions'
import { showModal } from '../../../common/Modal/Modal.actions'

const mapStateToProps = ({ app, manageFreeDNSModal }) => ({
  manageFreeDNSZones: manageFreeDNSModal.manageFreeDNSZones,
  manageFreeDNSZonesLoading: manageFreeDNSModal.manageFreeDNSZonesLoading,
  manageFreeDNSZonesError: manageFreeDNSModal.manageFreeDNSZonesError,
  updateDNSZonesLoading: manageFreeDNSModal.updateDNSZonesLoading,
  updateDNSZonesError: manageFreeDNSModal.updateDNSZonesError,
  auth: app.auth,
})

const mapDispatchToProps = {
  getDNSZones,
  setDNSZones,
  showModal,
}

export const ManageFreeDNSModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self)
