import { connect } from 'react-redux'

import { LayoutCommon as Self } from './LayoutCommon'

import { changeBackendLanguage } from '../../common/App/App.actions'
import { showModal } from '../../common/Modal/Modal.actions'

const mapStateToProps = ({ app }) => ({
  auth: app.auth,
  userInfo: app.userInfo,
})

const mapDispatchToProps = {
  changeBackendLanguage,
  showModal,
}

export const LayoutCommon = connect(mapStateToProps, mapDispatchToProps)(Self)
