import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { DomainConfigurationPage as Self } from './DomainConfigurationPage'
import { showModal } from '../../common/Modal/Modal.actions'
import { updateCart, removeFromCart } from '../../common/App/App.actions'
import { cleanRegisterError } from '../RegistrationPage/RegistrationPage.actions'

const mapStateToProps = ({ app }) => ({
  cart: app.cart,
  auth: app.auth,
})

const mapDispatchToProps = {
  showModal,
  updateCart,
  removeFromCart,
  cleanRegisterError,
}

export const DomainConfigurationPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Self)
)
