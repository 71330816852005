import { CLIENT_AREA_TABS } from "../../ClientAreaPage.constants";

export const headers = [
  {
    id: "invoiceHeader",
    tab: CLIENT_AREA_TABS.INVOICES,
    sortNumber: 0,
    sortType: "number",
    title: "invoicesTable.invoice",
    sortBy: "id",
  },
  {
    id: "priceHeader",
    tab: CLIENT_AREA_TABS.INVOICES,
    sortNumber: 1,
    sortType: "number",
    title: "invoicesTable.price",
    sortBy: "subtotal",
  },
  {
    id: "dateHeader",
    tab: CLIENT_AREA_TABS.INVOICES,
    sortNumber: 2,
    sortType: "number",
    title: "invoicesTable.date",
    sortBy: "date",
  },
  {
    id: "paymentHeader",
    tab: CLIENT_AREA_TABS.INVOICES,
    sortNumber: 3,
    sortType: "number",
    title: "invoicesTable.nextPayment",
    sortBy: "updated_at",
  },
  {
    id: "statusHeader",
    tab: CLIENT_AREA_TABS.INVOICES,
    sortNumber: 4,
    sortType: "text",
    title: "invoicesTable.status",
    sortBy: "status",
    isPaddingNeed: true,
  },
];
