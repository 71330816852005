import React from 'react'
import { Helmet } from 'react-helmet'

import { LayoutCommon } from '../../layouts/LayoutCommon'
import * as S from './EditProfilePage.styles'
import { EDIT_PROFILE_TABS } from './EditProfilePage.constants'
import { i18n } from '../../i18n'
import { PersonalDetails } from '../EditProfilePage/components/PersonalDetails'
import { Security } from '../EditProfilePage/components/Security'
import { Contacts } from '../EditProfilePage/components/Contacts'

export const EditProfilePage = ({ history, tab, changeTab }) => {
  return (
    <LayoutCommon>
      <Helmet>
        <title>{i18n.t('headers.editProfilePage')}</title>
        <meta
          name="description"
          content={i18n.t('headersDescriptions.editProfilePage')}
        />
        <meta name="og:title" content={i18n.t('headers.editProfilePage')} />
        <meta
          name="og:description"
          content={i18n.t('headersDescriptions.editProfilePage')}
        />
        <meta
          name="og:image"
          content="https://internet.am/images/fb_logo.png"
        />
      </Helmet>
      <S.PageBody>
        <S.HeaderContainer>
          <S.EditProfileTitle>
            {i18n.t('editProfilePage.title')}
          </S.EditProfileTitle>
        </S.HeaderContainer>
        <>
          <S.EditProfileContainer>
            <S.EditProfileCategory
              onClick={() => changeTab(EDIT_PROFILE_TABS.PERSONAL_DATA)}
              isActive={tab === EDIT_PROFILE_TABS.PERSONAL_DATA}
            >
              {i18n.t('editProfilePage.personalDetails')}
            </S.EditProfileCategory>
            <S.EditProfileCategory
              onClick={() => changeTab(EDIT_PROFILE_TABS.CONTACTS)}
              isActive={tab === EDIT_PROFILE_TABS.CONTACTS}
            >
              {i18n.t('editProfilePage.contacts')}
            </S.EditProfileCategory>
            <S.EditProfileCategory
              onClick={() => changeTab(EDIT_PROFILE_TABS.SECURITY)}
              isActive={tab === EDIT_PROFILE_TABS.SECURITY}
            >
              {i18n.t('editProfilePage.security')}
            </S.EditProfileCategory>
          </S.EditProfileContainer>
        </>
        {tab === EDIT_PROFILE_TABS.PERSONAL_DATA && <PersonalDetails />}
        {tab === EDIT_PROFILE_TABS.SECURITY && <Security />}
        {tab === EDIT_PROFILE_TABS.CONTACTS && <Contacts />}
      </S.PageBody>
    </LayoutCommon>
  )
}
