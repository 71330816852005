import { MODAL_TYPES } from '../../../common/Modal/Modal.types';
import { REGISTRATION_TYPES } from '../../../pages/RegistrationPage/RegistrationPage.types';
import { ADD_CONTACT_TYPES } from './AddContactModal.types';


const initialState = {
  personType: 0,
  loading: false,
  error: null,
};

function addContactModalPage(state = initialState, action) {
  switch (action.type) {
    case ADD_CONTACT_TYPES.SWITCH_PERSON_TYPE: {
      return {
        ...state,
        personType: action.personType,
      };
    }
    case ADD_CONTACT_TYPES.ADD_CONTACT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_CONTACT_TYPES.ADD_CONTACT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ADD_CONTACT_TYPES.ADD_CONTACT_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case MODAL_TYPES.HIDE_MODAL:
      return initialState;

    case REGISTRATION_TYPES.CLEAN_REGISTER_ERROR:
      return initialState;
    default:
      return state;
  }
}

export { addContactModalPage };
