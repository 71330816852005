import styled from 'styled-components'
import { Form, Field } from 'formik'

import closeModal from '../../../../images/closeModal.svg'

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 1;
  z-index: 100;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
  @media (min-width: 768px) {
    width: 670px;
    padding: 23px 42px;
    margin-left: 0;
    margin-right: 0;
  }
  @media (min-width: 1280px) {
    width: 800px;
  }
`
export const FormContainer = styled.div`
  width: 100%;
  @media (min-width: 1280px) {
    padding: 20px;
  }
`
export const ModalTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #242e46;
`
export const SubTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
  text-align: left;

  @media (min-width: 768px) {
    margin-bottom: 10px;
    font-size: 18px;
  }

  @media (min-width: 1280px) {
    font-size: 18px;
    margin-bottom: 15px;
    text-align: left;
  }
`

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
  }
`
export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-size: cover;

  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`

export const Input = styled(Field)`
  padding: 5px 12px;
  max-height: 44px;
  width: 100%;
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  text-align: left;
  margin-bottom: 5px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    width: 300px;
    padding: 12px;
    max-height: 44px;
    font-size: 16px;
  }
  @media (min-width: 1280px) {
    width: 350px;
    padding: 12px;
    max-height: 44px;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 0px;
  }
`

export const SubmitButton = styled.button`
  background-color: ${(p) => (p.disabled ? '#888e9b' : '#242e46')};
  border-radius: 5px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  opacity: 0.87;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 7px 50px;
  transition: 0.3s;
  cursor: ${(p) => (p.disabled ? 'initial' : 'pointer')};
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }

  @media (min-width: 768px) {
    padding: 11px 65px;
    font-size: 18px;
    align-self: flex-end;
    width: 230px;
    margin-bottom: 5px;
    margin-right: -30px;
  }
  @media (min-width: 1280px) {
    padding: 12px 65px;
    font-size: 18px;
    margin-bottom: 0px;
    margin-right: -20px;
  }
`
export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
`

export const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
  text-align: left;

  @media (min-width: 768px) {
    width: 300px;
    margin-bottom: 10px;
    font-size: 18px;
  }

  @media (min-width: 1280px) {
    width: 350px;
    font-size: 18px;
    margin-bottom: 15px;
    text-align: left;
  }
`
