import { SslConfigurationPage as Self } from './SslConfigurationPage'
import { connect } from 'react-redux'
import {
  configureSsl,
  initiateConfigureSslData,
  setStep,
  setValues,
  firstStepConfigure,
} from './SslConfigurationPage.actions'
import { showModal } from '../../common/Modal/Modal.actions'
import {
  getServiceDetails,
  setServiceDetails,
} from '../ClientAreaPage/ClientAreaPage.actions'

const mapStateToProps = ({
  sslConfiguationPage,
  CsrModal,
  clientAreaPage,
}) => ({
  step: sslConfiguationPage.step,
  csr: CsrModal.csrResult.csr,
  privateKey: CsrModal.csrResult.privateKey,
  nextStepData: sslConfiguationPage.nextStepData,
  initialValues: sslConfiguationPage.initialValues,
  approverEmails: sslConfiguationPage.approverEmails,
  approvalMethods: sslConfiguationPage.approvalMethods,
  loading: sslConfiguationPage.configureSslLoading,
  configureError: sslConfiguationPage.configureSslError,
  serviceDetails: clientAreaPage.serviceDetails,
  getServiceDetailsLoading: clientAreaPage.getServiceDetailsLoading,
  firstStepSslData: sslConfiguationPage.firstStepSslData,
})

const mapDispatchToProps = {
  showModal,
  configureSsl,
  setStep,
  setValues,
  initiateConfigureSslData,
  getServiceDetails,
  setServiceDetails,
  firstStepConfigure,
}

export const SslConfigurationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self)
