import FetchApi from '../../../helpers/FetchApi';
import { RESET_PASSWORD_TYPES } from './ResetPasswordModal.types';

export const resetPassword = (values, onSuccess) => async dispatch => {
  try {
    dispatch({
      type: RESET_PASSWORD_TYPES.RESET_PASSWORD_REQUEST,
    });
    await FetchApi.post('users/passreset', values);

    dispatch({
      type: RESET_PASSWORD_TYPES.RESET_PASSWORD_SUCCESS,
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    dispatch({
      type: RESET_PASSWORD_TYPES.RESET_PASSWORD_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    });
  }
};

export const clearError = () => ({
  type: RESET_PASSWORD_TYPES.CLEAR_FORM
})