import thawte from '../../images/thawte.png';
import geotrust from '../../images/geotrust.png';
import symantec from '../../images/symantec.png';
import sectigo from '../../images/sectigo.svg'

export const SSL_TABS = {
  DOMAIN_VALIDATION: 'domainValidation',
  ORGANIZATION_VALIDATION: 'organizattionValidation',
  EXTENDED_VALIDATION: 'exttendedValidation',
};

export const SslCards = {
  domainValidation: {
    title: 'Դոմենի ստուգում',
    data: [
      {
        id: 1,
        pid: 234,
        name: 'Positive SSL',
        image: sectigo,
        price: 10000,
        price_usd: 21.9,
      },
      {
        id: 2,
        name: 'Essential SSL',
        pid: 272,
        image: sectigo,
        price: 19000,
        price_usd: 39.9,
      },
      {
        id: 3,
        name: 'Positive SSL Wildcard',
        pid: 247,
        image: sectigo,
        price: 69000,
        price_usd: 142.0,
      },
      {
        id: 4,
        pid: 242,
        name: 'Essential SSL Wildcard',
        image: sectigo,
        price: 76000,
        price_usd: 149.9,
      },
      {
        id: 5,
        pid: 243,
        name: 'Rapid SSL',
        image: geotrust,
        price: 10000,
        price_usd: 21.9,
      },
      {
        id: 6,
        name: 'Rapid SSL Wildcard',
        pid: 244,
        image: geotrust,
        price: 87000,
        price_usd: 169.9,
      },
      {
        id: 7,
        name: 'Quick SSL Premium',
        pid: 112,
        image: geotrust,
        price: 44000,
        price_usd: 90.9,
      },
      {
        id: 8,
        pid: 114,
        name: 'Thawte SSL 123',
        image: thawte,
        price: 30000,
        price_usd: 61.9,
      },
    ],
  },
  organizationValidation: {
    title: 'Կազմակերպության ստուգում',
    data: [
      {
        id: 1,
        pid: 280,
        name: 'Instant SSL',
        image: sectigo,
        price: 30000,
        price_usd: 59.9,
      },
      {
        id: 2,
        pid: 285,
        name: 'Instant SSL PRO',
        image: sectigo,
        price: 37000,
        price_usd: 74.9,
      },
      {
        id: 3,
        pid: 119,
        name: 'Instant SSL Premium',
        image: sectigo,
        price: 40000,
        price_usd: 84,
      },
      {
        id: 4,
        pid: 273,
        name: 'Premium SSL Wildcard',
        image: sectigo,
        price: 120000,
        price_usd: 239.9,
      },
      {
        id: 5,
        pid: 115,
        name: 'Secure Site',
        image: sectigo,
        price: 275000,
        price_usd: 529.9,
      },
      {
        id: 6,
        pid: 116,
        name: 'Secure Site PRO',
        image: sectigo,
        price: 660000,
        price_usd: 1269.9,
      },
      {
        id: 7,
        pid: 117,
        name: 'True BusinessID',
        image: geotrust,
        price: 65000,
        price_usd: 129.9,
      },
      {
        id: 8,
        pid: 118,
        name: 'True BusinessID Wildcard',
        image: geotrust,
        price: 300000,
        price_usd: 579.9,
      },
      {
        id: 9,
        pid: 123,
        name: 'Thawte SSl Web server',
        image: thawte,
        price: 72000,
        price_usd: 139.9,
      },
    ],
  },
  extendedValidation: {
    title: 'Ընդլայնված ստուգում',
    data: [
      {
        id: 1,
        pid: 125,
        name: 'Secure Site\nwith EV',
        image: symantec,
        price: 650000,
        price_usd: 1249.9,
      },
      {
        id: 2,
        pid: 126,
        name: 'Secure Site Pro with EV',
        image: symantec,
        price: 950000,
        price_usd: 1829.9,
      },
      {
        id: 3,
        pid: 286,
        name: 'Sectigo EV SSL',
        image: sectigo,
        price: 92000,
        price_usd: 179.9,
      },
      {
        id: 4,
        pid: 129,
        name: 'True BusinessID',
        image: geotrust,
        price: 140000,
        price_usd: 269.9,
      },
      {
        id: 5,
        pid: 283,
        name: 'True BusinessID EV SAN',
        image: geotrust,
        price: 235000,
        price_usd: 459.9,
      },
      {
        id: 6,
        pid: 130,
        name: 'Thawte Web Server EV',
        image: thawte,
        price: 148000,
        price_usd: 289.9,
      },
    ],
  },
};
