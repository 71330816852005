import styled from 'styled-components';
import { Link } from 'react-router-dom';

import deleteButton from '../../../../images/deleteButton.svg';
import settingsButton from '../../../../images/settingsButton.svg';

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  width: 100%;
  @media (min-width: 1280px) {
    margin-top: 30px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 35px;
  width: 100%;
  @media (min-width: 768px) {
    width: 695px;
  }
  @media (min-width: 1280px) {
    width: 100%;
  }
`;

export const Contact = styled.div`
  box-sizing: border-box;
  border-radius: 5px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    height: 70px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  }
`;

export const Fullname = styled.div`
  display: flex;
  margin-left: 20px;
  @media (min-width: 768px) {
    margin-left: 45px;
  }
`;

export const FirstName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-right: 5px;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`;
export const LastName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`;
export const IconContainer = styled.div`
  display: flex;
  margin-right: 25px;
`;

export const SettingsIcon = styled(Link)`
  height: 22px;
  width: 22px;
  margin-right: 30px;
  background-image: url(${settingsButton});
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
`;
export const DeleteIcon = styled.button`
  height: 22px;
  width: 20px;
  background-image: url(${deleteButton});
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
`;

export const AddContactButton = styled.button`
  width: 100%;
  height: 49px;
  border-radius: 5px;
  background: #00c084;
  margin-top: 30px;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    width: 281px;
  }
`;
