const initialState = {
  width: 0,
  height: 0
}

export const viewport = (state=initialState, action) => {
  switch (action.type) {
    case 'SET_VIEWPORT_WIDTH':
      return {
        ...state,
        width: action.width,
        height: action.height
      };
    default:
      return state
  }
}