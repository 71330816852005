import React, { useState, useEffect, useCallback } from 'react'
import { ResetPasswordModal } from '../../Modals/ResetPasswordModal'
import { TwoFactorAuthModal } from '../../Modals/TwoFactorAuthModal'
import { changeUrl } from '../../../hooks/changeUrl'
import { Formik } from 'formik'
import { Loader } from '../../Loader'
import {
  loginYupScheme,
  loginAsClientYupScheme,
  loginInitialValues,
  loginAsClientInitialValues,
} from './LoginModal.config'
import { i18n } from '../../../i18n'
import slugify from 'react-slugify'
import qs from 'qs'

import * as S from './LoginModal.styles'

export const LoginModal = ({
  history,
  loading,
  hideModal,
  login,
  showModal,
  error,
  goTo,
  loginAsClientError,
  isLoginAsClient,
  resendEmail,
  resendMailOk,
  clearError,
}) => {
  const [loginAsClient, setLoginAsClient] = useState(false)

  useEffect(() => {
    if (history && history.location) {
      const params = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
        encode: true,
      })

      if (params.supermanisbatman) {
        setLoginAsClient(true)
      }
    }
  }, [])

  const handleEnter = useCallback((e, submitForm) => {
    if (e.key === 'Enter') {
      submitForm()
    }
  }, [])

  const responseFacebook = (response) => {}
  const responseGoogle = (response) => {}
  return (
    <S.Modal onClick={(e) => e.stopPropagation()}>
      <S.LoginModalContainer>
        <S.ModalTitle>{i18n.t('loginModal.title')}</S.ModalTitle>
        {loginAsClient ? (
          <Formik
            initialValues={loginAsClientInitialValues}
            validationSchema={loginAsClientYupScheme}
            onSubmit={(values, { setSubmitting }) => {
              if (!!values.username && !!values.email && !!values.password) {
                login(
                  values,
                  () => {
                    showModal(TwoFactorAuthModal, {
                      username: values.username,
                      email: values.email,
                      goTo,
                    })
                  },
                  () => {
                    if (goTo) {
                      history.push(goTo)
                    } else {
                      history.push(changeUrl('/client-area'))
                    }
                    hideModal()
                  },
                  'loginasclient'
                )
              }
              setSubmitting(false)
            }}
          >
            {({
              touched,
              errors,
              values,
              isSubmitting,
              handleChange,
              submitForm,
            }) => (
              <S.StyledForm onKeyPress={() => handleEnter(submitForm)}>
                <S.FormContainer>
                  <S.FormItem>
                    <S.Label>{i18n.t('loginModal.mail')}</S.Label>
                    <S.Input
                      onChange={handleChange}
                      placeholder="example@example.com"
                      name="username"
                      value={values.username}
                    />
                    {errors.username && touched.username && (
                      <S.ErrorMessage>{i18n.t(errors.username)}</S.ErrorMessage>
                    )}
                  </S.FormItem>
                  <S.FormItem>
                    <S.Label>{i18n.t('loginModal.password')}</S.Label>
                    <S.Input
                      onChange={handleChange}
                      type="password"
                      placeholder="******"
                      name="password"
                      value={values.password}
                    />
                    {errors.password && touched.password && (
                      <S.ErrorMessage>{i18n.t(errors.password)}</S.ErrorMessage>
                    )}
                  </S.FormItem>
                  <S.FormItem type="email">
                    <S.Label>{i18n.t('loginModal.client')}</S.Label>
                    <S.Input
                      onChange={handleChange}
                      placeholder="example@example.com"
                      name="email"
                      value={values.email}
                    />
                    {errors.email && touched.email && (
                      <S.ErrorMessage>{i18n.t(errors.email)}</S.ErrorMessage>
                    )}
                  </S.FormItem>
                  {loginAsClientError && (
                    <S.ErrorMessage spaced>{loginAsClientError}</S.ErrorMessage>
                  )}
                </S.FormContainer>
                <S.LoginButton type="submit" disabled={isSubmitting}>
                  {loading ? (
                    <Loader color="#FFF" size={0.5} />
                  ) : (
                    i18n.t('loginModal.login')
                  )}
                </S.LoginButton>
              </S.StyledForm>
            )}
          </Formik>
        ) : (
          <Formik
            initialValues={loginInitialValues}
            validationSchema={loginYupScheme}
            onSubmit={(values, { setSubmitting }) => {
              if (!!values.email && !!values.password) {
                login(
                  values,
                  () => {
                    showModal(TwoFactorAuthModal, {
                      email: values.email,
                      goTo,
                    })
                  },
                  () => {
                    if (goTo) {
                      history.push(goTo)
                    } else {
                      history.push(changeUrl('/client-area'))
                    }
                    hideModal()
                  },
                  'login'
                )
              }
              setSubmitting(false)
            }}
          >
            {({
              touched,
              errors,
              values,
              isSubmitting,
              handleChange,
              submitForm,
            }) => (
              <S.StyledForm onKeyPress={() => handleEnter(submitForm)}>
                <S.FormContainer>
                  <S.FormItem type="email">
                    <S.Label>{i18n.t('loginModal.mail')}</S.Label>
                    <S.Input
                      onChange={(e) => {
                        !!error && clearError()
                        handleChange(e)
                      }}
                      placeholder="example@example.com"
                      name="email"
                      value={values.email}
                    />
                    {errors.email && touched.email && (
                      <S.ErrorMessage>{i18n.t(errors.email)}</S.ErrorMessage>
                    )}
                  </S.FormItem>
                  <S.FormItem>
                    <S.Label>{i18n.t('loginModal.password')}</S.Label>
                    <S.Input
                      onChange={(e) => {
                        !!error && clearError()
                        handleChange(e)
                      }}
                      placeholder="******"
                      type="password"
                      name="password"
                      value={values.password}
                    />
                    {errors.password && touched.password && (
                      <S.ErrorMessage>{i18n.t(errors.password)}</S.ErrorMessage>
                    )}
                  </S.FormItem>
                  <S.ErrorWrapepr>
                    {error &&
                      (error === 'blocked_account' ? (
                        <S.ErrorMessage>
                          {i18n.t(`backend.error_code.blocked_account`)}
                        </S.ErrorMessage>
                      ) : (
                        <S.ErrorMessage spaced>
                          {i18n.t(`backend.logInError.${slugify(error)}`)}
                        </S.ErrorMessage>
                      ))}
                    {error === 'Email is not verified!' && !resendMailOk ? (
                      <S.ResendEmail onClick={() => resendEmail(values.email)}>
                        {i18n.t('loginModal.resendEmail')}
                      </S.ResendEmail>
                    ) : resendMailOk ? (
                      <S.ResendEmailDone>
                        {i18n.t('loginModal.emailSent')}
                      </S.ResendEmailDone>
                    ) : null}
                  </S.ErrorWrapepr>
                  <S.ResetPasswordWrapepr>
                    <S.BottomContainer>
                      <S.ResetPassword
                        onClick={() => showModal(ResetPasswordModal)}
                      >
                        {i18n.t('loginModal.question')}
                      </S.ResetPassword>
                    </S.BottomContainer>
                  </S.ResetPasswordWrapepr>
                </S.FormContainer>
                <S.LoginButton type="submit" disabled={isSubmitting}>
                  {loading ? (
                    <Loader color="#FFF" size={0.5} />
                  ) : (
                    i18n.t('loginModal.login')
                  )}
                </S.LoginButton>
                {false && (
                  <S.Social>
                    <S.BtnFacebook
                      appId="186612929844366" //testing ID
                      autoLoad={false}
                      size="small"
                      textButton=""
                      fields="name,email,picture"
                      callback={responseFacebook}
                      icon="fa-facebook"
                      redirectUri="https://mdw-ext.internet.am/auth/facebook"
                    />
                    <S.BtnGoogle
                      clientId="" //CLIENTID NOT CREATED YET
                      buttonText=""
                      size="small"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                    />
                  </S.Social>
                )}
                <S.RegisterNow
                  to={changeUrl('/registration')}
                  onClick={() => hideModal()}
                >
                  {i18n.t('loginModal.registerNow')}
                </S.RegisterNow>
              </S.StyledForm>
            )}
          </Formik>
        )}
        <S.CloseModalButton onClick={hideModal} />
      </S.LoginModalContainer>
    </S.Modal>
  )
}
