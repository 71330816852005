import React, { useEffect, useCallback } from 'react'
import qs from 'qs'
import { Helmet } from 'react-helmet'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import { decode } from 'html-entities'

import { LayoutCommon } from '../../layouts/LayoutCommon'
import * as S from './ClientAreaPage.styles'
import { i18n } from '../../i18n'
import { clientServices, CLIENT_AREA_TABS } from './ClientAreaPage.constants'
import { DomainsTable } from './components/DomainsTable'
import { ServicesTable } from './components/ServicesTable'
import { TicketsTable } from './components/TicketsTable'
import { InvoicesTable } from './components/InvoicesTable'
import { Tooltip } from '../../components/Tooltip'
import { initialValues, yupScheme } from './ClientAreaPage.config'
import { Loader } from '../../components/Loader'
import { TransferDomainModal } from '../../components/Modals/TransferDomainModal'
import { changeUrl, constructCannonical } from '../../hooks/changeUrl'
import { setCart } from '../../common/App/App.actions'
import { MaintenanceModal } from '../../components/Modals/MaintenanceModal/MaintenanceModal'
import Cookies from 'js-cookie'

const ClientAreaPage = ({
  tab,
  changeTab,
  getDomains,
  getServices,
  getTickets,
  getInvoices,
  domainsLoading,
  servicesLoading,
  ticketsLoading,
  invoicesLoading,
  domainsTotal,
  servicesTotal,
  ticketsTotal,
  invoicesTotal,
  auth,
  history,
  userInfo,
  numberWithCommas,
  offsets,
  showModal,
  cutTld,
  getUserCredit,
  credit,
  isCredit,
  cart,
  sortBy,
  sortOrder,
  setInitialSearchParams,
}) => {
  useEffect(() => {
    getDomains(offsets.domains)
    getServices(offsets.services)
    getTickets(offsets.tickets)
    getInvoices(offsets.invoices)
  }, [])

  const totals = {
    domains: domainsTotal,
    services: servicesTotal,
    tickets: ticketsTotal,
    invoices: invoicesTotal,
  }

  const loading = {
    domains: domainsLoading,
    services: servicesLoading,
    tickets: ticketsLoading,
    invoices: invoicesLoading,
  }

  useEffect(() => {
    getUserCredit()
  }, [])

  const handleSearch = useCallback(
    (domain) => {
      cutTld(domain)
      const domainSplitted = domain.split('.')
      const domainCut = domainSplitted[0]
      const params = qs.stringify(
        { searchInput: domainCut },
        {
          addQueryPrefix: true,
          encode: true,
        }
      )
      history.push(changeUrl(`/domain-registration${params}`))
    },
    [history]
  )

  useEffect(() => {
    const matchPath = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    })

    if (
      !matchPath.tab ||
      !Object.keys(CLIENT_AREA_TABS).find(
        (item) => item === matchPath.tab.toUpperCase()
      )
    ) {
      return
    }

    changeTab(
      CLIENT_AREA_TABS[
        Object.keys(CLIENT_AREA_TABS).find(
          (item) => item === matchPath.tab.toUpperCase()
        )
      ]
    )
  }, [history.location.search])

  const switchTab = (tab) => {
    const query = qs.stringify(
      {
        tab: Object.keys(CLIENT_AREA_TABS)
          .find((item) => CLIENT_AREA_TABS[item] === tab)
          .toLowerCase(),
      },
      { addQueryPrefix: true }
    )
    history.push(changeUrl(`/client-area${query}`))
  }

  return (
    <LayoutCommon>
      <Helmet>
        <title>{i18n.t('headers.clientAreaPage')}</title>
        <meta
          name="description"
          content={i18n.t('headersDescriptions.clientAreaPage')}
        />
        <link
          rel="alternate"
          href="https://internet.am/en/client-area"
          hreflang="en"
        />
        <link
          rel="alternate"
          href="https://internet.am/ru/client-area"
          hreflang="ru"
        />
        <link
          rel="alternate"
          href="https://internet.am/client-area"
          hreflang="am-AM"
        />
        <meta property="og:title" content={i18n.t('headers.clientAreaPage')} />
        <meta
          property="og:description"
          content={i18n.t('headersDescriptions.clientAreaPage')}
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ABC Domain LLC" />
        <meta
          property="og:image"
          content="https://internet.am/images/fb_logo.png"
        />
      </Helmet>
      <S.PageBody>
        <S.ClientAreaBodyContainer>
          <S.MainBlock>
            <S.TopBlock>
              <S.PersonDetails>
                <S.PersonInitials>
                  {decode(auth.fullname)}, ID: {auth.userId}
                </S.PersonInitials>
                {!!+credit && (
                  <S.Address style={{ fontFamily: 'sans-serif' }}>
                    {i18n.t('clientAreaPage.hasCredit')}{' '}
                    {numberWithCommas(+credit, true)}{' '}
                  </S.Address>
                )}
                {isCredit && (
                  <Link to={changeUrl('/credit-recharge')}>
                    {i18n.t('clientAreaPage.creditRecharge')}
                  </Link>
                )}
                <S.AddressBlock>
                  <S.AddressDetails>
                    <S.Address>{decode(auth.address)}</S.Address>
                  </S.AddressDetails>
                  <S.AddressDetails></S.AddressDetails>
                  <S.SettingsIcon to={changeUrl('/edit-profile')} />
                </S.AddressBlock>
              </S.PersonDetails>
              <S.TopImage />
            </S.TopBlock>
            <Formik
              initialValues={initialValues}
              validationSchema={yupScheme}
              onSubmit={(values, { setSubmitting }) => {
                handleSearch(values.domain)
                setSubmitting(false)
              }}
            >
              {({
                touched,
                errors,
                values,
                isSubmitting,
                handleChange,
                resetForm,
              }) => (
                <S.StyledFormContainer>
                  <S.Wrapper>
                    <S.SearchInput
                      placeholder={i18n.t('clientAreaPage.placeholder')}
                      name="domain"
                      value={values.domain}
                      onChange={handleChange}
                      haserror={touched.domain && errors.domain}
                    />
                    {touched.domain && errors.domain && (
                      <S.ErrorIconContainer className="tooltipContainer">
                        <S.ErrorIcon />
                        <Tooltip className="tooltip top right noArrow error">
                          {i18n.t(errors.domain)}
                        </Tooltip>
                      </S.ErrorIconContainer>
                    )}
                  </S.Wrapper>
                  <S.TopButtonsContainer>
                    <S.RegButton type="submit" disabled={isSubmitting}>
                      {i18n.t('clientAreaPage.regButton')}
                    </S.RegButton>
                    <S.TransferButton
                      type="button"
                      onClick={() =>
                        showModal(
                          TransferDomainModal,
                          {
                            largeModal: true,
                            initialValue: values.domain,
                          },
                          resetForm({})
                        )
                      }
                    >
                      {i18n.t('clientAreaPage.transferButton')}
                    </S.TransferButton>
                  </S.TopButtonsContainer>
                </S.StyledFormContainer>
              )}
            </Formik>

            <S.ContainerOfServicesCarts>
              {clientServices.map((service) => (
                <S.ServiceCart key={service.id} isActive={tab === service.tab}>
                  <S.ServiceCartDiv
                    onClick={() => {
                      switchTab(service.tab)
                    }}
                    isActive={tab === service.tab}
                  >
                    <S.ServiceCartImage>
                      <service.Image
                        fill={tab === service.tab ? '#fff' : '#00C084'}
                      />
                    </S.ServiceCartImage>
                    <S.ServiceCartCounter>
                      {loading[service.id] ? (
                        <Loader
                          color={tab === service.tab ? '#fff' : '#00C084'}
                          size={0.5}
                        />
                      ) : (
                        totals[service.id]
                      )}
                    </S.ServiceCartCounter>
                    <S.ServiceCartDescription>
                      {i18n.t(service.description)}
                    </S.ServiceCartDescription>
                  </S.ServiceCartDiv>
                  {service.path && (
                    <S.ServiceButton to={changeUrl(service.path)}>
                      +
                    </S.ServiceButton>
                  )}
                </S.ServiceCart>
              ))}
              {tab === CLIENT_AREA_TABS.DOMAINS && <DomainsTable />}
              {tab === CLIENT_AREA_TABS.SERVICES && <ServicesTable />}
              {tab === CLIENT_AREA_TABS.TICKETS && <TicketsTable />}
              {tab === CLIENT_AREA_TABS.INVOICES && <InvoicesTable />}
            </S.ContainerOfServicesCarts>
          </S.MainBlock>
        </S.ClientAreaBodyContainer>
      </S.PageBody>
    </LayoutCommon>
  )
}

export { ClientAreaPage }
