import styled from "styled-components";
import { Link } from "react-router-dom";
import { Form, Field } from "formik";

import Container from "../../components/Container/Container";
import settingsButton from "../../images/settingsButton.svg";
import clientAreaImage from "../../images/clientAreaImg.svg";
import errorIcon from "../../images/error-icon.svg";

export const PageBody = styled.div`
  background-color: #f8f8f8;
  width: 100%;
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 45px;
  @media (min-width: 768px) {
    padding-bottom: 60px;
  }
  @media (min-width: 1280px) {
    background-color: #ffffff;
    padding-top: 55px;
    padding-bottom: 65px;
  }
`;

export const ClientAreaBodyContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  overflow-x: hidden;
`;

export const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
  width: 90%;
  @media (min-width: 768px) {
    padding: 0px 70px;
  }
  @media (min-width: 1280px) {
    padding: 0px 0px;
  }
`;

export const TopBlock = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const PersonDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @media (min-width: 768px) {
    width: 45%;
    align-items: baseline;
    padding-top: 35px;
  }
  @media (min-width: 1280px) {
    padding-top: 60px;
    width: 60%;
  }
`;

export const PersonInitials = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #484857;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

export const TopImage = styled.div`
  @media (min-width: 768px) {
    height: 260px;
    width: 295.11px;
    background-image: url(${clientAreaImage});
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
  }
  @media (min-width: 768px) {
    height: 350px;
    width: 397.26px;
  }
`;

export const AddressBlock = styled.div`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 30px;
  width: 100%;
  position: relative;
  @media (min-width: 1280px) {
    width: 60%;
  }
`;
export const AddressDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 50px 4px 13px;
  @media (min-width: 768px) {
    padding: 24px 50px 4px 32px;
  }
`;

export const Address = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #484857;
  padding-bottom: 18px;
  @media (min-width: 768px) {
    font-size: 24px;
    padding-bottom: 24px;
  }
`;

export const SettingsIcon = styled(Link)`
  height: 17.45px;
  width: 17.45px;
  background-image: url(${settingsButton});
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  position: absolute;
  top: 14px;
  right: 8px;
`;

export const StyledFormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    padding-top: 60px;
  }
  @media (min-width: 1280px) {
    width: 65%;
    align-self: flex-start;
    padding-top: 0px;
    margin-top: 40px;
  }
`;
export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  @media (min-width: 768px) {
    width: 55%;
  }
`;
export const SearchInput = styled(Field)`
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  width: 100%;
  height: 34px;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  color: #212121;
  padding: 9px 13px 9px 13px;
  box-sizing: border-box;
  ::placeholder {
    color: #cfcfcf;
  }

  :-ms-input-placeholder {
    color: #212121;
  }

  ::-ms-input-placeholder {
    color: #212121;
  }

  @media (min-width: 768px) {
    font-size: 18px;
    height: 38px;
  }
  &:focus {
    border: 1px solid #242e46;
  }
  ${(p) => p.haserror && "border: 1px solid #DD2326;"};
`;
export const ErrorIconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  height: 30px;
  width: 30px;
  box-sizing: border-box;
`;

export const ErrorIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 18px;
  width: 18px;
  background-image: url(${errorIcon});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const TopButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;
  justify-content: space-between;
  @media (min-width: 768px) {
    margin-top: 0px;
    width: 40%;
    margin-left: 14px;
  }
`;

export const RegButton = styled.button`
  background: #00c084;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  width: 48%;
  height: 30px;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    font-size: 18px;
    width: 50%;
    margin-right: 7px;
    height: 38px;
  }
`;

export const TransferButton = styled.button`
  background: #242e46;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  width: 48%;
  height: 30px;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    font-size: 18px;
    width: 50%;
    margin-left: 7px;
    height: 38px;
  }
`;

export const ContainerOfServicesCarts = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 35px;
  @media (min-width: 768px) {
    padding-top: 60px;
  }
  @media (min-width: 1280px) {
    padding-top: 90px;
    padding-bottom: 0px;
  }
`;

export const ServiceCartDiv = styled.div`
  background: #ffffff;
  border: 1px solid #00c084;
  box-sizing: border-box;
  border-radius: 5px;
  height: 160px;
  &:hover {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border: 1px solid ${(p) => (p.isActive ? "#00c084" : "#fff")};
  }
  @media (min-width: 1280px) {
    height: 208px;
  }
`;

export const ServiceCartImage = styled.div`
  svg {
    width: 26px;
    height: 26px;
  }
  position: absolute;
  top: 10px;
  right: 10px;
  @media (min-width: 1280px) {
    svg {
      width: 36px;
      height: 36px;
    }
  }
`;

export const ServiceCartCounter = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  text-align: center;
  color: #484857;
  margin-top: 30px;
  color: #484857;
  @media (min-width: 1280px) {
    margin-top: 42px;
    font-size: 64px;
  }
`;

export const ServiceCartDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #484857;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 1280px) {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 38px;
  }
`;

export const ServiceButton = styled(Link)`
  margin-top: 5px;
  background: #ffffff;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  text-decoration: none;
  width: 100%;
  height: 28px;
  color: #00c084;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`;

export const ServiceCart = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  position: relative;
  margin: 0px 0px 30px 0px;
  cursor: pointer;
  &:hover {
    ${ServiceButton} {
      visibility: visible;
      justify-content: center;
      background: ${(p) => (p.isActive ? "#00C084" : "#FFFFFF")};
      color: ${(p) => (p.isActive ? "#FFFFFF" : "#00C084")};
    }
  }
  ${ServiceButton} {
    visibility: ${(p) => (p.isActive ? "visible" : "hidden")};
    background: ${(p) => (p.isActive ? "#00C084" : "#FFFFFF")};
    color: ${(p) => (p.isActive ? "#FFFFFF" : "#00C084")};
  }
  > div,
  ${ServiceCartCounter}, ${ServiceCartDescription} {
    background: ${(p) => (p.isActive ? "#00C084" : "#FFFFFF")};
    color: ${(p) => (p.isActive ? "#FFFFFF" : "#00C084")};
  }
  @media (min-width: 768px) {
    width: 23%;
    margin: 0px 0px 30px 0px;
  }
`;
