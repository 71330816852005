import { PRODUCT_DETAILS_TYPES } from './ProductDetailsPage.types';

const initialState = {
  productDomain: '',
};

function productDetailsPage(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_DETAILS_TYPES.SET_PRODUCT_DOMAIN:
      return {
        ...state,
        productDomain: action.value,
      };
    default:
      return state;
  }
}

export { productDetailsPage };
