import * as Yup from 'yup';

const configIP = [
  { id: 'ip1', name: 'glueRecordsModal.n1', placeholder: 'xxx.xxx.xxx.xxx' },
  { id: 'ip2', name: 'glueRecordsModal.n2', placeholder: 'xxx.xxx.xxx.xxx' },
  { id: 'ip3', name: 'glueRecordsModal.n3', placeholder: 'xxx.xxx.xxx.xxx' },
  { id: 'ip4', name: 'glueRecordsModal.n4', placeholder: 'xxx.xxx.xxx.xxx' },
  { id: 'ip5', name: 'glueRecordsModal.n5', placeholder: 'xxx.xxx.xxx.xxx' },
];

const configNS = [
  { id: 'ns1', name: 'nameServerModal.n1', placeholder: 'ns1.internet.am' },
  { id: 'ns2', name: 'nameServerModal.n2', placeholder: 'ns2.internet.am' },
  { id: 'ns3', name: 'nameServerModal.n3', placeholder: 'ns3.internet.am' },
  { id: 'ns4', name: 'nameServerModal.n4', placeholder: 'ns4.internet.am' },
  { id: 'ns5', name: 'nameServerModal.n5', placeholder: 'ns5.internet.am' },
];

const defaultNameservers = (nameservers) => {
  if (!nameservers) {
    return {
      ns1: '',
      ns2: '',
      ns3: '',
      ns4: '',
      ns5: '',
      ip1: '',
      ip2: '',
      ip3: '',
      ip4: '',
      ip5: '',
    };
  }
  const formattedNameservers = {};
  Object.keys(nameservers).forEach((key) => {
    if (key.startsWith('glue_')) {
      formattedNameservers[key.split('glue_')[1]] = nameservers[key];
    } else {
      formattedNameservers[key] = nameservers[key];
    }
  });
  return {
    ns1: '',
    ns2: '',
    ns3: '',
    ns4: '',
    ns5: '',
    ip1: '',
    ip2: '',
    ip3: '',
    ip4: '',
    ip5: '',
    ...formattedNameservers,
  };
};

const yupScheme = Yup.object().shape({
  ip1: Yup.string().matches(
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    'validationFields.invalidTyping'
  ),
  ip2: Yup.string().matches(
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    'validationFields.invalidTyping'
  ),
  ip3: Yup.string().matches(
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    'validationFields.invalidTyping'
  ),
  ip4: Yup.string().matches(
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    'validationFields.invalidTyping'
  ),
  ip5: Yup.string().matches(
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    'validationFields.invalidTyping'
  ),
  ns1: Yup.string()
    .required('validationFields.ns1Required')
    .matches(
      /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      'validationFields.ns1Url'
    ),
  ns2: Yup.string()
    .required('validationFields.ns2Required')
    .matches(
      /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      'validationFields.ns2Url'
    ),
  ns3: Yup.string()
    .nullable()
    .matches(
      /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      'validationFields.ns3Url'
    )
    .when('ip3', {
      is: (ns3) => !!ns3,
      then: Yup.string().required('validationFields.required'),
    }),
  ns4: Yup.string()
    .nullable()
    .matches(
      /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      'validationFields.ns4Url'
    )
    .when('ip4', {
      is: (ns4) => !!ns4,
      then: Yup.string().required('validationFields.required'),
    }),
  ns5: Yup.string()
    .nullable()
    .matches(
      /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      'validationFields.ns5Url'
    )
    .when('ip5', {
      is: (ns5) => !!ns5,
      then: Yup.string().required('validationFields.required'),
    }),
});

const ifNotEmptyGlues = (glues) => {
  if (!glues) {
    return false;
  }
  if (Object.values(glues).find((item) => !!item)) {
    return glues;
  } else {
    return false;
  }
};

export { configIP, configNS, defaultNameservers, yupScheme, ifNotEmptyGlues };
