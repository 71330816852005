import styled from "styled-components";
import sslHeader from "../../images/ssl-header.png";
import sslHeader_md from "../../images/ssl-header-md.png";
import sslImg from "../../images/ssl-header-img.png";
import sslImg_md from "../../images/ssl-header-img-md.png";
import packman from "../../images/packman.png";
import packman_md from "../../images/packman-md.png";
import packman_lg from "../../images/packman-lg.png";
import { Link } from "react-router-dom";

export const PageBody = styled.div`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  padding: 0px 20px 50px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  @media (min-width: 768px) {
    background-image: url(${sslHeader_md});
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
  }
  @media (min-width: 1280px) {
    margin-top: -77px;
  }
`;

export const Header = styled.div`
  background-image: url(${sslHeader});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 450px;
  width: 100%;
  @media (min-width: 768px) {
    background-image: none;
    height: 650px;
    padding-bottom: 50px;
  }
  @media (min-width: 1280px) {
    height: 720px;
    padding-bottom: 0px;
    margin-top: 20px;
  }
`;

export const HeaderImg = styled.div`
  background-image: url(${sslImg});
  background-repeat: no-repeat;
  background-size: cover;
  width: 194px;
  height: 126px;
  margin-bottom: 35px;
  margin-top: 97px;

  @media (min-width: 768px) {
    background-image: url(${sslImg_md});
    background-repeat: no-repeat;
    background-size: cover;
    width: 449px;
    height: 291px;
    margin-bottom: 70px;
    margin-top: 97px;
  }
  @media (min-width: 1280px) {
    background-image: url(${sslImg_md});
    width: 662px;
    height: 430px;
    margin-bottom: 25px;
    margin-top: 77px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const SslContainerTitle = styled.h1`
  text-align: center;
  color: #484857;
  font-size: 24px;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 36px;
  }
`;

export const SslCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    min-width: 750px;
    max-width: 840px;
    padding-top: 50px;
  }
  @media (min-width: 1280px) {
    ${'' /* justify-content: flex-start; */}
    min-width: 870px;
    max-width: 1280px;
  }
`;

export const SslCategoryContainer = styled.div`
  display: flex;
  width: 300px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 29px 0px 37px 0px;

  @media (min-width: 768px) {
    min-width: 750px;
    max-width: 820px;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 40px;
  }
  @media (min-width: 1280px) {
    min-width: 870px;
    max-width: 1020px;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;

export const SslCategory = styled.div`
  font-size: 14px;
  text-decoration: none;
  outline: none;
  border: 0;
  color: #484857;
  cursor: pointer;
  padding-right: 4px;
  padding-left: 4px;
  border-bottom: ${(p) => (p.isActive ? "2px solid #c3c5cb;" : "none")};
  padding-bottom: 6px;

  &:nth-child(2) {
    padding-top: 21px;
    order: 3;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    font-size: 18px;
    &:nth-child(2) {
      padding-top: 0px;
      margin: 0;
      order: unset;
    }
    padding-bottom: ${(p) => (p.isActive ? "8px" : "0px")};
  }
`;

export const UpperBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0px;
  min-width: 350px;
  @media (min-width: 768px) {
    min-width: 750px;
    max-width: 900px;
  }
  @media (min-width: 1280px) {
    width: 100%;
    margin-top: 70px;
    max-width: 1280px;
  }
`;

export const Packman = styled.div`
  background-image: url(${packman});
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 152px;
  height: 92.89px;
  margin-right: 0px;
  @media (min-width: 768px) {
    background-image: url(${packman_md});
    width: 393px;
    height: 240.17px;
    margin-right: 0px;
    margin-left: -35px;
  }
  @media (min-width: 1280px) {
    background-image: url(${packman_lg});
    width: 630px;
    height: 385px;
    margin-right: 0px;
    margin-left: 0px;
  }
`;
export const FeedbackBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  max-width: 170px;
  @media (min-width: 768px) {
    margin-left: 15px;
    margin-right: 30px;
    max-width: 320px;
  }
  @media (min-width: 1280px) {
    margin-left: 50px;
    margin-right: 65px;
    max-width: 500px;
  }
`;

export const Questions = styled.div`
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #484857;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    font-size: 36px;
    margin-bottom: 30px;
  }
  @media (min-width: 1280px) {
    font-size: 48px;
    margin-bottom: 20px;
  }
`;

export const WriteUsLink = styled(Link)`
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  font-weight: 300;
  color: #484857;
  width: 140px;
  @media (min-width: 768px) {
    font-size: 24px;
    width: unset;
  }
  @media (min-width: 1280px) {
    font-size: 36px;
  }
`;
export const BorderLine = styled.div`
  width: 120%;
  height: 3.16px;
  background: #161251;
  @media (min-width: 1280px) {
    height: 7px;
  }
`;
