import styled from 'styled-components';

import BuyHostingWithSale from '../../../../images/buyHostingWithSale.svg';
import BuyHostingWithSaleMobile from '../../../../images/buyHostingWithSaleMobile.svg';

export const SaleCardsContainerTitle = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  color: #242E46;
     @media (min-width: 768px) {
    font-size: 32px;
  }
  @media (min-width: 1280px) {
    font-size: 48px;
  }
`;
export const TldCardBackground = styled.div`
  background: white;
  border-radius: 20px;
`;

export const SaleCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  background: #fff;
  position: relative;
  min-width: 300px;
  height: 300px;
  background-size: contain;
  background-position: center;
  padding: 50px 205px 150px 205px;
  justify-content: space-around;
  margin-top: 80px;
`;

export const BackgroundImage = styled.div`
  background: url(${BuyHostingWithSaleMobile});
  width: 100%;
  height: 600px;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;

  @media (min-width: 768px) {
    width: 1380px;
    height: 885px;
    position: absolute;
    background-size: cover;
    background: url(${BuyHostingWithSale});
  }
  @media (min-width: 1280px) {
    background: url(${BuyHostingWithSale});
    width: 1838px;
    height: 970px;
    position: absolute;
    background-size: cover;
  }
`;

export const PriceCardsContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  z-index: 3;
  padding: 0px 5px;

  max-width: 280px;

  @media (min-width: 768px) {
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
    max-width: unset;
  }

  @media (min-width: 1280px) {
    justify-content: space-around;
    gap: 50px;
    margin-top: 80px;
  }
`;

export const PriceCard = styled.div`
  padding: 20px 45px 34px 30px;
  width: 145px;
  border-radius: 20px;
  background: ${({ background }) => background};
  backdrop-filter: blur(20px);
`;

export const TLDName = styled.div`
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 78px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
`;

export const TLDPrice = styled.div`
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  // line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 30px;
  color: #ffffff;
  // padding: 128px 53px 50px 30px;

  span {
    font-size: 14px;
  }
`;

export const TLDOldPrice = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-decoration: line-through;
  color: #ffffff;
  padding-left: 5px;
`;

export const BuyHostingContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  
  @media(min-width: 768px){
    margin-top: 100px;
  }
  @media(min-width: 1280px){
    margin-top: 130px;
  }
`;
