import React, { useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import slugify from 'react-slugify';

import * as S from './ResetPasswordModal.styles';
import { i18n } from '../../../i18n';
import { Loader } from '../../Loader';
import { initialValues, yupScheme } from './ResetPasswordModal.config';
import { ResetPasswordSuccessModal } from '../ResetPasswordSuccessModal';
import { useSearchParams } from '../../../hooks/useSearchParams'


export const ResetPasswordModal = ({
  hideModal,
  resetPassword,
  loading,
  error,
  showModal,
  clearError,
  history
}) => {

  const [searchParams, updateSearchParams] = useSearchParams({ history })

  const handleEnter = useCallback((e, submitForm) => {
    if (e.key === 'Enter') {
      submitForm();
    }
  }, []);

  useEffect(() => {
    updateSearchParams({ ...searchParams, forgot: 1 })
    return () => {
      const newParams = { ...searchParams }
      delete newParams.forgot
      updateSearchParams(newParams)
    }
  }, [])

  return (
    <S.Modal onClick={(e) => e.stopPropagation()}>
      <S.ResetPasswordModalContainer>
        <S.Title>{i18n.t('resetPasswordModal.title')}</S.Title>
        <S.SubHeader>{i18n.t('resetPasswordModal.subTitle')}</S.SubHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={yupScheme}
          onSubmit={(values) => {
            resetPassword(values, () => {
              showModal(ResetPasswordSuccessModal);
            });
          }}
        >
          {({ touched, errors, values, handleChange, submitForm }) => (
            <S.StyledForm onKeyPress={() => handleEnter(submitForm)}>
              <S.FormItem type="email">
                <S.Label>{i18n.t('resetPasswordModal.mail')}</S.Label>
                <S.Input
                  placeholder={i18n.t('resetPasswordModal.placeholder')}
                  name="email"
                  value={values.email}
                  onChange={(e) => {
                    !!error && clearError();
                    handleChange(e);
                  }}
                />
                {errors.email && touched.email && (
                  <S.ErrorMessage>{i18n.t(errors.email)}</S.ErrorMessage>
                )}
                {error && !errors.email && (
                  <S.ErrorMessage>
                    {i18n.t(`backend.forgotPassword.${slugify(error)}`)}
                  </S.ErrorMessage>
                )}
              </S.FormItem>
              <S.LoginButton type="submit">
                {loading ? (
                  <Loader color="#FFF" size={0.5} />
                ) : (
                  i18n.t('resetPasswordModal.send')
                )}
              </S.LoginButton>
            </S.StyledForm>
          )}
        </Formik>
        <S.CloseModalButton onClick={() => hideModal()} />
      </S.ResetPasswordModalContainer>
    </S.Modal>
  );
};
