import React from 'react'
import { PunycodeConverterModal } from '../Modals/PunycodeConverterModal'
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa'
import { ContactOwnerModal } from '../Modals/ContactOwnerModal'
import { changeUrl } from '../../hooks/changeUrl'
import { i18n } from '../../i18n'
import telcellimg from '../../images/Telcell_Logo.png'
import idramDarkLogo from '../../images/Idram_logo_dark.png'
import visaLogo from '../../images/Visa.png'
import arcaLogo from '../../images/ArCa.png'
import mastercardLogo from '../../images/Mastercard.png'

import * as S from './Footer.styles'

const Footer = ({
  history,
  showModal,
  dimensions,
  toggleLanguagesPopup,
  punycodeRef,
  contactRef,
}) => {
  const charter = i18n.t('footer.charter')
  const charterLink = i18n.t('footer.icannLink')

  return (
    <S.FooterContainer>
      <S.ColumnsContainer>
        <S.FooterColumn>
          <S.FooterTitle>{i18n.t('footer.main')}</S.FooterTitle>
          <S.FooterLink to={changeUrl('/about-us')}>
            {i18n.t('footer.aboutUs')}
          </S.FooterLink>
          <S.FooterLinkAnchor
            href="/docs/ABCDomain_Terms_and_conditions.pdf"
            download
          >
            {i18n.t('footer.generalTerms')}
          </S.FooterLinkAnchor>
          <S.FooterLink to={changeUrl('/special-terms')}>
            {i18n.t('footer.specialTerms')}
          </S.FooterLink>
        </S.FooterColumn>
        <S.FooterColumn>
          <S.FooterTitle>{i18n.t('footer.useful')}</S.FooterTitle>
          <S.FooterButton
            onClick={() =>
              showModal(PunycodeConverterModal, {
                history,
              })
            }
          >
            {i18n.t('footer.punycodeConverter')}
          </S.FooterButton>
          <S.FooterButton
            onClick={() =>
              showModal(ContactOwnerModal, { largeModal: true, history })
            }
          >
            {i18n.t('footer.contactDomainOwner')}
          </S.FooterButton>
          <S.FooterLinkCharter
            href={charterLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {charter}
          </S.FooterLinkCharter>
        </S.FooterColumn>
        <S.FooterColumn>
          <S.FooterTitle>{i18n.t('footer.contactUs')}</S.FooterTitle>
          <S.FooterLink to={changeUrl('/open-ticket')}>
            {i18n.t('footer.openTicket')}
          </S.FooterLink>

          {/* {process.env.REACT_APP_ENV === "dev" && (
          <S.Version to="/">
            Version: {process?.env.REACT_APP_VERSION}
          </S.Version>
        )} */}

          <S.Mail>
            <FaEnvelope />
            info@internet.am
          </S.Mail>
          <S.Phone>
            <FaPhoneAlt />
            <S.Number href="tel:+37460270027">+37460270027</S.Number>
          </S.Phone>
        </S.FooterColumn>
        <S.PaymentOptionsContainer>
          <S.PaymentOptionsImg
            src={telcellimg}
            alt="payment method telcell icon"
          />
          <S.PaymentOptionsImg
            src={idramDarkLogo}
            alt="payment method idram icon"
          />
          <S.PaymentOptionsImg src={arcaLogo} alt="payment method arca icon" />
          <S.PaymentOptionsImg src={visaLogo} alt="payment method visa icon" />
          <S.PaymentOptionsImg
            src={mastercardLogo}
            alt="payment method master card icon"
          />
        </S.PaymentOptionsContainer>
      </S.ColumnsContainer>
    </S.FooterContainer>
  )
}

export { Footer }
