import styled from "styled-components";

export const PaymentFailedPageContainer = styled.div`
  height: 450px;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (min-width: 768px) {
    height: 800px;
  }
`;

export const FailImageContainer = styled.img`
  width: 200px;
  @media (min-width: 768px) {
    width: 598px;
    height: 368px;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 50px;
`;

export const InvoiceId = styled.div`
  font-size: 22px;
`;

export const ErrorMessage = styled.div`
  font-size: 22px;
`;
