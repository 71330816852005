import styled from 'styled-components'
import { Field } from 'formik'

import * as fontWeight from '../../assets/styles/constants/fontWeight'
import Select from 'react-select'

export const PageTitle = styled.h1`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 24px;
  color: #484857;
`

export const RegistrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 40px 20px;

  @media (min-width: 768px) {
    padding: 130px 230px;
  }

  @media (min-width: 1280px) {
    padding: 70px 230px 130px 230px;
  }
`

export const Text = styled.div`
  line-height: 14px;
  font-size: 12px;
  color: #484857;

  @media (min-width: 768px) {
    margin-top: 14px;
    font-size: 14px;
  }

  &.terms {
    width: 922px;
    text-align: unset;
    line-height: 19px;
    font-size: 12px;
    width: 100%;

    @media (min-width: 768px) {
      width: 643px;
    }

    @media (min-width: 1280px) {
      font-size: 16px;
      width: 922px;
    }
  }

  > span {
    font-weight: bold;
  }
`

export const InformationText = styled(Text)`
  width: 100%;
  text-align: center;
  font-size: 12px;
  margin-top: 26px;

  @media (min-width: 768px) {
    width: 690px;
    font-size: 14px;
    line-height: 16px;
  }

  @media (min-width: 1280px) {
    width: 798px;
    margin-top: 29px;
  }
`

export const ReadMore = styled.a`
  width: 100%;
  text-align: center;
  font-size: 12px;
  margin-top: 26px;

  @media (min-width: 768px) {
    width: 690px;
    font-size: 14px;
    line-height: 16px;
  }

  @media (min-width: 1280px) {
    width: 798px;
    margin-top: 29px;
  }
`
export const Button = styled.button`
  @media (max-width: 325px) {
    margin: 0 8px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 44px;
  background-color: ${(p) => (p.isChecked ? '#dc773c' : '#fff')};
  color: ${(p) => (p.isChecked ? 'white' : 'black')};
  border: ${(p) => (p.isChecked ? 'none' : '1px solid #d4d4d4')};
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
  margin: 0px 20px;
  &:hover {
    border-color: ${(p) => (p.isChecked ? 'none' : '#DC773C')};
  }
`
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;

  @media (min-width: 1280px) {
    margin-top: 0px;
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  @media (min-width: 768px) {
    padding: 0;
  }

  @media (min-width: 1280px) {
    margin-top: 50px;
  }
`

export const FormSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid rgba(212, 212, 212, 0.4);
  padding-bottom: 28px;

  @media (min-width: 768px) {
    border: none;
    padding-bottom: 0px;
  }

  &:last-child {
    border: none;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
    border-bottom: 1px solid #d4d4d4;
    flex-wrap: wrap;
    width: 970px;
    justify-content: space-between;
    padding-bottom: 32px;
  }
`

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  width: 100%;

  &.radio {
    justify-content: center;
  }

  &.phoneInput {
    .react-tel-input{
      >input {
        width: calc(100% - 41px);
        z-index: 5;
        float: right;
      }
      .flag-dropdown{
        width: 100%;
        border-radius: 5px;
      }
      .country-list {
        width: 100%;
      }
      .form-control {
        padding-left: 10px;
        border-radius: 0 5px 5px 0px;
      }
    }
  }
}


  @media (min-width: 768px) {
    &.phoneInput {
      .react-tel-input {
        input {
          height: 41px;
        }
      }
    }
    margin-top: 47px;
    width: 411px;
  }
}

`

export const Label = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 17px;

  &.radio {
    margin: 0px;
    margin-left: 7px;
    opacity: 0.38;
  }

  @media (min-width: 768px) {
    font-size: 18px;
  }
`

export const Input = styled(Field)`
  width: auto;
  padding: 12px;
  max-height: 44px;
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;

  @media (min-width: 768px) {
    width: 385px;
  }

  &.radio {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    -webkit-appearance: none;
    border: 1px solid #d4d4d4;
    padding: 0px;
    cursor: pointer;

    &:checked {
      border-color: #dc773c;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      margin: 20% auto;
      border-radius: 50%;
      left: 4px;
      top: 0px;
      background: #d4d4d4;
    }

    &:checked:before {
      background: #dc773c;
    }
  }
`

export const RadioButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (min-width: 768px) {
    width: 425px;
  }

  @media (min-width: 1280px) {
    margin-top: 38px;
    justify-content: unset;
  }
`

export const Radio = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 33px;

  &:last-child {
    margin: 0px;
  }
`

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-right: auto;
  ${(p) => p.spaced && 'margin-top: 20px;'};
`

export const TermsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  flex-direction: column;
  @media (min-width: 768px) {
    align-items: center;
    margin-top: 37px;
  }

  @media (min-width: 1280px) {
    margin-top: 63px;
  }
`

export const Term = styled.div`
  display: flex;
  align-items: center;
  ${'' /* flex-direction: column; */}
`

export const Checkbox = styled.input`
  outline: none;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  margin-top: -18px;

  @media (min-width: 768px) {
    margin-right: 23px;
    margin-top: 7px;
  }

  &:checked {
    background: #00c084;
  }
`

export const CheckboxContainer = styled.div`
  display: block;
  position: relative;
  cursor: initial;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 30px;

  @media (min-width: 768px) {
    margin-right: 23px;
    margin-top: 7px;
    width: unset;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: white;
    border-radius: 3px;
    cursor: pointer;
  }

  input:checked ~ span {
    background: #00c084;
  }

  span::after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    left: 6px;
    top: 2px;
    width: 4px;
    height: 8px;

    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

    @media (min-width: 768px) {
      left: 6px;
      top: 2px;
      width: 4px;
      height: 8px;
    }
  }
`

export const RegistrationButton = styled.button`
  width: 129px;
  height: 38px;
  border-radius: 5px;
  background: #00c084;
  margin-top: 52px;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  border: none;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`

export const StyledSelect = styled(Select)`
  width: 100%;
  @media (min-width: 768px) {
    width: 409px;
  }
`
