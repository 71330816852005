import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import Cookies from 'js-cookie'

import { configureStore } from './store'
import Routes from './routes'
import { hot } from 'react-hot-loader/root'
import { Modal } from './common/Modal'
import {
  setWindowDimensions,
  setCart,
  setAuthData,
  getUserInfo,
} from './common/App/App.actions'
import {
  getContacts,
  getDomains,
} from './components/Modals/LoginModal/LoginModal.actions'

const preloadedState = window.__PRELOADED_STATE__
const store = configureStore(preloadedState)

delete window.__PRELOADED_STATE__

store.dispatch(
  setWindowDimensions({
    width: window.innerWidth,
    height: window.innerHeight,
  })
)

const token = Cookies.get('token')
const email = Cookies.get('email')
const fullname = Cookies.get('fullname')
const userId = Cookies.get('userId')
const address = Cookies.get('address')
const currency = Cookies.get('currency')
const creditRecharge = Cookies.get('creditRecharge') || 0
const legalStatus = Cookies.get('legalStatus')
const disableCode = Cookies.get('disableCode')

const cartRaw = localStorage.getItem('cart')
if (cartRaw) {
  const cart = JSON.parse(cartRaw)
  store.dispatch(setCart(cart))
}

if (
  token &&
  email &&
  fullname &&
  userId &&
  address &&
  currency &&
  legalStatus
) {
  store.dispatch(
    setAuthData({
      email,
      token,
      fullname,
      userId,
      address,
      currency: +currency,
      creditRecharge: +creditRecharge,
      legalStatus,
      disableCode,
    })
  )

  store.dispatch(getContacts())
  store.dispatch(getDomains())
  store.dispatch(getUserInfo())
}

window.addEventListener(
  'resize',
  debounce(() => {
    store.dispatch(
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    )
  }, 200)
)

window.addEventListener('input', (evt) => {
  if (evt.target.type !== 'file') {
    evt.target.value = evt.target.value.replace(/\u0588|\u2024/g, '.')
  }
})

class App extends Component {
  sendDetailsToSlack = (action) => {
    fetch(
      'https://hooks.slack.com/services/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',
      {
        method: 'POST',
        body: JSON.stringify({
          text: `${Cookies.get('userId')} (${Cookies.get(
            'fullname'
          )}) user has ${action} the chat.`,
        }),
      }
    )
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Routes />
          <Modal />
        </BrowserRouter>
      </Provider>
    )
  }
}

export default withTranslation()(hot(App))
