import styled from 'styled-components';
import { Form } from 'formik';
import Select from 'react-select';

import * as fontWeight from '../../assets/styles/constants/fontWeight';
import Container from '../../components/Container/Container';

export const PageBody = styled.div`
  background-color: #f8f8f8;
  width: 100%;
  display: flex;
  padding-top: 75px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 75px;
`;

export const DomainBodyContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const DomainTitle = styled.div`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #484857;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const DomainHeader = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #737373;
  margin-top: 15px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 21px;
    max-width: 650px;
    margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const SubHeader = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #00c084;
  margin-top: 25px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 21px;
    max-width: 650px;
    margin-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const FormContainer = styled(Form)`
  display: flex;
  justify-content: center;
  margin-top: 28px;
  width: 100%;
  @media (min-width: 768px) {
    margin-top: 46px;
    width: unset;
  }
`;

export const DomainInput = styled(Select)`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 3px;
  width: 76%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 24px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #212121;
  padding: 9px 19px 10px 19px;
  @media (min-width: 768px) {
    border-radius: 5px;
    width: 581px;
    font-size: 18px;
    line-height: 21px;
    padding: 8px 0px 9px 18px;
    margin-left: 0px;
  }
  .react-select__control,
  .react-select__control--is-focused {
    border: none;
    box-shadow: none;
  }
  .react-select__menu {
    left: 0;
  }
`;

export const DomainSearcherButton = styled.button`
  background: #00c084;
  border-radius: 3px;
  margin-left: 8px;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  width: 24%;
  padding: 11px 10px 12px 11px;
  margin-right: 20px;
  border: 0;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    border-radius: 5px;
    margin-left: 14px;
    font-size: 18px;
    line-height: 21px;
    width: 101px;
    margin-right: 0px;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  ${(p) => p.spaced && 'margin-top: 20px;'};
`;
