import styled from 'styled-components';

export const Tooltip = styled.div`
  display: none;
  width: 160px;
  font-size: 12px;
  background-color: #ffb400;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  top: 30px;
  left: calc(50% - 75px - ${(p) => p.cWidth || 0}px);
  z-index: 1;

  &.warning {
    background-color: #ffb400;
  }

  &.error {
    background-color: #dd2326;
  }

  &.top {
    top: -50px;
    &.noArrow {
      top: -35px;
    }
    &:after {
      border-width: 5px 5px 0;
      bottom: -5px;
      top: unset;
    }
  }
  &.right {
    // right: 24px;
    // left: unset;
  }

  &.noArrow {
    &:after {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: #ffb400 transparent;
    border-width: 0 5px 5px;
    top: -5px;
    left: 50%;
    margin-left: -5px;
  }
`;
