import styled from 'styled-components';
import { Form } from 'formik';

import closeModal from '../../../images/closeModal.svg';

export const ViewTicketModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 1;
  z-index: 100;
  border-radius: 5px;
  position: relative;
  @media (min-width: 768px) {
    padding: 23px 42px;
  }
`;

export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-size: cover;

  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`;

export const Modal = styled.div`
  // height: 100%;
  width: 310px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  outline: none;
  z-index: 100;
  @media (min-width: 360px) {
    width: 350px;
  }
  @media (min-height: 500px) {
    max-height: 520px;
    overflow-y: auto;
  }

  @media (min-width: 768px) {
    width: 750px;
    overflow-y: auto;
  }
`;

export const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 24px;
    font-weight: 400;
  }
`;

export const TicketData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const PersonInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(p) => (p.isClient ? 'flex-start' : 'flex-end')};
  font-size: 14px;
  font-weight: normal;
  padding-top: 30px;
  padding-bottom: 10px;
`;
export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 14px;
  font-size: 10px;
  font-weight: normal;
  color: #fff;
  background-color: #48a789;
  margin-right: 5px;
`;

export const Fullname = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-right: 20px;
`;

export const Date = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

export const Message = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: normal;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  border-radius: 5px;
  justify-content: center;
  padding: 10px 5px;
  margin-bottom: 5px;
  white-space: pre-line;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 22px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  @media (min-width: 768px) {
    margin-top: 20px;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Label = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 500;
  align-self: flex-start;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: 5px 12px;
  min-height: ${(p) => (p.isDefault ? '64px' : '30px')};
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  resize: vertical;
  max-height: ${(p) => (p.isDefault ? '64px' : '200px')};
  margin-top: 8px;
  box-sizing: border-box;
  ${(p) => p.isError && 'border: 1px solid #DD2326;'};

  @media (min-width: 768px) {
    padding: 12px;
    font-size: 16px;
    margin-top: 10px;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
  @media (min-width: 768px) {
    margin-left: calc(28% + 10px);
  }
`;

export const ErrorAndCounterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  width: 100%;
`;

export const TextareaError = styled.div`
  color: red;
  font-size: 12px;
  width: 260px;
  @media (min-width: 768px) {
    width: 100%;
  }
`;

export const Counter = styled.div`
  font-size: 12px;
  color: #242e46;
  align-self: flex-end;
  ${(p) => p.isError && 'color: #DD2326;'};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ReplyButton = styled.button`
  background: ${(p) => (p.disabled ? '#888e9b' : '#00c084')};
  color: #ffffff;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  font-size: 12px;
  padding: 9px 30px;
  margin-top: 21px;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    padding: 9px 0px;
    font-size: 14px;
    width: 45%;
  }
`;
export const CloseButton = styled.div`
  background-color: #242e46;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  font-size: 12px;
  padding: 9px 30px;
  margin-top: 21px;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    padding: 9px 0px;
    font-size: 14px;
    width: 45%;
  }
`;

export const Section = styled.section`
  width: 100%;
`;

export const AttachButton = styled.button`
  width: 100%;
  padding: 8px 0px;
  text-decoration: none;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  background: ${(p) => (p.disabled ? '#888e9b' : '#242e46')};
  border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 15px;
  @media (min-width: 768px) {
    width: 129px;
  }
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`;

export const Attachments = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  position: relative;
  height: ${(p) => (p.hidden ? '0px' : '200px')};
  float: left;
`;

export const AttachmentsList = styled.ul`
  list-style: none;
  padding-left: 0;
  width: ${(p) => (p.hidden ? '0px' : p.list ? '100%' : '250px')};
`;

export const AcceptedFiles = styled.li`
  font-size: 18px;
  color: #242e46;
  font-weight: normal;
  padding: 10px 10px 10px 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: rgba(36, 46, 70, 0.08);
  margin-right: 5px;
  border-radius: 5px;
`;

export const PreviewImage = styled.img`
  display: flex;
  width: 200px;
  margin-bottom: 5px;
  clip: rect(0, 200px, 150px, 0);
  height: 150px;
  object-fit: cover;
  top: 10px;
  position: absolute;
  border-radius: 5px;
`;

export const ImageFrame = styled.div`
  border-radius: 5px;
  border: 1px solid #242e46;
  width: 200px;
  height: 150px;
  margin-bottom: 30px;
`;

export const Link = styled.a`
  text-decoration: underline;
  color: #242e46;
  font-size: 14px;
  cursor: pointer;
`;

export const UpperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  bottom: 10px;
  position: absolute;
`;

export const FileName = styled.div`
  color: #242e46;
  font-size: 14px;
`;

export const RemoveButton = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  cursor: pointer;
  background-size: cover;
  margin-right: 20px;
  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`;

export const ErrorLimits = styled.div`
  margin-top: 10px;
`;
export const Type = styled.div`
  font-size: 12px;
  color: #737373;
  font-weight: 400;
`;
export const Limit = styled.div`
  font-size: 12px;
  color: #737373;
  font-weight: 400;
  margin-top: 5px;
`;

export const Error = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

export const AttachmentsReply = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: rgba(36, 46, 70, 0.08);
  border-radius: 5px;
  position: relative;
  height: 40px;
`;

export const AttachmentsListReply = styled.ul`
  list-style: none;
  padding-left: 0;
  width: 250px;
  overflow: hidden;
`;

export const AcceptedFilesReply = styled.li`
  font-size: 18px;
  color: #242e46;
  font-weight: normal;
  padding: 10px 10px 10px 20px;
  width: 85%;
`;

export const PreviewImageReply = styled.img`
  display: flex;
  width: 200px;
  margin-bottom: 5px;
  position: absolute;
  clip: rect(0, 200px, 150px, 0);
  height: 150px;
  object-fit: cover;
  top: 10px;
  border-radius: 5px;
`;

export const ImageName = styled.div`
  font-size: 18px;
  color: #242e46;
  font-weight: normal;
  overflow: hidden;
  width: 200px;
  bottom: 10px;
  position: absolute;
`;

export const RemoveButtonReply = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  cursor: pointer;
  background-size: cover;
  position: absolute;
  top: 10px;
  right: 5px;
  @media (min-width: 321px) {
    right: 10px;
  }
  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
    top: 12px;
  }
`;
