import styled from 'styled-components'

import * as fontWeight from '../../assets/styles/constants/fontWeight'

export const SSlCardContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 122px;
  flex-wrap: wrap;
  // flex-basis: content;
  margin-bottom: 20px;
  width: 300px;
  box-sizing: border-box;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
&:hover {
  margin-bottom: 75px;
  transition: all .2s ease-in-out;
  transform: scale(1.05);
}
  &:hover {  
      >div >div:nth-child(3) {
        display:none;
      }


      > div >div > div:last-child {
        width: 100%;
        top: 35px;
        height: 100px;
        display:flex;
      }
     >div > div > div:first-child{
        color: #fff;
        background-color: #242e46;
        cursor:pointer;
      } 
   }

  @media (min-width: 768px) {
    margin-bottom: 70px;
    width: 360px;
    &:hover {
  margin-bottom: 70px;
}
  }
  @media (min-width: 1280px) {
    width: 380px;
    margin-right: 40px;
    
    } 
    }
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  width: 100%;
  padding: 30px 0;
  @media (min-width: 768px) {
    height: 220px;
  }
  @media (min-width: 1280px) {
    width: 100%;
    // width:300px;
  }
`

export const Title = styled.div`
  font-weight: ${fontWeight.MEDIUM};
  color: #242e46;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: normal;
  text-align: center;
  font-size: 24px;

  height: 42px;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`

export const Image = styled.img`
  max-width: 250px;
  height: 74px;
`

export const Line = styled.div`
  border: 1px solid #242e46;
  width: 53px;
  margin-top: 10px;
  margin-bottom: 30px;
`

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
  color: #393939;
  font-size: 20px;
  font-style: normal;
  height: 50px;
  @media (min-width: 768px) {
    padding: 0px;
    font-size: 20px;
  }
`

export const OrderButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  position: relative;
`

export const OrderButton = styled.div`
  display: none;
  font-size: 18px;
  color: #fff;
  background-color: #242e46;
  justify-content: center;
  align-items: center;
  height: 59px;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  position: absolute;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }

  @media (min-width: 1280px) {
    font-size: 16px;
  }
`
