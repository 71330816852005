const config = [
  {
    id: 1,
    href: '/docs/Co-locationSP.pdf',
    text: 'specialTermsPage.coLocation',
  },
  {
    id: 2,
    href: '/docs/DedicatedSP.pdf',
    text: 'specialTermsPage.dedicatedServer',
  },
  {
    id: 3,
    href: '/docs/MailDeliverySP.pdf',
    text: 'specialTermsPage.mailDelivery',
  },
  { id: 4, href: '/docs/MailServiceSP.pdf', text: 'specialTermsPage.mail' },
  { id: 5, href: '/docs/DNSsP.pdf', text: 'specialTermsPage.dns' },
  { id: 6, href: '/docs/DomainSP.pdf', text: 'specialTermsPage.domains' },
  { id: 7, href: '/docs/HostingSP.pdf', text: 'specialTermsPage.hosting' },
  { id: 8, href: '/docs/SSLsP.pdf', text: 'specialTermsPage.ssl' },
]

export default config
