export const SSL_TYPES = {
  CHANGE_TAB: 'SSL_TYPES.CHANGE_TAB',
  DOMAIN_VALIDATION_REQUEST: 'SSL_TYPES.DOMAIN_VALIDATION_REQUEST',
  DOMAIN_VALIDATION_SUCCESS: 'SSL_TYPES.DOMAIN_VALIDATION_SUCCESS',
  DOMAIN_VALIDATION_FAIL: 'SSL_TYPES.DOMAIN_VALIDATION_FAIL',
  ORGANIZATION_VALIDATION_REQUEST: 'SSL_TYPES.ORGANIZATION_VALIDATION_REQUEST',
  ORGANIZATION_VALIDATION_SUCCESS: 'SSL_TYPES.ORGANIZATION_VALIDATION_SUCCESS',
  ORGANIZATION_VALIDATION_FAIL: 'SSL_TYPES.ORGANIZATION_VALIDATION_FAIL',
  EXTENDED_VALIDATION_REQUEST: 'SSL_TYPES.EXTENDED_VALIDATION_REQUEST',
  EXTENDED_VALIDATION_SUCCESS: 'SSL_TYPES.EXTENDED_VALIDATION_SUCCESS',
  EXTENDED_VALIDATION_FAIL: 'SSL_TYPES.EXTENDED_VALIDATION_FAIL'
};
