import { connect } from 'react-redux';

import { SettingsPopup as Self } from './SettingsPopup';
import {
  showSettingsPopup,
  changeIdProtection,
} from '../../../../ClientAreaPage.actions';
import { showModal } from '../../../../../../common/Modal/Modal.actions';

const mapStateToProps = ({ clientAreaPage }) => ({
  isShown: clientAreaPage.settingsPopupIsShown,
});

const mapDispatchToProps = {
  showSettingsPopup,
  showModal,
  changeIdProtection,
};

export const SettingsPopup = connect(mapStateToProps, mapDispatchToProps)(Self);
