import styled from 'styled-components'
import { Field } from 'formik'
import Select from 'react-select'

export const SslConfiguationPage = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  min-height: 500px;
`

export const StepsContainer = styled.div`
  width: 300px;

  @media (min-width: 768px) {
    width: 740px;
  }
  @media (min-width: 1280px) {
    width: 870px;
  }
`

export const Steps = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
`

export const StepLine = styled.div`
  width: 80px;
  height: 2px;
  background: ${(p) => (p.completed ? '#242e46' : '#ccc')};
  align-items: center;

  @media (min-width: 768px) {
    width: 300px;
  }

  @media (min-width: 1280px) {
    width: 330px;
  }
`

export const StepBox = styled.div`
  width: 30px;
  height: 30px;
  background: ${(p) => (p.completed ? '#242e46' : '#fff')};
  color: ${(p) => (p.completed ? '#fff' : '#242e46')};
  border-radius: 50%;
  border: ${(p) => (p.completed ? '2px solid #242e46' : '2px solid #ccc')};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1280px) {
    width: 50px;
    height: 50px;
  }
`
export const CsrInput = styled.textarea`
  width: 100%;
  height: 120px;
  overflow-y: auto;
  resize: none;
  outline: none;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  margin-top: 5px;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    white-space: pre-wrap;
    position: relative;
    width: 220px;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    white-space: pre-line;
    position: relative;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    white-space: pre-line;
    position: relative;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    white-space: pre-line;
    position: relative;
  }
`

export const GenerateBtn = styled.button`
  width: 100px;
  height: 30px;
  border: 1px solid black;
  border-radius: 3px;
  outline: none;
  margin-top: 10px;
`
export const FormContainer = styled.div`
  width: 300px;
  margin-top: 20px;

  @media (min-width: 768px) {
    width: 740px;
  }
  @media (min-width: 1280px) {
    width: 870px;
  }
`

export const FormItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`

export const Label = styled.label`
  width: 100%;
  align-items: left;
`

export const Input = styled(Field)`
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  max-height: 44px;
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  margin-top: 5px;
`

export const FormHeader = styled.p`
  font-size: 28px;
  font-weight: 700;
`
export const StyledSelect = styled(Select)`
  width: 100%;
  margin-top: 5px;
`

export const Button = styled.button`
  width: 100%;
  outline: none;
  color: #fff;
  pointer-events: ${(p) => (p.isDisabled ? 'none' : 'unset')};
  opacity: ${(p) => (p.isDisabled ? '0.5' : 'unset')};
  background: #242e46;
  border-radius: 3px;
  padding: 12px;
  box-sizing: border-box;
  margin-top: 20px;

  @media (min-width: 768px) {
    width: 200px;
  }
`
export const PhoneInput = styled.div`
  width: 100%;

  .form-control {
    width: 100%;
  }
`
export const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`
export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`
export const ApproverEmailForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Completed = styled.p`
  width: 100%;
  font-size: 20px;
  color: green;
  margin-top: 100px;
`

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
`
export const CompletedMessage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`
export const EmailInfo = styled.p`
  width: 100%;
  font-size: 20px;
`
