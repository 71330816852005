import { i18n } from '../../i18n'

export const format = (price, currency) => {
  const curr =
    currency === 'AMD'
      ? i18n.t('general.currency.dram')
      : i18n.t('general.currency.usd')

  const n = curr === 'AMD' ? parseInt(price) : price
  return `${(Math.round(10 * parseFloat(n)) / 10)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${curr}`
}
