import styled from 'styled-components'
import { Form, Field } from 'formik'

import * as fontWeight from '../../assets/styles/constants/fontWeight'
import Container from '../../components/Container/Container'
import checkmarkFilled from '../../images/checkmark-filled.svg'
import checkmarkEmpty from '../../images/checkmark-empty.svg'
import errorIcon from '../../images/error-icon.svg'
import carrot from '../../images/carrot.svg'

export const PageBody = styled.div`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  padding-top: 75px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
`

export const DomainBodyContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 40px;
`

export const DomainTitle = styled.h1`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #484857;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 29px;
  }
`

export const DomainHeader = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #737373;
  margin-top: 25px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 21px;
    max-width: 650px;
    margin-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }
`

export const FormContainer = styled(Form)`
  width: 100%;
  display: flex;
  margin: 20px auto 0 auto;

  @media (min-width: 768px) {
    width: 700px;
  }

  @media (min-width: 1024px) {
    width: 900px;
  }

  @media (min-width: 1280px) {
    width: 1000px;
  }
`

export const SearchInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    width: 700px;
  }

  @media (min-width: 1024px) {
    width: 900px;
  }

  @media (min-width: 1280px) {
    width: 1000px;
  }
`

export const ErrorIconContainer = styled.div`
  position: absolute;
  right: 0;
  padding: 20px;
  height: 30px;
  width: 30px;
  box-sizing: border-box;
`

export const ErrorIcon = styled.div`
  position: absolute;
  top: 11px;
  right: 10px;
  height: 18px;
  width: 18px;
  background-image: url(${errorIcon});
  background-repeat: no-repeat;
  background-size: cover;
`

export const DomainSearcher = styled(Field)`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #212121;
  padding: 9px 19px 10px 19px;
  @media (min-width: 768px) {
    border-radius: 5px;
    padding: 8px 0px 9px 18px;
  }
  &:focus {
    border: 1px solid #242e46;
  }
  ${(p) => p.isError && 'border: 1px solid #DD2326;'};
`

export const DomainSearcherButton = styled.button`
  background: #00c084;
  border-radius: 3px;
  margin-left: 8px;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  width: 30%;
  padding: 11px 10px 12px 11px;
  border: 0;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    border-radius: 5px;
    margin-left: 14px;
    font-size: 18px;
    line-height: 21px;
    width: 100px;
    padding: 8px 17px 9px 17px;
  }
`

export const MainBlock = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`

export const CartContainer = styled.div`
  display: none;
  flex-direction: column;
  position: sticky;
  top: 30px;
  height: ${(p) => p.quantity * 70 + 330}px;
  min-height: 300px;
  max-height: 800px;
  @media (min-width: 768px) {
    top: 60px;
    display: flex;
  }
  @media (min-width: 1280px) {
    top: 30px;
  }
`

export const ChoosedItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  width: 300px;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  @media (min-width: 768px) {
    justify-content: space-between;
    margin-top: 43px;
  }
  @media (min-width: 1280px) {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
  }
`

export const ChoosedItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 5px;
  padding-top: 40px;
  padding-bottom: 40px;
  max-height: 400px;
  overflow-y: auto;
`

export const ChoosedHeader = styled.div`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 18px;
  line-height: 21px;
  color: #242e46;
  align-self: center;
  margin-top: 17px;
`

export const Choosen = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
  margin-left: 20px;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const SelectedItems = styled.div`
  display: flex;
`

export const CheckmarkFilled = styled.div`
  width: 18px;
  height: 18px;
  background-image: url(${checkmarkFilled});
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
`

export const ChoosenName = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #242e46;
  > span {
    font-weight: bold;
  }
`

export const ChoosenPrice = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  color: #242e46;
  font-family: sans-serif;
`

export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
`
export const TotalText = styled.div`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 14px;
  line-height: 16px;
  color: #242e46;
`

export const TotalSum = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #242e46;
  font-family: sans-serif;
`
export const TotalSumText = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #242e46;
  padding-left: 3px;
`

export const ButtonContainer = styled.div`
  width: 100%;
  @media (min-width: 1280px) {
    width: 30%;
  }
`

export const OrderButton = styled.button`
  background: ${(p) => (p.disabled ? '#888e9b' : '#242e46')};
  border-radius: 5px;
  width: 182px;
  padding: 11px 1px 11px 1px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin-top: 21px;
  align-self: flex-end;
  text-decoration: none;
  text-align: center;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  border: none;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`

export const OptionsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 46px;
  width: 100%;

  @media (min-width: 768px) {
    margin-top: 43px;
    width: 50%;
  }

  @media (min-width: 1280px) {
    margin-top: 70px;
    width: 700px;
  }
`

export const SwitchButton = styled.button`
  background: #595ba8;
  border-radius: 5px;
  padding: 15px 10px;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  width: 45%;
  margin-bottom: 22px;
  border: none;
  transition: 0.3s;
  align-self: flex-end;

  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }

  @media (min-width: 1280px) {
    padding: 11px 29px 11px 29px;
    font-size: 14px;
    width: 35%;
    min-width: 128px;
    margin-bottom: 10px;
  }
`
export const Options = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  padding-right: 0px;

  @media (min-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`
export const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(p) => (p.isLastSelected ? '40px' : '8px')};
  border-radius: 5px;
  position: relative;
  @media (min-width: 768px) {
    margin-bottom: ${(p) => (p.isLastSelected ? '40px' : '25px')};
  }
  @media (min-width: 1280px) {
    flex-direction: row;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    border-left: ${(p) => (p.isSelected ? '5px solid #242e46' : 'none')};
  }
`

export const ListItemTopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-left: ${(p) => (p.isSelected ? '5px solid #242e46' : 'none')};
  @media (min-width: 768px) {
    align-items: flex-end;
  }

  @media (min-width: 1280px) {
    align-items: center;
    width: 70%;
    background-color: transparent;
    padding-right: 80px;
    border-left: none;
    border-radius: 0px;
    box-shadow: none;
  }
`

export const Premium = styled.div`
  background: #00c084;
  position: absolute;
  text-align: center;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  top: -13px;
  left: 10px;
  font-size: 14px;
  padding: 5px 20px;
`

export const Sale = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (min-width: 1280px) {
    flex-direction: row;
  }
`
export const SaleImg = styled.img`
  height: 48px;
  width: 48px;
  position: relative;
  align-self: flex-start;
  margin-left: -4px;
  margin-top: -2px;
  @media (min-width: 1280px) {
    padding-bottom: 25px;
  }
`
export const SaleImgText = styled.div`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  transform: rotate(45deg);
  position: absolute;
  padding: 12px 0px 12px 11px;
  align-self: flex-start;
`

export const DomainName = styled.div`
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  width: 205px;
  color: #242e46;
  font-family: sans-serif;
  word-break: break-all;
  > span {
    font-weight: 500;
  }
  padding: 28px 39px 20px 21px;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 28px;
    > span {
      font-weight: 500;
    }
    padding: 21px 0px 22px 11px;
    margin-top: -8px;
  }

  @media (min-width: 1280px) {
    font-size: 24px;
    line-height: 28px;
    > span {
      font-weight: 500;
    }
    padding: 21px 0px 22px 11px;
    margin-top: 0px;
  }
`

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 0;
  @media (min-width: 768px) {
    padding: 28px 0;
  }
  @media (min-width: 1280px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
    min-width: 180px;
  }
`
export const OldPrice = styled.div`
  font-weight: normal;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #737373;
  text-decoration: line-through;
  text-decoration-color: #242e46;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 23px;
    min-width: 75px;
  }
`

export const NewPrice = styled.div`
  font-weight: normal;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #393939;
  padding-right: 13px;
  padding-top: 12px;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 23px;
    padding-right: 18px;
  }
  @media (min-width: 1280px) {
    padding-left: 20px;
    padding-right: 0px;
    padding-top: 0px;
  }
`
export const Status = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  justify-content: center;
  padding: 21px 0px 22px 0px;
  margin-bottom: 22px;

  @media (min-width: 1280px) {
    margin-top: 0;
    min-width: 150px;
    padding-right: 34px;
    width: auto;
    margin-bottom: 0px;
  }
`
export const StatusText = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #242e46;
  padding-right: 6px;
  cursor: pointer;
`
export const CheckmarkEmpty = styled.div`
  width: 14px;
  height: 10px;
  background-image: url(${checkmarkEmpty});
  background-repeat: no-repeat;
  background-size: cover;
`
export const AddButton = styled.button`
  background: #242e46;
  border-radius: 5px;
  padding: 19px 0px 19px 0px;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 22px;
  border: none;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }

  @media (min-width: 1280px) {
    padding: 11px 29px 11px 29px;
    font-size: 14px;
    line-height: 16px;
    width: auto;
    min-width: 128px;
    margin-top: 0px;
    margin-right: 45px;
    margin-left: 10px;
    margin-bottom: 0px;
  }
`

export const WhoIsButton = styled.button`
  background: #595ba8;
  border-radius: 5px;
  padding: 19px 0px 19px 0px;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 22px;
  border: none;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }

  @media (min-width: 1280px) {
    padding: 11px 29px 11px 29px;
    font-size: 14px;
    line-height: 16px;
    width: auto;
    min-width: 128px;
    margin-top: 0px;
    margin-right: 45px;
    margin-left: 10px;
    margin-bottom: 0px;
  }
`
export const ShowMore = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: ${(p) => (p.alignRight ? 'flex-end' : 'space-between')};
  }
`
export const ShowMoreButton = styled.button`
  background: #00c084;
  border-radius: 5px;
  font-weight: ${fontWeight.MEDIUM};
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 12px 0px 11px 0px;
  margin-top: 20px;
  order: 2;
  width: 100%;
  border: 0;
  text-align: center;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    padding: 11px 0px;
    margin-top: 35px;
    margin-right: 0;
    margin-left: 0;
    order: 1;
    width: 190px;
  }
  @media (min-width: 1280px) {
    font-size: 18px;
    line-height: 21px;
    padding: 14px 0px;
    margin-top: 14px;
    order: 1;
    width: 230px;
  }
`
export const Showed = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #242e46;
  padding-top: 25px;
  order: 1;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    padding-top: 44px;
    order: 2;
  }
  @media (min-width: 1280px) {
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    padding-top: 30px;
    order: 2;
  }
`

export const MobileCartContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  height: ${(p) => (p.isExpanded ? '255px' : '55px')};
  left: 0;
  right: 0;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  border-radius: 5px 5px 0px 0px;
  z-index: 1;
  transition: height 0.1s;
  @media (min-width: 768px) {
    display: none;
  }
`

export const MobileCartInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0px 20px;
`

export const MobileCartTitle = styled.div`
  display: flex;
  height: 55px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #00c084;
  position: relative;
`

export const Carrot = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  background-image: url(${carrot});
  background-repeat: no-repeat;
  background-size: cover;
  top: 17px;
  right: -20px;
  transform: ${(p) => (p.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
`
export const MobileCartContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`
export const MobileCartContent = styled.div`
  min-height: 100px;
  max-height: 400px;
  width: 75%;
`

export const ChoosedItemsMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 5px;
  padding: 15px 0px;
  height: 100px;
  overflow-y: auto;
`

export const ChoosenMobile = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
  padding: 10px 10px;
  box-sizing: border-box;
`

export const SelectedItemsMobile = styled.div`
  display: flex;
`

export const ChoosenNameMobile = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: #242e46;
  > span {
    font-weight: bold;
  }
`

export const ChoosenPriceMobile = styled.div`
  font-weight: 300;
  font-size: 14px;
  text-align: right;
  color: #242e46;
  font-family: sans-serif;
`

export const TotalMobile = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
`
export const TotalTextMobile = styled.div`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 12px;
  color: #242e46;
`

export const TotalSumMobile = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: #242e46;
  font-family: sans-serif;
`
export const TotalSumTextMobile = styled.span`
  font-weight: normal;
  font-size: 12px;
  color: #242e46;
  padding-left: 3px;
`
export const CheckmarkFilledMobile = styled.div`
  width: 13.5px;
  height: 13.5px;
  background-image: url(${checkmarkFilled});
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
`
export const OrderNowButtonMobile = styled.button`
  background: ${(p) => (p.disabled ? '#888e9b' : '#00c084')};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  border-radius: 0px;
  width: 100%;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  padding: 12px 0px;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`

export const DomainPageContent = styled.a`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #242e46;
  padding-top: 25px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 21px;
    padding-top: 44px;
  }
  @media (min-width: 1280px) {
    font-size: 18px;
    line-height: 21px;
    padding-left: 60px;
    padding-top: 20;
  }
`
