import { AUTO_CHARGE_MODAL_TYPES } from './AutoChargeModal.types';

const initialState = {
  autopay: null,
  loading: false,
};

export const autoChargeModal = (state = initialState, action) => {
  switch (action.type) {
    case AUTO_CHARGE_MODAL_TYPES.AUTO_CHARGE_MODAL_TYPES_SET_AUTOCHARGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AUTO_CHARGE_MODAL_TYPES.AUTO_CHARGE_MODAL_TYPES_SET_AUTOCHARGE_SUCCESS:
      return {
        ...state,
        autopay: action.value,
        loading: false,
      };
    case AUTO_CHARGE_MODAL_TYPES.AUTO_CHARGE_MODAL_TYPES_SET_AUTOCHARGE_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
