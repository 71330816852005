import React, { useState, useEffect, useRef } from 'react'
import * as S from '../SslConfigurationPage.styles'
import { CsrModal } from '../../../components/Modals/CsrModal'
import { Formik, Form } from 'formik'
import { getInitialValues, getYupScheme } from './FirstStep.config'
import { countries } from '../../../constants/countries'
import PhoneInput from 'react-phone-input-2'
import { i18n } from '../../../i18n'
import { Loader } from '../../../components/Loader'
import { Checkbox } from '../../../components/Checkbox'

export const FirstStep = ({
  csr,
  showModal,
  privateKey,
  step,
  handleContinue,
  initialValues,
  loading,
  orderId,
  error,
  getServiceDetailsLoading,
  isWildcard,
  firstStepSslData,
}) => {
  const [countriesData, setCountriesData] = useState()
  const [inputCountryCode, setInputCountryCode] = useState('')

  useEffect(() => {
    setCountriesData(
      countries.map((item) => {
        return {
          ...item,
          label: `${item.label} (${item.value})`,
        }
      })
    )
  }, [])

  const downloadFile = (fileName, val) => {
    const element = document.createElement('a')
    const file = new Blob([val], { type: 'text/plain;charset=utf-8' })
    element.href = URL.createObjectURL(file)
    element.download = fileName
    document.body.appendChild(element)
    element.click()
  }

  const formikRef = useRef(null)

  const orderTypeOptions = [
    {
      label: i18n.t('sslConfiguration.new'),
      value: 'new',
    },
    {
      label: i18n.t('sslConfiguration.renew'),
      value: 'renew',
    },
  ]

  useEffect(() => {
    if (formikRef && formikRef.current) {
      formikRef.current.setFieldValue('csr', csr)
    }
  }, [csr])

  useEffect(() => {
    if (formikRef && formikRef.current) {
      formikRef.current.setFieldValue('privateKey', privateKey)
    }
  }, [privateKey])

  const ScrollToFieldError = ({ submitCount, isValid, errors }) => {
    useEffect(() => {
      if (isValid) return

      if (Object.keys(errors).length) {
        if (firstStepSslData) {
          firstStepSslData.forEach((item) => {
            const key = item.key
            if (errors.hasOwnProperty(key)) {
              for (const inputName of firstStepSslData) {
                if (errors[inputName.key]) {
                  const element = document.querySelector(
                    `input[name='${inputName.key}']`
                  )

                  element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  break
                }
              }
            }
          })
        } else {
          const fieldNames = Object.keys(errors)
          if (fieldNames.length) {
            for (const fieldName of fieldNames) {
              const element = document.querySelector(
                `[name='${fieldName}'], ${
                  fieldName === 'csr' && '#csrInput'
                },  ${fieldName === 'ordertype' && '.select'}`
              )

              if (element) {
                element.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                break
              }
            }
          }
        }
      }
    }, [submitCount, errors])

    return null
  }

  return (
    <S.FormContainer>
      {!getServiceDetailsLoading ? (
        <>
          <Formik
            initialValues={getInitialValues(
              initialValues,
              countriesData,
              firstStepSslData
            )}
            validationSchema={getYupScheme(firstStepSslData)}
            innerRef={formikRef}
            onSubmit={async (values) => {
              const formattedValues = {
                ...values,
                country: values.country.value,
                csr: values.csr,
                step: '2',
                ordertype: values.ordertype,
                ssl_order_id: orderId,
                servertype: '-1',
                private_key: values.privateKey,
                jobtitle: values.jobtitle ? values.jobtitle : 'N/A',
                orgname: values.orgname ? values.orgname : 'N/A',
                org_country: values.org_country
                  ? values.org_country.value
                  : undefined,
              }
              await handleContinue(formattedValues, 2)
            }}
          >
            {({
              touched,
              errors,
              handleChange,
              values,
              setFieldValue,
              dirty,
              handleBlur,
              handleSubmit,
              submitCount,
              isValid,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <ScrollToFieldError
                    submitCount={submitCount}
                    isValid={isValid}
                    errors={errors}
                  />
                  {firstStepSslData && (
                    <S.FormHeader>
                      {i18n.t('sslConfiguration.headerOrganization')}
                    </S.FormHeader>
                  )}
                  {firstStepSslData
                    ? firstStepSslData.map((i) => {
                        const options =
                          i.type === 'dropdown' &&
                          i.options.split(',').map((i) => ({
                            label: i,
                            value: i,
                          }))
                        return (
                          <S.FormItem>
                            <S.Label>
                              {i.required && '*'}
                              {i18n.t(`sslConfiguration.orgFields.${i.name}`)}
                            </S.Label>
                            {i.type === 'text' && i.key !== 'org_phone' ? (
                              <S.Input
                                value={values[i.key]}
                                placeholder={i18n.t(
                                  `sslConfiguration.orgFields.${i.name}`
                                )}
                                onChange={handleChange}
                                name={i.key}
                                onBlur={handleBlur}
                              />
                            ) : i.type === 'dropdown' &&
                              i.key === 'org_country' ? (
                              <S.StyledSelect
                                value={values[i.key]}
                                isSearchable={true}
                                name={i.key}
                                options={options}
                                onBlur={handleBlur}
                                onChange={(options) => {
                                  setFieldValue(`${i.key}`, options)
                                }}
                                className={'select'}
                                classNamePrefix={'select'}
                              />
                            ) : i.type === 'password' ? (
                              <S.Input
                                value={values[i.key]}
                                placeholder={i.name}
                                onChange={handleChange}
                                name={i.key}
                                onBlur={handleBlur}
                                type="password"
                              />
                            ) : i.type === 'textarea' ? (
                              <S.CsrInput
                                value={values[i.key]}
                                onChange={handleChange}
                                name={i.key}
                                onBlur={handleBlur}
                              />
                            ) : i.type === 'yesno' ? (
                              <Checkbox
                                name={i.key}
                                onBlur={handleBlur}
                                value={values[i.key]}
                                onChange={() => {
                                  setFieldValue(values[i.key], !values[i.key])
                                }}
                              />
                            ) : null}
                            {i.key === 'org_phone' && (
                              <S.PhoneInput>
                                <PhoneInput
                                  placeholder={i.key}
                                  value={
                                    values[i.key].startsWith('00')
                                      ? values[i.key].replace('00', '+')
                                      : values[i.key]
                                  }
                                  onChange={(phone, data) => {
                                    setInputCountryCode(data.countryCode)
                                    if (data.countryCode !== inputCountryCode) {
                                      setFieldValue(i.key, data.dialCode)
                                    } else {
                                      setFieldValue(i.key, phone)
                                    }
                                  }}
                                  inputProps={{ name: i.key }}
                                  masks={{ am: '(..) ......' }}
                                  country="am"
                                  onBlur={handleBlur}
                                />
                              </S.PhoneInput>
                            )}
                            {errors[i.key] && touched[i.key] && (
                              <S.ErrorMessage className="error-message">
                                {i18n.t(errors[i.key])}
                              </S.ErrorMessage>
                            )}
                          </S.FormItem>
                        )
                      })
                    : null}
                  <S.FormHeader>
                    {i18n.t('sslConfiguration.header1')}
                  </S.FormHeader>
                  <S.FormItem>
                    <S.Label>{i18n.t('sslConfiguration.orderType')}</S.Label>
                    <S.StyledSelect
                      value={orderTypeOptions.filter(
                        (option) => option.value === values.ordertype
                      )}
                      isSearchable={true}
                      name="ordertype"
                      options={orderTypeOptions}
                      onBlur={handleBlur}
                      onChange={(option) => {
                        setFieldValue('ordertype', option.value)
                      }}
                      className={'select'}
                      classNamePrefix={'select'}
                    />
                  </S.FormItem>
                  {errors.ordertype && touched.ordertype && (
                    <S.ErrorMessage>{i18n.t(errors.ordertype)}</S.ErrorMessage>
                  )}
                  <S.FormItem>
                    <S.Label>CSR(Certificate Signing Request)</S.Label>
                    <S.CsrInput
                      placeholder="-----BEGIN CERTIFICATE REQUEST------"
                      value={values.csr}
                      onChange={handleChange}
                      name="csr"
                      readOnly
                      onBlur={handleBlur}
                      id="csrInput"
                    />
                  </S.FormItem>
                  {errors.csr && (
                    <S.ErrorMessage>{i18n.t(errors.csr)}</S.ErrorMessage>
                  )}
                  <S.ButtonsWrapper>
                    <S.Button
                      type="button"
                      onClick={() => {
                        showModal(CsrModal, {
                          isGenerateOnlyModal: true,
                          isWildcard,
                        })
                      }}
                    >
                      {i18n.t('csrModal.generateCsr')}
                    </S.Button>
                    <S.Button
                      type="button"
                      onClick={() => {
                        showModal(CsrModal, {
                          isGenerateOnlyModal: false,
                          setCSR: setFieldValue,
                        })
                      }}
                    >
                      {i18n.t('csrModal.uploadCsr')}
                    </S.Button>
                    <S.Button
                      type="button"
                      isDisabled={!values.privateKey}
                      onClick={() => {
                        downloadFile('private_key', values.privateKey)
                      }}
                    >
                      {i18n.t('csrModal.downloadKey')}
                    </S.Button>
                  </S.ButtonsWrapper>
                  <S.FormItem>
                    <S.Label>{i18n.t('sslConfiguration.firstName')}</S.Label>
                    <S.Input
                      value={values.firstname}
                      placeholder="First name"
                      onChange={handleChange}
                      name="firstname"
                      onBlur={handleBlur}
                    />
                  </S.FormItem>
                  {errors.firstname && touched.firstname && (
                    <S.ErrorMessage>{i18n.t(errors.firstname)}</S.ErrorMessage>
                  )}
                  <S.FormItem>
                    <S.Label>{i18n.t('sslConfiguration.lastName')}</S.Label>
                    <S.Input
                      value={values.lastname}
                      placeholder="Last name"
                      onChange={handleChange}
                      name="lastname"
                      onBlur={handleBlur}
                    />
                  </S.FormItem>
                  {errors.lastname && touched.lastname && (
                    <S.ErrorMessage>{i18n.t(errors.lastname)}</S.ErrorMessage>
                  )}
                  <S.FormItem>
                    <S.Label>{i18n.t('sslConfiguration.organization')}</S.Label>
                    <S.Input
                      value={values.orgname}
                      placeholder="Organization name"
                      onChange={handleChange}
                      name="orgname"
                      onBlur={handleBlur}
                    />
                  </S.FormItem>
                  {errors.orgname && touched.orgname && (
                    <S.ErrorMessage>{i18n.t(errors.orgname)}</S.ErrorMessage>
                  )}
                  <S.FormItem>
                    <S.Label>{i18n.t('sslConfiguration.jobTitle')}</S.Label>
                    <S.Input
                      value={values.jobtitle}
                      placeholder="Job title"
                      onChange={handleChange}
                      name="jobtitle"
                      onBlur={handleBlur}
                    />
                  </S.FormItem>
                  {errors.jobtitle && touched.jobtitle && (
                    <S.ErrorMessage>{i18n.t(errors.jobtitle)}</S.ErrorMessage>
                  )}
                  <S.FormItem>
                    <S.Label>{i18n.t('sslConfiguration.email')}</S.Label>
                    <S.Input
                      value={values.email}
                      placeholder="internet@gmail.com"
                      onChange={handleChange}
                      name="email"
                      onBlur={handleBlur}
                    />
                  </S.FormItem>
                  {errors.email && touched.email && (
                    <S.ErrorMessage>{i18n.t(errors.email)}</S.ErrorMessage>
                  )}
                  <S.FormItem>
                    <S.Label>{i18n.t('sslConfiguration.address1')}</S.Label>
                    <S.Input
                      value={values.address1}
                      placeholder=""
                      onChange={handleChange}
                      name="address1"
                      onBlur={handleBlur}
                    />
                  </S.FormItem>
                  {errors.address1 && touched.address1 && (
                    <S.ErrorMessage>{i18n.t(errors.address1)}</S.ErrorMessage>
                  )}
                  <S.FormItem>
                    <S.Label>{i18n.t('sslConfiguration.address2')}</S.Label>
                    <S.Input
                      value={values.address2}
                      onChange={handleChange}
                      name="address2"
                      placeholder=""
                      onBlur={handleBlur}
                    />
                  </S.FormItem>
                  {errors.address2 && touched.address2 && (
                    <S.ErrorMessage>{i18n.t(errors.address2)}</S.ErrorMessage>
                  )}
                  <S.FormItem>
                    <S.Label>{i18n.t('sslConfiguration.city')}</S.Label>
                    <S.Input
                      value={values.city}
                      placeholder="Yerevan"
                      onChange={handleChange}
                      name="city"
                      onBlur={handleBlur}
                    />
                  </S.FormItem>
                  {errors.city && touched.city && (
                    <S.ErrorMessage>{i18n.t(errors.city)}</S.ErrorMessage>
                  )}
                  <S.FormItem>
                    <S.Label>{i18n.t('sslConfiguration.state')}</S.Label>
                    <S.Input
                      value={values.state}
                      placeholder="Yerevan"
                      onChange={handleChange}
                      name="state"
                      onBlur={handleBlur}
                    />
                  </S.FormItem>
                  {errors.state && touched.state && (
                    <S.ErrorMessage>{i18n.t(errors.state)}</S.ErrorMessage>
                  )}
                  <S.FormItem>
                    <S.Label>{i18n.t('sslConfiguration.zipCode')}</S.Label>
                    <S.Input
                      placeholder="0039"
                      value={values.postcode}
                      onChange={handleChange}
                      name="postcode"
                      onBlur={handleBlur}
                    />
                  </S.FormItem>
                  {errors.postcode && touched.postcode && (
                    <S.ErrorMessage>{i18n.t(errors.postcode)}</S.ErrorMessage>
                  )}
                  <S.FormItem>
                    <S.Label>{i18n.t('sslConfiguration.country')}</S.Label>
                    <S.StyledSelect
                      value={values.country}
                      isSearchable={true}
                      name="country"
                      options={countriesData}
                      onBlur={handleBlur}
                      onChange={(option) => {
                        setFieldValue('country', option)
                      }}
                      classNamePrefix="react-select"
                    />
                  </S.FormItem>
                  {errors.country && touched.country && (
                    <S.ErrorMessage>{i18n.t(errors.country)}</S.ErrorMessage>
                  )}
                  <S.FormItem>
                    <S.Label>{i18n.t('sslConfiguration.phoneNumber')}</S.Label>
                    <S.PhoneInput>
                      <PhoneInput
                        placeholder="Phonenumber"
                        value={
                          values.phonenumber.startsWith('00')
                            ? values.phonenumber.replace('00', '+')
                            : values.phonenumber
                        }
                        onChange={(phone, data) => {
                          setInputCountryCode(data.countryCode)
                          if (data.countryCode !== inputCountryCode) {
                            setFieldValue('phonenumber', data.dialCode)
                          } else {
                            setFieldValue('phonenumber', phone)
                          }
                        }}
                        name="phonenumber"
                        masks={{ am: '(..) ......' }}
                        country="am"
                        onBlur={handleBlur}
                      />
                    </S.PhoneInput>
                  </S.FormItem>
                  {errors.phonenumber && touched.phonenumber && (
                    <S.ErrorMessage>
                      {i18n.t(errors.phonenumber)}
                    </S.ErrorMessage>
                  )}

                  <S.ButtonsWrapper>
                    <S.Button type="button" isDisabled={true}>
                      {i18n.t('sslConfiguration.back')}
                    </S.Button>
                    <S.Button type="submit">
                      {loading ? (
                        <Loader color="#FFFFFF" size={0.5} />
                      ) : (
                        i18n.t('sslConfiguration.continue')
                      )}
                    </S.Button>
                  </S.ButtonsWrapper>
                </Form>
              )
            }}
          </Formik>

          {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
        </>
      ) : (
        <S.LoaderContainer>
          <Loader color={'#00C084'} size={0.5} />
        </S.LoaderContainer>
      )}
    </S.FormContainer>
  )
}
