import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

import { PersonalDetailsSuccessModal as Self } from './PersonalDetailsSuccessModal';


const mapStateToProps = ({ app }) => ({
  bindStatus: app.userInfo.bindStatus
});

export const PersonalDetailsSuccessModal = withTranslation()(
  withRouter(connect(mapStateToProps, null)(Self))
);
