import FetchApi from '../../helpers/FetchApi';

import { CREDIT_RECHARGE_TYPES } from './CreditRechargePage.types';

export const creditRecharge = (amount, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: CREDIT_RECHARGE_TYPES.CREDIT_RECHARGE_REQUEST,
    });

    const req = await FetchApi.post('users/creditRecharge', {
      amount,
    });

    dispatch({
      type: CREDIT_RECHARGE_TYPES.CREDIT_RECHARGE_SUCCESS,
    });

    if (onSuccess) {
      onSuccess(req.data.result.invoiceid);
    }
  } catch (e) {
    dispatch({
      type: CREDIT_RECHARGE_TYPES.CREDIT_RECHARGE_FAIL,
      error: e.data
        ? e.data.error || e.data.message || e.data.validation_error
        : e.message,
    });
  }
};
