import { CSR_MODAL } from './CsrModal.types'

const initialState = {
  loading: false,
  error: null,
  csrResult: {},
  tab: 0,
  step: 0,
  isKeyDownloaded: false,
  uploadLoading: false,
  uploadError: false,
}

export const CsrModal = (state = initialState, action) => {
  switch (action.type) {
    case CSR_MODAL.GENERATE_CSR_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        csrResult: {},
      }
    case CSR_MODAL.GENERATE_CSR_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        csrResult: action.data,
      }
    }
    case CSR_MODAL.GENERATE_CSR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        csrResult: {},
      }
    case CSR_MODAL.UPLOAD_CSR_REQUEST:
      return {
        ...state,
        uploadLoading: true,
        uploadError: false,
      }
    case CSR_MODAL.UPLOAD_CSR_SUCCESS:
      return {
        ...state,
        uploadLoading: false,
        uploadError: false,
      }
    case CSR_MODAL.UPLOAD_CSR_FAIL:
      return {
        ...state,
        uploadLoading: false,
        uploadError: action.error,
      }
    case CSR_MODAL.SET_TAB:
      return {
        ...state,
        tab: action.tab,
      }
    case CSR_MODAL.SET_STEP:
      return {
        ...state,
        step: action.step,
      }
    case CSR_MODAL.SET_IS_KEY_DOWNLOADED:
      return {
        ...state,
        isKeyDownloaded: true,
      }

    default:
      return {
        ...state,
      }
  }
}
