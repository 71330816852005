import React from 'react';

import * as S from './TransferDomainSuccessModal.styles';
import { i18n } from '../../../i18n';

export const TransferDomainSuccessModal = ({ hideModal }) => (
  <S.Modal onClick={(e) => e.stopPropagation()}>
    <S.TransferDomainSuccessModalContainer>
      <S.Image />
      <S.Title>{i18n.t('transferDomainSuccessModal.title')}</S.Title>
      <S.Header>{i18n.t('transferDomainSuccessModal.header')}</S.Header>
      <S.SubHeader>
        {i18n.t('transferDomainSuccessModal.subHeader')}
      </S.SubHeader>
      <S.CloseModalButton onClick={hideModal} />
    </S.TransferDomainSuccessModalContainer>
  </S.Modal>
);
