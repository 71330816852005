import { MODAL_TYPES } from "./Modal.types";

export const showModal = (ModalComponent, componentProps = {}) => ({
  type: MODAL_TYPES.SHOW_MODAL,
  ModalComponent,
  componentProps,
});

export const hideModal = () => ({
  type: MODAL_TYPES.HIDE_MODAL,
});
