import React from 'react';

import * as S from './ResetPasswordSuccessModal.styles';
import { i18n } from '../../../i18n';

export const ResetPasswordSuccessModal = ({ hideModal }) => (
  <S.Modal onClick={(e) => e.stopPropagation()}>
    <S.ResetPasswordModalContainer>
      <S.Image />
      <S.Header>{i18n.t('resetPasswordSuccessModal.header')}</S.Header>
      <S.SubHeader>{i18n.t('resetPasswordSuccessModal.subHeader')}</S.SubHeader>
      <S.CloseModalButton onClick={hideModal} />
    </S.ResetPasswordModalContainer>
  </S.Modal>
);
