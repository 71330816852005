import styled from 'styled-components';
import * as fontWeight from '../../assets/styles/constants/fontWeight';
import Container from '../../components/Container/Container';
import { Link } from 'react-router-dom';

export const PageBody = styled.div`
  background-color: #f8f8f8;
  width: 100%;
  display: flex;
  padding-top: 75px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 75px;
`;

export const ContactBodyContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ContactTitle = styled.h1`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #484857;
  display: flex;
  align-self: flex-start;
  margin-left: 30px;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 29px;
    margin-left: 0px;
    align-self: center;
  }
`;
export const ContactHeader = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #737373;
  margin-top: 25px;
  display: flex;
  align-self: flex-start;
  margin-left: 30px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 21px;
    max-width: 650px;
    margin-top: 20px;
    margin-left: 0px;
    align-self: center;
  }
`;

export const ContainerOfContactCarts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 30px 0;
  @media (min-width: 768px) {
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 929px;
  }

  @media (min-width: 1280px) {
    justify-content: space-around;
    max-width: 100%;
  }
`;

export const ContactCartDiv = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  padding: 25px;
  margin: 0px 25px 10px 25px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    margin: 0px;
    margin-bottom: ${(p) => (p.setMargin ? '68px' : '30px')};
    width: 330px;
  }

  @media (min-width: 1280px) {
    width: 260px;
    margin: 0px;
  }
`;
export const ContactCartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 5px;
`;
export const ContactCartTitle = styled.div`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 18px;
  color: #242e46;
`;
export const ContactCartDescription = styled.div`
  font-weight: 300;
  font-size: 16px;
  color: #242e46;
`;
export const ContactInfo = styled.div`
  font-weight: 300;
  font-size: 16px;
  color: #242e46;
  padding-top: 10px;
  padding-bottom: 5px;
`;
export const ContactSupport = styled(Link)`
  font-weight: 300;
  font-size: 16px;
  color: #242e46;
  cursor: pointer;
`;
export const ContactMail = styled.a`
  font-weight: 300;
  font-size: 16px;
  color: #242e46;
`;

export const MapContainer = styled.div`
  width: 100%;
`;

export const MapInnerContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  height: 400px;
  @media (min-width: 1280px) {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
`;
