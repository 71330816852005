import { DOMAIN_TYPES } from './DomainsPage.types';
import { LIMIT } from './DomainsPage.constants';
import FetchApi from '../../helpers/FetchApi';

export const setDomainsData = (domainsData) => ({
  type: DOMAIN_TYPES.SET_DOMAINS_DATA,
  domainsData,
});

export const setDomain = (value) => ({
  type: DOMAIN_TYPES.SET_DOMAIN,
  value,
});

export const domainLookup = (domain) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DOMAIN_TYPES.GET_DOMAINS_REQUEST,
    });

    const { cart } = getState().app;
    const { tlds } = getState().searchAreaBlock;

    const req = await FetchApi.post('domains/lookup', {
      domain,
      tlds,
      offset: 0,
      limit: LIMIT,
    });

    const filteredDomains = cart.filter((item) =>
      req.data.data.find(
        (innerItem) =>
          item.type === 'domain' &&
          innerItem.domain === item.domain &&
          innerItem.tld === item.tld
      )
    );

    filteredDomains.forEach((item) => {
      const matchIndex = req.data.data.findIndex(
        (innerItem) =>
          item.type === 'domain' &&
          item.tld === innerItem.tld &&
          item.domain === innerItem.domain
      );
      req.data.data[matchIndex].isAdded = true;
      req.data.data[matchIndex]._id = item._id;
    });

    dispatch({
      type: DOMAIN_TYPES.GET_DOMAINS_SUCCESS,
      data: req.data.data,
      total: req.data.total,
      offset: 0,
    });

    dispatch({
      type: DOMAIN_TYPES.GET_DOMAINS_REQUEST_PART2,
    });

    const req2 = await FetchApi.post('domains/lookup', {
      domain,
      tlds,
      offset: LIMIT,
      limit: LIMIT,
    });

    const filteredDomains2 = cart.filter((item) =>
      req2.data.data.find(
        (innerItem) =>
          item.type === 'domain' &&
          innerItem.domain === item.domain &&
          innerItem.tld === item.tld
      )
    );

    filteredDomains2.forEach((item) => {
      const matchIndex = req2.data.data.findIndex(
        (innerItem) =>
          item.type === 'domain' &&
          item.tld === innerItem.tld &&
          item.domain === innerItem.domain
      );
      req2.data.data[matchIndex].isAdded = true;
      req2.data.data[matchIndex]._id = item._id;
    });

    dispatch({
      type: DOMAIN_TYPES.GET_DOMAINS_SUCCESS_PART2,
      data: req2.data.data,
      total: req2.data.total,
    });
  } catch (e) {
    dispatch({
      type: DOMAIN_TYPES.GET_DOMAINS_FAIL,
      error: e,
    });
  }
};

export const moreDomainLookup = (domain, offset) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: DOMAIN_TYPES.GET_MORE_DOMAINS_REQUEST,
    });

    const { cart } = getState().app;
    const { tlds } = getState().searchAreaBlock;

    const req = await FetchApi.post('domains/lookup', {
      domain,
      tlds,
      limit: LIMIT,
      offset,
    });

    const filteredDomains = cart.filter((item) =>
      req.data.data.find(
        (innerItem) =>
          innerItem.domain === item.domain && innerItem.tld === item.tld
      )
    );

    filteredDomains.forEach((item) => {
      const matchIndex = req.data.data.findIndex(
        (innerItem) =>
          item.tld === innerItem.tld && item.domain === innerItem.domain
      );
      req.data.data[matchIndex].isAdded = true;
      req.data.data[matchIndex]._id = item._id;
    });

    dispatch({
      type: DOMAIN_TYPES.GET_MORE_DOMAINS_SUCCESS,
      data: req.data.data,
      offset,
    });

    dispatch({
      type: DOMAIN_TYPES.GET_MORE_DOMAINS_REQUEST_PART2,
    });

    const req2 = await FetchApi.post('domains/lookup', {
      domain,
      tlds,
      limit: LIMIT,
      offset: offset + LIMIT,
    });

    const filteredDomains2 = cart.filter((item) =>
      req2.data.data.find(
        (innerItem) =>
          innerItem.domain === item.domain && innerItem.tld === item.tld
      )
    );

    filteredDomains2.forEach((item) => {
      const matchIndex = req2.data.data.findIndex(
        (innerItem) =>
          item.tld === innerItem.tld && item.domain === innerItem.domain
      );
      req2.data.data[matchIndex].isAdded = true;
      req2.data.data[matchIndex]._id = item._id;
    });

    dispatch({
      type: DOMAIN_TYPES.GET_MORE_DOMAINS_SUCCESS_PART2,
      data: req2.data.data,
    });
  } catch (e) {
    dispatch({
      type: DOMAIN_TYPES.GET_MORE_DOMAINS_FAIL,
      error: e,
    });
  }
};

export const toggleMobileCart = () => ({
  type: DOMAIN_TYPES.TOGGLE_MOBILE_CART,
});

export const toggleUnavailableDomains = () => ({
  type: DOMAIN_TYPES.TOGGLE_UNAVAILABLE_DOMAINS,
});
