import { connect } from 'react-redux';

import { CreditRechargePage as Self } from './CreditRechargePage';
import { creditRecharge } from './CreditRechargePage.actions';

const mapStateToProps = ({ app, creditRechargePage }) => ({
  auth: app.auth,
  loading: creditRechargePage.loading,
  error: creditRechargePage.error,
});

const mapDispatchToProps = {
  creditRecharge,
};

export const CreditRechargePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self);
