import React, { useEffect, useCallback } from 'react'
import { changeUrl } from '../../hooks/changeUrl'
import { Helmet } from 'react-helmet'
import { QuestionsList } from '../../components/QuestionsList'
import { LayoutCommon } from '../../layouts/LayoutCommon'
import { questions } from '../SupportPage/SupportPage.constants'
import { i18n } from '../../i18n'
import {
  hostingPackages,
  colors,
  hostingAdvList,
  advantagesData1,
  advantagesData2,
  logos,
  HOSTING_TABS,
} from './HostingPage.constants'
import qs from 'qs'

import * as S from './HostingPage.styles'

const HostingPage = ({
  history,
  tab,
  changeTab,
  numberWithCommas,
  auth,
  addToCart,
}) => {
  useEffect(() => {
    const params = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
      encode: true,
    })
    if (params.type && params.type === 'vps') {
      changeTab(HOSTING_TABS.VPS)
    } else {
      changeTab(HOSTING_TABS.LINUX)
    }
  }, [history.location.search, changeTab])

  const handleOrderNowButton = useCallback(
    (product) => {
      const id = addToCart({
        ...product,
        type: 'hosting',
        time: { value: 1, name: 'monthly' },
      })

      history.push(changeUrl(`/product-details?id=${id}`))
    },
    [history, addToCart]
  )

  return (
    <LayoutCommon transparentHeader>
      <S.PageWrapper>
        <Helmet>
          <title>{i18n.t('headers.hostingPage')}</title>
          <meta
            name="description"
            content={i18n.t('headersDescriptions.hostingPage')}
          />
          <link
            rel="alternate"
            href="https://internet.am/en/hosting-packages"
            hreflang="en"
          />
          <link
            rel="alternate"
            href="https://internet.am/ru/hosting-packages"
            hreflang="ru"
          />
          <link
            rel="alternate"
            href="https://internet.am/hosting-packages"
            hreflang="am-AM"
          />

          <meta property="og:title" content={i18n.t('headers.hostingPage')} />
          <meta
            property="og:description"
            content={i18n.t('headersDescriptions.hostingPage')}
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="ABC Domain LLC" />
          <meta
            property="og:image"
            content="https://internet.am/images/HostingFb.png"
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={i18n.t('headers.hostingPage')} />
          <meta
            name="twitter:description"
            content={i18n.t('headersDescriptions.hostingPage')}
          />
          <meta
            name="twitter:image"
            content="https://internet.am/images/HostingFb.png"
          />
        </Helmet>
        <S.HeaderContainer>
          <S.TitleWrapper>
            <S.HostingTitle>{i18n.t('hostingPage.lHosting')}</S.HostingTitle>
            <S.HostingSTitle>{i18n.t('hostingPage.sTitle')}</S.HostingSTitle>
            <S.OrderNowButton href="#hostingPackages">
              {i18n.t('hostingPage.orderNow')}
            </S.OrderNowButton>
          </S.TitleWrapper>
        </S.HeaderContainer>
        <S.PageBody>
          <S.HostingCategoryContainer>
            <S.HostingCategory id="hostingPackages" name="hostingPackages">
              {i18n.t('hostingPage.title')}
            </S.HostingCategory>
          </S.HostingCategoryContainer>
          <S.HostingCardsContainer>
            <S.HostingCardsInnerContainer>
              {hostingPackages.map((hostingPackage, index) => (
                <S.HostingPackageDiv key={hostingPackage.id}>
                  <S.HostingContainer>
                    <S.PackageParagraph>
                      {hostingPackage.name}
                    </S.PackageParagraph>
                    <S.Details>
                      {hostingPackage.params.map(
                        (x) =>
                          x.isFeatured && (
                            <S.DetailContainer key={x.key}>
                              <S.ValueContainer>
                                {i18n.t(x.value)}
                              </S.ValueContainer>
                              <S.KeyContainer>{i18n.t(x.key)}</S.KeyContainer>
                            </S.DetailContainer>
                          )
                      )}
                    </S.Details>
                    <S.Wrapper>
                      <S.PriceContainer color={colors[index].backgroundColor}>
                        <S.PriceValueContainer>
                          {numberWithCommas(
                            auth.currency === 2
                              ? hostingPackage.price_usd
                              : hostingPackage.price
                          )}
                        </S.PriceValueContainer>
                        <S.PriceKeyContainer>
                          {i18n.t('general.periods.monthly')}
                        </S.PriceKeyContainer>
                      </S.PriceContainer>
                      <S.BuyButton
                        color={colors[index].backgroundColor}
                        onClick={() => handleOrderNowButton(hostingPackage)}
                      >
                        {i18n.t('hostingPackageBlock.buy')}
                      </S.BuyButton>
                    </S.Wrapper>
                  </S.HostingContainer>
                </S.HostingPackageDiv>
              ))}
            </S.HostingCardsInnerContainer>
            <S.ConstructorPromo>
              <S.CreateText>
                {i18n.t('hostingPage.createSite')}{' '}
                <span>{i18n.t('hostingPage.createSiteBold')}</span>
              </S.CreateText>
              <S.StartText>{i18n.t('hostingPage.useConstructor')}</S.StartText>
              <S.BeginButton to={changeUrl('/constructor-packages')}>
                {i18n.t('constructorPage.headerButton')}
              </S.BeginButton>
            </S.ConstructorPromo>
            <S.AdvantagesList>
              <S.ListHeader>{i18n.t('hostingPage.advatnages')}</S.ListHeader>
              <S.ListWrapper>
                {hostingAdvList.map(
                  (adv, index) =>
                    index < 4 && (
                      <S.List key={adv.id}>
                        <S.ListImg
                          src={adv.image}
                          alt="Advantage of Internet.am hosting image"
                          title="advantage image for hosting"
                        />
                        <S.ListText>{i18n.t(adv.description)}</S.ListText>
                      </S.List>
                    )
                )}
              </S.ListWrapper>
              <S.ListWrapper>
                {hostingAdvList.map(
                  (adv, index) =>
                    index >= 4 && (
                      <S.List key={adv.id}>
                        <S.ListImg
                          src={adv.image}
                          alt="Advantage of Internet.am hosting image"
                          title="advantage image for hosting list"
                        />
                        <S.ListText>{i18n.t(adv.description)}</S.ListText>
                      </S.List>
                    )
                )}
              </S.ListWrapper>
            </S.AdvantagesList>
            <S.HelpContainer>
              <S.LeftSide>
                <S.HelpHeader>{i18n.t('hostingPage.helpHeader')}</S.HelpHeader>
                <S.LiveChatHelp>
                  {i18n.t('hostingPage.liveChat')}
                </S.LiveChatHelp>
                <S.OrderNowText>
                  {i18n.t('hostingPage.orderNowText')}
                </S.OrderNowText>
              </S.LeftSide>
              <S.OrderNowUpperButton href="#hostingPackages">
                {i18n.t('hostingPage.orderNow')}
              </S.OrderNowUpperButton>
              <S.RightSide>
                <S.UsersData>
                  <S.UsersNumber>{i18n.t('hostingPage.users')}</S.UsersNumber>
                  <S.UsersText>{i18n.t('hostingPage.usersText')}</S.UsersText>
                </S.UsersData>
              </S.RightSide>
            </S.HelpContainer>
            <S.CmsBlock>
              <S.CmsHeader>{i18n.t('hostingPage.cms')}</S.CmsHeader>
              <S.LogosContainer>
                {logos.map((logo) => (
                  <S.LogoBlock key={logo.id}>
                    <S.Logo
                      src={logo.image}
                      alt="Internet.am Logo"
                      title="logo image"
                    />
                    <S.LogoTitle>{logo.title}</S.LogoTitle>
                  </S.LogoBlock>
                ))}
              </S.LogosContainer>
            </S.CmsBlock>
            <S.ABCAdvantagesList>
              <S.ABCHeader>{i18n.t('hostingPage.abcAvd')}</S.ABCHeader>
              <S.AdvantagesBody>
                <S.AdvantagesBlokLeft>
                  {advantagesData1.map((advantage) => (
                    <S.AdvantagesItem key={advantage.id}>
                      <S.CheckIcon
                        src={advantage.img}
                        alt="Check icon"
                        title="check icon for hosting page"
                      />
                      <S.AdvantageText>
                        {i18n.t(advantage.name)}
                      </S.AdvantageText>
                    </S.AdvantagesItem>
                  ))}
                </S.AdvantagesBlokLeft>
                <S.AdvantagesBlokRight>
                  {advantagesData2.map((advantage) => (
                    <S.AdvantagesItem key={advantage.id}>
                      <S.CheckIcon
                        src={advantage.img}
                        alt="Check icon"
                        title="check icon for hosting page"
                      />
                      <S.AdvantageText>
                        {i18n.t(advantage.name)}
                      </S.AdvantageText>
                    </S.AdvantagesItem>
                  ))}
                </S.AdvantagesBlokRight>
              </S.AdvantagesBody>
            </S.ABCAdvantagesList>
            <S.ContactUsContainer>
              <S.Image />
              <S.ContactUs>
                <S.ContactUsHeader>
                  {i18n.t('hostingPage.contacUsHeader')}
                </S.ContactUsHeader>
                <S.ContactUsSubHeader to={changeUrl('/open-ticket')}>
                  {i18n.t('hostingPage.contacUsSubHeader')}
                </S.ContactUsSubHeader>
              </S.ContactUs>
            </S.ContactUsContainer>
            <QuestionsList isInPage questions={questions.hosting.list} />
          </S.HostingCardsContainer>
        </S.PageBody>
      </S.PageWrapper>
    </LayoutCommon>
  )
}

export { HostingPage }
