import { VIEW_TICKET_TYPES } from './ViewTicketModal.types';
import moment from 'moment';

const initialState = {
  ticketsLoading: false,
  ticketsData: {},
  loading: false,
  error: null,
  replyLoading: false,
};

export const viewTicket = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_TICKET_TYPES.GET_TICKET_REQUEST:
      return {
        ...state,
        ticketsLoading: true,
        ticketsData: {},
      };

    case VIEW_TICKET_TYPES.GET_TICKET_SUCCESS: {
      return {
        ...state,
        ticketsLoading: false,
        ticketsData: action.data,
      };
    }
    case VIEW_TICKET_TYPES.GET_TICKET_FAIL:
      return {
        ...state,
        ticketsLoading: false,
        ticketsData: {},
      };
    case VIEW_TICKET_TYPES.REPLY_TICKET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        replyLoading: true,
      };
    case VIEW_TICKET_TYPES.REPLY_TICKET_SUCCESS:
      const ticketsData = { ...state.ticketsData };
      ticketsData.replies.reply.push({
        message: action.message,
        date: moment().format('YYYY-MM-DD hh:mm:ss'),
        name: action.fullname,
        email: action.email,
        attachments: action.attachments || undefined,
      });
      return {
        ...state,
        ticketsData,
        loading: false,
        error: null,
        replyLoading: false,
      };
    case VIEW_TICKET_TYPES.REPLY_TICKET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        replyLoading: false,
      };
    case VIEW_TICKET_TYPES.CLOSE_TICKET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VIEW_TICKET_TYPES.CLOSE_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case VIEW_TICKET_TYPES.CLOSE_TICKET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
