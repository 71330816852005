import React, { Component } from 'react'
import { ProtectedRoute } from './ProtectedRoute'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router'
import { ScrollTop } from './ScrollTop'

import { HomePage } from '../pages/HomePage'
import { EditProfilePage } from '../pages/EditProfilePage'
import { ErrorPage } from '../pages/ErrorPage'
import { TelcellPaymentPage } from '../pages/TelcellPaymentPage'
import { RegistrationPage } from '../pages/RegistrationPage'
import { VpsPage } from '../pages/VpsPage'
import { ConstructorPage } from '../pages/ConstructorPage'
import { DomainConfigurationPage } from '../pages/DomainConfigurationPage'
import { OpenTicketPage } from '../pages/OpenTicketPage'
import { ResetPasswordPage } from '../pages/ResetPasswordPage'
import { HostingPage } from '../pages/HostingPage'
import { SslPage } from '../pages/SslPage'
import { SupportPage } from '../pages/SupportPage'
import { DomainsPage } from '../pages/DomainsPage'
import { AboutUsPage } from '../pages/AboutUsPage'
import { SpecialTermsPage } from '../pages/SpecialTermsPage'
import { ContactsPage } from '../pages/ContactsPage'
import { OrderDetailsPage } from '../pages/OrderDetailsPage'
import { ProductDetailsPage } from '../pages/ProductDetailsPage'
import { ClientAreaPage } from '../pages/ClientAreaPage'
import { InvoicePage } from '../pages/InvoicePage'
import { TwoFactorAuthEnablePage } from '../pages/TwoFactorAuthEnablePage'
import { CreditRechargePage } from '../pages/CreditRechargePage'
import { ConfirmEmailPage } from '../pages/ConfirmEmailPage'
import { PaymentFailPage } from '../pages/PaymentFailPage/PaymentFailPage'
import { PaymentSuccessPage } from '../pages/PaymentSuccessPage'
import { ServiceDetails } from '../pages/ServiceDetails'
import { SSLConfigurationPage } from '../pages/SSLConfiguration'
import { SslConfigurationPage } from '../pages/SslConfigurationPage'
import { showModal } from '../common/Modal/Modal.actions'

class Routes extends Component {
  componentDidUpdate(oldProps) {
    if (this.props.auth.userId !== oldProps.auth.oldProps) {
      fetch(
        'https://hooks.slack.com/services/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',
        {
          method: 'POST',
          body: JSON.stringify({
            text: `${this.props.auth.userId} (${this.props.auth.fullname}) user has entered the site.`,
          }),
        }
      )
    }
  }

  render() {
    return (
      <ScrollTop>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/:lang(ru|en)/" component={HomePage} />
          <Route exact path="/registration" component={RegistrationPage} />
          <Route
            exact
            path="/:lang(ru|en)/registration"
            component={RegistrationPage}
          />
          <Route exact path="/hosting-packages" component={HostingPage} />
          <Route
            exact
            path="/:lang(ru|en)/hosting-packages"
            component={HostingPage}
          />
          <Route exact path="/vps-packages" component={VpsPage} />
          <Route exact path="/:lang(ru|en)/vps-packages" component={VpsPage} />

          <Route exact path="/ssl-packages" component={SslPage} />
          <Route exact path="/:lang(ru|en)/ssl-packages" component={SslPage} />

          <Route
            exact
            path="/constructor-packages"
            component={ConstructorPage}
          />
          <Route
            exact
            path="/:lang(ru|en)/constructor-packages"
            component={ConstructorPage}
          />
          <Route exact path="/support" component={SupportPage} />
          <Route exact path="/:lang(ru|en)/support" component={SupportPage} />

          <Route exact path="/domain-registration" component={DomainsPage} />
          <Route
            exact
            path="/:lang(ru|en)/domain-registration"
            component={DomainsPage}
          />

          <Route
            exact
            path="/domain-settings"
            component={DomainConfigurationPage}
          />
          <Route
            exact
            path="/:lang(ru|en)/domain-settings"
            component={DomainConfigurationPage}
          />
          <Route exact path="/order-details" component={OrderDetailsPage} />
          <Route
            exact
            path="/:lang(ru|en)/order-details"
            component={OrderDetailsPage}
          />

          <Route exact path="/product-details" component={ProductDetailsPage} />
          <Route
            exact
            path="/:lang(ru|en)/product-details"
            component={ProductDetailsPage}
          />

          <Route exact path="/about-us" component={AboutUsPage} />
          <Route exact path="/:lang(ru|en)/about-us" component={AboutUsPage} />

          <Route exact path="/special-terms" component={SpecialTermsPage} />
          <Route
            exact
            path="/:lang(ru|en)/special-terms"
            component={SpecialTermsPage}
          />

          <Route exact path="/contact-us" component={ContactsPage} />
          <Route
            exact
            path="/:lang(ru|en)/contact-us"
            component={ContactsPage}
          />

          <Route exact path="/reset-password" component={ResetPasswordPage} />
          <Route
            exact
            path="/:lang(ru|en)/reset-password"
            component={ResetPasswordPage}
          />

          <Route exact path="/email-verify" component={ConfirmEmailPage} />
          <Route
            exact
            path="/:lang(ru|en)/email-verify"
            component={ConfirmEmailPage}
          />

          <Route
            exact
            path="/ssl-configuration"
            component={SSLConfigurationPage}
          />
          <Route
            exact
            path="/:lang(ru|en)/ssl-configuration"
            component={SSLConfigurationPage}
          />

          <ProtectedRoute
            exact
            path="/open-ticket"
            component={OpenTicketPage}
          />
          <ProtectedRoute
            exact
            path="/:lang(ru|en)/open-ticket"
            component={OpenTicketPage}
          />
          <ProtectedRoute
            exact
            path="/client-area"
            component={ClientAreaPage}
          />

          <ProtectedRoute
            exact
            path="/:lang(ru|en)/client-area"
            component={ClientAreaPage}
          />
          <ProtectedRoute
            exact
            path="/edit-profile"
            component={EditProfilePage}
          />
          <ProtectedRoute
            exact
            path="/:lang(ru|en)/edit-profile"
            component={EditProfilePage}
          />
          <ProtectedRoute exact path="/invoice" component={InvoicePage} />
          <ProtectedRoute
            exact
            path="/:lang(ru|en)/invoice"
            component={InvoicePage}
          />

          <ProtectedRoute
            exact
            path="/enable-2fa"
            component={TwoFactorAuthEnablePage}
          />
          <ProtectedRoute
            exact
            path="/:lang(ru|en)/enable-2fa"
            component={TwoFactorAuthEnablePage}
          />
          <ProtectedRoute
            exact
            path="/credit-recharge"
            component={CreditRechargePage}
          />
          <ProtectedRoute
            exact
            path="/:lang(ru|en)/credit-recharge"
            component={CreditRechargePage}
          />
          <ProtectedRoute
            exact
            path="/payment-failed/:id/:data"
            component={PaymentFailPage}
          />
          <ProtectedRoute
            exact
            path="/:lang(ru|en)/payment-failed/:id/:data"
            component={PaymentFailPage}
          />
          <Route
            exact
            path="/:lang(ru|en)/payment-success"
            component={PaymentSuccessPage}
          />
          <Route exact path="/payment-success" component={PaymentSuccessPage} />
          <Route
            exact
            path="/:lang(ru|en)/telcell-payment"
            component={TelcellPaymentPage}
          />
          <Route exact path="/telcell-payment" component={TelcellPaymentPage} />

          <ProtectedRoute
            exact
            path="/:lang(ru|en)/ssl-configuration/:orderId/:id/:step"
            component={SslConfigurationPage}
          />
          <ProtectedRoute
            exact
            path="/ssl-configuration/:orderId/:id/:step"
            component={SslConfigurationPage}
          />
          <ProtectedRoute
            exact
            path="/:lang(ru|en)/service-details/:id"
            component={ServiceDetails}
          />
          <ProtectedRoute
            exact
            path="/service-details/:id"
            component={ServiceDetails}
          />
          <Route path="*" component={ErrorPage} />
        </Switch>
      </ScrollTop>
    )
  }
}

const mapStateToProps = ({ app }) => ({
  auth: app.auth,
})

const mapDispatchToProps = {
  showModal,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes))
