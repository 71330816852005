import { CLIENT_AREA_TABS } from '../../ClientAreaPage.constants';

export const headers = [
  {
    id: 'domainsHeader',
    tab: CLIENT_AREA_TABS.DOMAINS,
    sortNumber: 0,
    sortType: 'text',
    title: 'domainsTable.domain',
    sortBy: 'domainname',
  },
  {
    id: 'regHeader',
    tab: CLIENT_AREA_TABS.DOMAINS,
    sortNumber: 1,
    sortType: 'number',
    title: 'domainsTable.regData',
    sortBy: 'regdate',    
  },
  {
    id: 'paymentHeader',
    tab: CLIENT_AREA_TABS.DOMAINS,
    sortNumber: 2,
    sortType: 'number',
    title: 'domainsTable.expiryDate',
    sortBy: 'expirydate'
  },
  {
    id: 'statusHeader',
    tab: CLIENT_AREA_TABS.DOMAINS,
    sortNumber: 3,
    sortType: 'text',
    title: 'domainsTable.status',
    sortBy: 'status',
    isMarginNeed: true
  },
];
