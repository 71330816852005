import React, { useCallback, useEffect, useState, useRef } from 'react'
import qs from 'qs'
import { Formik } from 'formik'
import { Helmet } from 'react-helmet'

import { LayoutCommon } from '../../layouts/LayoutCommon'
import { initialValues, yupScheme } from './ProductDetailsPage.config'
import * as S from './ProductDetailsPage.styles'
import { i18n } from '../../i18n'

import { changeUrl } from '../../hooks/changeUrl'

const ProductDetailsPage = ({
  history,
  domains,
  updateCart,
  removeFromCart,
  cart,
}) => {
  const [domainOptions, setDomainOptions] = useState([])
  const inputRef = useRef(null)

  useEffect(() => {
    const userDomains = domains
      ? domains.map((item) => ({
          value: item.domainname,
          label: item.domainname,
        }))
      : []

    const cartDomains = cart
      .filter((item) => item.type === 'domain')
      .map((item) => ({
        value: `${item.domain}${item.tld}`,
        label: `${item.domain}${item.tld}`,
      }))

    const selectDomains = [...userDomains, ...cartDomains]
    setDomainOptions(selectDomains)
  }, [domains, setDomainOptions, cart])

  useEffect(() => {
    const params = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
      encode: true,
    })
    const match = cart.find((item) => item._id === params.id)
    if (!match) {
      history.push(changeUrl('/'))
    }
  }, [history, cart])

  const handleNextButton = useCallback(
    (v) => {
      const params = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
        encode: true,
      })
      const match = cart.find((item) => item._id === params.id)

      const domainParts = v.split('.')
      const domain = domainParts[0]
      domainParts.shift()
      const tld = `.${domainParts.join('.')}`

      const sameProductExistsInCart = cart.find(
        (item) =>
          ['hosting', 'constructor'].includes(item.type) &&
          item.tld === tld &&
          item.domain === domain
      )

      if (sameProductExistsInCart) {
        removeFromCart(params.id)
        return history.push(changeUrl('/order-details'))
      }

      updateCart(match, {
        domain,
        tld,
      })
      history.push(changeUrl('/order-details'))
    },
    [cart, updateCart, history, removeFromCart]
  )

  const handleBlur = useCallback(
    async (event, setFieldValue, setFieldError, setFieldTouched) => {
      if (!event.target.value) {
        return
      }
      setFieldTouched('domain', true)
      const domain =
        typeof event.target.value === 'object'
          ? event.target.value.value
          : event.target.value
      const success = await yupScheme.isValid({ domain })
      if (!success) {
        return setFieldError('domain', 'Please enter a valid domain.')
      }
      setDomainOptions([...domainOptions, { value: domain, label: domain }])
      setFieldValue('domain', domain)
      setFieldError('domain', null)
    },
    [domainOptions, setDomainOptions]
  )

  return (
    <LayoutCommon>
      <Helmet>
        <title>{i18n.t('headers.productDetailsPage')}</title>
        <meta
          name="description"
          content={i18n.t('headersDescriptions.productDetailsPage')}
        />
        <meta name="og:title" content={i18n.t('headers.productDetailsPage')} />
        <meta
          name="og:description"
          content={i18n.t('headersDescriptions.productDetailsPage')}
        />
        <meta
          name="og:image"
          content="https://internet.am/images/fb_logo.png"
        />
      </Helmet>
      <S.PageBody>
        <S.DomainBodyContainer>
          <S.DomainTitle>{i18n.t('productDetails.title')}</S.DomainTitle>
          {cart.map((item) =>
            item.type === 'hosting' &&
            item._id ===
              qs.parse(history.location.search, {
                ignoreQueryPrefix: true,
                encode: true,
              }).id ? (
              <>
                <S.DomainHeader>
                  {i18n.t('productDetails.header')}
                </S.DomainHeader>
                <S.SubHeader>
                  {i18n.t('header.hosting')} {item.name}
                </S.SubHeader>
              </>
            ) : item.type === 'constructor' &&
              item._id ===
                qs.parse(history.location.search, {
                  ignoreQueryPrefix: true,
                  encode: true,
                }).id ? (
              <>
                <S.DomainHeader>
                  {i18n.t('productDetails.header')}
                </S.DomainHeader>
                <S.SubHeader>
                  {i18n.t('header.constructor')} {item.name}
                </S.SubHeader>
              </>
            ) : null
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={yupScheme}
            onSubmit={async (values, { setSubmitting }) => {
              handleNextButton(values.domain)
              setSubmitting(false)
            }}
          >
            {({
              values,
              setFieldValue,
              setFieldError,
              setFieldTouched,
              isSubmitting,
              errors,
              touched,
            }) => (
              <>
                <S.FormContainer>
                  <S.DomainInput
                    ref={inputRef}
                    isSearchable
                    isClearable
                    name="domain"
                    placeholder="example.com"
                    options={domainOptions}
                    value={domainOptions.find(
                      (item) => item.label === values.domain
                    )}
                    onChange={(option) =>
                      setFieldValue('domain', option ? option.value : '')
                    }
                    onBlur={(e) =>
                      handleBlur(
                        e,
                        setFieldValue,
                        setFieldError,
                        setFieldTouched
                      )
                    }
                    classNamePrefix="react-select"
                  />

                  <S.DomainSearcherButton type="submit" disabled={isSubmitting}>
                    {i18n.t('productDetails.nextButton')}
                  </S.DomainSearcherButton>
                </S.FormContainer>
                {touched.domain && errors.domain && (
                  <S.ErrorMessage spaced>
                    {i18n.t(errors.domain)}
                  </S.ErrorMessage>
                )}
              </>
            )}
          </Formik>
        </S.DomainBodyContainer>
      </S.PageBody>
    </LayoutCommon>
  )
}

export { ProductDetailsPage }
