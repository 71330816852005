import styled from 'styled-components'

export const ReasonSideFiller = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 100px 25px;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 768px) {
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }
  @media (min-width: 1280px) {
    justify-content: center;
  }
`

export const NumberOne = styled.div`
  color: #242e46;
  ${
    '' /* font-style: normal;
  font-weight: normal;
  font-size: 200px; */
  }
  display: flex;
  justify-content: center;
  align-items: center;
  order: 2;

  img {
    margin-top: 40px;
    width: 253px;
    height: 143px;
  }

  @media (min-width: 768px) {
    order: 2;
  }
  @media (min-width: 1280px) {
    order: unset;
    margin-right: 200px;
    margin-top: 50px;
  }
`
export const Reasons = styled.div`
  width: 367px;
  height: 305px;
  background: yellow;
  order: 1;
  @media (min-width: 768px) {
    order: unset;
  }
  @media (min-width: 769px) {
    order: 1;
  }
  @media (min-width: 1280px) {
    order: unset;
  }
`
export const ReasonsList = styled.ul``

export const ReasonsListItem = styled.li`
  margin: 9px 5px;
  padding-bottom: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  list-style: none;
  color: #242e46;

  color: #242e46;
  > span {
    padding-left: 18px;
    color: #242e46;
  }

  &::before {
    color: #242e46;
    content: ' ';
    margin-top: 4px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #242e46;
    font-weight: bold;
    display: inline-block;
    margin-left: -10px;
  }

  @media (min-width: 768px) {
    font-size: 16px;
  }
`

export const ReasonsTitle = styled.div`
  font-size: 24px;
  text-align: center;
  color: #242e46;
  font-weight: 500;

  @media (min-width: 768px) {
    text-align: unset;
  }
  @media (min-width: 1280px) {
  }
`

export const ReasonValue = styled.li`
  margin: 0px 5px 30px;
  font-style: normal;
  font-size: 24px;
  list-style: none;

  &:last-child {
    font-weight: bold;
    margin-bottom: 0px;
  }

  color: #242e46;
  > span {
    padding-left: 18px;
    color: #242e46;
  }

  &::before {
    color: #242e46;
    content: ' ';
    margin-top: 4px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #242e46;
    font-weight: bold;
    display: inline-block;
    margin-left: -30px;
  }
  @media (min-width: 768px) {
    &::before {
      margin-left: -10px;
    }
  }
`

export const ReasonKey = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: #212121;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1280px) {
    font-size: 16px;
  }
`

export const ReasonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  flex: 0 50%;
  padding: 20px;

  padding-bottom: ${(p) => (p.setPadding ? '47px' : '0px')};

  @media (min-width: 768px) {
    flex: 1;
    padding-bottom: 0px;
  }
`

export const ReasonsListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  &:first-child {
    margin-top: 0px;
  }

  @media (min-width: 768px) {
    padding-left: 0px;
  }
`

export const ReasonsContainer = styled.div`
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
`
