import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const AccountPopup = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 150px;
  max-height: 300px;
  border-top: 1px solid #212121;
  top: 56px;
  right: 0px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  border-radius: 0px 0px 5px 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  @media (min-width: 768px) {
    right: -25px;
    left: unset;
    top: 77px;
  }
  @media (min-width: 1280px) {
    right: 0;
  }
`;
export const Id = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #242e46;
  text-align: center;
  padding: 15px 0px;
  margin: 5px 0px;
`;
export const AccountLink = styled(Link)`
  font-weight: normal;
  font-size: 14px;
  color: #242e46;
  text-align: center;
  padding: 15px 0px;
  margin: 5px 0px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
`;
export const LogoutButton = styled.button`
  font-weight: normal;
  font-size: 14px;
  color: #242e46;
  text-align: center;
  padding: 15px 0px;
  margin: 5px 0px;

  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
`;
