import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

import { RenewDomainModal as Self } from './RenewDomainModal';
import { renewDomain } from './RenewDomainModal.actions';

const mapStateToProps = ({ renewDomains }) => ({
  isRenewing: renewDomains.isRenewing,
});

const mapDispatchToProps = {
  renewDomain,
};

export const RenewDomainModal = withRouter(withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
));
