import React from 'react'

import * as S from './InvoicesTable.styles'
import { i18n } from '../../../../i18n'
import { headers } from './InvoicesTable.constants'
import { FilterPopup } from '../FilterPopup'
import { Sort } from '../SortPopup'
import { MobileSortPopup } from '../MobileSortPopup'
import { SettingsPopup } from './components/SettingsPopup'
import { Loader } from '../../../../components/Loader'
import { Pagination } from '../Pagination'
import { changeUrl } from '../../../../hooks/changeUrl'

const InvoicesTable = ({
  toggleFilterPopup,
  showSortPopup,
  data,
  total,
  statuses,
  showSettingsPopup,
  loading,
  numberWithCommas,
  filter,
  getInvoices,
  offsets,
  sortField,
  sortOrder,
  sortType,
  auth,
}) => {
  if (loading) {
    return (
      <S.EmptyStateContainer>
        <Loader color={'#00C084'} size={0.5} />
      </S.EmptyStateContainer>
    )
  }
  if (!data.length && !filter) {
    return <S.EmptyStateContainer>No records found</S.EmptyStateContainer>
  }

  return (
    <>
      <S.CartBlock>
        <S.Headers>
          {headers.map((header) => (
            <S.Header
              key={header.id}
              // style={header.isPaddingNeed && { paddingLeft: "0px" }}
            >
              <S.CorrectionWrapper>
                <S.Wrapper>
                  <S.Title>{i18n.t(header.title)}</S.Title>
                  <Sort
                    cb={getInvoices}
                    sortType={header.sortType}
                    sortNumber={header.sortNumber}
                    sortBy={header.sortBy}
                    sortOrder={sortField === header.sortBy && sortOrder}
                    offset={offsets.invoices}
                    sortField={sortField}
                  />
                </S.Wrapper>
              </S.CorrectionWrapper>
            </S.Header>
          ))}
          <S.SortMin
            id="desktopSort"
            onClick={() => showSortPopup(headers.sortNumber, headers)}
          />
          <MobileSortPopup
            cb={getInvoices}
            fields={headers}
            sortBy={sortField}
            sortOrder={sortOrder}
            offset={offsets.invoices}
          />
          <FilterPopup options={statuses} filterType="invoices" />
          <S.Filter id="desktopFilter" onClick={toggleFilterPopup} />
        </S.Headers>
        {data.map((item) => (
          <S.CartContent key={item.id}>
            <S.InvoiceData>
              <S.StyledLink
                to={changeUrl(`/invoice?id=${item.id}`)}
                target="_blank"
              >
                {item.id}
              </S.StyledLink>
              <S.InvoiceSum isAmd={auth.currency === 1}>
                {numberWithCommas(item.subtotal)}
              </S.InvoiceSum>
              <S.DateWrapper>
                <S.StartDate>{item.date}</S.StartDate>
                <S.EndDate>
                  {item.updated_at === '0000-00-00' || item.status === 'Unpaid'
                    ? ''
                    : item.updated_at.split(' ')[0]}
                </S.EndDate>
              </S.DateWrapper>
              <S.Status>
                {i18n.t(`backend.invoiceStatuses.${item.status}`)}
              </S.Status>
            </S.InvoiceData>
            <SettingsPopup popupId={item.id} id={item.id} />
            <S.CartSettingsIcon onClick={() => showSettingsPopup(item.id)} />
          </S.CartContent>
        ))}
      </S.CartBlock>
      <Pagination
        goToPage={(offset) => getInvoices(offset, sortOrder, sortField)}
        totalResults={total}
        currentOffset={offsets.invoices}
      />
      {/* <S.BottomButtonsContainer>
        <S.ActionsButton>
          {i18n.t('clientAreaPage.actionButton')}
          <S.ArrowIcon />
        </S.ActionsButton>
        <S.AddServiceButton>
          {i18n.t('clientAreaPage.addServiceButton')}
        </S.AddServiceButton>
      </S.BottomButtonsContainer> */}
    </>
  )
}

export { InvoicesTable }
