import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { MobileMenu as Self } from './MobileMenu';
import { hideMobileMenu } from '../Header.actions';
import { logout } from '../../../common/App/App.actions';
import { toggleLanguagesPopup } from '../../../common/App/App.actions';
import { changeBackendLanguage } from '../../../common/App/App.actions';

const mapStateToProps = ({ app, header }) => ({
  auth: app.auth,
  dimensions: app.dimensions,
  isExpanded: header.isExpanded,
});

const mapDispatchToProps = {
  hideMobileMenu,
  logout,
  toggleLanguagesPopup,
  changeBackendLanguage,
};

export const MobileMenu = withTranslation()(connect(
  mapStateToProps, mapDispatchToProps)(Self)
);
