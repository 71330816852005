import styled from 'styled-components'
import closeModal from '../../../images/closeModal.svg'

export const HostingModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 1;
  z-index: 100;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
  @media (min-width: 768px) {
    padding: 23px 42px;
    margin-left: 0px;
    margin-right: 0px;
  }
`

export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-size: cover;

  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`

export const Modal = styled.div`
  height: 650px;
  overflow-y: scroll;
  width: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  outline: none;
  z-index: 100;

  @media (min-width: 768px) {
    width: auto;
  }
`
export const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #484857;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 28px;
  }
  @media (min-width: 1280px) {
    line-height: 29px;
  }
`

export const ModalHeader = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #737373;
  margin-top: 25px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 21px;
  }
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  @media (min-width: 768px) {
    margin-top: 63px;
  }
  @media (min-width: 1280px) {
    margin-top: 76px;
  }
`
export const Block = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  margin-bottom: 20px;
  @media (min-width: 1280px) {
    display: flex;
    flex-direction: row;
  }
`
export const PackageParagraph = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #595ba8;
  margin-top: 8px;
  @media (min-width: 768px) {
    margin-top: 20px;
    font-size: 32px;
    line-height: 39px;
  }
  @media (min-width: 768px) {
    margin-top: 21px;
  }
  @media (min-width: 1280px) {
    margin-top: 39px;
    margin-left: 70px;
    position: absolute;
  }
`
export const Details = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  @media (min-width: 1280px) {
    align-self: flex-end;
    margin-left: 50px;
  }
`

export const DetailContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    width: unset;
    padding-left: 20px;
    padding-right: 20px;
    width: 18%;
  }
`

export const ValueContainer = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  color: #393939;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 28px;
  }
`

export const KeyContainer = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #393939;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 19px;
  }
`

export const PriceChooseContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (min-width: 1280px) {
    flex-direction: column;
    width: auto;
  }
`

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  width: 50%;
  @media (min-width: 1280px) {
    width: auto;
    padding: 36px 0px;
  }
`

export const PriceValue = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  font-family: sans-serif;
  text-align: center;
  color: #393939;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 23px;
  }
`

export const PriceKey = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #393939;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 23px;
  }
`

export const ChooseButton = styled.button`
  border-radius: 0px 0px 5px 0px;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  background-color: ${(p) => p.color};
  width: 50%;
  padding: 31px 35px;
  border: none;
  @media (min-width: 768px) {
    padding: 27px 0px;
    font-size: 24px;
    line-height: 28px;
  }
  @media (min-width: 1280px) {
    padding: 45px 67px;
    width: auto;
  }
`
