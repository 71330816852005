import { cloneDeep } from 'lodash'

import { APP_TYPES } from './App.types'
import { INVOICE_TYPES } from '../../pages/InvoicePage/InvoicePage.types'

const initialState = {
  dimensions: {
    width: 1028,
    height: 768,
  },
  auth: {},
  cartIsShown: false,
  languagesPopupIsShown: false,
  accountPopupIsShown: false,
  cart: [],
  addOrderLoading: false,
  addOrderError: null,
  userInfo: {},
  userInfoLoading: false,
  userInfoError: null,
  contactId: null,
  credit: {},
  userIp: null,
}

export const app = (state = initialState, action) => {
  switch (action.type) {
    case APP_TYPES.SET_WINDOW_DIMENSIONS: {
      return {
        ...state,
        dimensions: action.dimensions,
      }
    }
    case APP_TYPES.SET_AUTH_DATA: {
      return {
        ...state,
        auth: {
          token: action.token,
          email: action.email,
          fullname: action.fullname,
          userId: action.userId,
          address: action.address,
          currency: action.currency,
          credit: action.credit,
          creditRecharge: action.creditRecharge,
          legalStatus: action.legalStatus,
          disableCode: action.disableCode,
        },
      }
    }
    case APP_TYPES.SET_CART: {
      return {
        ...state,
        cart: action.cart,
      }
    }
    case APP_TYPES.ADD_TO_CART: {
      return {
        ...state,
        cart: [...state.cart, action.product],
      }
    }
    case APP_TYPES.REMOVE_FROM_CART: {
      const cartCopy = cloneDeep(state.cart)
      const productIndex = cartCopy.findIndex(
        (item) => item._id === action.product._id
      )
      cartCopy.splice(productIndex, 1)
      return {
        ...state,
        cart: cartCopy,
      }
    }
    case APP_TYPES.UPDATE_CART: {
      const cartCopy = cloneDeep(state.cart)
      const productIndex = cartCopy.findIndex(
        (item) => item._id === action.product._id
      )
      for (let objKey of Object.keys(action.obj)) {
        cartCopy[productIndex][objKey] = action.obj[objKey]
      }
      return {
        ...state,
        cart: cartCopy,
      }
    }
    case APP_TYPES.TOGGLE_CART: {
      return {
        ...state,
        cartIsShown: !state.cartIsShown,
      }
    }
    case APP_TYPES.SHOW_CART: {
      return {
        ...state,
        cartIsShown: action.status,
      }
    }
    case APP_TYPES.SET_DOMAIN_NAMESERVERS: {
      const cartCopy = cloneDeep(state.cart)
      const domainIndex = cartCopy.findIndex((item) => item._id === action.id)
      cartCopy[domainIndex].nameservers = action.nameservers
      return {
        ...state,
        cart: cartCopy,
      }
    }
    case APP_TYPES.SET_DOMAIN_IPS: {
      const cartCopy = cloneDeep(state.cart)
      const domainIndex = cartCopy.findIndex((item) => item._id === action.id)
      cartCopy[domainIndex].ips = action.ips
      return {
        ...state,
        cart: cartCopy,
      }
    }
    case APP_TYPES.SET_DOMAIN_CONTACT: {
      return {
        ...state,
        contactId: action.contactId,
      }
    }
    case APP_TYPES.TOGGLE_LANGUAGES_POPUP: {
      return {
        ...state,
        languagesPopupIsShown: !state.languagesPopupIsShown,
      }
    }
    case APP_TYPES.SHOW_LANGUAGES_POPUP: {
      return {
        ...state,
        languagesPopupIsShown: action.status,
      }
    }
    case APP_TYPES.TOGGLE_ACCOUNT_POPUP: {
      return {
        ...state,
        accountPopupIsShown: !state.accountPopupIsShown,
      }
    }
    case APP_TYPES.SHOW_ACCOUNT_POPUP: {
      return {
        ...state,
        accountPopupIsShown: action.status,
      }
    }

    case APP_TYPES.ADD_ORDER_REQUEST: {
      return {
        ...state,
        addOrderLoading: true,
        addOrderError: null,
      }
    }
    case APP_TYPES.ADD_ORDER_SUCCESS: {
      return {
        ...state,
        cart: [],
        addOrderLoading: false,
        addOrderError: null,
      }
    }
    case APP_TYPES.ADD_ORDER_FAIL: {
      return {
        ...state,
        addOrderLoading: false,
        addOrderError: action.error,
      }
    }
    case APP_TYPES.GET_USER_INFO_REQUEST:
      return {
        ...state,
        userInfoLoading: true,
        userInfoError: null,
        userInfo: {},
      }
    case APP_TYPES.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfoLoading: false,
        userInfo: action.userInfo,
        userInfoError: null,
      }
    case APP_TYPES.GET_USER_INFO_FAIL:
      return {
        ...state,
        userInfoLoading: false,
        userInfo: {},
        userInfoError: action.error,
      }
    case APP_TYPES.GET_USER_CREDIT_REQUEST:
      return {
        ...state,
        credit: {},
      }
    case APP_TYPES.GET_USER_CREDIT_SUCCESS:
      return {
        ...state,
        credit: action.credit,
      }
    case APP_TYPES.GET_USER_CREDIT_FAIL:
      return {
        ...state,
        credit: {},
      }
    case INVOICE_TYPES.APPLY_CREDIT_SUCCESS: {
      const credit = +state.userInfo.credit - action.amount
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          credit,
        },
      }
    }
    case APP_TYPES.LOGOUT: {
      return { ...cloneDeep(initialState) }
    }
    case APP_TYPES.GET_USER_IP: {
      return {
        ...state,
        userIp: action.userIp,
      }
    }
    default:
      return state
  }
}
