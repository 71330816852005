import { CLIENT_AREA_TYPES } from './ClientAreaPage.types'
import { VIEW_TICKET_TYPES } from '../../components/Modals/ViewTicketModal/ViewTicketModal.types'
import { CLIENT_AREA_TABS } from './ClientAreaPage.constants'

const initialState = {
  tab: CLIENT_AREA_TABS.DOMAINS,
  filterPopupIsShown: false,
  sortPopupIsShown: -1,
  settingsPopupIsShown: -1,
  domainsLoading: false,
  domainsRequestSuccess: false,
  domainsData: [],
  domainsTotal: 0,
  domainStatuses: [],
  domainsRequestError: null,
  servicesLoading: false,
  servicesRequestSuccess: false,
  servicesData: [],
  servicesTotal: 0,
  servicesStatuses: [],
  servicesRequestError: null,
  ticketsLoading: false,
  ticketsRequestSuccess: false,
  ticketsData: [],
  ticketsTotal: 0,
  ticketsStatuses: [],
  ticketsRequestError: null,
  invoicesLoading: false,
  invoicesRequestSuccess: false,
  invoicesData: [],
  invoicesTotal: 0,
  invoicesStatuses: [],
  invoicesRequestError: null,
  domainContactsLoading: false,
  domainContacts: {},
  transformedContacts: {},
  domainContactsError: false,
  domainContactModalTab: 'billing',
  updateDomainContactsLoading: false,
  updateDomainContactsError: null,
  changeProtectionLoading: false,
  changeProtectionError: null,
  getServiceDetailsLoading: false,
  getServiceDetailsError: null,
  serviceDetails: null,

  filters: {
    domains: null,
    services: null,
    tickets: null,
    invoices: null,
  },
  offsets: {
    domains: 0,
    services: 0,
    tickets: 0,
    invoices: 0,
  },
  sortField: {
    domains: null,
    services: null,
    tickets: null,
    invoices: null,
  },
  sortOrder: {
    domains: null,
    services: null,
    tickets: null,
    invoices: null,
  },
}

function clientAreaPage(state = initialState, action) {
  switch (action.type) {
    case CLIENT_AREA_TYPES.CHANGE_TAB:
      return {
        ...state,
        tab: action.tab,
      }

    case CLIENT_AREA_TYPES.TOGGLE_FILTER_POPUP: {
      return {
        ...state,
        filterPopupIsShown: !state.filterPopupIsShown,
      }
    }
    case CLIENT_AREA_TYPES.SET_INITIAL_PARAMS: {
      return {
        ...state,
        offsets: initialState.offsets,
      }
    }
    case CLIENT_AREA_TYPES.SHOW_FILTER_POPUP: {
      return {
        ...state,
        filterPopupIsShown: action.status,
      }
    }
    case CLIENT_AREA_TYPES.TOGGLE_SETTINGS_POPUP: {
      return {
        ...state,
        settingsPopupIsShown: -1,
      }
    }
    case CLIENT_AREA_TYPES.SHOW_SETTINGS_POPUP: {
      return {
        ...state,
        settingsPopupIsShown: action.popupId,
      }
    }
    case CLIENT_AREA_TYPES.TOGGLE_SORT_POPUP: {
      return {
        ...state,
        sortPopupIsShown: -1,
      }
    }
    case CLIENT_AREA_TYPES.SHOW_SORT_POPUP: {
      return {
        ...state,
        sortPopupIsShown: action.sortNumber,
        fields: action.fields,
      }
    }
    case CLIENT_AREA_TYPES.GET_DOMAINS_REQUEST:
      return {
        ...state,
        domainsLoading: true,
        domainsRequestSuccess: false,
        domainsData: [],
        domainsRequestError: null,
        domainsTotal: 0,
        domainsStatuses: [],
      }
    case CLIENT_AREA_TYPES.GET_DOMAINS_SUCCESS: {
      const domainsData = action.data.map((item) => ({
        ...item,
        type: 'domain',
      }))
      return {
        ...state,
        domainsLoading: false,
        domainsRequestSuccess: true,
        domainsData,
        domainsTotal: action.total,
        domainsRequestError: null,
        domainsStatuses: action.domainsStatuses,
        offsets: {
          ...state.offsets,
          domains: action.offset,
        },
        sortField: {
          ...state.sortField,
          domains: action.sortBy,
        },
        sortOrder: {
          ...state.sortOrder,
          domains: action.sortOrder,
        },
      }
    }

    case CLIENT_AREA_TYPES.GET_DOMAINS_FAIL:
      return {
        ...state,
        domainsLoading: false,
        domainsRequestSuccess: false,
        domainsData: [],
        domainsRequestError: action.error,
        domainsTotal: 0,
        domainsStatuses: [],
      }
    case CLIENT_AREA_TYPES.GET_SERVICES_REQUEST:
      return {
        ...state,
        servicesLoading: true,
        servicesRequestSuccess: false,
        servicesData: [],
        servicesRequestError: null,
        servicesTotal: 0,
        servicesStatuses: [],
      }

    case CLIENT_AREA_TYPES.GET_SERVICES_SUCCESS: {
      const servicesData = action.data.map((item) => ({
        ...item,
        type: 'service',
      }))
      return {
        ...state,
        servicesLoading: false,
        servicesRequestSuccess: true,
        servicesData,
        servicesTotal: action.total,
        servicesRequestError: null,
        servicesStatuses: action.servicesStatuses,
        offsets: {
          ...state.offsets,
          services: action.offset,
        },
        sortField: {
          ...state.sortField,
          services: action.sortBy,
        },
        sortOrder: {
          ...state.sortOrder,
          services: action.sortOrder,
        },
      }
    }
    case CLIENT_AREA_TYPES.GET_SERVICES_FAIL:
      return {
        ...state,
        servicesLoading: false,
        servicesRequestSuccess: false,
        servicesData: [],
        servicesRequestError: action.error,
        servicesTotal: 0,
        servicesStatuses: [],
      }
    case CLIENT_AREA_TYPES.GET_TICKETS_REQUEST:
      return {
        ...state,
        ticketsLoading: true,
        ticketsRequestSuccess: false,
        ticketsData: [],
        ticketsRequestError: null,
        ticketsTotal: 0,
        ticketsStatuses: [],
      }

    case CLIENT_AREA_TYPES.GET_TICKETS_SUCCESS: {
      const ticketsData = action.data.map((item) => ({
        ...item,
        type: 'ticket',
      }))
      return {
        ...state,
        ticketsLoading: false,
        ticketsRequestSuccess: true,
        ticketsData,
        ticketsTotal: action.total,
        ticketsRequestError: null,
        ticketsStatuses: action.ticketsStatuses,
        offsets: {
          ...state.offsets,
          tickets: action.offset,
        },
        sortField: {
          ...state.sortField,
          tickets: action.sortBy,
        },
        sortOrder: {
          ...state.sortOrder,
          tickets: action.sortOrder,
        },
      }
    }
    case CLIENT_AREA_TYPES.GET_TICKETS_FAIL:
      return {
        ...state,
        ticketsLoading: false,
        ticketsRequestSuccess: false,
        ticketsData: [],
        ticketsRequestError: action.error,
        ticketsTotal: 0,
        ticketsStatuses: [],
      }
    case VIEW_TICKET_TYPES.CLOSE_TICKET_SUCCESS: {
      const ticketsData = [...state.ticketsData]
      const ticketMatch = ticketsData.findIndex(
        (item) => item.id === action.ticketId
      )
      ticketsData[ticketMatch].status = 'Closed'
      return {
        ...state,
        ticketsData,
      }
    }
    case CLIENT_AREA_TYPES.GET_INVOICES_REQUEST:
      return {
        ...state,
        invoicesLoading: true,
        invoicesRequestSuccess: false,
        invoicesData: [],
        invoicesRequestError: null,
        invoicesTotal: 0,
        invoiceStatuses: [],
      }

    case CLIENT_AREA_TYPES.GET_INVOICES_SUCCESS: {
      const invoicesData = action.data.map((item) => ({
        ...item,
        type: 'invoice',
      }))
      return {
        ...state,
        invoicesLoading: false,
        invoicesRequestSuccess: true,
        invoicesData,
        invoicesTotal: action.total,
        invoicesRequestError: null,
        invoicesStatuses: action.invoicesStatuses,
        offsets: {
          ...state.offsets,
          invoices: action.offset,
        },
        sortField: {
          ...state.sortField,
          invoices: action.sortBy,
        },
        sortOrder: {
          ...state.sortOrder,
          invoices: action.sortOrder,
        },
      }
    }
    case CLIENT_AREA_TYPES.GET_INVOICES_FAIL:
      return {
        ...state,
        invoicesLoading: false,
        invoicesRequestSuccess: false,
        invoicesData: [],
        invoicesRequestError: action.error,
        invoicesTotal: 0,
        invoicesStatuses: [],
      }
    case CLIENT_AREA_TYPES.SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.filterType]: action.filterValue,
        },
        offsets: {
          domains: 0,
          services: 0,
          tickets: 0,
          invoices: 0,
        },
      }
    case CLIENT_AREA_TYPES.GET_DOMAIN_CONTACTS_REQUEST:
      return {
        ...state,
        domainContactsLoading: true,
        domainContacts: {},
        domainContactsError: null,
      }
    case CLIENT_AREA_TYPES.GET_DOMAIN_CONTACTS_SUCCESS:
      return {
        ...state,
        domainContactsLoading: false,
        domainContacts: action.data,
        transformedContacts: action.transformedContacts,
        domainContactModalTab: action.transformedContacts.registrant,
        domainContactsError: null,
      }
    case CLIENT_AREA_TYPES.GET_DOMAIN_CONTACTS_FAIL:
      return {
        ...state,
        domainContactsLoading: false,
        domainContacts: {},
        domainContactsError: action.error,
      }
    case CLIENT_AREA_TYPES.SET_DOMAIN_CONTACTS_REQUEST:
      return {
        ...state,
        updateDomainContactsLoading: true,
        updateDomainContactsError: null,
      }
    case CLIENT_AREA_TYPES.SET_DOMAIN_CONTACTS_SUCCESS:
      return {
        ...state,
        updateDomainContactsLoading: false,
        updateDomainContactsError: null,
      }
    case CLIENT_AREA_TYPES.SET_DOMAIN_CONTACTS_FAIL:
      return {
        ...state,
        updateDomainContactsLoading: false,
        updateDomainContactsError: action.error,
      }
    case CLIENT_AREA_TYPES.CHANGE_DOMAIN_CONTACT_MODAL_TAB:
      return {
        ...state,
        domainContactModalTab: action.tabName,
      }
    case CLIENT_AREA_TYPES.CHANGE_ID_PROTECT_REQUEST:
      return {
        ...state,
        changeProtectionLoading: true,
        changeProtectionError: null,
      }
    case CLIENT_AREA_TYPES.CHANGE_ID_PROTECT_SUCCESS:
      const domainsData = [...state.domainsData]
      const domainIndex = domainsData.findIndex(
        (item) => item.id === action.domainId
      )
      domainsData[domainIndex].idprotection = action.protectId
      return {
        ...state,
        changeProtectionLoading: false,
        changeProtectionError: null,
        domainsData,
      }
    case CLIENT_AREA_TYPES.CHANGE_ID_PROTECT_FAIL:
      return {
        ...state,
        changeProtectionLoading: false,
        changeProtectionError: action.error,
      }
    case CLIENT_AREA_TYPES.GET_SERVICE_DETAILS_REQUEST:
      return {
        ...state,
        getServiceDetailsLoading: true,
      }
    case CLIENT_AREA_TYPES.GET_SERVICE_DETAILS_SUCCESS:
      return {
        ...state,
        getServiceDetailsLoading: false,
        serviceDetails: action.data,
      }
    case CLIENT_AREA_TYPES.GET_SERVICE_DETAILS_FAIL:
      return {
        ...state,
        getServiceDetailsLoading: false,
        getServiceDetailsError: action.error,
      }
    case CLIENT_AREA_TYPES.SET_SERVICE_DETAILS:
      return {
        ...state,
        serviceDetails: action.data,
      }
    default:
      return state
  }
}

export { clientAreaPage }
