import styled from 'styled-components'

export const ServiceDetailsPage = styled.div`
  width: 100%;
  ${'' /* height: 100vh; */}
  background: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
export const InfoBlock = styled.div`
  width: 100%;
  margin-top: 50px;
  margin-bottom: 200px;
  border: 1px solid #cccccc9c;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;

  @media (min-width: 920px) {
    width: 900px;
  }
`

export const Label = styled.div`
  width: 200px;
  padding: 20px 5px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 700;

  @media (min-width: 920px) {
    font-weight: 400;
  }
`

export const Description = styled.div`
  width: 100%;
  padding: 20px 0;
  word-break: break-all;
  padding: 20px 5px;
  box-sizing: border-box;
  font-size: 18px;
  padding: 20px;

  @media (min-width: 920px) {
    width: calc(100% - 200px);
    border-left: 1px solid #ccc;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  :not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
  @media (min-width: 920px) {
    flex-direction: row;
  }
`
export const CsrInput = styled.textarea`
  width: 100%;
  height: 200px;
  overflow-y: auto;
  resize: none;
  outline: none;
  padding: 10px;
  border: none;
  font-size: 15px;
  box-sizing: border-box;

  border: 1px solid #d4d4d4;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    white-space: pre-wrap;
    position: relative;
    width: 220px;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    white-space: pre-line;
    position: relative;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    white-space: pre-line;
    position: relative;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    white-space: pre-line;
    position: relative;
  }
`
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
`
export const Button = styled.button`
  width: 240px;
  outline: none;
  color: #fff;
  background: #242e46;
  border-radius: 3px;
  padding: 12px;
  box-sizing: border-box;
  margin-left: 5px;

  :not(:first-child) {
    margin-top: 10px;
  }

  @media (min-width: 500px) {
    :not(:first-child) {
      margin-top: 0px;
    }
  }

  @media (min-width: 768px) {
    width: 240px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 500px) {
    flex-direction: row;
  }
`

export const SslInfoBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
  border: 1px solid #cccccc9c;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;

  @media (min-width: 820px) {
    flex-direction: row;
    width: 900px;
  }
`
export const SslInfoRight = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
`

export const SslInfoLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #cccccc1c;
  border-radius: 5px;
  position: relative;
  height: 280px;

  @media (min-width: 400px) {
    width: 350px;
  }
`

export const SslInfoLabel = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #414141;
  text-align: center;

  :not(:first-child) {
    margin-top: 10px;
  }

  @media (min-width: 920px) {
    font-size: 22px;
    font-weight: 400;
  }
`

export const SslInfo = styled.div`
  margin-top: 10px;
`

export const Image = styled.img`
  width: 200px;
  height: auto;
  border: none;
`
export const Status = styled.div`
  width: 100%;
  background: #00c084;
  text-align: center;
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 0 0 5px 5px;
  position: absolute;
  bottom: 0px;
`
