import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { OpenTicketPage as Self } from './OpenTicketPage'
import { openTicket } from './OpenTicketPage.actions'
import { showModal } from '../../common/Modal/Modal.actions'
import { getUserIp } from '../../common/App/App.actions'

const mapStateToProps = ({ openTicketPage, app }) => ({
  loading: openTicketPage.loading,
  error: openTicketPage.error,
  userIp: app.userIp,
})

const mapDispatchToProps = {
  openTicket,
  showModal,
  getUserIp,
}

export const OpenTicketPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Self)
)
