import React from 'react';

export default (props) => (
  <svg
    {...props}
    width="36"
    height="32"
    viewBox="0 0 36 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M35.4 20.9999C35.7313 20.9999 36 20.7312 36 20.3999V15.5999C36 15.2685 35.7313 14.9999 35.4 14.9999H27.8484L31.6242 11.2241C31.8584 10.9898 31.8584 10.61 31.6242 10.3757L28.3458 7.09727C28.1115 6.86304 27.7317 6.86304 27.4974 7.09727C26.56 8.03469 25.04 8.03469 24.1026 7.09727C23.1652 6.15984 23.1652 4.63989 24.1026 3.70247C24.3368 3.46817 24.3368 3.08837 24.1026 2.85407L21.4242 0.175669C21.1899 -0.0585563 20.8101 -0.0585563 20.5758 0.175669L5.7516 14.9999H0.6C0.26865 14.9999 0 15.2685 0 15.5999V20.3999C0 20.7312 0.26865 20.9999 0.6 20.9999C1.92548 20.9999 3 22.0744 3 23.3999C3 24.7253 1.92548 25.7999 0.6 25.7999C0.26865 25.7999 0 26.0685 0 26.3999V31.1999C0 31.5312 0.26865 31.7999 0.6 31.7999H35.4C35.7313 31.7999 36 31.5312 36 31.1999V26.3999C36 26.0685 35.7313 25.7999 35.4 25.7999C34.0745 25.7999 33 24.7253 33 23.3999C33 22.0744 34.0745 20.9999 35.4 20.9999ZM21 1.44827L22.8648 3.31307C21.709 4.93082 22.0835 7.17924 23.7012 8.33507C24.953 9.22952 26.635 9.22952 27.8868 8.33507L30.3516 10.7999L26.1516 14.9999H24.2484L25.6242 13.6241C25.8584 13.3898 25.8584 13.01 25.6242 12.7757L19.0242 6.17567C18.7899 5.94144 18.4101 5.94144 18.1758 6.17567L9.3516 14.9999H7.4484L21 1.44827ZM22.5516 14.9999H11.0484L18.6 7.44827L24.3516 13.1999L22.5516 14.9999ZM31.8465 23.9966C32.1009 25.5099 33.2867 26.6957 34.8 26.9501V30.5999H30V28.1999H28.8V30.5999H7.2V29.9999H6V30.5999H1.2V26.9501C3.16073 26.6205 4.48305 24.7639 4.1535 22.8032C3.8991 21.2898 2.71343 20.1041 1.2 19.8497V16.1999H6V17.9999H7.2V16.1999H34.8V19.8497C32.8393 20.1792 31.517 22.0359 31.8465 23.9966Z" />
    <path d="M30.0008 17.3999H28.8008V19.7999H30.0008V17.3999Z" />
    <path d="M30.0008 20.9998H28.8008V23.3998H30.0008V20.9998Z" />
    <path d="M30.0008 24.5999H28.8008V26.9999H30.0008V24.5999Z" />
    <path d="M7.2 19.2H6V21.6H7.2V19.2Z" />
    <path d="M7.2 22.7998H6V25.1998H7.2V22.7998Z" />
    <path d="M7.2 26.3999H6V28.7999H7.2V26.3999Z" />
    <path d="M26.4 19.1999C25.7372 19.1999 25.2 18.6627 25.2 17.9999C25.2 17.6686 24.9314 17.3999 24.6 17.3999H11.4C11.0687 17.3999 10.8 17.6686 10.8 17.9999C10.8 18.6627 10.2628 19.1999 9.6 19.1999C9.26865 19.1999 9 19.4686 9 19.7999V26.9999C9 27.3313 9.26865 27.5999 9.6 27.5999C10.2628 27.5999 10.8 28.1371 10.8 28.7999C10.8 29.1313 11.0687 29.3999 11.4 29.3999H24.6C24.9314 29.3999 25.2 29.1313 25.2 28.7999C25.2 28.1371 25.7372 27.5999 26.4 27.5999C26.7313 27.5999 27 27.3313 27 26.9999V19.7999C27 19.4686 26.7313 19.1999 26.4 19.1999ZM25.8 26.4755C24.9553 26.6956 24.2957 27.3552 24.0756 28.1999H11.9244C11.7044 27.3552 11.0447 26.6956 10.2 26.4755V20.3243C11.0447 20.1043 11.7044 19.4446 11.9244 18.5999H24.0756C24.2957 19.4446 24.9553 20.1043 25.8 20.3243V26.4755Z" />
  </svg>
);
