import Cookies from 'js-cookie'

export function changeUrl(url) {
  const lang = Cookies.get('lng')
  switch (lang) {
    case 'am':
      return url
    case 'ru':
      return `/ru${url}`
    case 'en':
      return `/en${url}`
    default:
      return url
  }
}

export function constructCannonical(path) {
  const lang = Cookies.get('lng')
  if (!lang || lang === 'am') {
    return `https://internet.am/${path}`
  }
  return `https://internet.am/${lang}/${path}`
}
