import styled from 'styled-components';
import sort from '../../../../images/selector.svg';

export const MobileSortingDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  max-height: 176px;
  border-bottom: 1px solid #212121;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0px 0px;
  padding-top: 16px;
  padding-bottom: 16px;
  left: 5px;
  top: 30px;
  align-items: flex-start;
  z-index: 1;

  @media (min-width: 768px) {
    left: 5px;
    bottom: 70px;
  }

  @media (min-width: 1280px) {
  }
`;
export const SortingDiv = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    width: 78px;
    height: 32px;
    border: none;
    background: #ffffff;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid #212121;
    position: absolute;
    bottom: 85px;

    &:hover {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24),
        0px 0px 2px rgba(0, 0, 0, 0.12);
    }
  }
`;

export const SortAsc = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 50%;
  justify-content: center;
  border-radius: 5px 0px 0px 0px;
  // background: ${(p) => (p.isChecked ? '#242e46' : 'none')};

  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`;

export const SortDesc = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 50%;
  justify-content: center;
  border-radius: 0px 5px 0px 0px;
  // background: ${(p) => (p.isChecked ? ' #242e46' : 'none')};

  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`;
export const SortMin = styled.button`
  height: 32px;
  width: 32px;
  ${`background-image: url(${sort});
  background-repeat: no-repeat;
  background-size: cover;`}

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${(p) => (p.isChecked ? ' #242e46' : 'none')};
  align-items: center;
`;
export const Header = styled.div`
  color: ${(p) => (p.isChecked ? '#fff' : 'none')};
  margin-left: 20px;
  font-size: 14px;
`;
export const Sort = styled.div`
  align-items: center;
  width: 50px;
`;
