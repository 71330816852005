import FetchApi from '../../../helpers/FetchApi';
import { VPS_MODAL_TYPES } from './VpsModal.types';

export const getVpsInfo = (serviceid) => async (dispatch) => {
  try {
    dispatch({
      type: VPS_MODAL_TYPES.GET_VPS_INFO_REQUEST,
    });
    const req = await FetchApi.get(
      `products/getVultrUser?serviceid=${serviceid}`
    );
    dispatch({
      type: VPS_MODAL_TYPES.GET_VPS_INFO_SUCCESS,
      data: req.data.result,
    });
  } catch (e) {
    dispatch({
      type: VPS_MODAL_TYPES.GET_VPS_INFO_FAIL,
      error: e,
    });
  }
};

export const togglePass = () => ({
  type: VPS_MODAL_TYPES.TOGGLE_PASSWORD,
});
