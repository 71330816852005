import { HEADER_TYPES } from './Header.types';

const initialState = {
  isExpanded: false,
  shouldBeOpaque: false,
};

function header(state = initialState, action) {
  switch (action.type) {
    case HEADER_TYPES.TOGGLE_MENU:
      return {
        ...state,
        isExpanded: !state.isExpanded,
      };
    case HEADER_TYPES.HIDE_MOBILE_MENU: {
      return {
        ...state,
        isExpanded: false,
      };
    }
    case HEADER_TYPES.SET_OPAQUE:
      return {
        ...state,
        shouldBeOpaque: action.status,
      };
    default:
      return state;
  }
}

export { header };
