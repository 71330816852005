import { TRANSFER_DOMAIN_TYPES } from './TransferDomainModal.types';

const initialState = {
  loading: false,
  error: null,
  data: null,
};

export const transferDomainModal = (state = initialState, action) => {
  switch (action.type) {
    case TRANSFER_DOMAIN_TYPES.TRANSFER_DOMAIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TRANSFER_DOMAIN_TYPES.TRANSFER_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TRANSFER_DOMAIN_TYPES.TRANSFER_DOMAIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
