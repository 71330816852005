import styled from 'styled-components'
import { Link } from 'react-router-dom'

import deleteButton from '../../../images/deleteButton.svg'
import settingsButton from '../../../images/settingsButton.svg'
import emoji from '../../../images/emoji-sad.svg'

export const CartContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  max-height: 560px;
  border-top: 1px solid #212121;
  top: 56px;
  right: 20px;
  left: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  border-radius: 0px;
  @media (min-width: 768px) {
    width: 325px;
    right: -70px;
    left: unset;
    top: 77px;
  }
  @media (min-width: 1280px) {
    right: 110px;
  }
`

export const CartInfo = styled.div`
  width: 100%;
  max-height: 425px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  border-radius: 0px;
  overflow-y: auto;
  @media (min-width: 768px) {
    max-height: 520px;
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 21px;
  padding-right: 21px;
  padding-bottom: 5px;
`

export const Header = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #242e46;
  margin-top: 22px;
`

export const SelectedItem = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c4c4c4;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(p) =>
    p.align === 'right' ? 'flex-end' : 'space-between'};
  margin-top: 15px;
  width: 100%;
`

export const AdditionalData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const AdditionalDataItem = styled.div`
  font-size: 14px;
  color: #242e46;
  font-weight: normal;
`

export const PackageName = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #242e46;
`

export const Icons = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
`

export const Delete = styled.button`
  height: 16px;
  width: 15px;
  margin-right: 10px;
  background-image: url(${deleteButton});
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
`
export const Settings = styled(Link)`
  height: 16px;
  width: 16px;
  background-image: url(${settingsButton});
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  ${(p) => p.disabled && `pointer-events: none;`};
`

export const SelectedCart = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
`
export const DomainName = styled.div`
  font-weight: 400;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #242e46;
  > span {
    font-weight: bold;
  }
`

export const DomainNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`

export const Price = styled.div`
  font-weight: 300;
  font-size: 16px;
  font-family: sans-serif;
  line-height: 21px;
  color: #242e46;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 45px;
  padding-bottom: 20px;
`
export const TotalText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #242e46;
`

export const TotalPrice = styled.div`
  font-weight: normal;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 16px;
  color: #242e46;
`
export const Button = styled.button`
  width: 100%;
  height: 40px;
  position: relative;
  background: #242e46;
  border-radius: 0px 0px 5px 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border: none;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  &:disabled {
    background-color: #888e9b;
    cursor: initial;
  }
`

export const DividerBorder = styled.div`
  border-top: 1px solid #c4c4c4;
  margin-top: 8px;
  margin-left: 21px;
  margin-right: 21px;
`

export const EmptyCart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const EmptyCartText = styled.div`
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #242e46;
  margin-top: 40px;
`
export const EmojiIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${emoji});
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 15px;
  margin-bottom: 40px;
`

export const WarningText = styled(Link)`
  color: #ffb400;
  font-size: 13px;
`
