import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { TwoFactorAuthModal as Self } from './TwoFactorAuthModal';
import { verify2FA } from '../LoginModal/LoginModal.actions';

const mapStateToProps = ({ loginModal }) => ({
  loading: loginModal.verify2FALoading,
});

const mapDispatchToProps = {
  verify2FA,
};

export const TwoFactorAuthModal = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Self));
