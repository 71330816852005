import { connect } from 'react-redux'

import { ResetPasswordModal as Self } from './ResetPasswordModal'
import { resetPassword, clearError } from './ResetPasswordModal.actions'
import { showModal } from '../../../common/Modal/Modal.actions'
import { withTranslation } from 'react-i18next'

const mapStateToProps = ({ passwordResetModal }) => ({
  loading: passwordResetModal.loading,
  error: passwordResetModal.error,
})

const mapDispatchToProps = {
  resetPassword,
  showModal,
  clearError,
}

export const ResetPasswordModal = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
)
