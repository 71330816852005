export const VIEW_TICKET_TYPES = {
  GET_TICKET_REQUEST: 'VIEW_TICKET_TYPES.GET_TICKET_REQUEST',
  GET_TICKET_SUCCESS: 'VIEW_TICKET_TYPES.GET_TICKET_SUCCESS',
  GET_TICKET_FAIL: 'VIEW_TICKET_TYPES.GET_TICKET_FAIL',
  REPLY_TICKET_REQUEST: 'VIEW_TICKET_TYPES.REPLY_TICKET_REQUEST',
  REPLY_TICKET_SUCCESS: 'VIEW_TICKET_TYPES.REPLY_TICKET_SUCCESS',
  REPLY_TICKET_FAIL: 'VIEW_TICKET_TYPES.REPLY_TICKET_FAIL',
  CLOSE_TICKET_REQUEST: 'VIEW_TICKET_TYPES.CLOSE_TICKET_REQUEST',
  CLOSE_TICKET_SUCCESS: 'VIEW_TICKET_TYPES.CLOSE_TICKET_SUCCESS',
  CLOSE_TICKET_FAIL: 'VIEW_TICKET_TYPES.CLOSE_TICKET_FAIL',
};
