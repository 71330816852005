import React from "react";
import { i18n } from "../../../i18n";
import * as S from "./PersonalDetailsSuccessModal.styles";

export const PersonalDetailsSuccessModal = ({ hideModal, bindStatus }) => {
  return (
    <S.Modal>
      <S.PersonalDetailsModal>
        <S.Image/>
        <S.CloseModalButton onClick={hideModal} />
        <S.Text>{i18n.t('personalDetailsSuccessModal.text')}</S.Text> 
      </S.PersonalDetailsModal>
    </S.Modal>
  );
};
