import { connect } from 'react-redux';

import { SettingsPopup as Self } from './SettingsPopup';
import { showSettingsPopup } from '../../../../ClientAreaPage.actions';
import { showModal } from '../../../../../../common/Modal/Modal.actions';

const mapStateToProps = ({ clientAreaPage, viewTicket }) => ({
  isShown: clientAreaPage.settingsPopupIsShown,
});

const mapDispatchToProps = {
  showSettingsPopup,
  showModal,
};

export const SettingsPopup = connect(mapStateToProps, mapDispatchToProps)(Self);
