import styled from 'styled-components'
import * as fontWeight from '../../assets/styles/constants/fontWeight'

export const FAQContainer = styled.div`
  display: flex;
  ${(p) => p.isInPage && `margin-top: 100px;`};
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 1280px) {
    width: 1100px;
  }
`

export const QuestionsTitle = styled.div`
  font-size: 24px;
  color: #484857;
  font-weight: ${fontWeight.MEDIUM};
`

export const QuestionsList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  width: 100%;
`
