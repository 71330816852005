import React from 'react'
import { Formik } from 'formik'
import { range } from 'lodash'

import * as S from './RenewDomainModal.styles'
import { i18n } from '../../../i18n'
import { config, initialValues } from './RenewDomainModal.config'
import { Loader } from '../../../components/Loader'

import { changeUrl } from '../../../hooks/changeUrl'

export const RenewDomainModal = ({
  history,
  hideModal,
  domain,
  renewDomain,
  domainid,
  isRenewing,
}) => {
  const domainTld = `.${domain.domainname.split('.').splice(1).join('.')}`

  const configProduct = config.find((item) => item.tld === domainTld)

  let configYears = configProduct ? configProduct.years || 1 : 1

  const years = range(1, configYears + 1)

  return (
    <S.Modal onClick={(e) => e.stopPropagation()}>
      <S.RenewModalContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting, setFieldValue }) => {
            await renewDomain(
              domainid,
              values.years,
              values.promocode || '-',
              (id) => {
                history.push(changeUrl(`/invoice?id=${id}`))
              }
            )
            setSubmitting(false)
            hideModal()
          }}
        >
          {({
            touched,
            errors,
            values,
            isSubmitting,
            handleChange,
            submitForm,
            setFieldValue,
          }) => (
            <S.StyledForm>
              <S.DomainContainer key={domain.id}>
                <S.Domain>{domain.domainname}</S.Domain>
                <S.Renew>
                  <S.RenewHeader>{i18n.t('renewModal.period')}</S.RenewHeader>
                  <S.Select
                    onChange={(e) => setFieldValue('years', e.target.value)}
                  >
                    {years.map((item) => (
                      <S.Option value={item}>
                        {item} {i18n.t('renewModal.year')}
                      </S.Option>
                    ))}
                  </S.Select>
                </S.Renew>
                <S.Renew>
                  <S.RenewHeader>
                    {i18n.t('renewModal.promocode')}
                  </S.RenewHeader>
                  <S.Input
                    onChange={(e) => setFieldValue('promocode', e.target.value)}
                  />
                </S.Renew>
              </S.DomainContainer>
              <S.RenewButton type="submit" disabled={isSubmitting}>
                {isRenewing ? (
                  <Loader color="#ffffff" size={0.5} />
                ) : (
                  i18n.t('renewModal.button')
                )}
              </S.RenewButton>
            </S.StyledForm>
          )}
        </Formik>
        <S.CloseModalButton onClick={hideModal} />
      </S.RenewModalContainer>
    </S.Modal>
  )
}
