import { connect } from 'react-redux'
import { Dropdown as Self } from './Dropdown'
import { toggleTld } from '../../pages/HomePage/components/SearchAreaBlock/SearchAreaBlock.actions' 

const mapStateToProps = ({ viewport }) => ({
  viewport
})

const mapDispatchToProps = {
  toggleTld
}

export const Dropdown = connect(mapStateToProps, mapDispatchToProps)(Self)