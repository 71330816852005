import styled from 'styled-components'
import { Field } from 'formik'

import * as fontWeight from '../../assets/styles/constants/fontWeight'

export const PageTitle = styled.div`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 24px;
  color: #484857;
`

export const RegistrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 40px 20px;
  min-height: 300px;
  ${(p) => p.centered && `justify-content: center;`};

  @media (min-width: 768px) {
    padding: 130px 230px;
  }

  @media (min-width: 1280px) {
    padding: 70px 230px 130px 230px;
  }
`

export const InformationText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 26px;

  @media (min-width: 768px) {
    width: 690px;
    font-size: 18px;
  }

  @media (min-width: 1280px) {
    width: 798px;
    margin-top: 29px;
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  @media (min-width: 768px) {
    padding: 0;
  }

  @media (min-width: 1280px) {
    margin-top: 30px;
  }
`

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  width: 100%;
  &.radio {
    justify-content: center;
  }

  @media (min-width: 768px) {
    width: unset;
    margin-top: 47px;
  }
`

export const Label = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 17px;

  &.radio {
    margin: 0px;
    margin-left: 7px;
    opacity: 0.38;
  }

  @media (min-width: 768px) {
    font-size: 18px;
  }
`

export const Input = styled(Field)`
  width: auto;
  padding: 12px;
  max-height: 44px;
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  width: 330px;
  @media (min-width: 768px) {
    width: 385px;
  }

  &.radio {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    -webkit-appearance: none;
    border: 1px solid #d4d4d4;
    padding: 0px;
    cursor: pointer;

    &:checked {
      border-color: #dc773c;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      margin: 20% auto;
      border-radius: 50%;
      left: 4px;
      top: 0px;
      background: #d4d4d4;
    }

    &:checked:before {
      background: #dc773c;
    }
  }
`

export const ErrorMessage = styled.div`
  width: 370px;
  color: red;
  font-size: 12px;
  margin-top: 5px;
  ${(p) => p.spaced && 'margin-top: 20px;'};
`

export const ConfirmButton = styled.button`
  width: 100%;
  height: 38px;
  border-radius: 5px;
  background: #00c084;
  margin-top: 52px;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  border: none;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`

export const StyledImage = styled.img`
  @media (min-width: 768px) {
    width: 400px;
  }
  width: 300px;
  margin-bottom: 20px;
`

export const FailText = styled.div`
  color: #242e46;
  font-size: 18px;
  margin-bottom: 65px;
`

export const TryAgainButton = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  background: #00c084;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  border: none;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`
