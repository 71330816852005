import { connect } from 'react-redux'

import { GetCodeModal as Self } from './GetCodeModal'
import { setNameServers } from '../NameServerModal.actions'
import { showModal } from '../../../../common/Modal/Modal.actions'

const mapStateToProps = ({ app, nameServerModal }) => ({
  updateNameserversLoading: nameServerModal.updateNameserversLoading,
  updateNameserversError: nameServerModal.updateNameserversError,
})

const mapDispatchToProps = {
  setNameServers,
  showModal,
}

export const GetCodeModal = connect(mapStateToProps, mapDispatchToProps)(Self)
