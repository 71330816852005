import styled from 'styled-components'
import cookie from '../../../images/cookie.png'
import closeModal from '../../../images/closeModal.svg'

export const Modal = styled.div`
  height: 300px;
  width: 100%;
  border-radius: 5px;
  outline: none;
  z-index: 100;
  overflow-y: auto;
  display: flex;

  @media (min-width: 768px) {
    width: auto;
    height: 450px;
  }
`

export const CookieModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 1;
  z-index: 100;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
  @media (min-width: 768px) {
    width: 600px;
    padding: 35px;

    margin-left: 0;
    margin-right: 0;
  }
`
export const Accept = styled.button`
  border: none;
  outline: none;
  background: #000000bd;
  color: #fff;
  padding: 10px 30px;
  font-size: 14px;
  border-radius: 3px;
  margin-top: 40px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`

export const Header = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-top: 15px;
  color: #000000bd;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 30px;
    margin-top: 30px;
  }
`

export const Info = styled.div`
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  color: #000000bd;

  @media (min-width: 768px) {
    font-size: 21px;
  }
`

export const Image = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${cookie});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: 768px) {
    width: 100px;
    height: 100px;
  }
`
export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background-size: cover;

  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`
