import { cloneDeep } from 'lodash'
import { DOMAIN_TYPES } from './DomainsPage.types'
import { APP_TYPES } from '../../common/App/App.types'

const initialState = {
  domain: '',
  domainsLoading: false,
  domainRequestSuccess: false,
  domainRequestPart2Loading: false,
  domainsData: [],
  domainsTotal: 0,
  domainsRequestError: null,
  domainsOffset: 0,
  moreDomainsLoading: false,
  moreDomainRequestSuccess: false,
  moreDomainRequestPart2Loading: false,
  moreDomainsRequestError: null,
  mobileCartIsExpanded: false,
  domainListExpanded: false,
}

function domainsPage(state = initialState, action) {
  switch (action.type) {
    case DOMAIN_TYPES.SET_DOMAIN:
      return { ...state, domain: action.value }
    case DOMAIN_TYPES.SET_DOMAINS_DATA:
      return { ...state, domainsData: action.domainsData }
    case DOMAIN_TYPES.GET_DOMAINS_REQUEST:
      return {
        ...state,
        domainsLoading: true,
        domainRequestSuccess: false,
        domainsData: [],
        domainsRequestError: null,
        domainsTotal: 0,
      }
    case DOMAIN_TYPES.GET_DOMAINS_SUCCESS: {
      const domainsData = action.data.map((item) => ({
        ...item,
        type: 'domain',
      }))
      return {
        ...state,
        domainsLoading: false,
        domainRequestSuccess: true,
        domainsData,
        domainsTotal: action.total,
        domainsRequestError: null,
        domainsOffset: 0,
      }
    }
    case APP_TYPES.REMOVE_FROM_CART: {
      const domainsDataCopy = cloneDeep(state.domainsData)
      const matchIndex = domainsDataCopy.findIndex(
        (item) =>
          item.type === 'domain' &&
          item.domain === action.product.domain &&
          item.tld === action.product.tld
      )
      if (matchIndex !== -1) {
        domainsDataCopy[matchIndex].isAdded = false
        return { ...state, domainsData: domainsDataCopy }
      }
      return state
    }
    case DOMAIN_TYPES.GET_DOMAINS_REQUEST_PART2:
      return {
        ...state,
        domainRequestPart2Loading: true,
      }
    case DOMAIN_TYPES.GET_DOMAINS_SUCCESS_PART2: {
      const domainsData = action.data.map((item) => ({
        ...item,
        type: 'domain',
      }))
      return {
        ...state,
        domainsLoading: false,
        domainRequestSuccess: true,
        domainsData: [...state.domainsData, ...domainsData],
        domainsRequestError: null,
        domainRequestPart2Loading: false,
      }
    }
    case DOMAIN_TYPES.GET_DOMAINS_FAIL:
      return {
        ...state,
        domainsLoading: false,
        domainRequestSuccess: false,
        domainsData: [],
        domainsRequestError: action.error,
        domainsTotal: 0,
      }
    case DOMAIN_TYPES.GET_MORE_DOMAINS_REQUEST:
      return {
        ...state,
        moreDomainsLoading: true,
        moreDomainRequestSuccess: false,
        moreDomainsRequestError: null,
      }
    case DOMAIN_TYPES.GET_MORE_DOMAINS_SUCCESS: {
      const domainsData = action.data.map((item) => ({
        ...item,
        type: 'domain',
      }))
      return {
        ...state,
        moreDomainsLoading: false,
        domainsData: [...state.domainsData, ...domainsData],
        moreDomainsRequestError: null,
        domainsOffset: action.offset,
      }
    }
    case DOMAIN_TYPES.GET_MORE_DOMAINS_REQUEST_PART2:
      return {
        ...state,
        moreDomainRequestPart2Loading: true,
      }
    case DOMAIN_TYPES.GET_MORE_DOMAINS_SUCCESS_PART2: {
      const domainsData = action.data.map((item) => ({
        ...item,
        type: 'domain',
      }))
      return {
        ...state,
        moreDomainsLoading: false,
        moreDomainRequestSuccess: true,
        domainsData: [...state.domainsData, ...domainsData],
        moreDomainsRequestError: null,
        moreDomainRequestPart2Loading: false,
      }
    }
    case DOMAIN_TYPES.GET_MORE_DOMAINS_FAIL:
      return {
        ...state,
        moreDomainsLoading: false,
        moreDomainRequestSuccess: false,
        domainsData: state.domainsData,
        moreDomainsRequestError: action.error,
      }
    case DOMAIN_TYPES.TOGGLE_MOBILE_CART:
      return {
        ...state,
        mobileCartIsExpanded: !state.mobileCartIsExpanded,
      }
    case APP_TYPES.SET_AUTH_DATA: {
      return {
        ...initialState,
      }
    }
    case DOMAIN_TYPES.TOGGLE_UNAVAILABLE_DOMAINS:
      return {
        ...state,
        domainListExpanded: !state.domainListExpanded,
      }
    default:
      return state
  }
}

export { domainsPage }
