import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Container from '../Container';

export const HeaderContainer = styled(Container)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;

  @media (min-width: 768px) {
    height: 77px;
  }
`;

export const DesktopView = styled.div`
  display: none;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 1280px) {
    display: flex;
  }
`;

export const MobileView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 1280px) {
    display: none;
  }
`;

export const ExpandedPart = styled.div`
  position: absolute;
  display: flex;
  background-color: white;
  left: 0;
  top: 77px;
  width: 100%;
`;

export const HeaderSideFiller = styled.div`
  background-color: rgba(255, 255, 255, 1);
  font-weight: 300;
  position: fixed;
  // overflow-y: auto;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 0px 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: flex-end;
  transition: 1s background-color;

  @media (min-width: 768px) {
    padding: 0px 80px;
  }
  @media (min-width: 1280px) {
    background-color: ${(p) =>
    p.transparent ? 'rgba(255,255,255,0)' : 'rgba(255,255,255,1)'};
  }
`;

export const Logo = styled.img`
  width: 24px;
  height: 39px;

  @media (min-width: 768px) {
    width: 32px;
    height: 50px;
  }
`;

export const ManIcon = styled.img`
  width: 16px;
  height: 18px;

  @media (min-width: 768px) {
    width: 24px;
    height: 28px;
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

export const LeftPart = styled.div`
  width: 40%;
`;
export const MenuButton = styled.div`
  cursor: pointer;
  padding: 20px;
  width: 35px;
  height: 35px;
  @media (min-width: 768px) {
    width: 52px;
    height: 52px;
  }
`;

export const MenuIcon = styled.img`
  width: 34px;
  height: 34px;

  @media (min-width: 768px) {
    width: 48px;
    height: 48px;
  }
`;
