import React from 'react';

import * as S from './ConfirmModal.styles';
import { i18n } from '../../../i18n';

import successIcon from '../../../images/greenCircle.png';
import dangerIcon from '../../../images/redCircle.png';

export const ConfirmModal = ({
  hideModal,
  onSuccessFn,
  subheaderKey,
  successBtnTextKey,
  modalType,
}) => {
  const modalSuccess = () => {
    onSuccessFn();
    hideModal();
  };

  const changeColor = (modalType) => {
    switch (modalType) {
      case 'danger':
        return dangerIcon;
      case 'success':
        return successIcon;
      default:
        return dangerIcon;
    }
  };

  return (
    <S.Modal onClick={(e) => e.stopPropagation()}>
      <S.ConfirmModalContainer>
        <S.Image
          src={changeColor(modalType)}
          alt="Modal meaning image"
          title="modal image"
        />
        <S.Header>{i18n.t('confirmModal.header')}</S.Header>
        <S.SubHeader>{i18n.t(subheaderKey)}</S.SubHeader>
        <S.ButtonsContainer>
          <S.Confirm onClick={modalSuccess} variant={modalType}>
            {i18n.t(successBtnTextKey)}
          </S.Confirm>
          <S.Cancel onClick={hideModal}>
            {i18n.t('confirmModal.cancelButton')}
          </S.Cancel>
        </S.ButtonsContainer>
        <S.CloseModalButton onClick={hideModal} />
      </S.ConfirmModalContainer>
    </S.Modal>
  );
};
