import React, { useCallback, useState } from 'react';
import { Formik, Form } from 'formik';
import { decode } from 'html-entities';
import PhoneInput from 'react-phone-input-2';
import { useEffect } from 'react';

import { countries } from '../../../../constants/countries';
import { initialValues, yupScheme } from './PersonalDetails.config';
import * as S from './PersonalDetails.styles';
import { i18n } from '../../../../i18n';
import { Loader } from '../../../../components/Loader';
import { Checkbox } from '../../../../components/Checkbox';
import { PersonalDetailsSuccessModal } from '../../../../components/Modals/PersonalDetailsSuccessModal';

const PersonalDetails = ({
  history,
  loading,
  error,
  info,
  getUserInfo,
  updateUserInfo,
  updateLoading,
  updateError,
  autopay,
  setAutoCharge,
  showModal,
  bindStatus,
}) => {
  useEffect(() => {
    getUserInfo();
  }, []);

  const [inputCountryCode, setInputCountryCode] = useState('');

  const handleEnter = useCallback((e, submitForm) => {
    if (e.key === 'Enter') {
      submitForm();
    }
  }, []);

  const initValues = initialValues(info);

  const legalData = info.customfields
    ? info.customfields.find((item) => item.id === 2)
    : null;

  const passData = info.customfields
    ? info.customfields.find((item) => item.id === 4)
    : null;


  return (
    <S.PersonalDetailsContainer>
      <S.FormContainer>
        {info.email ? (
          <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={yupScheme}
            onSubmit={async (values, { setSubmitting, resetForm}) => {
              const phonenumber = values.phonenumber
                .replace('+', '00')
                .replace(/ /g, '');
              const formattedValues = {
                phonenumber,
                country: values.country,
                state: values.state,
                city: values.city,
                address1: values.address1,
                postcode: values.postcode,
                autopay: values.autopay,
              };

              await updateUserInfo({ ...formattedValues}, () => showModal(PersonalDetailsSuccessModal))
              setSubmitting(false);
              resetForm({ values: formattedValues })
            }}
          >
            {({
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              submitForm,
              dirty,
            }) => {
              return (
                <Form onKeyPress={() => handleEnter(submitForm)}>
                  {info.customfields1 === 'ֆիզ. անձ' ? (
                    <S.FormSection>
                      <S.FormItemHeader>
                        <S.Label>
                          {i18n.t('registrationPage.firstname')}
                        </S.Label>
                        <S.TextField>{decode(info.firstname)}</S.TextField>
                      </S.FormItemHeader>
                      <S.FormItemHeader>
                        <S.Label>{i18n.t('registrationPage.lastname')}</S.Label>
                        <S.TextField>{decode(info.lastname)}</S.TextField>
                      </S.FormItemHeader>
                      <S.FormItemHeader>
                        <S.Label>{i18n.t('registrationPage.passport')}</S.Label>
                        <S.TextField>{passData && passData.value}</S.TextField>
                      </S.FormItemHeader>
                      <S.FormItemHeader>
                        <S.Label>{i18n.t('registrationPage.email')}</S.Label>
                        <S.TextField>{info.email}</S.TextField>
                      </S.FormItemHeader>
                      <S.FormItemHeader>
                        <S.Label>
                          {i18n.t('registrationPage.autopayment')}
                        </S.Label>
                        <Checkbox
                          name="autopay"
                          title={i18n.t('registrationPage.autoPay')}
                          onBlur={handleBlur}
                          value={values.autopay}
                          onChange={() => {
                              setFieldValue('autopay', !values.autopay);
                              // setAutoCharge(!values.autopay);
                          }}
                        />
                      </S.FormItemHeader>
                    </S.FormSection>
                  ) : (
                    <S.FormSection>
                      <S.FormItemHeader>
                        <S.Label>
                          {i18n.t('registrationPage.companyName')}
                        </S.Label>
                        <S.TextField>{decode(info.firstname)}</S.TextField>
                      </S.FormItemHeader>
                      <S.FormItemHeader>
                        <S.Label>
                          {i18n.t('registrationPage.companyType')}
                        </S.Label>
                        <S.TextField>{decode(info.lastname)}</S.TextField>
                      </S.FormItemHeader>
                      <S.FormItemHeader>
                        <S.Label>{i18n.t('registrationPage.taxCode')}</S.Label>
                        <S.TextField>
                          {legalData && legalData.value}
                        </S.TextField>
                      </S.FormItemHeader>
                      <S.FormItemHeader>
                        <S.Label>{i18n.t('registrationPage.email')}</S.Label>
                        <S.TextField>{info.email}</S.TextField>
                      </S.FormItemHeader>
                      <S.FormItemHeader>
                        <S.Label>
                          {i18n.t('registrationPage.autopayment')}
                        </S.Label>
                        <Checkbox
                          title={i18n.t('registrationPage.autoPay')}
                          name="autopay"
                          onBlur={handleBlur}
                          value={values.autopay}
                          onChange={() => {
                          
                              setFieldValue('autopay', !values.autopay);
                            
                          }}
                        />
                      </S.FormItemHeader>
                    </S.FormSection>
                  )}
                  <S.FormSectionBottom>
                    <S.FormItem>
                      <S.Label>{i18n.t('registrationPage.tel')}</S.Label>
                      <PhoneInput
                        placeholder={i18n.t(
                          'registrationPage.phonenumberPlaceholder'
                        )}
                        value={
                          values.phonenumber.startsWith('00')
                            ? values.phonenumber.replace('00', '+')
                            : values.phonenumber
                        }
                        onChange={(phone, data) => {
                          setInputCountryCode(data.countryCode);
                          if (data.countryCode !== inputCountryCode) {
                            setFieldValue('phonenumber', data.dialCode);
                          } else {
                            setFieldValue('phonenumber', phone);
                          }
                        }}
                        name="phonenumber"
                        masks={{ am: '(..) ......' }}
                        country="am"
                      />
                      {errors.phonenumber && touched.phonenumber && (
                        <S.ErrorMessage>
                          {i18n.t(errors.phonenumber)}
                        </S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem>
                      <S.Label>{i18n.t('registrationPage.country')}</S.Label>
                      <S.StyledSelect
                        defaultValue={countries.find(
                          (item) => item.value === values.country
                        )}
                        isSearchable={true}
                        name="country"
                        options={countries}
                        onChange={(option) =>
                          setFieldValue('country', option.value)
                        }
                        classNamePrefix="react-select"
                      />
                      {errors.country && touched.country && (
                        <S.ErrorMessage>
                          {i18n.t(errors.country)}
                        </S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem>
                      <S.Label>{i18n.t('registrationPage.state')}</S.Label>
                      <S.Input
                        placeholder="Yerevan"
                        name="state"
                        onChange={handleChange}
                        value={decode(values.state)}
                      />
                      {errors.state && touched.state && (
                        <S.ErrorMessage>{i18n.t(errors.state)}</S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem>
                      <S.Label>{i18n.t('registrationPage.city')}</S.Label>
                      <S.Input
                        placeholder="Yerevan"
                        name="city"
                        onChange={handleChange}
                        value={decode(values.city)}
                      />
                      {errors.city && touched.city && (
                        <S.ErrorMessage>{i18n.t(errors.city)}</S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem>
                      <S.Label>{i18n.t('registrationPage.address')}</S.Label>
                      <S.Input
                        placeholder="Tumanyan, 3"
                        name="address1"
                        onChange={handleChange}
                        value={decode(values.address1)}
                      />
                      {errors.address1 && touched.address1 && (
                        <S.ErrorMessage>
                          {i18n.t(errors.address1)}
                        </S.ErrorMessage>
                      )}
                    </S.FormItem>
                    <S.FormItem>
                      <S.Label>
                        {i18n.t('registrationPage.postalIndex')}
                      </S.Label>
                      <S.Input
                        placeholder="0023"
                        name="postcode"
                        onChange={handleChange}
                        value={decode(values.postcode)}
                      />
                      {errors.postcode && touched.postcode && (
                        <S.ErrorMessage>
                          {i18n.t(errors.postcode)}
                        </S.ErrorMessage>
                      )}
                    </S.FormItem>
                  </S.FormSectionBottom>
                  <S.SaveButton type="submit" disabled={isSubmitting || !dirty}>
                    {updateLoading ? (
                      <Loader color="#FFF" size={0.5} />
                    ) : (
                      i18n.t(
                        'editProfilePage.personalDetailsComponent.saveButton'
                      )
                    )}
                  </S.SaveButton>
                  {updateError && (
                    <S.ErrorMessage spaced>
                      {i18n.t(`backend.personalDetails.${updateError}`)}
                    </S.ErrorMessage>
                  )}
                </Form>
              );
            }}
          </Formik>
        ) : (
          <S.LoaderContainer>
            <Loader color={'#00C084'} size={0.7} />
          </S.LoaderContainer>
        )}
      </S.FormContainer>
    </S.PersonalDetailsContainer>
  );
};

export { PersonalDetails };
