import CancelIcon from "../../images/cancel-icon.png";
import CheckIcon from "../../images/check-icon.png";

export const constructorsData = [
  {
    id: 1,
    pid: 277,
    name: "Mini",
    providedArea: "10 MB",
    numberOfPages: 3,
    emails: 1,
    sslCertificate: true,
    price: 490,
    price_usd: 1.4,
  },
  {
    id: 2,
    pid: 282,
    name: "Lite",
    providedArea: "50 MB",
    numberOfPages: 5,
    emails: 5,
    sslCertificate: true,
    price: 890,
    price_usd: 2.1,
  },
  {
    id: 3,
    pid: 275,
    name: "Full",
    providedArea: "1 GB",
    numberOfPages: 100,
    emails: 100,
    sslCertificate: true,
    price: 1700,
    price_usd: 5.9,
  },
];

export const compareData = {
  rentSiteItems: [
    {
      img: CancelIcon,
      name: "constructorPage.compareRentSite.n1",
    },
    {
      img: CancelIcon,
      name: "constructorPage.compareRentSite.n2",
    },
    {
      img: CancelIcon,
      name: "constructorPage.compareRentSite.n3",
    },
    {
      img: CancelIcon,
      name: "constructorPage.compareRentSite.n4",
    },
    {
      img: CancelIcon,
      name: "constructorPage.compareRentSite.n5",
    },
    {
      img: CancelIcon,
      name: "constructorPage.compareRentSite.n6",
    },
    {
      img: CheckIcon,
      name: "constructorPage.compareRentSite.n7",
    },
  ],
  buyConstructorItems: [
    {
      img: CheckIcon,
      name: "constructorPage.compareBuyConstructor.n1",
    },
    {
      img: CheckIcon,
      name: "constructorPage.compareBuyConstructor.n2",
    },
    {
      img: CheckIcon,
      name: "constructorPage.compareBuyConstructor.n3",
    },
    {
      img: CheckIcon,
      name: "constructorPage.compareBuyConstructor.n4",
    },
    {
      img: CheckIcon,
      name: "constructorPage.compareBuyConstructor.n5",
    },
    {
      img: CheckIcon,
      name: "constructorPage.compareBuyConstructor.n6",
    },
    {
      img: CancelIcon,
      name: "constructorPage.compareBuyConstructor.n7",
    },
  ],
};

export const advantagesData = [
  "constructorPage.advantages.n1",
  "constructorPage.advantages.n2",
  "constructorPage.advantages.n3",
  "constructorPage.advantages.n4",
  "constructorPage.advantages.n5",
];
