import React from 'react'
import { i18n } from '../../i18n'

import * as S from './TldCard.styles'

export const TldCard = ({
  auth,
  tld: { name, oldPrice, background, currentPrice },
}) => {
  return (
    <S.TldCardContainer background={background}>
      <S.TldName>{name}</S.TldName>
      <S.TldPrice>
        {currentPrice}
        <span>{i18n.t('homePage.tldsCard.period')}</span>
      </S.TldPrice>
    </S.TldCardContainer>
  )
}
