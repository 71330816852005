import { GLUE_RECORDS_MODAL } from './GlueRecordsModal.types';
import FetchApi from '../../../helpers/FetchApi';

export const setGlueRecords = (
  domain_name,
  domain_id,
  values,
  onSuccess
) => async (dispatch) => {
  try {
    dispatch({
      type: GLUE_RECORDS_MODAL.SET_IPS_REQUEST,
    });
    const res = await FetchApi.post('domains/gluenameservers', {
      ...values,
      domain_name,
      domain_id,
    });
    dispatch({
      type: GLUE_RECORDS_MODAL.SET_IPS_SUCCESS,
    });
    if (onSuccess) {
      onSuccess(res.data.tld);
    }
  } catch (e) {
    dispatch({
      type: GLUE_RECORDS_MODAL.SET_IPS_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    });
  }
};

export const getGlues = (domainid) => async (dispatch) => {
  try {
    dispatch({
      type: GLUE_RECORDS_MODAL.GET_GLUES_REQUEST,
    });
    const req = await FetchApi.get(`domains/nameservers?domainid=${domainid}`);
    const obj = {};
    for (let key in req.data.glue_ns[0]) {
      if (
        [
          'glue_ns1',
          'glue_ns2',
          'glue_ns3',
          'glue_ns4',
          'glue_ns5',
          'glue_ip1',
          'glue_ip2',
          'glue_ip3',
          'glue_ip4',
          'glue_ip5',
        ].includes(key)
      ) {
        obj[key] = req.data.glue_ns[0][key];
      }
    }

    dispatch({
      type: GLUE_RECORDS_MODAL.GET_GLUES_SUCCESS,
      data: obj,
    });
  } catch (e) {
    dispatch({
      type: GLUE_RECORDS_MODAL.GET_GLUES_FAIL,
    });
  }
};
