import React, { useEffect } from 'react';

import FetchApi from '../../../helpers/FetchApi';
import * as S from './RedirectToCpanelModal.styles';
import { Loader } from '../../../components/Loader';

export const RedirectToCpanelModal = ({ hideModal, hostingId }) => {
  useEffect(() => {
    getRedirectUrlAndClose();
  }, [])

  const getRedirectUrlAndClose = async () => {
    try {
      const req = await FetchApi.post('users/logincpanel', { hostingid: hostingId });
      window.open(req.data.response.redirectTo);
      hideModal();
    } catch (e) {
      hideModal();
    }
  }

  return (
    <S.Modal>
      <S.RedirectToCpanelModalContainer>
        <Loader
          color='#00C084'
          size={0.5}
        />
      </S.RedirectToCpanelModalContainer>
    </S.Modal>
  )
}