import React from 'react'
import { LayoutCommon } from '../../layouts/LayoutCommon'
import { useParams } from 'react-router'
import { decode } from 'html-entities'
import { i18n } from '../../i18n'
import paymentFailed from '../../images/paymentFailed.svg'
import slugify from 'react-slugify'

import * as S from './PaymentFailPage.styles'

export const PaymentFailPage = () => {
  const { id, data } = useParams()
  const decodedData = atob(data)
  const parsedData = JSON.parse(decodedData)

  return (
    <LayoutCommon>
      <S.PaymentFailedPageContainer>
        <S.FailImageContainer src={paymentFailed} />
        <S.DataContainer>
          <S.InvoiceId>{decode(id)}</S.InvoiceId>
          <S.ErrorMessage>
            {i18n.t(`backend.paymentFailed.${slugify(parsedData.respcode)}`, {
              defaultValue: 'Unknown error',
            })}
          </S.ErrorMessage>
        </S.DataContainer>
      </S.PaymentFailedPageContainer>
    </LayoutCommon>
  )
}
