import React, { useEffect, useRef, useCallback } from 'react'
import { useReactToPrint } from 'react-to-print'
import qs from 'qs'
import { decode } from 'html-entities'
import { LayoutCommon } from '../../layouts/LayoutCommon'
import * as S from './InvoicePage.styles'
import { i18n } from '../../i18n'
import { Loader } from '../../components/Loader'
import { formatPaymentForm } from './InvoicePage.helpers'
import logo from '../../images/logo.svg'
import { DomainUnavailableModal } from '../../components/Modals/DomainUnavailableModal'
import { download } from './InvoicePage.helpers'

const Invoice = ({
  auth,
  setPaymentMethod,
  data,
  paymentForm,
  paymentFormLoading,
  numberWithCommas,
  applyCredit,
  applyCreditLoading,
  applyButtonShouldHide,
  showModal,
  getUserCredit,
  credit,
  getInvoice,
  getPaymentForm,
}) => {
  const formattedPaymentForm =
    paymentForm.body && formatPaymentForm(paymentForm.body)

  const paymentMethodOptions = [
    { value: 'ameriabank', label: 'Visa/MasterCard' },
    { value: 'idram', label: 'Idram' },
    { value: 'telcell', label: 'Telcell' },
  ]
  useEffect(() => {
    if (paymentForm.domainStatus?.length) {
      showModal(DomainUnavailableModal, {
        domainStatus: paymentForm.domainStatus || [],
      })
    }
  }, [paymentForm.domainStatus])

  useEffect(() => {
    const paymentFormButton = document.querySelector(
      '#payment-form form input[type=submit]'
    )
    if (paymentFormButton) {
      paymentFormButton.addEventListener('click', () => {
        const dataLayer = window.dataLayer || []
        dataLayer.push({
          event: 'purchase',
          currency: 'USD',
          value: data.invoice.total / (data.client.currency === 1 ? 390 : 1),
          transaction_id: data.invoice.invoiceid,
          items: [
            {
              item_id: data.invoice.items.item[0].id,
              item_name: data.invoice.items.item[0].description,
            },
          ],
          ecommerce: {
            purchase: {
              actionField: {
                currency: 'USD',
                id: data.invoice.invoiceid,
              },
              products: [
                {
                  name: data.invoice.items.item[0].description,
                  id: data.invoice.items.item[0].id,
                  price:
                    data.invoice.total / (data.client.currency === 1 ? 390 : 1),
                  brand: 'ABC Domain LLC',
                },
              ],
            },
          },
        })
      })
    }
  }, [paymentForm])

  const changePaymentMethod = useCallback(
    (s) => {
      setPaymentMethod(data.invoice.invoiceid, s.value)
    },
    [data]
  )

  const handleApplyCredit = useCallback(() => {
    const appliedCredit =
      +data.invoice.total >= +credit ? +credit : +data.invoice.total
    applyCredit(data.invoice.invoiceid, appliedCredit)
  }, [auth, data])

  // useEffect(() => {
  //   const ssls = Cookies.get('sslShouldBeShown')?.split(',')
  //   if (ssls && data?.invoice?.invoiceid) {
  //     ssls.forEach((item) => {
  //       if (
  //         item === data.invoice.invoiceid.toString() &&
  //         data.invoice.status === 'Paid'
  //       ) {
  //         showModal(SSLAttentionModal)
  //       }
  //     })
  //   }
  // }, [data])

  return (
    <S.InvoicePageContainer>
      {data.invoice && data.invoice.invoiceid ? (
        <>
          {paymentFormLoading ? (
            <S.InvoiceHeader>
              <S.LogoLink>
                <S.Logo src={logo} alt="Internet.am Logo" />
              </S.LogoLink>
              <S.InvoiceHeaderForm isLoading={paymentFormLoading}>
                <Loader color={'#00C084'} size={0.5} />
              </S.InvoiceHeaderForm>
            </S.InvoiceHeader>
          ) : (
            <S.InvoiceHeader>
              <S.LogoLink>
                <S.Logo src={logo} alt="Internet.am Logo" />
              </S.LogoLink>
              {paymentForm.body && (
                <S.InvoiceHeaderFormContainer>
                  <S.PaymentMethodSelect
                    options={paymentMethodOptions}
                    onChange={changePaymentMethod}
                    value={paymentMethodOptions.find(
                      (item) => item.value === data.invoice.paymentmethod
                    )}
                  />
                  <S.InvoiceHeaderForm
                    id="payment-form"
                    dangerouslySetInnerHTML={{
                      __html: formattedPaymentForm,
                    }}
                  />
                  {!!+credit &&
                    data.invoice.items.item &&
                    !data.invoice.items.item.find(
                      (item) => item.description === 'Credit Recharge'
                    ) && (
                      <S.ApplyCreditContainer>
                        <S.ApplyCreditAmount>
                          {i18n.t('invoicePage.hasCredit')}{' '}
                          {numberWithCommas(+credit)}{' '}
                          {i18n.t('invoicePage.hasCredit2')}
                        </S.ApplyCreditAmount>
                        <S.ApplyCreditButton
                          disabled={applyButtonShouldHide}
                          onClick={handleApplyCredit}
                        >
                          {applyCreditLoading ? (
                            <Loader color="#FFF" size={0.5} />
                          ) : (
                            i18n.t('invoicePage.applyCredit')
                          )}
                        </S.ApplyCreditButton>
                      </S.ApplyCreditContainer>
                    )}
                </S.InvoiceHeaderFormContainer>
              )}
              {data.invoice.status !== 'Unpaid' ? (
                <S.StatusContainer status={data.invoice.status}>
                  <S.Status>
                    {i18n.t(`backend.invoiceStatuses.${data.invoice.status}`)}
                  </S.Status>
                </S.StatusContainer>
              ) : null}
            </S.InvoiceHeader>
          )}
          <S.InvoiceDetails>
            <S.InvoiceId>
              {i18n.t('invoicePage.invoice')} #{data.invoice.invoiceid}
            </S.InvoiceId>
            <S.Date>
              <S.Header>{i18n.t('invoicePage.invoiceDate')}</S.Header>
              <S.Row>{data.invoice.date}</S.Row>
            </S.Date>
            <S.Wrapper>
              <S.ClientDetails>
                <S.Header>{i18n.t('invoicePage.invoicedTo')}</S.Header>
                <S.Row>{decode(data.user.fullname)}</S.Row>
                <S.Row>{decode(data.user.address1)}</S.Row>
                <S.Row>{`${data.user.postcode}, ${decode(
                  data.user.city
                )}, ${decode(data.user.countryname)}`}</S.Row>
              </S.ClientDetails>
              <S.ReceiverDetails>
                <S.Header>{i18n.t('invoicePage.receiver')}</S.Header>
                <S.Row>{i18n.t('invoicePage.company')}</S.Row>
                <S.Row>{i18n.t('invoicePage.taxpayerId')}</S.Row>
                <S.Row>{i18n.t('invoicePage.address')}</S.Row>
                <S.Row>{i18n.t('invoicePage.bankDetails')}</S.Row>
              </S.ReceiverDetails>
            </S.Wrapper>
          </S.InvoiceDetails>
          <S.InvoiceDescription>
            <S.DescriptionHeader>
              {i18n.t('invoicePage.invoiceItems')}
            </S.DescriptionHeader>
            <S.ProductDetails>
              <S.ProductDetailsRow>
                <S.DescriptionTitle>
                  {i18n.t('invoicePage.invoiceDescription')}
                </S.DescriptionTitle>
                <S.SumTitle>{i18n.t('invoicePage.sum')}</S.SumTitle>
              </S.ProductDetailsRow>
            </S.ProductDetails>
            <S.ProductDetails>
              {data.invoice.items &&
                data.invoice.items.item &&
                data.invoice.items.item.map((item) => (
                  <S.ProductDetailsRow key={item.id}>
                    <S.ProductDescription>
                      {item.description}
                    </S.ProductDescription>
                    <S.ProductValue isAmd={auth.currency === 1}>
                      {numberWithCommas(item.amount)}
                    </S.ProductValue>
                  </S.ProductDetailsRow>
                ))}
            </S.ProductDetails>
            <S.PaymentDetails>
              <S.Total>{i18n.t('invoicePage.subTotal')}</S.Total>
              <S.Sum isAmd={auth.currency === 1}>
                {numberWithCommas(data.invoice.subtotal)}
              </S.Sum>
            </S.PaymentDetails>
            {data.invoice.receipt_link ? (
              <S.EhdmDetails>
                <S.EHDM href={data.invoice.receipt_link} target="blink">
                  {i18n.t('invoicePage.open_EHDM')}
                </S.EHDM>
                <S.EHDM href={data.invoice.receipt_link} download>
                  {i18n.t('invoicePage.download_EHDM')}
                </S.EHDM>
              </S.EhdmDetails>
            ) : null}
            {data.invoice.status === 'Unpaid' ? (
              <S.PaymentDetails>
                <S.Total>{i18n.t('invoicePage.total')}</S.Total>
                <S.Sum isAmd={auth.currency === 1}>
                  {numberWithCommas(data.invoice.total)}
                </S.Sum>
              </S.PaymentDetails>
            ) : null}
          </S.InvoiceDescription>
          {auth.currency === 2 ? (
            <S.Note>{i18n.t('invoicePage.note')}</S.Note>
          ) : null}
          {data.invoice.status !== 'Cancelled' &&
          data.invoice.status !== 'Unpaid' ? (
            <>
              <S.MobileInfoWrapper>
                {data.invoice.transactions &&
                  data.invoice.transactions.transaction.map((i) => {
                    const refunded = i.refundid
                    return (
                      <S.Info>
                        <S.InfoHeader>
                          {i18n.t('invoicePage.transactionDate')}
                        </S.InfoHeader>
                        <S.InfoDetail>
                          <div>{i.date.split(' ')[0]}</div>
                          <div>{i.date.split(' ')[1]}</div>
                        </S.InfoDetail>
                        <S.InfoHeader>
                          {i18n.t('invoicePage.gateway')}
                        </S.InfoHeader>
                        <S.InfoDetail>
                          {data.invoice.paymentmethod === 'ameriabank' &&
                            (refunded
                              ? i18n.t('invoicePage.refundedToCard')
                              : i18n.t('invoicePage.creditCard'))}
                          {data.invoice.paymentmethod === 'idram' &&
                            (refunded
                              ? i18n.t('invoicePage.refundedToIdram')
                              : i.gateway.charAt(0).toUpperCase() +
                                i.gateway.slice(1))}
                        </S.InfoDetail>
                        <S.InfoHeader>
                          {i18n.t('invoicePage.transactionId')}
                        </S.InfoHeader>
                        <S.InfoDetail>
                          {refunded ? '-' : i.transid}
                        </S.InfoDetail>
                        <S.InfoHeader>
                          {i18n.t('invoicePage.amount')}
                        </S.InfoHeader>
                        <S.InfoDetail>
                          {
                            numberWithCommas(
                              refunded ? i.amountout : i.amountin
                            ).split('.')[0]
                          }
                        </S.InfoDetail>
                      </S.Info>
                    )
                  })}
                {data.invoice.credits &&
                  data.invoice.credits.map((i) => {
                    return (
                      <S.Info>
                        <S.InfoHeader>
                          {i18n.t('invoicePage.transactionDate')}
                        </S.InfoHeader>
                        <S.InfoDetail>
                          <div>{i.date}</div>
                        </S.InfoDetail>
                        <S.InfoHeader>
                          {i18n.t('invoicePage.gateway')}
                        </S.InfoHeader>
                        <S.InfoDetail>
                          {i.paymentMethod === 'creditApplied'
                            ? i18n.t('invoicePage.creditApplied')
                            : i18n.t('invoicePage.refundedToBalance')}
                        </S.InfoDetail>
                        <S.InfoHeader>
                          {i18n.t('invoicePage.transactionId')}
                        </S.InfoHeader>
                        <S.InfoDetail>-</S.InfoDetail>
                        <S.InfoHeader>
                          {i18n.t('invoicePage.amount')}
                        </S.InfoHeader>
                        <S.InfoDetail isAmd={auth.currency === 1}>
                          {numberWithCommas(i.amount).split('.')[0]}
                        </S.InfoDetail>
                      </S.Info>
                    )
                  })}
              </S.MobileInfoWrapper>
              <S.DesktopInfoWrapper>
                <S.Info>
                  <S.InfoHeader>
                    {i18n.t('invoicePage.transactionDate')}
                  </S.InfoHeader>
                  <S.InfoHeader>{i18n.t('invoicePage.gateway')}</S.InfoHeader>
                  <S.InfoHeader>
                    {i18n.t('invoicePage.transactionId')}
                  </S.InfoHeader>
                  <S.InfoHeader>{i18n.t('invoicePage.amount')}</S.InfoHeader>
                </S.Info>
                {data.invoice.transactions &&
                  data.invoice.transactions.transaction.map((i) => {
                    const refunded = i.refundid

                    return (
                      <S.InfoDescription>
                        <S.InfoDetail>
                          <div>{i.date.split(' ')[0]}</div>
                          <div>{i.date.split(' ')[1]}</div>
                        </S.InfoDetail>
                        <S.InfoDetail>
                          {data.invoice.paymentmethod === 'ameriabank' &&
                            (refunded
                              ? i18n.t('invoicePage.refundedToCard')
                              : i18n.t('invoicePage.creditCard'))}
                          {data.invoice.paymentmethod === 'idram' &&
                            (refunded
                              ? i18n.t('invoicePage.refundedToIdram')
                              : i.gateway.charAt(0).toUpperCase() +
                                i.gateway.slice(1))}
                        </S.InfoDetail>
                        <S.InfoDetail>
                          {refunded ? '-' : i.transid}
                        </S.InfoDetail>
                        <S.InfoDetail>
                          {
                            numberWithCommas(
                              refunded ? i.amountout : i.amountin
                            ).split('.')[0]
                          }
                        </S.InfoDetail>
                      </S.InfoDescription>
                    )
                  })}
                {data.invoice.credits &&
                  data.invoice.credits.map((i) => {
                    return (
                      <S.InfoDescription key={i.id}>
                        <S.InfoDetail>{i.date}</S.InfoDetail>
                        <S.InfoDetail>
                          {i.paymentMethod === 'creditApplied'
                            ? i18n.t('invoicePage.creditApplied')
                            : i18n.t('invoicePage.refundedToBalance')}
                        </S.InfoDetail>
                        <S.InfoDetail>-</S.InfoDetail>
                        <S.InfoDetail isAmd={auth.currency === 1}>
                          {numberWithCommas(i.amount).split('.')[0]}
                        </S.InfoDetail>
                      </S.InfoDescription>
                    )
                  })}
              </S.DesktopInfoWrapper>
            </>
          ) : null}
        </>
      ) : (
        <S.LoaderContainer>
          <Loader color={'#00C084'} size={0.5} />
        </S.LoaderContainer>
      )}
    </S.InvoicePageContainer>
  )
}

export const InvoicePage = (props) => {
  const invoiceRef = useRef(null)
  const params = qs.parse(props.history.location.search, {
    ignoreQueryPrefix: true,
    encode: true,
  })

  useEffect(() => {
    props.clearInvoice()
    getInvoiceData(params.id)
  }, [props.history.location.search])

  const getInvoiceData = (id, attempt = 0) => {
    if (attempt > 5) {
      return
    }
    props.getInvoice(
      id,
      (status) => {
        if (status === 'Unpaid') {
          props.getPaymentForm(id)
        }
      },
      async () => {
        await delay(500)
        getInvoiceData(id, attempt + 1)
      }
    )
    props.getUserCredit()
  }

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  })

  const invoiceId = props.data.invoice && props.data.invoice.invoiceid

  return (
    <LayoutCommon>
      <S.InvoicePageLayout>
        {invoiceId ? (
          <>
            <div ref={invoiceRef}>
              <Invoice {...props} />
            </div>
            <S.Buttons>
              <S.PrintButton onClick={handlePrint}>
                {i18n.t('invoicePage.print')}
              </S.PrintButton>
              <S.PrintButton onClick={() => download(invoiceRef, invoiceId)}>
                {i18n.t('invoicePage.download')}
              </S.PrintButton>
            </S.Buttons>
          </>
        ) : (
          <S.InvoicePageContainer>
            <S.InvoiceHeader>
              <S.LogoLink>
                <S.Logo src={logo} alt="Internet.am Logo" />
              </S.LogoLink>
              <S.InvoiceHeaderForm
                isLoading={props.paymentFormLoading || !invoiceId}
              >
                <Loader color={'#00C084'} size={0.5} />
              </S.InvoiceHeaderForm>
            </S.InvoiceHeader>
          </S.InvoicePageContainer>
        )}
      </S.InvoicePageLayout>
    </LayoutCommon>
  )
}

const delay = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
