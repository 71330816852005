import { SupportPage as Self } from './SupportPage'
import { connect } from 'react-redux'
import {
  changeTab,
  changeExpand,
  setResults,
  setSearchResultsShouldShow,
} from './SupportPage.actions'

const mapStateToProps = ({ supportPage }) => ({
  tab: supportPage.tab,
  expandedQuestion: supportPage.expandedQuestion,
  results: supportPage.results,
  searchResultsShouldShow: supportPage.searchResultsShouldShow,
})

const mapDispatchToProps = {
  changeTab,
  changeExpand,
  setResults,
  setSearchResultsShouldShow,
}

export const SupportPage = connect(mapStateToProps, mapDispatchToProps)(Self)
