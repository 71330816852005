import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Cart as Self } from './Cart';
import {
  showCart,
  removeFromCart,
  numberWithCommas,
} from '../../../common/App/App.actions';
import { showModal } from '../../../common/Modal/Modal.actions';

const mapStateToProps = ({ app }) => ({
  auth: app.auth,
  cart: app.cart,
  isShown: app.cartIsShown,
});

const mapDispatchToProps = {
  showCart,
  removeFromCart,
  numberWithCommas,
  showModal,
};

export const Cart = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
