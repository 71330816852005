import { connect } from 'react-redux';

import { TransferDomainModal as Self } from './TransferDomainModal';
import { showModal } from '../../../common/Modal/Modal.actions';
import { transferDomain } from './TransferDomainModal.actions';

const mapStateToProps = ({ transferDomainModal }) => ({
  error: transferDomainModal.error,
  loading: transferDomainModal.loading,
});

const mapDispatchToProps = {
  showModal,
  transferDomain,
};

export const TransferDomainModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self);
