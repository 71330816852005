import { connect } from 'react-redux'

import { InvoicePage as Self } from './InvoicePage'
import {
  getInvoice,
  getPaymentForm,
  setPaymentMethod,
  applyCredit,
  clearInvoice,
} from './InvoicePage.actions'
import { numberWithCommas, getUserCredit } from '../../common/App/App.actions'
import { showModal } from '../../common/Modal/Modal.actions'

const mapStateToProps = ({ invoicePage, app }) => ({
  auth: app.auth,
  loading: invoicePage.loading,
  applyButtonShouldHide: invoicePage.applyButtonShouldHide,
  applyCreditLoading: invoicePage.applyCreditLoading,
  data: invoicePage.data,
  error: invoicePage.error,
  paymentForm: invoicePage.paymentFormData,
  paymentFormLoading: invoicePage.paymentFormLoading,
  userInfo: app.userInfo.user,
  credit: app.credit,
})

const mapDispatchToProps = {
  getInvoice,
  getPaymentForm,
  setPaymentMethod,
  applyCredit,
  numberWithCommas,
  showModal,
  getUserCredit,
  clearInvoice,
}

export const InvoicePage = connect(mapStateToProps, mapDispatchToProps)(Self)
