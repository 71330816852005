import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { VpsModal as Self } from './VpsModal';
import { showModal } from '../../../common/Modal/Modal.actions';
import { getVpsInfo, togglePass } from './VpsModal.actions';

const mapStateToProps = ({ app, vpsInfo }) => ({
  auth: app.auth,
  loading: vpsInfo.vpsInfoLoading,
  vpsInfo: vpsInfo.vpsInfo,
  error: vpsInfo.error,
  showPass: vpsInfo.showPass,
});

const mapDispatchToProps = {
  showModal,
  getVpsInfo,
  togglePass,
};

export const VpsModal = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
