import styled from 'styled-components'
import { Link } from 'react-router-dom'
import settingsButton from '../../../../images/settingsButton.svg'
import sort from '../../../../images/selector.svg'
import filter from '../../../../images/filter.svg'
import arrow from '../../../../images/arrow-icon.svg'

export const Headers = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  align-items: center;
  @media (min-width: 768px) {
    padding-bottom: 40px;
    align-items: center;
  }
  @media (min-width: 1280px) {
  }
`

export const Header = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    width: 20%;
    justify-content: center;
    // flex-direction: column;
    // align-items: flex-start;
  }
`

export const CorrectionWrapper = styled.div`
  display: flex;
  // flex-direction: column;
  // align-items: flex-end;
`

export const Wrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 20px;
    gap: 10px;
  }
`

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #242e46;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 1280px) {
    font-size: 18px;
  }
`
export const Sort = styled.button`
  height: 32px;
  width: 32px;
  background-image: url(${sort});
  background-repeat: no-repeat;
  background-size: cover;
`
export const SortMin = styled.button`
  height: 32px;
  width: 32px;
  ${`
  background-image: url(${sort});
  background-repeat: no-repeat;
  background-size: cover;
  `}
  @media (min-width: 768px) {
    display: none;
  }
`

export const MobileSortingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (min-width: 768px) {
    display: none;
  }
`

export const Filter = styled.button`
  height: 32px;
  width: 32px;
  background-image: url(${filter});
  background-repeat: no-repeat;
  background-size: cover;
`

export const CartBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
  @media (min-width: 768px) {
    padding-bottom: 30px;
  }
  @media (min-width: 1280px) {
    padding-bottom: 45px;
    align-items: center;
    margin-top: 30px;
  }
`

export const CartContent = styled.div`
  display: flex;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  margin-top: 28px;
  min-height: 135px;
  @media (min-width: 768px) {
    margin-top: 0px;
    margin-bottom: 20px;
    height: 70px;
    min-height: unset;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  @media (min-width: 1280px) {
    width: 110%;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    border: none;
  }
`

export const StyledLink = styled(Link)`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #393939;
  word-break: break-all;
  order: 1;
  @media (min-width: 768px) {
    width: 20%;
    order: 1;
  }
  @media (min-width: 1024px) {
    width: 18%;
  }
  @media (min-width: 1280px) {
    font-size: 20px;
    width: 20%;
  }
`

export const InvoiceData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 10px;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0px 50px 0px 40px;
  }
  @media (min-width: 820px) {
    padding: 0px 50px 0px 40px;
  }
  @media (min-width: 1024px) {
    padding: 0px 50px 0px 65px;
  }
  @media (min-width: 1280px) {
    padding: 0px 110px;
  }
`

export const InvoiceId = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #393939;
  word-break: break-all;
  order: 1;
  @media (min-width: 768px) {
    width: 20%;
    order: 1;
  }
  @media (min-width: 1280px) {
    font-size: 20px;
  }
`
export const InvoiceSum = styled.div`
  font-style: normal;
  font-weight: normal;
  display:flex;
  flex-direction:column;
  font-family: ${(p) => p.isAmd ? 'Mardoto' : 'Roboto'};
  font-size: 16px;
  color: #393939;
  width: 57%;
  text-align: start;
  word-break: break-all;
  order: 2;
  margin-top: 20px;
  @media (min-width: 768px) {
    width: 10%;
    order: 2;
    margin-top: 0px;
    margin-right: 0px;
    text-align: center;
  }
  @media (min-width: 1280px) {
    font-size: 20px;
  }

 
`

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  order: 4;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;
  @media (min-width: 768px) {
    order: 3;
    width: 47%;
    margin-top: 0px; 
  }
  }
`

export const StartDate = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #393939;
  width: 50%;
  text-align: center;
  @media (min-width: 768px) {
    width: 50%;
    text-align: center;
    word-break: break-all;
    // padding-left: 30px;
  }
  @media (min-width: 1280px) {
    font-size: 20px;
  }
`
export const EndDate = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #393939;
  width: 50%;
  text-align: center;
  @media (min-width: 768px) {
    width: 40%;
    text-align: center;
    word-break: break-all;
  }
  @media (min-width: 1280px) {
    font-size: 20px;
  }
`
export const Status = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #393939;
  width: 50%;
  text-align: end;
  word-break: break-all;
  margin-top: -20px;
  margin-left: 30px;
  order: 3;
  @media (min-width: 768px) {
    order: 4;
    width: 17%;
    text-align: center;
    margin-top: 0px;
    margin-left: 0px;
  }
  @media (min-width: 1280px) {
    font-size: 20px;
    text-align: center;
  }
`

export const CartSettingsIcon = styled.div`
  height: 17.45px;
  width: 17.45px;
  background-image: url(${settingsButton});
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  position: absolute;
  top: 14px;
  right: 8px;
  cursor: pointer;
  @media (min-width: 768px) {
    top: 25px;
    right: 15px;
  }
`

export const BottomButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: 1280px) {
    width: 50%;
    margin-left: -50px;
  }
`

export const ActionsButton = styled.button`
  width: 100%;
  height: 38px;
  background: #00c084;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    height: 44px;
    font-size: 18px;
    width: 30%;
    margin-bottom: 40px;
  }
  @media (min-width: 1280px) {
    height: 49px;
    font-size: 18px;
    width: 40%;
    margin-bottom: 0px;
  }
`

export const ArrowIcon = styled.div`
  height: 24px;
  width: 24px;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-size: cover;
`

export const AddServiceButton = styled.button`
  width: 100%;
  height: 38px;
  background: #242e46;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 768px) {
    height: 44px;
    font-size: 18px;
    width: 40%;
  }
  @media (min-width: 1280px) {
    height: 49px;
    font-size: 18px;
    width: 55%;
    margin-bottom: 0px;
  }
`

export const EmptyStateContainer = styled.div`
  display: flex;
  height: 300px;
  width: 100%;
  justify-content: center;
  align-items: center;
`
