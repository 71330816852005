import * as Yup from 'yup'

export const getInitialValues = (productIds) => ({
  message: '',
  promocode: '',
  priceSelect: '',
  domains: productIds.reduce((accumulator, value) => {
    return { ...accumulator, [value]: '' }
  }, {}),
})

export const yupScheme = Yup.object().shape({
  message: Yup.string().max(500, 'validationFields.messageTextareaMax'),
  promocode: Yup.string()
    .matches(/^[a-zA-Z0-9_-]*$/, 'validationFields.invalidTyping')
    .min(2, 'validationFields.promocodeMin')
    .max(20, 'validationFields.promocodeMax'),
  domains: Yup.object().test({
    test: function (domains) {
      const errors = {}
      for (const key in domains) {
        const value = domains[key]

        const isDomainValid =
          !!/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(
            value
          )

        if (!value) {
          errors[key] = 'validationFields.domain'
        } else if (!isDomainValid) {
          errors[key] = 'validationFields.invalidTyping'
        }
      }
      if (Object.keys(errors).length !== 0) {
        return this.createError({
          message: JSON.stringify(errors),
          path: 'domains',
        })
      }

      return true
    },
  }),
})
