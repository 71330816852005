import styled from 'styled-components';

import closeModal from '../../../images/closeModal.svg';
import contactOwnerImage from '../../../images/contactOwnerSuccess.svg';

export const ContactOwnerSuccessModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 1;
  z-index: 100;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;

  @media (min-width: 768px) {
    padding: 23px 31px;
  }
`;

export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-size: cover;

  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`;

export const Modal = styled.div`
  height: 100%;
  width: 304px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  outline: none;
  z-index: 100;

  @media (min-width: 768px) {
    width: 396px;
  }
`;

export const Image = styled.div`
  background-image: url(${contactOwnerImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 178px;
  width: 282px;
  margin-top: 32px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: #242e46;
  margin-top: 15px;
`;

export const Header = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  color: #242e46;
  margin-top: 8px;
`;

export const SubHeader = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  color: #242e46;
  margin-top: 10px;
  margin-bottom: 25px;
`;
