import React from "react";

export default (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.87"
      d="M9.00968 14.6715L8.67135 15.0215V6.16664C8.67135 5.85723 8.54844 5.56049 8.32965 5.3417C8.11086 5.12291 7.81412 5 7.50471 5C7.19529 5 6.89855 5.12291 6.67976 5.3417C6.46098 5.56049 6.33806 5.85723 6.33806 6.16664V15.0215L5.99973 14.6715C5.78005 14.4518 5.4821 14.3284 5.17142 14.3284C4.86074 14.3284 4.56278 14.4518 4.3431 14.6715C4.12342 14.8912 4 15.1891 4 15.4998C4 15.8105 4.12342 16.1084 4.3431 16.3281L6.67639 18.6614C6.78734 18.7676 6.91817 18.8509 7.06138 18.9064C7.20103 18.9681 7.35203 19 7.50471 19C7.65739 19 7.80838 18.9681 7.94803 18.9064C8.09124 18.8509 8.22207 18.7676 8.33302 18.6614L10.6663 16.3281C10.7751 16.2193 10.8614 16.0902 10.9202 15.9481C10.9791 15.806 11.0094 15.6536 11.0094 15.4998C11.0094 15.346 10.9791 15.1936 10.9202 15.0515C10.8614 14.9094 10.7751 14.7803 10.6663 14.6715C10.5575 14.5627 10.4284 14.4764 10.2863 14.4175C10.1442 14.3587 9.99183 14.3284 9.83799 14.3284C9.68416 14.3284 9.53183 14.3587 9.38971 14.4175C9.24759 14.4764 9.11845 14.5627 9.00968 14.6715Z"
      fill={props.fillcolor}
    />
    <path
      d="M14.5312 10L16.6484 4.3125H17.7344L19.8633 10H18.6133L18.2227 8.82812H16.1641L15.7734 10H14.5312ZM16.4805 7.87891H17.9062L17.1953 5.74219L16.4805 7.87891Z"
      fill={props.fillcolor}
    />
    <path
      d="M14.7891 14.2617V13.3125H19.0547V13.9844L16.2539 18.0547H19.1172V19H14.7891V18.3125L17.6016 14.2617H14.7891Z"
      fill={props.fillcolor}
    />
  </svg>
);
