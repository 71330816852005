import Img1 from '../../images/clientAreaS1.jsx';
import Img2 from '../../images/clientAreaS2.jsx';
import Img3 from '../../images/clientAreaS3.jsx';
import Img4 from '../../images/clientAreaS4.jsx';

export const CLIENT_AREA_TABS = {
  DOMAINS: 0,
  SERVICES: 1,
  TICKETS: 2,
  INVOICES: 3,
};

export const clientServices = [
  {
    id: 'domains',
    tab: CLIENT_AREA_TABS.DOMAINS,
    Image: Img1,
    description: 'clientAreaPage.domains',
    path: '/domain-registration',
  },
  {
    id: 'services',
    tab: CLIENT_AREA_TABS.SERVICES,
    Image: Img2,
    description: 'clientAreaPage.services',
  },
  {
    id: 'tickets',
    tab: CLIENT_AREA_TABS.TICKETS,
    Image: Img3,
    description: 'clientAreaPage.tickets',
    path: '/open-ticket',
  },

  {
    id: 'invoices',
    tab: CLIENT_AREA_TABS.INVOICES,
    Image: Img4,
    description: 'clientAreaPage.invoices',
  },
];

export const DEFAULT_LIMIT = 25;
