import React from 'react'
import { whyChooseUs } from '../../HomePage.constants'
import * as S from './WhyChooseUsBlock.styles'
import { i18n } from '../../../../i18n'
import NumberOne from '../../../../images/numberOne.svg'

export const WhyChooseUsBlock = () => (
  <S.ReasonSideFiller>
    <S.NumberOne>
      <img src={NumberOne} alt="number one" />
    </S.NumberOne>
    <S.ReasonsContainer>
      <S.ReasonsTitle>{i18n.t('whyChooseUsBlock.title')}</S.ReasonsTitle>
      <S.ReasonsListContainer>
        {whyChooseUs.map((reason, index) => (
          <S.ReasonValue key={index}>
            <span>{i18n.t(reason.key).trim(' ')}</span>
          </S.ReasonValue>
        ))}
      </S.ReasonsListContainer>
    </S.ReasonsContainer>
  </S.ReasonSideFiller>
)
