import { VPS_MODAL_TYPES } from './VpsModal.types';

const initialState = {
  vpsInfoLoading: false,
  vpsInfo: {},
  error: null,
  showPass: false,
};

export const vpsInfo = (state = initialState, action) => {
  switch (action.type) {
    case VPS_MODAL_TYPES.GET_VPS_INFO_REQUEST:
      return {
        ...state,
        vpsInfoLoading: true,
        vpsInfo: {},
      };

    case VPS_MODAL_TYPES.GET_VPS_INFO_SUCCESS: {
      return {
        ...state,
        vpsInfoLoading: false,
        vpsInfo: action.data,
      };
    }
    case VPS_MODAL_TYPES.GET_VPS_INFO_FAIL:
      return {
        ...state,
        vpsInfoLoading: false,
        vpsInfo: {},
      };

    case VPS_MODAL_TYPES.TOGGLE_PASSWORD: {
      return {
        ...state,
        showPass: !state.showPass,
      };
    }
    default:
      return state;
  }
};
