import { connect } from "react-redux";

import { Sort as Self } from "./SortPopup";
import { showSortPopup } from "../../ClientAreaPage.actions";

const mapStateToProps = ({ clientAreaPage }) => ({
  isShown: clientAreaPage.sortPopupIsShown,
});

const mapDispatchToProps = {
  showSortPopup,
};

export const Sort = connect(mapStateToProps, mapDispatchToProps)(Self);
