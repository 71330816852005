import React, { useRef, useCallback } from 'react'

import * as S from './Cart.styles'
import { i18n } from '../../../i18n'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { Tooltip } from '../../Tooltip'
import { ConfirmModal } from '../../Modals/ConfirmModal'

import { changeUrl } from '../../../hooks/changeUrl'
import { validateAdditionalField } from '../../../helpers/validateRuDomain'

const Cart = ({
  history,
  auth,
  cart,
  showCart,
  removeFromCart,
  isShown,
  numberWithCommas,
  showModal,
}) => {
  const containerRef = useRef(null)

  useOnClickOutside(containerRef, () => showCart(false))

  const goToOrderDetails = useCallback(() => {
    history.push(changeUrl('/order-details'))
    showCart(false)
  }, [history, showCart])

  if (!isShown) {
    return null
  }

  return (
    <S.CartContainer ref={containerRef}>
      {!!cart.length ? (
        <>
          <S.CartInfo>
            {!!cart.filter((item) => item.type === 'domain').length && (
              <>
                <S.Section>
                  <S.Header>{i18n.t('cart.domain')}</S.Header>
                  {cart
                    .filter((item) => item.type === 'domain')
                    .map((item) => (
                      <S.SelectedItem key={item.id}>
                        <S.Row>
                          <S.DomainName>
                            {item.domain}
                            <span>{item.tld}</span>
                          </S.DomainName>
                          <S.Icons>
                            <S.Delete
                              onClick={() =>
                                showModal(ConfirmModal, {
                                  onSuccessFn: () => removeFromCart(item),
                                  modalType: 'danger',
                                  successBtnTextKey: 'cart.modalDeleteBtn',
                                  subheaderKey: 'cart.modalDeleteSubtitle',
                                })
                              }
                            />
                            <S.Settings
                              to={changeUrl(`/domain-settings?id=${item._id}`)}
                              disabled={
                                `${history.location.pathname}${history.location.search}` ===
                                `/domain-settings?id=${item._id}`
                              }
                            />
                          </S.Icons>
                        </S.Row>
                        <S.SelectedCart>
                          <S.DomainNameWrapper>
                            {item.type === 'domain' &&
                              item.tld === '.ru' &&
                              validateAdditionalField(
                                item,
                                undefined,
                                undefined,
                                auth.legalStatus
                              ) && (
                                <S.WarningText
                                  to={changeUrl(`/domain-settings`)}
                                >
                                  {i18n.t(
                                    'orderDetailsPage.productInfoMissing'
                                  )}
                                </S.WarningText>
                              )}
                          </S.DomainNameWrapper>
                          <S.Price>
                            {numberWithCommas(
                              auth.currency === 2
                                ? (item.premium_price_usd || item.price_usd) *
                                    item.years
                                : (item.premium_price_amd || item.price) *
                                    item.years
                            )}
                          </S.Price>
                        </S.SelectedCart>
                      </S.SelectedItem>
                    ))}
                </S.Section>
                <S.DividerBorder />
              </>
            )}
            {!!cart.filter((item) => item.type === 'hosting').length && (
              <S.Section>
                <S.Header>{i18n.t('cart.hosting')}</S.Header>
                {cart
                  .filter((item) => item.type === 'hosting')
                  .map((item) => (
                    <S.SelectedItem key={item._id}>
                      <S.Row>
                        <S.PackageName>{item.name}</S.PackageName>
                        <S.Icons>
                          <S.Delete
                            onClick={() =>
                              showModal(ConfirmModal, {
                                onSuccessFn: () => removeFromCart(item),
                                modalType: 'danger',
                                successBtnTextKey: 'cart.modalDeleteBtn',
                                subheaderKey: 'cart.modalDeleteSubtitle',
                              })
                            }
                          />
                          <S.Settings
                            to={changeUrl(`/product-details?id=${item._id}`)}
                            disabled={
                              `${history.location.pathname}${history.location.search}` ===
                              changeUrl(`/product-details?id=${item._id}`)
                            }
                          />
                        </S.Icons>
                      </S.Row>
                      <S.SelectedCart>
                        <S.DomainName>
                          {item.domain || (
                            <S.WarningText
                              to={changeUrl(`/product-details?id=${item._id}`)}
                            >
                              {i18n.t('cart.productInfoIncomplete')}
                            </S.WarningText>
                          )}
                          <span>{item.tld}</span>
                        </S.DomainName>
                        <S.Price>
                          {numberWithCommas(
                            auth.currency === 2
                              ? (
                                  item.price_usd *
                                  (item.time ? item.time.value : 1)
                                ).toFixed(1)
                              : item.price * (item.time ? item.time.value : 1)
                          )}
                        </S.Price>
                      </S.SelectedCart>
                    </S.SelectedItem>
                  ))}
              </S.Section>
            )}
            {!!cart.filter((item) => item.type === 'vps').length && (
              <S.Section>
                <S.Header>{i18n.t('cart.vps')}</S.Header>
                {cart
                  .filter((item) => item.type === 'vps')
                  .map((item) => (
                    <S.SelectedItem key={item._id}>
                      <S.Row>
                        <S.PackageName>{item.productServerSize}</S.PackageName>
                        <S.Icons>
                          <S.Delete
                            onClick={() =>
                              showModal(ConfirmModal, {
                                onSuccessFn: () => removeFromCart(item),
                                modalType: 'danger',
                                successBtnTextKey: 'cart.modalDeleteBtn',
                                subheaderKey: 'cart.modalDeleteSubtitle',
                              })
                            }
                          />
                        </S.Icons>
                      </S.Row>
                      <S.SelectedCart>
                        <S.AdditionalData>
                          <S.AdditionalDataItem>
                            {item.locationName}
                          </S.AdditionalDataItem>
                          <S.AdditionalDataItem>
                            {item.osName}
                          </S.AdditionalDataItem>
                        </S.AdditionalData>
                        <S.Price>
                          {numberWithCommas(
                            auth.currency === 2
                              ? (
                                  item.price_usd *
                                  (item.time ? item.time.value : 1)
                                ).toFixed(1)
                              : item.price * (item.time ? item.time.value : 1)
                          )}
                        </S.Price>
                      </S.SelectedCart>
                    </S.SelectedItem>
                  ))}
              </S.Section>
            )}
            {!!cart.filter((item) => item.type === 'ssl').length && (
              <S.Section>
                <S.Header>{i18n.t('cart.ssl')}</S.Header>
                {cart
                  .filter((item) => item.type === 'ssl')
                  .map((item) => (
                    <S.SelectedItem key={item.id}>
                      <S.Row>
                        <S.PackageName>{item.name}</S.PackageName>
                        <S.Icons>
                          <S.Delete
                            onClick={() =>
                              showModal(ConfirmModal, {
                                onSuccessFn: () => removeFromCart(item),
                                modalType: 'danger',
                                successBtnTextKey: 'cart.modalDeleteBtn',
                                subheaderKey: 'cart.modalDeleteSubtitle',
                              })
                            }
                          />
                        </S.Icons>
                      </S.Row>
                      <S.SelectedCart>
                        <S.Price>
                          {numberWithCommas(
                            auth.currency === 2 ? item.price_usd : item.price
                          )}
                        </S.Price>
                      </S.SelectedCart>
                    </S.SelectedItem>
                  ))}
              </S.Section>
            )}
            {!!cart.filter((item) => item.type === 'constructor').length && (
              <S.Section>
                <S.Header>{i18n.t('cart.constructor')}</S.Header>
                {cart
                  .filter((item) => item.type === 'constructor')
                  .map((item) => (
                    <S.SelectedItem key={item.id}>
                      <S.Row>
                        <S.PackageName>{item.name}</S.PackageName>
                        <S.Icons>
                          <S.Delete
                            onClick={() =>
                              showModal(ConfirmModal, {
                                onSuccessFn: () => removeFromCart(item),
                                modalType: 'danger',
                                successBtnTextKey: 'cart.modalDeleteBtn',
                                subheaderKey: 'cart.modalDeleteSubtitle',
                              })
                            }
                          />
                          <S.Settings
                            to={changeUrl(`/product-details?id=${item._id}`)}
                            disabled={
                              `${history.location.pathname}${history.location.search}` ===
                              changeUrl(`/product-details?id=${item._id}`)
                            }
                          />
                        </S.Icons>
                      </S.Row>
                      <S.SelectedCart>
                        <S.DomainName>
                          {item.domain || (
                            <S.WarningText
                              to={changeUrl(`/product-details?id=${item._id}`)}
                              onClick={() => showCart(false)}
                            >
                              {i18n.t('cart.productInfoIncomplete')}
                            </S.WarningText>
                          )}
                          <span>{item.tld}</span>
                        </S.DomainName>
                        <S.Price>
                          {numberWithCommas(
                            auth.currency === 2
                              ? (item.price_usd * item.time.value).toFixed(1)
                              : item.price * item.time.value
                          )}
                        </S.Price>
                      </S.SelectedCart>
                    </S.SelectedItem>
                  ))}
              </S.Section>
            )}
            <S.TotalContainer>
              <S.TotalText>{i18n.t('cart.total')}</S.TotalText>
              <S.TotalPrice>
                {numberWithCommas(
                  cart
                    .reduce(
                      (a, b) => {
                        const bPrice =
                          auth.currency === 2
                            ? (b.premium_price_usd || b.price_usd) *
                              (b.years || 1)
                            : (b.premium_price_amd || b.price) * (b.years || 1)
                        let first = a.time
                          ? a.time.value * a.price
                          : a.price * (a.years || 1)
                        let second = b.time ? b.time.value * bPrice : bPrice

                        return { price: first + second }
                      },
                      { price: 0 }
                    )
                    .price.toFixed(1)
                )}
              </S.TotalPrice>
            </S.TotalContainer>
          </S.CartInfo>
          <S.Button
            className="tooltipContainer"
            onClick={goToOrderDetails}
            disabled={cart.some(
              (item) =>
                (['constructor', 'hosting'].includes(item.type) &&
                  !item.domain) ||
                (item.type === 'domain' &&
                  item.tld === '.ru' &&
                  validateAdditionalField(
                    item,
                    undefined,
                    undefined,
                    auth.legalStatus
                  ))
            )}
          >
            {cart.some(
              (item) =>
                (['constructor', 'hosting'].includes(item.type) &&
                  !item.domain) ||
                (item.type === 'domain' &&
                  item.tld === '.ru' &&
                  validateAdditionalField(
                    item,
                    undefined,
                    undefined,
                    auth.legalStatus
                  ))
            ) && (
              <Tooltip className="tooltip top">
                {i18n.t('tooltips.cartProblemTooltip')}
              </Tooltip>
            )}
            {i18n.t('cart.orderNow')}
          </S.Button>
        </>
      ) : (
        <S.EmptyCart>
          <S.EmptyCartText>{i18n.t('cart.emptyCart')}</S.EmptyCartText>
          <S.EmojiIcon />
        </S.EmptyCart>
      )}
    </S.CartContainer>
  )
}

export { Cart }
