import * as Yup from 'yup';

export const initialValues = {
  number1: '',
  number2: '',
  number3: '',
  number4: '',
  number5: '',
  number6: '',
};

export const yupScheme = Yup.object().shape({
  number1: Yup.number().max(1),
  number2: Yup.number().max(1),
  number3: Yup.number().max(1),
  number4: Yup.number().max(1),
  number5: Yup.number().max(1),
  number6: Yup.number().max(1),
});
