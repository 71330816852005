import React, { useEffect } from 'react'
import * as S from '../SslConfigurationPage.styles'
import { Formik, Form } from 'formik'
import { getInitialValues, yupScheme } from '../SecondStep/SecondStep.config'
import { changeUrl } from '../../../hooks/changeUrl'
import { i18n } from '../../../i18n'
import { Trans } from 'react-i18next'

export const ThirdStep = ({ history, nextStepData }) => {
  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.replace(changeUrl('/client-area'))
      }
    }
  }, [history])

  return (
    <>
      <Formik
        initialValues={() => getInitialValues()}
        validationSchema={yupScheme}
        onSubmit={() => {}}
      >
        {({ touched, errors, handleChange, values, setFieldValue }) => {
          return (
            <Form>
              <S.CompletedMessage>
                <S.Completed>{i18n.t('sslConfiguration.message')}</S.Completed>
                <S.EmailInfo>
                  <Trans
                    i18nkey="sslConfiguration.emailMessage"
                    defaults={i18n.t('sslConfiguration.emailMessage', {
                      email: nextStepData?.user_email,
                    })}
                    components={{ b: <strong /> }}
                  />
                </S.EmailInfo>
              </S.CompletedMessage>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
