import * as Yup from 'yup';

export const initialValues = {
  domain: '',
};

export const yupScheme = Yup.object().shape({
  domain: Yup.string()
    .matches(
      /^[a-zA-Z0-9\u0561-\u0587\u0531-\u0556][a-zA-Z0-9-\u0561-\u0587\u0531-\u0556]{1,61}[a-zA-Z0-9\u0561-\u0587\u0531-\u0556](?:\.[a-zA-Z\u0561-\u0587\u0531-\u0556]{2,})+$/,
      'validationFields.invalidTyping'
    )
    .required('validationFields.domain'),
});
