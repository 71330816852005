import { backendEn } from '../backend/en'

export const en = {
  translation: {
    ...backendEn,
    header: {
      domain: 'Domain',
      constructor: 'Constructor',
      hosting: 'Hosting',
      vps: 'VPS',
      ssl: 'SSL',
      support: 'Support',
      registration: 'Registration',
      login: 'Log In',
      logout: 'Log out',
      blog: 'Blog',
    },
    loginModal: {
      title: 'Log In',
      mail: 'E-mail',
      password: 'Password',
      client: 'Client',
      rememberMe: 'Remember me',
      question: 'Forgot Password?',
      login: 'Log In',
      registerNow: 'Register',
      resendEmail: 'Resend email',
      emailSent: 'The email has been resent',
    },
    cookiesModal: {
      accept: 'I understand',
      header: 'This website uses cookies',
      text: 'This website uses cookies to deliver better user experience',
    },
    maintenanceModal: {
      warning: 'Dear valued partners,',
      info1:
        'we have received a notification from Information Technologies Center that technical maintenance planned on 8 May 2022. This may cause a short-term unavailability of services (4 to 5 hours) between 10:00 and 20:00.',
      // "due to technical maintenance planned on 25 and 26 January, 2022 you may face certain problems while using the website.",
      info2:
        'We do our best to provide excellent service to our clients. However, with heavy update, some temporary issues can be noticed.',
      info3: 'Thank you for cooperation and understanding.',
      info4: 'ABCDomain team',
    },

    personalDetailsSuccessModal: {
      text: 'Changes are saved successsfully',
    },
    authModal: {
      title: 'Enter Two-Factor Authentication Code',
      header: 'Enter the 6-digit code that you see in your autenticator app',
      confirmButton: 'Confirm',
      cancelButton: 'Cancel',
    },
    resetPasswordModal: {
      title: 'Forgot password',
      subTitle:
        'Forgot password? Enter Your e-mail to begin the password recovery process',
      mail: 'E-mail',
      placeholder: 'example@example.com',
      send: 'Send',
    },
    resetPasswordSuccessModal: {
      header: 'Your password reset process has started',
      subHeader: 'Please check Your email',
    },

    domainUnavailableModal: {
      header: "We can't complete Your purchase at this time.",
      subHeader: 'Please check domain availability again',
      message: 'The specified domain(s) is(are) unavailable:',
    },
    underMaintenanceModal: {
      header:
        'At the moment technical works are being carried out on the website and failures are possible.',
    },
    changePasswordSuccessModal: {
      header: 'Your password has been changed successfully',
    },
    openTicketSuccesModal: {
      title: 'Thank you!',
      header: 'Your ticket has been accepted',
      subHeader: 'Please check Your email',
    },
    transferDomainModal: {
      title: 'Transfer Domain',
      domainLabel: 'Domain',
      domainPlaceholder: 'example.am',
      eppCodeLabel: 'EPP Code',
      eppCodePlaceholder: 'EPP Code',
      submit: 'Transfer',
    },

    domainConfigurationPageLabels: {
      labelId: 'ID number',
      labelTin: 'Taxpayer identification number',
      labelDob: 'Date of birth',
      labelPassportIssuer: 'Passport issuer',
      labelPassportSeries: 'Passport series',
      labelPassportDate: 'Passport issue date',
      labelPhonenumber: 'Phonenumber',
      labelLegalAddress: 'Legal address',
      labelPostalAddress: 'Postal address',
      labelCompanyName: 'Company name',
    },

    domainConfigurationPageSubLabels: {
      subLabelId: '*ID or Passport number',
      subLabelTin: '*Only required for organizations',
      subLabelDob: '*Only required for persons',
    },

    transferDomainSuccessModal: {
      title: 'Thank you!',
      header: 'Your request is accepted',
      subHeader: 'We hope you will get some good news soon!',
    },
    footer: {
      main: 'Main',
      company: 'Company',
      aboutUs: 'About the company',
      generalTerms: 'General terms and conditions',
      specialTerms: 'Special terms and conditions',
      contactUs: 'Contact Us',
      useful: 'Useful',
      punycodeConverter: 'Punycode converter',
      contactDomainOwner: 'Contact Domain Holder',
      charter: 'ICANN charter',
      icannLink: 'https://www.icann.org/resources/pages/benefits-2013-09-16-en',
      ip: 'IP:',
      hosting: 'Hosting',
      vpsServers: 'VPS Packages',
      linuxHosting: 'Linux Hosting',
      openTicket: 'Open a support application',
    },

    editProfilePage: {
      title: 'My account',
      personalDetails: 'Personal Details',
      contacts: 'Contacts/Sub-accounts',
      security: 'Security',
      personalDetailsComponent: {
        saveButton: 'Save',
      },
      securityComponent: {
        currentPassword: 'Current password',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        noteHeader: 'Tip for a secure password',
        noteTextOne:
          'At least 12 characters long combination of at least one uppercase letter, one lowercase letter',
        noteTextTwo: 'Use special symbols and numbers (# $ ! % & etc.)',
        noteTextThree:
          'Do not use personal information, common words and phrases',
        linkText: 'Activate 2 factor authentication',
        linkText2: 'Deactivate 2 Factor authentication',
        confirmButton: 'Confirm',
        cancelButton: 'Cancel',
      },
      contactComponent: {
        addContact: 'Add contact +',
      },
    },

    aboutUsPage: {
      title: 'About ABCDomen Company',
      header:
        "Our efforts are aimed at satisfying our partners' needs and wants",
      paragraphOne:
        'The ABCDomen company was founded in 2007, including employees from the domain registration, hosting and web design divisions of the Arminco company. Our domain is <a href=https://internet.am/en/   target="_blank">internet.am</a>, which emphasizes the continuity of the traditions of the Arminco company - the first Internet provider in Armenia.',
      paragraphTwo:
        'ABCDomen Company is accredited by the Internet Society of Armenia - <a href=https://www.isoc.am/  target="_blank">ISOC.AM</a> as a registrar of domain names in the .am and հայ zones. The company has gone from creating its own data center in Armenia to collaborating with leading providers of cloud solutions in Europe, providing new services, and registering domains in most domain zones in the world. <a href=https://internet.am/en/domain-registration   target="_blank">Learn more about domain registration.</a>',
      paragraphThree:
        'The company is a Local Internet Registry (LIR), registered with RIPE (Resource IP European). Cybersecurity solutions are provided by the company itself, which acts as the Computer Emergency Response Team (CERT) for all domains registered in the .am and .հայ zones.',
      paragraphFour:
        'The development of technology and the constant increase in online user engagement allowed the company to switch to online user services from 2020 with high efficiency, allowing users to save time and not visit the company’s office.',
      paragraphFive:
        'An important activity of the company is supporting the creation of websites using a website builder, which allows small and medium-sized companies to independently create their websites using the website builder. <a href=https://internet.am/en/constructor-packages   target="_blank">Learn more about our website builder.</a>',
      paragraphSix:
        'For advanced users, the company provides virtual private server services - VPS, which allows you to independently manage your server with your own original software. <a href=https://internet.am/en/vps-packages   target="_blank">Learn more about VPS services.</a>',
      paragraphSeven:
        'Users working with our company have the opportunity to host their website, which allows them to carry out the whole range of work: domain registration, website creation, website hosting, installation of an SSL certificate from one window. Installing an SSL certificate today is the most important component of the effective operation of a website in the modern world. <a href=https://internet.am/en/hosting-packages  target="_blank"> Learn more about website hosting </a> and SSL certificates <a href=https://internet.am/en/ssl-packages  target="_blank">Learn more about SSL.</a>',
      paragraphEight:
        'The development of the Internet means the emergence of new challenges, we carefully monitor all new trends and respond to them accordingly. Today, all developed countries are taking measures to ensure the protection of personal data and ensure privacy. ABCDomain, in turn, follows the rules of the General Data Protection Regulation GDPR, while simultaneously providing the opportunity for interested parties to communicate with the domain owner in the .am and .հայ zones.<a href=https://internet.am/en/blog/domain-holder  target="_blank">Learn more about domain holder communication.</a>',
      welcome:
        'Welcome to our website <a href=https://internet.am/en/   target="_blank">internet.am!</a>',
      phone: 'Our phone: +37460270027',
      email: 'Our e-mail address: info@internet.am',
      rigthsForm:
        'FORM OF CHARTER OF RIGHTS AND OBLIGATIONS OF REGISTRATION OWNERS',
    },
    specialTermsPage: {
      title: 'Special terms and conditions',

      content:
        'ABCDomain  provides services on the basis of offer agreements. Contract details for each service are provided in PDF format. All agreements contain text in Armenian, Russian and English the text in Armenian is legally significant. Translations into English and Russian are for informational purposes only. Along with our main services, such as<br/><br/>' +
        'buy a domain <a href="https://internet.am/en/domain-registration" target="_blank"> https://internet.am/en/domain-registration</a><br/><br/>' +
        'domain hosting <a href="https://internet.am/en/hosting-packages" target="_blank"> https://internet.am/en/hosting-packages</a><br/><br/>' +
        'provision of an SSL certificate <a href="https://internet.am/en/ssl-packages" target="_blank">https://internet.am/en/ssl-packages</a><br/><br/>' +
        'developing websites using a website builder <a href="https://internet.am/en/constructor-packages" target="_blank">https://internet.am/en/constructor-packages</a> <br/><br/>' +
        'provision of VPS VDS dedicated server services  <a href="https://internet.am/en/vps-packages" target="_blank"> https://internet.am/en/vps-packages</a><br/><br/>' +
        'Among our offers, you can find services for hosting your servers in the data center of the ABCDomain company and creating your dedicated mail server on the territory of Armenia. It is important to note that we host not only servers operating under the.am domain but servers from any domain zone. <br/><br/>' +
        'When ordering an SSL certificate type EV it should be borne in mind that extended validation is a rather complex procedure that may require more time compared to a regular SSL certificate. Installing an SSL certificate requires certain knowledge from the developer of your website. <br/><br/>' +
        ' When using business card website builders, the webmaster does not need to study in detail all the intricacies of working with the website builder, however, when creating complex sites, for example, an electronic store, a more thorough approach is required. It is important to note that when using the website builder, you receive a free SSL certificate.<br/><br/>' +
        'Using IDN - Internationalized Domain Name ․հայ will allow you to save five times on its registration, and you will have the opportunity to be in higher positions in the Google search engine when your users are asking for questions in Armenian. In addition, using the .հայ domain allows you to present your organization with the correct pronunciation of the name of your organization to Armenian users.<br/><br/>' +
        'The service of hosting your servers in the data center of the ABCDomain company is provided after agreeing on the terms of placement with the customer, clarifying the dimensions of the servers, power consumption, and the required Internet connection speed.<br/><br/>' +
        'The ABCDomain company also accepts individual orders, such as providing a dedicated IP address for a website, developing and installing a dedicated email server that sends messages to users of the customer company, and other individual projects. In the event of such needs, an individual contract will be drawn up with you.',
      coLocation: 'Co-location Service',
      dedicatedServer: 'Dedicated Server Service',
      mailDelivery: 'Mail delivery Service',
      mail: 'Mail Service',
      dns: 'DNS',
      domains: 'Domains',
      hosting: 'Hosting',
      ssl: 'SSL',
    },
    contactUsPage: {
      title: 'Contact Us',
      header: "We're ready and wait for your questions",
      tabs: {
        sales: 'Sales',
        salesDescription:
          'To contact sales department please use the phone number within business hours  or contact 24/7 online chat.',
        info: 'Tel: +374 60 270027',
        mail: 'info@internet.am',
        href: 'mailto: info@internet.am',
        onlineChat: 'Online chat',
        chatDescription:
          'Use our online chat. Our specialists will help you anytime of the day.',
        techSupport: 'Technical support',
        techDescription:
          'Our technical support specialists are the best in the market. If case of any question you will get the response shortly.',
        support: 'Open a support ticket',
        hq: 'Information',
        hqDescription: '',
      },
    },
    csrModal: {
      uploadCsr: 'Upload CSR',
      generateCsr: 'Generate CSR',
      downloadKey: 'Download key',
      title: 'Generate a Certificate Signing Request',
      n1: 'Country code',
      n2: 'Region/State',
      n3: 'City',
      n4: 'Company name',
      n5: 'Legal status',
      n6: 'Domain',
      n7: 'E֊mail',
      button: 'Generate',
      generateCsrDisclaimer:
        'Attention: Save the private key by all means. You will not be able to recover your certificate without it. Confirm button will be active when you download the private key.',
      generateCsrDisclaimer2:
        'Attention: Save the private key by all means. You will not be able to recover your certificate without it.',

      downloadCsr: 'Download CSR',
      downloadPrivateKey: 'Download Private key',
      copyPasteCsrHere: 'Paste your own CSR here',
      confirmCsr: 'Confirm',
      validationType: 'Validation type',
      validationEmail: 'Validation email',
      fileValidation:
        'The instructions how to accomplish file validation will be sent via ticket',
      onlyUpperCase: 'Country code must be on upper case',
    },
    uploadCsrSuccessModal: {
      title: 'Thank you!',
      header: 'CSR confirmation is in process.',
      subHeader:
        'After confirmation you will receive the certificate and setup information by email.',
    },
    contactDomainOwnerModal: {
      title: 'Contact Domain Holder',
      domainLabel: 'Domain name',
      domainPlaceholder: 'example.am',
      nameLabel: 'Your name',
      namePlaceholder: 'Poghos Poghosyan',
      mailLabel: 'Reply to Email',
      mailPlaceholder: 'example@example.com',
      subjectLabel: 'Subject',
      subjectPlaceholder: 'About domain',
      messageLabel: 'Message',
      messagePlaceholder: 'Please contact me',
      submit: 'Submit',
    },
    contactOwnerSuccesModal: {
      title: 'Thank you!',
      header: 'We have sent your email to the domain holder',
      subHeader: 'We hope you will get some good news soon!',
    },
    domainsPage: {
      title: 'Domain search and registration',
      header: 'Enter the domain name',
      searchButton: 'Search',
      choosedHeader: 'Selected',
      totalText: 'Total',
      priceText: '/Annually',
      orderButton: 'Order Now',
      isAdded: 'Added',
      add: 'Add',
      showMore: 'Show more +',
      showed: 'Shown',
      mobileChoosen: 'Choosen',
      whois: 'Whois',
      showUnavialables: 'Show unavailables',
      hideUnavialables: 'Hide unavailables',
      unavailable: 'Unavailable',
      content:
        'Domain registration - general information<br/><br/>' +
        'The Domain Name System began in 1984 when the first name server was installed at the University of Wisconsin. In 1985, the domains .com, .org, .net began to operate. The function of a name server is quite simple - to associate a domain name with the IP address of a computer (combination of digits, like a telephone number) that is connected to the Internet. After accessing a site in a browser, your computer first finds out the site’s IP address from the name server, and then makes a connection using the received IP address.<br/><br/>' +
        'The engineers and scientists who created the Internet divided domains into two large groups - ccTLD (country code Top Level Domain) and generic domains (gTLD - generic Top Level Domain). All mapping tables between ccTLDs and gTLDs and their IP addresses are stored in the IANA database, which is managed by ICANN, a corporation registered in the State of California, USA.<br/><br/>' +
        'Technically, access to this database is carried out using 13 supercomputers, which until recently answered the queries of all Internet users - which IP address corresponds to the requested domain. Today, numerous mirrors of these 13 supercomputers have been created, and the user accesses the nearest mirror, speeding up the response. In Armenia, mirror servers are installed in the Internet Society of Armenia (www.isoc.am) and the MTS-Armenia company.<br/><br/>' +
        'When choosing a domain zone for registering your domain, you need to take into account that in the case of a gTLD, the regulation of your rights and obligations is carried out according to the rules developed by ICANN and the requirements that can be added by the company that acquired the rights to manage this domain from ICANN.<br/><br/>' +
        'If you choose a ccTLD domain, then the regulation of your rights and obligations will be carried out in accordance with the policies of the organization that has received the rights to administer the domain. In Armenia, the policy is available at the link <a href="https://www.am/policy/en/Policy_EN.pdf" target="_blank">https://www.am/policy/en/Policy_EN.pdf</a>.<br/><br/>' +
        'It is important to keep in mind that the ccTLD does not belong to this country; the Internet developers took the two-letter code from the ISO directory; for Armenia it is .am. In different countries, the policy for registering and using domain names may differ radically; before registering, it is advisable to look at what features exist when registering a domain in a given domain zone.<br/><br/>' +
        'In 2012, ICANN began the process of creating new gTLDs, the number of which has now reached 2000.<br/><br/>' +
        'At the same time, the process of creating new domain zones began, both ccTLD and gTLD in national languages IDN (Internationalized Domain Name). Armenia, as a result of lengthy discussions, launched the domain zone .հայ․ in 2014 Note that the principles of regulation for IDN users do not differ from those for Latin-language domains.',
    },
    constructorPage: {
      title: 'Website Builder',
      subTitle: 'Best Constructor packages for you',
      headerTitle: 'Create your own site',
      headerButton: 'Start now',
      headerText: 'Build your business with our constructor- easy and fast',
      videoTitle1: 'Simple Guide',
      videoTitle2: 'For Creating',
      videoTitle3: 'Site using Constructor',
      videoSubTitle1: '1. Import your preferred site',
      videoSubTitle2: '2. Make necessary changes',
      videoSubTitle3: '3. Publish',
      videoButton: 'Start now',
      advantages: {
        title: 'Advantages',
        n1: 'Fast',
        n2: 'Available',
        n3: 'Beautiful',
        n4: 'SEO/SSM optimization',
        n5: 'Optimization for different devices',
      },
      packagesTitle: 'Packages',
      compareTitle: 'Compare site and constructor',
      compareRentSite: {
        title: 'Create web-site',
        n1: 'Expensive',
        n2: 'Time consuming',
        n3: 'With technical problems',
        n4: 'Updating headache',
        n5: 'Difficult optimization for every device',
        n6: 'Difficult optimization for all social medias and search engines',
        n7: 'Unlimited functionality',
      },
      compareBuyConstructor: {
        title: 'Buy constructor',
        n1: 'Cheap',
        n2: 'Fast',
        n3: 'Without technical problems',
        n4: 'Updates are done by you',
        n5: 'Simple optimization for every device',
        n6: 'Simple optimization for all social medias and search engines',
        n7: 'Limited functionality',
      },
      HaveQuestionTitle: 'Any questions left?',
      HaveQuestionSubTitle: 'Address Online Chat!',
    },
    constructorComponent: {
      area: 'Available Space',
      pages: 'Max. pages',
      mails: 'E-mails',
      ssl: 'SSL certificate',
      orderNow: 'Order now',
    },

    hostingPage: {
      title: 'Hosting packages',
      sTitle: 'The best packages for You',
      vServers: 'Virtual servers',
      lHosting: 'Linux hosting',
      orderNow: 'Order now',
      advatnages: 'Advantages in all packages',
      mails: 'E-mails',
      capacity: 'Capacity',
      unlimited: 'Unlimited',
      db: 'DB-s',
      space: 'Available Space',
      subdomain: 'Subdomain',
      addon: 'addon',
      alias: 'alias',
      createSite: 'Create your site',
      createSiteBold: 'yourself',
      useConstructor: 'Build your website with our constructor fast and nice!',
      helpHeader: 'Thousands of companies have already chosen ABCDomain',
      liveChat:
        "Aren't you oriented in choosing the package? Write to our specialists via online chat",
      orderNowText: 'If you have already selected the package, order now',
      users: '10000+ users',
      usersText: 'have chosen our services',
      cms: 'Support for most popular CMS',
      abcAvd: 'Work with us',
      hostingAdv1: 'PHP',
      hostingAdv2: 'Unlimited traffic',
      hostingAdv3: 'Control hosting by Cpanel',
      hostingAdv4: 'DNS',
      hostingAdv5: 'FTP access',
      hostingAdv6: 'Server OS - CentOS',
      hostingAdv7: 'MySQL',
      hostingAdv8: 'Weekly backup',
      adv1: 'Favorable conditions, no hidden fees',
      adv2: 'Technical support via support application or via 24/7 chat',
      adv3: 'Unified service control center',
      adv4: 'Guaranteed provision of declared resources',
      adv5: 'Instant power boost capability',
      adv6: 'Highly efficient spam filtering',
      adv7: 'Discounts for internet.am partners',
      adv8: 'Advanced spam protection',
      contacUsHeader: 'Any questions left?',
      contacUsSubHeader: 'Address Online Chat!',
    },
    vpsPage: {
      vServers: 'VPS on Linux system',
      sTitle: 'Ubuntu, Debian, Fedora etc.',
      sLocation: 'Servers Location',
      serverType: 'Server OS',
      serverSize: 'Server Size',
      hostnameLabel: 'Server Label',
      hostnamePlaceholder: 'Server IP',
      labelPlaceholder: 'Server label',
      aditionalFeatures: 'Aditional Features',
      enable: 'Enable ',
      backup: 'Auto Backups',
      forward: 'Next',
      serverQ: 'Number of servers',
      location: 'Location',
      type: 'OS',
      size: 'Size',
      price: 'Price',
      registerNow: 'Next',
    },
    sslPage: {
      title: 'Buy SSL Certificates for a Secure Internet Experience',
      domainValidation: 'Domain Validation',
      companyValidation: 'Organization Validation',
      extendedValidation: 'Extended Validation',
      orderNow: 'Order now',
    },
    supportPage: {
      title: 'Knowledge base',
      placeholderText: 'Your question',
      search: 'Search',
      knownSection: 'Frequent',
      categories: 'By categories',
      searchResults: 'Search Results',
      noSearchResults: 'No results found',
    },
    cart: {
      domain: 'Domain',
      hosting: 'Hosting',
      vps: 'VPS',
      ssl: 'SSL',
      constructor: 'Constructor',
      total: 'Total',
      orderNow: 'Order now',
      emptyCart: 'Your cart is empty',
      productInfoIncomplete: 'Please insert domain',
      modalDeleteBtn: 'Delete',
      modalDeleteSubtitle: 'Do you really want to delete this items?',
    },
    aboutMobileAppBlock: {
      title: 'Download our',
      titleBold: 'new application',
      list: [
        'Domain search and registration',
        'Payment for services via bank cards and PayPal',
        'Support ticket management',
        'Receipt of notifications',
      ],
    },
    searchAreaBlock: {
      title: 'More than 20 domain zones',
      search: 'Search',
      info: 'Show all the possible domain extensions',
    },
    hostingInfoBlock: {
      title: 'Shared Linux Hosting packages',
      packageInfo:
        'Different hosting packages: Shared Hosting and virtual servers wide range',
      more: 'more',
    },
    hostingPackageBlock: {
      buy: 'Buy',
    },
    servicesBlock: {
      title:
        'Shared Hosting (Linux), virtual servers, web site constructors, SSL certificates',
      description:
        'Different hosting packages: Shared Hosting (Linux), virtual servers, web site constructors, SSL certificates and more',
      more: 'More',
      hosting: 'Hosting',
      hostingDescription:
        'A modern hosting service that allows you to host different advanced websites made with popular frameworks: Wordpress, Joomla, Laravel. PHP version selection, MariaDB/MySQL databases and much more.',
      ssl: 'SSL certificates',
      sslDescription:
        'SSL Certificates from the best providers. With Domain, Organization and Extended validation, as well as Wildcard certificates with the best prices',
      constructor: 'Website constructor',
      constructorDescription:
        'Do you need a simple website? Do you need it quick? No need to worry. With our website constructor you can make a nice, high quality and mobile optimized website in a few hours.',
      cloudVPS: 'Cloud VPS',
      cloudVPSDescription:
        'We offer a large variety of VPS servers. We have VPS servers of any size and power. With their help you can host platforms, API-s and other systems',
    },

    SSlservicesBlock: {
      title:
        'Shared Hosting (Linux), virtual servers, web site constructors, SSL certificates',
      description:
        'Different hosting packages: Shared Hosting (Linux), virtual servers, web site constructors, SSL certificates and more',
      ssl_one: 'What is SSL?',
      sslDescription_one:
        'SSL (Secure Sockets Layer) is a standard security technology that establishes an encrypted link between a web server and a browser. It ensures that all data transmitted between the server and the browser remains private and secure.',
      ssl_two: 'Why SSL Certificates are Essential?',
      sslDescription_two:
        'SSL certificates are essential for website owners who value their visitors privacy and want to protect sensitive information such as login credentials, credit card details, and personal data. By having an SSL certificate, you enable HTTPS, which ensures that data is transmitted securely, and users see the reassuring padlock icon in their browsers.',
      ssl_three: 'Buy SSL Certificate in Armenia',
      sslDescription_three:
        'If you operate a website in Armenia, we offer SSL certificates that comply with the highest security standards. Secure your online presence and gain your visitors trust with our reliable SSL solutions. We also provide affordable SSL options, making it easy for you to get a cheap SSL certificate without compromising security.',
      ssl_four: 'HTTPS Everywhere',
      sslDescription_four:
        'HTTPS Everywhere is a browser extension developed by the Electronic Frontier Foundation (EFF). It ensures that your connection to websites is encrypted whenever possible, providing an extra layer of security even on websites without SSL certificates.',
      more: 'More',
    },
    whyChooseUsBlock: {
      title: 'Why us ?',
      key1: 'years of experience',
      value1: 'No 1',
      key2: 'professional team',
      value2: '3.3',
      key3: '24/7 support',
      value3: '111 K',
      key4: 'On domains market',
      value4: '24/7',
    },
    customerOpinionBlock: {
      title: "Customers' opinions",
    },
    homePageHosting: {
      month: 'monthly',
    },
    resetPasswordPage: {
      title: 'Reset Password',
      infoText: 'Enter new password',
      password: 'Password',
      confirmPassword: 'Confirm password',
      confirmButton: 'Confirm',
      failText: 'Your reset password link is invalid or has already expired',
      tryAgainButton: 'Reset again',
    },
    confirmEmailPage: {
      failText: 'Your registration link is invalid or has already expired',
    },
    registrationPage: {
      title: 'Registration',
      infoText:
        'The information provided during the registration is the basis for the identification  of the  purchased service holder. Only the Customer is responsible for incorrect or incomplete information provided.',
      infoText2:
        'Modern requirements for passwords: a reminder <br/><br/>' +
        'Modern password requirements on networks provide increased security as cyber threats constantly evolve. Here are some basic requirements for passwords that are considered important today and are advisable to use when registering with the ABCDomain Company:<br/><br/>' +
        'Password Length: The password must be long, usually at least 12 characters. Long passwords are more difficult to guess using brute-force methods.<br/><br/>' +
        'Complexity: The password must include a variety of characters, such as uppercase and lowercase letters, numbers, and special characters (for example, !, @, #, $, %). This makes it more difficult for burglars.<br/><br/>' +
        'Unusualness: The password must not be obvious or contain simple words, dictionary words, phrases or consecutive characters (for example, &quot;password&quot;, &quot;123456&quot; or &quot;qwerty&quot;). It is better to use random combinations of symbols.<br/><br/>' +
        'Personal data: do not use your personal data in passwords; first name, last name, and date of birth can help hackers when searching for your password by brute force.<br/><br/>' +
        'Uniqueness: A unique password must be used for each account. You should not use the same password to register on various social networks, online stores, and other sites where there is a registration requirement.<br/><br/>' +
        'Two-factor authentication (2FA): Carefully check whether the site where you are going to register has 2FA. This is an additional layer of security that requires you to enter an additional code that is sent to your email address.<br/><br/>' +
        'Update regularly: Passwords should be changed regularly, even if there is no suspicion that your account has been compromised.<br/><br/>' +
        'Using a phrase: Instead of a simple password, you can use long phrases that are easy to remember but difficult to guess (for example, “Thank you for your help!”).<br/><br/>' +
        'Password Managers: You can use password managers that generate and store unique and complex passwords for different accounts.<br/><br/>' +
        'If you are proficient in touch typing and use two languages, you can type the same phrase in the second language using the letters of the first language.<br/><br/>' +
        'Activity Monitoring: Check your accounts for suspicious activity and respond immediately.<br/><br/>' +
        'Training: Think of this text as a reminder about cybersecurity and the importance of secure passwords.<br/><br/>' +
        'Don&#39;t forget to stay tuned to the news in the field of cybersecurity and cyber hygiene.<br/><br/>' +
        'Compliance with these requirements will help strengthen the security of your accounts and prevent unauthorized access to them.',
      readMoreBtn: 'Read More',
      readLessBtn: 'Read Less',
      text: 'Only',
      textBold: 'Latin script',
      individual: 'Individual',
      entity: 'Legal person',
      firstname: 'First name',
      lastname: 'Last name',
      passport: 'Passport',
      companyName: 'Company name',
      companyType: 'Legal status',
      taxCode: 'Taxpayer Identification Numbers (TIN)',
      tel: 'Phone number',
      phonenumberPlaceholder: 'Enter phone number',
      email: 'E-mail',
      password: 'Password',
      confirmPassword: 'Confirm password',
      country: 'Country',
      state: 'Region/State',
      city: 'City',
      address: 'Address',
      postalIndex: 'Postal code',
      terms:
        'By registering on www.internet.am, I confirm that I have read and fully accept the General Terms and Conditions (including Special Terms and Conditions), the Price list presented in the website.',
      regButton: 'Register',
      autoPay:
        'I give my consent to be AUTOMATICALLY charged when invoices are presented in the current account.',
      imAgree: 'I do give my consent to the processing of my personal data',
      autopayment: 'Automatic renewal',
      autopayButton: 'I agree',
      reCaptchaError: 'Incorrect reCaptcha',
    },
    domainSettings: {
      title: 'Domain setup',
      header: 'Please review the selected domain name',
      period: ' Registration period',
      years: 'Year',
      incognito: 'Incognito',
      caution:
        'Protect your personal information: Reduce spam, enable data protection.',
      addHosting: 'Add hosting',
      hostingIsAdded: 'Hosting is added',
      nameServers: 'Set up Name-servers',
      warning:
        'Attention: You can check if the domain you have chosen matches the trademark registered in Armenia with the following ',
      warningLink: 'link.',
      next: 'Next',
    },
    orderDetailsPage: {
      title: 'Payment',
      login: 'Log In',
      register: 'Register',
      domainHeader: 'Information about the holder',
      domainNote:
        'If you register the domain for another, such as individual or legal person, you can add the contacts in your personal page and select the mentioned contact when registering the domain.  If you do not intend to, skip this section.',
      current: 'Current',
      newContact: 'Add new contact',
      selectContact: 'Choose contact',
      domainTitle: 'Domain',
      hostingTitle: 'Hosting',
      constructorTitle: 'Constructor',
      sslTitle: 'SSL',
      vpsTitle: 'VPS',
      vpsQuantity: 'Quantity',
      addNotes: 'Additional notes',
      placeholder:
        'You can add additional information or information to your order',
      total: 'Total',
      paymentDate: 'Subject to payment',
      payment: 'Pay',
      caution:
        'All the information in the order is transmitted in encrypted form, based on security considerations and the need to prevent fraud. Your Ip address…. ??? (37.252.81.188) will be maintained.',
      productInfoIncomplete: 'Please insert domain',
      productInfoMissing: 'Please insert domain information',
      promocode: 'Promocode',
      promocodeInput: 'Enter code',
      selectTime: {
        n1: 'Monthly',
        n2: 'Quarterly',
        n3: 'Semi-Annually',
        n4: 'Annually',
      },
    },
    productDetails: {
      title: 'Product Details',
      header: 'Type your hosting address',
      placeholder: 'Select...',
      nextButton: 'Next',
    },
    nameServerModal: {
      title: 'Name servers',
      header:
        'If you want to use your name servers, insert them in an appropriate section. Our name servers are used for hosting by default.',
      submit: 'Save',
      n1: 'Name server 1*',
      n2: 'Name server 2*',
      n3: 'Name server 3',
      n4: 'Name server 4',
      n5: 'Name server 5',
      more: 'More',
    },

    getCodeModal: {
      title: 'DNS change now requires a security code',
      subTitle:
        'The code has been sent to the administrative email of the domain via',
      submitCode: 'Submit ',
    },

    nameServerSuccessModal: {
      n1: 'Thank you!',
      n2: 'Nameservers have been successfully set',
    },
    glueRecordsModal: {
      title: 'DNS glue records',
      submit: 'Save',
      n1: 'IP 1',
      n2: 'IP 2',
      n3: 'IP 3',
      n4: 'IP 4',
      n5: 'IP 5',
    },
    glueRecordsSuccessModal: {
      n1: 'Thank you!',
      n2: 'Nameservers data will be updated within 24 hours',
    },
    vpsModal: {
      title: 'VPS Settings',
      ip: 'IP',
      username: 'Username',
      password: 'Password',
    },
    hostingModal: {
      title: 'Linux hosting',
      header: 'The best Linux packages for You',
      button: 'Choose',
    },
    manageFreeDNSModal: {
      title: 'Manage DNS',
      header: 'DNS records successfully changed',
    },
    registrationModal: {
      header: 'Thanks for the registration',
      subHeader: 'Please check Your email',
      login: 'Please login into system',
    },
    addContactModal: {
      header: 'Add',
      addButton: 'Add',
    },
    openTicketPage: {
      title: 'Open a support application',
      name: 'Name',
      email: 'E-mail',
      subject: 'Subject',
      subjectPlaceholder: 'Subject...',
      section: 'Section',
      service: 'Appropriate service',
      priority: 'Priority',
      inputFile: 'Insert a file',
      message: 'Message',
      textareaPlaceholder: 'Message...',
      attachButton: 'Attach files',
      sendButton: 'Send',
      cancelButton: 'Cancel',
      note: 'Allowed File Extensions: .jpg, .jpeg, .png',
      limit: 'Limit: 2.5 MB per file',
      limitError: 'Only 1 file can be uploaded',
    },
    clientAreaPage: {
      placeholder: 'Register domain',
      regButton: 'Register',
      transferButton: 'Transfer',
      domains: 'Domains',
      services: 'Services',
      tickets: 'Support applications',
      invoices: 'Invoices',
      actionButton: 'Actions',
      addServiceButton: 'Add service +',
      hasCredit: 'Account balance',
      credit: 'credit',
      creditRecharge: 'Recharge credit',
    },
    domainsTable: {
      domain: 'Domain',
      regData: 'Registration date',
      expiryDate: 'Paid till',
      status: 'Status',
    },
    servicesTable: {
      service: 'Service',
      serviceRegDate: 'Registration date',
      serviceBillCycle: 'Billing cycle',
      price: 'Price',
      expiryDate: 'Paid till',
      status: 'Status',
    },
    ticketsTable: {
      id: 'ID',
      section: 'Department',
      header: 'Inquiry subject',
      lastAnnouncement: 'Inquiry/response date',
      status: 'Status',
      departments: {
        1: 'Administrative',
        2: 'Technical',
        3: 'Financial',
      },
    },

    invoicesTable: {
      invoice: 'Number',
      price: 'Sum',
      date: 'Date presented',
      nextPayment: 'Updated',
      status: 'Status',
    },

    filterPopup: {
      showAll: 'Show all',
    },

    clientAreaTablesStatuses: {
      open: 'Open',
      close: 'Close',
      cancel: 'Canceled',
      active: 'Active',
    },

    sectionOptions: {
      adminDep: 'Administrative department',
      techDep: 'Technical department',
      finDep: 'Financial department',
    },
    priorityOptions: {
      low: 'Low',
      medium: 'Medium',
      high: 'High',
    },

    viewTicketModal: {
      title: 'Ticket Info',
      message: 'Message',
      placeholder: 'Your message',
      reply: 'Reply',
      close: 'Close ticket',
      download: 'Download',
    },

    invoicePage: {
      receiver: 'Pay to:',
      company: 'ABCDomain LLC',
      taxpayerId: 'Tax code։ 01561302',
      address: '',
      telephone: 'Phone։ +374 60 27 00 27',
      bankDetails: 'Bank requisites։ 1570020918560100 “AmeriaBank” CJSC',
      invoicedTo: 'Invoiced to:',
      invoiceDate: 'Invoice Date:',
      invoiceItems: 'Invoice Items',
      invoiceDescription: 'Description',
      sum: 'Amount',
      subTotal: 'Subtotal',
      credit: 'Credit',
      total: 'Total',
      note: '* The final charge will be in AMD',
      transactionDate: 'Transaction date',
      gateway: 'Gateway',
      transactionId: 'Transaction ID',
      amount: 'Amount',
      responseInfo: 'No Related Transactions Found',
      print: 'Print',
      hasCredit: 'You have',
      hasCredit2: 'credit',
      applyCredit: 'Apply',
      invoice: 'Invoice',
      creditCard: 'Payment card',
      refundedToCard: 'Refunded to payment card',
      refundedToBalance: 'Refunded to account balance',
      refundedToIdram: 'Refunded to Idram wallet',
      creditApplied: 'Credit applied',
      download: 'Download',
      download_EHDM: 'Download E-HDM',
      open_EHDM: 'Open E-HDM',
    },

    creditRecharge: {
      title: 'Credit recharge',
      header: 'Recharge your credit and make payment from your balance',
      placeholder: 'Amount',
      submit: 'Submit',
    },

    settingsPopup: {
      title: 'View ticket',
      viewInvoice: 'View invoice',
      enterCpanel: 'Enter Cpanel',
      freeDNSManagement: 'Manage DNS',
      csrModal: 'Upload CSR',
      vps: 'Settings',
      details: 'View details',
      configure: 'Configure',
    },
    protectIdModal: {
      activate:
        'By activating incognito your domain details will become private',
      deactivate:
        'By deactivating incognito your domain details will become public',
      button: 'Continue',
    },
    renewModal: {
      button: 'Renew Domain',
      period: 'Renew period',
      promocode: 'Promocode',
      year: 'Year',
    },
    domainTableSettingsPopup: {
      renew: 'Prolong',
      ns: 'Set DNS',
      changeDomainContacts: 'Update domain contacts',
      activateProtectId: 'Activate incognito mode',
      deactivateProtectId: 'Disable incognito mode',
      modalDeleteBtn: 'Yes',
      modalDeleteSubtitle: 'Do you really want to complete this action?',
    },

    errors: {
      error: 'ID number is mandatory and must be at least 4 characters',
      errorTin:
        'Taxpayer identification number is mandatory and must be at least 3 characters',
      errorDob: 'Date of birth is mandatory and must be YYYY-MM-DD format',
    },

    domainContactModal: {
      title: 'Domain Contacts',
      header:
        'If you want to change your domain contacts, insert them in an appropriate section.',
      tab1: 'Registrant',
      tab2: 'Technical',
      tab3: 'Admnistrative',
      n1: 'Name',
      n2: 'Country',
      n3: 'City',
      n4: 'Organization',
      n5: 'E-mail',
      n6: 'Address',
      n7: 'Postcode',
      n8: 'Phone number',
      submit: 'Save',
    },

    editContactModal: {
      header: 'Edit',
      saveButton: 'Save',
    },
    confirmModal: {
      header: 'Are you sure?',
      cancelButton: 'Cancel',
    },
    autoChargeModal: {
      header: 'Automatic payment',
      text: 'No time waste any more. You will never forget to pay the invoices anymore as they will automatically be charged once generated.',
      notnow: 'Not now',
    },

    twoFactorAuthEnablePage: {
      title: 'Scan QR Code to activate two factor authentication',
      submitButton: 'I have already scanned',
    },

    validationFields: {
      onlyLatin: 'Latin characters and (. - @) symbols only',
      firstname: 'Field is required',
      firstnameMin: 'Field must be at least 2 characters',
      firstnameMax: 'Field must be at most 72 characters',
      lastname: 'Field is required',
      lastnameMin: 'Field must be at least 2 characters',
      lastnameMax: 'Field must be at most 72 characters',
      fullName: 'Full name is required',
      fullNameMin: 'Full name must be at least 6 characters',
      fullNameMax: 'Full name must be at most 72 characters',
      legalCodeMin: 'This field must contain at least 5 characters',
      eppCode: 'EPP Code is required',
      eppMinPhysical: 'EPP Code must be at least 4 characters',
      eppMaxPhysical: 'EPP Code must be at most 30 characters',
      passport: 'Field is required',
      phonenumber: 'Phone is required',
      phonenumberMin: 'Phone number must be at least 9 characters',
      phonenumberMax: 'Phone number must be at most 20 characters',
      email: 'Email is required',
      client: 'Client is required',
      password:
        'At least 12 characters long combination of at least one uppercase letter, one lowercase letter, numbers and symbols, (Do not use personal information, common words and phrases)',
      passMin:
        'At least 12 characters long combination of at least one uppercase letter, one lowercase letter, numbers and symbols, (Do not use personal information, common words and phrases)',
      passMax: 'Password must be at most 64 characters',
      loginPass: 'Password is required',
      passMatch: 'Passwords must match',
      country: 'Country is required',
      countryMax: 'Country must be at most 2 characters',
      locality: 'Locality is required',
      organizationUnit: 'Organization Unit is required',
      commonName: 'Domain is required',
      state: 'State is required',
      city: 'City is required',
      productId: 'Product Id is required',
      organization: 'Organization Name is required',
      promocodeMin: 'Promocode must contain at least 2 characters',
      promocodeMax: 'Promocode must contain maximum 20 characters',
      address: 'Address is required',
      postcode: 'Postal index is required',
      domain: 'Insert domain',
      question: 'Field is required',
      subject: 'Subject is required',
      subjectMin: 'Subject must be at least 2 characters',
      subjectMax: 'Subject must be at most 40 characters',
      message: 'Message is required',
      messageMin: 'Message must be at least 2 characters',
      messageMax: 'Message must be at most 2000 characters',
      messageTextareaMax: 'Message must be at most 500 characters',
      section: 'Section is required',
      service: 'Appropriate service is required',
      priority: 'Priority is required',
      invalidTyping: 'Incorrect format',
      ns1Required: 'ns1 is required',
      ns2Required: 'ns2 is required',
      ns1Url: 'ns1 should be an URL',
      ns2Url: 'ns2 should be an URL',
      ns3Url: 'ns3 should be an URL',
      ns4Url: 'ns4 should be an URL',
      ns5Url: 'ns5 should be an URL',
      sameDomain: 'Email should be registered in other domain',
      priceTime: 'Period is required',
      onlyDigits: 'Only digits are allowed',
      required: 'Field is required',
      amountMax8: 'Amount must be at most 8 digits',
      vpsLabelMin: 'Server name must be at least 4 characters',
      vpsLabelMax: 'Server name must be at most 25 characters',
      validationType: 'Validation type is required',
      firstnamePhysical: 'Name is required',
      firstnameMinPhysical: 'Name must be at least 2 characters',
      firstnameMaxPhysical: 'Name must be at most 72 characters',
      postalAddress: 'Postal address is required',
      legalAddress: 'Legal address is required',
      dob: 'Date of birth is required',
      passportSeries: 'Passport series is required',
      passportIssueDate: 'Passport issue date is required',
      passportIssuer: 'Passport issuer is required',
      companyName: 'Company name is required',
      tid: 'Taxpayer identification number is required',
      id: 'ID number is required',
      postalAddressInvalid: 'Postal address must contain only cyrillic letters',
      legalAddressInvalid: 'Legal address must contain only cyrillic letters',
      companyNameInvalid: 'Company name must contain only latin letters',
      csrRequired: 'Csr is required',
      csr: 'Incorrect format',
      wrongCode: 'Wrong code',
      codeRequired: 'Fill in the code',
      privacy: 'Is required',
    },

    ipNote: {
      ipText: 'Sent from IP address:',
    },

    headers: {
      aboutUsPage: 'Internet.am - About Us',
      clientAreaPage: 'Internet.am - Client Area',
      editProfilePage: 'Internet.am - My account',
      constructorPage: 'Internet.am - Website Constructor',
      contactsPage: 'Internet.am - Contact Us',
      domainConfigurationPage: 'Internet.am - Domain setup',
      domainsPage: 'Internet.am - Registration of domain names',
      homePage: 'Internet.am - Leading domain registrar in .am zone',
      hostingPage: 'Internet.am - Hosting packages',
      vpsPage: 'Internet.am - The best Cloud VPS servers for programming',
      openTicketPage: 'Internet.am - Open Ticket',
      orderDetailsPage: 'Internet.am - Payment',
      productDetailsPage: 'Internet.am - Product Details',
      registrationPage: 'Internet.am - Registration at Internet.am',
      resetPasswordPage: 'Internet.am - Reset Password',
      specialTermsPage: 'Internet.am - Special terms and conditions',
      sslPage: 'Internet.am - SSL certificates',
      supportPage: 'Internet.am - Support',
      creditRechargePage: 'Internet.am - Credit recharge',
      errorPage: 'Internet.am - 404 error',
      confirmEmailPage: 'Internet.am - Confirm Email',
      blogPage: 'Internet.am - Blog',
      sslConfiguration: 'Internet.am - SSL configuration',
    },
    headersDescriptions: {
      aboutUsPage:
        '«ABC DOMAIN» company has started its activity from 2007 as an official registrar of domains in .AM zone and maintains its leading position till today.',
      clientAreaPage:
        'It has never been so easy. Register your domain name and create your web page in minutes. All starts with catchy name. Here you go!',
      editProfilePage:
        'It has never been so easy. Register your domain name and create your web page in minutes. All starts with catchy name. Here you go!',
      constructorPage:
        'Self-creation of website without the intervention of a developer. Benefits: fast, affordable, easy, SEO/SSM optimized.',
      contactsPage:
        'You are free to contact us via  Tel: +374 60 270027, info@internet.amor by applying to online chat.',
      domainsPage:
        'ABDomain is in the first position among the top 10 domain registration companies in Armenia. The company provides registration services for am, IDN domain հայ and for the main internet domain zones in the world.<br/><br/>' +
        'The company is officially accredited by the Armenian Network Information Center (AMNIC), https://www.am/, governed by the Internet Society of Armenia (ISOC.AM), https://www.isoc.am/. <br/><br/>' +
        'Register your unique name in am zone and become one of more than 40000 Armenian domain name owners. If the desired name is already registered, you can register your name in   com.am, net.am, org.am zones. <br/><br/>' +
        'Your next step may be the creation of your am website and webmail with an SSL certificate. ABCDomain provides an easy management of your domain name, DNS records, SSL certificates and hosting services via the cPanel management tools. <br/><br/>' +
        'You can reach us online via abcdomain.am (https://abcdomain.am) or internet.am (https://internet.am) websites. ABCDomain continues the best traditions of Arminco, the first Internet provider in Armenia.',
      domainConfigurationPage:
        'It has never been so easy. Register your domain name and create your web page in minutes. All starts with catchy name. Here you go!',
      homePage:
        'Domain registration in .AM,.ՀԱՅ,.ORG,.COM,.NET,.INFO,.GROUP zones and not only. Hosting, SSL, Constructor, 24/7 support.',
      hostingPage:
        'Explore business and personal hosting packages for reliable and scalable web hosting solutions offered by the leading company among the top 10 domain registrars in Armenia, ABCDomain LLC. Shared hosting, dedicated servers and a range of plans tailored to meet your websites needs. Enjoy high performance, reliable support, and advanced features to ensure your online presence is fast, secure and hassle-free in the Internet. Find the perfect hosting package for your website and webmail today.',
      vpsPage:
        'Experience high-performance VPS hosting solutions tailored to meet your specific needs from ABCDomain, the leading company among the top 10 domain registrars in Armenia. Our Virtual Private Servers offer dedicated resources, scalability and robust security for optimal website and application performance. Enjoy the flexibility to customize your server environment, seamless scalability and reliable expert support. Elevate your online presence with our reliable VPS services, providing the power and control of your digital project demand.',
      openTicketPage:
        'It has never been so easy. Register your domain name and create your web page in minutes. All starts with catchy name. Here you go!',
      orderDetailsPage:
        'It has never been so easy. Register your domain name and create your web page in minutes. All starts with catchy name. Here you go!',
      productDetailsPage:
        'It has never been so easy. Register your domain name and create your web page in minutes. All starts with catchy name. Here you go!',
      registrationPage:
        'Fill in the required fields to register domain name as a legal or physical person.',
      resetPasswordPage:
        'It has never been so easy. Register your domain name and create your web page in minutes. All starts with catchy name. Here you go!',
      specialTermsPage:
        'The special terms and conditions of ABCDOMAIN LLC concerning domains, hosting, SSL, DNS Management.',
      sslPage:
        'Positive SSL, Positive Wildcard SSL, Rapid SSL, Sectigo EV SSL, Symantec and wide selection with other packages. Annually starting from 10000.',
      supportPage:
        'How to register a new domain? How to obtain hosting and change the domain’s name servers? What is SSL certificate? How to apply to online chat?',
      creditRechargePage:
        'It has never been so easy. Register your domain name and create your web page in minutes. All starts with catchy name. Here you go!',
      errorPage:
        'It has never been so easy. Register your domain name and create your web page in minutes. All starts with catchy name. Here you go!',
      confirmEmailPage:
        'It has never been so easy. Register your domain name and create your web page in minutes. All starts with catchy name. Here you go!',
    },

    headerKeywords: {
      homePage:
        'domain hosting, buy domain in Armenia, domain registrar, .am domain leading registrar, SSL certificate, Linux hosting',
      domainsPage:
        'top 10 domain Armenia,top 10 domain registration, abc, abc am, abc domain, abc domain am, abc domain armenia abc domain llc, abc domains, abc domen, abc internet, abc.am, arminco, abcdomain,abcdomain llc,am,am abc,am am,am domain,am domain names,am domain price,am domain registration,am domains,am internet,am name,am site,am tld,am web,amnic.net,anet internet,armenia domain,armenian domain,armenian domains,buy .am domain,buy am domains,buy domain armenia buy domain search,buy name,buy new domain,buy url domain,buy website name,by am,cheap domain purchase,cheap domains,cpanel webmail,dns Armenia,domain,domain .am,domain abc,domain am,domain Armenia,domain booking,domain forwarding,domain info,domain name ideas,domain name price,domain name reseller,domain page,domain provider,domain registration company,domain registration sites,domain search, domain.am,domein,domen,domen am,domeyn,dwmain registration sites,email domain,get domain,host,how to obtain domain name,how to register a website domain,how to take domain name,inet am,inet Armenia,inet llc, international domain name,internet,internet a b c, internet abc, internet am,internet Armenia,internet domain,internet domains,internet domen,internet in Armenia,internet patetner,internet subdomain,internet Yerevan,isoc am,name,name am,name hosting,name registration,names website,net am,net.am,new domain registration,new email account,official .am registrar,online am,price am,proverit domain,register .am domain,register a website name,registry domain,site,site:.am,the domain, the domain name,url registration,web domain,web domain names,web domains,  web hosting and domain, web internet,web kayqer,web whois,webhost,webmail am,website names,website registration,wher to get domain name,where is domain name registrated,where to find domain name,worldpress am,worldpress am,your domain',
      hostingPage:
        'buy hosting in Armenia, hosting packages, linux hosting with cpanel , creation of email addresses.',
      vpsPage:
        'SSD Cloud, bandwidth, Ubuntu, Debian, fedora, VPS, virtual private server',
      sslPage:
        'sertifikat,ssl,ssl internet,ssl sertifikat,ssl vkayagir,ssl am certificate,am certificate,abcdomain ssl,internet ssl,cheap ssl certificate.',
      constructorPage:
        'how to create a webpage from zero, landing page, website creation, web constructor.',
      specialTermsPage:
        'domain registration, hosting, dns management, ssl certificate',
      aboutUsPage: 'էյբիսիդոմեյն, abc domen, abcdomain, abcdomen, internet am',
    },

    homePage: {
      searchAreaBlock: {
        title: 'Register your domain name',
        sale: ' discount',
        info: 'In Winter internet.am offers unprecedented discounts! To apply, use ',
        promocode: 'WINTER2021',
        rusText: '',
        endText: ' promo code',
      },
      tldsCard: {
        period: 'annually',
        oldPrice: '10000/year',
      },

      buyDomainWithSale: {
        title: 'Register your domain name',
      },
      hostingPackageBlock: {
        secondaryText: 'NS գրառումների\nփոփոխման համար\nնախատեսված փաթեթ',
      },
      tldCards: {
        period: '/տարին',
        oldPrice: '10000/տարին',
      },
    },

    tooltips: {
      cartProblemTooltip:
        'There are products in your cart that need your attention.',
      registerRequiredToSelectContact: 'To select contact you have to login.',
    },
    general: {
      periods: {
        daily: 'daily',
        monthly: 'monthly',
        yearly: 'annually',
        forYears: 'Year(s)',
      },
      currency: {
        dram: '֏',
        usd: '$',
      },
    },
    faq: {
      title: 'FAQ',
      domainsTitle: 'Domain',
      hostingTitle: 'Hosting',
      constructorTitle: 'Constructor',
      vpsTitle: 'VPS',
      sslTitle: 'SSL',
      domains: {
        q1: 'How to register a domain name?',
        a1: '1. search the domain name(s) from the home page or the section domains<br /><br />2. configure the domain(s) settings per requirements (registration period, hosting selection, name server configuration)<br /><br />3. select the domain name(s) holder<br /><br />4. make the payment via the preferred means available on the invoice',
        q2: 'How to change a domain nameservers?',
        a2: '1. login your account<br /><br />2. choose the section domains<br /><br />3. select the domain name that should be modified<br /><br />4. in domain settings (at the right of the line/row) choose set DNS<br /><br />5. make the change and save',
        q3: 'How to transfer in a domain name?',
        a3: `1. login your account<br /><br />2. choose transfer<br /><br />3. fill in the domain name and the EPP code (*you may get the transfer code for .am domain via <a href="https://www.am/transfer/code/" target="_blank">https://www.am/transfer/code/</a> link, the password of the domain name may be reset via <a href="https://www.am/password/reset/" target="_blank">https://www.am/password/reset/</a>, if needed)<br /><br />4. transfer`,
      },
      hosting: {
        q1: 'How to login to hosting cpanel ?',
        a1: "1. login your account<br /><br />2. choose the section 'Services'<br /><br />3. login cpanel (to the right of the line/raw of the service chosen)",
        q2: 'How to change the already purchased hosting package?',
        a2: '<a href="/open-ticket">Open a support application</a>',
        q3: 'How to change the billing cycle chosen for hosting?',
        a3: '<a href="/open-ticket">Open a support application</a>',
        q4: 'What is a shared hosting?',
        a4:
          'Virtual or shared hosting is a technology where one server (dedicated or virtual) hosts many websites of different owners. Users share CPU, memory, and Internet connection resources.<br/><br/>' +
          'Advantages:<br/><br/>' +
          'You don&#39;t need to configure server settings - everything is already done by the hosting provider․<br/><br/>' +
          'The server&#39;s operation is being monitored by the staff of the hosting provider, software is being updated, and errors fixed, hacker attacks are being monitored on the server side.<br/><br/>' +
          'In case of hardware problems, backup equipment is turned on, and if necessary, you get access to your backups for recovery.<br/><br/>' +
          'On shared hosting, you have access to a control panel that allows you to make various additional settings for your website<br/><br/>' +
          'Well, the most important advantage is the affordable price.<br/><br/>' +
          'Of course, there are also disadvantages:<br/><br/>' +
          'A limited amount of resources that are distributed among all sites hosted on this server.<br/><br/>' +
          'It is possible that one of the users will be included in the blacklist of spam senders, which may affect all users, although for a very short time as system administrators of the hosting company are taking measures to change email routes.<br/><br/>' +
          'The limited software used in shared hosting is not a very big problem because all major programs and programming languages are being supported.<br/><br/>' +
          'To summarize, we can state that for most small and medium-sized businesses, virtual hosting allows them to solve their problems of being represented on the Internet.<br/><br/>' +
          'If you need more resources, then the obvious choice is VPS, <a href=https://internet.am/en/vps-packages   target="_blank">  more details here. </a>',
      },
      constructor: {
        q1: 'What quality and functionality website can be created with our website constructor?',
        a1: 'Internet.am website constructor has great functionality - import of preferred website, responsive design, SEO optimization, creation of pages with little functionality and many other functions',
        advantagesTitle: 'Creating a website using the web site builedr',
        advantagesInfo: 'Advantages:',
        info1:
          'You create your website yourself and do not depend on a web designer or design company',
        info2:
          'You can import the site you like, create the necessary images and texts using AI, and place them on the imported site',
        info3:
          'Multilingual support allows you to create a trilingual website, which is relevant for Armenia',
        info4: 'Using the constructor allows you to get a free SSL certificate',
        info5:
          'CMS is not used, which prevents hackers from hacking it using known vulnerabilities',
        info6:
          'Incomparably lower financial costs for creating a website compared to the services of web studios or web designers',
        info7:
          'Making changes is in your hands, there is no need to develop technical specifications and draw up a contract for the development and management of the site',
        info8: 'There are many plugins at your disposal',
        info9: 'Support for major payment systems for online stores',
        info10:
          'Implementation of your design abilities directly, no need to know programming languages',
      },
      vps: {
        q1: 'What is VPS needed for?',
        a1: 'VPS Server, unlike shared hosting, uses virtualization technology. You get a server with its resources, a server that is controlled by you. It is more small-scale and inexpensive than an entire server but has the possibility of scaling.',
        q2: 'Why do you need a VPS server?',
        a2: 'VPS – Virtual Private Server is a dedicated private computer that is managed by your organization and is located in the data center of the hosting and VPS service provider. In the early stages of Internet development, VPS was just a separate server in a data center. Today, a VPS is usually part of a supercomputer in a data center, and this supercomputer is logically divided into servers. You can order a large logical piece of a supercomputer at an expensive price, or you can order a small logical piece of the same supercomputer. In addition to VPS, the abbreviation VDS is also used - Virtual dedicated server, which means that these notions are synonymous.',
        q3: 'How you can use VPS server?',
        a3:
          'For web sites whose traffic exceeds 10 thousand per day, the capabilities of shared hosting become insufficient, and the best domain hosting for that case is a VPS server.<br/><br/>' +
          'To develop and test your software products before publishing them on a production site, you create a layout of a new site in .am zone, check its operation, and when all inconsistencies are eliminated, you can place it in production.<br/><br/>' +
          'To create a remote desktop for your organization. Today there is a large selection of programs that allow you to create a virtual office for company employees. There is no need to worry about moving, business trips, or various lockdowns, your desktop is accessible from anywhere in the world.<br/><br/>' +
          'To create your own VPN server on a VPS server, this dramatically increases the cybersecurity of your desktop. Allow connections to your organizations desktop only from this VPN server and you can be sure that all direct hacker attacks on your organizations desktop will be repelled.<br/><br/>' +
          'For your accounting server, which will allow the accountant, bookkeeper, and administration to work remotely. To organize video surveillance - storing video files requires a lot of memory; on your VPS server you can always find the optimal balance between storage time, image quality and other factors of video surveillance systems.<br/><br/>' +
          'To store the archive of your organization, personal data of employees and users. For many different tasks, from the webmail and Internet of Things to the actual game server.<br/><br/>' +
          'But you need to remember that maintaining your own VPS server requires the presence of qualified personnel who maintain this server. Today, solutions based on the Linux operating system are common. There are certain difficulties with inviting specialists in this field, but these difficulties can be resolved.<br/><br/>' +
          'It is worth mentioning the issue of choosing a VPS server supplier; reliable suppliers today ensure both uninterrupted operation of the server and provide backup services. ABCDomain offers ultra-reliable services, flexible tariff plans, and easy configuration of the parameters of your VPS server.',

        q4: 'Platforms written on which programming languages can be hosted on VPS servers?',
        a4: 'Platforms written with any modern programming language can be hosted on VPS. Shared hosting is intended for PHP or static HTML/JS/CSS websites, whereas you can setup the required environment yourself for any programming language on VPS.',
      },
      ssl: {
        q1: 'What is SSL certificate?',
        a1: 'SSL stands for Secure Socket Layers, a security protocol. The protocol describes a process in which data transfer between the user and the server occurs in an encrypted form so that a third party cannot connect to the process.<br /><br />SSL certificate affirms a clear connection between the owner of the domain name and the site that the domain name points to.<br /><br />SSL certificate is obtained from various reliable providers; the reliability of the provider is confirmed by the historically established successful practice of using certificates of the current provider.<br /><br />Each certificate has an expiration date, which means that you need to update it to have your site secure (usually is done once a year).',
        q2: 'What is Wildcard?',
        a2: 'Wildcard is used to secure not only domain, but also subdomains',
        q3: 'How to setup SSL certificate?',
        a3: 'For the instructions how to set up, follow this <a href="ssl-configuration" target="_blank">link</a>',
      },
    },
    accountPopup: {
      clientArea: 'My account',
      editProfile: 'Edit Profile',
      logout: 'Log out',
    },
    pagination: {
      previous: 'Previous',
      next: 'Next',
    },
    sslConfigurationPage: {
      header:
        'How to generate a certificate and install the final files in Cpanel?',
      howTo1: 'To generate a certificate one should',
      howTo2: 'To install the final files in Cpanel one should',
      sub: 'In case you website is not hosted by us, please follow your provider’s instructions',
      steps1: {
        step1: 'login your account “My account”',
        step2: 'choose the section services',
        step3:
          'choose the “Configure now” button on the right of the certificate service',
        step4:
          'choose the order type, generate CSR or upload already generated CSR, fill in the administrative contact information, <b>DOWNLOAD KEY <i>(Private Key is needed to have the certificate  installed, thus please keep the Key in a private and safe place)</i></b> and click continue',
        step5:
          'choose Domain Control Validation (DCV) method, click continue and check the email to finish the process <b><i>(in case you choose «Email Validation», choose the email address for validation from the list and be sure that the provided email is already created)</i></b>',
        step6:
          '<i>to download Validation files and to get the Private Key you need to choose the section services, “View details” button on the right of the certificate service</i>',
      },
      steps2: {
        step1: 'choose the subsection "SSL/TLS" in the “security” section',
        step2: 'choose “Manage SSL sites"  button',
        step3: 'domain - Select a Domain / choose your domain name',
        step4: 'insert the certificate and the private key',
        step5:
          'in the section Certificate: (CRT) paste identically and insert the whole content of yourdomain.crt file',
        step6:
          'in the section Private Key (KEY) paste identically and insert the Private Key downloaded with the CSR',
        step7: 'press Install Certificate button',
      },

      steps3: {
        step7: 'login your account “My account”',
        step8: 'choose the section services',
        step9: 'press upload CSR button - <b>Upload CSR</b>',
        step10:
          'choose the preferred option <b><i>(in case you choose «Email Validation», please be sure that the validation email is already created)</i></b>',
        step11:
          'fill in all the gaps and press generate button <b><i>(in case the sections are filled in wrong, the request will be declined)</i></b>',
        step12:
          '<i>In case an “Email Validation” is chosen, a validation link will be sent within one business day to your email address after you have generated/uploaded CSR. Once you follow the link, the certificated will be validated, yourdomain.crt and yourdomain.ca files will be sent.</i>',
        step13:
          '<i>In case you have chosen to verify the validation via file, instructions will be sent within one business day.</i>',
        step14:
          '<i><b>If you have purchased Wildcard SSL, when generating the CSR, fill in the domain name in the appropriate section like *.example.com.</b></i>',
      },
      steps4: {
        step9: 'choose the subsection "SSL/TLS" in the “security” section',
        step10: 'choose "Manage SSL sites" button',
        step11: 'domain - Select a Domain / chose your domain name',
        step12: 'insert the certificate and the private key',
        step13:
          'in the section Certificate: (CRT) paste identically and insert the whole content of yourdomain.crt file',
        step14:
          'in the section Private Key (KEY) paste identically and insert the Private Key downloaded with the CSR',
        step15: 'press Install Certificate button',
      },
      sub1: '1.2 In case you already have a generated CSR',
      sub2: 'The CSR may be generated via the tools available in our website / internet.am / or any other means known to you (via terminal or any other website)',
      sub2v1:
        '1.1 In case you prefer to generate the certificate using the tools available in our website',
      sub3: 'To generate a certificate via our website',
      sub4: 'Once the certificate is generated or validated in case of e-mail validation an e-mail will be sent within one business day with the validation link. After you follow the link, the certificated will be validated.',
      sub5: 'In case you have chosen to pass the validation via file, instructions will be sent within one business day.',
      sub6: '**If you have purchased Wildcard SSL, please, when generating the certificate fill in the domain name in the domain name the certificate is meant for like *.example.com.',
      sub7: 'After the validation the final files will be sent / yourdomain.crt and yourdomain.ca which is requested to be inserted in the Cpanel',
      sub8: 'The CSR may be generated via the tools available in our website / internet.am / or any other means known to you (via terminal or any other website)If the certificate is generated via our website',
      sub9: 'How to install the final files in Cpanel',
      sub10:
        'In case you website is not hosted by us, please follow your provider’s instructions',
      sub11:
        'In case an “Email Validation” is chosen, a validation link will be sent within one business day to your email address after you have generated/uploaded CSR. Once you follow the link, the certificated will be validated, yourdomain.crt and yourdomain.ca files will be sent.',
      sub12:
        'In case you have chosen to verify the validation via file, instructions will be sent within one business day.',
      sub13:
        'If you have purchased Wildcard SSL, when generating the CSR, fill in the domain name in the appropriate section like *.example.com.',
    },
    sslAttentionModal: {
      header: 'Attention',
      info: 'Configurations for the purchased SSL are not completed yet.',
      info1: 'Instructions',
    },

    sslConfiguration: {
      new: 'New',
      renew: 'Renew',
      header1: 'Administrative contact information',
      headerOrganization: 'Organization contact information',
      orderType: 'Order type',
      back: 'Back',
      continue: 'Continue',
      firstName: 'First name',
      lastName: 'Last name',
      organization: 'Organization',
      jobTitle: 'Job title',
      city: 'City',
      zipCode: 'Zip code',
      country: 'Country',
      phoneNumber: 'Phone',
      email: 'Email address',
      address1: 'Address 1',
      address2: 'Address 2',
      state: 'Region/State',
      header2: 'Certificate approver email',
      domain: 'Domain',
      method: 'DCV Method',
      'Awaiting Configuration': 'Awaiting configuration',
      'Configuration Submitted': 'Configuration submitted',
      message: 'Configuration is completed!',
      emailMessage: 'Please check <b>{{email}}</b> email.',
      invalidTyping: 'Field must contain only latin letters',

      orgFields: {
        'Organization Name': 'Company name',
        Division: 'Division',
        'LEI code': 'LEI code',
        Duns: 'DUNS',
        Address: 'Address',
        City: 'City',
        Country: 'Country',
        Fax: 'Fax',
        'Phone Number': 'Phone numer',
        'Zip Code': 'Zip code',
        'State / Region': 'State/region',
        leiCodeError: 'LEI-code must be at most 20 characters',
        orgDunsError: 'The DUNS must be 9-digit number',
      },
    },

    serviceDetails: {
      regDate: 'Registration Date',
      cycle: 'Billing cycle',
      next: 'Next due date',
      domain: 'Domain',
      configStatus: 'Configuration Status',
      sslStatus: 'SSL Status',
      orderId: 'Partner order ID',
      approverEmail: 'Approver email',
      actions: 'Actions',
      getPrivateKey: 'Get private key',
      downloadFile: 'Download validation files',
      Active: 'Active',
      Cancelled: 'Cancelled',
      Processing: 'Processing',
      Expired: 'Expired',
      Terminated: 'Terminated',
    },

    pricelistPage: {
      zone: 'Zone',
      year: 'year',
      period: 'Period',
      register: 'Register',
      renew: 'Renew',
      transfer: 'Transfer',
      details: 'Zone details',
      search: 'TLD search',
      info: 'Additional information and/or document(s) may be requested when registering domain(s) in some zones',
      info2: 'More details:',
    },

    telcellPaymenPage: {
      backButton: 'back to my account',
    },

    goToClientAreaPage: {
      backButton: 'back to my account',
    },

    paymentSuccessStatus: {
      success: 'Successfully paid',
    },
  },
}
