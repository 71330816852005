import React from 'react'
import * as S from './TablePagination.styles'

export const TablePagination = ({
  dataPerPage,
  totalResults,
  paginate,
  current,
}) => {
  const pageNumbers = []
  const pagesQuantity = Math.ceil(totalResults / dataPerPage)

  for (let i = 1; i <= pagesQuantity; i++) {
    pageNumbers.push(i)
  }

  if (current < 5) {
    pageNumbers.slice(0, 10)
  } else {
    pageNumbers.slice(current - 5, current + 5)
  }

  if (pagesQuantity === 1) {
    return null
  }

  return (
    <S.PaginationContainer>
      <S.Pages>
        {pageNumbers.map((number, ind) => (
          <S.Page
            key={ind}
            isActive={current === number}
            onClick={() => paginate(number)}
          >
            {number}
          </S.Page>
        ))}
      </S.Pages>
    </S.PaginationContainer>
  )
}
