import { NAME_SERVER_MODAL } from './NameServerModal.types'
import FetchApi from '../../../helpers/FetchApi'
import Cookies from 'js-cookie'

export const getNameservers = (domainid) => async (dispatch) => {
  try {
    dispatch({
      type: NAME_SERVER_MODAL.GET_NAMESERVERS_REQUEST,
    })
    const req = await FetchApi.get(`domains/nameservers?domainid=${domainid}`)
    const obj = {}
    for (let key in req.data.data) {
      if (['ns1', 'ns2', 'ns3', 'ns4', 'ns5'].includes(key)) {
        obj[key] = req.data.data[key]
      }
    }
    dispatch({
      type: NAME_SERVER_MODAL.GET_NAMESERVERS_SUCCESS,
      data: obj,
    })
  } catch (e) {
    dispatch({
      type: NAME_SERVER_MODAL.GET_NAMESERVERS_FAIL,
    })
  }
}

export const setNameServers =
  (domainid, values, code, onSuccess, onError) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: NAME_SERVER_MODAL.SET_NAMESERVERS_REQUEST,
      })
      await FetchApi.post('domains/setnameservers', {
        nameservers: { ...values },
        code,
        domainid,
      })
      dispatch({
        type: NAME_SERVER_MODAL.SET_NAMESERVERS_SUCCESS,
      })
      if (onSuccess) {
        onSuccess()
      }
    } catch (e) {
      dispatch({
        type: NAME_SERVER_MODAL.SET_NAMESERVERS_FAIL,
        error: e.data ? e.data.error || e.data.validation_error : e.message,
      })
      if (onError) {
        onError(e.data ? e.data.error || e.data.validation_error : e.message)
      }
    }
  }

export const getNameServersCode =
  (domainid, onSuccess) => async (dispatch, getState) => {
    try {
      const lang = Cookies.get('lng')
      dispatch({
        type: NAME_SERVER_MODAL.GET_NAMESERVERS_CODE_REQUEST,
      })
      const response = await FetchApi.post('domains/codeEmail', {
        domainid,
        type: 'ns',
        lang,
      })
      dispatch({
        type: NAME_SERVER_MODAL.GET_NAMESERVERS_CODE_SUCCESS,
      })
      if (onSuccess) {
        onSuccess(response)
      }
    } catch (e) {
      dispatch({
        type: NAME_SERVER_MODAL.GET_NAMESERVERS_CODE_FAIL,
        error: e.data ? e.data.error || e.data.validation_error : e.message,
      })
    }
  }
