import { EDIT_PROFILE_TYPES } from './EditProfilePage.types';
import { EDIT_PROFILE_TABS } from './EditProfilePage.constants';

const initialState = {
  tab: EDIT_PROFILE_TABS.PERSONAL_DATA,
  changePassLoading: false,
  changePassError: null,
};

function editProfile(state = initialState, action) {
  switch (action.type) {
    case EDIT_PROFILE_TYPES.CHANGE_TAB:
      return {
        ...state,
        tab: action.tab,
      };
    default:
      return state;
  }
}

export { editProfile };
