import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ErrorMobileMenu as Self } from './ErrorMobileMenu';
import { hideMobileMenu } from '../../../components/Header/Header.actions';

const mapStateToProps = ({ header }) => ({
  isExpanded: header.isExpanded,
});

const mapDispatchToProps = {
  hideMobileMenu,
};

export const ErrorMobileMenu = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
