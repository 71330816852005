import { connect } from 'react-redux'

import { ProductDetailsPage as Self } from './ProductDetailsPage'
import { updateCart, removeFromCart } from '../../common/App/App.actions'
import { setProductDomain } from './ProductDetailsPage.actions'

const mapStateToProps = ({ app, productDetailsPage, loginModal }) => ({
  cart: app.cart,
  productDomain: productDetailsPage.productDomain,
  domains: loginModal.domains,
})

const mapDispatchToProps = {
  updateCart,
  setProductDomain,
  removeFromCart,
}

export const ProductDetailsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self)
