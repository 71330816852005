const config = [
  {
    id: 1,
    title: 'contactUsPage.tabs.sales',
    description: 'contactUsPage.tabs.salesDescription',
    info: 'contactUsPage.tabs.info',
    mail: 'contactUsPage.tabs.mail',
    href: 'contactUsPage.tabs.href',
  },
  {
    id: 2,
    title: 'contactUsPage.tabs.onlineChat',
    description: 'contactUsPage.tabs.chatDescription',
  },
  {
    id: 3,
    title: 'contactUsPage.tabs.techSupport',
    description: 'contactUsPage.tabs.techDescription',
    support: 'contactUsPage.tabs.support',
  },
  {
    id: 4,
    title: 'contactUsPage.tabs.hq',
    description: 'contactUsPage.tabs.hqDescription',
    mail: 'contactUsPage.tabs.mail',
    href: 'contactUsPage.tabs.href',
  },
];

export { config };
