import React, { useCallback, useEffect } from 'react';
import { Formik } from 'formik';

import * as S from './GlueRecordsModal.styles';
import {
  configIP,
  configNS,
  defaultNameservers,
  ifNotEmptyGlues,
  yupScheme,
} from './GlueRecordsModal.config';
import { Loader } from '../../Loader';
import { SetNameServersSuccessModal } from '../../Modals/SetNameServersSuccessModal';
import { SetGlueRecordsSuccessModal } from '../../Modals/SetGlueRecordsSuccessModal';
import { i18n } from '../../../i18n';

export const GlueRecordsModal = ({
  cart,
  hideModal,
  id,
  shouldGetGlues,
  domain,
  showModal,
  updateGlueLoading,
  updateGlueError,
  setGlueRecords,
  targetNameservers,
  setNewGlues,
  getGlues,
  glueLoading,
  glues,
}) => {
  const handleEnter = useCallback((e, submitForm) => {
    if (e.key === 'Enter') {
      submitForm();
    }
  }, []);

  useEffect(() => {
    if (shouldGetGlues) {
      getGlues(domain.id);
    }
  }, []);

  return (
    <S.Modal onClick={(e) => e.stopPropagation()}>
      {glueLoading ? (
        <S.LoaderWrapper>
          <Loader color="#242e46" size={0.5} />
        </S.LoaderWrapper>
      ) : (
        <S.GlueRecordsModalContainer>
          <S.ModalTitle>{i18n.t('glueRecordsModal.title')}</S.ModalTitle>
          <S.FormContainer>
            <Formik
              initialValues={defaultNameservers(
                ifNotEmptyGlues(glues) || targetNameservers
              )}
              validationSchema={yupScheme}
              onSubmit={async (values, { setSubmitting }) => {
                setGlueRecords(domain.domainname, domain.id, values, (tld) => {
                  setTimeout(() => {
                    hideModal();
                    if (!updateGlueError) {
                      setTimeout(() => {
                        tld === '.am'
                          ? showModal(SetNameServersSuccessModal)
                          : showModal(SetGlueRecordsSuccessModal);
                      }, 400);
                    }
                  }, 10);
                });
                setSubmitting(false);
              }}
            >
              {({
                touched,
                errors,
                isSubmitting,
                handleChange,
                submitForm,
                values,
                dirty,
              }) => (
                <S.StyledForm onKeyPress={() => handleEnter(submitForm)}>
                  <S.MainContainer>
                    <S.LeftSide>
                      {configNS.map((item) => (
                        <S.FormItem key={item.id}>
                          <S.Label>{i18n.t(item.name)}</S.Label>
                          <S.Input
                            name={item.id}
                            placeholder={item.placeholder}
                          />
                          {errors[item.id] && touched[item.id] && (
                            <S.ErrorMessage>
                              {i18n.t(errors[item.id])}
                            </S.ErrorMessage>
                          )}
                        </S.FormItem>
                      ))}
                    </S.LeftSide>
                    <S.RightSide>
                      {configIP.map((item) => (
                        <S.FormItem key={item.id}>
                          <S.Label>{i18n.t(item.name)}</S.Label>
                          <S.Input
                            name={item.id}
                            placeholder={item.placeholder}
                          />
                          {errors[item.id] && touched[item.id] && (
                            <S.ErrorMessage>
                              {i18n.t(errors[item.id])}
                            </S.ErrorMessage>
                          )}
                        </S.FormItem>
                      ))}
                    </S.RightSide>
                  </S.MainContainer>
                  <S.SubmitButton
                    type="submit"
                    disabled={isSubmitting || !dirty}
                  >
                    {updateGlueLoading ? (
                      <Loader color="#ffffff" size={0.5} />
                    ) : (
                      i18n.t('nameServerModal.submit')
                    )}
                  </S.SubmitButton>
                  <S.MainErrorMessage>
                    {updateGlueError && updateGlueError}
                  </S.MainErrorMessage>
                </S.StyledForm>
              )}
            </Formik>
          </S.FormContainer>
          <S.CloseModalButton onClick={() => hideModal()} />
        </S.GlueRecordsModalContainer>
      )}
    </S.Modal>
  );
};
