import React from 'react'
import { LayoutCommon } from '../../layouts/LayoutCommon'
import { Helmet } from 'react-helmet'
import { i18n } from '../../i18n'
import config from './SpecialTermsPage.config'

import * as S from './SpecialTermsPage.styles'

const SpecialTermsPage = () => (
  <LayoutCommon>
    <Helmet>
      <title>{i18n.t('headers.specialTermsPage')}</title>
      <meta
        name="description"
        content={i18n.t('headersDescriptions.specialTermsPage')}
      />
      <link
        rel="alternate"
        href="https://internet.am/en/special-terms"
        hreflang="en"
      />
      <link
        rel="alternate"
        href="https://internet.am/ru/special-terms"
        hreflang="ru"
      />
      <link
        rel="alternate"
        href="https://internet.am/special-terms"
        hreflang="am-AM"
      />

      <meta
        name="keywords"
        content={i18n.t('headerKeywords.specialTermsPage')}
      />
      <meta property="og:title" content={i18n.t('headers.specialTermsPage')} />
      <meta
        property="og:description"
        content={i18n.t('headersDescriptions.specialTermsPage')}
      />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="ABC Domain LLC" />
      <meta
        property="og:image"
        content="https://internet.am/images/fb_logo.png"
      />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={i18n.t('headers.specialTermsPage')} />
      <meta
        name="twitter:description"
        content={i18n.t('headersDescriptions.specialTermsPage')}
      />
      <meta
        name="twitter:image"
        content="https://internet.am/images/fb_logo.png"
      />
    </Helmet>
    <S.PageBody>
      <S.SpecialTermsBodyContainer>
        <S.SpecialTermsTitle>
          {i18n.t('specialTermsPage.title')}
        </S.SpecialTermsTitle>
        {/* <S.SpecialTermsContent
          dangerouslySetInnerHTML={{
            __html: i18n.t('specialTermsPage.content'),
          }}
        /> */}
        <S.MainBlock>
          <S.TermsList>
            {config.map((item) => (
              <S.TermsItem key={item.id}>
                <S.TermsLink href={i18n.t(item.href)} download>
                  <S.TermsItemName>{i18n.t(item.text)}</S.TermsItemName>
                </S.TermsLink>
              </S.TermsItem>
            ))}
          </S.TermsList>
        </S.MainBlock>
      </S.SpecialTermsBodyContainer>
    </S.PageBody>
  </LayoutCommon>
)

export { SpecialTermsPage }
