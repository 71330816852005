import { OPEN_TICKET_TYPES } from './OpenTicketPage.types';

const initialState = {
  loading: false,
  error: null,
};

function openTicketPage(state = initialState, action) {
  switch (action.type) {
    case OPEN_TICKET_TYPES.OPEN_TICKET_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case OPEN_TICKET_TYPES.OPEN_TICKET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case OPEN_TICKET_TYPES.OPEN_TICKET_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}

export { openTicketPage };
