import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import { TicketsTable as Self } from "./TicketsTable";
import {
  toggleFilterPopup,
  showSortPopup,
  showSettingsPopup,
  getTickets,
} from "../../ClientAreaPage.actions";
import { showModal } from "../../../../common/Modal/Modal.actions";

const mapStateToProps = ({ clientAreaPage }) => ({
  data: clientAreaPage.ticketsData,
  loading: clientAreaPage.ticketsLoading,
  success: clientAreaPage.ticketsRequestSuccess,
  total: clientAreaPage.ticketsTotal,
  statuses: clientAreaPage.ticketsStatuses,
  filter: clientAreaPage.filters.tickets,
  offsets: clientAreaPage.offsets,
  sortField: clientAreaPage.sortField.tickets,
  sortOrder: clientAreaPage.sortOrder.tickets,
});

const mapDispatchToProps = {
  toggleFilterPopup,
  showSortPopup,
  showSettingsPopup,
  getTickets,
  showModal,
};

export const TicketsTable = withRouter(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Self))
);
