import styled from 'styled-components';

export const Languages = styled.div`
  position: absolute;
  display: ${(p) => (p.isDesktop ? 'none' : 'flex')};
  flex-direction: column;
  width: 120px;
  max-height: 300px;
  background: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  bottom: 40px;
  right: 40px;
  border: 1px solid #eeeeee;

  @media (min-width: 1280px) {
    display: flex;
    border-radius: 0px 0px 5px 5px;
    border: none;
    border-top: 1px solid #212121;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
    bottom: unset;
    right: 0;
    top: 77px;
  }
`;

export const LanguageContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  align-items: center;
  justify-content: space-around;
  margin: 5px 0px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const Flag = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 1px;
  background-image: url(${(p) => p.img});
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const Text = styled.p`
  font-family: ${(p) => p.lng === 'Հայերեն' ? 'Mardoto' : 'Roboto'};
  font-weight: 500;
  font-size: 14px;
  color: #242e46;
  width: 60px;
  text-align: left;
`;
