import { LOGIN_TYPES } from './LoginModal.types';
import { MODAL_TYPES } from '../../../common/Modal/Modal.types';

const initialState = {
  loading: false,
  error: null,
  loginAsClientError: null,
  contacts: [],
  contactsLoading: false,
  contactsError: null,
  domains: [],
  domainsLoading: false,
  domainsError: null,
  verify2FALoading: false,
  verify2FAData: {},
  verify2FAError: null,
  removeContactLoading: false,
  removeContactError: null,
  info: {},
  updateLoading: false,
  updateError: false,
  resendMailOk: false,
};

export const loginModal = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_TYPES.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGIN_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case LOGIN_TYPES.LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case LOGIN_TYPES.GET_CONTACTS_REQUEST:
      return {
        ...state,
        contactsLoading: true,
        contacts: [],
        contactsError: null,
      };
    case LOGIN_TYPES.GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.contacts,
        contactsLoading: false,
        contactsError: null,
      };
    case LOGIN_TYPES.GET_CONTACTS_FAIL:
      return {
        ...state,
        contactsLoading: false,
        contacts: [],
        contactsError: action.error,
      };
    case LOGIN_TYPES.ADD_NEW_CONTACT:
      return {
        ...state,
        contacts: [...state.contacts, action.contact],
      };
    case LOGIN_TYPES.REMOVE_CONTACT_REQUEST: {
      const contactsCopy = [...state.contacts];
      const contactIndex = contactsCopy.findIndex(
        (item) => item.id === action.contactId
      );
      contactsCopy.splice(contactIndex, 1);
      return {
        ...state,
        contacts: contactsCopy,
        removeContactLoading: true,
        removeContactError: false,
      };
    }
    case LOGIN_TYPES.REMOVE_CONTACT_SUCCESS:
      return {
        ...state,
        removeContactLoading: false,
        removeContactError: false,
      };
    case LOGIN_TYPES.REMOVE_CONTACT_FAIL:
      return {
        ...state,
        removeContactLoading: false,
        removeContactError: action.error,
      };
    case LOGIN_TYPES.UPDATE_CONTACT_INFO_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: null,
      };
    case LOGIN_TYPES.UPDATE_CONTACT_INFO_SUCCESS:
      const contactsCopy = [...state.contacts];
      const contactIndex = contactsCopy.findIndex(
        (item) => item.id === action.contactId
      );
      contactsCopy[contactIndex] = {
        ...contactsCopy[contactIndex],
        ...action.values,
      };
      return {
        ...state,
        contacts: contactsCopy,
        updateLoading: false,
        updateError: null,
      };
    case LOGIN_TYPES.UPDATE_CONTACT_INFO_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: action.error,
      };
    case LOGIN_TYPES.GET_DOMAINS_REQUEST:
      return {
        ...state,
        domainsLoading: true,
        domains: [],
        domainsError: null,
      };
    case LOGIN_TYPES.GET_DOMAINS_SUCCESS:
      return {
        ...state,
        domains: action.domains,
        domainsLoading: false,
        domainsError: null,
      };
    case LOGIN_TYPES.GET_DOMAINS_FAIL:
      return {
        ...state,
        domainsLoading: false,
        domains: [],
        domainsError: action.error,
      };
    case LOGIN_TYPES.VERIFY_2FA_REQUEST:
      return {
        ...state,
        verify2FALoading: true,
      };
    case LOGIN_TYPES.VERIFY_2FA_SUCCESS:
      return {
        ...state,
        verify2FALoading: false,
      };
    case LOGIN_TYPES.VERIFY_2FA_FAIL:
      return {
        ...state,
        verify2FALoading: false,
        verify2FAError: action.error,
      };
    case LOGIN_TYPES.CHANGE_LOGIN_TAB:
      return {
        ...state,
        loginTab: action.tabId,
      };
    case LOGIN_TYPES.LOGIN_AS_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        loginAsClientError: action.error,
      };
    case MODAL_TYPES.HIDE_MODAL:
      return {
        ...initialState,
        contacts: state.contacts,
      };
    case LOGIN_TYPES.RESEND_EMAIL_REQUEST:
      return {
        ...state,
        resendMailOk: false,
        loading: true,
        error: null,
      };
    case LOGIN_TYPES.RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        resendMailOk: true,
        loading: false,
        error: null,
      };
    case LOGIN_TYPES.RESEND_EMAIL_FAIL:
      return {
        ...state,
        resendMailOk: false,
        loading: false,
        error: action.error,
      };
    case LOGIN_TYPES.CLEAR_FORM:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
