import React, { useCallback, useEffect, useRef } from 'react'
import qs from 'qs'
import { Formik } from 'formik'
import { Helmet } from 'react-helmet'

import * as S from './SupportPage.styles'
import { LayoutCommon } from '../../layouts/LayoutCommon'
import { questions, SUPPORT_TABS } from './SupportPage.constants'
import { QuestionsList } from '../../components/QuestionsList'
import { i18n } from '../../i18n'
import { initialValues, yupScheme } from './SupportPage.config'
import { Tooltip } from '../../components/Tooltip'

import { changeUrl } from '../../hooks/changeUrl'

export const SupportPage = ({
  history,
  changeTab,
  tab,
  setResults,
  setSearchResultsShouldShow,
  searchResultsShouldShow,
  results,
}) => {
  const formikRef = useRef(null)
  useEffect(() => {
    const params = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
      encode: true,
    })
    if (params.searchQuery) {
      setSearchResultsShouldShow(true)
      const filteredQuestions = []
      Object.values(questions).forEach((item) => {
        filteredQuestions.push(
          ...item.list.filter(
            (q) =>
              i18n.t(q.question).includes(params.searchQuery) ||
              i18n.t(q.answer).includes(params.searchQuery) ||
              (q.tags && q.tags.some((item) => item === params.searchQuery))
          )
        )
      })
      if (formikRef.current) {
        formikRef.current.setFieldValue('question', params.searchQuery)
      }
      setResults(filteredQuestions)
    } else {
      setSearchResultsShouldShow(false)
    }
  }, [history.location.search, setResults, setSearchResultsShouldShow])

  const featuredQuestions = [...Object.values(questions)]
    .map((item) => item.list)
    .flat()
    .filter((item) => item.isFeatured)

  const handleSearch = useCallback((question) => {
    if (question) {
      history.push(changeUrl(`/support?searchQuery=${question}`))
    } else {
      history.push(changeUrl('/support'))
    }
  })

  const handleEnter = useCallback(
    (e, question) => {
      if (e.keyCode === 13) {
        handleSearch(question)
      }
    },
    [handleSearch]
  )

  const resetQuestions = useCallback(
    (resetForm, setFieldTouched) => {
      setFieldTouched('question', true)
      resetForm()
      setResults([])
      history.push(changeUrl('/support'))
    },
    [history, setResults]
  )

  return (
    <LayoutCommon>
      <Helmet>
        <title>{i18n.t('headers.supportPage')}</title>
        <meta
          name="description"
          content={i18n.t('headersDescriptions.supportPage')}
        />
        <link
          rel="alternate"
          href="https://internet.am/en/support"
          hreflang="en"
        />
        <link
          rel="alternate"
          href="https://internet.am/ru/support"
          hreflang="ru"
        />
        <link
          rel="alternate"
          href="https://internet.am/support"
          hreflang="am-AM"
        />

        <meta property="og:title" content={i18n.t('headers.supportPage')} />
        <meta
          property="og:description"
          content={i18n.t('headersDescriptions.supportPage')}
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ABC Domain LLC" />
        <meta
          property="og:image"
          content="https://internet.am/images/fb_logo.png"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={i18n.t('headers.supportPage')} />
        <meta
          name="twitter:description"
          content={i18n.t('headersDescriptions.supportPage')}
        />
        <meta
          name="twitter:image"
          content="https://internet.am/images/fb_logo.png"
        />
      </Helmet>
      <S.PageBody>
        <S.QuestionPageContainer>
          <S.HeaderWrapper>
            <S.TitleSearchWrapper>
              <S.QuestionPageTitle>
                {i18n.t('supportPage.title')}
              </S.QuestionPageTitle>
              <Formik
                initialValues={initialValues}
                validationSchema={yupScheme}
                onSubmit={(values, { setSubmitting }) => {
                  handleSearch(values.question)
                  setSubmitting(true)
                }}
                innerRef={formikRef}
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  resetForm,
                  setFieldTouched,
                  dirty,
                }) => (
                  <S.FormContainer>
                    <S.SearchInputContainer>
                      <S.SearchInput
                        name="question"
                        value={values.question}
                        onChange={handleChange}
                        onKeyDown={(e) => handleEnter(e, values.question)}
                        placeholder={i18n.t('supportPage.placeholderText')}
                        isError={touched.question && errors.question}
                      />

                      {touched.question && errors.question && (
                        <S.ErrorIconContainer className="tooltipContainer">
                          <S.ErrorIcon />
                          <Tooltip className="tooltip top right noArrow error">
                            {i18n.t(errors.question)}
                          </Tooltip>
                        </S.ErrorIconContainer>
                      )}
                      {searchResultsShouldShow && dirty && (
                        <S.ResetIcon
                          type="reset"
                          onClick={() =>
                            resetQuestions(resetForm, setFieldTouched)
                          }
                        />
                      )}
                    </S.SearchInputContainer>
                    <S.SearchButton type="submit">
                      {i18n.t('supportPage.search')}
                    </S.SearchButton>
                  </S.FormContainer>
                )}
              </Formik>
            </S.TitleSearchWrapper>
            <S.QuestionsImg />
          </S.HeaderWrapper>
          <S.QuestionsContainer>
            {searchResultsShouldShow ? (
              <>
                <S.FAQSectionTitle>
                  {i18n.t('supportPage.searchResults')}
                </S.FAQSectionTitle>
                {!!results.length ? (
                  <QuestionsList questions={results} />
                ) : (
                  <S.EmptyStateContainer>
                    {i18n.t('supportPage.noSearchResults')}
                  </S.EmptyStateContainer>
                )}
              </>
            ) : (
              <>
                <S.TabMenu>
                  <S.Tab
                    onClick={() => changeTab(SUPPORT_TABS.FREQUENT)}
                    isActive={tab === SUPPORT_TABS.FREQUENT}
                  >
                    {i18n.t('supportPage.knownSection')}
                  </S.Tab>
                  <S.Tab
                    onClick={() => changeTab(SUPPORT_TABS.CATEGORIES)}
                    isActive={tab === SUPPORT_TABS.CATEGORIES}
                  >
                    {i18n.t('supportPage.categories')}
                  </S.Tab>
                </S.TabMenu>

                {tab === SUPPORT_TABS.FREQUENT &&
                  Object.values(questions).map((section) => (
                    <S.FAQSection>
                      <S.FAQSectionTitle>
                        {i18n.t(section.id)}
                      </S.FAQSectionTitle>
                      <QuestionsList questions={section.list} />
                    </S.FAQSection>
                  ))}
                {tab === SUPPORT_TABS.CATEGORIES &&
                  Object.values(questions).map((section) => (
                    <S.FAQSection>
                      <S.FAQSectionTitle>
                        {i18n.t(section.id)}
                      </S.FAQSectionTitle>
                      <QuestionsList questions={section.list} />
                    </S.FAQSection>
                  ))}
              </>
            )}
          </S.QuestionsContainer>
        </S.QuestionPageContainer>
      </S.PageBody>
    </LayoutCommon>
  )
}
