import { RESET_PASSWORD_TYPES } from './ResetPasswordModal.types';
import { MODAL_TYPES } from '../../../common/Modal/Modal.types';

const initialState = {
  loading: false,
  error: null,
  data: null,
};

export const passwordResetModal = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_TYPES.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RESET_PASSWORD_TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data,
      };
    case RESET_PASSWORD_TYPES.RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: null,
      };
    case RESET_PASSWORD_TYPES.CLEAR_FORM:
      return {
        ...state,
        error: null
      }
    case MODAL_TYPES.HIDE_MODAL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
