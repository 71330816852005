import { connect } from 'react-redux';
import { AddContactModal as Self } from './AddContactModal';
import { addContact } from './AddContactModal.actions';
import { addNewContact } from '../LoginModal/LoginModal.actions';
import { updateContactInfo } from '../LoginModal/LoginModal.actions';
import { showModal } from '../../../common/Modal/Modal.actions';
import { cleanRegisterError } from '../../../pages/RegistrationPage/RegistrationPage.actions';
import { withTranslation } from 'react-i18next';

const mapStateToProps = ({ addContactModalPage, loginModal }) => ({
  loading: addContactModalPage.loading,
  error: addContactModalPage.error,
  updateLoading: loginModal.updateLoading,
});

const mapDispatchToProps = {
  addContact,
  addNewContact,
  updateContactInfo,
  cleanRegisterError,
  showModal,
};

export const AddContactModal = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
