import korea from '../../images/south-korea.png'
import uk from '../../images/uk.png'
import us from '../../images/us.png'
import canada from '../../images/canada.png'
import singapore from '../../images/singapore.png'
import netherlands from '../../images/netherlands.png'
import germany from '../../images/germany.png'
import france from '../../images/france.png'
import australia from '../../images/australia.png'
import japan from '../../images/japan.png'
import centOs from '../../images/centos.png'
import debian from '../../images/debian.png'
import fedora from '../../images/fedora.png'
import freeBsd from '../../images/freebsd.png'
import openBsd from '../../images/openbsd.png'
import ubuntu from '../../images/ubuntu.png'
import sweden from '../../images/swe.png'
import mexico from '../../images/mexico.png'
import brazil from '../../images/brazil.png'
import poland from '../../images/poland.png'
import spain from '../../images/spain.png'
import india from '../../images/india.png'
import southAfrica from '../../images/southAfrica.png'
import chile from '../../images/chile.png'
import israel from '../../images/israel.png'

export const serversLocations = [
  {
    id: '1',
    image: korea,
    city: 'Seoul',
    country: 'South Korea',
    name: 'KR',
  },
  {
    id: '2',
    image: uk,
    city: 'London',
    country: 'United Kingdom',
    name: 'GB',
  },

  {
    id: '3',
    image: canada,
    city: 'Toronto',
    country: 'Canada',
    name: 'CA',
  },
  {
    id: '4',
    image: singapore,
    city: 'Singapore',
    country: 'Singapore',
    name: 'SG',
  },
  {
    id: '5',
    image: sweden,
    city: 'Stockholm',
    country: 'Sweden',
    name: 'SE',
  },
  {
    id: '6',
    image: netherlands,
    city: 'Amsterdam',
    country: 'Netherlands',
    name: 'NL',
  },
  {
    id: '7',
    image: germany,
    city: 'Frankfurt',
    country: 'Germany',
    name: 'DE',
  },
  {
    id: '8',
    image: france,
    city: 'Paris',
    country: 'France',
    name: 'FR',
  },
  {
    id: '9',
    image: australia,
    city: 'Sydney',
    country: 'Australia',
    name: 'AU',
  },
  {
    id: '10',
    image: japan,
    city: 'Tokio',
    country: 'Japan',
    name: 'JP',
  },

  {
    id: '11',
    image: us,
    city: 'Chicago',
    country: 'United States',
    name: 'US',
  },
  {
    id: '12',
    image: us,
    city: 'Atlanta',
    country: 'United States',
    name: 'US',
  },
  {
    id: '13',
    image: us,
    city: 'New York',
    country: 'United States',
    name: 'US',
  },
  {
    id: '14',
    image: us,
    city: 'Dallas',
    country: 'United States',
    name: 'US',
  },
  {
    id: '15',
    image: us,
    city: 'Los Angeles',
    country: 'United States',
    name: 'US',
  },
  {
    id: '16',
    image: us,
    city: 'Miami',
    country: 'United States',
    name: 'US',
  },
  {
    id: '17',
    image: us,
    city: 'Seattle',
    country: 'United States',
    name: 'US',
  },
  {
    id: '18',
    image: us,
    city: 'Sillicon Valley',
    country: 'United States',
    name: 'US',
  },
  {
    id: '19',
    image: mexico,
    city: 'Mexico City',
    country: 'Mexico',
    name: 'MX',
  },
  {
    id: '20',
    image: brazil,
    city: 'São Paulo',
    country: 'Brazil',
    name: 'BR',
  },
  {
    id: '21',
    image: poland,
    city: 'Warsaw',
    country: 'Poland',
    name: 'PL',
  },
  {
    id: '22',
    image: spain,
    city: 'Madrid',
    country: 'Spain',
    name: 'ES',
  },
  {
    id: '23',
    image: india,
    city: 'Mumbai',
    country: 'India',
    name: 'IN',
  },
  {
    id: '24',
    image: india,
    city: 'Bangalore',
    country: 'India',
    name: 'IN',
  },
  {
    id: '25',
    image: southAfrica,
    city: 'Johannesburg',
    country: 'South Africa',
    name: 'ZA',
  },
  {
    id: '26',
    image: chile,
    city: 'Santiago',
    country: 'Chile',
    name: 'CL',
  },
  {
    id: '27',
    image: israel,
    city: 'Tel Aviv',
    country: 'Israel',
    name: 'IL',
  },
]

export const serverTypes = [
  {
    id: '1',
    image: centOs,
    name: 'centos',
    type: '8 x64',
  },
  {
    id: '2',
    image: debian,
    name: 'debian',
    type: 'Select Version',
  },
  {
    id: '3',
    image: fedora,
    name: 'fedora',
    type: 'Select Version',
  },
  {
    id: '4',
    image: freeBsd,
    name: 'freebsd',
    type: 'Select Version',
  },
  {
    id: '5',
    image: openBsd,
    name: 'openbsd',
    type: 'Select Version',
  },
  {
    id: '6',
    image: ubuntu,
    name: 'ubuntu',
    type: 'Select Version',
  },
]

export const VPSPackages = [
  {
    id: '1',
    name: '25 GB SSD',
    price: 10,
    priceH: 0.007,
    cpu: '1 CPU',
    memory: '1024 MB Memory',
    gb: '1000 GB Bandwidth',
  },
  {
    id: '2',
    name: '50 GB SSD',
    price: 10,
    priceH: 0.007,
    cpu: '1 CPU',
    memory: '1024 MB Memory',
    gb: '1000 GB Bandwidth',
  },
  {
    id: '3',
    name: '100 GB SSD',
    price: 10,
    priceH: 0.007,
    cpu: '1 CPU',
    memory: '1024 MB Memory',
    gb: '1000 GB Bandwidth',
  },
  {
    id: '4',
    name: '150 GB SSD',
    price: 10,
    priceH: 0.007,
    cpu: '1 CPU',
    memory: '1024 MB Memory',
    gb: '1000 GB Bandwidth',
  },
]
