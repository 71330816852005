import React, { useCallback, useEffect } from 'react'
import { initialValues, yupScheme } from './ContactOwnerModal.config'
import { ContactOwnerSuccessModal } from '../ContactOwnerSuccessModal'
import { useSearchParams } from '../../../hooks/useSearchParams'
import { Formik } from 'formik'
import { Loader } from '../../../components/Loader'
import { i18n } from '../../../i18n'
import slugify from 'react-slugify'

import * as S from './ContactOwnerModal.styles'

export const ContactOwnerModal = ({
  hideModal,
  contactOwner,
  showModal,
  error,
  loading,
  history,
}) => {
  const [searchParams, updateSearchParams] = useSearchParams({ history })

  const handleEnter = useCallback((e, submitForm) => {
    if (e.key === 'Enter') {
      submitForm()
    }
  }, [])

  useEffect(() => {
    updateSearchParams({ ...searchParams, contact: 1 })
    return () => {
      const newParams = { ...searchParams }
      delete newParams.contact
      updateSearchParams(newParams)
    }
  }, [])

  return (
    <S.Modal onClick={(e) => e.stopPropagation()}>
      <S.ContactOwnerModalContainer>
        <S.ModalTitle>{i18n.t('contactDomainOwnerModal.title')}</S.ModalTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={yupScheme}
          onSubmit={(values) => {
            contactOwner(values, () => {
              showModal(ContactOwnerSuccessModal)
            })
          }}
        >
          {({
            touched,
            errors,
            values,
            isSubmitting,
            handleChange,
            submitForm,
          }) => (
            <S.StyledForm onKeyPress={() => handleEnter(submitForm)}>
              <S.FormItem>
                <S.Row>
                  <S.Label>
                    {i18n.t('contactDomainOwnerModal.domainLabel')}*
                  </S.Label>
                  <S.Input
                    placeholder={i18n.t(
                      'contactDomainOwnerModal.domainPlaceholder'
                    )}
                    onChange={handleChange}
                    name="domain"
                    value={values.domain}
                  />
                </S.Row>
                <S.Row>
                  {touched.domain && errors.domain && (
                    <S.ErrorMessage spaced>
                      {i18n.t(errors.domain)}
                    </S.ErrorMessage>
                  )}
                </S.Row>
              </S.FormItem>
              <S.FormItem>
                <S.Row>
                  <S.Label>
                    {i18n.t('contactDomainOwnerModal.nameLabel')}*
                  </S.Label>
                  <S.Input
                    placeholder={i18n.t(
                      'contactDomainOwnerModal.namePlaceholder'
                    )}
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                  />
                </S.Row>
                <S.Row>
                  {errors.name && touched.name && (
                    <S.ErrorMessage>{i18n.t(errors.name)}</S.ErrorMessage>
                  )}
                </S.Row>
              </S.FormItem>
              <S.FormItem type="email">
                <S.Row>
                  <S.Label>
                    {i18n.t('contactDomainOwnerModal.mailLabel')}*
                  </S.Label>
                  <S.Input
                    placeholder={i18n.t(
                      'contactDomainOwnerModal.mailPlaceholder'
                    )}
                    onChange={handleChange}
                    name="email"
                    value={values.email}
                  />
                </S.Row>
                <S.Row>
                  {errors.email && touched.email && (
                    <S.ErrorMessage>{i18n.t(errors.email)}</S.ErrorMessage>
                  )}
                </S.Row>
              </S.FormItem>

              <S.FormItem>
                <S.Row>
                  <S.Label>
                    {i18n.t('contactDomainOwnerModal.subjectLabel')}*
                  </S.Label>
                  <S.Input
                    placeholder={i18n.t(
                      'contactDomainOwnerModal.subjectPlaceholder'
                    )}
                    onChange={handleChange}
                    name="subject"
                    value={values.subject}
                  />
                </S.Row>
                <S.Row>
                  {errors.subject && touched.subject && (
                    <S.ErrorMessage>{i18n.t(errors.subject)}</S.ErrorMessage>
                  )}
                </S.Row>
              </S.FormItem>
              <S.FormItem>
                <S.Row>
                  <S.Label>
                    {i18n.t('contactDomainOwnerModal.messageLabel')}*
                  </S.Label>
                  <S.Textarea
                    placeholder={i18n.t(
                      'contactDomainOwnerModal.messagePlaceholder'
                    )}
                    onChange={handleChange}
                    name="message"
                    value={values.message}
                    maxLength={1000}
                    isError={values.message.length === 1000}
                  />
                </S.Row>
                <S.CounterRow>
                  <S.Counter isError={values.message.length === 1000}>
                    {values.message.length}/1000
                  </S.Counter>
                </S.CounterRow>
                <S.Row>
                  {errors.message && touched.message && (
                    <S.ErrorMessage>{i18n.t(errors.message)}</S.ErrorMessage>
                  )}
                </S.Row>
              </S.FormItem>

              <S.SubmitButton>
                {loading ? (
                  <Loader color="#FFF" size={0.5} />
                ) : (
                  i18n.t('contactDomainOwnerModal.submit')
                )}
              </S.SubmitButton>
              {error && (
                <S.ErrorMessage spaced>
                  {i18n.t(`backend.contactOwnerModal.${slugify(error)}`)}
                </S.ErrorMessage>
              )}
            </S.StyledForm>
          )}
        </Formik>
        <S.CloseModalButton onClick={() => hideModal()} />
      </S.ContactOwnerModalContainer>
    </S.Modal>
  )
}
