import * as Yup from 'yup';

export const initialValues = {
  email: '',
  domain: '',
  subject: '',
  name: '',
  message: '',
};

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || 'Two strings should be equal',
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref);
    },
  });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);

export const yupScheme = Yup.object().shape({
  email: Yup.string()
    .email('validationFields.invalidTyping')
    .required('validationFields.email'),

  domain: Yup.string()
    .matches(
      /^[a-zA-Z0-9\u0561-\u0587\u0531-\u0556]{1,256}\.[a-zA-Z0-9\u0561-\u0587\u0531-\u0556]{2,30}(\.[a-zA-Z0-9\u0561-\u0587\u0531-\u0556]{2,30})?$/,
      'validationFields.invalidTyping'
    )
    .required('validationFields.domain'),

  name: Yup.string()
    .min(2, 'validationFields.firstnameMinPhysical')
    .max(72, 'validationFields.firstnameMaxPhysical')
    .matches(/^([-A-Za-z0-9 ]){1,72}$/, 'validationFields.invalidTyping')
    .required('validationFields.firstnamePhysical'),

  subject: Yup.string()
    .min(2, 'validationFields.subjectMin')
    .max(200, 'validationFields.subjectMax')
    .required('validationFields.subject'),

  message: Yup.string()
    .min(2, 'validationFields.messageMin')
    .max(2000, 'validationFields.messageMax')
    .required('validationFields.message'),
});
