import styled from 'styled-components'
import * as fontWeight from '../../../../assets/styles/constants/fontWeight'
import Container from '../../../../components/Container'
import { Link } from 'react-router-dom'

export const ServiceCartDiv = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 25%);
  border-radius: 5px;
  padding: 25px;
  width: 335px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    margin: 0px;
    width: 320px;
    height: 320px;
    position: relative;
  }

  @media (min-width: 1280px) {
    margin: 0px;
    width: 314px;
    height: 310px;
  }
`

export const ServiceTitle = styled.h3`
  margin-top: 100px;
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #242e46;
  background: #fff @media (min-width: 768px) {
    font-size: 24px;
  }
  @media (min-width: 1280px) {
    font-size: 24px;
  }
`

export const ServiceDescription = styled.div`
  font-size: 14px;
  text-align: center;
  color: #737373;
  max-width: 532px;
  margin: 30px 20px;
  font-weight: 300;

  @media (min-width: 768px) {
    margin: 20px 0px 40px 0px;
    font-size: 18px;
  }
`

export const ServiceCartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  // margin: 10px 5px;
`

export const ServiceCartTitle = styled.div`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 18px;
  color: #242e46;
`

export const ServiceCartImage = styled.img`
  width: 36px;
  height: 36px;
`

export const ServiceCartDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #242e46;
  margin-top: 20px;
`

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
  // padding: 7px 20px;
  @media (min-width: 768px) {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
`

export const ServiceButton = styled(Link)`
  background-color: #242e46;
  color: #ffffff;
  border-radius: 5px;
  padding: 9px 36px;
  border: none;
  text-decoration: none;
  font-size: 14px;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`

export const ContainerOfServicesCarts = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;

  @media (min-width: 768px) {
    gap: 70px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1490px) {
    display: flex;
    gap: 30px;
  }
`

export const ContainerOfServices = styled(Container)`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  align-items: center;
  width: unset;

  @media (min-width: 768px) {
    marign-top: 20px;
  }
`

export const ServicesSideFiller = styled.div`
  background-color: #ffffff;
  ${'' /* padding-top: 100px; */}

  @media (min-width: 768px) {
    ${'' /* padding-top: 120px; */}
  }
  @media (min-width: 1280px) {
    ${'' /* padding-top: 200px; */}
  }
`
