import React from 'react';

import * as S from './RegistrationSuccessModal.styles';
import { i18n } from '../../../i18n';

export const RegistrationSuccessModal = ({ hideModal }) => (
  <S.Modal onClick={(e) => e.stopPropagation()}>
    <S.RegistrationModalContainer>
      <S.Image />
      <S.Header>{i18n.t('registrationModal.header')}</S.Header>
      <S.SubHeader>{i18n.t('registrationModal.subHeader')}</S.SubHeader>
      <S.CloseModalButton onClick={hideModal} />
    </S.RegistrationModalContainer>
  </S.Modal>
);
