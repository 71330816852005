import React from 'react'
import { LayoutCommon } from '../../layouts/LayoutCommon'
import { changeUrl } from '../../hooks/changeUrl'
import { i18n } from '../../i18n'
// import failImg from '../../images/reset-password-fail.png'
import successIcon from '../../images/greenCircle.png'

import * as S from './PaymentSuccessPage.styles'

export const PaymentSuccessPage = () => {
  return (
    <LayoutCommon>
      <S.PaymentSuccessPageContainer>
        <S.DataContainer>
          <S.Image src={successIcon} />
          <S.Text>{i18n.t(`paymentSuccessStatus.success`)}</S.Text>
        </S.DataContainer>
        <S.BackLinkBtn to={changeUrl('/client-area')}>
          {i18n.t(`goToClientAreaPage.backButton`)}
        </S.BackLinkBtn>
      </S.PaymentSuccessPageContainer>
    </LayoutCommon>
  )
}
