import { EDIT_PROFILE_TYPES } from '../../EditProfilePage.types'
import FetchApi from '../../../../helpers/FetchApi'

export const changePass = (value, oldpass, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_PROFILE_TYPES.CHANGE_PASSWORD_REQUEST,
    })
    await FetchApi.post('users/passchange', {
      oldpass: oldpass,
      password: value,
      password2: value,
    })
    dispatch({
      type: EDIT_PROFILE_TYPES.CHANGE_PASSWORD_SUCCESS,
    })
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    dispatch({
      type: EDIT_PROFILE_TYPES.CHANGE_PASSWORD_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    })
  }
}

export const getActivateStatus = (email) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_PROFILE_TYPES.GET_ACTIVATE_STATUS_REQUEST,
    })
    const response = await FetchApi.post('users/checkIf2FAison', {
      email,
    })

    dispatch({
      type: EDIT_PROFILE_TYPES.GET_ACTIVATE_STATUS_SUCCESS,
      activateStatus: response.data.rowFound,
      qrCodeToken: response.data.result.token,
    })
  } catch (e) {
    dispatch({
      type: EDIT_PROFILE_TYPES.GET_ACTIVATE_STATUS_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    })
  }
}

export const disableActivateLink = (token, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_PROFILE_TYPES.DISABLE_ACTIVATE_REQUEST,
    })
    await FetchApi.post('users/disable2Fa', {
      token,
    })
    dispatch({
      type: EDIT_PROFILE_TYPES.DISABLE_ACTIVATE_SUCCESS,
    })
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    dispatch({
      type: EDIT_PROFILE_TYPES.DISABLE_ACTIVATE_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    })
  }
}
