import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  @media (min-width: 1280px) {
    margin: 0 auto;
    width: 1180px;
  }
`;

export default Container;
