import React from "react";

export default (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.87"
      d="M9.00968 14.6715L8.67135 15.0215V6.16664C8.67135 5.85723 8.54844 5.56049 8.32965 5.3417C8.11086 5.12291 7.81412 5 7.50471 5C7.19529 5 6.89855 5.12291 6.67976 5.3417C6.46098 5.56049 6.33806 5.85723 6.33806 6.16664V15.0215L5.99973 14.6715C5.78005 14.4518 5.4821 14.3284 5.17142 14.3284C4.86074 14.3284 4.56278 14.4518 4.3431 14.6715C4.12342 14.8912 4 15.1891 4 15.4998C4 15.8105 4.12342 16.1084 4.3431 16.3281L6.67639 18.6614C6.78734 18.7676 6.91817 18.8509 7.06138 18.9064C7.20103 18.9681 7.35203 19 7.50471 19C7.65739 19 7.80838 18.9681 7.94803 18.9064C8.09124 18.8509 8.22207 18.7676 8.33302 18.6614L10.6663 16.3281C10.7751 16.2193 10.8614 16.0902 10.9202 15.9481C10.9791 15.806 11.0094 15.6536 11.0094 15.4998C11.0094 15.346 10.9791 15.1936 10.9202 15.0515C10.8614 14.9094 10.7751 14.7803 10.6663 14.6715C10.5575 14.5627 10.4284 14.4764 10.2863 14.4175C10.1442 14.3587 9.99183 14.3284 9.83799 14.3284C9.68416 14.3284 9.53183 14.3587 9.38971 14.4175C9.24759 14.4764 9.11845 14.5627 9.00968 14.6715Z"
      fill={props.fillcolor}
    />
    <path
      d="M17.6289 4.30469V10H16.5V5.64453L15.1562 6.0625V5.14844L17.5078 4.30469H17.6289Z"
      fill={props.fillcolor}
    />
    <path
      d="M18.6758 15.9453C18.6758 16.5703 18.569 17.1146 18.3555 17.5781C18.1445 18.0417 17.8255 18.4036 17.3984 18.6641C16.974 18.9219 16.4505 19.0534 15.8281 19.0586H15.6875V18.1133H15.7812C16.3359 18.1107 16.7552 17.9922 17.0391 17.7578C17.3229 17.5234 17.4883 17.1784 17.5352 16.7227C17.2357 17.0143 16.8893 17.1602 16.4961 17.1602C16.1497 17.1602 15.8516 17.0781 15.6016 16.9141C15.3516 16.7474 15.1615 16.5195 15.0312 16.2305C14.9036 15.9388 14.8398 15.6094 14.8398 15.2422C14.8398 14.8724 14.918 14.5352 15.0742 14.2305C15.2331 13.9232 15.457 13.6797 15.7461 13.5C16.0352 13.3203 16.3685 13.2305 16.7461 13.2305C17.1315 13.2305 17.4701 13.3268 17.7617 13.5195C18.0534 13.7122 18.2786 13.9844 18.4375 14.3359C18.5964 14.6875 18.6758 15.0938 18.6758 15.5547V15.9453ZM15.9648 15.2305C15.9648 15.431 15.9948 15.612 16.0547 15.7734C16.1146 15.9323 16.2044 16.0586 16.3242 16.1523C16.4466 16.2461 16.5964 16.293 16.7734 16.293C16.9505 16.293 17.1068 16.2474 17.2422 16.1562C17.3776 16.0651 17.4792 15.9479 17.5469 15.8047V15.3477C17.5469 15.0872 17.5117 14.8672 17.4414 14.6875C17.3737 14.5052 17.2786 14.3698 17.1562 14.2812C17.0365 14.1901 16.8984 14.1445 16.7422 14.1445C16.5833 14.1445 16.4453 14.1953 16.3281 14.2969C16.2109 14.3984 16.1211 14.5326 16.0586 14.6992C15.9961 14.8633 15.9648 15.0404 15.9648 15.2305Z"
      fill={props.fillcolor}
    />
  </svg>
);
