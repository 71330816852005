import { backendAm } from '../backend/am'

export const am = {
  translation: {
    ...backendAm,
    header: {
      domain: 'Դոմեն',
      constructor: 'Կոնստրուկտոր',
      hosting: 'Հոսթինգ',
      vps: 'VPS',
      ssl: 'SSL',
      support: 'Օգնություն',
      registration: 'Գրանցվել',
      login: 'Մուտք',
      logout: 'Ելք',
      blog: 'Բլոգ',
    },
    loginModal: {
      title: 'Մուտք',
      mail: 'Էլ. հասցե',
      password: 'Գաղտնաբառ',
      client: 'Հաճախորդ',
      rememberMe: 'Հիշել ինձ',
      question: 'Մոռացե՞լ եք գաղտնաբառը:',
      login: 'Մուտք',
      registerNow: 'Գրանցվել',
      resendEmail: 'Կրկին ուղարկել նամակը',
      emailSent: 'Նամակը կրկին ուղարկվել է',
    },
    cookiesModal: {
      accept: 'Պարզ է',
      header: 'Կայքն օգտագործում է cookie ֆայլեր',
      text: 'Կայքն օգտագործում է cookie ֆայլեր` կայքի բոլոր հնարավորություններից օգտվելը հասանելի դարձնելու համար',
    },
    maintenanceModal: {
      warning: 'Հարգելի գործընկերներ,',
      info1:
        // "«Ինտերնետ տեխնոլոգիաների կենտրոն» ՍՊԸ կողմից ստացել ենք տեղեկացում, առ այն, որ սույն թվականի դեկտեմբերի 18-ին և 19-ին տեղի են ունենալու տեխնիկական վերազինման աշխատանքներ, ինչը կարող է հանգեցնել վճարման հետ կապված խնդիրների .am և ․հայ տիրույթում գրանցված կամ գրանցվող դոմենների համար։ Հնարավոր բարդություններից խուսափելու համար կարող եք նշված տիրույթներում հաշվեգրված դոմենների դիմաց վճարում անել շրջանցելով վերազինման համար նախատեսված օրերը։ Շնորհակալ ենք համագործակցության և ըմբռնման համար։",
        '«Ինտերնետ տեխնոլոգիաների կենտրոն» ՍՊԸ կողմից ստացել ենք տեղեկացում, առ այն,  որ սույն թվականի մայիսի 8-ին իրականացվելու են տեխնիկական աշխատանքներ, ինչով պայմանավորված 10:00-ից մինչև 20:00-ն ընկած ժամանակահատվածում կարող է լինել  ծառայությունների  կարճաժամկետ անհասանելիություն (4–ից 5 ժամ)։',

      info2:
        'Մենք անում ենք հնարավոր ամեն ինչ մեր գործընկերներին գերազանց սպասարկում ապահովելու համար:',
      info3: 'Շնորհակալ ենք համագործակցության և ըմբռնման համար',
      info4: 'ԷյԲիՍիԴոմեն թիմ',
    },

    personalDetailsSuccessModal: {
      text: 'Փոփոխությունները հաջողությամբ պահպանված են',
    },
    authModal: {
      title: 'Մուտքագրեք երկփուլային վավերացման կոդը',
      header:
        'Մուտքագրեք 6 նիշ պարունակող կոդը, ոորը դուք տեսնում եք ձեր autenticator հավելվածում',
      confirmButton: 'Հաստատել',
      cancelButton: 'Չեղարկել',
    },

    resetPasswordModal: {
      title: 'Գաղտնաբառի վերականգնում',
      subTitle:
        'Մոռացե՞լ եք գաղտնաբառը։ Մուտքագրեք Ձեր էլ. հասցեն գաղտնաբառի վերականգնման գործընթացը սկսելու համար։ ',
      mail: 'Էլ. հասցե',
      placeholder: 'example@example.com',
      send: 'Ուղարկել ',
    },
    resetPasswordSuccessModal: {
      header: 'Ձեր գաղտնաբառի վերականգնման գործընթացը սկսվել է',
      subHeader: 'Խնդրում ենք ստուգել Ձեր էլ.հասցեն',
    },
    domainUnavailableModal: {
      header: 'Այս պահին մենք չենք կարող ավարտել Ձեր գնումը',
      subHeader: 'Խնդրում ենք կրկին ստուգել դոմենի հասանելիությունը',
      message: 'Նշված դոմեն(ներ)֊ը անհասանելի է(են)`',
    },
    underMaintenanceModal: {
      header:
        'Այս պահին կայքում տարվում են տեխնիկական աշխատանքներ և հնարավոր են խափանումներ',
    },
    changePasswordSuccessModal: {
      header: 'Ձեր գաղտնաբառը հաջողությամբ փոփոխվել է',
    },
    openTicketSuccesModal: {
      title: 'Շնորհակալություն!',
      header: 'Ձեր հայտն ընդունված է',
      subHeader: 'Խնդրում ենք ստուգել Ձեր էլ.հասցեն',
    },
    transferDomainModal: {
      title: 'Տեղափոխել դոմենը',
      domainLabel: 'Դոմեն',
      domainPlaceholder: 'example.am',
      eppCodeLabel: 'EPP Code',
      eppCodePlaceholder: 'EPP Code',
      submit: 'Տեղափոխել',
    },
    transferDomainSuccessModal: {
      title: 'Շնորհակալություն!',
      header: 'Ձեր հարցումն ընդունված է',
      subHeader: 'Հուսով ենք, որ շուտով լավ նորություններ կստանաք',
    },

    domainConfigurationPageLabels: {
      labelId: 'ID համար',
      labelTin: 'Հարկ վճարողի հաշվառման համար',
      labelDob: 'Ծննդյան տարեթիվ',
      labelPassportIssuer: 'Անձնագիր տրամադրող',
      labelPassportSeries: 'Անձնագրի սերիա',
      labelPassportDate: 'Անձնագրի տրամադրման ամսաթիվ',
      labelPhonenumber: 'Հեռախոսահամար',
      labelLegalAddress: 'Իրավաբանական հասցե',
      labelPostalAddress: 'Փոստային հասցե',
      labelCompanyName: 'Ընկերության անվանում',
    },

    domainConfigurationPageSubLabels: {
      subLabelId: '*ID կամ անձնագրի համար',
      subLabelTin: '*Պարտադիր է միայն կազմակերպությունների համար',
      subLabelDob: '*Պարտադիր է միայն ֆիզ․ անձանց համար',
    },

    footer: {
      main: 'Հիմնական',
      company: 'Ընկերություն',
      aboutUs: 'Ընկերության մասին',
      generalTerms: 'Դրույթներ և պայմաններ',
      specialTerms: 'Հատուկ պայմաններ և դրույթներ',
      contactUs: 'Կապ',
      useful: 'Օգտակար',
      punycodeConverter: 'Punycode converter',
      contactDomainOwner: 'Կապ հաստատել դոմենի իրավատիրոջ հետ',
      charter: 'ICANN խարտիա',
      icannLink: 'https://www.icann.org/resources/pages/benefits-2013-09-16-en',
      ip: 'IP:',
      hosting: 'Հոսթինգ',
      vpsServers: 'VPS փաթեթներ',
      linuxHosting: 'Linux Հոսթինգ',
      openTicket: 'Բացել աջակցության հայտ',
    },

    editProfilePage: {
      title: 'Իմ հաշիվը',
      personalDetails: 'Անձնական տվյալներ',
      contacts: 'Կոնտակտներ/Սուբ-հաշիվներ',
      security: 'Անվտանգություն',
      personalDetailsComponent: {
        saveButton: 'Պահպանել',
      },
      securityComponent: {
        currentPassword: 'Գործող գաղտնաբառը',
        newPassword: 'Նոր գաղտնաբառը',
        confirmPassword: 'Հաստատել գաղտնաբառը',
        noteHeader: 'Հուշում հուսալի գաղտնաբառի համար',
        noteTextOne: 'Ոչ պակաս, քան 12 նիշ` առնվազն մեկ մեծատառ, մեկ փոքրատառ',
        noteTextTwo: 'Օգտագործեք հատուկ սիմվոլներ թվեր (# $ ! % & և այլն)',
        noteTextThree:
          'Մի օգտագործեք անձնական տվյալները, ընդհանուր բառեր և բառակապակցությունները',
        linkText: 'Ակտիվացնել երկփուլային ավտորիզացումը',
        linkText2: 'Անջատել երկփուլային ավտորիզացումը',
        confirmButton: 'Հաստատել',
        cancelButton: 'Չեղարկել',
      },
      contactComponent: {
        addContact: 'Ավելացնել կոնտակտ +',
      },
    },

    aboutUsPage: {
      title: 'Էյբիսիդոմեն ընկերության մասին',
      header:
        'Մեր ջանքերն ուղղված են մեր գործընկերների կարիքների և ցանկությունների բավարարմանը',
      paragraphOne:
        'Էյբիսիդոմեն ընկերությունը հիմնադրվել է 2007 թվականին՝ ներառելով Arminco ընկերության դոմենների գրանցման, հոսթինգի և վեբ դիզայնի բաժինների աշխատակիցներին։ Մեր դոմենը <a href=https://internet.am/   target="_blank">internet.am-ն</a> է, որն ընդգծում է Հայաստանում առաջին ինտերնետ պրովայդեր Արմինկո ընկերության ավանդույթների շարունակականությունը։',
      paragraphTwo:
        'Էյբիսիդոմեն ընկերությունը հավատարմագրված է Հայաստանի Ինտերնետ Հանրության ISOC.AM-ի կողմից որպես .am և հայ գոտիներում դոմենների անունների գրանցող: Ընկերությունը անցել է Հայաստանում սեփական տվյալների կենտրոնի ստեղծումից մինչև Եվրոպայում ամպային լուծումների առաջատար մատակարարների հետ համագործակցությունը, նոր ծառայություններ մատուցելու և աշխարհի դոմենային գոտիների մեծ մասում դոմենների գրանցմանը՝ <a href=https://internet.am/am/domain-registration target="_blank">մանրամասն դոմենի գրանցման մասին։</a>',
      paragraphThree:
        'Ընկերությունը տեղական ինտերնետ ռեգիստր է (LIR), գրանցված RIPE-ում (Resource IP European): Կիբերանվտանգության լուծումները տրամադրում է հենց ընկերությունը, որը հանդես է գալիս որպես համակարգչային արտակարգ իրավիճակների արձագանքման թիմ (CERT) .am և ․հայ գոտիներում գրանցված բոլոր դոմենների համար։',
      paragraphFour:
        'Տեխնոլոգիաների զարգացումը և առցանց օգտատերերի ներգրավվածության մշտական աճը թույլ տվեցին ընկերությանը 2020 թվականից բարձր արդյունավետությամբ անցնել առցանց օգտատերերի ծառայություններին՝ թույլ տալով օգտվողներին խնայել ժամանակը և չայցելել ընկերության գրասենյակ:',
      paragraphFive:
        'Ընկերության կարևոր գործունեությունը վեբ կոնստրուկտորի միջոցով կայքերի ստեղծմանն ապահովելն է, ինչը թույլ է տալիս փոքր և միջին ընկերություններին ինքնուրույն ստեղծել իրենց կայքերը՝ <a href=https://internet.am/am/constructor-packages   target="_blank">մանրամսն կոնսրուկտորի մասին</a>։',
      paragraphSix:
        'Առաջադեմ օգտատերերի համար ընկերությունը տրամադրում է վիրտուալ մասնավոր սերվերի ծառայություններ՝ VPS, <a href=https://internet.am/am/vps-packages  target="_blank">մանրամասն VPS-ի մասին,</a>  ինչը թույլ է տալիս ինքնուրույն կառավարել ձեր սերվերը սեփական օրիգինալ ծրագրաշարով։',
      paragraphSeven:
        'Մեր ընկերության հետ աշխատող օգտատերերը հնարավորություն ունեն տեղադրել իրենց կայքը՝ <a href=https://internet.am/am/hosting-packages  target="_blank"> մանրամսն հոսթինգի մասին,</a> ինչը նրանց թույլ է տալիս իրականացնել աշխատանքների ողջ շրջանակը՝ դոմենի գրանցում, վեբ կայքի ստեղծում, վեբ հոստինգ, SSL սերտիֆիկատի տեղադրում մեկ պատուհանից: SSL սերտիֆիկատի տեղադրումն այսօր ժամանակակից աշխարհում կայքի արդյունավետ գործունեության ամենակարեւոր բաղադրիչն է,<a href=https://internet.am/ssl-packages target="_blank">մանրամասն ssl-ի մասին:</a> ',
      paragraphEight:
        'Ինտերնետի զարգացումը նշանակում է նոր մարտահրավերների առաջացում, մենք ուշադիր հետևում ենք բոլոր նոր միտումներին և համապատասխանաբար արձագանքում դրանց։ Այսօր բոլոր զարգացած երկրները միջոցներ են ձեռնարկում անձնական տվյալների պաշտպանությունն ապահովելու և գաղտնիությունը ապահովելու համար։ Էյբիսիդոմենը իր հերթին հետևում է Տվյալների պաշտպանության ընդհանուր կանոնակարգի GDPR կանոններին՝ միաժամանակ հնարավորություն տալով շահագրգիռ կողմերին շփվել տիրույթի սեփականատիրոջ հետ .am և .հայ գոտիներում,  տե՛ս <a href=https://internet.am/blog/domain-holder target="_blank">ով է դոմենի իրավատերը։</a>',
      welcome:
        'Բարի գալուստ Էյբիսիդոմեն կայք <a href=https://internet.am/   target="_blank">internet.am!</a>',
      phone: 'Մեր հեռախոսը․ +376027002',
      email: 'Մեր էլեկտրոնային փոստի հասցեն․ info@interent.am',
      rigthsForm:
        'FORM OF CHARTER OF RIGHTS AND OBLIGATIONS OF REGISTRATION OWNERS',
    },
    specialTermsPage: {
      title: 'Հատուկ պայմաններ և դրույթներ',

      content:
        'Էյբիսիդոմեն ընկերությունը ծառայություններ է մատուցում օֆերտա պայմանագրերի հիման վրա: Յուրաքանչյուր ծառայության համար պայմանագրի մանրամասները ներկայացված են pdf ձևաչափով: Բոլոր պայմանագրերը պարունակում են հայերեն, ռուսերեն և անգլերեն տեքստեր, հայերեն տեքստը իրավաբանորեն նշանակալի է։ Անգլերեն և ռուսերեն թարգմանությունները միայն տեղեկատվական նպատակ ունեն:<br/><br/>' +
        'Մեր հիմնական ծառայություններին զուգահեռ, ինչպիսիք են<br/><br/>' +
        'գնել դոմեն  <a href="https://internet.am/domain-registration" target="_blank"> https://internet.am/domain-registration</a><br/><br/>' +
        'դոմենի  հոստինգ <a href="https://internet.am/hosting-packages" target="_blank"> https://internet.am/hosting-packages</a><br/><br/>' +
        'SSL սերտիֆիկատի տրամադրում <a href="https://internet.am/ssl-packages" target="_blank">https://internet.am/ssl-packages</a><br/><br/>' +
        'կայքերի մշակում՝ օգտագործելով վեբ կայքի կոնստրուկտոր <a href="https://internet.am/constructor-packages" target="_blank">https://internet.am/constructor-packages</a> <br/><br/>' +
        'VPS VDS հատուկ սերվերի ծառայությունների տրամադրում <a href="https://internet.am/vps-packages" target="_blank"> https://internet.am/vps-packages</a><br/><br/>' +
        'Մեր առաջարկների մեջ կարող եք գտնել ձեր սերվերների հոսթինգի ծառայություններ ABCDomin ընկերության տվյալների կենտրոնում՝ ստեղծելով ձեր հատուկ փոստային սերվերը Հայաստանի տարածքում: Կարևոր է նշել, որ մենք տեղակայում ենք ենք ոչ միայն .am տիրույթում գործող սերվերներ, այլև ցանկացած դոմենային գոտու սերվերներ:<br/><br/>' +
        'EV տեսակի SSL սերտիֆիկատ պատվիրելիս անհրաժեշտ  է հաշվի առնել, որ ընդլայնված սերտիֆիկացումը  բավականին բարդ ընթացակարգ է, որը կարող է ավելի շատ ժամանակ պահանջել սովորական SSL սերտիֆիկատի համեմատ: SSL սերտիֆիկատի  տեղադրումը պահանջում է որոշակի գիտելիքներ ձեր կայքի մշակողից:<br/><br/>' +
        'Այցեքարտ-կայք մշակելիս  կայքի ադմինը անհրաժեշտություն չունի մանրամասն ուսումնասիրել կայքի կոնստրուկտորի  հետ աշխատելու բոլոր նրբությունները, սակայն բարդ կայքեր, օրինակ՝ էլեկտրոնային խանութ ստեղծելիս անհրաժեշտ է ավելի մանրակրկիտ մոտեցում: Կարևոր է նշել, որ կայքի կոնստրուկտորից օգտվելիս դուք ստանում եք անվճար SSL սերտիֆիկատ:<br/><br/>' +
        'IDN-ի միջազգայնացված դոմենային անունը ․հայ օգտագործելը թույլ կտա հինգ անգամ խնայել դրա գրանցման ծախսերը։<br/><br/>' +
        'Հնարավորություն կունենաք ավելի բարձր դիրքերում լինել Google որոնողական համակարգում, երբ ձեր օգտատերերը հայերեն հարցեր են տալիս։ Բացի այդ, .հայ դոմենի օգտագործումը թույլ է տալիս հայ օգտատերերին ներկայացնել կազմակերպության անվանումը ճիշտ գրությաբ։<br/><br/>' +
        'Էյբիսիդոմեն ընկերության տվյալների կենտրոնում ձեր սերվերների հոսթինգի ծառայությունը տրամադրվում է հաճախորդի հետ տեղաբաշխման պայմանները համաձայնեցնելուց հետո՝ հստակեցնելով սերվերների չափերը, էներգիայի սպառումը և ինտերնետ կապի պահանջվող արագությունը:<br/><br/>' +
        'Էյբիսիդոմեն ընկերությունը ընդունում է նաև անհատական ​​պատվերներ, ինչպիսիք են՝ կայքի համար հատուկ IP հասցե տրամադրելը, հատուկ էլ․փոստի սերվերի մշակումը և տեղադրումը, որը հաղորդագրություններ է ուղարկում հաճախորդների ընկերության օգտատերերին և այլ անհատական ​​նախագծեր: Նման կարիքների դեպքում ձեզ հետ կկազմվի անհատական ​​պայմանագիր։',

      coLocation: 'Co-location ծառայություն',
      dedicatedServer: 'Dedicated Server ծառայություն',
      mailDelivery: 'Mail delivery ծառայություն',
      mail: 'Mail ծառայություն',
      dns: 'DNS',
      domains: 'Դոմեններ',
      hosting: 'Հոսթինգ',
      ssl: 'SSL',
    },
    contactUsPage: {
      title: 'Կապ',
      header: 'Մենք պատրաստ ենք և սպասում ենք Ձեր հարցերին',
      tabs: {
        sales: 'Վաճառք',
        salesDescription:
          'Վաճառքի բաժնի հետ կապ  հաստատելու համար կարող եք զանգահարել աշխատանքային ժամերին ստորև նշված համարով կամ դիմել  24/7 առցանց չատին:',
        info: 'Հեռ՝ +374 60 270027',
        mail: 'info@internet.am',
        href: 'mailto: info@internet.am',
        onlineChat: 'Օնլայն չատ',
        chatDescription:
          'Օգտվեք մեր օնլայն չատից։ Մեր մասնագետները կօգնեն Ձեզ օրվա ցանկացած ժամի։',
        techSupport: 'Տեխնիկական սպասարկում',
        techDescription:
          'Մեր տեխնիկական սպասարկման մասնագետները լավագույններից են շուկայում: Ձեր բոլոր հարցերին ամենակարճ ժամկետում Դուք կստանաք որակյալ պատասխաններ:',
        support: 'Բացել աջակցության տոմս',
        hq: 'Տեղեկություն',
        hqDescription: '',
      },
    },
    csrModal: {
      uploadCsr: 'Վերբեռնել CSR',
      generateCsr: 'Գեներացնել CSR',
      downloadKey: 'Ներբեռնել key',
      title: 'Ստեղծել սերտիֆիկատի ստորագրման հարցում',
      n1: 'Երկրի կոդ',
      n2: 'Նահանգ/Մարզ',
      n3: 'Քաղաք',
      n4: 'Ընկերության անվանումը',
      n5: 'Իրավաբանական տեսակը',
      n6: 'Դոմեն',
      n7: 'Էլ․ հասցե',
      button: 'Գեներացնել',
      generateCsrDisclaimer:
        'Ուշադրություն՝ պարտադիր պահպանեք private key -ը, առանց դրա հնարավոր չի լինի սերտիֆիկատի վերականանգումը։ Հաստատել կոճակը կակտիվանա, երբ բեռնեք Private key-ը',
      generateCsrDisclaimer2:
        'Ուշադրություն՝ պարտադիր պահպանեք private key -ը, առանց դրա հնարավոր չի լինի սերտիֆիկատի վերականանգումը։',
      downloadCsr: 'Բեռնել CSR-ը',
      downloadPrivateKey: 'Բեռնել Private key-ն',
      copyPasteCsrHere: 'Տեղադրեք ձեր սեփական CSR-ը այստեղ',
      confirmCsr: 'Հաստատել',
      validationType: 'Վալիդացման տարբերակ',
      validationEmail: 'Վալիդացիայի էլ. փոստ',
      fileValidation:
        'Ֆայլով վալիդացիայի ուղեցույցը Ձեզ կուղարկվի աջակցության տոմսի միջոցով',
      onlyUpperCase: 'Երկրի կոդը պետք է լինի մեծատառ',
    },
    uploadCsrSuccessModal: {
      title: 'Շնորհակալություն!',
      header: 'CSR-ը հաստատման փուլում է',
      subHeader:
        'Հաստատումից հետո Դուք կստանաք սերտիֆիկատը և կարգավորումները էլ. փոստով։',
    },
    contactDomainOwnerModal: {
      title: 'Կապ հաստատել դոմենի իրավատիրոջ հետ',
      domainLabel: 'Դոմեն',
      domainPlaceholder: 'example.am',
      nameLabel: 'Ձեր անունը',
      namePlaceholder: 'Պողոս Պողոսյան',
      mailLabel: 'Պատասխանել Email-ին',
      mailPlaceholder: 'example@example.com',
      subjectLabel: 'Վերնագիր',
      subjectPlaceholder: 'Դոմենի վերաբերյալ',
      messageLabel: 'Հաղորդագրություն',
      messagePlaceholder: 'Խնդրում եմ կապ հաստատել',
      submit: 'Ուղարկել',
    },
    contactOwnerSuccesModal: {
      title: 'Շնորհակալություն!',
      header: 'Մենք ուղարկել ենք ձեր հաղորդագրությունը դոմենի իրավատիրոջը',
      subHeader: 'Հուսով ենք, որ շուտով լավ նորություններ կստանաք',
    },
    domainsPage: {
      title: 'Դոմենի որոնում և գրանցում',
      header: 'Մուտքագրեք դոմենը',
      searchButton: 'Որոնում',
      choosedHeader: 'Ընտրված',
      totalText: 'Ընդհանուր`',
      priceText: '/Տարեկան',
      orderButton: 'Պատվիրել հիմա',
      isAdded: 'Ավելացվել է',
      add: 'Ավելացնել',
      showMore: 'Տեսնել ավելին +',
      showed: 'Ցուցադրված է',
      mobileChoosen: 'Ընտրված',
      whois: 'Whois',
      showUnavialables: 'Ցուցադրել զբաղվածները',
      hideUnavialables: 'Թաքցնել զբաղվածները',
      unavailable: 'Զբաղված է',
      content:
        'Դոմենի գրանցում - ընդհանուր տեղեկություն<br/><br/>' +
        'Դոմենների անունների համակարգը սկսվել է 1984 թվականին, երբ առաջին անունների սերվերը տեղադրվեց Վիսկոնսինի համալսարանում: 1985 թվականին սկսեցին գործել .com, .org, .net դոմենները։ Անվան սերվերի գործառույթը բավականին պարզ է՝ դոմենի անունը կապել ինտերնետին միացված համակարգչի IP հասցեի հետ (նիշերի համակցություն, հեռախոսահամարի նիշերի պես): Բրաուզերի միջոցով կայքի անունը գրելուց  հետո ձեր համակարգիչը սկզբում հայտնաբերում է կայքի IP հասցեն անունների սերվերից, այնուհետ կապ է հաստատում ստացված IP հասցեի միջոցով:<br/><br/>' +
        'Ինժեներներն ու գիտնականները, ովքեր ստեղծել են ինտերնետը, դոմենային գոտիները բաժանել են երկու մեծ խմբի՝ ccTLD (երկրի կոդի բարձրագույն մակարդակի դոմեն) և ընդհանուր դոմեններ gTLD -( ընդհանուր վերին մակարդակի դոմեն): Բոլոր տվյալները, որոնք կապում են ccTLD-ների և gTLD-ների դրանց IP հասցեների հետ պահվում են IANA տվյալների բազայում, որը կառավարվում է ԱՄՆ Կալիֆորնիա նահանգում գրանցված ICANN կորպորացիայի կողմից:<br/><br/>' +
        'Տեխնիկապես այս տվյալների բազա մուտքն իրականացվում է 13 սուպերհամակարգիչների միջոցով, որոնք մինչև վերջերս պատասխանում էին ինտերնետի բոլոր օգտատերերի հարցումներին, թե որ IP հասցեն է համապատասխանում պահանջվող դոմենին: Այսօր այս 13 սուպերհամակարգիչների բազմաթիվ հայելիներ են ստեղծվել, օգտատերը մուտք է գործում մոտակա հայելին՝ արագացնելով արձագանքը։ Հայաստանում հայելային սերվերներ են տեղադրված Հայաստանի ինտերնետ հանրությունում (www.isoc.am) և ՄՏՍ-Հայաստան ընկերությունում։<br/><br/>' +
        'Ձեր դոմենի գրանցման համար դոմենային գոտի ընտրելիս պետք է հաշվի առնել, որ gTLD-ի դեպքում ձեր իրավունքների և պարտականությունների կարգավորումն իրականացվում է ICANN-ի կողմից մշակված կանոնների և պահանջների համաձայն, որոնք կարող են լրացվել  այն ընկերության կողմից,  որը ձեռք է բերել այս դոմենի կառավարման իրավունքը ICANN-ից:<br/><br/>' +
        'Եթե դուք ընտրում եք դոմեն ccTLD գոտում, ապա ձեր իրավունքների և պարտավորությունների կարգավորումը կիրականացվի դոմենը կառավարելու իրավունք ստացած կազմակերպության քաղաքականության համաձայն: Հայաստանում քաղաքականությունը հասանելի է հղումով  <a href="https://www.am/policy/am/Policy_AM.pdf" target="_blank">https://www.am/policy/am/Policy_AM.pdf</a>:<br/><br/>' +
        'Հարկ է հաշվի առնել, որ ccTLD-ը չի պատկանում այս երկրին։ Ինտերնետ մշակողները վերցրել են երկու տառի համադրության կոդը ISO տվյալների բազայից, Հայաստանի համար դա .am է։ Տարբեր երկրներում դոմենային անունների գրանցման և օգտագործման քաղաքականությունը կարող է արմատապես տարբերվել։ Նախքան դոմեն գրանցելը՝ նպատակահարմար է ծանոթանալ տվյալ դոմենային  գոտու քաղաքականությանը։<br/><br/>' +
        '2012 թվականին ICANN-ը սկսեց նոր gTLD-ների ստեղծման գործընթացը, որոնց թիվն այժմ հասել է 2000-ի:<br/><br/>' +
        'Միաժամանակ սկսվեց նոր դոմենային գոտիների ստեղծման գործընթացն ինչպես ccTLD, այնպես էլ gTLD ազգային լեզուներով IDN (Internationalized Domain Name): Հայաստանում  երկար քննարկումների արդյունքում՝ 2014 թվականին գործարկվեց  ․հայ դոմենային գոտին։  Նկատի ունեցեք, որ IDN-ի օգտատերերի կարգավորման սկզբունքները չեն տարբերվում լատինատառ դոմենների  սկզբունքներից:',
    },
    constructorPage: {
      title: 'Կայքի կոնստրուկտոր',
      subTitle: 'Լավագույն կոնստրուկտորները Ձեզ համար',
      headerTitle: 'Պատրաստի՛ր քո սեփական կայքը',
      headerButton: 'Սկսել հիմա',
      headerText:
        'Սկսեք կառուցել Ձեր բիզնեսը մեր կոնստրուկտորի հետ՝ պարզ և արագ',
      videoTitle1: 'Պարզ ուղեցույց',
      videoTitle2: 'Կայքի կոնստրուկտորով',
      videoTitle3: 'կայք պատրաստելու համար',
      videoSubTitle1: '1. Ներմուծեք ձեր հավանած կայքը',
      videoSubTitle2: '2. Կատարեք անհրաժեշտ փոփոխությունները',
      videoSubTitle3: '3. Հրապարակեք',
      videoButton: 'Սկսել հիմա',
      advantages: {
        title: 'Առավելություններ',
        n1: 'Արագ',
        n2: 'Մատչելի',
        n3: 'Գեղեցիկ',
        n4: 'SEO/SSM օպտիմիզացված',
        n5: 'Օպտիմիզացված տարբեր սարքերի համար',
      },
      packagesTitle: 'Փաթեթներ',
      compareTitle: 'Համեմատենք կայքն ու կոնստրուկտորը',
      compareRentSite: {
        title: 'Պատվիրել կայք',
        n1: 'Թանկ',
        n2: 'Շատ ժամանակատար',
        n3: 'Տեխնիկական խնդիրներով',
        n4: 'Թարմացնելու գլխացավանք',
        n5: 'Դժվար օպտիմիզացում բոլոր սարքերի համար',
        n6: 'Դժվար օպտիմիզացում բոլոր սոց. կայքերի և որոնման համակարգերի համար',
        n7: 'Անսահմանափակ ֆունկցիոնալ',
      },
      compareBuyConstructor: {
        title: 'Գնել կոնստրուկտոր',
        n1: 'Մատչելի',
        n2: 'Արագ',
        n3: 'Առանց տեխնիկական խնդիրների',
        n4: 'Թարմացումներն արվում են ձեր կողմից',
        n5: 'Հեշտ օպտիմիզացում բոլոր սարքերի համար',
        n6: 'Հեշտ օպտիմիզացում բոլոր սոց. կայքերի և որոնման համակարգերի համար',
        n7: 'Սահմանափակ ֆունկցիոնալ',
      },
      HaveQuestionTitle: 'Մնացի՞ն հարցեր',
      HaveQuestionSubTitle: 'Դիմեք օնլայն չատին',
    },
    constructorComponent: {
      area: 'Տրամադրվող տարածք',
      pages: 'Էջերի քանակ',
      mails: 'Էլ. հասցեներ',
      ssl: 'SSL սերտիֆիկատ',
      orderNow: 'Պատվիրել հիմա',
    },
    hostingPage: {
      title: 'Հոսթինգ փաթեթներ',
      sTitle: 'Լավագույն փաթեթները Ձեզ համար',
      vServers: 'Վիրտուալ սերվերներ',
      lHosting: 'Linux հոսթինգ',
      orderNow: 'Պատվիրել հիմա',
      advatnages: 'Առավելություններ բոլոր փաթեթներում',
      mails: 'Էլ. հասցեներ',
      capacity: 'Թողունակություն',
      unlimited: 'Անսահմանափակ',
      db: 'Տվյալների բազա',
      space: 'Տրամադրվող տարածք',
      subdomain: 'Subdomain',
      addon: 'addon',
      alias: 'alias',
      createSite: 'Պատրաստի՛ր կայքդ',
      createSiteBold: 'ինքնուրույն',
      useConstructor: 'Պատրաստիր կայքդ մեր կոնստրուկտորով արագ և գեղեցիկ',
      helpHeader: 'Հազարավոր կազմակերպություններ արդեն ընտրել են ABCDomain',
      liveChat:
        'Չե՞ք կողմնորոշվում փաթեթի ընտրության հարցում։ Խորհրդակցեք մասնագետի հետ օնլայն չաթի միջոցով։',
      orderNowText: 'Եթե արդեն ընտրել եք փաթեթը, պատվիրեք հիմա',
      users: '10000+ օգտատեր',
      usersText: 'ընտրել է մեր ծառայությունները',
      cms: 'Ամենատարածված CMS-ների օգտագործում',
      abcAvd: 'Աշխատեք մեզ հետ',
      hostingAdv1: 'PHP',
      hostingAdv2: 'Անսահմանափակ տրաֆֆիկ',
      hostingAdv3: 'Կառավարել հոսթինգը Cpanel-ի միջոցով',
      hostingAdv4: 'DNS',
      hostingAdv5: 'Հասանելիություն FTP-ի միջոցով',
      hostingAdv6: 'Սերվեր OS - CentOS',
      hostingAdv7: 'MySQL',
      hostingAdv8: 'Շաբաթական backup',
      adv1: 'Բարենպաստ պայմաններ` առանց թաքնված վճարների',
      adv2: 'Տեխնիկական աջակցություն հայտի կամ 24/7 չաթի միջոցով',
      adv3: 'Ծառայությունների կառավարման միասնական կենտրոն',
      adv4: 'Հայտարարված ռեսուրսների երաշխավորված ապահովում',
      adv5: 'Հզորության ակնթարթային ուժեղացման հնարավորություն',
      adv6: 'Արդյունավետ միջոցներով սպամի զտում',
      adv7: 'Զեղչեր internet.am գործընկերների համար',
      adv8: 'Ընդլայնված պաշտպանություն սպամից',
      contacUsHeader: 'Մնացի՞ն հարցեր',
      contacUsSubHeader: 'Դիմեք օնլայն չատին',
    },
    vpsPage: {
      vServers: 'VPS Linux համակարով',
      sTitle: 'Ubuntu, Debian, Fedora և այլն',
      sLocation: 'Սերվերների տեղակայում',
      serverType: 'Սերվերի OS',
      serverSize: 'Սերվերի չափ',
      hostnameLabel: 'Սերվերի անվանում',
      hostnamePlaceholder: 'Սերվերի IP',
      labelPlaceholder: 'Սերվերի անվանում',
      aditionalFeatures: 'Լրացուցիչ կարգավորումներ',
      enable: 'Ակտիվացնել ',
      backup: 'Auto Backup',
      forward: 'Առաջ',
      serverQ: 'Սերվերների քանակ',
      location: 'Տեղակայում',
      type: 'OS',
      size: 'Չափ',
      price: 'Գումար',
      registerNow: 'Առաջ',
    },
    sslPage: {
      title: 'SSL Սերտիֆիկատներ ',
      domainValidation: 'Դոմենի ստուգում',
      companyValidation: 'Կազմակերպության ստուգում',
      extendedValidation: 'Ընդլայնված ստուգում',
      orderNow: 'Պատվիրել հիմա',
    },
    supportPage: {
      title: 'Գիտելիքների բազա',
      placeholderText: 'Ձեր հարցը',
      search: 'Որոնել',
      knownSection: 'Հայտնիները',
      categories: 'Ըստ բաժինների',
      searchResults: 'Որոնման արդյունքներ',
      noSearchResults: 'Արդյունքներ չգտնվեցին',
    },
    cart: {
      domain: 'Դոմեն',
      hosting: 'Հոսթինգ',
      vps: 'VPS',
      ssl: 'SSL',
      constructor: 'Կոնստրուկտոր',
      total: 'Ընդհանուր`',
      orderNow: 'Պատվիրել հիմա',
      emptyCart: 'Ձեր զամբյուղը դատարկ է',
      productInfoIncomplete: 'Խնդրում ենք մուտքագրել դոմենը',
      modalDeleteBtn: 'Հեռացնել',
      modalDeleteSubtitle: 'Իսկապե՞ս ուզում եք հեռացնել',
    },
    aboutMobileAppBlock: {
      title: 'Ներբեռնիր մեր',
      titleBold: 'նոր հավելվածը',
      list: [
        'Դոմենների որոնում և գրանցում',
        'Ծառայությունների վճարում բանկային քարտերի և PayPal-ի միջոցով',
        'Աջակցության տոմսերի կառավարում',
        'Ծանուցումների ստացում',
      ],
    },
    searchAreaBlock: {
      title: 'Ավելի քան 20 դոմենային տիրույթ',
      search: 'Որոնել',
      info: 'Ցուցադրել բոլոր հնարավոր տիրույթները',
    },
    hostingInfoBlock: {
      title: 'Shared Linux Hosting փաթեթներ',
      packageInfo:
        'Ամենատարբեր հոսթինգ փաթեթներ՝ Shared Linux Hosting և վիրտուալ սերվերների լայն տեսականի',
      more: 'ավելին',
    },
    hostingPackageBlock: {
      buy: 'Գնել',
    },
    servicesBlock: {
      title:
        'Shared Hosting(Linux), վիրտուալ սերվերներ, կայքի կոնստրուկտորներ, անվտանգության',
      description:
        'Ամենատարբեր հոսթինգ փաթեթներ` Shared Hosting(Linux), վիրտուալ սերվերներ, կայքի կոնստրուկտորներ, անվտանգության սերտիֆիկատներ և այլն',
      more: 'Ավելին',
      hosting: 'Հոսթինգ',
      hostingDescription:
        'Ժամանակակից Linux հոսթինգ ծառայություն, որը թույլ է տալիս տեղակայել ամենատարբեր և ժամանակակից կայքերը՝ պատրաստած հայտնի գործիքակազմով, ինչպիսիք են Wordpress, Joomla, Laravel, ընձեռում է PHP տարբերակների ընտրություն, MariaDB/MySQL և այլն։',
      ssl: 'SSL սերտիֆիկատներ',
      sslDescription:
        'Ամենատարբեր SSL սերտիֆիկատները լավագույն մատակարարների կողմից։ Դոմենի, կազմակերպության և ընդլայնված ստուգումով, ինչպես նաև Wildcard սերտիֆիկատներ լավագույն գներով',
      constructor: 'Կայքի կոնստրուկտոր',
      constructorDescription:
        'Անհրաժե՞շտ է ներկայացուցչական կայք։ Անհրաժե՞շտ է շտապ։ Կարիք չկա անհանգստանալու, մեր կայքի կոնստրուկտորը ձեզ կօգնի պատրաստել գեղեցիկ, որակյալ և բջջային հեռախոսների համար օպտիմիզացված կայք մի քանի ժամվա ընթացքում',
      cloudVPS: 'Cloud VPS',
      cloudVPSDescription:
        'Առաջարկում ենք VPS սերվերների լայն տեսականի։ Մենք ունենք ցանկացած չափի և հզորության VPS սերվերներ, որոնց միջոցով կարող եք տեղակայել մեծ պլատֆորմաներ, API-ներ կամ այլ համակարգեր',
    },
    whyChooseUsBlock: {
      title: 'Ինչու՞ մենք',
      key1: 'բազմամյա փորձ',
      value1: 'No 1',
      key2: 'պրոֆեսիոնալ թիմ',
      value2: '3.3',
      key3: '24/7 սպասարկում',
      value3: '111 հզր',
      key4: 'Դոմենների շուկայում',
      value4: '24/7',
    },
    customerOpinionBlock: {
      title: 'Հաճախորդների կարծիքները',
    },
    homePageHosting: {
      month: 'ամիս',
    },
    resetPasswordPage: {
      title: 'Գաղտնաբառի վերականգնում',
      infoText: 'Մուտքագրեք նոր գաղտնաբառը',
      password: 'Գաղտնաբառ',
      confirmPassword: 'Հաստատել գաղտնաբառը',
      confirmButton: 'Հաստատել',
      failText: 'Ձեր գաղտնաբառի վերականգնման հղումը սխալ է կամ սպառվել է',
      tryAgainButton: 'Կրկին վերականգնել',
    },
    confirmEmailPage: {
      failText: 'Ձեր գրանցման հղումը սխալ է կամ սպառվել է',
    },
    registrationPage: {
      title: 'Գրանցում',
      infoText:
        'Ձեռք բերվող ծառայության իրավատիրոջ նույնականացման համար հիմք է հանդիսանում գրանցման ընթացքում մուտք արվող տեղեկատվությունը։ Մուտքագրած սխալ կամ թերի տեղեկատվության համար պատասխանատվություն կրում է բացառապես Պատվիրատուն։',
      infoText2:
        'Գաղտնաբառերի ընտրության ժամանակակից պահանջները. հիշեցում <br/><br/>' +
        'Ժամանակակից գաղտնաբառերի նկատմամբ պահանջները ցանցերում ապահովում են անվտանգության բարձրացում, քանի որ կիբեր սպառնալիքները մշտապես զարգանում են: Ահա գաղտնաբառերի նկատմամբ կիրառվող հիմնական պահանջներ, որոնք այսօր կարևոր են համարվում և խորհուրդ է տրվում օգտագործել ԷյԲիՍիդոմեն ընկերությունում գրանցվելիս։<br/><br/>' +
        'Գաղտնաբառի երկարությունը։ Գաղտնաբառը պետք է լինի երկար, սովորաբար ոչ պակաս, քան 12 նիշից բաղկացած համադրություն: Համապատասխան մեթոդներ կիրառելու դեպքում անգամ՝ երկար գաղտնաբառերը ավելի   դժվար է կռահել, երբ հաքերը տառ առ տառ փոփոխելով փորձում է վերծանել ձեր գաղտնաբառը:<br/><br/>' +
        'Բարդություն։ Գաղտնաբառը պետք է ներառի տարբեր նիշեր, ինչպիսիք են մեծատառ և փոքրատառ տառերը, թվերը և հատուկ նիշերը (օրինակ՝ !, @, #, $, %): Սա ավելի է դժվարացնում հաքերների աշխատանքը: <br/><br/>' +
        'Անսովորություն։ Գաղտնաբառը չպետք է լինի ակնհայտ կամ պարունակի պարզ բառեր, բառարանային բառեր, արտահայտություններ կամ հաջորդական նիշեր (օրինակ՝ «password», «123456», «qwerty»): Ավելի լավ է օգտագործել տառերի, թվերի և նիշերի պատահական համակցություն:<br/><br/>' +
        'Անձնական տվյալներ։ Մի օգտագործեք ձեր անձնական տվյալները գաղտնաբառերում, մասնավորապես՝ անունը, ազգանունը, ծննդյան ամսաթիվը կարող են օգնել հաքերներին ձեր գաղտնաբառը որոնելիս:<br/><br/>' +
        'Եզակիություն։ Յուրաքանչյուր հաշվի համար պետք է օգտագործվի եզակի գաղտնաբառ: Դուք չպետք է օգտագործեք նույն գաղտնաբառը տարբեր հարթակներում առցանց խանութներում և տարբեր կայքերում գրանցվելու համար։<br/><br/>' +
        'Երկփուլային նույնականացում (2FA)։ Հավաստիացեք, որ այն կայքը, որտեղ պատրաստվում եք գրանցվել, ունի 2FA: Սա անվտանգության լրացուցիչ շերտ է, որը պահանջում է մուտքագրել լրացուցիչ ծածկագիր, որն ուղարկվում է ձեր էլ.փոստի հասցեին:<br/><br/>' +
        'Պարբերաբար թարմացրեք։ Գաղտնաբառերը պետք է պարբերաբար փոխվեն, նույնիսկ եթե կասկած չկա, որ ձեր հաշիվը վտանգված է:<br/><br/>' +
        'Օգտագործելով արտահայտություն։ Պարզ գաղտնաբառի փոխարեն կարող եք օգտագործել երկար արտահայտություններ, որոնք հեշտ է հիշել, բայց դժվար է կռահել (օրինակ՝ «7—անգամ—ստուգիր—1 անգամ—կտրիր»):<br/><br/>' +
        'Գաղտնաբառերի կառավարիչներ: Դուք կարող եք օգտագործել գաղտնաբառերի կառավարիչներ, որոնք ստեղծում և պահում են եզակի և բարդ գաղտնաբառեր տարբեր հաշիվների համար:<br/><br/>' +
        'Տարբեր լեզուներ։ Եթե ​​դուք տիրապետում եք հպումով մուտքագրելուն և օգտագործում եք երկու լեզու, կարող եք նույն արտահայտությունը մուտքագրել երկրորդ լեզվով՝ օգտագործելով առաջին լեզվի տառերը:<br/><br/>' +
        'Գործունեության մոնիթորինգ։ Ստուգեք ձեր հաշիվները կասկածելի գործունեության համար և անմիջապես արձագանքեք:<br/><br/>' +
        'Խորհուրդ։ Սույն բովանդակությունը որպես հիշեցում է կիբերանվտանգության և անվտանգ գաղտնաբառերի կարևորության մասին:<br/><br/>' +
        'Մի մոռացեք հետևել կիբերանվտանգության և կիբերհիգիենայի ոլորտի նորություններին:<br/><br/>' +
        'Այս պահանջներին համապատասխանելը կօգնի ամրապնդել ձեր հաշիվների անվտանգությունը և կանխել ձեր հաշվի հնարավոր չարտոնված մուտքը:',
      readMoreBtn: 'Կարդալ ավելին',
      readLessBtn: 'Թաքցնել',
      text: 'Անհրաժեշտ է լրացնել միայն',
      textBold: 'լատինատառ',
      individual: 'Ֆիզ. անձ',
      entity: 'Իրավ. անձ',
      firstname: 'Անուն',
      lastname: 'Ազգանուն',
      passport: 'Անձնագիր',
      companyName: 'Ընկերության անվանումը',
      companyType: 'Իրավաբանական տեսակը',
      taxCode: 'ՀՎՀՀ',
      tel: 'Հեռախոսահամար',
      phonenumberPlaceholder: 'Մուտքագրեք հեռախոսահամարը',
      email: 'Էլ. հասցե',
      password: 'Գաղտնաբառ',
      confirmPassword: 'Հաստատել գաղտնաբառը',
      country: 'Երկիր',
      state: 'Մարզ/նահանգ',
      city: 'Քաղաք',
      address: 'Հասցե',
      postalIndex: 'Փոստային ինդեքս',
      terms:
        'Գրանցվելով www.internet.am կայքում`  հաստատում եմ, որ կարդացել և ամբողջությամբ ընդունում եմ կայքում հրապարակված Ընդհանուր Դրույթներ , Պայմանները (ներառյալ Հատուկ Պայմանները) և Գնացուցակը։',
      regButton: 'Գրանցվել',
      autoPay:
        'Տալիս եմ համաձայնությունս տվյալ ըքաունթում ներկայացված հաշիվների դիմաց գումարի ԱՎՏՈՄԱՏ գանձման համար:',
      imAgree:
        'Տալիս եմ համաձայնությունս իմ անձնական տվյալների մշակման վերաբերյալ ',
      autopayment: 'Ավտոմատ երկարաձգում',
      autopayButton: 'Ես համաձայն եմ',
      reCaptchaError: 'Սխալ reCaptcha',
    },
    domainSettings: {
      title: 'Դոմենի կարգավորում',
      header: 'Խնդրում ենք ստուգել ընտրված դոմենը',
      period: 'Գրանցման ժամկետ',
      years: 'Տարի',
      incognito: 'Ինկոգնիտո',
      caution:
        'Պաշտպանեք Ձեր անձնական տվյալները և նվազեցրեք սպամի քանակը, միացրեք տվյալների պաշտպանությունը:',
      addHosting: 'Ավելացնել հոսթինգ',
      hostingIsAdded: 'Հոսթինգը ավելացված է',
      nameServers: 'Կարգավորել Name-սերվերները',
      warning:
        'Կարող եք ստուգել ` արդյոք Ձեր ընտրած դոմենը համընկնում է ՀՀ-ում գրանցված որևէ ապրանքային նշանին հետևյալ ',
      warningLink: 'հղումով:',
      next: 'Հաջորդը',
    },
    orderDetailsPage: {
      title: 'Վճարել',
      login: 'Մուտք գործել',
      register: 'Գրանցվել',
      domainHeader: 'Իրավատիրոջ մասին տեղեկատվություն',
      domainNote:
        'Դոմենն այլ անձի կամ ընկերության փոխարեն գրանցելու դեպքում կարող եք ավելացնել կոնտակտ անձնական էջում և դոմենը գրանցելիս ընտրել նշված կոնտակտը: Եթե դրա կարիքը չկա, անտեսեք այս բաժինը:',
      current: 'Ընթացիկ',
      newContact: 'Ավելացնել նոր կոնտակտ',
      selectContact: 'Ընտրել կոնտակտը',
      domainTitle: 'Դոմեն',
      hostingTitle: 'Հոսթինգ',
      constructorTitle: 'Կոնստրուկտոր',
      sslTitle: 'SSL',
      vpsTitle: 'VPS',
      vpsQuantity: 'Քանակը',
      addNotes: 'Հավելյալ նշումներ',
      placeholder: 'Կարող եք նշել հավելյալ տեղեկատվություն',
      total: 'Ընդհանուր`',
      paymentDate: 'Վճարման ենթակա է',
      payment: 'Վճարել',
      caution:
        'Պատվերում նշված բոլոր տվյալները փոխանցվում են կոդավորված ձևով, ելելով անվտանգության նկատառումներից և խարդախոևթյունների կանխարգելման անհրաժեշտությունից: Ձեր Ipհասցեն…. ??? (37.252.81.188) կպահպանվի:',
      productInfoIncomplete: 'Խնդրում ենք մուտքագրել դոմենը',
      productInfoMissing: 'Խնդրում ենք մուտքագրել տվյալները',
      promocode: 'Պրոմոկոդ',
      promocodeInput: 'Մուտքագրեք կոդը',
      selectTime: {
        n1: 'Ամսական',
        n2: 'Եռամսյակային',
        n3: 'Կիսամյակային',
        n4: 'Տարեկան',
      },
    },
    productDetails: {
      title: 'Պրոդուկտի մասնրամասներ',
      header: 'Մուտքագրեք Ձեր հոսթինգի հասցեն',
      placeholder: 'Ընտրել...',
      nextButton: 'Հաջորդը',
    },
    nameServerModal: {
      title: 'Name servers',
      header:
        'Եթե ուզում եք օգտագործել ձեր նեյմսերվերները, մուտքագրեք դրանք համապատասխան բաժնում: Հոսթինգի համար լռելայն օգտագործվում են մեր նեյմսերվերները:',
      submit: 'Պահպանել',
      n1: 'Name server 1*',
      n2: 'Name server 2*',
      n3: 'Name server 3',
      n4: 'Name server 4',
      n5: 'Name server 5',
      more: 'Ավելին',
    },

    getCodeModal: {
      title: 'DNS-ի փոփոխությունն այժմ պահանջում է անվտանգության կոդ։',
      subTitle: 'Կոդն ուղարկվել է դոմենի ադմինիստրատիվ դաշտում  նշված մեյլին:',
      submitCode: 'Կիրառել',
    },

    nameServerSuccessModal: {
      n1: 'Շնորհակալություն!',
      n2: 'Nameserver-ները հաջողությամբ տեղադրվեցին',
    },

    glueRecordsModal: {
      title: 'DNS glue records',
      submit: 'Պահպանել',
      n1: 'IP 1',
      n2: 'IP 2',
      n3: 'IP 3',
      n4: 'IP 4',
      n5: 'IP 5',
    },
    glueRecordsSuccessModal: {
      n1: 'Շնորհակալություն!',
      n2: 'Nameserver-ների տվյալները կթարմացվեն 24 ժամվա ընթացքում',
    },
    vpsModal: {
      title: 'VPS կարգավորումներ',
      ip: 'IP',
      username: 'Մուտքանուն',
      password: 'Գաղտնաբառ',
    },

    hostingModal: {
      title: 'Linux հոսթինգ',
      header: 'Լավագույն Linux փաթեթները Ձեզ համար',
      button: 'Ընտրել',
    },
    manageFreeDNSModal: {
      title: 'Կառավարել DNS',
      header: 'DNS գրառումները հաջողությամբ փոփոխված են',
    },
    registrationModal: {
      header: 'Շնորհակալ ենք գրանցման համար',
      subHeader: 'Խնդրում ենք ստուգել Ձեր էլ. փոստը',
      login: 'Խնդրում ենք մուտք գործել համակարգ',
    },
    addContactModal: {
      header: 'Ավելացնել',
      addButton: 'Ավելացնել',
    },
    openTicketPage: {
      title: 'Բացել հայտ',
      name: 'Անուն',
      email: 'Էլ. հասցե',
      subject: 'Վերնագիր',
      subjectPlaceholder: 'Վերնագիր...',
      section: 'Բաժին',
      service: 'Համապատասխան ծառայություն',
      priority: 'Առաջնահերթություն',
      inputFile: 'Ներդիր',
      message: 'Հաղորդագրություն',
      textareaPlaceholder: 'Հաղորդագրություն...',
      attachButton: 'Կցել ֆայլեր',
      sendButton: 'Ուղարկել',
      cancelButton: 'Չեղարկել',
      note: 'Թույլատրելի ֆայլերի տեսակներ: .jpg, .jpeg, .png',
      limit: 'Սահմանաչափ: 2.5 ՄԲ մեկ ֆայլի համար',
      limitError: 'Միայն 1 ֆայլ կարելի է վերբեռնել',
    },

    clientAreaPage: {
      placeholder: 'Գրանցել դոմեն',
      regButton: 'Գրանցել',
      transferButton: 'Տեղափոխել',
      domains: 'Դոմեններ',
      services: 'Ծառայություններ',
      tickets: 'Աջակցության հայտեր',
      invoices: 'Հաշիվներ',
      actionButton: 'Գործողություններ',
      addServiceButton: 'Ավելացնել ծառայություն +',
      hasCredit: 'Հաշվի մնացորդ՝',
      credit: 'բալանսի վրա',
      creditRecharge: 'Լիցքավորել բալանսը',
    },

    domainsTable: {
      domain: 'Դոմեն',
      regData: 'Գրանցման ամսաթիվ',
      expiryDate: 'Վճարված է մինչև',
      status: 'Կարգավիճակ',
    },
    servicesTable: {
      service: 'Ծառայություն',
      serviceRegDate: 'Գրանցման ամսաթիվ',
      serviceBillCycle: 'Վճարման պարբերականություն',
      price: 'Գինը',
      expiryDate: 'Վճարված է մինչև',
      status: 'Կարգավիճակ',
    },
    ticketsTable: {
      id: 'ID',
      section: 'Բաժին',
      header: 'Հայտի վերնագիր',
      lastAnnouncement: 'Հայտի/պատասխանի ամսաթիվ',
      status: 'Կարգավիճակ',
      departments: {
        1: 'Ադմինիստրատիվ',
        2: 'Տեխնիկական',
        3: 'Ֆինանսական',
      },
    },
    invoicesTable: {
      invoice: 'Համար',
      price: 'Գումար',
      date: 'Ներկայացման ամսաթիվ',
      nextPayment: 'Թարմացված',
      status: 'Կարգավիճակ',
    },

    filterPopup: {
      showAll: 'Ցույց տալ բոլորը',
    },

    clientAreaTablesStatuses: {
      open: 'Բաց է',
      close: 'Փակ է',
      cancel: 'Դադարեցված է',
      active: 'Ակտիվ է',
    },

    sectionOptions: {
      adminDep: 'Ադմինիստրատիվ բաժին',
      techDep: 'Տեխնիկական բաժին',
      finDep: 'Ֆինանսական բաժին',
    },
    priorityOptions: {
      low: 'Ցածր',
      medium: 'Միջին',
      high: 'Բարձր',
    },

    viewTicketModal: {
      title: 'Տոմսի տվյալներ',
      message: 'Հաղորդագրություն',
      placeholder: 'Ձեր հաղորդագրությունը',
      reply: 'Պատասխանել',
      close: 'Փակել տոմսը',
      download: 'Ներբեռնել',
    },

    invoicePage: {
      receiver: 'Ստացող`',
      company: '«ԷյԲիՍիԴոմեն» ՍՊԸ',
      taxpayerId: 'ՀՎՀՀ՝ 01561302',
      address: '',
      telephone: 'Հեռախոս՝ +374 60 27 00 27',
      bankDetails: 'Հ/հ` 1570020918560100 «Ամերիաբանկ» ՓԲԸ',
      invoicedTo: 'Հաշվի իրավատեր`',
      invoiceDate: 'Ամսաթիվ:',
      invoiceItems: 'Հաշվի կետեր',
      invoiceDescription: 'Նկարագրություն',
      sum: 'Գումար',
      subTotal: 'Ընդամենը',
      credit: 'Կրեդիտ',
      total: 'Վճարման ենթակա է',
      note: '* Վերջնական վճարումը լինելու է ՀՀ դրամով',
      transactionDate: 'Գործարքի ամսաթիվ',
      gateway: 'Վճարված է',
      transactionId: 'Գործարքի ID',
      amount: 'Գումար',
      responseInfo: 'Գործարքները չգտնվեցին',
      print: 'Տպել',
      hasCredit: 'Դուք ունեք',
      hasCredit2: 'բալանսի վրա',
      applyCredit: 'Կիրառել',
      invoice: 'Հաշիվ',
      creditCard: 'Վճարային քարտ',
      refundedToCard: 'Վերադարձ վճարային քարտին',
      refundedToBalance: 'Վերադարձ հաշվի մնացորդին',
      refundedToIdram: 'Վերադարձ Idram դրամապանակին',
      creditApplied: 'Գանձում մնացորդից',
      download: 'Բեռնել',
      download_EHDM: 'Բեռնել Է - ՀԴՄ-ն',
      open_EHDM: 'Տեսնել Է - ՀԴՄ-ն',
    },

    creditRecharge: {
      title: 'Բալանսի լիցքավորում',
      header: 'Լիցքավորեք բալանսը և կատարեք վճարումներ բալանսից',
      placeholder: 'Գումարը',
      submit: 'Հաստատել',
    },

    settingsPopup: {
      title: 'Դիտել տոմսը',
      viewInvoice: 'Տեսնել հաշիվը',
      enterCpanel: 'Մուտք Cpanel',
      freeDNSManagement: 'Կառավարել DNS-ը',
      csrModal: 'Վերբեռնել CSR',
      vps: 'Կարգավորումներ',
      details: 'Մանրամասները',
      configure: 'Կարգավորել',
    },

    protectIdModal: {
      activate: 'Ակտիվացնելով ինկոգնիտոն ձեր դոմենի տվյալները կդառնան գաղտնի',
      deactivate:
        'Ապաակտիվացնելով ինկոգնիտոն՝ Ձեր դոմենի տվյալները կդառնան հանրային',
      button: 'Շարունակել',
    },

    renewModal: {
      button: 'Երկարացնել դոմենը',
      period: 'Երկարացման ժամկետ',
      promocode: 'Պռոմոկոդ',
      year: 'Տարի',
    },
    domainTableSettingsPopup: {
      renew: 'Երկարաձգել',
      ns: 'Կարգավորել DNS',
      changeDomainContacts: 'Թարմացնել դոմենի կոնտակտները',
      activateProtectId: 'Ակտիվացնել ինկոգնիտո ռեժիմ',
      deactivateProtectId: 'Ապաակտիվացնել ինկոգնիտո ռեժիմ',
      modalDeleteBtn: 'Այո',
      modalDeleteSubtitle: 'Իսկապե՞ս ուզում եք կատարել այս գործողությունը:',
    },

    errors: {
      error: ' ID համարը պարտադիր է և պետք է պարունակի առնվազն 4 նիշ',
      errorTin:
        'Հարկ վճարողի հաշվառման համարը պարտադիր է և պետք է պարունակի առնվազն 3 նիշ',
      errorDob:
        'Ծննդյան տարեթիվը պարտադիր է և պետք է պարունակի YYYY-MM-DD ձևաչափով',
    },

    domainContactModal: {
      title: 'Դոմենային կոնտակտներ',
      header:
        'Եթե դուք ցանկանում եք փոփոխել դոմենային կոնտակտները, ներդրեք համապատասխան դաշտերում',
      tab1: 'Գրանցորդ',
      tab2: 'Տեխնիկական',
      tab3: 'Ադմինիստրատիվ',
      n1: 'Անուն',
      n2: 'Երկիր',
      n3: 'Քաղաք',
      n4: 'Կազմակերպություն',
      n5: 'Էլ․ փոստ',
      n6: 'Հասցե',
      n7: 'Փոստային ինդեքս',
      n8: 'Հեռախոսահամար',
      submit: 'Պահպանել',
    },

    editContactModal: {
      header: 'Խմբագրել',
      saveButton: 'Պահպանել',
    },
    confirmModal: {
      header: 'Համոզված եք?',
      cancelButton: 'Չեղարկել',
    },

    autoChargeModal: {
      header: 'Ավտոմատ գանձում',
      text: ' Այլևս ոչ մի ժամանակի կորուստ: Դուք երբեք չեք մոռանա վճարել ներկայացված հաշիվների դիմաց, քանի որ դրանք գեներացվելուց հետո ինքնաբերաբար կմարվեն Ձեր հաշվից:',
      notnow: 'Ոչ հիմա',
    },

    twoFactorAuthEnablePage: {
      title: 'Սկանավորեք QR կոդը երկփուլային վավերացումը ակտիվացնելու համար',
      submitButton: 'Ես արդեն սկանավորել եմ',
    },

    validationFields: {
      onlyLatin: 'Միայն լատինատառ և (. - @) սիմվոլներ',
      firstname: 'Դաշտը պարտադիր է',
      firstnameMin: 'Դաշտը պետք է պարունակի նվազագույնը 2 սիմվոլ',
      firstnameMax: 'Դաշտը պետք է պարունակի առավելագույնը 72 սիմվոլ',
      lastname: 'Դաշտը պարտադիր է',
      lastnameMin: 'Դաշտը պետք է պարունակի նվազագույնը 2 սիմվոլ',
      lastnameMax: 'Դաշտը պետք է պարունակի առավելագույնը 72 սիմվոլ',
      legalCodeMin: 'Տվյալ դաշտը պետք է պարունակի առնվազն 5 նիշ',
      fullName: 'Ամբողջական անվանումը պարտադիր է',
      fullNameMin: 'Պետք է պարունակի նվազագույնը 6 սիմվոլ',
      fullNameMax: 'Պետք է պարունակի առավելագույնը 72 սիմվոլ',
      eppCode: 'EPP Code պարտադիր է',
      eppMinPhysical: 'EPP Code է պարունակի նվազագույնը 4 սիմվոլ',
      eppMaxPhysical: 'EPP Code է պարունակի առավելագույնը 30 սիմվոլ',
      passport: 'Դաշտը պարտադիր է',
      phonenumber: 'Հեռախոսահամարը պարտադիր է',
      phonenumberMin: 'Հեռախոսահամարը պետք է պարունակի նվազագույնը 9 սիմվոլ',
      phonenumberMax: 'Հեռախոսահամարը պետք է պարունակի առավելագույնը 20 սիմվոլ',
      email: 'Էլ. հասցեն պարտադիր է',
      client: 'Օգտատիրոջ էլ. հասցեն պարտադիր է',

      password:
        'Ոչ պակաս, քան 12 նիշ` առնվազն մեկ մեծատառ, մեկ փոքրատառ, թվեր և սիմվոլներ, (Մի օգտագործեք անձնական տվյալները, ընդհանուր բառեր և բառակապակցությունները)',
      passMin:
        'Ոչ պակաս, քան 12 նիշ` առնվազն մեկ մեծատառ, մեկ փոքրատառ, թվեր և սիմվոլներ, (Մի օգտագործեք անձնական տվյալները, ընդհանուր բառեր և բառակապակցությունները)',
      passMax: 'Գաղտնաբառը պետք է պարունակի առավելագույնը 64 սիմվոլ',
      loginPass: 'Գաղտնաբառը պարտադիր է',
      passMatch: 'Գաղտնաբառերը չեն համընկնում',
      country: 'Երկիրը պարտադիր է',
      countryMax: 'Երկիրը պետք է պարունակի առավելագույնը 2 սիմվոլ',
      locality: 'Գտնվելու վայրը պարտադիր է',
      organizationUnit: 'Կազմակերպության միավորը պարտադիր է',
      domain: 'Մուտքագրել դոմենը',
      commonName: 'Դոմենը պարտադիր է',
      state: 'Մարզը պարտադիր է',
      city: 'Քաղաքը պարտադիր է',
      productId: 'Արտադրանքի Id-ն պարտադիր է',
      organization: 'Կազմակերպության անվանումը պարտադիր է',
      promocodeMin: 'Պրոմոկոդը պետք է պարունակի նվազագույնը 2 սիմվոլ',
      promocodeMax: 'Պրոմոկոդը պետք է պարունակի առավելագույնը 20 սիմվոլ',
      address: 'Հասցեն պարտադիր է',
      postcode: 'Փոստային ինդեքսը պարտադիր է',
      question: 'Լրացրեք դաշտը',
      subject: 'Վերնագիրը պարտադիր է',
      subjectMin: 'Վերնագիրը պետք է պարունակի նվազագույնը 2 սիմվոլ',
      subjectMax: 'Վերնագիրը պետք է պարունակի առավելագույնը 40 սիմվոլ',
      message: 'Հաղորդագրությունը պարտադիր է',
      messageMin: 'Հաղորդագրությունը պետք է պարունակի նվազագույնը 2 սիմվոլ',
      messageMax:
        'Հաղորդագրությունը պետք է պարունակի առավելագույնը 2000 սիմվոլ',
      messageTextareaMax:
        'Հաղորդագրությունը պետք է պարունակի առավելագույնը 500 սիմվոլ',
      section: 'Բաժինը պարտադիր է',
      service: 'Համապատասխան ծառայությունը պարտադիր է',
      priority: 'Առաջնահերթությունը պարտադիր է',
      invalidTyping: 'Սխալ ֆորմատ',
      ns1Required: 'ns1 պարտադիր է',
      ns2Required: 'ns2 պարտադիր է',
      ns1Url: 'ns1 պետք է լինի URL',
      ns2Url: 'ns2 պետք է լինի URL',
      ns3Url: 'ns3 պետք է լինի URL',
      ns4Url: 'ns4 պետք է լինի URL',
      ns5Url: 'ns5 պետք է լինի URL',
      sameDomain: 'Էլ. հասցեն պետք է լինի գրանցված այլ դոմենում',
      priceTime: 'Ժամանակաշրջանը պարտադիր է',
      onlyDigits: 'Թույլատրվում են միայն թվեր',
      required: 'Դաշտը պարտադիր է',
      amountMax8: 'Գումարը պետք է պարունակի առավելագույնը 8 նիշ',
      vpsLabelMin: 'Սերվերի անունը պետք է պարունակի նվազագույնը 4 նիշ',
      vpsLabelMax: 'Սերվերի անունը պետք է պարունակի առավելագույնը 25 սիմվոլ',
      validationType: 'Վալիդացման մեթոդը պարտադիր է',
      firstnamePhysical: 'Անունը պարտադիր է',
      firstnameMinPhysical: 'Անունը պետք է պարունակի նվազագույնը 2 նիշ',
      firstnameMaxPhysical: 'Անունը պետք է պարունակի առավելագույնը 72 սիմվոլ',
      postalAddress: 'Փոստային հասցեն պարտադիր է',
      legalAddress: 'Իրավաբանական հասցեն պարտադիր է',
      dob: 'Ծննդյան տարեթիվը պարտադիր է',
      passportSeries: 'Անձնագրի սերիան պարտադիր է',
      passportIssueDate: 'Անձնագրի տրամադրման տարեթիվը պարտադիր է',
      passportIssuer: 'Անձնագրի տրամադրողը պարտադիր է',
      companyName: 'Կազմակերպության անվանումը պարտադիր է',
      tid: 'Հարկ վճարողի հաշվառման համարը պարտադիր է',
      id: 'ID համարը պարտադիր է',
      postalAddressInvalid: 'Փոստային հասցեն պետք է լինի կիրիլիցայով',
      legalAddressInvalid: 'Իրավաբանական հասցեն պետք է լինի կիրիլիցայով',
      companyNameInvalid:
        'Կազմակերպության անվանումը պետք է պարունակի միայն լատինական տառեր',
      wrongCode: 'Սխալ կոդ',
      codeRequired: 'Լրացրե՛ք կոդը',
      privacy: 'Պարտադիր է',
    },

    ipNote: {
      ipText: 'Ուղարկված է IP հասցեից ՝',
    },

    headers: {
      aboutUsPage: 'Internet.am - Մեր մասին',
      clientAreaPage: 'Internet.am - Հաճախորդի պանել',
      editProfilePage: 'Internet.am - Իմ հաշիվ',
      constructorPage: 'Internet.am - Կայքի Կոնստրուկտոր',
      contactsPage: 'Internet.am - Կապ',
      domainsPage: 'Internet.am - Դոմենների գրանցում',
      domainConfigurationPage: 'Internet.am - Դոմենի կարգավորում',
      homePage: 'Internet.am –.AM տիրույթի առաջատար ռեգիստրար',
      hostingPage: 'Internet.am - Հոսթինգ փաթեթներ',
      vpsPage:
        'Internet.am - Լավագույն Cloud VPS սերվերները` ծրագրավորման համար',
      openTicketPage: 'Internet.am - Բացել տոմս',
      orderDetailsPage: 'Internet.am - Վճարել',
      productDetailsPage: 'Internet.am - Պրոդուկտի մասնրամասներ',
      registrationPage: 'Internet.am - Գրանցում Internet.am կայքում',
      resetPasswordPage: 'Internet.am - Գաղտնաբառի վերականգնում',
      specialTermsPage: 'Internet.am - Հատուկ դրույթներ և պայմաններ',
      sslPage: 'Internet.am - SSL սերտիֆիկատներ',
      supportPage: 'Internet.am - Օգնություն',
      creditRechargePage: 'Internet.am - Բալանսի լիցքավորում',
      errorPage: 'Internet.am - 404 սխալ',
      confirmEmailPage: 'Internet.am - Հաստատել էլ. հասցեն',
      blogPage: 'Internet.am - Բլոգ',
      sslConfiguration: 'Internet.am - SSL կարգավորումներ',
    },
    headersDescriptions: {
      aboutUsPage:
        '«ԷՅԲԻՍԻԴՈՄԵՆ» ՍՊԸ-ն իր գործունեությունը սկսել է 2007 թվականից` որպես դոմենների պաշտոնական հաշվեգրող .AM տիրույթում եւ պահպանում է առաջատարի դիրքն՝ առ այսօր։',
      clientAreaPage:
        'Ավելի հեշտ երբեք չէր եղել։ Գրանցե՛ք Ձեր դոմենը եւ ստեղծե՛ք Ձեր կայքը՝ հաշված րոպեների ընթացքում։ Ամեն ինչ սկսվում է գրավիչ դոմենից։',
      editProfilePage:
        'Ավելի հեշտ երբեք չէր եղել։ Գրանցե՛ք Ձեր դոմենը եւ ստեղծե՛ք Ձեր կայքը՝ հաշված րոպեների ընթացքում։ Ամեն ինչ սկսվում է գրավիչ դոմենից։',
      constructorPage:
        'Կայքի ինքնուրույն ստեղծում՝ առանց ծրագրավորողի միջամտության: Առավելությունները՝ արագ, մատչելի, հեշտ, SEO/SSM օպտիմիզացված:',
      contactsPage:
        'Մեզ հետ կարող եք կապ հաստատել 060270027 հեռախոսահամարով, գրելով info@internet.am էլ. փոստին, կամ՝ դիմելով internet.am/support առկա առցանց չաթին։',
      domainsPage:
        'ԷՅԲԻՍԻԴՈՄԵՆ ընկերությունն առաջին տեղն է զբաղեցնում հայկական դոմենի գրանցում իրականացնող թոփ 10 ընկերությունների շարքում։ Ընկերությունը գրանցնում է դոմեններ am և հայ գոտիներում, ինչպես նաև աշխարհի հիմնական տիրույթներում։<br/><br/>' +
        'Ընկերությունը պաշոնապես վկայագրված է Հայաստանի ցանցային տեղեկատվական կենտրոնի (AMNIC) կողմից (https://www.am/), որը կառավարվում է Հայաստանի Ինտերնտ Հանրություն ՀԿ-ի միջոցով(https://www.isoc.am/): <br/><br/>' +
        'Գրանցեք ձեր դոմենը am գոտում և դարձեք Հայաստանում ավելի քան 40000 դոմենների իրավատերից մեկը։ Եթե ձեր նախընտրած դոմենն արդեն իսկ գրանցմած է am գոտում, կարող եք անունը գրանցել com.am, net.am, org.am գոտիներում։ Հաջորդ քայլը կարող է լինել ձեր am կայքի և վեբ փոստի ստեղծումը SSL վկայականով: <br/><br/>' +
        'Էյբիսիդոմեն ընկերությունը տրամդրում է դոմենի, DNS կառավարման, SSL սերտիֆիկատի տեղադրման դյուրին հարթակ, cPanel կառավարման գործիքների միջոցով հոսթինգի հեշտ կառավարում։ <br/><br/>' +
        'Մեզ կարող եք գտնել abcdomain.am (https://abcdomain.am) կամ internet.am (https://internet.am) կայքերի միջոցով։ Էյբիսիդոմեն ընկերությունը շարունակում է Հայաստանում առաջին ինտրենտ մատակարար Արմինկո ընկերության լավ ավանդույթները։',
      domainConfigurationPage:
        'Ավելի հեշտ երբեք չէր եղել։ Գրանցե՛ք Ձեր դոմենը եւ ստեղծե՛ք Ձեր կայքը՝ հաշված րոպեների ընթացքում։ Ամեն ինչ սկսվում է գրավիչ դոմենից։',
      homePage:
        '.AM,.ՀԱՅ,.ORG,.COM,.NET,.INFO,.GROUP և մի շարք այլ տիրույթներում դոմենների գրանցում` տարեկան սկսած 2400 դրամից: Hosting,SSL,Constructor, 24/7 աջակցություն',
      hostingPage:
        ' Ցանոթացեք հայկական դոմենի գրանցում իրականացնող թոփ 10 ընկերությունների շարքում առաջատար հանդիսացող ԷՅԲԻՍԻԴՈՄԵՆ ընկերության կողմից առաջարկվող հոսթինգ ծառայության անհատական, բիզնես փաթեթներին և վեբ հոսթինգի լուծումներին։ Ընդհանուր հոսթինգ, առանձնացված  սերվերներ, առաջարկում ենք մի շարք փաթեթներ, որոնք հարմարեցված են ձեր կայքի կարիքների բավարարմանը: Վայելեք բարձր կատարողականություն, հուսալի աջակցություն  և օգտվեք այն հնարավորություններից, որոնք կապահովեն ձեր՝ արագ, անվտանգ և առանց դժվարությունների առցանց ներկայություն: Ընտրեք ձեր կայքի և վեբ փոստի կարիքների ապահովման համար լավագույն հոսթինգ փաթեթը:',
      vpsPage:
        'Օգտվեք թոփ 10 ընկերությունների շարքում առաջատար հանդիսացող ԷՅԲԻՍԻԴՈՄԵՆ ընկերության կողմից առաջարկվող բարձրորակ VPS հոստինգի լուծումներց։ Լուծումներ, որոնք հարմարեցված են ձեր կարիքների բավարարմանը։ Մեր վիրտուալ մասնավոր սերվերներն առաջարկում են առանձնացված ռեսուրսներ, մասշտաբայնություն և անվտանգություն օպտիմալ կայքի եւ հավելվածների համար: Օգվտեք առաջարկվող ճկունությունից եւ Էյբիսիդոմեն ընկերության կողմից տրամադրվող հուսալի փորձագիտական աջակցությունից: Բարձրացրեք ձեր առցանց ներկայությունը մեր հուսալի VPS ծառայությունների միջոցով, որն ապահովում է հզորություն և վերահսկողություն ձեր թվային նախագծի կարիքների նկատմամբ։',
      openTicketPage:
        'Ավելի հեշտ երբեք չէր եղել։ Գրանցե՛ք Ձեր դոմենը եւ ստեղծե՛ք Ձեր կայքը՝ հաշված րոպեների ընթացքում։ Ամեն ինչ սկսվում է գրավիչ դոմենից։',
      orderDetailsPage:
        'Ավելի հեշտ երբեք չէր եղել։ Գրանցե՛ք Ձեր դոմենը եւ ստեղծե՛ք Ձեր կայքը՝ հաշված րոպեների ընթացքում։ Ամեն ինչ սկսվում է գրավիչ դոմենից։',
      productDetailsPage:
        'Ավելի հեշտ երբեք չէր եղել։ Գրանցե՛ք Ձեր դոմենը եւ ստեղծե՛ք Ձեր կայքը՝ հաշված րոպեների ընթացքում։ Ամեն ինչ սկսվում է գրավիչ դոմենից։',
      registrationPage:
        'Իրավաբանական կամ ֆիզիկական անձի տվյալներով դոմեն գրանցելու համար անհրաժեշտ է լրացնել պահանջվող դաշտերը:',
      resetPasswordPage:
        'Ավելի հեշտ երբեք չէր եղել։ Գրանցե՛ք Ձեր դոմենը եւ ստեղծե՛ք Ձեր կայքը՝ հաշված րոպեների ընթացքում։ Ամեն ինչ սկսվում է գրավիչ դոմենից։',
      specialTermsPage:
        'ԷյԲիՍիԴոմեն ՍՊԸ հատուկ դրույթներն ու պայմանները՝ Դոմեններ, Հոսթինգ, SSL, DNS կառավարում ծառայությունների վերաբերյալ:',
      sslPage:
        'Positive SSL, Positive Wildcard SSL, Rapid SSL, Sectigo EV SSL, Symantec և մի շարք այլ փաթեթների լայն ընտրանի: Տարեկան արժեքը՝ սկսած 10000 դրամից:',
      supportPage:
        'Ինչպե՞ս գրանցել դոմեն։ Ինչպե՞ս ձեռք բերել հոսթինգ եւ թարմացնելդոմենի nameserver-ները։ Ի՞նչ է SSL սերտիֆիկատը։ Ինչպե՞ս դիմել առցանց չաթին։',
      creditRechargePage:
        'Ավելի հեշտ երբեք չէր եղել։ Գրանցե՛ք Ձեր դոմենը եւ ստեղծե՛ք Ձեր կայքը՝ հաշված րոպեների ընթացքում։ Ամեն ինչ սկսվում է գրավիչ դոմենից։',
      errorPage:
        'Ավելի հեշտ երբեք չէր եղել։ Գրանցե՛ք Ձեր դոմենը եւ ստեղծե՛ք Ձեր կայքը՝ հաշված րոպեների ընթացքում։ Ամեն ինչ սկսվում է գրավիչ դոմենից։',
      confirmEmailPage:
        'Ավելի հեշտ երբեք չէր եղել։ Գրանցե՛ք Ձեր դոմենը եւ ստեղծե՛ք Ձեր կայքը՝ հաշված րոպեների ընթացքում։ Ամեն ինչ սկսվում է գրավիչ դոմենից։',
    },

    headerKeywords: {
      homePage:
        'Դոմեն հոսթինգ, գնել դոմեն Հայաստանում, դոմեն Ռեգիստրար, .am դոմենի առաջատար ռեգիստրար, SSL սերտիֆիկատ,  Լինուքս հոսթինգ',
      domainsPage:
        'դոմենի գրանցում,գնել դոմեն,դոմեյն,առցանց դոմենի գրանցում,օնլայն, ամ դոմեն, ամ,ам,am,.am,.ам,․ամ, ․հայ,.hay, .հայ, հայկական դոմենի գրանցում',
      hostingPage:
        'գնել հոսթինգ Հայաստանում, հոսթինգ փաթեթներ, linux hosting cpanel հնարավորությամբ,  էլ. հասցեների ստեղծում',
      vpsPage:
        'SSD Cloud, bandwidth, Ubuntu, Debian, fedora, VPS, virtual private server, վիրտուալ առանձնացված սերվեր',
      sslPage:
        'ssl սերտիֆիկատ, սսլ սերտիֆիկատ, ssl վկայագիր, անվճար ssl սերտիֆիկատ, անվճար սսլ սերտիֆիկատ, անվճար ssl սերտիֆիկատ, վկայագիր, անվտանգ ssl սերտիֆիկատ',
      constructorPage:
        'Ինչպե՞ս զրոյից ստեղծել վեբ կայք, landing կայք, կայքի պատրաստում, վեբ կոնստրուկտոր',
      specialTermsPage:
        'դոմենի գրանցում, հոսթինգ, dns կառավարում, SSL հավաստագիր',
      aboutUsPage: 'էյբիսիդոմեյն, abc domen, abcdomain, abcdomen, internet am',
    },

    homePage: {
      searchAreaBlock: {
        title: 'Գրանցի´ր քո դոմենը',
        sale: 'զեղչով',
        info: 'Ձմռանը Internet.am-ում գործում են աննախադեպ զեղչեր։ Օգտվելու համար կիրառեք ',
        promocode: 'WINTER2021',
        rusText: '',
        endText: ' պրոմոկոդը',
      },

      tldsCard: {
        period: 'տարեկան',
        oldPrice: '10000/տարին',
      },
      buyDomainWithSale: {
        title: 'Գրանցի´ր քո դոմենը',
      },
      hostingPackageBlock: {
        secondaryText: 'NS գրառումների\nփոփոխման համար\nնախատեսված փաթեթ',
      },
      tldCards: {
        period: '/տարին',
        oldPrice: '10000/տարին',
      },
    },

    tooltips: {
      cartProblemTooltip: 'Դուք ունեք ընտրված պրոդուկտներ զամբյուղում',
      registerRequiredToSelectContact:
        'Կոնտակտ ընտրելու համար անհրաժեշտ է գրանցվել',
    },
    general: {
      periods: {
        daily: 'օրական',
        monthly: 'ամսական',
        yearly: 'տարեկան',
        forYears: 'Տարի(ներ)',
      },
      currency: {
        dram: '֏',
        usd: '$',
      },
    },
    faq: {
      title: 'ՀՏՀ',
      domainsTitle: 'Դոմեն',
      hostingTitle: 'Հոսթինգ',
      constructorTitle: 'Կոնստրուկտոր',
      vpsTitle: 'VPS',
      sslTitle: 'SSL',
      domains: {
        q1: 'Ինչպե՞ս գրանցել դոմեն',
        a1: '1. փնտրեք նախընտրելի դոմեն(ներ)ը հիմնական էջից կամ դոմեններ բաժնից<br /><br />2. կատարեք նախընտրած դոմեն(ներ)ի կարգավորումները (գրանցման ժամանակահատվածի ընտրություն, հոսթինգի ավելացում, nameserver-ների կարգավորում)<br /><br />3. ընտրեք դոմեն(ներ)ի իրավատիրոջը<br /><br />4. կատարեք վճարում՝ ստեղծված կանխավճարի հաշվի վրա առկա վճարային տարբերակներից նախընտրելիով',
        q2: 'Ինչպե՞ս փոխել դոմենի nameserver-ները',
        a2: '1. մուտք գործեք ձեր ըքաունթ<br /><br />2. ընտրեք դոմեններ բաժինը<br /><br />3. ընտրեք խմբագրման ենթակա դոմենը<br /><br />4. դոմենի կարգավորվումներում (տողի աջ մասում) ընտրեք կարգավորել DNS<br /><br />5. կատարեք փոփոխությունը, սեղմեք պահպանել հրահանգը',
        q3: 'Ինչպե՞ս տեղափոխել դոմենի սպասարկումն ABCDomain',
        a3: `1. մուտք գործեք ձեր ըքաունթ<br /><br />2. ընտրեք տեղափոխել հրահանգը<br /><br />3. նշեք դոմենն ու EPP Code (*.am տիրույթում հաշվեգրված դոմենների կոդը կարող եք ստանալ <a href="https://www.am/transfer/code/" target="_blank"> https://www.am/transfer/code/</a> հղումով, անհրաժեշտության դեպքում կարող եք վերականգնել դոմենի գաղտնաբառը <a href="https://www.am/password/reset/" target="_blank"> https://www.am/password/reset/</a> հղումով)<br /><br />4. տեղափոխել`,
      },
      hosting: {
        q1: 'Ինչպե՞ս մուտք գործել cpanel',
        a1: '1. մուտք գործեք ձեր ըքաունթ<br /><br />2. ընտրեք «Ծառաթյուններ» բաժինը<br /><br />3. ընտրված ծառայության տողի աջ մասում – մուտք cpanel',
        q2: 'Ինչպե՞ս փոխել հոսթինգ ծառայության գործող փաթեթը',
        a2: '<a href="/open-ticket">Բացել աջակցության հայտ<a/>',
        q3: 'Ինչպե՞ս փոխել հոսթինգ ծառայության դիմաց արվող վճարումների պարբերականությունը',
        a3: '<a href="/open-ticket">Բացել աջակցության հայտ</a>',
        q4: 'Ի՞նչ է համօգտագործվող հոստինգը:',
        a4:
          'Վիրտուալ կամ համօգտագործվող հոսթինգը տեխնոլոգիա է, որտեղ մեկ սերվեր (առանձնացված կամ վիրտուալ) տեղակայում է տարբեր սեփականատերերի բազմաթիվ կայքեր: Օգտատերերը կիսում են պրոցեսորը, հիշողությունը և ինտերնետ կապի ռեսուրսները:<br/><br/>' +
          'Առավելությունները՝<br/><br/>' +
          'Հարկավոր չէ կարգավորել սերվերի կարգավորումները, քանի որ ամեն ինչ արդեն արված է հոսթինգի մատակարարի կողմից։<br/><br/>' +
          'Սերվերի աշխատանքը վերահսկվում է հոսթինգ մատակարարի անձնակազմի կողմից, թարմացնում է ծրագրերը, ուղղում սխալները և վերահսկում հաքերային հարձակումները սերվերի կողմից:<br/><br/>' +
          'Սարքավորումների հետ կապված խնդիրների դեպքում պահուստային սարքավորումը միացված է և անհրաժեշտության դեպքում ստանում պահուստային տարբերակը։<br/><br/>' +
          'Համօգտագործվող հոսթինգում ունեք հասանելիություն կառավարման վահանակի, որը թույլ է տալիս կատարել տարբեր լրացուցիչ կարգավորումներ։<br/><br/>' +
          'Եվ ոչ պակաս կարևոր առավելություն՝ մատչելի գին։<br/><br/>' +
          'Իհարկե, կան նաև թերություններ՝<br/><br/>' +
          'Սահմանափակ քանակությամբ ռեսուրսներ, որոնք բաշխված են այս սերվերում տեղակայված բոլոր կայքերի միջև<br/><br/>' +
          'Հնարավոր է, որ օգտատերերից մեկը ներառվի սպամ ուղարկողների սև ցուցակում, ինչը կարող է ազդել բոլոր օգտատերերի վրա, թեև շատ կարճ ժամանակով, քանի որ հոսթինգ ընկերության համակարգի ադմինիստրատորները միջոցներ են ձեռնարկում էլ․փոստի երթուղիները փոխելու համար։<br/><br/>' +
          'Համօգտագործվող հոսթինգում օգտագործվող սահմանափակ ծրագրակազմը մեծ խնդիր չէ, քանի որ բոլոր հիմնական ծրագրերն ու ծրագրավորման լեզուներն ապահովված են:<br/><br/>' +
          'Ամփոփելով՝ կարող ենք փաստել, որ փոքր և միջին բիզնեսի մեծ մասի համար վիրտուալ հոսթինգը թույլ է տալիս լուծել ինտերնետում ներկայացված լինելու իրենց խնդիրները։<br/><br/>' +
          'Եթե ​​ձեզ ավելի շատ ռեսուրսներ են պետք, ապա ակնհայտ ընտրությունը VPS-ն է, <a href=https://internet.am/vps-packages   target="_blank"> ավելի մանրամասն՝ այստեղ </a>',
      },
      constructor: {
        q1: 'Ի՞նչ որակի և ֆունկցիոնալությամբ կայք է հնարավոր պատրաստել կայքի կոնստրուկտորի միջոցով',
        a1: 'Մեր կայքի կոնստրուկտորն ունի լայն հնարավորություններ` ձեր նախընտրած կայքի իմպորտ, responsive դիզայն ստանալու, SEO օպտիմիզացում անելու, փոքր ֆունկցիոնալով էջեր պատրաստելու և բազմաթիվ այլ գործառույթներ',
        advantagesTitle: 'Վեբ կայքերի ստեղծում կոնստրուկտորի միջոցով',
        advantagesInfo: 'Առավելությունները.',
        info1:
          'Դուք ինքներդ եք ստեղծում ձեր կայքը և կախված չեք վեբ դիզայներներից կամ դիզայներական ընկերություններից',
        info2:
          'Դուք կարող եք ներմուծել ձեր նախընտրած կայքը, ստեղծել անհրաժեշտ պատկերներ և տեքստեր՝ օգտագործելով արհեստական բանականությունը և տեղադրել դրանք ներմուծված կայքում',
        info3:
          'Բազմալեզու աջակցությունը թույլ է տալիս ստեղծել եռալեզու կայք, որը արդիական է Հայաստանի համար',
        info4:
          'Կոնստրուկտորի օգտագործումը թույլ է տալիս ստանալ անվճար SSL սերտիֆիկատ',
        info5:
          'CMS չի օգտագործվում, ինչը թույլ չի տալիս հաքերներին կոտրել այն՝ օգտագործելով հայտնի խոցելիությունները',
        info6:
          'Վեբ ստուդիաների կամ վեբ դիզայներների ծառայությունների համեմատությամբ կայքի ստեղծման համար անհամեմատ ավելի ցածր ֆինանսական ծախսեր',
        info7:
          'Փոփոխություններ կատարելը ձեր ձեռքերում է, կարիք չկա մշակել տեխնիկական պայմաններ և պայմանագիր կազմել կայքի մշակման և սատարման  համար',
        info8: 'Ձեր տրամադրության տակ կան բազմաթիվ պլագիններ',
        info9: 'Առցանց խանութների հիմնական վճարային համակարգերի աջակցություն',
        info10:
          'Ձեր դիզայներական կարողությունների ուղղակի իրականացում, ծրագրավորման լեզուների իմացության կարիք չկա',
      },
      vps: {
        q1: 'Ինչի՞ համար է նախատեսված VPS սերվերը',
        a1: 'Ի տարբերություն shared hosting-ի՝ VPS սերվերն օգտագործում է վիրտուալիզացիայի տեխնոլոգիա։ Ստանում եք սերվեր իր ռեսուրսներով, որը կառավարվում է ձեր կողմից։ Լինելով ավելի փոքր և մատչելի է քան ամբողջական սերվերը՝ ունի մասշտաբայնացման հնարավորություն:',
        q2: 'Ինչու՞ է անհրաժեշտ VPS սերվեր:',
        a2: 'VPS – Վիրտուալ մասնավոր սերվերը  մասնավոր համակարգիչ է, որը կառավարվում է ձեր կազմակերպության կողմից և գտնվում է հոստինգի և VPS ծառայության մատակարարի տվյալների կենտրոնում: Ինտերնետի զարգացման սկզբնական փուլում VPS-ն ընդամենը առանձին սերվեր էր տվյալների կենտրոնում: Այսօր այն, սովորաբար, տվյալների կենտրոնում գտնվող գերհամակարգչի մի մաս է, որը տրամաբանորեն բաժանված է սերվերների: Դուք կարող եք պատվիրել սուպերհամակարգչի մեծ տրամաբանական կտորը թանկ գնով կամ ՝ նույն սուպերհամակարգչի փոքր տրամաբանական կտորը: Բացի VPS-ից, օգտագործվում է VDS հապավումը՝ Վիրտուալ առանձնացված սերվեր, ինչը նշանակում է, որ այս հասկացությունները հոմանիշ են։',
        q3: 'Ինչպե՞ս է կարելի օգտագործել VPS սերվերը:',
        a3:
          'Վեբ կայքերի համար, որոնց տրաֆիկը գերազանցում է օրական 10 հազարը, համօգտագործվող հոսթինգի հնարավորությունները դառնում են անբավարար և այդ դեպքում լավագույն լուծումը VPS սերվերն է։<br/><br/>' +
          'Ձեր ծրագրային արտադրանքը պաշտոնական  կայքում հրապարակելուց առաջ` այն մշակելու և փորձարկելու համար դուք ստեղծում եք նոր կայքի դասավորություն .am գոտում, ստուգում դրա աշխատանքը և, երբ բոլոր անհամապատասխանությունները վերացվեն, կարող եք այն պաշտոնապես հրապարակել:<br/><br/>' +
          'Ձեր կազմակերպության համար հեռավար աշխատասեղան ստեղծելու համար: Այսօր կա ծրագրերի մեծ ընտրություն, որում թույլ են տալիս ստեղծել վիրտուալ գրասենյակ ընկերության աշխատակիցների համար: Տեղափոխվելու, գործուղումների կամ տարբեր արգելափակումների մասին անհանգստանալու կարիք չկա, ձեր աշխատասեղանը հասանելի է աշխարհի ցանկացած կետից:<br/><br/>' +
          'VPS սերվերի վրա ձեր սեփական VPN սերվեր ստեղծելու համար, ինչը կտրուկ մեծացնում է ձեր աշխատասեղանի կիբերանվտանգությունը: Թույլ տվեք միացումներ ձեր կազմակերպության աշխատասեղանին բացառապես  VPN սերվերից և կարող եք վստահ լինել, որ ձեր կազմակերպության աշխատասեղանի վրա բոլոր հաքերային հարձակումները կկանխվեն:<br/><br/>' +
          'Ձեր հաշվապահական սերվերի համար, որը թույլ կտա հաշվապահին, հաշվետարին և վարչակազմին աշխատել հեռակա կարգով: Տեսահսկում կազմակերպելու համար՝ վիդեո ֆայլերի պահպանումը պահանջում է մեծ հիշողություն։ VPS սերվերի վրա  միշտ կարող եք գտնել օպտիմալ հավասարակշռություն պահպանման ժամանակի, պատկերի որակի և տեսահսկման համակարգերի այլ գործոնների միջև:<br/><br/>' +
          'Ձեր կազմակերպության արխիվը, աշխատակիցների և օգտատերերի անձնական տվյալները պահելու համար: Շատ տարբեր առաջադրանքների համար՝ սկսած վեբփոստից և ինտերնետային պարագաներից՝ մինչև խաղային սերվեր:<br/><br/>' +
          'Անհրաժեշտ է հաշվի առնել, որ սեփական VPS սերվերի պահպանումը պահանջում է պրոֆեսիոնալ անձնակազմի առկայությունը: Այսօր տարածված են Linux օպերացիոն համակարգի վրա հիմնված լուծումները: Այս ոլորտում մասնագետներ հրավիրելու հետ կապված որոշակի դժվարություններ կան, որոնք, թեև, լուծելի են: Ոչ պակաս կարևոր է VPS սերվերի մատակարարի ընտրության հարցը։ Վստահելի մատակարարներն այսօր ապահովում են ինչպես սերվերի անխափան աշխատանքը, այնպես էլ՝ տրամադրում են պահեստային ծառայություններ: Էյբիսիդոմեն ընկերությունն առաջարկում է գերհուսալի ծառայություններ, ճկուն սակագնային պլաններ և VPS սերվերի պարամետրերի հեշտ կարգավորում։',
        q4: 'Ո՞ր ծրագրավորման լեզուներով պատրաստված համակարգերն են տեղակայվում VPS-ի վրա։',
        a4: 'Բոլոր արդի ծրագրավորման լեզուներով պատրաստված համակարգերը կարելի է տեղադրել VPS-ի վրա։ Shared hosting-ը նախատեսված է PHP կամ ստատիկ HTML/JS/CSS կայքերի համար, մինչդեռ VPS- ում կարող եք ինքնուրույն կարգավորել անհրաժեշտ միջավայրը ցանկացած ծրագրավորման լեզվի համար:',
      },
      ssl: {
        q1: 'Ի՞նչ է SSL սերտիֆիկատը',
        a1: 'SSL սերտիֆիկատը բացատրվում է, ինչպես Secure Socket Layers․ դա անվտանգության արձանագրության մի գործընթաց է, որով օգտվողի և սերվերի միջև տվյալների փոխանակումը իրականացվում է շիֆրավորված ձևով այնպես, որ երրորդ կողմը հնարավորություն չունենա ներխուժել այդ գործընթացի մեջ։<br/><br/>SSL սերտիֆիկատը միանշանակորեն հաստատում է տվյալ դոմենի օգտատերի և այն կայքի միջև կապը, որի վրա ուղղորդում է տվյալ դոմենային անունը։<br /><br />SSL սերտիֆիկատը ստանում են Ինտերնտում տարբեր մատակարարներից, տվյալ մատակարարի հուսալիությունը հաստատվում է այդ մատակարարի կողմից տրամադրված սերտիֆիկատների պատմականորեն ձևավորված հաջող պրակտիկայով։<br /><br />SSL սերտիֆիկատն ունի օգտագործման ժամկետ, ինչն իր հերթին նշանակում է, որ անհրաժեշտ է թարմացնել այն՝ կայքն անվտանգ պահպանելու համար։ Սերտիֆիկատի թարմացումն իրականացվում է տարեկան մեկ անգամ։',
        q2: 'Ի՞նչ է Wildcard-ը',
        a2: 'Wildcard-ը օգտագործվում է պաշտպանելու ոչ միայն դոմենը, այլև սաբդոմենները',
        q3: 'Ինչպե՞ս կարգավորել SSL-ը',
        a3: 'Կարող եք անցնել <a href="ssl-configuration" target="_blank">հղումով</a>',
      },
    },

    accountPopup: {
      clientArea: 'Իմ հաշիվը',
      editProfile: 'Խմբագրել պրոֆիլը',
      logout: 'Ելք',
    },
    pagination: {
      previous: 'Հետ',
      next: 'Առաջ',
    },
    sslConfigurationPage: {
      header:
        'Ինչպե՞ս գեներացնել սերտիֆիկատ տեղադրել վերջնական ֆայլերը Cpanel-ում',
      howTo1: 'Սերտիֆիկատը գեներացնելու համար անրհաժեշտ է',
      howTo2: 'Վերջնական ֆայլերը Cpanel-ում տեղադրելու համար անհրաժեշտ է',
      sub: 'Եթե Ձեր կայքի սերվերը մեզ մոտ չի գտնվում, ապա հետևեք Ձեր հոսթինգ ընկերության ուղեցույցին։',
      steps1: {
        step1: 'մուտք գործեք անձնական գրասենյակ՝ «Իմ հաշիվը»',
        step2: 'ընտրել ծառայություններ բաժինը',
        step3:
          'ընտրել սերտիֆիկատ ծառայության տողի աջ կողմում գտնվող «Կարգավորել» հրահանգը',
        step4:
          'ընտրել պատվերի տեսակը, գեներացնել կամ վերբեռնել սերտիֆիկատը, լրացրնել ադմինիստրարտիվ կոնտակտային տվյալներ, նեռբեռել Key-ն <b><i>(Private Key-ն անրհաժեշտ է սերտիֆիկատը տեղադրելու համար, ուստի անհրաժեշտ է այն պահպանել ապահով վայրում և չկորցնել)</i></b>, սեղմել շարունակել',
        step5:
          'ընտրել Domain Control Validation (DCV) մեթոդը, սեղմել շարունակել և՝ գործընթացն ավարտելու համար ստուգել մեյլը <b><i>(եթե ընտրեք եք «Email Validation» տարբերակը, ապա ցուցակից ընտրել մեյլը, որը պետք է նախապես ստեղծված լինի)</i></b>',
        step6:
          'վալիդացիայի ֆայլերը և Private Key –ն կարող եք ստանալ ծառայություններ բաժինում / մանրամասներ',
      },
      steps2: {
        step1: 'մուտք գործել "security" բաժնում "SSL/TLS" ենթաբաժինը',
        step2: 'սեղմել "Manage SSL sites" կոճակին',
        step3: 'Domain - Select a Domain դաշտում ընտրել դոմենը',
        step4: 'մուտքագրել սերտիֆիկատը և Private Key-ն',
        step5:
          'Certificate: (CRT) դաշտում նույնությամբ պատճենել և տեղադրել yourdomain.crt ֆայլի պարունակությունը',
        step6:
          'Private Key (KEY) դաշտում նույնությամբ պատճենել և տեղադրել CSR-ի հետ մեկտեղ ներբեռնված Private Key-ն',
        step7:
          'Certificate Authority Bundle: (CABUNDLE) դաշտում նույնությամբ պատճենել  և տեղադրել yourdomain.ca ֆայլի պարունակությունը',
        step8: 'սեղմել Install Certificate կոճակը',
      },
      steps3: {
        step7: 'մուտք գործեք անձնական գրասենյակ՝ «Իմ հաշիվը»',
        step8: 'ընտրեք ծառայություններ բաժինը',
        step9:
          'սեղմեք սերտիֆիկատ ծառայության տողի աջ կողմում գտնվող կարգավորումների հրահանգի վրա, ընտրեք վերբեռնել CSR հրահանգը, <b>այնուհետ՝ վերբեռնել CSR</b>',
        step10:
          'ընտրեք վալիդացիայի նախընտրելի տարբերակը <b><i>(եթե ընտրել եք «Email Validation» տարբերակը, ապա ուշադիր եղեք, որ վալիդացիայի համար նշված էլ. փոստը նախապես ստեղծված լինի)</i></b>',
        step11: 'Սեղմեք «ստեղծել» հրահանգը',
        step12:
          '<i>«Email Validation» տարբերակի պարագայում CSR-ը գեներացնելուց/վերբեռնելուց հետո մեկ աշխատանքային օրվա ընթացքում Ձեզ կուղարկվի նամակ՝ վալիդացիայի հղումով։ Այդ հղումով անցնելուց հետո սերտիֆիկատը կհաստատվի և կուղարկվեն yourdomain.crt և yourdomain.ca անհրաժեշտ վերջնական ֆայլերը։</i>',
        step13:
          '<i>Ֆայլով վալիդացիայի պարագայում մեկ աշխատանքային օրվա ընթացքում կուղարկվի վալիդացիան անցնելու վերաբերյալ ուղեցույց։</i>',
        step14:
          '<i><b>**Եթե գնել եք Wildcard տեսակի SSL սերտիֆիկատ, ապա  CSR գեներացնելիս «Դոմեն» դաշտում  example.com տեսքի դոմենի փոխարեն լրացնել *.example.com տեսքով այն դոմենը, որի համար նախատեսված է SSL սերտիֆիկատը։</b></i>',
      },
      steps4: {
        step9: 'մուտք գործեք "security" բաժնում "SSL/TLS" ենթաբաժինը',
        step10: 'սեղմեք "Manage SSL sites" կոճակին',
        step11: 'Domain - Select a Domain դաշտում ընտրեք Ձեր դոմենը',
        step12: 'մուտքագրեք սերտիֆիկատը և Private Key-ն',
        step13:
          'Certificate: (CRT) դաշտում նույնությամբ պատճենեք և տեղադրեք yourdomain.crt ֆայլի պարունակությունը',
        step14:
          'Private Key (KEY) դաշտում նույնությամբ պատճենեք և տեղադրեք CSR-ի հետ մեկտեղ ներբեռնված Private Key-ն',
        step15: 'սեղմեք Install Certificate կոճակը',
        step16:
          'Certificate Authority Bundle: (CABUNDLE) դաշտում նույնությամբ պատճենեք և տեղադրեք yourdomain.ca ֆայլի պարունակությունը',
      },
    },
    sslAttentionModal: {
      header: 'Ուշադրություն',
      info: 'Ձեր ձեռք բերած SSL սերտիֆիկատի կարգավորումներն ավարտված չեն',
      info1: 'Կարգավորումներ',
    },

    sslConfiguration: {
      new: 'Նոր',
      renew: 'Երկարաձգում',
      header1: 'Ադմինիստրատիվ կոնտակտային տվյալներ',
      headerOrganization: 'Կազմակերպության կոնտակտային տվյալներ',
      orderType: 'Պատվերի տեսակը',
      back: 'Հետ',
      continue: 'Շարունակել',
      firstName: 'Անուն',
      lastName: 'Ազգանուն',
      organization: 'Կազմակերպություն',
      jobTitle: 'Պաշտոն',
      city: 'Քաղաք',
      zipCode: 'Փոստային ինդեքս',
      country: 'Երկիր',
      phoneNumber: 'Հեռախոսահամար',
      email: 'Էլ․ փոստ',
      address1: 'Հասցե 1',
      address2: 'Հասցե 2',
      state: 'Նահանգ/Մարզ',
      header2: 'Սերտիֆիկատի հաստատման էլ․ փոստ',
      domain: 'Դոմեն',
      method: 'DCV միջոց',
      'Awaiting Configuration': 'Կարգավորել',
      'Configuration Submitted': 'Կարգավորումները կատարված են',
      message: 'Կարգավորումն ավարտված է',
      emailMessage: 'Խնդրում ենք ստուգել <b>{{email}}</b> հասցեն։',
      invalidTyping: 'Դաշտը պետք է պարունակի միայն լատինական տառեր',

      orgFields: {
        'Organization Name': 'Ընկերության անվանում',
        Division: 'Ստորաբաժանում',
        'LEI code': 'LEI կոդ',
        Duns: 'DUNS',
        Address: 'Հասցե',
        City: 'Քաղաք',
        Country: 'Երկիր',
        Fax: 'Ֆաքս',
        'Phone Number': 'Հեռախոսահամար',
        'Zip Code': 'Փոստային ինդեքս',
        'State / Region': 'Մարզ/նահանգ',
        leiCodeError: 'LEI կոդը պետք է պարունակի առավելագույնը 20 սիմվոլ',
        orgDunsError: 'DUNS-ը պետք է լինի 9 նիշանոց թիվ',
      },
    },

    serviceDetails: {
      regDate: 'Գրանցման ամսաթիվ',
      cycle: 'Վճարման պարբերականությունը',
      next: 'Վճարված է մինչև',
      domain: 'Դոմեն',
      configStatus: 'Կոնֆիգուրացիայի կարգավիճակը',
      sslStatus: 'SSL կարգավիճակը',
      orderId: 'Գործընկերոջ պատվերի ID',
      approverEmail: 'Հաստատման էլ․ փոստ',
      actions: 'Գործողություններ',
      getPrivateKey: 'Ստանալ private key',
      downloadFile: 'Ներբեռնել վալիդացիայի ֆայլերը',
      Active: 'Ակտիվ',
      Cancelled: 'Չեղարկված',
      Processing: 'Մշակման փուլ',
      Expired: 'Ժամկետանց',
      Terminated: 'Ջնջված',
    },

    pricelistPage: {
      zone: 'Տիրույթ',
      year: 'տարի',
      period: 'Գրանցման ժամկետ',
      register: 'Գրանցում',
      renew: 'Երկարաձգում',
      transfer: 'Տեղափոխում',
      details: 'Տիրույթի մանրամասներ',
      search: 'TLD որոնում',
      info: 'Որոշ տիրույթներում դոմեն(ներ) գրանցելիս հնարավոր է պահանջվի հավելյալ տեղեկատվություն և/կամ փաստաթղթեր',
      info2: 'Մանրամասները՝',
    },

    telcellPaymenPage: {
      backButton: 'Վերադարձ անձնական էջ',
    },
    goToClientAreaPage: {
      backButton: 'Վերադարձ անձնական էջ',
    },

    paymentSuccessStatus: {
      success: 'Բարեհաջող վճարված է',
    },
  },
}
