import { connect } from 'react-redux';

import { GlueRecordsModal as Self } from './GlueRecordsModal';
import {
  setNewDomainNameServers,
  setNewGlues,
} from '../../../common/App/App.actions';
import {
  setNameServers,
  getNameservers,
} from '../NameServerModal/NameServerModal.actions';
import { setGlueRecords, getGlues } from './GlueRecordsModal.actions';
import { showModal } from '../../../common/Modal/Modal.actions';

const mapStateToProps = ({ app, nameServerModal, glueRecordsModal }) => ({
  cart: app.cart,
  nameservers: nameServerModal.modalNameservers,
  nameserversLoading: nameServerModal.modalNameserversLoading,
  glues: glueRecordsModal.modalGlues,
  glueLoading: glueRecordsModal.modalGluesLoading,
  updateNameserversLoading: nameServerModal.updateNameserversLoading,
  updateNameserversError: nameServerModal.updateNameserversError,
  updateGlueLoading: glueRecordsModal.updateGlueLoading,
  updateGlueError: glueRecordsModal.updateGlueError,
  tld: glueRecordsModal.tld,
});

const mapDispatchToProps = {
  setNameServers,
  setNewDomainNameServers,
  getNameservers,
  showModal,
  setGlueRecords,
  setNewGlues,
  getGlues,
};

export const GlueRecordsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self);
