import FetchApi from '../../../helpers/FetchApi';
import { ADD_CONTACT_TYPES } from './AddContactModal.types';

export const addContact = (values) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_CONTACT_TYPES.ADD_CONTACT_REQUEST,
    });

    const res = await FetchApi.post('contacts', values);
    dispatch({
      type: ADD_CONTACT_TYPES.ADD_CONTACT_SUCCESS,
    });
    return res.data.data.contactid;
  } catch (e) {
    dispatch({
      type: ADD_CONTACT_TYPES.ADD_CONTACT_FAIL,
      error: e.data ? e.data.error || e.data.validation_error : e.message,
    });
    return false;
  }
};
