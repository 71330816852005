import { NAME_SERVER_MODAL } from './NameServerModal.types'
import { MODAL_TYPES } from '../../../common/Modal/Modal.types'

const initialState = {
  modalNameservers: null,
  modalNameserversLoading: false,
  modalNameserversError: null,
  updateNameserversLoading: false,
  updateNameserversError: null,
  modalNameserversCode: null,
  modalNameserversCodeLoading: false,
  modalNameserversCodeError: null,
}

export const nameServerModal = (state = initialState, action) => {
  switch (action.type) {
    case NAME_SERVER_MODAL.GET_NAMESERVERS_REQUEST:
      return {
        ...state,
        modalNameserversLoading: true,
        modalNameservers: null,
        modalNameserversError: null,
      }
    case NAME_SERVER_MODAL.GET_NAMESERVERS_SUCCESS:
      return {
        ...state,
        modalNameserversLoading: false,
        modalNameservers: action.data,
        modalNameserversError: null,
      }
    case NAME_SERVER_MODAL.GET_NAMESERVERS_FAIL:
      return {
        ...state,
        modalNameserversLoading: false,
        modalNameservers: null,
        modalNameserversError: action.error,
      }
    case NAME_SERVER_MODAL.SET_NAMESERVERS_REQUEST:
      return {
        ...state,
        updateNameserversLoading: true,
        updateNameserversError: null,
      }
    case NAME_SERVER_MODAL.SET_NAMESERVERS_SUCCESS:
      return {
        ...state,
        updateNameserversLoading: false,
        updateNameserversError: null,
      }
    case NAME_SERVER_MODAL.SET_NAMESERVERS_FAIL:
      return {
        ...state,
        updateNameserversLoading: false,
        updateNameserversError: action.error,
      }
    case NAME_SERVER_MODAL.GET_NAMESERVERS_CODE_REQUEST:
      return {
        ...state,
        modalNameserversCodeLoading: true,
        modalNameserversCode: null,
        modalNameserversCodeError: null,
      }
    case NAME_SERVER_MODAL.GET_NAMESERVERS_CODE_SUCCESS:
      return {
        ...state,
        modalNameserversCodeLoading: false,
        modalNameserversCode: action.data,
        modalNameserversCodeError: null,
      }
    case NAME_SERVER_MODAL.GET_NAMESERVERS_CODE_FAIL:
      return {
        ...state,
        modalNameserversCodeLoading: false,
        modalNameserversCode: null,
        modalNameserversCodeError: action.error,
      }
    case MODAL_TYPES.HIDE_MODAL:
      return {
        ...initialState,
      }
    default:
      return {
        ...state,
      }
  }
}
