import { connect } from 'react-redux';

import { FilterPopup as Self } from './FilterPopup';
import { showFilterPopup, setFilter } from '../../ClientAreaPage.actions';

const mapStateToProps = ({ clientAreaPage }) => ({
  isShown: clientAreaPage.filterPopupIsShown,
  filters: clientAreaPage.filters,
});

const mapDispatchToProps = {
  showFilterPopup,
  setFilter,
};

export const FilterPopup = connect(mapStateToProps, mapDispatchToProps)(Self);
