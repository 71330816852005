const config = [
  {
    tld: '.am',
    years: 3,
    canBeIncognito: true,
  },
  {
    tld: '.հայ',
    years: 3,
    canBeIncognito: true,
  },
  {
    tld: '.co.am',
    years: 3,
    canBeIncognito: true,
  },
  {
    tld: '.com.am',
    years: 3,
    canBeIncognito: true,
  },
  {
    tld: '.net.am',
    years: 3,
    canBeIncognito: true,
  },
  {
    tld: '.org.am',
    years: 3,
    canBeIncognito: true,
  },
  {
    tld: '.com',
    years: 1,
    canBeIncognito: true,
  },
  {
    tld: '.net',
    years: 1,
    canBeIncognito: true,
  },
  {
    tld: '.org',
    years: 1,
    canBeIncognito: true,
  },
  {
    tld: '.by',
    years: 1,
    canBeIncognito: false,
  },

  {
    tld: '.ru',
    years: 1,
    canBeIncognito: false,
    additionalFields: [
      {
        id: 'idNumbers',
        label: 'domainConfigurationPageLabels.labelId',
        subLabel: 'domainConfigurationPageSubLabels.subLabelId',
        error: 'errors.error',
        personType: 'physical',
        placeholder: 'domainConfigurationPageLabels.labelId',
        format: 'text',
      },
      {
        url: '/domain-settings',
        id: 'taxpayerIdentificationNumber',
        label: 'domainConfigurationPageLabels.labelTin',
        subLabel: 'domainConfigurationPageSubLabels.subLabelTin',
        errorTin: 'errors.errorTin',
        personType: 'legal',
        placeholder: '321321',
        format: 'text',
      },
      {
        url: '/domain-settings',
        id: 'dateOfBirth',
        label: 'domainConfigurationPageLabels.labelDob',
        subLabel: 'domainConfigurationPageSubLabels.subLabelDob',
        errorDob: 'errors.errorDob',
        personType: 'physical',
        placeholder: 'YYYY-MM-DD',
        mask: 'YYYY-MM-DD',
        format: 'date',
      },
      {
        id: 'passportSeries',
        label: 'domainConfigurationPageLabels.labelPassportSeries',
        subLabel: 'domainConfigurationPageSubLabels.subLabelDob',
        errorPassportSeries: 'hfkjsdhfsjdkhffhs',
        personType: 'physical',
        placeholder: 'AM00000000',
        format: 'text',
      },

      {
        id: 'companyNameLat',
        label: 'domainConfigurationPageLabels.labelCompanyName',
        subLabel: 'domainConfigurationPageSubLabels.subLabelTin',
        errorCompany: 'hfkjsdhfsjdkhffhs',
        personType: 'legal',
        placeholder: 'Example',
        format: 'text',
      },

      {
        id: 'postalAddressCyr',
        label: 'domainConfigurationPageLabels.labelPostalAddress',
        subLabel: 'domainConfigurationPageSubLabels.subLabelTin',
        errorPostal: 'hfkjsdhfsjdkhffhs',
        personType: 'legal',
        placeholder: 'domainConfigurationPageLabels.labelPostalAddress',
        format: 'text',
      },

      {
        id: 'legalAddressCyr',
        label: 'domainConfigurationPageLabels.labelLegalAddress',
        subLabel: 'domainConfigurationPageSubLabels.subLabelTin',
        errorLegal: 'hfkjsdhfsjdkhffhs',
        personType: 'legal',
        placeholder: 'domainConfigurationPageLabels.labelLegalAddress',
        format: 'text',
      },

      {
        id: 'passportIssuer',
        label: 'domainConfigurationPageLabels.labelPassportIssuer',
        subLabel: 'domainConfigurationPageSubLabels.subLabelDob',
        errorPassportIssuer: 'dgdfdggdg',
        personType: 'physical',
        placeholder: 'domainConfigurationPageLabels.labelPassportIssuer',
        format: 'text',
      },
      {
        id: 'passportDate',
        label: 'domainConfigurationPageLabels.labelPassportDate',
        subLabel: 'domainConfigurationPageSubLabels.subLabelDob',
        errorPassportDate: 'errors.errorDob',
        personType: 'physical',
        placeholder: 'YYYY-MM-DD',
        format: 'date',
      },
      {
        id: 'phoneNumber',
        label: 'domainConfigurationPageLabels.labelPhonenumber',
        errorPhone: 'dgdfdggdg',
        personType: 'physical',
        placeholder: '+79231234567',
        format: 'text',
      },
      {
        id: 'phoneNumber',
        label: 'domainConfigurationPageLabels.labelPhonenumber',
        errorPhone: 'dgdfdggdg',
        personType: 'legal',
        placeholder: '+79231234567',
        authNeeded: true,
        format: 'text',
      },
    ],
  },
];

export { config };
