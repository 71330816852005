import React from 'react';

import * as S from './SetGlueRecordsSuccessModal.styles';
import { i18n } from '../../../i18n';

export const SetGlueRecordsSuccessModal = ({ hideModal }) => (
  <S.Modal onClick={(e) => e.stopPropagation()}>
    <S.UploadCsrSuccessModalContainer>
      <S.Image />
      <S.Title>{i18n.t('glueRecordsSuccessModal.n1')}</S.Title>
      <S.Header>{i18n.t('glueRecordsSuccessModal.n2')}</S.Header>
      <S.CloseModalButton onClick={hideModal} />
    </S.UploadCsrSuccessModalContainer>
  </S.Modal>
);
