import styled from 'styled-components'

export const DomainPrices = styled.div`
  width: 100%;
  background: #fff;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DomainTableContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 80px;
  width: 100%;

  @media (min-width: 768px) {
    width: 700px;
  }

  @media (min-width: 1024px) {
    width: 900px;
  }

  @media (min-width: 1280px) {
    width: 1000px;
  }
`

export const DomainsTable = styled.div`
  width: 800px;

  @media (min-width: 768px) {
    width: 100%;
  }
`

export const BottomWrapper = styled.div`
  width: 100%;
  margin: 20px auto 0 auto;
  overflow-x: scroll;
  border: 1px solid #ccc;
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;

  @media (min-width: 768px) {
    overflow-x: hidden;
    border: none;
  }
`

export const TopWrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 700px;
  }

  @media (min-width: 1024px) {
    width: 900px;
  }

  @media (min-width: 1280px) {
    width: 1000px;
  }
`

export const TableRow = styled.div`
  width: 100%;
  height: 50px;
  color: #484857;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  @media (min-width: 768px) {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
`

export const RowItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  text-align: left;
  font-family: sans-serif;
  font-size: 14px;

  @media (min-width: 768px) {
    width: 150px;
  }

  @media (min-width: 1280px) {
    width: 200px;
  }
`

export const Link = styled.a`
  width: 100px;
  word-break: break-all;
  color: black;
  text-decoration: underline;
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #242e46;
  height: 50px;
  border-radius: 5px 5px 0px 0px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  color: #fff;
  text-align: center;
  font-size: 14px;

  @media (min-width: 768px) {
    width: 150px;
    font-size: 16px;
  }

  @media (min-width: 1280px) {
    width: 200px;
  }
`
export const SearchInput = styled.input`
  width: 250px;
  height: 20px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 5px;
  margin-top: 10px;

  @media (min-width: 768px) {
    width: 300px;
    padding: 18px;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const Error = styled.div`
  width: 100%;
  height: 200px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`
export const RadioButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;

  @media (min-width: 768px) {
    width: 425px;
    justify-content: unset;
    margin-top: 20px;
  }

  @media (min-width: 1280px) {
    margin-top: 25px;
  }
`

export const Radio = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 33px;

  &:last-child {
    margin: 0px;
  }
`
export const Input = styled.input`
  width: auto;
  padding: 12px;
  max-height: 44px;
  outline: none;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 5px;

  @media (min-width: 768px) {
    width: 385px;
  }

  &.radio {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    -webkit-appearance: none;
    border: 1px solid #d4d4d4;
    padding: 0px;
    cursor: pointer;

    &:checked {
      border-color: #dc773c;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      margin: 20% auto;
      border-radius: 50%;
      left: 4px;
      top: 0px;
      background: #d4d4d4;
    }

    &:checked:before {
      background: #dc773c;
    }
  }
`
export const Label = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 17px;

  &.radio {
    margin: 0px;
    margin-left: 7px;
    opacity: 0.38;
  }

  @media (min-width: 768px) {
    font-size: 18px;
  }
`

export const Info = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;

  @media (min-width: 768px) {
    font-size: 16px;
  }

  @media (min-width: 1280px) {
    font-size: 18px;
  }
`
