import { CREDIT_RECHARGE_TYPES } from './CreditRechargePage.types';

const initialState = {
  loading: false,
  error: null,
  data: {},
};

function creditRechargePage(state = initialState, action) {
  switch (action.type) {
    case CREDIT_RECHARGE_TYPES.CREDIT_RECHARGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREDIT_RECHARGE_TYPES.CREDIT_RECHARGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CREDIT_RECHARGE_TYPES.CREDIT_RECHARGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export { creditRechargePage };
