export const SSL_TYPES = {
  SET_STEP: 'SSL_TYPES.SET_STEP',
  SET_VALUES: 'SSL_TYPES.SET_VALUES',
  SET_OPTIONS: 'SSL_TYPES.SET_OPTIONS',
  CONFIGURE_SSL_REQUEST: 'SSL_TYPES.CONFIGURE_SSL_REQUEST',
  CONFIGURE_SSL_SUCCESS: 'SSL_TYPES.CONFIGURE_SSL_SUCCESS',
  CONFIGURE_SSL_FAIL: 'SSL_TYPES.CONFIGURE_SSL_FAIL',
  FIRST_STEP_CONFIGURE_SSL_REQUEST:
    'SSL_TYPES.FIRST_STEP_CONFIGURE_SSL_REQUEST',
  FIRST_STEP_CONFIGURE_SSL_SUCCESS:
    'SSL_TYPES.FIRST_STEP_CONFIGURE_SSL_SUCCESS',
  FIRST_STEP_CONFIGURE_SSL_FAIL: 'SSL_TYPES.FIRST_STEP_CONFIGURE_SSL_FAIL',
}
