import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Footer as Self } from './Footer';
import { showModal } from '../../common/Modal/Modal.actions';
import { toggleLanguagesPopup } from '../../common/App/App.actions';

const mapStateToProps = ({ app }) => ({
  dimensions: app.dimensions,
});

const mapDispatchToProps = {
  showModal,
  toggleLanguagesPopup,
};

export const Footer = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Self)
);
