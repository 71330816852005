import { i18n } from '../../i18n'
import { v4 as uuidv4 } from 'uuid'
import Cookies from 'js-cookie'

import FetchApi from '../../helpers/FetchApi'
import { APP_TYPES } from './App.types'

import { changeUrl } from '../../hooks/changeUrl'

export const setWindowDimensions = (dimensions) => ({
  type: APP_TYPES.SET_WINDOW_DIMENSIONS,
  dimensions,
})

export const setAuthData = (auth) => ({
  type: APP_TYPES.SET_AUTH_DATA,
  email: auth.email,
  token: auth.token,
  fullname: auth.fullname,
  userId: auth.userId,
  address: auth.address,
  currency: auth.currency,
  creditRecharge: auth.creditRecharge,
  legalStatus: auth.legalStatus,
  disableCode: auth.disableCode,
})

export const logout = (history) => (dispatch) => {
  FetchApi.removeToken()
  history.push(changeUrl('/'))
  dispatch({
    type: APP_TYPES.LOGOUT,
  })
}

export const setCart = (cart) => ({
  type: APP_TYPES.SET_CART,
  cart,
})

export const addToCart = (product) => (dispatch, getState) => {
  const { cart } = getState().app
  const { userId } = getState().app.auth

  const prod =
    product.type === 'domain'
      ? {
          item_id: `${product.domain}${product.tld}`,
          item_name: `${product.domain}${product.tld}`,
          item_category: product.type,
        }
      : {
          item_id: product.id,
          item_name: product.name,
          item_category: product.type,
        }

  const dataLayer = window.dataLayer || []
  dataLayer.push({
    event: 'add_to_cart',
    currency: 'USD',
    value: product.premium_price_usd || product.price_usd,
    category: [prod.item_category],
    items: [
      {
        name: [prod.item_name],
        id: [prod.item_id],
      },
    ],
    ecommerce: {
      currency: 'USD',
      add: {
        products: [
          {
            name: [prod.item_name],
            id: [prod.item_id],
            price: product.premium_price_usd || product.price_usd,
            category: [prod.item_category],
          },
        ],
      },
    },
  })

  const maxOrder = Math.max.apply(
    Math,
    cart.map(function (o) {
      return o.order || 0
    })
  )

  const match = cart.find(
    (item) =>
      product.type === 'domain' &&
      product.type === item.type &&
      product.domain === item.domain &&
      product.tld === item.tld
  )

  if (match) return match.id
  const id = uuidv4()
  dispatch({
    type: APP_TYPES.ADD_TO_CART,
    product: { ...product, _id: id, order: maxOrder + 1 },
  })
  // const carts = JSON.parse(localStorage.getItem('carts')) || {};
  localStorage.setItem('cart', JSON.stringify(getState().app.cart))
  // localStorage.setItem(
  //   'carts',
  //   JSON.stringify({ ...carts, [userId]: getState().app.cart })
  // );

  return id
}

export const removeFromCart = (product) => (dispatch, getState) => {
  const prod =
    product.type === 'domain'
      ? {
          item_id: `${product.domain}${product.tld}`,
          item_name: `${product.domain}${product.tld}`,
          item_category: product.type,
        }
      : {
          item_id: product.id,
          item_name: product.name,
          item_category: product.type,
        }
  const dataLayer = window.dataLayer || []
  dataLayer.push({
    event: 'remove_from_cart',
    currency: 'USD',
    value: product.premium_price_usd || product.price_usd,
    category: [prod.item_category],
    items: [
      {
        name: [prod.item_name],
        id: [prod.item_id],
      },
    ],
    ecommerce: {
      remove: {
        products: [
          {
            name: [prod.item_name],
            id: [prod.item_id],
            price: product.premium_price_usd || product.price_usd,
            category: [prod.item_category],
          },
        ],
      },
    },
  })

  dispatch({
    type: APP_TYPES.REMOVE_FROM_CART,
    product,
  })
  localStorage.setItem('cart', JSON.stringify(getState().app.cart))
}

export const updateCart = (product, obj) => (dispatch, getState) => {
  dispatch({
    type: APP_TYPES.UPDATE_CART,
    product,
    obj,
  })
  localStorage.setItem('carts', JSON.stringify(getState().app.cart))
}

export const showCart = (status) => ({
  type: APP_TYPES.SHOW_CART,
  status,
})

export const toggleCart = () => ({
  type: APP_TYPES.TOGGLE_CART,
})

export const setNewDomainNameServers =
  (id, nameservers, onSuccess) => (dispatch) => {
    dispatch({
      type: APP_TYPES.SET_DOMAIN_NAMESERVERS,
      id,
      nameservers,
    })
    if (onSuccess) {
      onSuccess()
    }
  }

export const setNewGlues = (id, nameservers, ips, onSuccess) => (dispatch) => {
  dispatch({
    type: APP_TYPES.SET_DOMAIN_IPS,
    id,
    nameservers,
    ips,
  })
  if (onSuccess) {
    onSuccess()
  }
}

export const showLanguagesPopup = (status) => ({
  type: APP_TYPES.SHOW_LANGUAGES_POPUP,
  status,
})

export const toggleLanguagesPopup = () => ({
  type: APP_TYPES.TOGGLE_LANGUAGES_POPUP,
})

export const showAccountPopup = (status) => ({
  type: APP_TYPES.SHOW_ACCOUNT_POPUP,
  status,
})

export const toggleAccountPopup = () => ({
  type: APP_TYPES.TOGGLE_ACCOUNT_POPUP,
})

export const setDomainContact = (contactId) => ({
  type: APP_TYPES.SET_DOMAIN_CONTACT,
  contactId,
})

export const addOrder =
  (promocode, domains, onSuccess) => async (dispatch, getState) => {
    try {
      dispatch({
        type: APP_TYPES.ADD_ORDER_REQUEST,
      })

      const { cart } = getState().app

      const order = {
        domains: [],
      }

      const contactID = getState().app.contactId

      if (contactID !== null) {
        order.contactid = contactID
      }

      cart.forEach((item, ind) => {
        if (item.type === 'domain') {
          const obj = {
            domain_tp: 'register',
            domain: `${item.domain}${item.tld}`,
            nameservers: item.nameservers,
            regperiod: item.years,
            idprotection: item.incognito,
            domainfields: item.additionalFields,
            isPremium: item.premium_price_usd ? true : undefined,
          }

          if (item.contact) {
            obj.contactid = item.contact
          }

          const hostingMatch = cart.find(
            (product) =>
              ['hosting', 'constructor'].includes(product.type) &&
              product.tld === item.tld &&
              product.domain === item.domain
          )
          if (hostingMatch) {
            obj.pid = hostingMatch.pid
            obj.billingcycle = hostingMatch.time.name
          }
          order.domains.push(obj)
        }

        if (item.type === 'hosting' || item.type === 'constructor') {
          const domainMatch = cart.find(
            (product) =>
              product.type === 'domain' &&
              product.tld === item.tld &&
              product.domain === item.domain
          )
          if (!domainMatch) {
            order.domains.push({
              domain_tp: 'own',
              domain: `${item.domain}${item.tld}`,
              personType: '',
              pid: item.pid,
              domaintype: '',
              regperiod: '',
              billingcycle: item.time.name,
            })
          }
        }
        if (item.type === 'ssl') {
          order.domains.push({
            domain: Object.values(domains)[ind],
            pid: item.pid,
            productType: 'ssl',
          })
        }
        if (item.type === 'vps') {
          order.domains.push({
            pid: item.pid,
            lid: item.serverLocationId,
            oid: item.serverOsId,
            billingcycle: item.time.name,
            productType: 'vps',
          })
        }
      })

      if (promocode) {
        order.promocode = promocode
      }

      const orderSuccess = await FetchApi.post('orders/', {
        ...order,
      })

      await delay(2000)
      localStorage.clear('cart')

      dispatch({
        type: APP_TYPES.ADD_ORDER_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(orderSuccess.data.data.invoiceid)
      }
    } catch (e) {
      dispatch({
        type: APP_TYPES.ADD_ORDER_FAIL,
        error: e.data ? e.data.error || e.data.validation_error : e.message,
      })
    }
  }

export const changeBackendLanguage = (lng) => (dispatch) => {
  dispatch({
    type: APP_TYPES.CHANGE_BACKEND_LANGUAGE_REQUEST,
  })
  FetchApi.post('users/updateCustomerLang', { language: lng })
}

export const getUserInfo = () => async (dispatch) => {
  try {
    dispatch({
      type: APP_TYPES.GET_USER_INFO_REQUEST,
    })

    const req = await FetchApi.get('users/info')

    dispatch({
      type: APP_TYPES.GET_USER_INFO_SUCCESS,
      userInfo: req.data,
    })
  } catch (e) {
    dispatch({
      type: APP_TYPES.GET_USER_INFO_FAIL,
      error: e,
    })
  }
}

export const setAutopay = (value) => async (dispatch) => {
  try {
    dispatch({
      type: APP_TYPES.SET_AUTOPAY,
    })
  } catch (e) {}
}

export const getUserCredit = (onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: APP_TYPES.GET_USER_CREDIT_REQUEST,
    })

    const res = await FetchApi.post('payments/getCredit')

    Cookies.set('creditRecharge', res.data.credit)

    dispatch({
      type: APP_TYPES.GET_USER_CREDIT_SUCCESS,
      credit: res.data.credit,
    })

    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    dispatch({
      type: APP_TYPES.GET_USER_CREDIT_FAIL,
      error: e,
    })
  }
}

export const numberWithCommas = (x, noCurr) => (dispatch, getState) => {
  const c = noCurr ? 1 : +getState().app.auth.currency || 1

  const currency =
    c === 1 ? i18n.t('general.currency.dram') : i18n.t('general.currency.usd')

  const n = c === 1 ? parseInt(x) : x
  return `${(Math.round(10 * parseFloat(n)) / 10)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${currency}`
}

export const getUserIp = () => async (dispatch) => {
  try {
    const res = await fetch(
      'https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572'
    )
    const ip = await res.json()
    dispatch({
      type: APP_TYPES.GET_USER_IP,
      userIp: ip.IPv4,
    })
  } catch (e) {}
}

const delay = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
