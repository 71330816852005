import { HostingPage as Self } from './HostingPage'
import { connect } from 'react-redux'
import { changeTab, changeExpand } from './HostingPage.actions'
import { addToCart, numberWithCommas } from '../../common/App/App.actions'

const mapStateToProps = ({ hostingPage, app }) => ({
  tab: hostingPage.tab,
  expandedCard: hostingPage.expandedCard,
  auth: app.auth,
})

const mapDispatchToProps = {
  changeTab,
  changeExpand,
  numberWithCommas,
  addToCart,
}

export const HostingPage = connect(mapStateToProps, mapDispatchToProps)(Self)
