import React, { useCallback } from 'react';
import * as S from './ConstructorCard.styles';
import { i18n } from '../../i18n';

import { changeUrl } from '../../hooks/changeUrl';

export const ConstructorCard = ({
  auth,
  constructor,
  productType,
  addToCart,
  history,
  numberWithCommas,
}) => {
  const handleOrderNowButton = useCallback(
    (constructor) => {
      const id = addToCart({
        ...constructor,
        type: 'constructor',
        time: { value: 1, name: 'monthly' },
      });

      history.push(changeUrl(`/product-details?id=${id}`));
    },
    [history, addToCart]
  );
  return (
    <>
      <S.ConstructorContainer>
        <S.TitleRow>
          <S.TitleContainer>{constructor.name}</S.TitleContainer>
        </S.TitleRow>
        <S.Row>
          <S.ColumnContainer>
            <S.ColumnValue>{constructor.providedArea}</S.ColumnValue>
            <S.ColumnKey>{i18n.t('constructorComponent.area')}</S.ColumnKey>
          </S.ColumnContainer>
          <S.ColumnContainer>
            <S.ColumnValue>{constructor.numberOfPages}</S.ColumnValue>
            <S.ColumnKey>{i18n.t('constructorComponent.pages')}</S.ColumnKey>
          </S.ColumnContainer>
          <S.ColumnContainer>
            <S.ColumnValue>{constructor.emails}</S.ColumnValue>
            <S.ColumnKey>{i18n.t('constructorComponent.mails')}</S.ColumnKey>
          </S.ColumnContainer>
          <S.ColumnContainer>
            <S.ColumnValue>
              {constructor.sslCertificate ? '+' : '-'}
            </S.ColumnValue>
            <S.ColumnKey>{i18n.t('constructorComponent.ssl')}</S.ColumnKey>
          </S.ColumnContainer>
        </S.Row>
        <S.OrderContainer>
          <S.PriceContainer>
            {`${numberWithCommas(
              auth.currency === 2 ? constructor.price_usd : constructor.price
            )}
             /
              ${i18n.t('general.periods.monthly')}`}
          </S.PriceContainer>
          <S.OrderButton
            onClick={() => handleOrderNowButton(constructor, productType)}
          >
            {i18n.t('constructorComponent.orderNow')}
          </S.OrderButton>
        </S.OrderContainer>
      </S.ConstructorContainer>
    </>
  );
};
