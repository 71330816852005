import React, { useCallback } from 'react';
import Slider from 'react-slick';

import * as S from './HostingPackagesBlock.styles';
import { i18n } from '../../../../i18n';
import { changeUrl } from '../../../../hooks/changeUrl';

export const HostingPackagesBlock = ({
  auth,
  history,
  data,
  colors,
  shouldBeFiltered,
  addToCart,
  numberWithCommas,
  showSecondaryText,
}) => {
  const settings = {
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 3,
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 1210,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          arrows: false,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          arrows: false,
        },
      },
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          settings: 'unslick',
        },
      },
    ],
  };

  const handleOrderNowButton = useCallback(
    (product) => {
      const id = addToCart({
        ...product,
        type: 'hosting',
        time: { value: 1, name: 'monthly' },
      });
      history.push(changeUrl(`/product-details?id=${id}`));
    },
    [history, addToCart]
  );

  const filteredData = shouldBeFiltered
    ? data.filter((item) => item.isFeatured)
    : data;

  return (
    <>
      <S.HostingPackageSideFiller className="sidefilter">
        <S.Slides>
          <Slider {...settings}>
            {filteredData.map((hostingPackage, index) => (
              <S.HostingPackageSlideContainer key={hostingPackage.id}>
                <S.HostingPackageDiv key={hostingPackage.id}>
                  <S.HostingContainer>
                    <S.PackageParagraph>
                      {hostingPackage.name}
                    </S.PackageParagraph>
                    {showSecondaryText && !!hostingPackage.secondaryText ? (
                      <S.KeyContainer>
                        {hostingPackage.secondaryText}
                      </S.KeyContainer>
                    ) : (
                      hostingPackage.params.map(
                        (x) =>
                          x.isFeatured && (
                            <S.DetailContainer key={x.key}>
                              <S.ValueContainer>
                                {i18n.t(x.value)}
                              </S.ValueContainer>
                              <S.KeyContainer>{i18n.t(x.key)}</S.KeyContainer>
                            </S.DetailContainer>
                          )
                      )
                    )}
                    <S.PriceContainer color={colors[index].backgroundColor}>
                      <S.PriceValueContainer>
                        {numberWithCommas(
                          auth.currency === 2
                            ? hostingPackage.price_usd
                            : hostingPackage.price
                        )}
                      </S.PriceValueContainer>
                      <S.PriceKeyContainer>
                        {i18n.t('general.periods.monthly')}
                      </S.PriceKeyContainer>
                    </S.PriceContainer>
                    <S.BuyButton
                      onClick={() => handleOrderNowButton(hostingPackage)}
                    >
                      {i18n.t('hostingPackageBlock.buy')}
                    </S.BuyButton>
                  </S.HostingContainer>
                </S.HostingPackageDiv>
              </S.HostingPackageSlideContainer>
            ))}
          </Slider>
        </S.Slides>
      </S.HostingPackageSideFiller>
      <S.HostingPackageSideFillerDesktop>
        <S.Slides className="slides">
          {filteredData.map((hostingPackage, index) => (
            <S.HostingPackageSlideContainer key={hostingPackage.id}>
              <S.HostingPackageDiv>
                <S.HostingContainer>
                  <S.PackageParagraph>{hostingPackage.name}</S.PackageParagraph>
                  {hostingPackage.params.map((x) => (
                    <S.DetailContainer key={x.key}>
                      <S.ValueContainer>{i18n.t(x.value)}</S.ValueContainer>
                      <S.KeyContainer>{i18n.t(x.key)}</S.KeyContainer>
                    </S.DetailContainer>
                  ))}
                  <S.PriceContainer color={colors[index].backgroundColor}>
                    <S.PriceValueContainer>
                      {numberWithCommas(
                        auth.currency === 2
                          ? hostingPackage.price_usd
                          : hostingPackage.price
                      )}
                    </S.PriceValueContainer>
                    <S.PriceKeyContainer>
                      {i18n.t('general.periods.monthly')}
                    </S.PriceKeyContainer>
                  </S.PriceContainer>
                  <S.BuyButton onClick={() => addToCart(hostingPackage)}>
                    {i18n.t('hostingPackageBlock.buy')}
                  </S.BuyButton>
                </S.HostingContainer>
              </S.HostingPackageDiv>
            </S.HostingPackageSlideContainer>
          ))}
        </S.Slides>
      </S.HostingPackageSideFillerDesktop>
    </>
  );
};
