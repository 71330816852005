import styled from 'styled-components';

export const DropdownContainer = styled.div`
  margin: auto 0;
`

export const DropdownWrapper = styled.div`
  display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
  z-index: 10;
  position: absolute;
  max-width: 83px;
  background: #fff;
  max-height: 140px;
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: #ccc;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
}

  min-width: 83px;
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.1);
  padding: 5px 0 5px 0;
  margin-top: 19px;
  border-radius: 5px;
`

export const DropdownOption = styled.div`
  padding: 5px 0 5px 10px;
  font-family: ${(p) => p.fontChange ? 'Mardoto' : 'Roboto'};
  width: 100%;
  font-size: 16px;
  cursor:  ${p => p.isDisabled ? 'unset' : 'pointer'};
  background: ${p => p.default || p.value || 'none'};
  color: ${p => p.isDisabled ? '#C4C4C4' : '#000'};
  :hover {
    color: ${p => p.isDisabled ? '#C4C4C4' : '#fff'};
    background: ${p => p.isDisabled ? 'none' : '#242E46'} ;
  }
`

/*
top: ${({ coordinates }) => coordinates.bottom + 'px'};
left: ${({ coordinates }) => coordinates.left + 'px'};
*/