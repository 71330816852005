import React from 'react'
import { i18n } from '../../../i18n'
import { config } from '../Header.config'
import * as S from './NavBar.styles'
import Cookies from 'js-cookie'
import { changeUrl } from '../../../hooks/changeUrl'

export const NavBar = () => {
  function changeBlogUrl(url) {
    const lang = Cookies.get('lng')
    switch (lang) {
      case 'am':
        return `/am${url}`
      case 'ru':
        return `/ru${url}`
      case 'en':
        return `/en${url}`
      default:
        return `/am${url}`
    }
  }

  return (
    <S.NavigationContainer>
      <S.NavigationList>
        {config.map((item) => (
          <S.ListItem key={item.translationKey}>
            <S.InlineLink to={changeUrl(item.to)}>
              {i18n.t(item.translationKey)}
            </S.InlineLink>
          </S.ListItem>
        ))}
        <S.Blog href={`/blog${changeBlogUrl('/posts')}`}>Blog</S.Blog>
      </S.NavigationList>
    </S.NavigationContainer>
  )
}
