import { TWO_FACTOR_AUTH_ENABLE_TYPES } from './TwoFactorAuthEnablePage.types';

const initialState = {
  loading: false,
  data: {},
  error: null,
};

function twoFactorAuthEnablePage(state = initialState, action) {
  switch (action.type) {
    case TWO_FACTOR_AUTH_ENABLE_TYPES.GET_QR_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        data: {},
        error: null,
      };
    case TWO_FACTOR_AUTH_ENABLE_TYPES.GET_QR_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };
    case TWO_FACTOR_AUTH_ENABLE_TYPES.GET_QR_CODE_FAIL:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.error,
      };
    default:
      return state;
  }
}

export { twoFactorAuthEnablePage };
