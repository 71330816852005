import styled from 'styled-components';
import Slider from 'react-slick';

import opinionBackground from '../../../../images/customerOpinionBackground.svg';

export const StyledSlider = styled(Slider)`
  .slick-dots {
    left: -10px;
    text-align: center;
    margin-top: 20px;

    > li {
      &.slick-active {
        > button {
          &:before {
            content: '';
            background-color: #212121;
            height: 8px;
            width: 8px;
            border: 0;
            top: 6px;
            left: 6px;
          }
        }
      }
      > button {
        &:before {
          content: '';
          background-color: white;
          height: 4px;
          width: 4px;
          border: 2px solid #232323;
          border-radius: 4px;
          top: 6px;
          left: 6px;
        }
      }
    }
  }
`;

export const CustomersOpinion = styled.div`
  background-color: #ffffff;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${opinionBackground});
  background-size: cover;

  @media (min-width: 768px) {
    padding-top: 91px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 100px;
  }
  @media (min-width: 1280px) {
    padding: 40px 314px 100px 314px;
  }
`;

export const CustomerOpinionTitle = styled.div`
  font-weight: 500;
  color: #212121;
  text-align: center;
  font-size: 20px;
  color: #212121;
  @media (min-width: 768px) {
    font-size: 24px;
  }
  @media (min-width: 1280px) {
    font-size: 24px;
  }
`;

export const CustomerOpinionDiv = styled.div`
  background-color: #fff;
  &:after {
    width: 20px;
    height: 40px;
    background: #fff;
    position: absolute;
    bottom: -21px;
    left: 40px;
  }
`;

export const OpinionDiv = styled.div`
  padding-top: 20px;
  font-weight: 300;
  width: 700px;
  // height: 430px;
  display: flex;
  font-family: ${(p) => p.isArmenian ? 'Mardoto' : 'Roboto'};
  flex-direction: column;
  justify-content: space-between;


  @media (min-width: 768px) {
    // height: 240px;
    width: 812px;
  }
  @media (min-width: 1280px) {
  }
`;

export const OpinionDivContent = styled.div`
  font-size: 18px;
  width: 290px;
  margin: auto;
  font-weight: 400;
 
  text-align: justify;
  word-break: break-space;

  @media (min-width: 768px) {
    font-size: 20px;
    width: 640px;
  }
  @media (min-width: 1280px) {
    font-size: 24px;
    line-height: 48px;
    
    width: 810px;
  }
`;

export const OpinionDivAuthor = styled.em`
  display: block;
  font-size: 14px;
  width: 290px;
  margin: auto;
  font-style: normal;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 20px;
    width: 669px;
    text-align: center;
  }
  @media (min-width: 1280px) {
    width: 810px;
  }
`;

export const SlideContainer = styled.div`
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  height: 500px;
  justify-content: center;
  outline: none;

  @media (min-width: 768px) {
    height: 350px;
  }
  @media (min-width: 1280px) {
    height: 500px;
  }
`;
