import { connect } from 'react-redux'

import { NameServerModal as Self } from './NameServerModal'
import { setNewDomainNameServers } from '../../../common/App/App.actions'
import {
  setNameServers,
  getNameservers,
  getNameServersCode,
} from './NameServerModal.actions'
import { showModal } from '../../../common/Modal/Modal.actions'

const mapStateToProps = ({ app, nameServerModal }) => ({
  cart: app.cart,
  nameservers: nameServerModal.modalNameservers,
  nameserversLoading: nameServerModal.modalNameserversLoading,
  updateNameserversLoading: nameServerModal.updateNameserversLoading,
  updateNameserversError: nameServerModal.updateNameserversError,
  modalNameserversCodeLoading: nameServerModal.modalNameserversCodeLoading,
  auth: app.auth,
})

const mapDispatchToProps = {
  setNameServers,
  setNewDomainNameServers,
  getNameservers,
  getNameServersCode,
  showModal,
}

export const NameServerModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self)
