import React, { useCallback } from 'react';
import * as S from './SslCard.styles';
import { i18n } from '../../i18n';

import { changeUrl } from '../../hooks/changeUrl';

const SslCard = ({ auth, card, addToCart, history, numberWithCommas }) => {
  const handleOrderNowButton = useCallback(
    (constructor) => {
      const id = addToCart({ ...constructor, type: 'ssl' });
      history.push(changeUrl(`/order-details?id=${id}`));
    },
    [history, addToCart]
  );
  return (
    <>
      <S.SSlCardContainer>
        <S.Body>
          <S.Title>{card.name}</S.Title>
          <S.Image
            // src={card.description}
            src={card.image}
            alt="Ssl package image"
            title="ssl package cat image"
          />
          <S.Line />
          <S.OrderButtonContainer onClick={() => handleOrderNowButton(card)}>
            <S.PriceContainer>
              {`${numberWithCommas(
                auth.currency === 2 ? card.price_usd : card.price
              )}  / 
              ${i18n.t('general.periods.yearly')}`}
            </S.PriceContainer>
            <S.OrderButton>{i18n.t('sslPage.orderNow')}</S.OrderButton>
          </S.OrderButtonContainer>
        </S.Body>
      </S.SSlCardContainer>
    </>
  );
};

export { SslCard };
