import styled from 'styled-components'
import * as fontWeight from '../../../../assets/styles/constants/fontWeight'
import Container from '../../../../components/Container'

export const ContainerOfPackages = styled(Container)`
  display: flex;
  background-color: #ffffff;
  justify-content: space-between;
`

export const Slides = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  z-index: 2;
`

export const HostingPackageSideFiller = styled.div`
  background-color: #ffffff;
  max-width: 2500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  .slick-list {
    padding: 0 0;
    @media (min-width: 1280px) {
      width: 100%;
    }
  }

  .slick-slider {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .slick-arrow {
    display: none;
  }

  .slick-track {
    display: flex !important;
    align-items: center;
    justify-content: center;
    min-height: 760px;
  }

  .slick-slide {
    width: 100%;

    @media (min-width: 768px) {
      max-width: 242px;
      min-width: 242px;
    }
  }
`

export const HostingPackageSideFillerDesktop = styled(HostingPackageSideFiller)`
  display: none;

  @media (min-width: 1280px) {
    display: none;
  }
`

export const PriceContainer = styled.div`
  padding: 29px 0;
  width: 100%;
`

export const PriceValueContainer = styled.div`
  font-size: 20px;
  text-align: center;
  font-family: sans-serif;
  color: black;
`

export const PriceKeyContainer = styled.div`
  font-size: 20px;
  text-align: center;
  color: black;
`

export const HostingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 710px;
`

export const PackageParagraph = styled.div`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 24px;
  color: #242e46;
  padding: 30px 0;
`

export const DetailContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
`

export const ValueContainer = styled.div`
  font-size: 22px;
  color: #393939;
  margin-bottom: 10px;
`

export const KeyContainer = styled.div`
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  color: #393939;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 400;
`

export const BuyButton = styled.button`
  border-radius: 5px;
  border: none;
  padding: 8px 44px;
  margin-top: 17px;
  margin-bottom: 36px;
  background-color: #00c084;
  color: #ffffff;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  }
`

export const HostingPackageSlideContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
`

export const HostingPackageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  transition: transform 0.1s;
  border: 1px solid #dedede;

  &:last-child {
    margin: 0px;

    ${PriceContainer} {
      margin-top: auto;
    }
    ${KeyContainer} {
      margin-top: auto;
    }
  }
  &:hover {
    transform: scale(1.03) translate(0, 10px);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
  }
`
