import React, { useEffect, useRef, createRef, useState } from 'react';
import { Formik } from 'formik';
import Dropzone from 'react-dropzone';
import { decode } from 'html-entities';


import FetchApi from '../../../helpers/FetchApi';
import * as S from './ViewTicketModal.styles';
import { i18n } from '../../../i18n';
import { initialValues, yupScheme } from './ViewTicketModal.config';
import { Loader } from '../../../components/Loader';

import { changeUrl } from '../../../hooks/changeUrl';

export const ViewTicketModal = ({
  getTicketsInfo,
  getTicketsInfoSimple,
  replyTicket,
  closeTicket,
  hideModal,
  data,
  ticketId,
  auth,
  loading,
  error,
  replyLoading,
  history,
  getUserIp,
  userIp,
}) => {
  const modalRef = useRef(null);
  const [previews, setPreviews] = useState([]);
  useEffect(() => {
    getTicketsInfo(ticketId, () => {
      modalRef.current.scrollTo(0,0);

    });
    return history.push(changeUrl('/client-area?tab=tickets'));
  }, [modalRef.current]);

  const dropzoneRef = createRef();
  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  useEffect(() => {
    getUserIp();
  }, []);

  return (
      <S.Modal onClick={(e) => e.stopPropagation()} ref={modalRef}>
        <S.ViewTicketModalContainer>
          {loading ? (
            <Loader color="#00C084" size={0.5} />
          ) : (
            <>
              <S.ModalTitle>
                {i18n.t('viewTicketModal.title')} {data.tid}
              </S.ModalTitle>
              {data.replies &&
                data.replies.reply &&
                data.replies.reply.map((item) => (
                  <S.TicketData key={item.id}>
                    <S.PersonInfo isClient={item.name}>
                      <S.Fullname>
                        {decode(item.name) || decode(item.admin)}
                      </S.Fullname>
                      <S.Date>{item.date}</S.Date>
                    </S.PersonInfo>
                    {!item.message || item.message.length < 1 ? (
                      <S.Message>N/A</S.Message>
                    ) : (
                      <S.Message>{decode(item.message)}</S.Message>
                    )}

                    <S.AttachmentsList
                      list={item.attachments && item.attachments.length > 0}
                      hidden={!item.attachments || item.attachments.length < 1}
                    >
                      <S.Attachments
                        hidden={!item.attachments || item.attachments.length < 1}
                      >
                        {item.attachment &&
                          item.attachment.split('|').map((file, index) => {
                            return (
                              <S.AcceptedFiles key={file}>
                                <S.ImageFrame>
                                  <S.PreviewImage
                                    src={`${FetchApi.getUrl()}/tickets/download?ticketId=${ticketId}&filename=${file}&token=${FetchApi.getToken()}`}
                                    alt="preview image"
                                    title="uploaded image"
                                  />
                                </S.ImageFrame>
                                <S.UpperWrapper>
                                  <S.FileName>
                                    {file.length > 10
                                      ? file.substring(0, 10) + '...'
                                      : file}
                                  </S.FileName>
                                  <S.Link
                                    href={
                                      `${FetchApi.getUrl()}/tickets/download?ticketId=${ticketId}&filename=${
                                        item.attachment
                                      }&token=${FetchApi.getToken()}` ||
                                      previews[index]
                                    }
                                    target="_blank"
                                    download={`${FetchApi.getUrl()}/tickets/download?ticketId=${ticketId}&filename=${
                                      item.attachment
                                    }&token=${FetchApi.getToken()}`}
                                  >
                                    {i18n.t('viewTicketModal.download')}
                                  </S.Link>
                                </S.UpperWrapper>
                              </S.AcceptedFiles>
                            );
                          })}
                      </S.Attachments>
                    </S.AttachmentsList>
                  </S.TicketData>
                ))}


              {data.status !== 'Closed' && (
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={yupScheme}
                  onSubmit={async (
                    values,
                    { setSubmitting, setFieldValue, resetForm }
                  ) => {
                    const ticketMessage = `${values.ticketMessage} \n\n${i18n.t(
                      'ipNote.ipText'
                    )} ${userIp && userIp}`;

                    const formattedValues = {
                      ...values,
                      ticketMessage,
                    };

                    await replyTicket({ ...formattedValues, ticketId }, auth);
                    getTicketsInfoSimple(ticketId);
                    resetForm()
                    setPreviews([]);
                    modalRef.current.scrollTo(0, modalRef.current.scrollHeight);
                    setSubmitting(false);
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    isValid,
                    dirty,
                  }) => (
                    <S.StyledForm>
                      <S.FormItem>
                        <S.Label>{i18n.t('viewTicketModal.message')}*</S.Label>
                        <S.Textarea
                          placeholder={i18n.t('viewTicketModal.placeholder')}
                          onChange={handleChange}
                          name="ticketMessage"
                          value={values.ticketMessage}
                          maxLength={2000}
                          isError={values.ticketMessage.length === 2000}
                          isDefault={values.ticketMessage.length === 0}
                          onBlur={handleBlur}
                        />
                        <S.ErrorAndCounterWrapper>
                          {!(isValid && touched && !dirty) && (
                            <S.TextareaError>
                              {i18n.t(errors.ticketMessage)}
                            </S.TextareaError>
                          )}
                          <S.Counter
                            isError={values.ticketMessage.length === 2000}
                          >
                            {values.ticketMessage.length}/2000
                          </S.Counter>
                        </S.ErrorAndCounterWrapper>
                        <Dropzone
                          ref={dropzoneRef}
                          noClick
                          noKeyboard
                          name="files"
                          minSize={0}
                          maxSize={2621440}
                          multiple={false}
                          maxFiles={1}
                          accept="image/jpg, image/jpeg, image/png, image/JPG, image/JPEG, image/PNG"
                          onDrop={(files) => {
                            setFieldValue('files', [...values.files, ...files]);
                            const reader = new FileReader();
                            reader.onload = (event) => {
                              setPreviews([...previews, event.target.result]);
                            };
                            if (files[0]) {
                              reader.readAsDataURL(files[0]);
                            }
                          }}
                        >
                          {({ getRootProps, getInputProps }) => {
                            return (
                              <S.Section>
                                <div {...getRootProps()}>
                                  <aside>
                                    <input {...getInputProps()} />
                                    <S.ErrorLimits>
                                      <S.Type>
                                        * {i18n.t('openTicketPage.note')}
                                      </S.Type>
                                      <S.Limit>
                                        * {i18n.t('openTicketPage.limit')}
                                      </S.Limit>
                                    </S.ErrorLimits>
                                    <S.AttachButton
                                      type="button"
                                      onClick={openDialog}
                                      disabled={values.files.length > 0}
                                    >
                                      {i18n.t('openTicketPage.attachButton')}
                                    </S.AttachButton>
                                    <S.AttachmentsListReply>
                                      {values.files.map((file, index) => (
                                        <S.AttachmentsReply>
                                          <S.AcceptedFilesReply key={file.path}>
                                            {/* <S.ImageFrame>
                                              <S.PreviewImageReply
                                                src={previews[index]}
                                                alt="preview image"
                                                title="uploaded image"
                                              />
                                            </S.ImageFrame> */}
                                            <S.ImageName>
                                              {file.path.length > 20
                                                ? file.path.substring(0, 20) +
                                                  '...'
                                                : file.path}
                                            </S.ImageName>
                                          </S.AcceptedFilesReply>
                                          <S.RemoveButtonReply
                                            onClick={() => {
                                              setFieldValue(
                                                'files',
                                                values.files.filter(
                                                  (item) =>
                                                    item.path !== file.path
                                                )
                                              );
                                              setPreviews([]);
                                            }}
                                          />
                                        </S.AttachmentsReply>
                                      ))}
                                    </S.AttachmentsListReply>
                                  </aside>
                                </div>
                                {values.files.length > 1 && (
                                  <S.ErrorMessage>
                                    {i18n.t('openTicketPage.limitError')}
                                  </S.ErrorMessage>
                                )}
                              </S.Section>
                            );
                          }}
                        </Dropzone>
                        <S.Row></S.Row>
                      </S.FormItem>
                      <S.ButtonContainer>
                        <S.ReplyButton
                          disabled={
                            isSubmitting ||
                            !(isValid && dirty) ||
                            values.files.length > 1
                          }
                        >
                          {replyLoading ? (
                            <Loader color="#ffffff" size={0.5} />
                          ) : (
                            i18n.t('viewTicketModal.reply')
                          )}
                        </S.ReplyButton>
                        <S.CloseButton
                          onClick={() => closeTicket(ticketId, () => hideModal())}
                        >
                          {i18n.t('viewTicketModal.close')}
                        </S.CloseButton>
                      </S.ButtonContainer>
                    </S.StyledForm>
                  )}
                </Formik>
              )}
              {error && <S.Error>{i18n.t(`backend.viewTicket.${error.message}`)}</S.Error>}
              <S.CloseModalButton onClick={hideModal} />
            </>
          )}
        </S.ViewTicketModalContainer>
      </S.Modal>
    
  );
};
